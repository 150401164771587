import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Hidden,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { Globe as GlobeIcon } from '@fluence/core'
import { Link } from 'react-router-dom'
import NavLinkRef from '../NavLinkRef'
import DetailItem from '../DetailItem'
import { getCurrentAssetId, getAssetCapacityText, getAssetIcon } from '../../utility/utility'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import MarketShotClock from '../MarketShotClock'

const useStyles = makeStyles(theme => ({
  appBar: {
    overflow: 'hidden',
    backgroundColor: theme.custom.palette.background.chrome.secondary,
  },
  toolbar: {
    minHeight: theme.custom.overrides.AssetHeader.minHeight,
    whiteSpace: 'nowrap',
  },
  title: {
    flex: '1 1 auto',
    color: theme.palette.text.primary,
  },
  home: {
    minWidth: theme.custom.overrides.AssetHeader.navItem.width,
    width: theme.custom.overrides.AssetHeader.navItem.width,
    backgroundColor: theme.custom.palette.background.chrome.secondary,
    height: theme.custom.overrides.AssetHeader.height,
    justifyContent: 'center',
    marginRight: 15,
    paddingLeft: 12,
    color: theme.palette.text.primary,
  },
  assetMeta: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 0,
  },
  listIcon: {
    minWidth: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
}))

function AssetHeader(props) {
  const classes = useStyles(props)
  const { asset, assets, permissions, location } = props
  const sortedAssets = _.isArray(assets) ? _.orderBy(assets, ['name'], ['asc']) : []
  const assetId = _.get(asset, 'assetId')

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuIsOpen] = useState(false)
  const menuId = 'asset-search-menu'
  const isAssetPage = !_.isEmpty(asset)

  const getTitle = () => {
    const path = location.pathname
    if (path === '/') {
      return 'Overview'
    } else if (path === '/userManagement') {
      return 'User Management'
    } else if (path === '/preferences') {
      return 'Preferences'
    } else {
      return _.get(asset, 'name', '')
    }
  }
  const title = getTitle()

  function handleMenuClick(event) {
    if (isAssetPage) {
      setMenuIsOpen(!menuOpen)
      setAnchorEl(!menuOpen ? event.currentTarget : null)
    }
  }

  const createAssetUrl = externalAsset => {
    const id = _.get(externalAsset, 'assetId')
    const defaultDestination = `/asset/${id}/bids`

    if (!getCurrentAssetId(location.pathname)) {
      return defaultDestination
    }
    const pathArray = location.pathname.split(`/asset/${assetId}`)
    const suffix = _.last(pathArray)
    const to = `/asset/${id}${suffix}`

    // If user doesn't have access to this destination send them to the default path
    return to.startsWith(`/asset/${id}/tradingSettings`) && !canAdjustRiskAppetite(permissions, externalAsset)
      ? defaultDestination
      : to
  }

  const regionDisplayName = _.get(asset, 'region.data.display_name', '').toUpperCase()
  return (
    <AppBar position="sticky" className={classes.appBar}>
      <Toolbar disableGutters variant="dense" className={classes.toolbar}>
        <ListItem button component={Link} to="/" className={classes.home}>
          <HomeIcon fontSize="small" />
        </ListItem>
        <Typography variant="h2" className={classes.title} onClick={handleMenuClick}>
          {title}
          {isAssetPage && (
            <IconButton
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              className={classes.iconButton}
            >
              <ArrowDropDown />
            </IconButton>
          )}
        </Typography>
        <Hidden xsDown>
          {!_.isEmpty(asset) && !asset.isError && (
            <div className={classes.assetMeta}>
              <DetailItem
                primaryText={regionDisplayName}
                secondaryText="market region"
                icon={<GlobeIcon fontSize="small" />}
              />
              <DetailItem
                primaryText={getAssetCapacityText(asset).both}
                secondaryText="Installed Capacity"
                icon={getAssetIcon(asset.data.tag_prefix, asset.data.asset_type)}
              />
              <MarketShotClock asset={asset} />
            </div>
          )}
        </Hidden>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        id={menuId}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClick}
      >
        {sortedAssets.map(ast => (
          <ListItem
            selected={ast.assetId === assetId}
            key={ast.assetId}
            button
            component={NavLinkRef}
            onClick={handleMenuClick}
            to={createAssetUrl(ast)}
          >
            <ListItemIcon className={classes.listIcon}>
              {getAssetIcon(ast.data.tag_prefix, ast.data.asset_type)}
            </ListItemIcon>
            <ListItemText primary={ast.name} />
          </ListItem>
        ))}
      </Menu>
    </AppBar>
  )
}

AssetHeader.propTypes = {
  asset: PropTypes.object,
  assets: PropTypes.array,
  location: PropTypes.object,
}

export default AssetHeader
