import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress as muiLinearProgress, } from '@material-ui/core'

const DISPLAY_NAME = 'AmsLinearProgress'

const LinearProgress = withStyles(theme => ({
  root: {
    height: 2,
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.default,
  },
}))(muiLinearProgress)

LinearProgress.displayName = DISPLAY_NAME

LinearProgress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
}

export default LinearProgress
