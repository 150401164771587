import React from 'react'
import { withRouter } from 'react-router-dom'
import { Paper, Typography, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const classes = theme => ({
  error: {
    width: '100%',
    padding: theme.spacing(2),

    '& h1': {
      marginBottom: theme.spacing(1),
    },
  },
})

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      const { classes } = this.props

      return (
        <Paper className={classes.error}>
          <Typography variant="h2" component="h1">
            Something went wrong
          </Typography>

          <Typography variant="body1">
            Please refresh the page and contact support at{' '}
            <Link href="mailto:support.nem@fluenceenergy.com" target="_blank" rel="noopener">
              support.nem@fluenceenergy.com
            </Link>{' '}
            if the problem persists.
          </Typography>
        </Paper>
      )
    }

    return this.props.children
  }
}

export default withRouter(withStyles(classes, { withTheme: true })(AppErrorBoundary))
