import { combineReducers } from 'redux'
import _ from 'lodash'
import types from './types'

const marketData = (state = {}, action) => {
  switch (action.type) {
    case types.GLOBAL_APP_MARKETS_SUCCESS: {
      const payload = _.get(action, 'payload.data', {})
      if (!_.isEqual(payload, state)) {
        return payload
      }
      return state
    }
    default:
      return state
  }
}

export default combineReducers({
  marketData,
})
