import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import { CACHE_API } from '../cachingMiddleware'
import { makeResourceReducer, ResourceAction } from '../api'

// API actions
export const allAssets = new ResourceAction({
  type: 'ASSETS',
  config: {
    endpoint: '/market/frontend/assets?marketName=AEMO',
  },
})

export const allAssignableAssets = new ResourceAction({
  type: 'ASSIGNABLE_ASSETS',
  config: {
    endpoint: '/market/assets/assignable?marketName=AEMO',
  },
})

export const market = new ResourceAction({
  type: 'MARKETS',
  config: {
    endpoint: '/market/markets',
  },
})

export const allMarkets = new ResourceAction({
  type: 'MARKETS',
  config: {
    endpoint: '/market/markets',
  },
})

export const mapInfo = new ResourceAction({
  type: 'MAP_INFO',
  config: (marketName, asOf) => ({
    endpoint: `/market/frontend/market/${marketName}/map?asOf=${encodeURIComponent(asOf.toISOString())}`,
  }),
})

export const getAssets = () => ({
  [CACHE_API]: {
    mapStateToCache: state => state.dashboard.assets.payload,
    mapStateToUpdatedOn: state => state.dashboard.assets.payload.updatedOn,
    createFetchAction: () => allAssets.get(),
    invalidateAfter: '00:05:00',
  },
})

// UI actions
export const SORT_ASSETS = 'SORT_ASSETS'
export const SELECT_MAP_REGION = 'SELECT_MAP_REGION'

export const sortAssets = (title, direction) => ({
  type: SORT_ASSETS,
  payload: {
    title,
    direction,
  },
})

export const selectRegion = regionName => ({
  type: SELECT_MAP_REGION,
  payload: regionName,
})

// UI reducer
export const TOGGLE_LIVE_VIEW = 'TOGGLE_LIVE_VIEW'

const view = (
  state = {
    sortTitle: 'Asset',
    sortDirection: 'asc',
    selectedRegion: null,
    search: [],
    isLiveView: null,
  },
  action,
) => {
  switch (action.type) {
    case SORT_ASSETS:
      return Object.assign({}, state, {
        sortTitle: action.payload.title,
        sortDirection: action.payload.direction,
      })
    case SELECT_MAP_REGION:
      return Object.assign({}, state, { selectedRegion: action.payload })
    case 'ASSETS_SUCCESS':
      let localStorageView = localStorage.getItem('IS_LIVE_ASSET_VIEW')
      if (localStorageView !== null) {
        localStorageView = localStorage.getItem('IS_LIVE_ASSET_VIEW') === 'true'
      }
      let isLiveView = false
      const hasLiveAssets = action.payload.filter(asset => asset.data.is_live).length > 0
      const hasSimulatedAssets = action.payload.filter(asset => !asset.data.is_live).length > 0

      if (state.isLiveView !== null) {
        isLiveView = (state.isLiveView && hasLiveAssets) || !hasSimulatedAssets
      } else if (localStorageView !== null) {
        isLiveView = localStorageView
      } else {
        isLiveView = hasLiveAssets
      }

      localStorage.setItem('IS_LIVE_ASSET_VIEW', isLiveView)

      return {
        ...state,
        isLiveView,
      }
    case TOGGLE_LIVE_VIEW:
      return {
        ...state,
        isLiveView: action.payload,
      }
    default:
      return state
  }
}

// API reducers
const assets = makeResourceReducer(allAssets, [], true)
const assignableAssets = makeResourceReducer(allAssignableAssets, [])
const markets = makeResourceReducer(allMarkets, [])
const map = makeResourceReducer(mapInfo)

export default reduceReducers(
  combineReducers({
    assets,
    assignableAssets,
    view,
    markets,
    map,
  }),
)
