import _ from 'lodash'

export const ADDITION_TYPE = 'addition'
export const SUBTRACTION_TYPE = 'subtraction'

/**
 * Perform arithmetic function on two arrays. If any element is null, the resulting value is null
 *
 * @param {Array<number | null>} subjectNumberArr An array whose elements will be the subject of the arithmetic operation
 * @param {Array<number | null>} objectNumberArr The array whose elements will be the object of the arithmetic operation
 * @param {boolean} arithmeticType specify the type of arithmetic operation
 * @returns {Array<number | null>} The resulting array, length is limited by the subjectNumberArr
 */

export const performArrayArithmetic = (
  subjectNumberArr: Array<number | null>,
  objectNumberArr: Array<number | null>,
  arithmeticType: string,
): Array<number | null> => {
  return subjectNumberArr.map((subjectNumberVal, i) => {
    const objectNumberVal = _.get(objectNumberArr, i)
    if (_.isNil(objectNumberVal) || _.isNil(subjectNumberVal)) {
      return null
    }
    switch (arithmeticType) {
      case ADDITION_TYPE:
        return subjectNumberVal + objectNumberVal
      case SUBTRACTION_TYPE:
        return subjectNumberVal - objectNumberVal
      default:
        return null
    }
  })
}

/**
 * Perform arithmetic function on two array of arrays. If any element is null, the resulting value is null
 *
 * @param {Array<number | null>} subjectNumberArr An array of arrays whose elements will be the subject of the arithmetic operation
 * @param {Array<number | null>} objectNumberArr The array of arrays whose elements will be the object of the arithmetic operation
 * @param {string} arithmeticType specify the type of arithmetic operation
 * @returns {Array<Array<number | null>>} The resulting array, length is limited by the subjectNumberArr
 */

export const performArrayOfArraysArithmetic = (
  subjectNumberArrayOfArrays: Array<Array<number | null>>,
  objectNumberArrayOfArrays: Array<Array<number | null>>,
  arithmeticType: string,
): Array<Array<number | null>> => {
  const resultArrayOfArrays: Array<Array<number | null>> = []
  subjectNumberArrayOfArrays.forEach((subjectNumberArr: Array<number | null>, idx: any) => {
    const objectNumberArr = _.get(objectNumberArrayOfArrays, idx)
    const resArr = performArrayArithmetic(subjectNumberArr, objectNumberArr, arithmeticType)
    resultArrayOfArrays.push(resArr)
  })
  return resultArrayOfArrays
}

/**
 * Perform arithmetic operation on two objects that contain an array of arrays. If any element is null, the resulting value is null
 *
 * @param {Object} subjectNumberObject An object that contains an array of arrays whose elements will be the subject of the arithmetic operation
 * @param {Object} objectNumberObject An object that contains an array of arrays [[]] whose elements will be the object of the arithmetic operation
 * @param {Array<number | string> | string} arrKey The key that allows us to access the array of arrays in the object
 * @param {string} arithmeticType specify the type of arithmetic operation
 * @returns {Array<Array<number | null>>} The resulting array of arrays, length is limited by the subjectNumberArr
 */

export const performArrayOfArraysInObjectArithmetic = (
  subjectNumberObject: Object,
  objectNumberObject: Object,
  arrayKey: Array<number | string> | string,
  arithmeticType: string,
): Array<Array<number | null>> => {
  const subjectNumberArrayOfArrays = _.get(subjectNumberObject, arrayKey)
  const objectNumberArrayOfArrays = _.get(objectNumberObject, arrayKey)
  if (!_.isNil(subjectNumberArrayOfArrays)) {
    return performArrayOfArraysArithmetic(subjectNumberArrayOfArrays, objectNumberArrayOfArrays, arithmeticType)
  }
  return []
}

/**
 * Perform arithmetic operation on two objects that contain an array of <numbers | null>. If any element is null, the resulting value is null
 *
 * @param {Array<number | null>} subjectObject An object that contains an array of arrays whose elements will be the subject of the arithmetic operation
 * @param {Array<number | null>} objectObject An object that contains an array of arrays [[]] whose elements will be the object of the arithmetic operation
 * @param {Array<number | string> | string} arrayKey The key that allows us to access the array in the object
 * @param {string} arithmeticType specify the type of arithmetic operation
 * @returns {Array<number | null>} The resulting array of arrays, length is limited by the subjectNumberArr
 */

export const performArrayInObjectArithmetic = (
  subjectObject: Object,
  objectObject: Object,
  arrayKey: Array<number | string> | string,
  arithmeticType: string,
): Array<number | null> => {
  const subjectNumberArray = _.get(subjectObject, arrayKey)
  const objectNumberArray = _.get(objectObject, arrayKey)
  if (!_.isNil(subjectNumberArray)) {
    return performArrayArithmetic(subjectNumberArray, objectNumberArray, arithmeticType)
  }
  return []
}

/**
 * Perform arithmetic operation on two objects that contain an array of arrays. If any element is null, the result of the arithmetic operation is null
 *
 * @param {Array<number | null>} subjectObject An object that contains an array of arrays or array of values whose elements will be used as the subject of the arithmetic operation
 * @param {Array<number | null>} objectObject An object that contains an array of arrays or array of values [[]] whose elements will be used as the object of the arithmetic operation
 * @param {Array<number | string> | string} arrayKey The key that allows us to access the array in the object
 * @param {boolean} applyOnArrayOfArrays whether to perform arithmetic operation on an array of arrays
 * @param {string} arithmeticType specify the type of arithmetic operation
 * @returns {Array<number | null>} The resulting array of arrays, length is limited by the subjectNumberArr
 */

export const performObjectsWithArraysArithmetic = (
  subjectObject: Object,
  objectObject: Object,
  arrayKey: Array<number | string> | string,
  applyOnArrayOfArrays: boolean = false,
  arithmeticType: string,
): Object => {
  const tempObject = JSON.parse(JSON.stringify(subjectObject))
  if (applyOnArrayOfArrays) {
    return _.set(
      tempObject,
      arrayKey,
      performArrayOfArraysInObjectArithmetic(subjectObject, objectObject, arrayKey, arithmeticType),
    )
  } else {
    return _.set(
      tempObject,
      arrayKey,
      performArrayInObjectArithmetic(subjectObject, objectObject, arrayKey, arithmeticType),
    )
  }
}
