import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  formatMarketValue,
  getIndex,
  getTableTextColor,
  KW_TO_MW,
  marketsFix,
  perKWToPerMW,
  timeFormat,
  chartsTag,
  enablementTag,
  marketPriceTag,
  settlementTag,
} from '../../utility/utility'
import {
  extractActiveProductNamesFromList,
  getActiveProductTimeMapFromAsset,
  isProductNameActive,
} from '../../utility/asset-utils'

const DISPLAY_NAME = 'ResultTable'
const SCROLL_OFFSET_TOP = 160

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: 0,
      textTransform: 'uppercase',
      '& tbody:hover td[rowspan], tr:hover td': {
        backgroundColor: theme.palette.action.hover,
      },
      '& td': {
        padding: theme.spacing(0.75, 0.5),
      },
      '& th': {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
      },
      '& thead > tr:nth-child(1) > th:first-child': {
        paddingLeft: theme.spacing(2),
      },
    },
    noTextSelect: {
      userSelect: 'none',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    dualTypeHeaderTop: {
      borderBottom: 'none',
      paddingBottom: 0,
      lineHeight: '1rem',
    },
    singleTypeHeader: {
      padding: theme.spacing(1),
    },
    dualTypeHeaderBottom: {
      borderTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
    },
    stickyHeaderSecond: {
      position: 'sticky',
      top: 22,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
    },
    tableRowPast: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    tableRowCurrent: {
      color: '#75ACED',
    },
    tableRowFuture: {},
    zeroValue: {
      color: theme.palette.text.tertiary,
    },
    inheritColor: {
      color: 'inherit',
    },
  }),
  { name: DISPLAY_NAME },
)

const ResultTable = props => {
  const { asset, allProductData, selectedResultCategory, selectedDate, settlementIntervalByTime } = props
  const classes = useStyles()

  const bidInterval = _.get(asset, 'market.data.bid_interval')
  const isLoading = _.get(allProductData, 'isLoading')
  const intervals = _.get(allProductData, 'data', {})
  const currentIndex = getIndex(moment(), moment(selectedDate), moment(selectedDate).add(1, 'd'), bidInterval)
  const WINDOWS_PER_INTERVAL = settlementIntervalByTime === bidInterval ? 1 : 6

  const [shouldScroll, setShouldScroll] = useState(true)
  const tableContainerRef = useRef()
  const refsArray = useRef([])

  useEffect(() => {
    if (shouldScroll && !_.isNil(tableContainerRef) && _.get(refsArray, 'current.length', false)) {
      const offsetTop = _.get(refsArray, ['current', currentIndex, 'offsetTop'], 0)
      tableContainerRef.current.scrollTo(0, Math.max(offsetTop - SCROLL_OFFSET_TOP, 0))
      setShouldScroll(false)
    }
  }, [shouldScroll, tableContainerRef, currentIndex, isLoading, refsArray])

  const SettlementTotal = ({ asset, selectedDate }) => {
    const bidInterval = _.get(asset, 'market.data.bid_interval')
    const forecastStartIndex = getIndex(
      moment(intervals.forecastStartTime),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )

    const productSums = []
    const productTypeSum = []
    const productTypeSize = asset.productTypes.length
    _.range(0, forecastStartIndex).forEach(intervalIndex => {
      asset.productNames.forEach((productName, productNameIndex) => {
        asset.productTypes.forEach((productType, productTypeIndex) => {
          const productIndex = productTypeSize * productNameIndex + productTypeIndex
          let value = null
          if (!_.isEmpty(intervals) && (intervalIndex < forecastStartIndex || forecastStartIndex === -1)) {
            value = intervals.values[productIndex][intervalIndex]
          }
          productSums[productIndex] = (productSums[productIndex] || 0) + (value || 0)
          productTypeSum[productIndex % productTypeSize] =
            (productTypeSum[productIndex % productTypeSize] || 0) + (value || 0)
        })
      })
    })

    const className = 'finance-total-column'
    const empty = <TableCell key={0} style={{ borderBottom: '1px solid transparent' }} />
    const total = (
      <TableCell key={1} style={{ color: 'white' }} className={className}>
        Total
      </TableCell>
    )
    const productSumElements = productSums.map((sum, index) => (
      <TableCell key={index + 2} className={className} style={{ color: getTableTextColor(sum) }}>
        {formatMarketValue(sum, 1, 0, false, true)}
      </TableCell>
    ))
    const productTypeSumElements = productTypeSum.map((sum, index) => (
      <TableCell key={productSums.length + index + 2} className={className} style={{ color: getTableTextColor(sum) }}>
        {formatMarketValue(sum, 1, 0, false, true)}
      </TableCell>
    ))
    return (
      <TableBody>
        <TableRow>{_.concat(empty, total, productSumElements, productTypeSumElements)}</TableRow>
      </TableBody>
    )
  }

  const Rows = React.forwardRef((rowProps, ref) => {
    const { asset, selectedDate, currentIndex } = rowProps
    const bidInterval = _.get(asset, 'market.data.bid_interval')
    const endIndex = getIndex(
      moment(selectedDate).add(1, 'd').subtract(bidInterval, 'minutes'),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )
    const forecastStartIndex = getIndex(
      moment(intervals.forecastStartTime),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )
    const isValidIndex = currentIndex !== -1
    const numIntervals = (endIndex + 1) / WINDOWS_PER_INTERVAL

    return _.range(0, numIntervals).map(interval => (
      <TableBody key={interval}>
        {_.range(0, WINDOWS_PER_INTERVAL).map(intervalWindow => {
          const intervalIndex = interval * WINDOWS_PER_INTERVAL + intervalWindow
          return (
            <TableRow
              key={intervalIndex}
              className={clsx({
                [classes.tableRowPast]: isValidIndex && intervalIndex < currentIndex,
                [classes.tableRowCurrent]: isValidIndex && intervalIndex === currentIndex,
                [classes.tableRowFuture]: isValidIndex && intervalIndex > currentIndex,
              })}
              ref={ref => {
                refsArray.current[intervalIndex] = ref
              }}
            >
              <Row
                asset={asset}
                selectedResultCategory={selectedResultCategory}
                selectedDate={selectedDate}
                intervalIndex={intervalIndex}
                forecastStartIndex={forecastStartIndex}
                currentIndex={currentIndex}
              />
            </TableRow>
          )
        })}
      </TableBody>
    ))
  })

  const Row = ({ intervalIndex, forecastStartIndex, currentIndex, selectedDate }) => {
    const initial = []
    if (intervalIndex % WINDOWS_PER_INTERVAL === 0) {
      const intervalNumber = parseInt(intervalIndex / WINDOWS_PER_INTERVAL) + 1
      initial.push(
        <TableCell className={classes.inheritColor} align="center" key="interval" rowSpan={WINDOWS_PER_INTERVAL}>
          {intervalNumber}
        </TableCell>,
      )
    }

    const timezone = _.get(asset, 'market.data.timezone')
    const startTime = moment(selectedDate)
      .add(intervalIndex * bidInterval, 'minutes')
      .tz(timezone)
    const timeField = `${startTime.format(timeFormat)} - ${moment(startTime)
      .add(bidInterval, 'minutes')
      .format(timeFormat)}`
    const time = (
      <TableCell key="time" className={classes.inheritColor}>
        {timeField}
      </TableCell>
    )
    const formatData = getFormatData(selectedResultCategory)
    const typeSum = []
    const colSpan = asset.dualType ? formatData.colSpan : 1

    const values = []
    const activeProductTimeMap = getActiveProductTimeMapFromAsset(asset)

    asset.productNames.forEach((productName, productNameIndex) => {
      asset.productTypes.forEach((productType, productTypeIndex) => {
        // create only row column for price product (gen and load have same price)
        const isProductNameActiveWithSelectedTime = isProductNameActive(
          selectedDate,
          activeProductTimeMap,
          productName,
          productType,
        )
        if (
          isProductNameActiveWithSelectedTime &&
          (selectedResultCategory !== marketPriceTag || productTypeIndex === 0)
        ) {
          let value = null
          if (!_.isEmpty(intervals) && (intervalIndex < forecastStartIndex || forecastStartIndex === -1)) {
            const valueIndex =
              selectedResultCategory === marketPriceTag
                ? productNameIndex
                : asset.productTypes.length * productNameIndex + productTypeIndex
            value = intervals.values[valueIndex][intervalIndex]
          }
          const convertedValue = _.isNil(value) ? null : value * formatData.factor
          typeSum[productTypeIndex] = (typeSum[productTypeIndex] || 0) + (convertedValue || 0)
          const isDollar = selectedResultCategory !== enablementTag
          const displayValue = formatMarketValue(convertedValue, 1, marketsFix, false, isDollar)
          const isZero = displayValue === '0' || displayValue === '$0'
          values.push(
            <TableCell
              key={productName + productType}
              colSpan={colSpan}
              className={clsx({
                [classes.inheritColor]: intervalIndex === currentIndex,
                [classes.zeroValue]: isZero && intervalIndex !== currentIndex,
              })}
            >
              {displayValue}
            </TableCell>,
          )
        }
      })
    })

    let types = []
    if (props.selectedResultCategory === settlementTag) {
      types = typeSum.map((sum, index) => (
        <TableCell key={'sum' + index} style={{ color: getTableTextColor(sum) }}>
          {formatMarketValue(sum, 1, 0, false, true)}
        </TableCell>
      ))
    }
    return _.concat(initial, time, values, types)
  }

  const FirstHeader = ({ asset, selectedResultCategory, selectedDate }) => {
    const { noTextSelect, noWrap, singleTypeHeader, dualTypeHeaderTop, stickyHeader } = classes
    const className = clsx(stickyHeader, noTextSelect, noWrap, {
      [dualTypeHeaderTop]: asset.dualType,
      [singleTypeHeader]: !asset.dualType,
    })
    const singleType = clsx(stickyHeader, noTextSelect, noWrap, singleTypeHeader)
    const colSpan = asset.dualType ? 2 : 1
    const rowSpan = asset.dualType ? 2 : 1
    const activeProductNames = extractActiveProductNamesFromList(asset, selectedDate, asset.productNames)
    return (
      <TableRow>
        <TableCell key={0} className={singleType} rowSpan={rowSpan} />
        <TableCell key={1} className={singleType} rowSpan={rowSpan}>
          interval
        </TableCell>
        {activeProductNames.map((product, index) => (
          <TableCell colSpan={colSpan} key={index + 2} className={className}>
            {asset.market.data.product_display_names[product]}
          </TableCell>
        ))}
        {selectedResultCategory === settlementTag && (
          <TableCell
            colSpan={colSpan}
            key={asset.productNames.length + 2}
            className={className}
            style={{ color: 'white' }}
          >
            TOTAL
          </TableCell>
        )}
      </TableRow>
    )
  }

  const SecondHeader = ({ asset, selectedResultCategory, selectedDate }) => {
    const { noTextSelect, noWrap, dualTypeHeaderBottom, stickyHeaderSecond } = classes
    const className = clsx(stickyHeaderSecond, noTextSelect, noWrap, dualTypeHeaderBottom)
    const other = []
    let index = 2
    const isMarketTag = selectedResultCategory === marketPriceTag
    const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', [])
    const headers = asset.productTypes.map(type => (isMarketTag ? '' : productTypeDisplayNames[type]))
    const activeProductNames = extractActiveProductNamesFromList(asset, selectedDate, asset.productNames)
    activeProductNames.forEach(() => {
      headers.forEach(header => {
        other.push(
          <TableCell key={index++} className={className}>
            {header}
          </TableCell>,
        )
      })
    })

    if (props.selectedResultCategory === settlementTag) {
      headers.forEach(header => {
        other.push(
          <TableCell key={index++} className={className}>
            {isMarketTag ? '' : header}
          </TableCell>,
        )
      })
    }
    return <TableRow className={classes.table}>{other}</TableRow>
  }

  return (
    <Box className={classes.root} style={{ overflowX: 'auto' }} ref={tableContainerRef}>
      <Table className={classes.table} size="small" aria-label="market results table">
        <TableHead>
          <FirstHeader asset={asset} selectedResultCategory={selectedResultCategory} selectedDate={selectedDate} />
          {!!asset.dualType && (
            <SecondHeader asset={asset} selectedResultCategory={selectedResultCategory} selectedDate={selectedDate} />
          )}
        </TableHead>
        <Rows asset={asset} selectedDate={selectedDate} currentIndex={currentIndex} />
        {selectedResultCategory === settlementTag && <SettlementTotal asset={asset} selectedDate={selectedDate} />}
      </Table>
    </Box>
  )
}

export default ResultTable

const getFormatData = selectedResultCategory => {
  switch (selectedResultCategory) {
    case enablementTag:
      return {
        title: 'Enablements (MW)',
        fileName: 'Enablements',
        factor: KW_TO_MW,
        colSpan: 1,
      }
    case marketPriceTag:
      return {
        title: 'Market Price ($/MWh)',
        fileName: 'MarketPrices',
        factor: perKWToPerMW,
        colSpan: 2,
      }
    case settlementTag:
      return {
        title: 'Estimated Settlements ($)',
        fileName: 'Settlements',
        factor: 1,
        colSpan: 1,
      }
    case chartsTag:
      return {
        title: 'Charts',
      }
    default:
      return null
  }
}
