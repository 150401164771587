import React, { useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  allBidSpreadSettingsResource,
  allPriceForecastResource,
  bidSpreadSettingResource,
  priceForecastSettingResource,
  upsertAssetDataResource,
} from '../../redux/features/setting'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { BID_SPREAD_SETTING_TAG, DEFAULT_BID_SPREADING, FORECAST_SETTING_TAG } from '../../utility/constants'
import Card from '../Card'
import RouteLeavingGuard from '../RouteLeavingGuard'

const ORIGIN = {
  AEMO: 'AEMO',
  FLUENCE: 'Fluence',
}

const DEFAULT_PRICE_FORECAST_ERROR_PER_KWH = {
  default: 0.1,
  modified: 0,
}

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
    minHeight: theme.spacing(32),
    maxHeight: theme.spacing(32),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
  },
  activeItem: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  action: {
    paddingLeft: theme.spacing(0.5),
  },
  radio: {
    marginBottom: 0,
  },
  menuContainer: {
    minWidth: theme.spacing(10),
    maxWidth: theme.spacing(18),
    marginLeft: theme.spacing(4),
  },
  menu: {
    width: 200,
  },
}))

const quantiles = []
for (let i = 5; i <= 95; i = i + 5) {
  quantiles.push({ value: i / 100, label: `P${i}` })
}

const QUANTILE_ITEMS = [
  {
    value: 0.01,
    label: 'P1',
  },
  ..._.range(5, 100, 5).map(i => ({ value: i / 100, label: `P${i}` })),
  {
    value: 0.99,
    label: 'P99',
  },
].reverse()

const DEFAULT_STATE = {
  selectedOrigin: null,
  upperQuantile: DEFAULT_BID_SPREADING['upper_quantile'],
  medianQuantile: DEFAULT_BID_SPREADING['median_quantile'],
  lowerQuantile: DEFAULT_BID_SPREADING['lower_quantile'],
  priceForecastErrorPerKwh: DEFAULT_BID_SPREADING['price_forecast_error_per_kwh'],
}

const isDefault = (upper, lower) => {
  return upper === DEFAULT_BID_SPREADING['upper_quantile'] && lower === DEFAULT_BID_SPREADING['lower_quantile']
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return action.payload
    case 'setEqual': {
      const priceForecastErrorPerKwh = isDefault(action.payload, action.payload)
        ? DEFAULT_PRICE_FORECAST_ERROR_PER_KWH.default
        : DEFAULT_PRICE_FORECAST_ERROR_PER_KWH.modified
      return {
        ...state,
        upperQuantile: action.payload,
        lowerQuantile: action.payload,
        medianQuantile: action.payload,
        priceForecastErrorPerKwh,
      }
    }
    case 'setSelectedOrigin':
      return {
        ...state,
        selectedOrigin: action.payload,
      }
    case 'setUpperQuantile': {
      const priceForecastErrorPerKwh = isDefault(action.payload, state.lowerQuantile) ? 0.01 : 0
      return {
        ...state,
        upperQuantile: action.payload,
        medianQuantile: _.round((state.lowerQuantile + action.payload) / 2, 2),
        priceForecastErrorPerKwh,
      }
    }
    case 'setLowerQuantile': {
      const priceForecastErrorPerKwh = isDefault(state.upperQuantile, action.payload) ? 0.01 : 0
      return {
        ...state,
        lowerQuantile: action.payload,
        medianQuantile: _.round((state.upperQuantile + action.payload) / 2, 2),
        priceForecastErrorPerKwh,
      }
    }
    case 'reset':
      return DEFAULT_STATE
    default:
      return state
  }
}

function PriceForecastCard(props) {
  const { asset, bidSpreadSetting, permissions, priceForecastOriginData } = props
  const {
    disablePriceForecastModel,
    dispatchCreateAssetData,
    dispatchGetAllPriceForecastRecords,
    dispatchGetBidSpread,
    getCurrentPriceForecastSetting,
  } = props
  const { notifySuccess, notifyError, notifyWarning } = useNotifier()

  const classes = useStyles()

  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE)

  const useAemoPreDispatch = _.get(priceForecastOriginData, 'payload.data.use_aemo_pre_dispatch', false)
  const priceForecastOrigin = getOriginValue(useAemoPreDispatch)

  const [unchangedForm, setUnchangedForm] = useState(DEFAULT_STATE)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  const assetId = _.get(asset, 'assetId')

  const originModified = unchangedForm.selectedOrigin !== state.selectedOrigin
  const bidSpreadModified =
    unchangedForm.upperQuantile !== state.upperQuantile || unchangedForm.lowerQuantile !== state.lowerQuantile
  const modified = originModified || bidSpreadModified

  useEffect(() => {
    if (!_.isNil(assetId) && _.isFunction(getCurrentPriceForecastSetting)) {
      setIsInitialized(false)
      const priceForecastSettingPromise = getCurrentPriceForecastSetting(assetId, moment()).then(
        priceForecastResponse => {
          dispatch({ type: 'setSelectedOrigin', payload: null })
          return priceForecastResponse
        },
      )
      const getBidSpreadPromise = dispatchGetBidSpread(assetId, moment())
      Promise.all([priceForecastSettingPromise, getBidSpreadPromise]).then(
        ([priceForecastResponse, bidSpreadResponse]) => {
          if (priceForecastResponse.error || bidSpreadResponse.error) {
            notifyWarning('Unable to retrieve Price Forecast Setting')
          } else {
            const useAemoPreDispatch = _.get(priceForecastResponse, 'payload.data.use_aemo_pre_dispatch', false)
            if (!useAemoPreDispatch && _.isEmpty(_.get(bidSpreadResponse, 'payload'))) {
              notifyWarning('Upper and Lower forecast quantiles not specified')
            }
          }
          setIsInitialized(true)
        },
      )
    }
  }, [assetId, dispatchGetBidSpread, getCurrentPriceForecastSetting, notifyWarning])

  useEffect(() => {
    const updateSelectedOrigin = _.isNil(state.selectedOrigin) && state.selectedOrigin !== priceForecastOrigin
    if (isInitialized && updateSelectedOrigin) {
      const form = {
        selectedOrigin: priceForecastOrigin,
        upperQuantile: _.get(bidSpreadSetting, 'payload.data.upper_quantile'),
        medianQuantile: _.get(bidSpreadSetting, 'payload.data.median_quantile'),
        lowerQuantile: _.get(bidSpreadSetting, 'payload.data.lower_quantile'),
        priceForecastErrorPerKwh: _.get(bidSpreadSetting, 'payload.data.price_forecast_error_per_kwh'),
      }
      setUnchangedForm(form)
      dispatch({ type: 'set', payload: form })
    }
  }, [bidSpreadSetting, isInitialized, priceForecastOrigin, state.selectedOrigin])

  const handleChange = event => {
    const newValue = _.get(event, 'target.value')
    dispatch({ type: 'setSelectedOrigin', payload: newValue })
  }

  const handleSubmit = () => {
    if (modified) {
      onSave()
    }
  }

  const onSave = () => {
    if (!modified) {
      return Promise.reject(new Error('Error: Nothing to save'))
    }
    const priceForecastOriginSetting = _.get(priceForecastOriginData, 'payload.data', {})
    const assetData = {
      ...priceForecastOriginSetting,
      'use_aemo_pre_dispatch': state.selectedOrigin === 'AEMO',
    }
    const bidSpread = {
      lower_quantile: state.lowerQuantile,
      median_quantile: state.medianQuantile,
      price_forecast_error_per_kwh: state.priceForecastErrorPerKwh,
      upper_quantile: state.upperQuantile,
    }

    setSaveInProgress(true)
    const now = moment()
    const createForecastPromise = originModified
      ? dispatchCreateAssetData(asset.assetId, FORECAST_SETTING_TAG, moment(now), assetData)
      : Promise.resolve()
    const createBidSpreadPromise = bidSpreadModified
      ? dispatchCreateAssetData(asset.assetId, BID_SPREAD_SETTING_TAG, moment(now), bidSpread)
      : Promise.resolve()
    return Promise.all([createForecastPromise, createBidSpreadPromise]).then(responses => {
      if (responses.some(response => _.get(response, 'error'))) {
        notifyError('Something went wrong. Please try again')
        setSaveInProgress(false)
        return 'Error creating assetData'
      } else {
        // Update risk log
        dispatchGetAllPriceForecastRecords(asset)

        const getBidSpreadPromise = dispatchGetBidSpread(assetId, moment())
        const getCurrentPriceForecastPromise = getCurrentPriceForecastSetting(asset.assetId, moment())
        return Promise.all([getBidSpreadPromise, getCurrentPriceForecastPromise]).then(responses => {
          setSaveInProgress(false)
          if (responses.some(response => _.get(response, 'error'))) {
            notifyError('Unable to retrieve updated values')
            return 'Error requsting updated price forecast setting'
          }
          dispatch({ type: 'reset' })
          notifySuccess(`Price Forecast Model saved successfully`)
          return responses
        })
      }
    })
  }

  const handleQuantileOnChange = quantile => e => {
    let type = quantile
    const upper = quantile === 'setUpperQuantile' ? e.target.value : state.upperQuantile
    const lower = quantile === 'setLowerQuantile' ? e.target.value : state.lowerQuantile
    if (upper < lower) {
      type = 'setEqual'
    }
    dispatch({
      type,
      payload: e.target.value,
    })
  }

  const showLoading = !saveInProgress && !isInitialized
  return (
    <>
      <RouteLeavingGuard description="Price Forecast Model" when={modified} />
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
          action: classes.action,
        }}
        title="Price Forecast Model"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          canAdjustTradeSettings && (
            <Button variant="text" onClick={handleSubmit} disabled={!modified || saveInProgress}>
              SAVE
            </Button>
          )
        }
        inProgress={!isInitialized}
      >
        <Grid container className={classes.activeItem} direction="column">
          <Typography variant="body1" color="textSecondary">
            Price forecast used in bidding strategy
          </Typography>
          {showLoading ? (
            <Box position="relative" p={3.5}>
              <Box position="absolute" zIndex="100" left="calc( 50% - 20px )" top="40%">
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <Box pt={1} display="flex" justifyContent="flex-start">
              <RadioGroup
                aria-label="forecast-option"
                value={_.isNil(state.selectedOrigin) ? '' : state.selectedOrigin}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={ORIGIN.FLUENCE}
                  control={<Radio color="primary" />}
                  label="Fluence ML Forecast"
                  className={classes.radio}
                  disabled={disablePriceForecastModel || !isInitialized || saveInProgress}
                />
                <Box mb={2} display="flex" flexDirection="column">
                  <TextField
                    className={classes.menuContainer}
                    select
                    name="upper"
                    label="Upper"
                    value={state.upperQuantile}
                    onChange={handleQuantileOnChange('setUpperQuantile')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    disabled={disablePriceForecastModel || state.selectedOrigin !== ORIGIN.FLUENCE || saveInProgress}
                  >
                    {QUANTILE_ITEMS.map((option, index) => {
                      const isSaved = option.value === unchangedForm.upperQuantile
                      return (
                        <MenuItem
                          key={index}
                          value={option.value}
                          style={{ fontSize: isSaved ? 20 : 14, fontWeight: isSaved ? 600 : 400 }}
                        >
                          {option.label}
                          {option.value === DEFAULT_BID_SPREADING.upper_quantile && ' - Default'}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                  <TextField
                    className={classes.menuContainer}
                    select
                    name="lower"
                    label="Lower"
                    value={state.lowerQuantile}
                    onChange={handleQuantileOnChange('setLowerQuantile')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'right',
                        },
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    disabled={disablePriceForecastModel || state.selectedOrigin !== ORIGIN.FLUENCE || saveInProgress}
                  >
                    {QUANTILE_ITEMS.map((option, index) => {
                      const isSaved = option.value === unchangedForm.lowerQuantile
                      return (
                        <MenuItem
                          key={index}
                          value={option.value}
                          style={{ fontSize: isSaved ? 20 : 14, fontWeight: isSaved ? 600 : 400 }}
                        >
                          {option.label}
                          {option.value === DEFAULT_BID_SPREADING.lower_quantile && ' - Default'}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Box>
                <FormControlLabel
                  value="AEMO"
                  control={<Radio color="primary" />}
                  label="AEMO Pre-dispatch"
                  className={classes.radio}
                  disabled={!isInitialized || saveInProgress}
                />
              </RadioGroup>
            </Box>
          )}
        </Grid>
      </Card>
    </>
  )
}

const mapStateToProps = () => {
  return state => ({
    bidSpreadSetting: _.get(state, 'setting.bidSpreadSetting'),
    permissions: currentUserPermissionsSelector(state),
    priceForecastOriginData: _.get(state, 'setting.priceForecast'),
    // TODO: Remove once sondes is permanently deployed
    disablePriceForecastModel: _.get(state, 'globalApp.marketData.disable_price_forecast_model'),
  })
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetAllPriceForecastRecords: asset => {
      const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        const bidSpreadPromise = dispatch(allBidSpreadSettingsResource.get(asset.assetId, startTime, moment()))
        const bidStrategyPromise = dispatch(allPriceForecastResource.get(asset.assetId, startTime, moment()))
        return Promise.all([bidStrategyPromise, bidSpreadPromise])
      } else {
        return Promise.reject(new Error('Unable to update price forecast records'))
      }
    },
    getCurrentPriceForecastSetting: (assetId, asOf) => dispatch(priceForecastSettingResource.get(assetId, asOf)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchGetBidSpread: (assetId, asOf) => dispatch(bidSpreadSettingResource.get(assetId, asOf)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceForecastCard)

const getOriginValue = useAemoPreDispatch => {
  if (useAemoPreDispatch) {
    return ORIGIN.AEMO
  } else {
    return ORIGIN.FLUENCE
  }
}
