import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, Grid, makeStyles } from '@material-ui/core'
import KeyboardDatePicker from '../components/KeyboardDatePicker'
import { getTradingStart } from '../utility/utility'
import Details from '../components/asset-operations/Details'
import AssetOperationsChartCard from '../components/asset-operations/AssetOperationsChartCard'
import WarrantyDetailsCard from '../components/asset-operations/WarrantyDetailsCard'
import TrackingCard from '../components/asset-operations/TrackingCard'

const DISPLAY_NAME = 'AssetOperations'

const useStyles = makeStyles(
  theme => ({
    datePickerBox: {
      maxHeight: theme.spacing(6),
      paddingBottom: theme.spacing(1.5),
    },
  }),
  { name: DISPLAY_NAME },
)

const AssetOperations = props => {
  const { asset } = props
  const classes = useStyles()

  const assetId = _.get(asset, 'assetId')
  const market = _.get(asset, 'market')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const marketTimezone = _.get(market, 'data.timezone')
  moment.tz.setDefault(marketTimezone)

  const [selectedTime, setSelectedTime] = useState({
    start: null,
    end: null,
  })

  const handleChangeDatePicker = date => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    setSelectedTime({
      start: newDate,
      end: getTradingStart(market, newDate).add(1, 'days'),
    })
  }

  useEffect(() => {
    if (!_.isNil(market) && _.isNil(selectedTime.start)) {
      setSelectedTime({
        start: getTradingStart(market),
        end: getTradingStart(market).add(1, 'days'),
      })
    }
  }, [market, selectedTime, selectedTime.start])

  const minDate = moment().subtract(3, 'years')
  const maxDate = getTradingStart(market).add(1, 'days')

  return (
    <>
      <Box className={classes.datePickerBox}>
        <KeyboardDatePicker
          timezone={marketTimezone}
          marketStartHour={marketStartHour}
          minDate={minDate}
          maxDate={maxDate}
          selectedDate={selectedTime.start}
          onChange={handleChangeDatePicker}
          ariaLabel="change market prices start date"
        />
      </Box>
      {asset.data.tag_prefix === 'battery' ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AssetOperationsChartCard asset={asset} selectedRange={selectedTime} />
          </Grid>
          <Grid item xs={12}>
            <TrackingCard asset={asset} startTime={selectedTime.start} endTime={selectedTime.end} />
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <WarrantyDetailsCard assetId={assetId} startTime={selectedTime.start} endTime={selectedTime.end} />
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={10}>
            <Details asset={asset} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Box clone order={{ xs: 3, md: 2 }}>
            <Grid item xs={12} md={3} xl={2}>
              <Details asset={asset} />
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, md: 3 }}>
            <Grid item xs={12} md={9} xl={10}>
              <AssetOperationsChartCard asset={asset} selectedRange={selectedTime} />
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  )
}

AssetOperations.displayName = DISPLAY_NAME

export default AssetOperations
