import PropTypes from 'prop-types'

const MarketAssetProductData = {
  getLatestAssetProductData: {
    name: 'getLatestAssetProductData',
    method: 'GET',
    url: ({ assetId, productId }) => `/market/assets/asset/${assetId}/product/${productId}/assetProductData/last`,
    pathParams: { assetId: PropTypes.number, productId: PropTypes.number },
    queryParams: {
      'tag': PropTypes.string.isRequired,
      'startTime': PropTypes.string.isRequired,
      'asOf': PropTypes.string,
      'deleted': PropTypes.bool.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getAssetProductDataRange: {
    name: 'getAssetProductDataRange',
    method: 'GET',
    url: ({ assetId, productId }) => `/market/assets/asset/${assetId}/product/${productId}/assetProductData/range`,
    pathParams: { assetId: PropTypes.number, productId: PropTypes.number },
    queryParams: {
      'tag': PropTypes.string.isRequired,
      'startTime': PropTypes.string.isRequired,
      'endTime': PropTypes.string.isRequired,
      'asOf': PropTypes.string,
      'deleted': PropTypes.bool.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  createAssetProductData: {
    name: 'createAssetProductData',
    method: 'POST',
    url: () => `/market/assets/assetProductData`,
    pathParams: {},
    queryParams: {},
    body: {},
    headers: { 'Content-Type': 'application/json' },
  },
  deleteAssetProductData: {
    name: 'deleteAssetProductData',
    method: 'PUT',
    url: () => `/market/assets/assetProductData/delete`,
    pathParams: {},
    queryParams: {
      'assetProductDataId': PropTypes.number.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketAssetProductData
