import React from 'react'
import Toggle from '../Toggle'

const DISPLAY_NAME = 'ProductTypeToggle'

function ProductTypeToggle(props) {
  const {
    disabledTypes = [],
    selected,
    onChange,
    productTypes = [],
    productTypeDisplayNames = {},
    variant = 'primary',
    disabled = false,
    highlightedProductTypeNames = [],
  } = props

  const options = productTypes.map(type => {
    return {
      text: productTypeDisplayNames[type],
      key: type,
      toolTip: `Product Type: ${productTypeDisplayNames[type]}`,
      disabled: disabledTypes.includes(type),
    }
  })

  return (
    <Toggle
      disabled={disabled}
      values={options}
      selected={selected}
      onChange={onChange}
      variant={variant}
      highlightedItems={highlightedProductTypeNames}
    />
  )
}
ProductTypeToggle.displayName = DISPLAY_NAME

export default React.memo(ProductTypeToggle)
