import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import GraphImpl from './GraphImpl'

const GraphWrapper = React.memo(props => {
  const { range, context, currentTime, displayRange, getData, inProgress } = props
  const start = _.get(range, 'start')
  const end = _.get(range, 'end')

  const [state, setState] = useState({
    data: null,
    isLoading: true,
  })
  const lastInProgress = useRef(inProgress)

  const contextSerialized = _.isObject(context) ? JSON.stringify(context) : '{}'
  useEffect(() => {
    const context = JSON.parse(contextSerialized)
    const repopulateData = async (range, getData, context) => {
      const data = await getData({ ...context, range })
      setState({
        data,
        isLoading: _.isNil(data),
      })
    }

    if (!inProgress) {
      setState(prev => ({ ...prev, isLoading: true }))
      repopulateData({ start, end }, getData, context)
    }
  }, [start, end, currentTime, getData, contextSerialized, inProgress])

  const showInProgress = state.isLoading || inProgress || lastInProgress.current
  lastInProgress.current = inProgress
  return (
    <GraphImpl {...props} data={state.data} range={displayRange} onRef={props.onRef} showInProgress={showInProgress} />
  )
})
GraphWrapper.displayName = 'GraphWrapper'

export default GraphWrapper
