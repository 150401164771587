import types from './types'

export const selectAssetId = assetId => ({
  type: types.SELECT_ASSET_ID.root,
  payload: assetId,
})

export const updateRiskSetting = (assetId, riskSetting) => ({
  type: types.AF_UPDATE_RISK.root,
  payload: {
    assetId,
    riskSetting,
  },
})

export const updateAssetData = (assetId, property, data) => ({
  type: types.AF_UPDATE_ASSETDATA.root,
  payload: {
    assetId,
    property,
    data,
  },
})

const actions = {
  selectAssetId,
  updateAssetData,
  updateRiskSetting,
}

export default actions
