import _ from 'lodash'
import moment from 'moment-timezone'
import { custom as customTheme } from '../theme/theme'
import { colors, dateTimeFormat, dateTimeFormatWithSeconds, dayOfWeekFormat, KW_TO_MW, perKWToPerMW } from './utility'
import { setRgbaValue } from './color.js'
import { MINUTES_IN_HOUR, TRADING_DAY_START_HOUR } from './constants'

const { monospaceFontFamily } = _.get(customTheme, 'palette.graphs')
const noDataText = 'No Data'

let CanvasJS = require('../assets/scripts/canvasjs.min')
CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS

export const stripLineTemplate = {
  labelPlacement: 'outside',
  color: 'white',
  thickness: 0.8,
  labelBackgroundColor: 'transparent',
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 9,
  labelWrap: false,
  labelMaxWidth: 100,
  labelFontColor: 'white',
}

export const stateOfEnergyGraphTemplate = {
  name: 'State of Energy',
  height: 250,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 3600,
    },
    axisY: {
      margin: -15,
      minimum: 0,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '#,##0.## MWh').padStart(12),
    },
  },
  seriesOption: {
    color: colors[0],
    factor: KW_TO_MW,
    visible: true,
    yValueFormatString: '#,##0.## MWh',
    type: 'stackedArea',
    dataPoints: [],
    fillOpacity: 0.5,
    lineThickness: 1.3,
    markerSize: 0,
  },
  series: [],
}

export const powerGraphTemplate = {
  name: 'Power',
  height: 250,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 3600,
    },
    axisY: {
      margin: -15,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '#,##0.## MW').padStart(12),
    },
  },
  seriesOption: {
    color: colors[3],
    factor: KW_TO_MW,
    visible: true,
    yValueFormatString: '#,##0.## MW',
    type: 'line',
    dataPoints: [],
    lineThickness: 1.3,
    markerSize: 0,
  },
  series: [],
}

export const outagePowerTemplate = {
  name: 'Outage',
  height: 250,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 604800,
      gridThickness: 0,
    },
    axisY: {
      gridThickness: 0,
      margin: -15,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '#,##0.## MWh').padStart(12),
      minimum: 0,
    },
  },
  seriesOption: {
    factor: KW_TO_MW,
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '#,##0.## MW',
    type: 'line',
    dataPoints: [],
    lineThickness: 1.3,
    markerSize: 0,
  },
  series: [],
}

export const outageEnergyTemplate = {
  name: 'State of Energy',
  height: 250,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 604800,
      gridThickness: 0,
    },
    axisY: {
      gridThickness: 0,
      margin: -15,
      minimum: 0,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '#,##0.## MWh').padStart(12),
    },
  },
  seriesOption: {
    factor: KW_TO_MW,
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '#,##0.## MWh',
    type: 'line',
    dataPoints: [],
    lineThickness: 1.3,
    markerSize: 0,
  },
  series: [],
}

export const priceGraphTemplate = {
  name: 'Market Prices',
  height: 200,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 3600,
    },
    axisY: {
      margin: -25,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '$#,##0.##/MWh').padStart(15),
    },
  },
  seriesOption: {
    factor: perKWToPerMW,
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '$#,##0.##/MWh',
    type: 'line',
    dataPoints: [],
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const enablementGraphTemplate = {
  name: 'Enablements',
  height: 200,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      interval: 3600,
    },
    axisY: {
      margin: -25,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '#,##0.## MW').padStart(15),
    },
  },
  seriesOption: {
    factor: KW_TO_MW,
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '#,##0.## MW',
    type: 'stackedArea',
    dataPoints: [],
    fillOpacity: 0.4,
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const settlementGraphTemplate = {
  name: 'Settlements',
  height: 200,
  options: {
    toolTip: {},
    zoomEnabled: true,
    axisX: {
      gridThickness: 0,
      interval: 3600,
    },
    axisY: {
      gridThickness: 0,
      margin: -25,
      labelFormatter: e => '.' + CanvasJS.formatNumber(e.value, '$#,##0').padStart(15),
    },
  },
  seriesOption: {
    type: 'stackedColumn',
    showInLegend: false,
    factor: 1,
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '$#,##0',
    dataPoints: [],
    fillOpacity: 0.9,
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const settlementSummaryGraphTemplate = {
  name: 'Settlements-Groups',
  key: 'settlementSummaryGraph',
  height: 250,
  options: {
    dataPointWidth: 180,
    toolTip: { shared: false },
    zoomEnabled: false,
    axisX: {
      margin: 10,
      gridThickness: 0,
      labelFormatter: () => '',
    },
    axisY: {
      gridThickness: 0,
      tickLength: 7,
    },
  },
  seriesOption: {
    type: 'stackedBar',
    showInLegend: false,
    canToggle: false,
    legend: false,
    visible: true,
    yValueFormatString: '$#,##0',
    dataPoints: [],
    fillOpacity: 0.9,
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const financeStackedBar = {
  name: 'Asset Settlements',
  key: 'assets-settlements',
  height: 250,
  options: {
    toolTip: { shared: false },
    zoomEnabled: false,
    axisX: {
      margin: 10,
      gridThickness: 0,
    },
    axisY: {
      gridThickness: 0,
      tickLength: 7,
    },
  },
  seriesOption: {
    type: 'stackedBar',
    canToggle: true,
    legend: true,
    visible: true,
    yValueFormatString: '$#,##0',
    dataPoints: [],
    fillOpacity: 0.9,
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const financeRangeGraphTemplate = {
  key: 'financeRangeGraph',
  height: 292,
  options: {
    toolTip: {},
    zoomEnabled: false,
    axisX: {
      margin: 10,
      gridThickness: 0,
    },
    axisY: {
      gridThickness: 0,
      labelFormatter: () => '',
    },
  },
  seriesOption: {
    type: 'rangeBar',
    showInLegend: false,
    canToggle: false,
    legend: false,
    visible: true,
    yValueFormatString: '$#,##0',
    dataPoints: [],
    fillOpacity: 1,
    lineThickness: 1,
    markerSize: 0,
  },
  series: [],
}

export const bidGraphTemplate = {
  key: `bids-graph`,
  height: 150,
  options: {
    toolTip: {
      contentFormatter: e => bidTooltipContentFormatter(e),
      backgroundColor: 'transparent',
    },
    zoomEnabled: false,
    showTooltipTotal: false,
    axisX: {
      title: 'MW',
      margin: -5,
      labelFontSize: 9,
      titleFontSize: 10,
      valueFormatString: '#,##0',
      minimum: 0,
      gridThickness: 0,
      lineThickness: 0.8,
      lineDashType: 'dash',
      lineColor: 'white',
    },
    axisY: {
      title: '$/MW',
      margin: -1,
      labelFontSize: 9,
      titleFontSize: 10,
      valueFormatString: '#,##0',
      gridThickness: 0,
      labelFormatter: () => ' ',
      stripLines: [
        {
          ...stripLineTemplate,
          thickness: 0,
        },
        {
          ...stripLineTemplate,
        },
      ],
    },
  },
  series: [
    {
      options: {
        xValueFormatString: '#,##0MW',
        yValueFormatString: '$#,##0/MWh',
        name: 'Bid',
        type: 'stepLine',
        markerSize: 0,
        lineThickness: 1.3,
      },
    },
  ],
}

const bidTooltipContentFormatter = e => {
  const entry = e.entries[0]
  const priceBand = _.isFinite(entry.dataPoint.y)
    ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
    : 'No Data'
  const bid = _.isFinite(entry.dataPoint.x)
    ? CanvasJS.formatNumber(entry.dataPoint.x, entry.dataSeries.xValueFormatString)
    : 'No Data'
  return `<div>
            <span style='color: #808697'>${'PriceBand'}:</span>
            <span style='color: ${entry.dataSeries.color};'>${priceBand}</span>
          </div>
          <div>
            <span style='color: #808697'>${'Cummulative bid'}:</span>
            <span style='color: ${entry.dataSeries.color};'>${bid}</span>
          </div>`
}

export const settlementTooltipContentFormatter = (start, end) => e => {
  const title =
    !!start && !!end
      ? `<h6 class='mt-xs mb-none'>${start.format(dateTimeFormat)} to ${end.format(dateTimeFormat)} </h6>`
      : ''
  const entry = e.entries[0]
  const formattedValue = _.isFinite(entry.dataPoint.y)
    ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
    : noDataText
  const series = `<div><span style='color: #808697'>${entry.dataSeries.name}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>`
  return title + series
}

export const financeSummaryTooltipContentFormatter = (start, end) => e => {
  const title =
    !!start && !!end
      ? `<h6 class='mt-xs mb-none'>${start.format(dateTimeFormat)} to ${end.format(dateTimeFormat)} </h6>`
      : ''
  const entry = e.entries[0]
  const totalSeries = entry.dataSeries.dataPoints.find(series => series.label === 'Total')
  const value = entry.dataPoint.y[1] - entry.dataPoint.y[0]
  const total = totalSeries.y[1] - totalSeries.y[0]
  const percent = _.isFinite(value) && _.isFinite(total) && total > 0 ? ((value * 100) / total).toFixed(2) + '%' : ''
  const formattedValue = _.isFinite(value)
    ? CanvasJS.formatNumber(value, entry.dataSeries.yValueFormatString)
    : noDataText
  const series = `<div><span style='color: #808697'>${
    entry.dataSeries.name || entry.dataPoint.label
  }:</span> <span style='color: ${entry.dataSeries.color || entry.dataPoint.color};'>${
    formattedValue + (percent === '' ? ' ' : ` (${percent})`)
  }</span></div>`
  return title + series
}

export const futuresTooltipContentFormatter = (name, timezone, forecastStart) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const tradingDateTime =
    time.hours() * MINUTES_IN_HOUR + time.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(time).subtract(1, 'days')
      : time
  const title = `<h3 style="color: white; margin: 0;">${tradingDateTime.format(dateTimeFormatWithSeconds)}</h3>`
  const subtitle = `<div style='color: white; margin-bottom: 5px'>${time.format(dayOfWeekFormat)}</div>`
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  const series = visibleSeries.reduce((output, entry) => {
    // hack so that the extra dataPoint in actual market demand is not shown in tooltip.
    if (time.isSame(forecastStart) && name === 'Market Demand' && entry.dataSeries.name === 'Energy') {
      return output
    }

    const displayName = time.isSameOrAfter(forecastStart) ? entry.dataSeries.name : entry.dataSeries.name.split(' ')[0]
    const formattedValue = _.isFinite(entry.dataPoint.y)
      ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
      : 'No Data'
    return (
      `<div><span style='color: rgba(255,255,255,0.5)'>${displayName}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>` +
      output
    )
  }, '')
  return title + subtitle + series
}

export const priceForecastContentFormatter = (
  name,
  timezone,
  forecastStart,
  products,
  actualTypes,
  forecastTypes,
  colors,
  productSuffice,
  actualSeriesName,
) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const graphTitle = `<h6 class='mt-xs mb-none'>${name + productSuffice}</h6>`
  const title = `<p class='mb-none text-xs' style='color: #808697'>${time.format(dateTimeFormatWithSeconds)}</p>`
  const subtitle = `<p class='mt-neg-md mb-none text-xs' style='color: #808697'>${time.format(dayOfWeekFormat)}</p>`
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  const productValues = {}
  const types = !time.isSameOrAfter(forecastStart) ? actualTypes : forecastTypes
  products.forEach(product => (productValues[product] = types.map(() => null)))
  let seriesOutput = ''

  visibleSeries.forEach(entry => {
    const seriesName = entry.dataSeries.name
    const displayName = entry.dataSeries.name.split(' ')[0]

    // do not use table tooltip, if only one data point
    if (types.length === 1) {
      const formattedValue = _.isFinite(entry.dataPoint.y)
        ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
        : 'No Data'
      seriesOutput =
        `<div><span style='color: #808697'>${displayName}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>` +
        seriesOutput

      // if multiple datapointss, add it to productValues, which will be used to create tooltip table
    } else {
      const formattedValue =
        _.isFinite(entry.dataPoint.y) || !_.isNil(entry.dataPoint.y)
          ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
          : 'No Data'
      const typeIndex = types.findIndex(type =>
        type === 'ACTUAL' ? seriesName.includes(actualSeriesName) : seriesName.includes(type),
      )
      productValues[displayName][typeIndex] = formattedValue
    }
  })

  // use productValues to create table if needed
  if (seriesOutput === '') {
    let table = "<table><thead><tr style='color: #808697'><th class='pr-xs'>Product</th>"
    types.forEach(type => (table += `<th class='pr-xs'>${type}</th>`))
    table += '</tr></thead><tbody>'

    products.forEach(product => {
      if (!productValues[product].every(value => _.isNil(value))) {
        table += `<tr style='color: ${
          colors[product.toUpperCase()]
        }'><th class='pr-xs' style='color: #808697'>${product}</th>`
        productValues[product].forEach((value, idx) => {
          let color =
            _.get(types, idx) === 'INTERVENTION'
              ? setRgbaValue(colors[product.toUpperCase()], 'a', 0.5)
              : colors[product.toUpperCase()]
          table += `<th style='color: ${color}' class='pr-xs'>${_.isNil(value) ? 'No data' : value}</th>`
        })
        table += '</tr>'
      }
    })

    seriesOutput = table + '</tbody></table>'
  }
  return graphTitle + title + subtitle + seriesOutput
}

export const forecastTooltipContentFormatter = (name, timezone) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const graphTitle = `<h6 class='mt-xs mb-none'>${name}</h6>`
  const title = `<p class='mb-none text-xs' style='color: #808697'>${time.format(dateTimeFormatWithSeconds)}</p>`
  const subtitle = `<p class='mt-neg-md mb-none text-xs' style='color: #808697'>${time.format(dayOfWeekFormat)}</p>`
  const seriesToolTip = {}
  e.entries
    .filter(entry => entry.dataSeries.visible)
    .forEach(entry => {
      // if line is a range chart
      if (_.isArray(entry.dataPoint.y)) {
        const lines = entry.dataSeries.name.split(',')
        lines.forEach((line, index) => {
          const val = lines.length === 1 ? entry.dataPoint.y[1] - entry.dataPoint.y[0] : entry.dataPoint.y[index]
          const formattedValue = _.isFinite(val)
            ? CanvasJS.formatNumber(val, entry.dataSeries.yValueFormatString)
            : 'No Data'
          seriesToolTip[
            line
          ] = `<div><span style='color: #808697'>${line}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>`
        })
      } else {
        const formattedValue = _.isFinite(entry.dataPoint.y)
          ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
          : 'No Data'
        seriesToolTip[
          entry.dataSeries.name
        ] = `<div><span style='color: #808697'>${entry.dataSeries.name}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>`
      }
    })
  const displayOrder = ['Actual', 'AEMO Pre-Dispatch', 'Fluence P90', 'Fluence P50', 'Fluence P10']
  const series = displayOrder.reduce(
    (output, series) => (seriesToolTip.hasOwnProperty(series) ? output + seriesToolTip[series] : output),
    '',
  )
  return graphTitle + title + subtitle + series
}

export const tooltipContentFormatter = (name, timezone) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const tradingDateTime =
    time.hours() * MINUTES_IN_HOUR + time.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(time).subtract(1, 'days')
      : time
  const title = `<p class='mb-none text-xs' style='color: white; font-size: 13px; font-weight: 800; margin-bottom: -10px; margin-top: 2px'>${tradingDateTime.format(
    dateTimeFormatWithSeconds,
  )}</p>`
  const subtitle = `<p class='mt-neg-md mb-none text-xs' style='color: white; margin-bottom: 4px;'>${time.format(
    dayOfWeekFormat,
  )}</p>`
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  const series = visibleSeries.reduce((output, entry) => {
    const formattedValue = _.isFinite(entry.dataPoint.y)
      ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
      : 'No Data'
    return (
      `<div><span style='color: #808697'>${entry.dataSeries.name}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>` +
      output
    )
  }, '')
  return title + subtitle + series
}
