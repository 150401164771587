import React, { useEffect, useState, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox,
} from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  KW_TO_MW,
  MAX_AVAIL,
  convertMWToKW,
  formatMarketValue,
  getCurrentTradingIntervalStart,
  getIndex,
  getMarketTime,
  getTimeAtStartOf,
  getAllEnabledProductIds,
} from '../../utility/utility'
import { NUM_FIVE_MIN_INTERVALS_PER_DAY } from '../../utility/constants'
import {
  createManualBid,
  getLatestBidFile,
  getLatestBidFilesByProducts,
  getLatestBidFilesForFutureDayByProducts,
  lastestBidFileClearCache,
  selectLatestBidFile,
  selectProductName,
  selectProjectedPriceBandInputs,
  getManualBids,
  getManualBidsData,
  getPriceBands,
} from '../../redux/features/bid'
import { PRODUCT_TYPE_GEN, PRODUCT_FCAS } from '../../pages/Bids'
import InputNumberFormat from '../../components/InputNumberFormat'
import { getProductNameGivenId, getProductTypeGivenId } from '../../utility/asset-utils'
import { getMarketStartGivenTimestamp, getNextIntervalChange } from '../../utility/time-utils'
import { getDefaultZeroBidValues, getValuesForBidIndex, getMaxAvailFromAssetProductId } from '../../utility/bids-utils'
import Card from '../Card'
import IntervalRangePicker from '../IntervalRangePicker'
import { PriceBandFirstHeader, PriceBandSecondHeader } from '../price-bands'
import ManualBidCombinedProductToggle from '../toggles/ManualBidCombinedProductToggle'
import HelperTextModal from './HelperTextModal'

const DISPLAY_NAME = 'ManualBidModal'
const SETTLEMENT_INTERVAL_MINS = 5
const MAX_AVAIL_ZERO_VALUE = 0
const LARGE_BID_SIZE = 100
const PRICE_BAND_INPUT_KEY = 'priceBandInputs'
const MAX_AVAIL_INPUT_KEY = 'maxAvail'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: `calc(100vw - ${theme.spacing(4)}px)`,
      maxWidth: 1100,
      position: 'absolute',
      top: '20%',
      right: 0,
      left: 0,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(1.25),
      },
      minHeight: 416,
    },
    gridContainer: {
      marginBottom: theme.spacing(2),
    },
    smallLabel: {
      fontSize: theme.typography.pxToRem(10),
      marginBottom: theme.spacing(0.375),
      color: theme.custom.palette.graphs.labelColor,
    },
    box: {
      paddingLeft: 0,
      marginRight: theme.spacing(1),
    },
    iconText: {
      bottom: 3,
      position: 'relative',
    },
    arrow: {
      top: theme.spacing(1),
      position: 'relative',
      marginLeft: -theme.spacing(0.875),
    },
    tableContainer: {
      overflow: 'auto',
      marginBottom: theme.spacing(1.25),
    },
    table: {
      marginBottom: theme.spacing(1),
      tableLayout: 'fixed',
      minWidth: theme.spacing(108),
    },
    rowFields: {
      paddingRight: 0,
      borderBottom: 'none',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    checkboxRowField: {
      paddingRight: 0,
      borderBottom: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
    autoCalcLabel: {
      paddingRight: 0,
      borderBottom: 'none',
      textAlign: 'center',
    },
    secondRow: {
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    orange: {
      color: '#C37E2D',
    },
    number: {
      width: '100%',
      backgroundColor: '#282d36',
      color: '#FFF',
    },
    numberInput: {
      marginLeft: theme.spacing(1),
    },
    textGrid: {
      width: '50%',
    },
    reason: {
      width: '97%',
      backgroundColor: '#282d36',
    },
    textReason: {
      fontSize: theme.typography.subtitle1.fontSize,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
    text: {
      height: 60,
    },
    footer: {
      paddingTop: theme.spacing(2.375),
    },
    marginLeft: {
      marginLeft: theme.spacing(1.75),
    },
    marginRight: {
      marginRight: theme.spacing(1.75),
    },
    errorMessage: {
      height: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
    },
    message: {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.error.main,
    },
    manualBidExplainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(-1.5),
    },
  }),
  { name: DISPLAY_NAME },
)

const ManualBiddingModal = React.forwardRef(function ManualBiddingModal(props, ref) {
  const classes = useStyles()
  const {
    asset,
    bidCollationInterval,
    manualBidSelectedDate,
    marketStartHour,
    marketTimezone,
    onChangeProductName,
    onChangeProductType,
    onChangeSelectedDate,
    onClose,
    onSubmit,
    open,
    productId,
    productName,
    productType = PRODUCT_TYPE_GEN,
    productTypes,
    disabledProductTypeToggleNames,
    showProductTypeToggle,
  } = props
  const {
    allPriceBands,
    createManualBid,
    dispatchGetCombinedBids,
    dispatchGetLatestBidFile,
    dispatchGetLatestFutureBidFileByProducts,
    dispatchGetPriceBands,
    dispatchLastestBidFileClearCache,
    latestBidFile,
    latestFutureDayBidFile,
    manualBids,
  } = props
  const { notifySuccess, notifyError } = useNotifier()
  const assetId = _.get(asset, 'assetId')
  const timezone = _.get(asset, 'market.data.timezone')
  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNames = _.get(asset, 'market.data.product_display_names')
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})
  const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
  const [errorMessage, setErrorMessage] = useState('')
  const [datePickerError, setDatePickerError] = useState(false)
  const [reason, setReason] = useState('')
  const [collapsed, setCollapsed] = useState(true)
  const [inProgressGetBids, setInProgressGetBids] = useState(false)
  const [inProgressGetPriceBands, setInProgressGetPriceBands] = useState(false)
  const productIndex = asset.products.findIndex(
    product => product.name === productName && product.data.type === productType,
  )
  const [priceBandValues, setPriceBandValues] = useState(_.get(allPriceBands, [productIndex, 'data', 'values'], {}))
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [largeRequest, setLargeRequest] = useState(false)
  const [allMaxAvailZeroMode, setAllMaxAvailZeroMode] = useState(false)
  const [productToggleVariant, setProductToggleVariant] = useState('')
  const [multiProductManualBids, setMultiProductManualBids] = useState({})
  const initialPriceBands = _.mapValues(_.keyBy(priceBandNames), () => 0)
  const [priceBandInputs, setPriceBandInputs] = useState(initialPriceBands)

  const [, setLastIntervalUpdate] = useState()
  const timeNow = moment().tz(marketTimezone)
  const currentTradingDay = getMarketStartGivenTimestamp(timeNow, marketStartHour, marketTimezone)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', '')
  const { 'bid_creator_start_cutoff_secs': bidCreatorStartCutoffSecs } = _.get(
    asset,
    'market.data.gate_closure_cutoffs',
    {},
  )
  const manualBidCutoffDuration = moment.duration(bidCreatorStartCutoffSecs, 'seconds').subtract(1, 'minutes')
  const nextIntervalChange = getNextIntervalChange(moment(timeNow), manualBidCutoffDuration.asSeconds())
  const [values, setValues] = useState({ startTime: null, endTime: null })

  const manualBidSelectedDateTimestamp = moment(manualBidSelectedDate).valueOf()

  const startIntervalIndex = getIndex(
    values.startTime,
    manualBidSelectedDate,
    moment(manualBidSelectedDate).add(1, 'days'),
    5,
  )

  const endIntervalIndex = getIndex(
    values.endTime,
    manualBidSelectedDate,
    moment(manualBidSelectedDate).add(1, 'days'),
    5,
  )

  const projectedPriceBandInputs = useSelector(state =>
    selectProjectedPriceBandInputs(
      state,
      assetId,
      manualBidSelectedDateTimestamp,
      productId,
      bidCreatorStartCutoffSecs,
      marketStartHour,
      marketTimezone,
      startIntervalIndex,
      endIntervalIndex,
      priceBandNames,
    ),
  )

  const registeredMaxAvail = getMaxAvailFromAssetProductId(asset, productId)
  const maxAvailZeroPriceBandInputs = _.isNil(projectedPriceBandInputs)
    ? getDefaultZeroBidValues(productType, productName, priceBandNames, registeredMaxAvail)
    : projectedPriceBandInputs

  const allEnabledProducts = useMemo(
    () => getAllEnabledProductIds(asset, manualBidSelectedDate),
    [asset, manualBidSelectedDate],
  )

  const defaultAutoCalcState = allEnabledProducts.reduce((acc, productId) => {
    acc[productId] = false
    return acc
  }, {})
  const [autoCalcState, setAutoCalcState] = useState(defaultAutoCalcState)
  const handleAutoCalcChange = val => {
    setAutoCalcState(prev => {
      const updated = _.cloneDeep(prev)
      updated[productId] = val
      return updated
    })
  }

  const resetAutoCalcState = () => {
    setAutoCalcState(defaultAutoCalcState)
  }

  useEffect(() => {
    dispatchLastestBidFileClearCache()
  }, [dispatchLastestBidFileClearCache])

  /**
   * TL;DR; This function will force the component to rerender on a time interval.
   *
   * The rerender is needed to force our interval list to update and invalidate old intervals in near real time.
   */
  const nextIntervalChangeTimestamp = nextIntervalChange.valueOf()
  useEffect(() => {
    if (open && !_.isNil(marketTimezone)) {
      // nextIntervalChange changes every five minutes right after end of interval (endOfInterval from parent component triggers rerender)
      const waitTime = nextIntervalChangeTimestamp - moment().tz(marketTimezone).valueOf() // milliseconds until next available interval
      const timeoutHandle = setTimeout(() => {
        setLastIntervalUpdate(moment().tz(marketTimezone))
      }, waitTime)
      return () => {
        clearTimeout(timeoutHandle)
      }
    }
  }, [open, marketTimezone, nextIntervalChangeTimestamp])

  const startOfSelectedDate = getMarketStartGivenTimestamp(
    manualBidSelectedDateTimestamp,
    marketStartHour,
    marketTimezone,
  )

  const rowFields = [...priceBandNames, MAX_AVAIL]

  const selectedBidFile = useSelector(state =>
    selectLatestBidFile(state, assetId, moment(manualBidSelectedDateTimestamp).format('YYYY-MM-DD'), productId),
  )
  const startTimeIndex =
    moment.duration(moment(values.startTime).diff(startOfSelectedDate)).asMinutes() / SETTLEMENT_INTERVAL_MINS
  const selectedBid = _.get(selectedBidFile, ['bids', startTimeIndex])

  const seededMaxAvail = _.get(selectedBid, 'maxAvailablePower', registeredMaxAvail)
  const pbInputProductRetrievalKey = productId
  const defaultMaxAvailValue = formatMarketValue(seededMaxAvail, KW_TO_MW, 0, true, false)
  const [highlightedProductTypeNames, setHighlightedProductTypeNames] = useState([])
  const [highlightedProductNames, setHighlightedProductNames] = useState([])

  const currMaxAvailInputValue = useMemo(
    () => _.get(multiProductManualBids, [pbInputProductRetrievalKey, MAX_AVAIL_INPUT_KEY], defaultMaxAvailValue),
    [defaultMaxAvailValue, multiProductManualBids, pbInputProductRetrievalKey],
  )

  // when user toggles a new product, retrieve saved priceband input & maxAvail or initialize PB input
  useEffect(() => {
    if (open && !allMaxAvailZeroMode) {
      const initializePriceBands = _.mapValues(_.keyBy(priceBandNames), () => 0)
      setPriceBandInputs(
        _.get(multiProductManualBids, [pbInputProductRetrievalKey, PRICE_BAND_INPUT_KEY], initializePriceBands),
      )
    }
  }, [allMaxAvailZeroMode, open, pbInputProductRetrievalKey, multiProductManualBids, priceBandNames, productType, productId])

  useEffect(() => {
    // gen or load changing
    const bidsGroupedByProductType = _.groupBy(multiProductManualBids, 'productType')
    const modifiedDisplayProducts = _.values(multiProductManualBids).filter(bid => {
      const priceBandInputs = _.get(bid, PRICE_BAND_INPUT_KEY, [0])
      const pbInputsSum = _.sum(_.values(priceBandInputs).map(strInt => parseInt(strInt)))
      const isPbModified = pbInputsSum !== 0
      return isPbModified
    })
    if (!_.isEmpty(bidsGroupedByProductType)) {
      if (_.has(bidsGroupedByProductType, productType)) {
        const modifiedDisplayProductNames = modifiedDisplayProducts
          .filter(bid => bid.productType === productType)
          .map(bid => bid.productName)
        const modifiedDisplayProductTypeNames = Array.from(new Set(modifiedDisplayProducts.map(bid => bid.productType)))
        setHighlightedProductTypeNames(modifiedDisplayProductTypeNames)
        setHighlightedProductNames(modifiedDisplayProductNames)
      } else {
        setHighlightedProductNames([])
      }
    } else {
      setHighlightedProductTypeNames([])
      setHighlightedProductNames([])
    }
  }, [multiProductManualBids, productType])

  useEffect(() => {
    const newPriceBands = _.get(allPriceBands, [productIndex, 'data', 'values'], {})
    if (moment(values.startTime).isValid() && !_.isEqual(priceBandValues, newPriceBands)) {
      setPriceBandValues(newPriceBands)
    }
  }, [allPriceBands, priceBandValues, productIndex, values.startTime])

  const currentTradingDayTimestamp = moment(currentTradingDay).valueOf()
  useEffect(() => {
    if (moment(manualBidSelectedDateTimestamp).isValid() && !_.isNil(assetId) && !_.isEmpty(allEnabledProducts)) {
      if (allMaxAvailZeroMode) {
        if (moment(manualBidSelectedDateTimestamp).isSame(currentTradingDayTimestamp, 'day')) {
          setInProgressGetBids(() => {
            const clearCache = true
            dispatchGetCombinedBids(
              assetId,
              allEnabledProducts,
              manualBidSelectedDateTimestamp,
              bidCollationInterval,
              clearCache,
            ).then(responses => {
              responses.forEach(response => {
                response.error && console.info('Unable to retreive bid file info.', response)
              })
              setInProgressGetBids(false)
            })
            return true
          })
        } else {
          setInProgressGetBids(true)
          dispatchGetLatestFutureBidFileByProducts(
            assetId,
            allEnabledProducts,
            moment(manualBidSelectedDateTimestamp).format('YYYY-MM-DD'),
          ).then(response => {
            if (response.error) {
              console.info('Unable to retreive bid file info.', response)
            }
            setInProgressGetBids(false)
          })
        }
      }
    }
  }, [allEnabledProducts, allMaxAvailZeroMode, assetId, bidCollationInterval, currentTradingDayTimestamp, dispatchGetCombinedBids, dispatchGetLatestFutureBidFileByProducts, manualBidSelectedDateTimestamp])

  useEffect(() => {
    if (!_.isNil(manualBidSelectedDateTimestamp) && !_.isEmpty(asset)) {
      setInProgressGetPriceBands(true)
      dispatchGetPriceBands(asset, moment(manualBidSelectedDateTimestamp)).finally(() =>
        setInProgressGetPriceBands(false),
      )
    }
  }, [asset, dispatchGetPriceBands, manualBidSelectedDateTimestamp, nextIntervalChangeTimestamp])

  useEffect(() => {
    if (allMaxAvailZeroMode) {
      const clearCache = false
      dispatchGetLatestBidFile(
        assetId,
        productId,
        moment(manualBidSelectedDateTimestamp).format('YYYY-MM-DD'),
        moment(manualBidSelectedDateTimestamp).add(1, 'days'),
        bidCollationInterval,
        clearCache,
      )
    }
  }, [assetId, bidCollationInterval, dispatchGetLatestBidFile, manualBidSelectedDateTimestamp, productId, allMaxAvailZeroMode])

  useEffect(() => {
    setProductToggleVariant(allMaxAvailZeroMode ? 'orange' : '')
  }, [allMaxAvailZeroMode])

  const expandFCAS = (event, productName) => {
    if (productName === PRODUCT_FCAS) {
      setCollapsed(false)
    }
  }

  const handleIntervalOnChange = (startTime, endTime) => {
    onChangeSelectedDate(getMarketStartGivenTimestamp(startTime, marketStartHour, timezone))
    setValues({ startTime, endTime })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitInProgress(true)
    // Validate rebid reason input
    let currentBidsFormattingError = getBidReasonErrorMessage(reason)
    const bids = []
    // Attempt to validate PB inputs and create bid payload for submission based on submission mode
    if (!currentBidsFormattingError) {
      const startTimeSubmit = getMarketTime(moment(values.startTime), asset.market.data.timezone)
      const endTimeSubmit = getMarketTime(moment(values.endTime), asset.market.data.timezone)
      const intervals = _.round(
        moment.duration(endTimeSubmit.diff(startTimeSubmit)).asMinutes() / SETTLEMENT_INTERVAL_MINS,
      )

      if (allMaxAvailZeroMode) {
        // Mode (1) for AllMaxAvailZero Manual Bid Mode: Attempt to create manual bid payload with maxAvail as 0 for all enabled products
        for (const productId of allEnabledProducts) {
          const endTimeMinutes = moment(startTimeSubmit)
          const endIndex = endIntervalIndex === -1 ? NUM_FIVE_MIN_INTERVALS_PER_DAY : endIntervalIndex
          for (let i = startIntervalIndex; i < endIndex; i++) {
            const submittedBids = _.get(latestBidFile, [
              assetId,
              moment(manualBidSelectedDate).format('YYYY-MM-DD'),
              productId,
              'bids',
            ])
            const manualBidsByProductId = _.get(
              getManualBidsData(manualBids, assetId, productId, manualBidSelectedDate),
              'data',
              [],
            )
            const defaultMaxAvail = getMaxAvailFromAssetProductId(asset, productId)
            const products = _.get(asset, 'products')
            const productType = getProductTypeGivenId(productId, products)
            const productName = getProductNameGivenId(productId, products)
            let values = getDefaultZeroBidValues(productType, productName, priceBandNames, defaultMaxAvail)
            if (moment(manualBidSelectedDate).isAfter(moment(currentTradingDay))) {
              const valuesFromLatestBidFile = _.get(latestFutureDayBidFile, [
                assetId,
                moment(manualBidSelectedDate).format('YYYY-MM-DD'),
                productId,
                'bids',
                i,
                'values',
              ])
              if (_.isArray(valuesFromLatestBidFile) && valuesFromLatestBidFile.length === priceBandNames.length) {
                values = valuesFromLatestBidFile
              }
            } else {
              // else if current day, obtain priceband allocations from current day bids.
              values = getValuesForBidIndex(i, submittedBids, manualBidsByProductId, manualBidSelectedDate)
            }

            const bid = {
              assetId,
              productId,
              bid: {
                startTime: endTimeMinutes.format(),
                endTime: endTimeMinutes.add(SETTLEMENT_INTERVAL_MINS, 'minutes').format(),
                values,
                maxAvailablePower: MAX_AVAIL_ZERO_VALUE,
                reason: reason.trim(),
                bidConfiguration: {},
              },
            }
            bids.push(bid)
          }
        }
      } else {
        const resultObject = Object.entries(multiProductManualBids).reduce((acc, [key, eachBid]) => {
          const pbInputs = eachBid?.priceBandInputs
          const newPbInputsSum = _.sum(_.values(pbInputs || {}).map(Number))
          const autoCalcState = eachBid?.isAutoCalcChecked
          const shouldInclude = autoCalcState === false && newPbInputsSum === 0

          if (!shouldInclude) {
            acc[key] = eachBid
          }
          return acc
        }, {})

        // Mode (2) for Multi-Product Manual Bid Mode: Attempt to create multi-product manual bid payload for selected products
        // validate all pricebands across different products in multi-product bid. If there's an error, update currentSubmissionError variable
        const priceBandErrorMessages = Object.values(resultObject)
          .map(bid =>
            getPriceBandAllocationErrorMessage(bid.maxAvail, bid.priceBandInputs, bid.productName, bid.productType),
          )
          .filter(error => error !== '')
        if (_.isEmpty(priceBandErrorMessages)) {
          for (const productId in resultObject) {
            const currBidPriceBandInputs = _.get(resultObject, [productId, PRICE_BAND_INPUT_KEY])
            const currBidMaxAvail = _.get(resultObject, [productId, MAX_AVAIL_INPUT_KEY])
            const endTimeMinutes = moment(startTimeSubmit)
            const isAutoCalcChecked = _.get(autoCalcState, productId)
            for (let i = 0; i < intervals; i++) {
              const bid = {
                assetId,
                productId,
                bid: {
                  startTime: endTimeMinutes.format(),
                  endTime: endTimeMinutes.add(SETTLEMENT_INTERVAL_MINS, 'minutes').format(),
                  values: priceBandNames.map(name => convertMWToKW(currBidPriceBandInputs[name])),
                  maxAvailablePower: isAutoCalcChecked ? null : convertMWToKW(currBidMaxAvail),
                  reason: reason.trim(),
                  bidConfiguration: {},
                },
              }
              bids.push(bid)
            }
          }
        } else {
          currentBidsFormattingError = priceBandErrorMessages[0]
        }
      }
    }
    // Attempt to submit bids
    if (currentBidsFormattingError) {
      // if there is any error in the priceband input or rebid reason input, then do not submit and display error message
      setErrorMessage(currentBidsFormattingError)
      setSubmitInProgress(false)
    } else {
      // else if there is no error in user input, then submit payload
      setSubmitInProgress(() => {
        if (bids.length > LARGE_BID_SIZE) {
          setLargeRequest(true)
        }
        createManualBid(bids).then(response => {
          setSubmitInProgress(false)
          if (response.error) {
            notifyError('Manual bid submission unsuccessful')
          } else {
            onSubmit().then(() => {
              onClose()
              notifySuccess('Manual bid submission successful')
            })
          }
        })
        return true
      })
    }
  }

  const getPriceBandAllocationErrorMessage = (maxAvailable, pbInputs, productName, productType) => {
    const productIndicator = `For ${productType}-${productName}`
    if (_.isNil(pbInputs)) {
      return `${productIndicator}: Please modify Priceband inputs`
    }
    if (values.startTime === '' || !moment(values.startTime).isValid) {
      return 'Start time not well formed'
    }
    const actualStartTime = getMarketTime(moment(values.startTime), asset.market.data.timezone)
    const timeAtStartOfInterval = getTimeAtStartOf(actualStartTime, SETTLEMENT_INTERVAL_MINS)
    const pastTimeError = 'Can only create manual bid for current or future interval'
    if (!timeAtStartOfInterval.isSame(actualStartTime)) {
      return `Start time is not aligned to ${SETTLEMENT_INTERVAL_MINS} minutes`
    }
    if (actualStartTime.isBefore(getCurrentTradingIntervalStart(asset))) {
      return pastTimeError
    }
    const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
    const priceBandDisplayNames = asset.market.data.price_band_display_names
    let totalBidMW = 0
    for (let i = 0; i < priceBandNames.length; i++) {
      const value = pbInputs[priceBandNames[i]]
      if (value === '' || _.isNil(value) || isNaN(value)) {
        return `${productIndicator}: ${priceBandDisplayNames[i]} is empty or not well formatted`
      } else if (!Number.isInteger(+value)) {
        return `${productIndicator}: ${priceBandDisplayNames[i]} can not be decimal`
      } else if (value < 0) {
        return `${productIndicator}: ${priceBandDisplayNames[i]} must be greater than zero`
      }
      totalBidMW += +value
    }
    const productCapKW = _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) * KW_TO_MW
    if (!allMaxAvailZeroMode && productCapKW !== totalBidMW) {
      return `${productIndicator}: Total bid must sum to ${productType}-${productName} capacity (${productCapKW} MW)`
    }
    if (maxAvailable === '') {
      return `${productIndicator}: Max Avail is required`
    }
    const isAutoCalcChecked = _.get(autoCalcState, productId)
    if (!isAutoCalcChecked && parseFloat(maxAvailable) !== _.round(maxAvailable, 2)) {
      return `${productIndicator}: Max Avail cannot have more than two decimals`
    }
    return ''
  }

  const getBidReasonErrorMessage = overrideReason => {
    const overrideReasonTrimmed = overrideReason.trim()
    const validRebidReason = /^[0-2][0-9][0-5][0-9] [aefpAEFP] .+/
    if (!validRebidReason.test(overrideReasonTrimmed)) {
      const wrongCategory = /^[0-2][0-9][0-5][0-9] [^aefpAEFP] .+/
      if (wrongCategory.test(overrideReasonTrimmed)) {
        const category = overrideReasonTrimmed[5]
        return `Invalid Reason category ${category}. Category must be A, F, P, or E`
      } else {
        return `Invalid Reason. Following reason format must be used “HHMM F Rebid explanation”`
      }
    }
    return ''
  }

  const handleAllocationOnChange = name => event => {
    const value = _.get(event, 'target.value')
    const bidProductKey = productId
    if (name === MAX_AVAIL) {
      if (!allMaxAvailZeroMode) {
        const isInputValueAutoCalcMaxAvail = value === '-'
        if (!isInputValueAutoCalcMaxAvail) {
          // this is the actual maxAvail we'll submit
          setMultiProductManualBids(prevState => ({
            ...prevState,
            [bidProductKey]: {
              ...prevState[bidProductKey],
              productType,
              productName,
              [MAX_AVAIL_INPUT_KEY]: value,
            },
          }))
        }
      }
      if (errorMessage) {
        setErrorMessage(getPriceBandAllocationErrorMessage(value, priceBandInputs, productName, productType))
      }
    } else if (!allMaxAvailZeroMode) {
      setPriceBandInputs(prev => {
        const newPbInputs = {
          ...prev,
          [name]: value,
        }
        const newPbInputsSum = _.sum(_.values(newPbInputs).map(strInt => parseInt(strInt)))
        const isAllPbInputZero = newPbInputsSum === 0
        if (!isAllPbInputZero) {
          setMultiProductManualBids(prevState => {
            if (_.has(prevState, [bidProductKey])) {
              const prevProductBid = _.get(prevState, [bidProductKey])
              return {
                ...prevState,
                [bidProductKey]: {
                  ...prevProductBid,
                  priceBandInputs: newPbInputs,
                },
              }
            } else {
              return {
                ...prevState,
                [bidProductKey]: {
                  ...prevState[bidProductKey],
                  productType,
                  productName,
                  priceBandInputs: newPbInputs,
                  maxAvail: currMaxAvailInputValue,
                },
              }
            }
          })
        } else if (isAllPbInputZero && _.has(multiProductManualBids, bidProductKey)) {
          setMultiProductManualBids(prevState => _.omit(prevState, bidProductKey))
        }
        if (errorMessage) {
          setErrorMessage(
            getPriceBandAllocationErrorMessage(
              _.get(multiProductManualBids, [pbInputProductRetrievalKey, MAX_AVAIL_INPUT_KEY]),
              newPbInputs,
              productName,
              productType,
            ),
          )
        }
        return newPbInputs
      })
    }
  }

  const handleReasonOnChange = event => {
    const value = _.get(event, 'target.value')
    setReason(value)
    if (errorMessage) {
      setErrorMessage(getBidReasonErrorMessage(value))
    }
  }

  const handleDatePickerError = (error, value) => {
    setDatePickerError(!_.isEmpty(error))
  }

  const handleToggleAllMaxAvailZero = () => {
    setAllMaxAvailZeroMode(prev => {
      if (!prev) {
        resetAutoCalcState()
      }
      return !prev
    })
  }

  const disabledProductNames = asset.productNames.filter(
    productName => _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) === 0,
  )

  const isDateTimeError =
    !moment(manualBidSelectedDate).isValid() ||
    datePickerError ||
    moment(values.startTime).isSameOrAfter(values.endTime) ||
    _.isNil(values.startTime) ||
    values.startTime === '' ||
    values.endTime === ''

  const isInputValid = _.isEmpty(errorMessage) && !isDateTimeError
  const priceBandDisplayNames = _.get(asset, 'market.data.price_band_display_names', [])
  const helperText = `Schedule a manual bid for selected period.\n   • Manual bids will override Fluence algorithmic bids.\n   • Creating a manual bid for multiple products will highlight the affected products in orange.\n   • Toggling on "SET ALL MAXAVAIL TO ZERO" will populate a single manual bid using the current price band allocations and setting MaxAvail to zero for all registered products.\n   • Rebid reasons must follow the following convention: “HHMM {A, F, P, E} REBID EXPLANATION.”\n   • Intervals with active manual bids will include a dot ●`

  const inProgress = submitInProgress || inProgressGetBids || inProgressGetPriceBands
  const showLargeRequestMessage = submitInProgress && largeRequest

  const handleAutoCalcCheckboxClick = e => {
    const checkedValue = e.target.checked
    handleAutoCalcChange(e.target.checked)
    const bidProductKey = productId
    if (checkedValue) {
      setMultiProductManualBids(prevState => {
        return {
          ...prevState,
          [bidProductKey]: {
            ...prevState[bidProductKey],
            productType,
            productName,
            isAutoCalcChecked: checkedValue,
          },
        }
      })
    } else {
      const pricebandInputs = multiProductManualBids[productId]?.priceBandInputs
      const newPbInputsSum = _.sum(_.values(pricebandInputs).map(strInt => parseInt(strInt)))
      if ((_.isNil(pricebandInputs) || newPbInputsSum === 0) && _.has(multiProductManualBids, bidProductKey)) {
        setErrorMessage('')
      }
      setMultiProductManualBids(prevState => {
        return {
          ...prevState,
          [bidProductKey]: {
            ...prevState[bidProductKey],
            isAutoCalcChecked: checkedValue,
          },
        }
      })
    }
  }

  return (
    <Box className={classes.root} ref={ref} tabIndex={-1}>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        title={
          <Box>
            <Box display="inline" pr={0.5}>
              {`Manual Bidding - ${asset.name}`}
            </Box>
            <HelperTextModal helperText={helperText} title="Manual Bid Creation Instructions" />
          </Box>
        }
        titleTypographyProps={{ variant: 'h3' }}
        inProgress={inProgress}
      >
        {!showLargeRequestMessage && (
          <>
            <Grid container spacing={3} className={classes.secondRow}>
              <Grid item xs={8}>
                <Box display="flex" flexWrap="wrap">
                  <Box flex="2 1 auto">
                    <IntervalRangePicker
                      datePickerLabel="TRADING DATE"
                      defaultIntervalRange={1}
                      endTimeLabel="PERIOD END TIME"
                      initialStartDate={manualBidSelectedDate}
                      intervalCutoffSec={manualBidCutoffDuration.asSeconds()}
                      marketStartHour={marketStartHour}
                      marketTimezoneDisplayName={marketTimezoneDisplayName}
                      onChange={handleIntervalOnChange}
                      onDatePickerError={handleDatePickerError}
                      startTimeLabel="PERIOD START TIME"
                      timezone={marketTimezone}
                      disabled={submitInProgress}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel
                      label="SET ALL MAXAVAIL TO ZERO"
                      labelPlacement="end"
                      value="allMaxAvailZero"
                      control={
                        <Switch
                          checked={allMaxAvailZeroMode}
                          color="primary"
                          onChange={handleToggleAllMaxAvailZero}
                          value="allMaxAvailZero"
                          inputProps={{ 'aria-label': 'toggle set all maxAvail zero' }}
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className={classes.gridContainer} spacing={0} wrap="wrap">
              <ManualBidCombinedProductToggle
                asset={asset}
                selectedTime={manualBidSelectedDate}
                // Product Type Toggle Props
                selectedProductType={productType}
                onChangeProductType={onChangeProductType}
                productTypes={productTypes}
                productTypeDisplayNames={productTypeDisplayNames}
                productTypeToggleVariant={productToggleVariant}
                highlightedProductTypeNames={highlightedProductTypeNames}
                // Product Toggle Props
                selectedProductName={productName}
                onChangeProductName={onChangeProductName}
                disabledProductNames={disabledProductNames}
                onProductToggleCollapse={expandFCAS}
                productNames={productNames}
                productDisplayNames={productDisplayNames}
                productToggleVariant={productToggleVariant}
                highlightedProductNames={highlightedProductNames}
                disabledProductTypeToggleNames={collapsed ? disabledProductTypeToggleNames : []}
                showProductTypeToggle={showProductTypeToggle}
              />
            </Grid>
            <Typography className={classes.smallLabel}>Allocate MW Bids across Price Bands</Typography>
            <div className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <PriceBandFirstHeader priceBandNames={priceBandDisplayNames} />
                  </TableRow>
                  <TableRow>
                    <PriceBandSecondHeader
                      maxAvailLabel="MaxAvail"
                      noBorder
                      priceBandNames={priceBandNames}
                      priceBandValues={priceBandValues}
                      showAutoCalc
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {rowFields.map(name => {
                      const currPriceBandInputs = allMaxAvailZeroMode ? maxAvailZeroPriceBandInputs : priceBandInputs
                      const shouldShowPbAllocationDashes =
                        allMaxAvailZeroMode && _.get(currPriceBandInputs[name], 'length', -1) > 1
                      const PBdisplayValue = shouldShowPbAllocationDashes ? '-' : _.get(currPriceBandInputs, name, 0)
                      const isAutoCalcChecked = _.get(autoCalcState, productId)
                      const maxAvailDisplayValue = isAutoCalcChecked ? '-' : currMaxAvailInputValue
                      const textValue = `${name === MAX_AVAIL ? maxAvailDisplayValue : PBdisplayValue}`
                      return (
                        <TableCell key={name} className={classes.rowFields}>
                          <TextField
                            className={classes.number}
                            InputProps={{
                              classes: { input: classes.numberInput },
                              inputComponent: InputNumberFormat,
                              inputProps: { decimalScale: name === MAX_AVAIL ? 2 : 0 },
                            }}
                            value={allMaxAvailZeroMode && name === MAX_AVAIL ? 0 : textValue}
                            disabled={allMaxAvailZeroMode || (name === MAX_AVAIL && isAutoCalcChecked)}
                            onChange={handleAllocationOnChange(name)}
                          />
                        </TableCell>
                      )
                    })}
                    <TableCell key="checkbox" className={classes.checkboxRowField}>
                      <Checkbox
                        checked={_.get(autoCalcState, productId)}
                        onChange={handleAutoCalcCheckboxClick}
                        disabled={allMaxAvailZeroMode}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <Grid container>
              <Grid item className={classes.textGrid}>
                <Typography className={classes.smallLabel}>Supply Reason for Manual Adjustment *</Typography>
                <TextField
                  required
                  className={classes.reason}
                  value={reason}
                  multiline
                  rows="3"
                  placeholder="Reason"
                  variant="outlined"
                  InputProps={{ classes: { root: classes.textReason } }}
                  onChange={handleReasonOnChange}
                />
              </Grid>
              <Grid item className={classes.footer}>
                <Button variant="secondary" onClick={onClose} disabled={submitInProgress}>
                  CANCEL
                </Button>
                <Button
                  variant="primary"
                  className={clsx(classes.marginLeft, classes.marginRight)}
                  disabled={submitInProgress || inProgressGetBids || !isInputValid}
                  onClick={handleSubmit}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {showLargeRequestMessage && (
          <Typography>
            Submitting request. {largeRequest ? 'Saving multiple manual bids may take several moments to update' : ''}
          </Typography>
        )}
        <div className={classes.errorMessage}>
          <label className={classes.message}>{errorMessage}</label>
        </div>
      </Card>
    </Box>
  )
})

const mapStateToProps = state => {
  return {
    allPriceBands: _.get(state, 'bid.modalSelectedPriceBands.payload', []),
    latestBidFile: _.get(state, 'bid.latestBidFile', {}),
    latestFutureDayBidFile: _.get(state, 'bid.latestFutureDayBidFile', {}),
    manualBids: _.get(state, 'bid.manualBids'),
    manualBidsMapByStartTime: _.get(state, 'bid.manualBidsMapByStartTime'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createManualBid: bid => dispatch(createManualBid.post(bid)),
    onProductNameChange: productName => dispatch(selectProductName(productName)),
    dispatchGetPriceBands: (asset, startTime) => dispatch(getPriceBands('selected').get(asset, startTime, null, false)),
    dispatchGetLatestBidFile: (assetId, productId, tradingDay, asOf, bidCollationInterval, clearCache = false) =>
      dispatch(getLatestBidFile(assetId, productId, tradingDay, asOf, bidCollationInterval, clearCache)),
    dispatchGetCombinedBids: (
      assetId,
      productIds,
      selectedDate,
      bidCollationInterval,
      isBeforeToday = false,
      clearCache = false,
    ) => {
      const promises = []
      promises.push(
        dispatch(
          getLatestBidFilesByProducts(
            assetId,
            productIds,
            moment(selectedDate).format('YYYY-MM-DD'),
            moment(selectedDate).add(1, 'days'),
            bidCollationInterval,
            !clearCache,
          ),
        ),
      )
      for (const productId of productIds) {
        promises.push(
          dispatch(
            getManualBids(
              assetId,
              productId,
              moment(selectedDate),
              moment(selectedDate).add(1, 'days'),
              isBeforeToday,
              clearCache,
            ),
          ),
        )
      }
      return Promise.all(promises)
    },
    dispatchGetLatestFutureBidFileByProducts: (assetId, productIds, tradingDay) =>
      dispatch(getLatestBidFilesForFutureDayByProducts(assetId, productIds, tradingDay)),
    dispatchLastestBidFileClearCache: () => dispatch(lastestBidFileClearCache()),
  }
}

ManualBiddingModal.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ManualBiddingModal)
