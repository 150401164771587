import React from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull'
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20'
import BatteryStdIcon from '@material-ui/icons/BatteryStd'
import DnsIcon from '@material-ui/icons/Dns'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { Hash as HashIcon } from '@fluence/core'
import Card from '../Card'
import { convertkWToMW } from '../../utility/utility'
import DetailList from '../DetailList'

const DISPLAY_NAME = 'Details'
const TITLE = 'Details'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 300,
    },
    tableCardContent: {
      minHeight: 200,
      padding: 0,
    },
    action: {
      paddingRight: theme.spacing(1),
    },
    actionBtn: {
      cursor: 'pointer',
    },
    secondary: {
      color: theme.palette.text.tertiary,
    },
    muiListItemIconRoot: {
      color: theme.palette.text.tertiary,
      minWidth: 40,
    },
  }),
  { name: DISPLAY_NAME },
)

const Details = props => {
  const { asset } = props
  const classes = useStyles()

  const name = _.get(asset, 'name')
  const participantId = _.get(asset, 'data.participant_id')
  const duidGen = _.get(asset, 'data.duid_gen')
  const duidLoad = _.get(asset, 'data.duid_load')
  const duid = _.get(asset, 'data.duid')
  const bduTransitionDate = _.get(asset, 'data.feature_flags.bdu_transition_start_time')
  const location = `${_.get(asset, 'data.latitude')}, ${_.get(asset, 'data.longitude')}`
  const isDualType = _.get(asset, 'dualType', false)
  const isBattery = _.get(asset, 'data.asset_type', []).includes('battery')

  const inverters = _.get(asset, 'data.number_inverters')
  const chargeRate = _.get(asset, 'data.inverter_discharge_rate')
  const totalChargeRate = convertkWToMW(inverters * chargeRate)
  const chargeRateDisplay = _.isNumber(inverters) && _.isNumber(chargeRate) && `${totalChargeRate} MW`
  const chargingEfficiency = numeral(_.get(asset, 'data.charging_efficiency')).format('0,0%')

  const dischargeRate = _.get(asset, 'data.inverter_discharge_rate')
  const totalDischarge = convertkWToMW(inverters * dischargeRate)
  const dischargeRateDisplay = _.isNumber(inverters) && _.isNumber(dischargeRate) && `${totalDischarge} MW`
  const dischargingEfficiency = numeral(_.get(asset, 'data.discharging_efficiency')).format('0,0%')

  const generalDetails = []
  let chargeDetails = []
  let dischargeDetails = []
  let capacityDetails = []
  let details = []

  if (participantId) {
    generalDetails.push({
      primary: participantId,
      secondary: 'Participant ID',
      IconComponent: HashIcon,
    })
  }
  if (duidGen) {
    generalDetails.push({
      primary: duidGen,
      secondary: 'DUID (GEN)',
      IconComponent: HashIcon,
    })
  }
  if (duidLoad) {
    generalDetails.push({
      primary: duidLoad,
      secondary: 'DUID (LOAD)',
      IconComponent: HashIcon,
    })
  }
  if (duid) {
    generalDetails.push({
      primary: duid,
      secondary: 'DUID',
      IconComponent: HashIcon,
    })
  }
  if (bduTransitionDate) {
    generalDetails.push({
      primary: bduTransitionDate,
      secondary: 'BDU Transition Date',
      IconComponent: ScheduleIcon,
    })
  }
  generalDetails.push({
    primary: location,
    secondary: 'LOCATION',
    IconComponent: LocationOnIcon,
  })

  if (isDualType) {
    chargeDetails = [
      {
        primary: chargeRateDisplay,
        secondary: 'CHARGE RATE',
        IconComponent: BatteryChargingFullIcon,
      },
      {
        primary: chargingEfficiency,
        secondary: 'CHARGE EFFICIENCY',
        IconComponent: BatteryChargingFullIcon,
      },
    ]
    dischargeDetails = [
      {
        primary: dischargeRateDisplay,
        secondary: 'DISCHARGE RATE',
        IconComponent: BatteryCharging20Icon,
      },
      {
        primary: dischargingEfficiency,
        secondary: 'DISCHARGE EFFICIENCY',
        IconComponent: BatteryCharging20Icon,
      },
    ]
  }

  if (isBattery) {
    const numBatteries = _.get(asset, 'data.number_batteries')
    const batteryCapcity = _.get(asset, 'data.battery_capacity_kwh')
    const totalCapacityMwNumber = convertkWToMW(numBatteries * batteryCapcity, 0)
    const totalCapacityMw = `${totalCapacityMwNumber} MWh`
    const numberBatteries = _.get(asset, 'data.number_batteries')
    const numberInverters = _.get(asset, 'data.number_inverters')
    capacityDetails = [
      {
        primary: totalCapacityMw,
        secondary: 'INSTALLED CAPACITY',
        IconComponent: BatteryStdIcon,
      },
      {
        primary: numberBatteries,
        secondary: 'NUMBER OF BATTERY PACKS',
        IconComponent: BatteryStdIcon,
      },
    ]
    if (isDualType) {
      capacityDetails.push({
        primary: numberInverters,
        secondary: 'NUMBER OF INVERTERS',
        IconComponent: DnsIcon,
      })
    }
  } else {
    details = [
      {
        primary: name,
        secondary: 'NAME',
        IconComponent: AssignmentIcon,
      },
      ...generalDetails,
      ...chargeDetails,
      ...dischargeDetails,
    ]
  }

  return (
    <Card classes={{ content: classes.tableCardContent }} title={TITLE} inProgress={false} className={classes.card}>
      {isBattery ? (
        <Grid container>
          <Box clone order={{ sm: 1, md: 1 }}>
            <Grid item container xs={12} sm={6} md={3}>
              <DetailList listItems={generalDetails} />
            </Grid>
          </Box>
          <Box clone order={{ sm: 3, md: 2 }}>
            <Grid item container xs={12} sm={6} md={3}>
              <DetailList listItems={chargeDetails} />
            </Grid>
          </Box>
          <Box clone order={{ sm: 4, md: 3 }}>
            <Grid item container xs={12} sm={6} md={3}>
              <DetailList listItems={dischargeDetails} />
            </Grid>
          </Box>
          <Box clone order={{ sm: 2, md: 4 }}>
            <Grid item container xs={12} sm={6} md={3}>
              <DetailList listItems={capacityDetails} />
            </Grid>
          </Box>
        </Grid>
      ) : (
        <DetailList listItems={details} />
      )}
    </Card>
  )
}

export default Details
