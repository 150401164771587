import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'

const { AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_DATABASE_CONNECTION, AUTH_AUDIENCE } = window

/**
 * A JSON parsing function that will return false if the json string is invalid.
 * @param {string} [jsonString] - property of objects to compare
 * @example
 * // returns { a: 123 }
 * parseJsonString('{"a": 123}')
 * @example
 * // returns false
 * parseJsonString("{invalid JSON}")
 */
export function parseJsonString(jsonString) {
  try {
    const json = JSON.parse(jsonString)
    if (json && typeof json === 'object') {
      return json
    }
  } catch (e) {}
  return false
}

window.addEventListener('storage', event => {
  if (event.storageArea === localStorage) {
    // const { key, oldValue, newValue, url } = event
    // console.log('localStorage changed, did somebody change our login status?', event)
  }
})

export class Auth {
  constructor(clientId, domain, connection, tokenPrefix) {
    this.clientId = clientId
    this.domain = domain
    this.connection = connection
    this.localStorageTokenKey = `${tokenPrefix}_auth_token`
    this.localStorageIdTokenKey = `${tokenPrefix}_id_token`
    this.localStorageTokenExpKey = `${tokenPrefix}_expires_in`
  }

  isLoggedIn = () => {
    const idTokenString = localStorage.getItem(this.localStorageIdTokenKey)
    const idToken = parseJsonString(idTokenString) // unix timestamp
    const authTokenExp = parseInt(localStorage.getItem(this.localStorageTokenExpKey)) // timestamp

    // token expiration data is in seconds and Date.now() is in miliseconds, so normalize before compare
    return authTokenExp && Date.now() < authTokenExp && idToken
  }

  getToken = () => {
    return localStorage.getItem(this.localStorageTokenKey)
  }

  setToken = (token, idToken, expiresIn) => {
    const now = Date.now()

    // convert expiresIn from seconds to milliseconds
    const authTokenExpiration = now + expiresIn * 1000

    localStorage.setItem(this.localStorageTokenKey, token)
    localStorage.setItem(this.localStorageIdTokenKey, JSON.stringify(idToken))
    localStorage.setItem(this.localStorageTokenExpKey, JSON.stringify(authTokenExpiration))
  }

  getTokenExpiration = () => {
    const authTokenExpiration = localStorage.getItem(this.localStorageTokenExpKey)
    return parseInt(authTokenExpiration)
  }

  login = (email, password, success, failure, mfa, audience = AUTH_AUDIENCE) => {
    if (_.isEmpty(AUTH_AUDIENCE)) {
      console.error('AUTH_AUDIENCE is not set. This will cause login to fail.')
      failure(new Error('AUTH_AUDIENCE is not set. This will cause login to fail.'))
    }
    const url = `https://${this.domain}/oauth/token`
    const params = {
      audience,
      grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
      username: email,
      password: password,
      client_id: this.clientId,
      realm: this.connection,
      scope: 'openid',
    }
    const encodedParams = Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      })
      .join('&')

    fetch(url, {
      method: 'POST',
      body: encodedParams,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    }).then(
      response => {
        response.json().then(json => {
          if (response.ok) {
            this.setToken(json.access_token, jwtDecode(json.id_token), json.expires_in)
            success()
          } else if (json.error === 'mfa_required') {
            this.mfaChallenge(json.mfa_token, mfa, failure)
          } else {
            failure(json)
          }
        })
      },
      error => {
        failure(error)
      },
    )
  }

  mfaChallenge = (token, mfa, failure) => {
    const url = `https://${this.domain}/mfa/challenge`
    const params = {
      mfa_token: token,
      challenge_type: 'otp',
      client_id: this.clientId,
    }
    const encodedParams = Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      })
      .join('&')

    fetch(url, {
      method: 'POST',
      body: encodedParams,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    }).then(
      response => {
        if (response.ok) {
          mfa(token)
        } else {
          failure('mfa enrollment required')
        }
      },
      error => {
        failure(error)
      },
    )
  }

  mfaResponse = (token, code, success, failure) => {
    const url = `https://${this.domain}/oauth/token`
    const params = {
      grant_type: 'http://auth0.com/oauth/grant-type/mfa-otp',
      mfa_token: token,
      otp: code,
      client_id: this.clientId,
    }
    const encodedParams = Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      })
      .join('&')

    fetch(url, {
      method: 'POST',
      body: encodedParams,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    }).then(
      response => {
        response.json().then(json => {
          if (response.ok) {
            this.setToken(json.access_token, jwtDecode(json.id_token), json.expires_in)
            success()
          } else {
            failure()
          }
        })
      },
      error => {
        failure(error)
      },
    )
  }

  deleteAuthToken = () => {
    localStorage.removeItem(this.localStorageTokenKey)
    localStorage.removeItem(this.localStorageIdTokenKey)
    localStorage.removeItem(this.localStorageTokenExpKey)
  }

  forgotPassword = (email, success, failure) => {
    // https://auth0.com/docs/api/authentication?http#change-password
    const url = `https://${this.domain}/dbconnections/change_password`
    const params = {
      client_id: this.clientId,
      connection: this.connection,
      email,
    }
    const encodedParams = Object.keys(params)
      .map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      })
      .join('&')

    fetch(url, {
      method: 'POST',
      body: encodedParams,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
    }).then(
      response => {
        response.text().then(text => {
          if (response.ok) {
            success(text)
          } else {
            failure(text)
          }
        })
      },
      error => {
        failure(error)
      },
    )
  }
}

export default new Auth(AUTH_CLIENT_ID, AUTH_DOMAIN, AUTH_DATABASE_CONNECTION, 'market_portal')
