import React from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@material-ui/core'
import { ZoomOutMap } from '@material-ui/icons'
import moment from 'moment-timezone'
import _ from 'lodash'
import LegendImpl from './LegendImpl'
import GraphWrapper from './GraphWrapper'

export class GraphGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      legend: this.getLegend(props),
      currentTime: props.currentTime || moment().unix(),
    }
    this.chartRefs = []
    this.handleSetChartRefFns = []

    for (let i = 0; i < props.children.length; i++) {
      this.handleSetChartRefFns.push(ref => (this.chartRefs[i] = ref))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentTime !== prevProps.currentTime) {
      this.setState({ currentTime: this.props.currentTime })
    }

    // If there are no childred previous should be 0. If its not an array then there is 1 child.
    const numPrevChildren = _.isEmpty(prevProps.children)
      ? 0
      : _.isArray(prevProps.children)
      ? prevProps.children.filter(c => !!c).length
      : 1
    const children = this.props.children
    const numChildren = _.isEmpty(children) ? 0 : _.isArray(children) ? children.filter(c => !!c).length : 1
    if (numChildren !== numPrevChildren) {
      // When the number of children changes make sure the legends are updated/initialized.
      this.setState({ legend: this.getLegend(this.props) })
    }
  }

  componentWillUnmount() {
    this.chartRefs = []
  }

  handleDisplayRangeReset = event => {
    const { start, end } = this.props.range
    this.props.setDisplayRange(start, end)
  }

  handleOnMouseEnter = chartIndex => () => {
    const ref = this.chartRefs[chartIndex]
    if (_.has(ref, 'options.toolTip')) {
      ref.options.toolTip.enabled = false
      ref.render()
    }
  }

  // TODO this toolTip is capitalized instea of tooltip so it shoudl probably change
  handleOnMouseLeave = chartIndex => () => {
    const ref = this.chartRefs[chartIndex]
    if (_.has(ref, 'options.toolTip')) {
      ref.options.toolTip.enabled = true
      ref.render()
    }
  }

  setRangeChanged = e => {
    this.rangeChanged(e.chart.axisX[0])
  }

  render() {
    // TODO: Enable each chart to specify inProgress separately.
    const { children, displayRange, context, range, inProgress = false } = this.props
    const { legend, currentTime } = this.state
    const zoomResetVisible = _.isNil(displayRange) || _.isEqual(range, displayRange) ? 'hidden' : 'visible'

    return (
      <Box position="relative">
        {React.Children.map(children, (child, index) => {
          if (child && child.type === Graph) {
            return (
              <Box position="relative">
                <Typography variant="h4" style={{ marginTop: 15, marginBottom: 10 }}>
                  {child.props.name}
                </Typography>
                <Box position="absolute" top="48px" right="40px" zIndex="100" visibility={zoomResetVisible}>
                  <IconButton
                    onClick={this.handleDisplayRangeReset}
                    onMouseEnter={this.handleOnMouseEnter(index)}
                    onMouseLeave={this.handleOnMouseLeave(index)}
                    aria-label="zoom out"
                  >
                    <ZoomOutMap />
                  </IconButton>
                </Box>
                <GraphWrapper
                  {...child.props}
                  onRef={this.handleSetChartRefFns[index]}
                  key={index}
                  range={range}
                  displayRange={displayRange}
                  context={context}
                  legend={legend}
                  currentTime={currentTime}
                  inProgress={inProgress}
                  rangeChanged={this.setRangeChanged}
                />
              </Box>
            )
          } else if (child && child.type === Legend) {
            return <LegendImpl {...child.props} legend={legend} toggleItem={item => this.toggleLegend(item)} />
          }
        })}
      </Box>
    )
  }

  rangeChanged(xAxis) {
    const displayRange = {
      start: xAxis.viewportMinimum,
      end: xAxis.viewportMaximum,
    }
    this.props.setDisplayRange(displayRange.start, displayRange.end)
  }

  toggleLegend(item) {
    const legend = {
      ...this.state.legend,
      [item.id]: !this.state.legend[item.id],
    }
    this.setState({
      legend,
    })
  }

  // TODO: Improve this function by only updating values that haven't been initialized yet.
  getLegend(props) {
    const legend = {}
    React.Children.forEach(props.children, child => {
      if (child) {
        // TODO ^^^use child.type instead of items to figure out if it is leggend or not
        const items = child.props.items || []
        items.forEach(item => (legend[item.id] = true))
      }
    })
    return legend
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
  }
}

export class Graph extends React.Component {}

/* eslint-disable react/no-unused-prop-types */
Graph.propTypes = {
  formatSeries: PropTypes.func.isRequired,
  formatTooltip: PropTypes.func,
  formatX2Axis: PropTypes.func.isRequired,
  formatXAxis: PropTypes.func.isRequired,
  formatYAxis: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  height: PropTypes.number,
  name: PropTypes.string,
}

export class Legend extends React.Component {}

Legend.propTypes = {
  legend: PropTypes.array,
  toggleItem: PropTypes.array,
}
/* eslint-enable react/no-unused-prop-types */
