import _ from 'lodash'
import moment from 'moment-timezone'

const selectLatestRegulationThroughput = (assetId, productId) => state => {
  const value = _.get(state, ['regulationThroughput', 'latest', `${assetId}_${productId}`, 'data', 'value'])
  const createdOn = _.get(state, ['regulationThroughput', 'latest', `${assetId}_${productId}`, 'createdOn'])
  const startTime = _.get(state, ['regulationThroughput', 'latest', `${assetId}_${productId}`, 'startTime'])
  return { createdOn, startTime, value }
}

const selectRegulationThroughputSchedule = (assetId, genProductId, loadProductId) => state => {
  const genItems = _.get(state, ['regulationThroughput', 'range', `${assetId}_${genProductId}`], [])
  const loadItems = _.get(state, ['regulationThroughput', 'range', `${assetId}_${loadProductId}`], [])

  // Ensure we display only the latest eliment per time slot by comparing createdOn dates. Newest wins
  const genItemsUnique = Object.values(
    genItems.reduce((acc, next) => {
      const prev = _.get(acc, next.startTime)
      if (!prev || moment(next.createdOn) > moment(prev.createdOn)) {
        acc[next.startTime] = next
      }
      return acc
    }, {}),
  )
  const combinedItems = {}
  genItemsUnique.forEach(item => {
    if (!item.deleted) {
      _.set(combinedItems, [item.startTime, 'assetProductDataIds', 0], item.assetProductDataId)
      _.set(combinedItems, [item.startTime, 'createdOn'], item.createdOn)
      _.set(combinedItems, [item.startTime, 'startTime'], item.startTime)
      _.set(combinedItems, [item.startTime, 'value', 0], _.get(item, 'data.value'))
    }
  })

  const loadItemsUnique = Object.values(
    loadItems.reduce((acc, next) => {
      const prev = _.get(acc, next.startTime)
      if (!prev || moment(next.createdOn) > moment(prev.createdOn)) {
        acc[next.startTime] = next
      }
      return acc
    }, {}),
  )
  loadItemsUnique.forEach(item => {
    if (!item.deleted) {
      _.set(combinedItems, [item.startTime, 'assetProductDataIds', 1], item.assetProductDataId)
      _.set(combinedItems, [item.startTime, 'createdOn'], item.createdOn)
      _.set(combinedItems, [item.startTime, 'startTime'], item.startTime)
      _.set(combinedItems, [item.startTime, 'value', 1], _.get(item, 'data.value'))
    }
  })

  const sortedItems = Object.values(combinedItems).sort((a, b) => moment(a.startTime) - moment(b.startTime))
  return sortedItems
}

const selectors = {
  selectLatestRegulationThroughput,
  selectRegulationThroughputSchedule,
}

export default selectors
