import React from 'react'
let CanvasJS = require('../../assets/scripts/canvasjs.min')
require('../../assets/scss/canvasjs.css')

CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS

class CanvasJSChart extends React.Component {
  static _cjsContainerId = 0

  constructor(props) {
    super(props)
    this.options = props.options ? props.options : {}
    this.containerProps = { width: '100%', position: 'relative', height: props.containerProps.height || '400px' }
    this.chartContainerId = 'canvasjs-react-chart-container-' + CanvasJSChart._cjsContainerId++
  }

  componentDidMount() {
    this.chart = new CanvasJS.Chart(this.chartContainerId, this.options)
    this.chart.render()
		if(this.props.onRef) {
			this.props.onRef(this.chart)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.options !== nextProps.options
  }

  componentDidUpdate() {
    this.chart.options = this.props.options
    this.chart.render()
  }

  componentWillUnmount() {
    this.chart.destroy()
		if(this.props.onRef) {
			this.props.onRef(undefined)
    }
  }

  render() {
    return <div id={this.chartContainerId} style={this.containerProps} />
  }
}

export default CanvasJSChart
