import _ from 'lodash'
import moment from 'moment'

export const CACHE_API = Symbol('Cache API')

const isCacheAction = action => _.isPlainObject(action) && action.hasOwnProperty(CACHE_API)

export const cachingMiddleware = store => next => action => {
  // Ensure that the action should be processed by this middleware
  if (!isCacheAction(action)) {
    return next(action)
  }

  const state = store.getState()
  const dispatch = store.dispatch
  const {
    mapStateToCache,
    key,
    createFetchAction,
    fetchAction,
    createFinalAction,
    mapStateToUpdatedOn,
    invalidateAfter,
  } = action[CACHE_API]
  const cache = mapStateToCache(state)

  // If the cache is an object and a key is provided, then the cache contains zero or more keys that can be cached
  let shouldFetch = false
  if (_.isPlainObject(cache) && key) {
    if (!cache.hasOwnProperty(key)) {
      shouldFetch = true
    }
  }

  // If the cache is an array, then it is treated as the entire cache
  else if (_.isArray(cache)) {
    shouldFetch = false
  }

  // If the cache is undefined, then assume that it will be populated by fetching
  else if (cache === null) {
    shouldFetch = true
  }

  // If invalidation constraints are provided, determine if the cache needs to be invalidated
  if (!shouldFetch && mapStateToUpdatedOn && invalidateAfter) {
    const updatedOn = mapStateToUpdatedOn(state)
    if (!updatedOn || moment().utc().diff(moment(updatedOn)) > moment.duration(invalidateAfter).asMilliseconds()) {
      shouldFetch = true
    }
  }

  let promises = {}
  if (shouldFetch && createFetchAction) {
    const createActionResult = Promise.resolve(dispatch(createFetchAction(key)))
    promises.creationAction = createActionResult
  }
  if (shouldFetch && fetchAction) {
    const fetchActionPromise = Promise.resolve(fetchAction(dispatch))
    promises.fetchAction = fetchActionPromise
  }

  if (!shouldFetch) {
    if (_.isPlainObject(cache) && key) {
      promises.cached = Promise.resolve(cache[key])
    } else if (_.isArray(cache)) {
      promises.cached = Promise.resolve(cache)
    }
  }

  // Dispatch the finishing action if provided
  if (createFinalAction) {
    const createFinalActionResult = Promise.resolve(dispatch(createFinalAction(key)))
    promises.creationFinalAction = createFinalActionResult
  }

  return promises
}
