import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { useGetAllActiveProducts } from '../../redux/api'
import { getOperationalCapacityMarketAssetDataNameMap } from '../../utility/asset-utils'
import { getTradingStart } from '../../utility/utility'
import ChangeLog from './ChangeLog'
import NewScheduledChangeCard from './NewScheduledChangeCard'
import OperationalCapacityCard from './OperationalCapacityCard'
import ScheduledChangesCard from './ScheduledChangesCard'

interface Props {
  asset: any
}
const useStyles = makeStyles(theme => ({
  ocContent: {
    height: '100%',
  },
}))

const Overrides: React.FC<Props> = props => {
  const { asset } = props
  const classes = useStyles()
  const market = _.get(asset, 'market')
  const marketId = _.get(market, 'marketId')
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'
  const permissions = useSelector(state => currentUserPermissionsSelector(state))
  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)
  const tradingStartTime = getTradingStart(market, moment())
  const { data: allActiveProducts, isLoading: allActiveProductDataIsLoading } = useGetAllActiveProducts(
    marketId,
    tradingStartTime,
  )

  const operationalCapacityMarketAssetDataNameMap = !allActiveProductDataIsLoading
    ? getOperationalCapacityMarketAssetDataNameMap(allActiveProducts)
    : {}
  const isOpCapProductDataAvailable = !_.isEmpty(operationalCapacityMarketAssetDataNameMap)
  return (
    <Box>
      <Grid container spacing={2}>
        {isBattery ? (
          <>
            <Grid item xs={12} className={classes.ocContent}>
              {!!asset && <ScheduledChangesCard asset={asset} />}
            </Grid>
            <Grid item xs={12} md={7} lg={5}>
              <Box mb={2} className={classes.ocContent}>
                {isOpCapProductDataAvailable && (
                  <OperationalCapacityCard
                    asset={asset}
                    showDelete={!!canAdjustRiskAppetite}
                    operationalCapacityMarketAssetDataNameMap={operationalCapacityMarketAssetDataNameMap}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={7} className={classes.ocContent}>
              {!!asset && <NewScheduledChangeCard asset={asset} />}
            </Grid>
            <Grid item xs={12}>
              {isOpCapProductDataAvailable && (
                <ChangeLog
                  asset={asset}
                  operationalCapacityMarketAssetDataNameMap={operationalCapacityMarketAssetDataNameMap}
                />
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {isOpCapProductDataAvailable && (
                <ChangeLog
                  asset={asset}
                  operationalCapacityMarketAssetDataNameMap={operationalCapacityMarketAssetDataNameMap}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                {isOpCapProductDataAvailable && (
                  <OperationalCapacityCard
                    asset={asset}
                    showDelete={!!canAdjustTradeSettings}
                    operationalCapacityMarketAssetDataNameMap={operationalCapacityMarketAssetDataNameMap}
                  />
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default Overrides
