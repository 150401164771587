import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import { useNotifier } from '@fluence/core'
import GeneralInfo from '../components/preferences/GeneralInfo'
import ChangePassword from '../components/preferences/ChangePassword'
import AssetSelection from '../components/preferences/AssetSelection'
import NotificationSelections from '../components/preferences/NotificationSelections'
import { getAssets } from '../redux/features/dashboard'
import { currentUser, editUserAction, setCurrentUser, setPasswordAction } from '../redux/features/user'
import { defaultAlertPreferenceFields } from '../components/userManagement/userManagementUtility'

const Preferences = props => {
  const {
    user,
    userIsLoading,
    alertGroups,
    assets,
    dispatchGetUser,
    dispatchSetUser,
    dispatchGetAllMarkets,
    dispatchGetAssets,
    dispatchEditUser,
    changePassword,
  } = props
  const { notifySuccess, notifyError } = useNotifier()

  const userName = _.get(user, 'name', '')
  const email = _.get(user, 'email', '')
  const timezone = _.get(user, 'preferences.marketAlerts.timezone', '')
  const phoneNumber = _.get(user, 'phoneNumber', '')
  const password = _.get(user, 'password', '')

  const blackoutStartTime = _.get(user, 'preferences.marketAlerts.blackoutStartTime', '')
  const blackoutEndTime = _.get(user, 'preferences.marketAlerts.blackoutEndTime', '')

  const selectedAssetIds = _.get(user, 'preferences.marketAlerts.assetIds', [])
  const selectedEmails = _.get(user, 'preferences.marketAlerts.email', [])
  const selectedSms = _.get(user, 'preferences.marketAlerts.sms', [])

  const [changePasswordModalOpen, setModalOpen] = React.useState(false)
  const handleChangPasswordModalOpen = () => {
    setModalOpen(true)
  }

  const handleChangPasswordModalClose = () => {
    setModalOpen(false)
  }

  const handleChangePasswordSubmit = password => {
    return changePassword(email, password).then(response => {
      if (response.error) {
        console.debug('Preferences - handleChangePasswordSubmit() - response: ', response)
      } else {
        notifySuccess('Password changed')
      }
      return response
    })
  }

  useEffect(() => {
    dispatchGetUser()
    dispatchGetAssets()
  }, [dispatchGetUser, dispatchGetAllMarkets, dispatchGetAssets])

  const dispatchNewEditUser = newUser => {
    const permissionsToAdd = {
      marketPermissions: [],
      customerPermissions: [],
      assetPermissions: [],
    }
    const permissionsToDelete = {
      marketPermissions: [],
      customerPermissions: [],
      assetPermissions: [],
    }

    const updatedUser = {
      ..._.omit(newUser, ['permissions']),
      permissionsToAdd: permissionsToAdd,
      permissionsToDelete: permissionsToDelete,
    }
    // ensure all default preferences exist, just to be safe
    const newUserAlertPreferences = _.get(updatedUser, 'preferences.marketAlerts')
    _.forEach(defaultAlertPreferenceFields, alertName => {
      if (!_.keys(newUserAlertPreferences).includes(alertName)) {
        updatedUser.preferences.marketAlerts[alertName] = []
      }
    })

    return dispatchEditUser(updatedUser)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <ChangePassword
          open={changePasswordModalOpen}
          onClose={handleChangPasswordModalClose}
          onSubmit={handleChangePasswordSubmit}
        />
        <GeneralInfo
          initialName={userName}
          initialEmail={email}
          initialBlackoutStartTime={blackoutStartTime}
          initialBlackoutEndTime={blackoutEndTime}
          initialPassword={password}
          initialPhone={phoneNumber}
          initialTimezone={timezone}
          getUser={dispatchGetUser}
          setUser={dispatchSetUser}
          saveUser={dispatchNewEditUser}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
          onChangePasswordOpen={handleChangPasswordModalOpen}
        />
        <br />
        <AssetSelection
          assets={assets}
          initialSelectedIds={selectedAssetIds}
          userIsLoading={userIsLoading}
          getUser={dispatchGetUser}
          setUser={dispatchSetUser}
          saveUser={dispatchNewEditUser}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <NotificationSelections
          alertGroups={alertGroups}
          initialEmailSelected={selectedEmails}
          initialSmsSelected={selectedSms}
          getUser={dispatchGetUser}
          setUser={dispatchSetUser}
          saveUser={dispatchNewEditUser}
          notifySuccess={notifySuccess}
          notifyError={notifyError}
        />
      </Grid>
    </Grid>
  )
}

Preferences.displayName = 'Preferences'

const mapStateToProps = state => {
  const user = _.get(state, 'user.current.payload', {})
  const userIsLoading = _.get(state, 'user.current.isLoading', false)
  const alertGroups = _.get(state, 'globalApp.marketData.alert_groups', [])
  const assets = _.get(state, 'dashboard.assets.payload', [])

  return {
    alertGroups,
    assets,
    user,
    userIsLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchGetUser: () => dispatch(currentUser.get()),
  dispatchSetUser: user => dispatch(setCurrentUser(user)),
  dispatchGetAssets: () => dispatch(getAssets()),
  dispatchEditUser: newUser => dispatch(editUserAction.put(newUser)),
  changePassword: (email, password) => dispatch(setPasswordAction.post(email, { password })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preferences)
