import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { makeStyles, fade } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Tooltip from '@material-ui/core/Tooltip'
import { PRODUCT_FCAS, PRODUCT_NAME_ENERGY } from '../pages/Bids'

const DISPLAY_NAME = 'Toggle'

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: 'transparent',
    },
    firstButton: {
      borderRadius: '15px 0 0 15px',
    },
    middleButton: {
      '&:not(:first-child)': {
        borderRadius: 0,
        marginLeft: 0,
        borderLeft: '1px solid #30353c',
      },
    },
    lastButton: {
      borderRadius: '0 15px 15px 0',
      '&:not(:first-child)': {
        borderLeft: '1px solid #30353c',
      },
    },
    orangeToggles: {
      backgroundColor: theme.custom.palette.colors.DARK_ORANGE,
      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: theme.custom.palette.colors.BRIGHT_ORANGE,
        '&.MuiToggleButton-root.Mui-selected:hover': {
          backgroundColor: theme.custom.palette.colors.BRIGHT_ORANGE,
        },
      },
      '&.MuiToggleButton-root:hover': {
        backgroundColor: fade(theme.custom.palette.colors.DARK_ORANGE, 0.5),
      },
    },
  }),
  { name: 'Toggle' },
)

const Toggle = React.forwardRef(function Toggle(props, ref) {
  const {
    disabled: disabledProp = false,
    exclusive = true,
    onChange,
    selected,
    values,
    variant = 'primary',
    highlightedItems = [],
    disableFCASExpansion = false,
  } = props
  const classes = useStyles()
  return (
    <ToggleButtonGroup
      ref={ref}
      value={selected}
      exclusive={exclusive}
      onChange={onChange}
      classes={{ root: classes.root }}
    >
      {values.map(({ disabled = false, key, text = '', toolTip = '', ...rest }, index) => {
        // Highlight products in FCAS Pill during expanded and non-expanded state
        const isHighlighted =
          highlightedItems.includes(key) ||
          (!_.isEmpty(highlightedItems.filter(item => item !== PRODUCT_NAME_ENERGY)) && text === PRODUCT_FCAS)
        const toggleClass = isHighlighted || (variant === 'orange' && !disabled) ? classes.orangeToggles : 'root'
        const disableFCASButton = text === PRODUCT_FCAS && disableFCASExpansion
        return (
          <ToggleButton
            disableFocusRipple
            disableRipple
            size="small"
            value={key}
            key={key}
            disabled={disabled || disabledProp || disableFCASButton}
            classes={{ root: toggleClass }}
            className={clsx({
              [classes.firstButton]: index === 0,
              [classes.middleButton]: index > 0 && index < values.length - 1,
              [classes.lastButton]: index === values.length - 1,
            })}
            {...rest}
          >
            <Tooltip title={toolTip} placement="top">
              <Typography variant="body2">{text}</Typography>
            </Tooltip>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
})

Toggle.displayName = DISPLAY_NAME

Toggle.propTypes = {
  onChange: PropTypes.func,
  selectedKey: PropTypes.any,
  selectedKeys: PropTypes.array,
  values: PropTypes.array.isRequired,
}

export default Toggle
