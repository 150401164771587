import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import InputNumberFormat from '../../InputNumberFormat'
import { formatMoney, perKWToPerMW } from '../../../utility/utility'
import { getInputTextFieldProps } from './ScheduledChangesInputUtil'

const DISPLAY_NAME = 'DefaultScheduledChangesInput'

const useStyles = makeStyles(theme => ({
  priceInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  impliedBox: {
    minWidth: 280,
  },
  smallInputTextField: {
    maxWidth: theme.spacing(24),
  },
  largeInputTextField: {
    minWidth: theme.spacing(30),
  },
  largeInputLabelWidth: {
    minWidth: theme.spacing(30),
  },
  label: {
    marginRight: 0,
    minWidth: theme.spacing(12),
  },
  impliedText: {
    fontSize: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  noImpliedSection: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: '1 0 auto',
  },
  flexFixed: {
    flex: '0 0 auto',
  },
  ml1: {
    marginLeft: theme.spacing(),
  },
  pr4: {
    paddingRight: theme.spacing(4),
  },
}))

const DefaultScheduledChangesInput: React.FC<DefaultScheduledChangesProps> = props => {
  const {
    checkInputValueAllowed,
    handleChange,
    handleChangeCheckbox,
    inputConfigs = [],
    inputDecimalLimit = 8,
    isEndDateEnabled = false,
    isReturnInput = false,
    largeInputTextField = false,
    precision = Infinity,
    touched,
    values,
  } = props
  const classes = useStyles()

  return (
    <>
      {inputConfigs.map((inputConfig: InputConfig, index: number) => {
        const {
          allowSubmitEmptyInput = false,
          current,
          disableImplied = true,
          displayLabel = '',
          emptyInputHelperText = '',
          endAdornment = '',
          endTextFieldDisplayLabel = '',
          endTextFieldLabel,
          inputAdornment = '$',
          label = index.toString(),
          override = false,
          overrideLabel = '',
          overrideValue,
        } = inputConfig

        // get textfield props
        const dataLabelToUse = isReturnInput ? endTextFieldLabel : label
        const {
          inputLabel,
          allowNegativeInput,
          showBepPriceError,
          showInputValueError,
          overrideCheckboxLabel,
          isOverrideCheckboxChecked,
        } = getInputTextFieldProps(values, inputConfig, touched, dataLabelToUse, inputDecimalLimit)

        // get display labels
        const availableStartDisplayLabel = _.size(displayLabel) !== 0 ? displayLabel : label
        const availableEndDisplayLabel =
          _.size(endTextFieldDisplayLabel) !== 0 ? endTextFieldDisplayLabel : endTextFieldLabel
        const applicableDisplayLabel = isReturnInput ? availableEndDisplayLabel : availableStartDisplayLabel
        const currentValueDisplayLabel = isReturnInput
          ? applicableDisplayLabel.toUpperCase()
          : `CURRENT ${applicableDisplayLabel.toUpperCase()}`
        const newStartInputDisplayLabel = override
          ? `${applicableDisplayLabel.toUpperCase()} OVERRIDE`
          : `NEW ${applicableDisplayLabel.toUpperCase()}`
        const finalNewInputDisplayLabel = isReturnInput
          ? availableEndDisplayLabel.toUpperCase()
          : newStartInputDisplayLabel

        // check input validity
        const isNewInputDisabled = isReturnInput ? !isEndDateEnabled : override && isOverrideCheckboxChecked
        const isNewInputRequired =
          (isReturnInput && isEndDateEnabled) || !override || (override && !isOverrideCheckboxChecked)
        const hasInputError = override ? showBepPriceError : showInputValueError
        const displayInputError = isReturnInput ? isEndDateEnabled && hasInputError : hasInputError

        // show helper text
        const price = _.get(values, inputLabel)
        const currEmptyInputFieldHelperText =
          allowSubmitEmptyInput && isNaN(parseFloat(price)) && _.get(_.toString(price), 'length', 0) === 0
            ? emptyInputHelperText
            : ''
        const displayEmptyFieldHelperText = !isEndDateEnabled && isReturnInput ? '' : currEmptyInputFieldHelperText
        return (
          <div key={dataLabelToUse} className={classes.priceInputs}>
            {!isReturnInput && (
              <div className={clsx(classes.flexFixed, classes.pr4)}>
                <TextField
                  disabled
                  name="current"
                  className={clsx({
                    [classes.smallInputTextField]: !largeInputTextField,
                    [classes.largeInputTextField]: largeInputTextField,
                  })}
                  label={currentValueDisplayLabel}
                  value={_.isNumber(current) ? (precision ? _.round(current, precision) : current) : '-'}
                  margin="normal"
                  InputProps={{
                    startAdornment: inputAdornment ? (
                      <InputAdornment position="start">{inputAdornment}</InputAdornment>
                    ) : null,
                    endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: { root: clsx({ [classes.largeInputLabelWidth]: largeInputTextField }) },
                  }}
                />
              </div>
            )}
            <div className={clsx(classes.flexFixed, classes.impliedBox)}>
              <div className={classes.flex}>
                {!isReturnInput && (
                  <Box className={clsx(classes.flex, classes.flexGrow)} visibility={override ? 'visible' : 'hidden'}>
                    <FormControl
                      className={clsx({
                        [classes.formControl]: true,
                        [classes.noImpliedSection]: !isOverrideCheckboxChecked,
                      })}
                    >
                      <InputLabel
                        shrink
                        className={clsx({
                          [classes.noImpliedSection]: !isOverrideCheckboxChecked,
                        })}
                      >
                        {overrideLabel}
                      </InputLabel>
                      <Box mt={2}>
                        <FormControlLabel
                          className={classes.label}
                          color="primaryText"
                          control={
                            <Checkbox
                              disabled={disableImplied}
                              checked={!disableImplied && isOverrideCheckboxChecked}
                              onChange={handleChangeCheckbox}
                              value={overrideCheckboxLabel}
                              color="primary"
                              className={clsx({
                                [classes.noImpliedSection]: !isOverrideCheckboxChecked,
                              })}
                            />
                          }
                          label={
                            <Typography className={classes.impliedText}>
                              {_.isNumber(overrideValue) ? formatMoney(overrideValue * perKWToPerMW, 2) : '-'}
                            </Typography>
                          }
                        />
                      </Box>
                    </FormControl>
                  </Box>
                )}
                <div className={clsx({ [classes.flexGrow]: true, [classes.ml1]: !isReturnInput })}>
                  <TextField
                    name="price"
                    className={clsx({
                      [classes.smallInputTextField]: !largeInputTextField,
                      [classes.largeInputTextField]: largeInputTextField,
                    })}
                    disabled={isNewInputDisabled}
                    required={isNewInputRequired}
                    label={finalNewInputDisplayLabel}
                    margin="normal"
                    helperText={displayEmptyFieldHelperText}
                    onChange={handleChange(inputLabel)}
                    value={_.get(values, inputLabel, '')}
                    InputProps={{
                      startAdornment: inputAdornment ? (
                        <InputAdornment position="start">{inputAdornment}</InputAdornment>
                      ) : null,
                      endAdornment: endAdornment ? (
                        <InputAdornment position="end">{endAdornment}</InputAdornment>
                      ) : null,
                      inputComponent: InputNumberFormat,
                      inputProps: {
                        isAllowed: (values: any) => checkInputValueAllowed(values, precision, allowNegativeInput),
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      classes: { root: clsx({ [classes.largeInputLabelWidth]: largeInputTextField }) },
                    }}
                    error={displayInputError}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

interface InputConfig {
  allowSubmitEmptyInput: boolean
  current: number
  disableImplied: boolean
  displayLabel: string
  emptyInputHelperText: string
  endAdornment: string
  endTextFieldDisplayLabel: string
  endTextFieldLabel: string
  inputAdornment: string
  label: string
  override: boolean
  overrideLabel: string
  overrideValue: number
}

interface DefaultScheduledChangesProps {
  checkInputValueAllowed: Function
  handleChange: any
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  inputConfigs: Array<InputConfig>
  inputDecimalLimit: number
  isEndDateEnabled: boolean
  isReturnInput: boolean
  largeInputTextField: boolean
  precision: number
  touched: object
  values: object
}

DefaultScheduledChangesInput.displayName = DISPLAY_NAME

export default DefaultScheduledChangesInput
