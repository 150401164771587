export const editUserBase = '/userManagement/editUser'
export const routes = {
  BASENAME: `/markets`,
  DASHBOARD: `/`,
  FINANCIAL_PERFORMANCE: `/financialPerformance`,
  LOGIN: `/login`,
  TEMP_CREDENTIALS: `/tempCredentials`,
  PREFERENCES: '/preferences',
  USER_MANAGEMENT: `/userManagement`,
  CREATE_USER: `/userManagement/createUser`,
  EDIT_USER: `${editUserBase}/:userId`,

  ASSET: {
    path: `/asset/:assetId`,
    nestedRoutes: {
      ASSET_OPERATIONS: `/assetOperations`,
      BIDS: `/bids`,
      FORECASTS: `/forecasts`,
      MARKETS_RESULTS: `/marketResults`,
      OUTAGE_MANAGEMENT: `/assetOperations/outageManagement`,
      TRADE_SETTINGS: `/tradingSettings`,
    },
  },
}

export default routes
