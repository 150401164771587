import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import { withStyles } from '@material-ui/styles'
import { Box, Typography, Tooltip } from '@material-ui/core'
import { TooltipContent } from '../TooltipContent'
import {
  allActiveBepResource,
  allActiveLgcResource,
  allBidSpreadSettingsResource,
  allFCASResource,
  allPriceForecastResource,
  bepImpliedLogResource,
  costSettingResource,
  getRegulationThroughputRange,
  risksResource,
  selectRegulationThroughputSchedule,
} from '../../redux/features/setting'
import {
  convertPricePerkWhToMWh,
  decimalToPercentage,
  formatCalendarDateAsTradingDay,
  formatMoney,
  perKWToPerMW,
} from '../../utility/utility'
import {
  BEP_SETTING_TAG,
  BID_SPREAD_SETTING_TAG,
  BID_STRATEGY_DISPLAY_NAMES,
  BID_STRATEGY_SETTING_TAG,
  DATETIME_FORMAT,
  FORECAST_SETTING_TAG,
  LGC_SETTING_TAG,
  PRODUCT_NAMES,
  PRODUCT_TYPES,
  THROUGHPUT_TAG,
  DEGRADATION_COST_SETTING,
} from '../../utility/constants'
import { getProductIdByGivenNameType, getEnabledProductKeys } from '../../utility/asset-utils'

import Card from '../Card'

const styles = theme => ({
  cardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    minHeight: 285,
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  panelBody: {
    overflow: 'auto',
    height: 270,
  },
  list: {
    marginTop: 0,
    paddingLeft: theme.spacing(3),
    cursor: 'default',
    listStyle: 'none',

    '& li::before': {
      color: '#808697',
      content: `"•"`,
      display: 'inline-block',
      fontWeight: 'bold',
      width: '1em',
      marginLeft: '-1em',
    },
  },
  tooltip: {
    maxWidth: 'none',
  },
})

const RiskLog = props => {
  const {
    asset,
    classes,
    bepData,
    bepImplied,
    bidSpread = [],
    costSettings,
    fcasData,
    lgcData,
    onInitialize,
    priceForecastData,
    regulationThroughput: regulationThroughputState,
    risks = [],
  } = props

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
    if (commercialOnlineDate) {
      setIsInitialized(false)
      onInitialize(asset).then(() => setIsInitialized(true))
    }
  }, [asset, onInitialize])

  const allRisks = _.get(risks, 'error') ? [] : [...risks]

  const bep = _.isArray(bepData) ? bepData.filter(bep => !bep.deleted) : []
  const lgc = _.isArray(lgcData) ? lgcData.filter(lgc => !lgc.deleted) : []

  const priceForecast =
    _.get(priceForecastData, 'payload', []).filter(
      priceForecast => !_.isNil(_.get(priceForecast, `data.use_aemo_pre_dispatch`)),
    ) || []

  const fcas = !_.get(fcasData, 'payload', []).isError ? fcasData.payload : []

  const products = _.get(asset, 'products')
  const raiseRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.GEN, products)
  const raiseRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.LOAD, products)
  const raiseRegThroughput = selectRegulationThroughputSchedule(
    regulationThroughputState,
    asset.assetId,
    raiseRegGenProductId,
    raiseRegLoadProductId,
    true,
  )
  const lowerRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.GEN, products)
  const lowerRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.LOAD, products)
  const lowerRegThroughput = selectRegulationThroughputSchedule(
    regulationThroughputState,
    asset.assetId,
    lowerRegGenProductId,
    lowerRegLoadProductId,
    true,
  )
  const degradationCostSettings = costSettings
    .filter(costSetting => !costSetting.deleted)
    .map(costSetting => ({
      ...costSetting,
      startTime: moment(costSetting.startTime),
    }))

  const allRecords = allRisks
    .concat(bep)
    .concat(lgc)
    .concat(priceForecast)
    .concat(fcas)
    .concat(bidSpread)
    .concat(raiseRegThroughput)
    .concat(lowerRegThroughput)
    .concat(degradationCostSettings)
    .sort((x, y) => {
      return moment(x.startTime) - moment(y.startTime)
    })

  const [displayedRecords, setDisplayedRecords] = useState([])
  const assetString = JSON.stringify(asset)
  const recordCount = useRef(0)
  useEffect(() => {
    if (isInitialized && recordCount.current !== allRecords.length) {
      const asset = JSON.parse(assetString)
      const prevRecords = {}
      const records = []

      allRecords.forEach(rec => {
        const prevRec = _.get(prevRecords, rec.tag)
        prevRecords[rec.tag] = rec

        const title = () => <Title asset={asset} record={rec} />
        const tooltip = <TooltipTitle asset={asset} record={rec} previousRecord={prevRec} bepImplied={bepImplied} />
        records.push({
          ...rec,
          previousRecord: prevRec,
          title,
          tooltip,
        })
      })

      setDisplayedRecords(records.reverse())
      recordCount.current = records.length
    }
  }, [assetString, allRecords, bepImplied, isInitialized, recordCount])

  return (
    <Card
      title="Bidding Strategy Adjustment Log"
      titleTypographyProps={{ variant: 'h2' }}
      classes={{
        root: classes.root,
        content: classes.cardContent,
      }}
      inProgress={!isInitialized}
    >
      {isInitialized && (
        <Box className={classes.panelBody}>
          {displayedRecords.length > 0 ? (
            <ul className={classes.list}>
              {displayedRecords.map((elem, index) => {
                const title = elem.title()
                return (
                  <li key={index}>
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={elem.tooltip}
                      arrow
                      placement="right-start"
                      key={index}
                    >
                      <Typography variant="body1" component="span">
                        {title}
                      </Typography>
                    </Tooltip>
                  </li>
                )
              })}
            </ul>
          ) : (
            <Typography variant="body1">None</Typography>
          )}
        </Box>
      )}
    </Card>
  )
}

const mapStateToProps = state => {
  const risksData = _.get(state, 'setting.risks')
  const risks = _.get(risksData, 'payload')
  const bepData = _.get(state, 'setting.allActiveBep.payload', [])
  const lgcData = _.get(state, 'setting.allActiveLgc.payload', [])
  const bepImplied = _.get(state, 'setting.bepImpliedLog', {})
  const bepImpliedValue = _.get(bepImplied, 'payload.value', null)
  const priceForecastData = _.get(state, 'setting.allPriceForecast')
  const fcasData = _.get(state, 'setting.allFCAS')
  const bidSpreadData = _.get(state, 'setting.allBidSpread')
  const bidSpread = _.get(bidSpreadData, 'payload')
  const endOfInterval = _.get(state, 'dispatchInterval.status.endOfInterval')
  const regulationThroughput = _.get(state, 'setting.riskLogRegulationThroughput')
  const costSettings = _.get(state, 'setting.riskLogCostSetting.payload')

  return {
    bepData,
    bepImplied: bepImpliedValue,
    bidSpread,
    costSettings,
    endOfInterval,
    fcasData,
    lgcData,
    regulationThroughput,
    risks,
    priceForecastData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitialize: asset => {
      const now = moment()
      const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        const riskPromise = dispatch(risksResource.get(asset.assetId, startTime, moment(now)))
        const allActiveBepPromise = dispatch(allActiveBepResource.get(asset.assetId, startTime, moment(now)))
        const allActiveLgcPromise = dispatch(allActiveLgcResource.get(asset.assetId, startTime, moment(now)))
        const bepImplementedLogPromise = dispatch(bepImpliedLogResource.get(asset.assetId, moment(now), 1))
        const allPriceForecastPromise = dispatch(allPriceForecastResource.get(asset.assetId, startTime, moment(now)))
        const allFCASPromise = dispatch(allFCASResource.get(asset.assetId, startTime, moment(now)))
        const allBidSpreadSettingsPromise = dispatch(
          allBidSpreadSettingsResource.get(asset.assetId, startTime, moment(now)),
        )

        const products = _.get(asset, 'products')
        const productTypes = _.get(asset, 'productTypes')
        const productNames = _.get(asset, 'productNames')
        const configuration = _.get(asset, 'data.configuration')
        const enabledProductKeys = getEnabledProductKeys(productTypes, productNames, configuration)

        const raiseRegGenKey = `${PRODUCT_NAMES.RAISEREG}_${PRODUCT_TYPES.GEN}`
        let raiseRegGenPromise = Promise.resolve([])
        if (enabledProductKeys.includes(raiseRegGenKey)) {
          const raiseRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.GEN, products)
          raiseRegGenPromise = dispatch(getRegulationThroughputRange(asset.assetId, raiseRegGenProductId, startTime))
        }

        const raiseRegLoadKey = `${PRODUCT_NAMES.RAISEREG}_${PRODUCT_TYPES.LOAD}`
        let raiseRegLoadPromise = Promise.resolve([])
        if (enabledProductKeys.includes(raiseRegLoadKey)) {
          const raiseRegLoadProductId = getProductIdByGivenNameType(
            PRODUCT_NAMES.RAISEREG,
            PRODUCT_TYPES.LOAD,
            products,
          )
          raiseRegLoadPromise = dispatch(getRegulationThroughputRange(asset.assetId, raiseRegLoadProductId, startTime))
        }

        const lowerRegGenKey = `${PRODUCT_NAMES.LOWERREG}_${PRODUCT_TYPES.GEN}`
        let lowerRegGenPromise = Promise.resolve([])
        if (enabledProductKeys.includes(lowerRegGenKey)) {
          const lowerRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.GEN, products)
          lowerRegGenPromise = dispatch(getRegulationThroughputRange(asset.assetId, lowerRegGenProductId, startTime))
        }

        const lowerRegLoadKey = `${PRODUCT_NAMES.LOWERREG}_${PRODUCT_TYPES.LOAD}`
        let lowerRegLoadPromise = Promise.resolve([])
        if (enabledProductKeys.includes(lowerRegLoadKey)) {
          const lowerRegLoadProductId = getProductIdByGivenNameType(
            PRODUCT_NAMES.LOWERREG,
            PRODUCT_TYPES.LOAD,
            products,
          )
          lowerRegLoadPromise = dispatch(getRegulationThroughputRange(asset.assetId, lowerRegLoadProductId, startTime))
        }

        const costSettingPromise = dispatch(costSettingResource.get(asset.assetId, startTime, moment(now)))

        return Promise.all([
          riskPromise,
          allActiveBepPromise,
          allActiveLgcPromise,
          bepImplementedLogPromise,
          allPriceForecastPromise,
          allFCASPromise,
          allBidSpreadSettingsPromise,
          raiseRegGenPromise,
          raiseRegLoadPromise,
          lowerRegGenPromise,
          lowerRegLoadPromise,
          costSettingPromise,
        ])
      }
      return Promise.resolve()
    },
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RiskLog))

const Title = ({ asset, record }) => {
  const timezone = _.get(asset, 'market.data.timezone')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const datetime = formatCalendarDateAsTradingDay(record.startTime, timezone, marketStartHour, DATETIME_FORMAT)
  const title = `${datetime} - ${_.get(record, 'createdBy.name')}`
  return title
}

const TooltipTitle = ({ asset, record, previousRecord, bepImplied }) => {
  let result = null
  const products = _.get(asset, 'products')
  const raiseRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.GEN, products)
  const raiseRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.LOAD, products)
  const lowerRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.GEN, products)
  const lowerRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.LOAD, products)
  if (record.tag === 'risk_setting') {
    const logs = []
    const isEqual = _.isEqual(_.get(previousRecord, 'data'), _.get(record, 'data'))
    asset.productNames.forEach(productName => {
      const productDisplayName = asset.market.data.product_display_names[productName]
      const newValue = _.get(record, `data.${productName}`, '').toLocaleString('en', {
        style: 'percent',
        maximumSignificantDigits: 2,
        maximumFractionDigits: 0,
      })
      const prevValue = _.get(previousRecord, `data.${productName}`, '').toLocaleString('en', {
        style: 'percent',
        maximumSignificantDigits: 2,
        maximumFractionDigits: 0,
      })
      const newLogEntry = {
        label: productDisplayName,
        newValue,
        prevValue,
      }
      if (!previousRecord || isEqual || prevValue !== newValue) {
        logs.push(newLogEntry)
      }
    })
    result = <TooltipContent title="Bidding Risk Appetite" changes={logs} firstHeader="" />
  } else if (record.tag === BEP_SETTING_TAG) {
    const value = _.get(record, 'data.break_even_price_per_kwh')
    const usedImplied = _.get(record, 'data.usedImpliedValue')
    const newValue =
      value !== null ? formatMoney(value * perKWToPerMW, 2) : formatMoney((usedImplied || bepImplied) * perKWToPerMW, 2)

    const oldValue = _.get(previousRecord, 'data.break_even_price_per_kwh')
    const oldImplied = _.get(previousRecord, 'data.usedImpliedValue')
    const prevValue = !_.isNil(oldValue)
      ? formatMoney(oldValue * perKWToPerMW, 2)
      : formatMoney((oldImplied || bepImplied) * perKWToPerMW, 2)

    const newLogEntry = {
      label: 'BEP',
      newValue,
      newValuePostfix: value === null && <sup>*</sup>,
      prevValue,
      prevValuePostfix: _.isNil(oldValue) && <sup>*</sup>,
      units: `/MWh`,
    }
    const footer = (_.isNil(oldValue) || value === null) && (
      <em>
        <span>
          <sup>*</sup> = implied
        </span>
      </em>
    )
    result = <TooltipContent changes={[newLogEntry]} firstHeader="" footer={footer} />
  } else if (record.tag === LGC_SETTING_TAG) {
    const value = _.get(record, 'data.lgc_price_per_kwh')
    const newValue = formatMoney(value * 1000, 2)
    const oldValue = _.get(previousRecord, 'data.lgc_price_per_kwh')
    const prevValue = formatMoney(oldValue * 1000, 2)
    const newLogEntry = {
      label: 'LGC',
      newValue,
      prevValue,
      units: `/MWh`,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  } else if (record.tag === FORECAST_SETTING_TAG) {
    const newValue = _.get(record, 'data.use_aemo_pre_dispatch') ? 'AEMO PD' : 'Fluence'
    const prevValue = _.get(previousRecord, 'data.use_aemo_pre_dispatch') ? 'AEMO PD' : 'Fluence'
    const newLogEntry = {
      label: 'Price Forecast',
      newValue,
      prevValue,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  } else if (record.tag === BID_STRATEGY_SETTING_TAG) {
    // CR-FCAS AND Default Bid Cards use this setting
    const newFcasValue = _.get(record, 'data.use_fcas_cost_recovery_in_opportunity_price') ? 'ON' : 'OFF'
    const previousFcasValue = _.get(previousRecord, 'data.use_fcas_cost_recovery_in_opportunity_price') ? 'ON' : 'OFF'

    const newDefaultBidMethod = _.get(record, 'data.default_bid_method')
    const newDefaultBidValue = _.get(BID_STRATEGY_DISPLAY_NAMES, newDefaultBidMethod)
    const previousDefaultBidMethod = _.get(previousRecord, 'data.default_bid_method')
    const previousDefaultBidValue = _.get(BID_STRATEGY_DISPLAY_NAMES, previousDefaultBidMethod)

    const noPrevious = _.isNil(previousRecord)
    const isSame = newFcasValue === previousFcasValue && newDefaultBidMethod === previousDefaultBidMethod

    const logs = []
    if (noPrevious || isSame || newFcasValue !== previousFcasValue) {
      const newLogEntry = {
        label: 'CR-FCAS',
        newValue: newFcasValue,
        prevValue: previousFcasValue,
      }
      logs.push(newLogEntry)
    }
    if (noPrevious || isSame || newDefaultBidMethod !== previousDefaultBidMethod) {
      const newLogEntry = {
        label: 'Default Bid',
        newValue: newDefaultBidValue,
        prevValue: previousDefaultBidValue,
      }
      logs.push(newLogEntry)
    }
    result = <TooltipContent title="Bid Strategy" changes={logs} />
  } else if (record.tag === BID_SPREAD_SETTING_TAG) {
    const upper = _.get(record, 'data.upper_quantile')
    const lower = _.get(record, 'data.lower_quantile')
    const newValue = !upper || !lower ? '-' : `Fluence P${lower * 100}, P${upper * 100}`

    const prevUpper = _.get(previousRecord, 'data.upper_quantile')
    const prevLower = _.get(previousRecord, 'data.lower_quantile')
    const prevValue = !prevUpper || !prevLower ? '-' : `Fluence P${prevLower * 100}, P${prevUpper * 100}`

    const newLogEntry = {
      label: 'Price Forecast',
      newValue,
      prevValue,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  } else if (record.tag === `${THROUGHPUT_TAG} ${raiseRegGenProductId} ${raiseRegLoadProductId}`) {
    const createValueDisplayStr = values => {
      const hasGen = _.isNumber(_.get(values, 0))
      const genValue = hasGen ? `GEN: ${decimalToPercentage(_.get(values, 0), 2)}%` : ''

      const hasLoad = _.isNumber(_.get(values, 1))
      const loadValue = hasLoad ? `LOAD: ${decimalToPercentage(_.get(values, 1), 2)}%` : ''

      return `${genValue} ${hasGen && hasLoad ? ', ' : ''}${loadValue}`
    }
    const newValue = createValueDisplayStr(_.get(record, 'value'))
    const prevValue = createValueDisplayStr(_.get(previousRecord, 'value'))
    const newLogEntry = {
      label: 'Regulation Raise Utilisation',
      newValue,
      prevValue,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  } else if (record.tag === `${THROUGHPUT_TAG} ${lowerRegGenProductId} ${lowerRegLoadProductId}`) {
    const createValueDisplayStr = values => {
      const hasGen = _.isNumber(_.get(values, 0))
      const genValue = hasGen ? `GEN: ${decimalToPercentage(_.get(values, 0), 2)}%` : ''

      const hasLoad = _.isNumber(_.get(values, 1))
      const loadValue = hasLoad ? `LOAD: ${decimalToPercentage(_.get(values, 1), 2)}%` : ''

      return `${genValue} ${hasGen && hasLoad ? ', ' : ''}${loadValue}`
    }
    const newValue = createValueDisplayStr(_.get(record, 'value'))
    const prevValue = createValueDisplayStr(_.get(previousRecord, 'value'))
    const newLogEntry = {
      label: 'Regulation Lower Utilisation',
      newValue,
      prevValue,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  } else if (record.tag === DEGRADATION_COST_SETTING) {
    const createValueDisplayStr = data => {
      const hasCharge = _.isNumber(_.get(data, 'charge'))
      const genValue = hasCharge ? `CHARGE: $${convertPricePerkWhToMWh(_.get(data, 'charge'))}` : ''

      const hasDischarge = _.isNumber(_.get(data, 'discharge'))
      const loadValue = hasDischarge ? `DISCHARGE: $${convertPricePerkWhToMWh(_.get(data, 'discharge'))}` : ''

      return `${genValue} ${hasCharge && hasDischarge ? ', ' : ''}${loadValue}`
    }
    const newValue = createValueDisplayStr(_.get(record, 'data'))
    const prevValue = createValueDisplayStr(_.get(previousRecord, 'data'))
    const newLogEntry = {
      label: 'Throughput Cost',
      newValue,
      prevValue,
    }
    result = <TooltipContent changes={[newLogEntry]} />
  }
  return result
}

