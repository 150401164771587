import React, { useRef } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress } from '@material-ui/core'
import { xAxis, yAxis, graphOptions } from '../../graphs/utility/defaults'
import { formatAndPadValue } from '../../graphs/utility/breakpoints'
import CanvasJSChart from './CanvasJSChart'

const DISPLAY_NAME = 'GraphImpl'
const useStyles = makeStyles(
  theme => ({
    container: {
      position: 'relative',
    },
    blocker: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 10,
      background: theme.palette.background.paper,
    },
    blocker2: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: 71,
      height: 13,
      background: theme.palette.background.paper,
    },
  }),
  { name: DISPLAY_NAME },
)

const timezone = 'Etc/GMT-10'
const dayBegin = moment().tz(timezone).startOf('day').unix()
const dayEnd = moment().tz(timezone).endOf('day').unix()
const placeholderOptions = {
  ...graphOptions,
  axisX: {
    ...xAxis,
    interval: 3600,
    labelFormatter: e => moment.unix(e.value).tz(timezone).format('HH:MM'),
    viewportMinimum: dayBegin,
    viewportMaximum: dayEnd,
  },
  axisY: {
    ...yAxis,
    interval: 200,
    viewportMinimum: 0,
    viewportMaximum: 1000,
    labelFormatter: formatAndPadValue({ prefix: '', suffix: '' }),
  },
  axisY2: {
    ...yAxis,
    labelFormatter: e => '',
    margin: 25,
  },
  data: [
    {
      type: 'line',
      dataPoints: [],
    },
  ],
  zoomType: 'xy',
  height: 200,
}

function GraphImpl(props) {
  const defaultOptionsRef = useRef({ ...placeholderOptions, height: props.height })
  const defaultOptions = defaultOptionsRef.current
  const { name, height } = props
  const classes = useStyles()

  const getGraphOptions = ownProps => {
    const { formatXAxis, formatX2Axis, formatYAxis, formatSeries, formatTooltip, showInProgress } = ownProps
    const { rangeChanged, data, context, range, legend, height, currentTime } = ownProps
    const args = {
      ...context,
      range,
      data,
      legend,
      currentTime,
      height,
    }
    if (showInProgress || ![formatXAxis, formatX2Axis, formatYAxis, formatSeries, formatTooltip].every(_.isFunction)) {
      return defaultOptions
    }

    try {
      args.series = formatSeries(args)
      if (!_.isArray(args.series) || args.series.some(_.isNil)) {
        throw new Error('GraphImpl - invalid series data')
      }
    } catch (e) {
      console.info('GraphImpl - formatSeries() - EXCEPTION: ', e)
      return defaultOptions
    }

    if (!_.isArray(args.series) || !args.series.some(s => s.visible === true)) {
      return defaultOptions
    }

    const newOptions = {
      ...graphOptions,
      data: _.reverse(args.series),
      rangeChanged,
      height,
      axisY2: defaultOptions.axisY2, // note that currently there are no real axisY2. this is a fake axisY2 to prevent 4:00 at the end get truncated
    }

    try {
      newOptions.axisX = formatXAxis(args)
    } catch (e) {
      console.info('GraphImpl - formatXAxis() - EXCEPTION: ', e)
      console.info('GraphImpl - formatXAxis() - args: ', args)
      return defaultOptions
    }

    try {
      newOptions.axisX2 = formatX2Axis ? formatX2Axis(args) : {}
    } catch (e) {
      console.info('GraphImpl - formatX2Axis() - EXCEPTION: ', e)
      return defaultOptions
    }

    try {
      newOptions.axisY = formatYAxis(args)
    } catch (e) {
      console.info('GraphImpl - formatYAxis() - EXCEPTION: ', e)
      return defaultOptions
    }

    try {
      newOptions.toolTip = formatTooltip(args)
    } catch (e) {
      console.info('GraphImpl - formatTooltip() - EXCEPTION: ', e)
      return defaultOptions
    }

    return newOptions
  }

  const options = getGraphOptions(props)
  const { showInProgress } = props

  return (
    <div className={classes.container}>
      {showInProgress && (
        <Box position="absolute" zIndex="100" left="50%" top="40%">
          <CircularProgress />
        </Box>
      )}
      <CanvasJSChart name={name} containerProps={{ height }} options={options} onRef={props.onRef} />
    </div>
  )
}
GraphImpl.displayName = DISPLAY_NAME

export default GraphImpl
