import React, { useState } from 'react'
import _ from 'lodash'
import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core'
import {
  useNotifier,
  UserSubmitBar,
  UserPermissionsForm,
  UserInfoForm,
  getCreateEditUserRequestBody,
  getLevelIdFromNodeId,
} from '@fluence/core'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ROUTES from '../../config/routes'
import { AEMO_MARKET, ASSET_LEVEL, CUSTOMER_LEVEL, HAS_MFA, MARKET_LEVEL } from '../../utility/constants'
import {
  activate,
  createUser,
  deactivate,
  enableMfa,
  resendMFA,
  resetPassword,
  updateUser,
  useFetchAssetPermission,
  useFetchCustomerChildren,
  useFetchCustomerLevelPermissions,
  useFetchGlobalPermissions,
  useFetchMarketLevelChildren,
} from '../../redux/api.js'
import colors from '../../config/colors'
import { getFormFieldDict, getNemDefaultPreferences } from './userManagementUtility'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    color: colors.WHITE,
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', colors.OUTER_SPACE)}`,
  },
  cardContent: {
    paddingTop: theme.spacing(1),
  },
}))

const genericErrorMsg = 'Something went wrong'

function UserForm(props) {
  const classes = useStyles()
  const { notifySuccess, notifyError } = useNotifier()
  const history = useHistory()
  const { userId, selectedUser, mutateAdministrableUsers, currentUser } = props
  const isEditMode = !_.isNil(userId)

  const [tree, setTree] = useState()
  const getEditUserPermissions = () => {
    return selectedUser.permissions
  }
  const defaultPermissions = {
    marketPermissions: [],
    customerPermissions: [],
    assetPermissions: [],
  }
  const getDefaultPermissions = isEditMode => {
    return isEditMode ? getEditUserPermissions() : defaultPermissions
  }

  const formFieldDict = getFormFieldDict(isEditMode, selectedUser, currentUser)
  const getFormFieldValues = formDict => {
    return _.keys(formDict).reduce((acc, fieldName) => {
      acc[fieldName] = formDict[fieldName].defaultValue
      return acc
    }, {})
  }
  const [formPermissions, setFormPermissions] = useState(getDefaultPermissions(isEditMode))

  const { trigger: marketLevelChildrenTrigger } = useFetchMarketLevelChildren()
  const { trigger: globalPermissionTrigger } = useFetchGlobalPermissions()
  const { trigger: customerChildrenTrigger } = useFetchCustomerChildren()
  const { trigger: customerPermissionTrigger } = useFetchCustomerLevelPermissions()
  const assetChildrenTrigger = () => {
    return Promise.resolve([])
  }
  const { trigger: assetPermissionTrigger } = useFetchAssetPermission()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: getFormFieldValues(formFieldDict),
  })
  const isActive = isEditMode ? selectedUser.isActive : false

  const getMarketLevelChildren = () => {
    return marketLevelChildrenTrigger().then(r => {
      return !r.error ? r : []
    })
  }
  const getMarketLevelPermissions = () => {
    return globalPermissionTrigger().then(r => {
      return !r.error ? r : []
    })
  }
  const getCustomerChildren = customerId => {
    return customerChildrenTrigger(customerId).then(r => {
      return !r.error ? r : []
    })
  }
  const getCustomerPermissions = customerId => {
    return customerPermissionTrigger(customerId).then(r => {
      return !r.error ? r : []
    })
  }
  const getAssetChildren = assetId => {
    return assetChildrenTrigger(assetId).then(r => {
      return !r.error ? r : []
    })
  }
  const getAssetPermission = assetId => {
    return assetPermissionTrigger(assetId).then(r => {
      return !r.error ? r : []
    })
  }

  const fetchPermissions = nodeId => {
    const [level, id] = getLevelIdFromNodeId(nodeId)
    if (level === MARKET_LEVEL) {
      return getMarketLevelPermissions()
    }
    if (level === CUSTOMER_LEVEL) {
      return getCustomerPermissions(id)
    }
    if (level === ASSET_LEVEL) {
      return getAssetPermission(id)
    }
    return []
  }

  const handleResetPass = () => {
    resetPassword(selectedUser.email)
      .then(r => {
        if (r.ok) {
          notifySuccess('Reset password email sent')
        } else {
          notifyError(genericErrorMsg)
        }
      })
      .catch(() => {
        notifyError(genericErrorMsg)
      })
  }

  const handleResendMFA = () => {
    resendMFA(selectedUser.email)
      .then(r => {
        if (r.ok) {
          notifySuccess('MFA email sent')
        } else {
          notifyError(genericErrorMsg)
        }
      })
      .catch(() => {
        notifyError(genericErrorMsg)
      })
  }
  const handleActivateUser = () => {
    activate(userId)
      .then(r => {
        if (r.ok) {
          mutateAdministrableUsers()
          notifySuccess('User activated')
        } else {
          notifyError(genericErrorMsg)
        }
      })
      .catch(() => {
        notifyError(genericErrorMsg)
      })
  }
  const handleDeactivateUser = () => {
    deactivate(userId)
      .then(r => {
        if (r.ok) {
          mutateAdministrableUsers()
          notifySuccess('User deactivated')
        } else {
          notifyError(genericErrorMsg)
        }
      })
      .catch(() => {
        notifyError(genericErrorMsg)
      })
  }
  const handleActivateDeactivate = () => {
    return isActive ? handleDeactivateUser() : handleActivateUser()
  }

  const onSubmit = data => {
    const body = getCreateEditUserRequestBody(
      defaultPermissions,
      formPermissions,
      tree,
      selectedUser,
      isEditMode,
      userId,
      data,
      'MarketUserPreferences',
    )
    // required for alerts
    body.preferences = getNemDefaultPreferences()
    const persistUserFn = isEditMode ? updateUser : createUser

    // in order to change MFA we need to use separate request
    if (isEditMode && body.hasMfa !== selectedUser.hasMfa) {
      enableMfa(selectedUser.userId, body.hasMfa)
        .then(r => {
          if (!r.ok) {
            notifyError(genericErrorMsg)
          } else {
            persistUserFn(body)
              .then(r => {
                if (r.ok) {
                  const successMessage = isEditMode
                    ? `User ${data.name} updated successfully`
                    : 'User created successfully'
                  notifySuccess(successMessage)
                  history.push(ROUTES.USER_MANAGEMENT)
                } else {
                  notifyError(genericErrorMsg)
                }
              })
              .catch(() => {
                notifyError(genericErrorMsg)
              })
          }
        })
        .catch(() => {
          notifyError(genericErrorMsg)
        })
    } else {
      // either create or has not changed MFA checkbox
      persistUserFn(body)
        .then(r => {
          if (r.ok) {
            const successMessage = isEditMode ? `User ${data.name} updated successfully` : 'User created successfully'
            notifySuccess(successMessage)
            history.push(ROUTES.USER_MANAGEMENT)
          } else {
            notifyError(genericErrorMsg)
          }
        })
        .catch(() => {
          notifyError(genericErrorMsg)
        })
    }
  }

  const getTitle = () => {
    if (isEditMode) {
      return 'Edit user'
    }
    return 'Create new user'
  }
  const handleCancel = () => {
    history.push(ROUTES.USER_MANAGEMENT)
  }

  return (
    <Card className={classes.root} data-testid="qa-usr-mgmt-card">
      <CardHeader
        title={getTitle()}
        className={classes.cardHeader}
        titleTypographyProps={{ variant: 'h3' }}
        data-testid="qa-usr-mgmt-card-header"
      />
      <CardContent className={classes.cardContent}>
        <UserInfoForm
          isActive={isActive}
          isEditMode={isEditMode}
          errors={errors}
          control={control}
          handleResetPass={handleResetPass}
          handleResendMFA={handleResendMFA}
          formFieldDict={formFieldDict}
          Controller={Controller}
          showResendMfa={_.get(selectedUser, HAS_MFA)}
        />
        <UserPermissionsForm
          getMarketLevelChildren={getMarketLevelChildren}
          getCustomerChildren={getCustomerChildren}
          fetchPermissions={fetchPermissions}
          getAssetChildren={getAssetChildren}
          formPermissions={formPermissions}
          setFormPermissions={setFormPermissions}
          marketName={AEMO_MARKET}
          tree={tree}
          setTree={setTree}
        />
        <UserSubmitBar
          isEditMode={isEditMode}
          handleActivateDeactivate={handleActivateDeactivate}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isActive={isActive}
        />
      </CardContent>
    </Card>
  )
}

export default UserForm
