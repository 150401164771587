import React, { useState, useEffect, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Modal } from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  deleteManualBids,
  fetchManualBidsByProductIds,
  selectActiveManualBids,
  resetZeroMaxBidsMapByStartTime,
} from '../../redux/features/bid'
import { findProduct, getSortedProductNames, getAllEnabledProductIds } from '../../utility/utility'
import { getNextIntervalChange, getNextIntervalTime, getMarketStartGivenTimestamp } from '../../utility/time-utils'
import ManualBidDelete from './ManualBidDelete'
import ManualBiddingModal from './ManualBiddingModal'

const DISPLAY_NAME = 'ManualBidDashboard'
const MODE = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
}

const ManualBidDashboard = React.forwardRef(function ManualBidDashboard(props, ref) {
  const {
    asset,
    bidCollationInterval,
    onClose,
    open,
    productId: initialProductId,
    productType: initialProductType,
    startTime: initStartTime,
    disabledProductTypeToggleNames,
    showProductTypeToggle,
  } = props
  const {
    dispatchDeleteManualBids,
    dispatchGetManualBidsByProductIds,
    bidsMappedByManualBidIdStartTime,
    dispatchResetZeroMaxBidsMapByStartTime,
  } = props
  const { notifySuccess, notifyError } = useNotifier()

  const { assetId, market } = asset
  const marketTimezone = _.get(market, 'data.timezone')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const productTypes = _.get(asset, 'productTypes', [])
  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNamesMapByProductId = _.keyBy(asset.products, 'productId')

  const [productType, setProductType] = useState(initialProductType)
  const [selectedDay, setSelectedDay] = useState(initStartTime)
  const [productId, setProductId] = useState(initialProductId)
  const productName = _.get(productDisplayNamesMapByProductId, [productId, 'name'])

  // Used to force rerender when interval becomes invalid.
  const [, setLastIntervalUpdate] = useState()
  const [openMode, setOpenMode] = useState(MODE.DELETE)
  const timeNow = moment().tz(marketTimezone)

  const { 'bid_creator_start_cutoff_secs': bidCreatorStartCutoffSecs } = _.get(
    asset,
    'market.data.gate_closure_cutoffs',
    {},
  )
  const manualBidCutoffDuration = moment.duration(bidCreatorStartCutoffSecs, 'seconds').subtract(1, 'minutes')
  const nextIntervalTimeTimestamp = getNextIntervalTime(moment(timeNow), manualBidCutoffDuration.asSeconds()).valueOf()
  const nextIntervalChange = getNextIntervalChange(moment(timeNow), manualBidCutoffDuration.asSeconds())

  /**
   * TL;DR; This function will force the component to rerender on a time interval.
   *
   * The rerender is needed to force our interval list to update and invalidate old intervals in near real time.
   */
  const nextIntervalChangeTimestamp = nextIntervalChange.valueOf()
  useEffect(() => {
    if (open && !_.isNil(marketTimezone)) {
      // nextIntervalChange changes every five minutes right after end of interval (endOfInterval from parent component triggers rerender)
      const waitTime = nextIntervalChangeTimestamp - moment().tz(marketTimezone).valueOf() // milliseconds until next available interval
      const timeoutHandle = setTimeout(() => {
        setLastIntervalUpdate(moment().tz(marketTimezone))
      }, waitTime)
      return () => {
        clearTimeout(timeoutHandle)
      }
    }
  }, [open, marketTimezone, nextIntervalChangeTimestamp])

  useEffect(() => {
    if (open) {
      setSelectedDay(initStartTime)
      setOpenMode(MODE.DELETE)
      setProductId(initialProductId)
      setProductType(initialProductType)
    }
  }, [open, initStartTime, initialProductId, initialProductType])

  // Set new date value at end of trading day
  useEffect(() => {
    const nowMarketStartHour = getMarketStartGivenTimestamp(nextIntervalTimeTimestamp, marketStartHour)
    if (open && moment(selectedDay).isBefore(nowMarketStartHour)) {
      setSelectedDay(nowMarketStartHour)
    }
  }, [open, marketStartHour, nextIntervalTimeTimestamp, selectedDay])

  const selectedDayTimestamp = selectedDay.valueOf()
  useEffect(() => {
    if (open && openMode === MODE.DELETE && moment(selectedDayTimestamp).isValid()) {
      const allEnabledProductIds = getAllEnabledProductIds(asset, moment(selectedDayTimestamp))
      const cache = false
      dispatchGetManualBidsByProductIds(
        assetId,
        allEnabledProductIds,
        moment(selectedDayTimestamp),
        moment(selectedDayTimestamp).add(1, 'days'),
        cache,
      )
    }
  }, [asset, assetId, open, openMode, selectedDayTimestamp, dispatchGetManualBidsByProductIds])

  const deletableManualBids = useSelector(state =>
    selectActiveManualBids(state, assetId, productId, selectedDay, nextIntervalTimeTimestamp),
  )

  useEffect(() => {
    setProductId(initialProductId)
    setProductType(initialProductType)
  }, [initialProductId, initialProductType])

  const handleChangeProductName = (e, productName, newProductType) => {
    const type = _.isNil(newProductType) ? productType : newProductType
    if (!_.isNil(productName)) {
      const selectedProduct = findProduct(asset, productName, type)
      setProductId(selectedProduct.productId)
    }
  }

  const handleChangeProductType = (event, productType) => {
    if (!_.isNil(productType)) {
      // ensure disabled product is not selected
      let nextProductName = productName
      const disabledProducts = asset.productNames.filter(
        productName => asset.data.configuration[`${productName}_${productType}`] === 0,
      )

      setProductType(productType)

      // handle case when a product ID is disabled during product type toggle
      if (disabledProducts.includes(productName)) {
        nextProductName = getSortedProductNames(productNames).find(product => !disabledProducts.includes(product))
        handleChangeProductName(event, nextProductName, productType)
      } else {
        const selectedProduct = findProduct(asset, productName, productType)
        setProductId(selectedProduct.productId)
      }
    }
  }

  const handleDeleteManualBid = useCallback(
    async bidsToDelete => {
      const promises = []
      for (const productId in bidsToDelete) {
        promises.push(dispatchDeleteManualBids(bidsToDelete[productId]))
      }
      return Promise.all(promises).then(responses => {
        if (responses.some(response => response.error)) {
          notifyError('Manual bid deletion unsuccessful')
        } else {
          notifySuccess('Manual bid deletion successful')
          dispatchResetZeroMaxBidsMapByStartTime()
          const allEnabledProductIds = getAllEnabledProductIds(asset, moment(selectedDayTimestamp))
          const cache = false
          return dispatchGetManualBidsByProductIds(
            assetId,
            allEnabledProductIds,
            moment(selectedDayTimestamp),
            moment(selectedDayTimestamp).add(1, 'days'),
            cache,
          )
        }
      })
    },
    [
      asset,
      assetId,
      dispatchDeleteManualBids,
      dispatchGetManualBidsByProductIds,
      dispatchResetZeroMaxBidsMapByStartTime,
      notifyError,
      notifySuccess,
      selectedDayTimestamp,
    ],
  )
  const handleChangeMode = useCallback(modeName => {
    setOpenMode(modeName)
  }, [])

  const handleManualBidCreate = () => {
    const allEnabledProductIds = getAllEnabledProductIds(asset, moment(selectedDayTimestamp))
    const cache = false
    return dispatchGetManualBidsByProductIds(
      assetId,
      allEnabledProductIds,
      moment(selectedDayTimestamp),
      moment(selectedDayTimestamp).add(1, 'days'),
      cache,
    )
  }

  const handleChangeDate = newDate => {
    setSelectedDay(() => moment(newDate).valueOf())
  }

  const switchCreateMode = useCallback(() => handleChangeMode(MODE.CREATE), [handleChangeMode])
  const switchDeleteMode = useCallback(() => handleChangeMode(MODE.DELETE), [handleChangeMode])

  return (
    <>
      <Modal
        open={open}
        BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.18)' } }}
        aria-labelledby="delete-manual-bids-title"
        aria-describedby="delete-manual-bids-description"
      >
        <>
          <ManualBidDelete
            action={<CreateModeBtn onClick={switchCreateMode} />}
            asset={asset}
            bids={deletableManualBids}
            cutoffTime={nextIntervalTimeTimestamp}
            endTime={moment(selectedDay).add(1, 'days').valueOf()}
            marketTimezone={marketTimezone}
            onChangeDate={setSelectedDay}
            onChangeProductName={handleChangeProductName}
            onChangeProductType={handleChangeProductType}
            onClose={onClose}
            onDelete={handleDeleteManualBid}
            productName={productName}
            productType={productType}
            startTime={selectedDay}
            tradingDay={selectedDay}
            bidsMappedByManualBidIdStartTime={bidsMappedByManualBidIdStartTime}
            disabledProductTypeToggleNames={disabledProductTypeToggleNames}
            showProductTypeToggle={showProductTypeToggle}
          />
          <Modal
            open={openMode === MODE.CREATE}
            BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.18)' } }}
            aria-labelledby="create-manual-bids-title"
            aria-describedby="create-manual-bids-description"
          >
            <ManualBiddingModal
              asset={asset}
              bidCollationInterval={bidCollationInterval}
              manualBidSelectedDate={selectedDay}
              marketStartHour={marketStartHour}
              marketTimezone={marketTimezone}
              onChangeProduct={setProductId}
              onChangeProductName={handleChangeProductName}
              onChangeProductType={handleChangeProductType}
              onChangeSelectedDate={handleChangeDate}
              onClose={switchDeleteMode}
              onSubmit={handleManualBidCreate}
              open={openMode === MODE.CREATE}
              productId={productId}
              productName={productName}
              productNames={productNames}
              productType={productType}
              productTypes={productTypes}
              disabledProductTypeToggleNames={disabledProductTypeToggleNames}
              showProductTypeToggle={showProductTypeToggle}
            />
          </Modal>
        </>
      </Modal>
    </>
  )
})

const CreateModeBtn = React.memo(({ onClick }) => (
  <Button variant="text" onClick={onClick}>
    CREATE MANUAL BID
  </Button>
))

const mapStateToProps = state => ({
  bidsMappedByManualBidIdStartTime: _.get(state, 'bid.bidsMappedByManualBidIdStartTime'),
})

const mapDispatchToProps = dispatch => {
  return {
    dispatchDeleteManualBids: bidsToDelete => dispatch(deleteManualBids.put(bidsToDelete)),
    dispatchGetManualBidsByProductIds: (assetId, productIds, startTime, endTime, cache) =>
      dispatch(fetchManualBidsByProductIds(assetId, productIds, startTime, endTime, true, cache)),
    dispatchResetZeroMaxBidsMapByStartTime: () => dispatch(resetZeroMaxBidsMapByStartTime()),
  }
}

ManualBidDashboard.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(ManualBidDashboard)
