import React from 'react'

function AgreementText() {
  return (
    <div>
      Together with the PSA (defined below), these AMS Platform Terms of Use (“<strong>Terms of Use</strong>”) between
      you and Fluence Energy Pty Ltd (“<strong>Fluence</strong>”) govern your access to and use of the AMS Platform (the
      “<strong>Service</strong>”). PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE YOU USE THE SERVICE. BY USING THE
      SERVICE OR BY CLICKING TO ACCEPT OR AGREE TO THESE TERMS OF USE WHEN THIS OPTION IS MADE AVAILABLE TO YOU, (1) YOU
      ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE, AND (2) YOU ACCEPT AND AGREE TO BE BOUND AND
      ABIDE BY THE TERMS AND CONDITIONS SET FORTH HEREIN. If you do not agree to be bound by and comply with these Terms
      of Use, as updated by Fluence from time to time, you must not access or use the Service or any Fluence Materials
      (as defined below).
      <br />
      <br />
      You acknowledge and represent that you are being provided access to and use of the Service in your capacity as an
      employee, contractor, agent, or investor of the company, partnership or other organization (“
      <strong>Customer</strong>”) which is a party to a Platform Services Agreement (“<strong>PSA</strong>”) with
      Fluence. The terms and conditions of the PSA will prevail over any inconsistent terms and conditions of these
      Terms of Use, and capitalized terms used but not defined herein shall have the meanings assigned to such terms in
      the PSA.
      <h4>1. Access to the AMS Platform</h4>
      The Service is an Internet-based platform accessed through Fluence’s web portal that uses deep learning algorithms
      and advanced optimization techniques, enabling the transaction of complex energy assets in wholesale energy
      markets to maximize asset value. You have been added to the Service as an authorized user (an “
      <strong>Authorized User</strong>”) of Customer, and you have been assigned a User ID and password or other access
      credentials (“<strong>Your Credentials</strong>”) by Customer. You are only permitted to access and use the
      Service and Fluence Materials to the extent expressly authorized in the PSA entered into between Customer and
      Fluence. Your use of the Service and all Fluence Materials shall be exclusively for the benefit of such Customer,
      and not for your personal benefit or the benefit of any other person or entity.
      <br />
      <br />
      The Service includes or will enable your access to, without limitation, the AMS Platform, the AMS Platform
      Documentation, other AMS Platform Materials, and certain Resultant Data (as such terms are defined in the PSA),
      and images, data, visualizations, reports, and text incorporated into or made available as part of the Service,
      the “look and feel” of the Service, any training or support services provided to you by Fluence or its affiliates
      for the purpose of accessing and using the Service, and any compilations, summary or arranged data derived or
      produced from the Service, including but not limited to, submitted or projected bids, market awards or
      enablements, market prices forecasted by Fluence, estimated settlements, asset telemetry, and comparisons to
      baseline revenue (all of the foregoing, collectively, the “<strong>Fluence Materials</strong>”).
      <h4>2. Ownership of the Fluence Service</h4>
      Fluence is only providing you with access to use the Service and Fluence Materials pursuant to the PSA entered
      into between Customer and Fluence, and except as expressly set forth in the PSA, you have no right or license of
      any kind to the Service or any Fluence Materials. Fluence retains sole and exclusive ownership of and all right,
      title and interest in and to the Service and Fluence Materials (including ownership of all trade secrets, trade
      dress, copyrights, feedback and other intellectual property or proprietary rights pertaining to the Service or
      Fluence Materials), and to all modifications, derivative works, and enhancements of the Service or Fluence
      Materials, subject only to the rights expressly granted to Customer by Fluence in the PSA.
      <h4>3. Restrictions and Obligations</h4>
      You understand and agree that only you, the individual designated by Customer as an Authorized User, are permitted
      to access the Service and Fluence Materials using Your Credentials. You must take all necessary steps and
      precautions to ensure that no other person or entity learns or uses Your Credentials. You agree to immediately
      notify Customer and Fluence if you suspect or become aware that any other person or entity learns or uses Your
      Credentials. You also agree that you will neither register nor use Your Credentials for group purposes or as a
      role account (nor allow any other person or entity to do the same). Fluence retains the right to suspend your
      access to the Service and Fluence Materials without notice if Your Credentials are used, or if Fluence reasonably
      believes Your Credentials have been used, for group purposes or as a role account. No service bureau work,
      multiple-user license or time-sharing arrangement for the use of the Service or Fluence Materials is permitted,
      except as expressly authorized by Fluence in writing.
      <br />
      <br />
      If you are Fluence’s direct competitor, you shall under no circumstances access or use the Service or any Fluence
      Materials without Fluence’s prior written consent. In addition, you shall not access or use the Service or any
      Fluence Materials for purposes of competitive analysis of the AMS Platform, the development, provision, access, or
      use of a competing software service or product or any other purpose that is or may be to the detriment or
      commercial disadvantage of Fluence.
      <br />
      <br />
      You shall not, and will not encourage or assist any third party to, copy, modify, reverse engineer, disassemble,
      decompile, decode, translate, resell, or distribute the Service or Fluence Materials (electronically or
      otherwise), or otherwise attempt to discover, derive or gain access to the source code or underlying ideas or
      algorithms for any portion of the Service or applicable Fluence Materials, or any copy, adaptation, derivative
      work, compilation, transcription or merged portion thereof, except as expressly authorized by Fluence in the PSA
      or otherwise required under applicable law. You shall not transfer, assign, lease, lend, rent, resell,
      redistribute, sublicense, publish or otherwise share Your Credentials or the authorization granted to you under
      the PSA, or otherwise make the Service or any Fluence Materials available for the use of any other person or
      entity. You shall not make the data and information from the Service (including, without limitation, any Fluence
      Materials) available to any other person or entity, or frame, window, scrape, or otherwise reproduce, distribute,
      copy, store, license, or sell the data, information, or other content derived from the Service or any Fluence
      Materials.
      <br />
      <br />
      In addition, you shall not, and shall not encourage or assist any other person or entity to: (a) damage, disable,
      overburden, disrupt or impair the Service or any Fluence Materials (or any network or systems connected to the
      Service); (b) bypass or breach any security device or protection used by the Fluence to restrict access to the
      Service or any Fluence Materials; (c) use any automated process or service (such as a bot, a spider or periodic
      caching of information stored by Fluence) to access or use the Service or any Fluence Materials; (d) input,
      upload, transmit, or otherwise provide to or through the Service or the Fluence Materials any information, content
      or code that is unlawful, injurious, defamatory, objectionable, or which contains, transmits, or activates any
      malware or other Harmful Code; (e) remove, delete, alter, or obscure any notices of copyright, trademark, patent,
      or other intellectual property or proprietary rights from the Service or any Fluence Materials (including any
      copies thereof); (f) use the Service or any Fluence Materials to engage in any unlawful or fraudulent activity or
      otherwise violate the rights of a third party; or (g) access or attempt to access Fluence’s other accounts,
      computer systems or networks not covered by these Terms of Use, through password mining or any other means.
      <h4>4. Confidentiality</h4>
      The Service and Fluence Materials constitute Fluence’s Confidential Information (as defined in the PSA). With
      respect to such Confidential Information, You agree: <br />
      (a) not to disclose the Confidential Information to any other person or entity (other than Customer’s employees
      who have a need to know the Confidential Information to exercise Customer’s rights or perform its obligations
      under the PSA); <br />
      (b) to protect the Confidential Information from unauthorized use and disclosure with the same degree of care
      accorded to Customer’s own confidential and proprietary information (but no less than reasonable care); and <br />
      (c) to refrain from using the Confidential Information, except as expressly permitted under the PSA.
      <h4>5. Suspension of Access to Service</h4>
      You, Customer or Fluence may terminate or suspend your access to the Service and/or any Fluence Materials (in
      whole or in part) at any time. Without limiting the foregoing, Fluence retains the right to immediately suspend,
      terminate, or otherwise deny your access to or use of all or any part of the Service, Fluence Materials, Your
      Credentials, or any other application or interface used by you to access or use the Service or Fluence Materials,
      without incurring any obligation or liability, if in Fluence’s reasonable opinion: (a) you use the Service or any
      Fluence Materials in violation of any federal, state, local or foreign laws, rules, regulations and ordinances;
      (b) you fail to comply with these Terms of Use, the PSA, or any other terms and conditions required in connection
      with accessing and/or using the Service or Fluence Materials; (c) you access or use the Service or any Fluence
      Materials beyond the scope of the rights granted or for a purpose not authorized under these Terms of Use or the
      PSA; (d) you are, have been, or are likely to be involved in any fraudulent, misleading, or unlawful activities;
      (e) there is a threat, attack, or security risk arising from Your Credentials or your access to or use of the
      Service or any Fluence Materials; or (f) the PSA has expired or is terminated.
      <br />
      <br />
      If you cease to be employed or otherwise engaged by Customer, or if Your Credentials are disabled or your right to
      access and use the Service or Fluence Materials is otherwise terminated or suspended by Fluence or Customer for
      any reason, you must immediately cease all access to and use of the Service and Fluence Materials through any
      means. Further, you must promptly return (or destroy, if requested by Fluence) all materials in your possession or
      control which are based on or otherwise include information pertaining to the Service or Fluence Materials
      (including all copies thereof), and you agree to promptly provide Fluence with written certification of your
      compliance with the terms of this Section upon Fluence’s request.
      <h4>6. Compliance With Laws</h4>
      You acknowledge and agree that your access to and use of the Service and the Fluence Materials may be subject to
      applicable foreign, federal, state and/or local laws, ordinances, rules, regulations, by-laws, orders, guidelines,
      principles or codes, including without limitation, the laws, ordinances, rules, regulations, by-laws, orders,
      guidelines, principles or codes of foreign, federal, state and/or local agencies (each such agency, a “
      <strong>Regulatory Agency</strong>”). You agree to comply with all applicable laws, ordinances, rules,
      regulations, by-laws, orders, guidelines, principles, codes and/or requirements imposed by any government or
      Regulatory Agency with respect to your use, transmission and disclosure of any data or information, including
      Fluence Materials, via the Service. You also represent and warrant that any data, media or other content you
      disseminate through the Service does not violate the intellectual property rights or other rights of any third
      party. You shall not, directly or indirectly, access, export, re-export, or release the Service, Fluence
      Materials, or the underlying software or technology to, or make the Service, Fluence Materials, or the underlying
      software or technology accessible from, any jurisdiction or country to which export, re-export, or release is
      prohibited by law, rule, or regulation, including, without limitation, Cuba, Iran, North Korea, Syria, or the
      Crimea region of Ukraine or any other country subject to embargo or sanction by the United States Department of
      the Treasury’s Office of Foreign Assets Control.
      <h4>7. No Warranty</h4>
      YOU ARE PROVIDED ACCESS TO THE SERVICE AND Fluence MATERIALS ON AN “AS-IS” BASIS, AND TO THE MAXIMUM EXTENT
      PERMITTED UNDER APPLICABLE LAWS, Fluence DISCLAIMS ANY AND ALL PROMISES, REPRESENTATIONS AND WARRANTIES OF ANY
      KIND WITH RESPECT TO THE SERVICE AND Fluence MATERIALS, WHETHER EXPRESS, IMPLIED OR OTHERWISE, INCLUDING BUT NOT
      LIMITED TO THE CONDITION OR CONFORMITY TO ANY REPRESENTATION OR DESCRIPTION, COMPATIBILITY WITH ALL EQUIPMENT AND
      SOFTWARE CONFIGURATIONS, THE EXISTENCE OF ANY LATENT OR PATENT DEFECTS, ANY NEGLIGENCE, WARRANTIES OF TITLE,
      AGAINST INFRINGEMENT AND ITS MERCHANTABILITY OR FITNESS FOR A PARTICULAR USE OR PURPOSE; FURTHER, Fluence MAKES NO
      WARRANTIES, REPRESENTATIONS, OR COVENANTS OF ANY KIND WITH RESPECT TO ANY THIRD-PARTY CONTENT, WHETHER EXPRESS,
      IMPLIED, OR OTHERWISE. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
      DISCLAIMER MAY NOT APPLY TO YOU.
      <br />
      <br />
      <h4>8. Data Privacy</h4>
      It is important that you understand how Fluence uses any data you or Customer provide to Fluence, including
      personal information. The Fluence Privacy Policy (the “<strong>Privacy Policy</strong>”) describes how Fluence
      uses data and information provided to Fluence by you or Customer. By accessing and using the Service, and thereby
      agreeing to these Terms of Use, you also acknowledge that you have read and consent to the processing of such data
      and information (including your personal information) in accordance with the Privacy Policy. You can view the
      Privacy Policy per the link below.
      <br />
      <br />
      Additionally, you hereby acknowledge that Fluence, or Fluence’s subcontractors, will utilize cloud technology in
      connection with the storage of your personal information, and it is possible that this may result in storage in an
      overseas location. In addition, Fluence may disclose your personal information to overseas entities that provide
      support functions to us. It is possible that information will be transferred to a jurisdiction where you will not
      be able to seek redress under a privacy law or regulation in place in your jurisdiction (including, but not
      limited to, the Australian Privacy Act 1988). Fluence will not be accountable for how these overseas recipients
      handle your personal information. By providing your personal information to Fluence, you consent to Fluence’s
      disclosure of your personal information to these parties.
      <h4>9. Limitation of Liability</h4>
      TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAWS, IN NO EVENT WILL Fluence (INCLUDING ITS CURRENT AND FORMER
      SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SUCCESSORS OR ASSIGNS) OR ANY THIRD-PARTY PROVIDER BE LIABLE
      TO YOU IN RELATION TO THESE TERMS OF USE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES
      (INCLUDING WITHOUT LIMITATION LOSS OF PROFITS, LOSS OF BUSINESS OPPORTUNITY, LOSS OF REVENUES OR SAVINGS, LOSS OF
      DATA OR COSTS OF RECREATING LOST DATA), WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF SUCH
      PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS LIMITATION UPON DAMAGES AND CLAIMS IS INTENDED
      TO APPLY WITHOUT REGARD TO WHETHER OTHER PROVISIONS OF THESE TERMS OF USE HAVE BEEN BREACHED OR HAVE BEEN HELD TO
      BE INVALID OR INEFFECTIVE.
      <br />
      <br />
      FURTHER, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAWS, THE AGGREGATE LIABILITY OF Fluence AND ITS
      THIRD-PARTY PROVIDERS (INCLUDING THEIR RESPECTIVE CURRENT AND FORMER SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES
      OR AGENTS) TO YOU IN RELATION TO THESE TERMS OF USE WILL NOT IN ANY EVENT EXCEED THE AGGREGATE AMOUNT OF ONE
      THOUSAND UNITED STATES DOLLARS (USD $1,000.00). THE FOREGOING NOTWITHSTANDING, NOTHING IN THESE TERMS OF USE SHALL
      EXCLUDE OR OTHERWISE LIMIT ANY LIABILITY FOR (A) PERSONAL INJURY OR DEATH CAUSED DIRECTLY BY THE NEGLIGENCE OF
      Fluence OR A THIRD PARTY; OR (B) FRAUDULENT MISREPRESENTATION; OR (C) WILLFUL MISCONDUCT. THE FOREGOING
      LIMITATIONS ARE A FUNDAMENTAL PART OF THE BASIS OF THE BARGAIN HEREUNDER.
      <br />
      <br />
      Without limiting the foregoing, in no event will any third party engaged by or assisting Fluence in the marketing
      and/or development of the Service have any responsibility or liability for any claims or damages whatsoever in any
      way connected to the Service or their use, whether in tort or otherwise. The provisions of this Section will
      operate for the benefit of and may be enforced by any such third party.
      <h4>10. Governing Law</h4>
      To the maximum extent permitted by applicable law, these Terms of Use will be governed by, and construed in
      accordance with, the laws of the State of California, United States of America, without regard to conflict of law
      rules thereof.
      <h4>11. Miscellaneous</h4>
      You acknowledge that your breach or threatened breach of any of the provisions of these Terms of Use may cause
      immediate and irreparable harm to Fluence for which Fluence may not have an adequate remedy in money or damages,
      and in the event of such a breach or threatened breach, you therefore agree that Fluence will therefore be
      entitled to seek and obtain equitable relief, including an injunction, restraining order, specific performance,
      and/or any other relief that may be available from a court of competent jurisdiction, without any requirement to
      post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy.
      Such remedies are not exclusive and are in addition to all other remedies that may be available at law, in equity
      or otherwise.
      <br />
      <br />
      You may not assign, delegate, novate or otherwise transfer any rights or obligations under these Terms of Use. Any
      attempted or purported assignment, delegation or other transfer by you shall be null and void.
      <br />
      <br />
      If any provision of these Terms of Use is invalid, illegal, or unenforceable in any jurisdiction, such invalidity,
      illegality, or unenforceability will not affect any other term or provision of these Terms of Use or invalidate or
      render unenforceable such term or provision in any other jurisdiction. All provisions of these Terms of Use which
      expressly or by implication continue to govern your rights and obligations after termination of use of the Service
      shall survive notwithstanding completion of any agreement in connection with which the Service is used.
      <br />
      <br />
      Fluence will have the right to modify these Terms of Use from time to time. If Fluence makes any material
      modifications to these Terms of Use, Fluence will notify you that these Terms of Use have been updated using the
      Service’s notification methods. You understand and agree that your continued use of the Service following such
      notice indicates you have reviewed the updated Terms of Use and are providing your acceptance of such
      modifications to these Terms of Use.
    </div>
  )
}

export default AgreementText
