import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { PRODUCT_TYPE_GEN, PRODUCT_TYPE_LOAD, PRODUCT_NAME_ENERGY } from '../pages/Bids'
import { Asset, AssetType, Configuration, PartialConfiguration, ProductsEntity } from '../typings/asset'
import { ProductDataResponse, OperationalCapacityData } from '../redux/api'
import {
  FCAS_REGULATION_OR_ENERGY_PRODUCT_NAMES,
  BATTERY_NON_SCHEDULED_ASSET_TYPE,
  BATTERY_SCHEDULED_ASSET_TYPE,
  KW_TO_MW,
  RENEWABLE_ASSETS,
  PRODUCT_TIME_LABEL_TO_SEC,
} from './constants'
import { formatMarketValue, getSortedProductNames } from './utility'
import { getMarketStartGivenTimestamp } from './time-utils'

/**
 * @param {Asset} asset Asset
 * @returns {boolean}
 */
export const isAssetScheduledBattery = (asset: Asset): boolean => {
  return isScheduledBattery(_.get(asset, 'data.asset_type'))
}

/**
 * @param {AssetType} assetType An asset type
 * @returns {boolean} Is the given asset a scheduled Battery
 */
export const isScheduledBattery = (assetType: AssetType): boolean => {
  return _.isEqual(assetType, BATTERY_SCHEDULED_ASSET_TYPE)
}

/**
 * @param {Asset} asset Asset
 * @returns {boolean}
 */
export const isAssetNonScheduledBattery = (asset: Asset): boolean => {
  return isNonScheduledBattery(_.get(asset, 'data.asset_type'))
}

/**
 * @param {AssetType} assetType An asset type
 * @returns {boolean} Is the given asset a non scheduled Battery
 */
export const isNonScheduledBattery = (assetType: AssetType): boolean => {
  return _.isEqual(assetType, BATTERY_NON_SCHEDULED_ASSET_TYPE)
}

/**
 * @param {Asset} asset Asset
 * @returns {boolean}
 */
export const isAssetRenewable = (asset: Asset): boolean => {
  const assetCategory = _.get(asset, 'data.tag_prefix')
  return RENEWABLE_ASSETS.includes(assetCategory)
}

/**
 *
 * @param {number} productId Unique product identifier
 * @param {Object[]} products List of all possible products
 * @returns {string} "GEN" | "LOAD"
 */
export const getProductTypeGivenId = (productId: number, products: ProductsEntity[]): string => {
  const product = products.find(product => product.productId === productId)
  return _.get(product, 'data.type')
}

/**
 *
 * @param {number} productId Unique product identifier
 * @param {Object[]} products List of all possible products
 * @returns {string} product name e.g. "ENERGY" | "RAISEREG"
 */
export const getProductNameGivenId = (productId: number, products: ProductsEntity[]): string | undefined => {
  const product = products.find(product => product.productId === productId)
  return _.get(product, 'name')
}

/**
 *
 * @param {string} productName Unique product name
 * @param {Object[]} products List of all possible products
 * @returns {number} product id e.g. 1
 */
export const getProductIdByGivenNameType = (
  productName: string,
  productType: string,
  products: ProductsEntity[] = [],
): number | undefined => {
  const product = products.find(product => product.name === productName && _.get(product, 'data.type') === productType)
  return _.get(product, 'productId')
}

export const getProductIdGivenConfigurationKey = (configurationKey: string, products: []): number | undefined => {
  const [productName, productType] = configurationKey.split('_')
  return getProductIdByGivenNameType(productName, productType, products)
}

export const getProductNameGivenConfigurationKey = (configurationKey: string, products: []): string | undefined => {
  const [productName, productType] = configurationKey.split('_')
  const productId = getProductIdByGivenNameType(productName, productType, products)
  if (productId === undefined) {
    return undefined
  }
  return getProductNameGivenId(productId, products)
}

/**
 *
 * @param {String[]} productNames List of product names
 * @returns {String[]} List of product names not in FCAS_REGULATION_OR_ENERGY_PRODUCT_NAMES
 */
export const removeNSBESSHiddenProducts = (productNames: string[]): string[] => {
  return productNames.filter(productName => !FCAS_REGULATION_OR_ENERGY_PRODUCT_NAMES.has(productName))
}

/**
 * For ProductToggle, find the first active product name in the sorted order
 * @param {Object} asset Asset Object
 * @param {string} productType The product type we're searching for the first active product name
 * @param {string} selectedTime selected time
 * @returns {string} the string name of the first product that is present
 */
export const getFirstActiveProductName = (asset: Asset, productType: string, selectedTime: Moment | string): string => {
  const productNames: string[] = _.get(asset, 'productNames', []) || []
  const disabledProductNames = productNames.filter(
    productName => _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) === 0,
  )
  const sortedProductNames: string[] = getSortedProductNames(productNames)
  const activeSortedProductNames = extractActiveProductNamesFromList(
    asset,
    selectedTime,
    sortedProductNames,
    productType,
  )
  const firstActiveProductName = activeSortedProductNames.find(
    productName => !disabledProductNames.includes(productName),
  )
  return _.isNil(firstActiveProductName) ? PRODUCT_NAME_ENERGY : firstActiveProductName
}

/**
 * For the ProductTypeToggle, decide whether to disable certain product type names
 * @param {Object} asset Asset object
 * @returns {String[]} that contains product type names to disable in Product Type Toggle
 */
export const getDisabledProductTypeToggleNames = (asset: Asset): string[] => {
  const assetCategory = _.get(asset, 'data.tag_prefix')
  const isRenewableAssetType = RENEWABLE_ASSETS.includes(assetCategory)
  if (isAssetNonScheduledBattery(asset)) {
    // for Non-Scheduled BESS assets, we disable GEN by default
    return [PRODUCT_TYPE_GEN]
  } else if (isRenewableAssetType) {
    // for renewable assets, we disable LOAD by default
    return [PRODUCT_TYPE_LOAD]
  } else {
    // if default case, we do not disable anything
    return []
  }
}

/**
 * get disabled product names for product type gen or load
 * @param {Object} asset Asset object
 * @param {string} productType The product type we're searching for the first active product name
 * @returns {String[]} that contains product names that are disabled for the asset
 */
export const getDisabledProductNames = (asset: Asset, productType: string): string[] => {
  const productNames = _.get(asset, 'productNames', [])
  return productNames
    ? productNames.filter(productName => _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) === 0)
    : []
}

/**
 * Get first enabled product type name
 * @param {Object} asset Asset object
 * @returns {String} first enabled product type name
 */
export const getFirstActiveProductTypeName = (asset: Asset) => {
  const productTypes = _.get(asset, 'productTypes', []) || []
  const disabledProductTypeNames = getDisabledProductTypeToggleNames(asset)
  const enabledProductTypes = productTypes.filter(
    productType => !disabledProductTypeNames.includes(productType.toUpperCase()),
  )
  return !_.isEmpty(enabledProductTypes) ? enabledProductTypes[0] : ''
}

/**
 * Decide whether to show product type toggle based on asset type
 * @param {Object} asset Asset object
 * @returns {Boolean} True to show, false to hide
 */
export const shouldShowProductTypeToggle = (asset: Asset): boolean => {
  const assetCategory = _.get(asset, 'data.tag_prefix')
  const isRenewableAssetType = RENEWABLE_ASSETS.includes(assetCategory)
  if (isRenewableAssetType || isAssetNonScheduledBattery(asset)) {
    return false
  } else {
    return true
  }
}

export const getEnabledConfigurationKeys = (configuration: PartialConfiguration) => {
  return _.pickBy(configuration, value => value !== 0)
}

export const getEnabledProductKeys = (
  productTypes: string[] = [],
  productNames: string[] = [],
  configuration: Configuration,
) => {
  const enabledProducts: string[] = []
  productTypes.forEach(productType => {
    productNames.forEach(productName => {
      const productKey = `${productName}_${productType}`
      const configValue = _.get(configuration, productKey)
      if (_.isNumber(configValue) && configValue !== 0) {
        enabledProducts.push(productKey)
      }
    })
  })

  return enabledProducts
}

export type ConfigurationKey = `${string}_${string}`

export const getEnabledProductIds = (
  products: ProductsEntity[],
  productTypes = [],
  productNames = [],
  configuration: Configuration,
) => {
  const enabledProductKeys = getEnabledProductKeys(productTypes, productNames, configuration)

  const productsMapByProductKey = _.keyBy(products, product => {
    return `${_.get(product, 'name', '')}_${_.get(product, 'data.type', '')}`
  })

  const enabledProductIds = enabledProductKeys.map(enabledProductKey =>
    _.get(productsMapByProductKey, [enabledProductKey, 'productId']),
  )
  return enabledProductIds
}

export const getRegisteredPasaValue = (asset: Asset): string => {
  const registeredPasaValue = formatMarketValue(
    asset.data.inverter_discharge_rate * asset.data.number_inverters,
    KW_TO_MW,
    0,
  )
  return registeredPasaValue
}

/**
 * Check if the 1 second products are active.
 * @param asset Asset object
 * @param time The time to check against. Defaults to current time.
 * @returns boolean
 */
export const is1SecProductActive = (asset: Asset, time: Moment | number | string | undefined = moment()): boolean => {
  const products = _.get(asset, 'products', [])
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const timezone = _.get(asset, 'market.data.timezone')
  const lower1SecProduct = products.find(product => product.name === 'LOWER1SEC')
  const startTime = moment(_.get(lower1SecProduct, 'startTime'))
  return (
    startTime.isValid() &&
    timezone &&
    _.isNumber(marketStartHour) &&
    getMarketStartGivenTimestamp(moment(time), marketStartHour, timezone).isSameOrAfter(startTime)
  )
}

/**
 * Get active product time map from asset
 * @param asset Asset object
 * @returns object[] active product time map {productName_productType: time}
 */
export const getActiveProductTimeMapFromAsset = (asset: Asset): object => {
  const startTimeKey = 'startTime'
  const endTimeKey = 'endTime'
  const assetProducts = _.get(asset, 'products')
  return assetProducts.reduce((acc, productInfo) => {
    return {
      ...acc,
      [`${_.get(productInfo, 'name')}_${_.get(productInfo, ['data', 'type'])}`]: {
        startTime: _.get(productInfo, startTimeKey),
        endTime: _.get(productInfo, endTimeKey),
      },
    }
  }, {})
}

/**
 * Check if ffr products are active in list of product names.
 * @param asset Asset object
 * @param time The time to check against. Defaults to current time.
 * @param productNames list of product names to be filtered
 * @returns string[] active product names
 */
export const extractActiveProductNamesFromList = (
  asset: Asset,
  time: Moment | number | string | undefined = moment(),
  productNames: string[],
  productType: string = 'GEN',
): string[] => {
  const startTimeKey = 'startTime'
  const endTimeKey = 'endTime'
  const distantPast = '1900-01-01T00:00:00Z'
  const productTimeMap = getActiveProductTimeMapFromAsset(asset)
  return productNames.filter(productName => {
    const startTime = moment(
      _.get(productTimeMap, [`${productName.toUpperCase()}_${productType.toUpperCase()}`, startTimeKey], distantPast),
    )
    const endTime = moment(
      _.get(productTimeMap, [`${productName.toUpperCase()}_${productType.toUpperCase()}`, endTimeKey], distantPast),
    )
    return moment(time).isSameOrAfter(startTime) && moment(time).isBefore(endTime)
  })
}

/**
 * Check if a product name is active based on the activeProductTime map
 * @param selectedTime moment selectedDate
 * @param activeProductTimeMap object
 * @param productName string product name
 * @param productType string product type
 * @returns boolean
 */

export const isProductNameActive = (selectedTime, activeProductTimeMap, productName, productType) => {
  const distantPast = '1900-01-01T00:00:00Z'
  const startTimeKey = 'startTime'
  const endTimeKey = 'endTime'
  const startTime = moment(
    _.get(
      activeProductTimeMap,
      [`${productName.toUpperCase()}_${productType.toUpperCase()}`, startTimeKey],
      distantPast,
    ),
  )
  const endTime = moment(
    _.get(activeProductTimeMap, [`${productName.toUpperCase()}_${productType.toUpperCase()}`, endTimeKey], distantPast),
  )
  return moment(selectedTime).isSameOrAfter(startTime) && moment(selectedTime).isBefore(endTime)
}

/**
 * Check if ffr products are active in list of objects that contain product name and type.
 * @param asset Asset object
 * @param time The time to check against. Defaults to current time.
 * @param objectList object[] list of objects to filter
 * @param productNameKey key for extract product name from asset
 * @param productTypeKey key for extracting product type from asset
 * @returns string[] active product names
 */
export const extractActiveProductNamesFromListOfObjects = (
  asset: Asset,
  time: Moment | number | string | undefined = moment(),
  objectList: object[],
  productNameKey: string = 'name',
  productTypeKey: string = 'type',
): object[] => {
  const productTimeMap = getActiveProductTimeMapFromAsset(asset)
  return objectList.filter(productData => {
    const productName = _.get(productData, productNameKey)
    const productType = _.get(productData, productTypeKey)
    return isProductNameActive(time, productTimeMap, productName, productType)
  })
}

/**
 * Sort ffr products are active in list of objects as reg name, less time name (e.g. 1sec), more time (e.g. 5 min)
 * @param objectList object[] list of objects to filter
 * @param productNameKey key for extract product name from asset
 * @param descending whether return sort order in descending
 * @returns string[] active product names
 */
export const sortProductNamesFromListOfObjects = (
  objectList: object[],
  productNameKey: string = 'name',
  descending: boolean = false,
): object[] => {
  return objectList.sort((a, b) => {
    const aProductName = _.get(a, productNameKey)
    const bProductName = _.get(b, productNameKey)
    const aTimeValue = aProductName.match(/\d+/g)
    const bTimeValue = bProductName.match(/\d+/g)
    let aCompareValue = _.isNil(aTimeValue) ? 0 : _.toNumber(aTimeValue)
    let bCompareValue = _.isNil(bTimeValue) ? 0 : _.toNumber(bTimeValue)
    _.keys(PRODUCT_TIME_LABEL_TO_SEC).forEach(timeLabel => {
      if (aProductName.includes(timeLabel)) {
        aCompareValue = _.get(PRODUCT_TIME_LABEL_TO_SEC, timeLabel) * aCompareValue
      }
      if (bProductName.includes(timeLabel)) {
        bCompareValue = _.get(PRODUCT_TIME_LABEL_TO_SEC, timeLabel) * bCompareValue
      }
    })
    return descending
      ? _.toNumber(bCompareValue) - _.toNumber(aCompareValue)
      : _.toNumber(aCompareValue) - _.toNumber(bCompareValue)
  })
}

/**
 * Get operational capacity market asset data map
 * @param productDataList ProductDataResponse[] list of products from API
 * @returns OperationalCapacityData operational capacity data object
 */
export const getOperationalCapacityMarketAssetDataNameMap = (
  productDataList: ProductDataResponse[],
): OperationalCapacityData => {
  return productDataList.reduce((acc, productData) => {
    const productName = _.get(productData, 'name').toUpperCase()
    const productType = _.get(productData, 'data.type').toUpperCase()
    const key = `${productName}_${productType}`
    return { ...acc, [key]: key }
  }, {})
}
