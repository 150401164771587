import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { assetOperations } from '../redux/features/asset'
import routes from '../config/routes'
import ErrorNotFound from '../components/error/ErrorNotFound'
import { currentUserPermissionsSelector } from '../redux/features/user'
import { canAdjustRiskAppetite, canOnlyPauseBidDelivery } from '../utility/user-utils'
import AssetOperations from './AssetOperations'
import Bids from './Bids'
import Forecasts from './Forecasts'
import MarketResults from './MarketResults'
import TradeSettings from './TradeSettings'

const nestedRoutes = routes.ASSET.nestedRoutes

const DISPLAY_NAME = 'Asset'
const Asset = React.memo(props => {
  const { assetsMapByAssetId } = props
  const { dispatchGetAssets, permissions, user } = props

  const { path } = useRouteMatch()
  const { assetId } = useParams()
  const asset = assetsMapByAssetId[assetId]
  const [assetIsLoading, setAssetIsLoading] = useState(true)

  useEffect(() => {
    dispatchGetAssets().then(response => {
      setAssetIsLoading(false)
    })
    const getAssetHandle = setInterval(dispatchGetAssets, 1000 * 60 * 5)
    return () => clearInterval(getAssetHandle)
  }, [dispatchGetAssets])

  const hasError = !assetIsLoading && _.isUndefined(asset)
  const hasTradeSettingsAccess = canAdjustRiskAppetite(permissions, asset)
  const hasOnlyPauseBidDelivery = canOnlyPauseBidDelivery(user, asset)

  return (
    <>
      {hasError && <ErrorNotFound />}
      {!hasError && !assetIsLoading && (
        <Switch>
          <Route
            path={`${path}${nestedRoutes.ASSET_OPERATIONS}`}
            render={() => (!hasOnlyPauseBidDelivery ? <AssetOperations asset={asset} /> : <Redirect to="bids" />)}
          />
          <Route path={`${path}${nestedRoutes.BIDS}`} exact>
            <Bids asset={asset} />
          </Route>
          <Route
            path={`${path}${nestedRoutes.FORECASTS}`}
            exact
            render={() => (!hasOnlyPauseBidDelivery ? <Forecasts asset={asset} /> : <Redirect to="bids" />)}
          />
          <Route
            path={`${path}${nestedRoutes.MARKETS_RESULTS}`}
            render={() => (!hasOnlyPauseBidDelivery ? <MarketResults asset={asset} /> : <Redirect to="bids" />)}
          />
          <Route
            path={`${path}${nestedRoutes.TRADE_SETTINGS}`}
            render={() => (hasTradeSettingsAccess ? <TradeSettings asset={asset} /> : <Redirect to="bids" />)}
          />
          <Route path="*">
            <ErrorNotFound />
          </Route>
        </Switch>
      )}
    </>
  )
})
Asset.displayName = DISPLAY_NAME

const mapStateToProps = state => {
  const assetsMapByAssetId = _.get(state, 'asset.assets.map')
  const currentUser = _.get(state, 'user.current.payload', {})
  const userIsLoading = _.get(state, 'user.current.isLoading', false)
  const permissions = currentUserPermissionsSelector(state)

  return {
    assetsMapByAssetId,
    permissions,
    user: currentUser,
    userIsLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchGetAssets: (shouldCache = false) => dispatch(assetOperations.getAllAssets(shouldCache)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Asset)
