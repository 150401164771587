import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useNotifier } from '@fluence/core'
import { deleteMultipleAssetDataResource, postMultipleAssetDataResource } from '../../redux/features/setting'
import { useGetOpCapAndPasaAssetDataSchedule } from '../../redux/api'
import { getNextIntervalTime } from '../../utility/time-utils'
import {
  is1SecProductActive,
  isAssetRenewable,
  isAssetScheduledBattery,
  isAssetNonScheduledBattery,
} from '../../utility/asset-utils'
import {
  convertBessEnergyKwToMw,
  convertKwToMWAndTruncate,
  convertMWToKW,
  getAssetMWCapacity,
  getCalendarDate,
  getOpcapDisplayValue,
  getTradingStart,
  getNumberTruncatedAfterXthDecimalPlace,
} from '../../utility/utility'
import {
  DATE_FORMAT_DAY,
  FCAS_CONTINGENCY_PRODUCT_NAMES,
  FCAS_REGULATION_PRODUCTS,
  GENERIC_PASA_NAME,
  LOWER_FCAS_CONTINGENCY_PRODUCT_NAMES,
  OPERATIONAL_CAPACITY_SETTING_TAG,
  PASA_SETTINGS,
  PASA_SETTING_TAG,
  PRODUCT_NAMES,
  PRODUCT_TIME_LABEL_TO_SEC,
  PRODUCT_TYPES,
  RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES,
} from '../../utility/constants'
import ChangesCard from './ChangesCard'
import SchedulingModal from './SchedulingModal'
import OpcapTable from './OpcapTable'

const DISPLAY_NAME = 'OperationalCapacityCard'
const CARD_TITLE = 'Operational Capacity (MW)'

const NOTIFICATION_MSGS = {
  DATA_FETCH_ERROR: 'Something went wrong. Failed to fetch Operational Capacity entries.',
  DELETE_ERROR: 'Something went wrong. Unable to delete Operational Capacity entry',
  SAVE_ERROR: 'Something went wrong. Failed to save new Operational Capacity entries',
  SAVE_SUCCESS: 'Operational Capacity adjustment scheduled successfully',
}

const RENEWABLE_INFO_MODAL_TEXT =
  `Operational Capacity is a means for users to better account for temporary reductions in their asset's capacity and reduce the Max Avail value in the bids Mosaic submits to the market.\n` +
  `Setting an Operational Capacity override allows users to manage scenarios where an asset's actual capacity falls below its UIGF, triggering AEMO to limit the dispatch of the asset to the lower of the Max Avail and the UIGF values for that interval.\n` +
  'It also enables users to better signal any foreseeable capacity limitations to the market within the forward bidding horizons.\n'

const BESS_INFO_MODAL_TEXT = `Operational Capacity is a means for users to place temporary limits in their asset’s capacity for each of the individual products Mosaic optimises for, and restricts the resulting Non-Scheduled Setpoints and Max Avail values bid by the asset.

Setting an Operational Capacity override allows users to manage scenarios where they need to restrict the volume of a market product below its registered capacity and prevent AEMO from attempting to clear volume above that capacity level – such as an outage or physical constraint.

It also enables users to better signal any foreseeable capacity limitations to the market within the forward bidding horizons.`

const RENEWABLE_SCHEDULE_DESCRIPTION_TEXT =
  'Setting an Operational Capacity override changes the Max Avail value submitted in bids by Mosaic for the defined intervals.  AEMO will then limit the dispatch of the asset to the lower of this value and UIGF during those intervals.\n' +
  'If no override is defined, Mosaic will continue to bid the asset at its registered capacity, and dispatch will be limited to UIGF.'

const BESS_SCHEDULE_DESCRIPTION_TEXT =
  'Setting an Operational Capacity override applies an upper limit to the Max Avail value submitted in bids by Mosaic for a given market product during the defined intervals unless telemetered plant conditions constrain these capacities further.  Operational Capacity will also restrict the setpoints calculated for Non-Scheduled assets.\n' +
  'If no override is defined, and no other constraint is present, Mosaic will continue to limit the dispatch of each market product provided by the asset to its registered or installed capacities.'

// Object Keys
const ASSET_DATA_ID_KEY = 'assetDataId'

// Table Constants
const ROW_RAISE_NAME = 'C-RAISE'
const ROW_LOWER_NAME = 'C-LOWER'

const COL_ENERGY_NAME = PRODUCT_NAMES.ENERGY
const COL_LOWER_REG_NAME = 'LREG'
const COL_RAISE_REG_NAME = 'RREG'
const COL_PASA_NAME = GENERIC_PASA_NAME

// Config Label Constants for Schedule Modal
const RETURN_PREFIX = 'RETURN'
const VALUE_LABELS = [''] // no value labels
function OperationalCapacityCard(props) {
  const { asset, showDelete = false, operationalCapacityMarketAssetDataNameMap } = props
  const { dispatchCreateAssetData, dispatchDeleteAssetData } = props
  const { notifySuccess, notifyError } = useNotifier()

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)
  const assetId = _.get(asset, 'assetId')
  const market = _.get(asset, 'market')
  const timezone = _.get(asset, 'market.data.timezone')
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)
  const mtz = _.get(asset, 'market.data.timezone')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const nextInterval = getNextIntervalTime(moment(), 300)
  const configuration = _.get(asset, 'data.configuration')

  const {
    data: opCapAndPasaSettings,
    isError: opCapAndPasaSettingsFetchError,
    isLoading: opCapAndPasaSettingsIsLoading,
    mutate,
  } = useGetOpCapAndPasaAssetDataSchedule(assetId, nextInterval)

  const energyGenRegisteredLimitKw = _.get(configuration, operationalCapacityMarketAssetDataNameMap.ENERGY_GEN)
  const isRenewableAsset = isAssetRenewable(asset)
  const isScheduledBattery = isAssetScheduledBattery(asset)
  const isNsBattery = isAssetNonScheduledBattery(asset)
  const is1SecProductActiveForAsset = is1SecProductActive(asset)
  const opCapFcasRegulationProducts = FCAS_REGULATION_PRODUCTS.reduce(
    (acc, productName) => [...acc, ..._.values(PRODUCT_TYPES).map(productType => `${productName}_${productType}`)],
    [],
  )
  const schedulableOperaiontalCapacityProducts = isScheduledBattery
    ? { ...operationalCapacityMarketAssetDataNameMap, ...PASA_SETTINGS }
    : _.omit(operationalCapacityMarketAssetDataNameMap, opCapFcasRegulationProducts.concat(_.values(PASA_SETTINGS)))
  moment.tz.setDefault(timezone)

  const operationalCapacitySettings = []
  const pasaSettings = []

  _.forEach(opCapAndPasaSettings, opCapAndPasaRecords => {
    const opCapRecord = _.first(opCapAndPasaRecords.filter(record => record.tag === OPERATIONAL_CAPACITY_SETTING_TAG))
    const pasaRecord = _.first(opCapAndPasaRecords.filter(record => record.tag === PASA_SETTING_TAG))
    if (!_.isNil(opCapRecord)) {
      operationalCapacitySettings.push(opCapRecord)
    }
    if (!_.isNil(pasaRecord)) {
      pasaSettings.push(pasaRecord)
    }
  })

  useEffect(() => {
    if (opCapAndPasaSettingsFetchError) {
      notifyError(NOTIFICATION_MSGS.DATA_FETCH_ERROR)
    }
  }, [notifyError, opCapAndPasaSettingsFetchError])

  const operationalCapacityTableData = _.values(schedulableOperaiontalCapacityProducts).reduce(
    (acc, opCapProductName) => {
      const [productName, productType] = opCapProductName.split('_')
      const isRaiseFCASContingency = RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES.has(productName)
      const isLowerFCASContingency = LOWER_FCAS_CONTINGENCY_PRODUCT_NAMES.has(productName)

      if (isScheduledBattery) {
        // filter out load-side for Raise and gen-side for Lower
        if (
          (isRaiseFCASContingency && productType === PRODUCT_TYPES.LOAD) ||
          (isLowerFCASContingency && productType === PRODUCT_TYPES.GEN)
        ) {
          return acc
        }
      } else if (isNsBattery) {
        // filter out gen-side for Raise and Lower
        if (
          (isRaiseFCASContingency && productType === PRODUCT_TYPES.GEN) ||
          (isLowerFCASContingency && productType === PRODUCT_TYPES.GEN)
        ) {
          return acc
        }
      }
      const foundTimeValue = productName.match(/\d+/g)

      // compute tableGroup
      const tableGroup = FCAS_CONTINGENCY_PRODUCT_NAMES.has(productName)
        ? 'RAISE_FCAS_CONTINGENCY_PRODUCT'
        : 'FCAS_REGULATION_OR_ENERGY_PRODUCT_OR_PASA'

      // compute rowGroup and columnGroup
      let rowGroup = productType
      let columnGroup = ''
      if (isRaiseFCASContingency || isLowerFCASContingency) {
        rowGroup = isLowerFCASContingency ? ROW_LOWER_NAME : ROW_RAISE_NAME
        columnGroup = productName.slice(productName.indexOf(foundTimeValue))
      } else {
        switch (productName) {
          case PRODUCT_NAMES.RAISEREG:
            columnGroup = COL_RAISE_REG_NAME
            break
          case PRODUCT_NAMES.LOWERREG:
            columnGroup = COL_LOWER_REG_NAME
            break
          case GENERIC_PASA_NAME:
            columnGroup = COL_PASA_NAME
            break
          case PRODUCT_NAMES.ENERGY:
            columnGroup = COL_ENERGY_NAME
            break
          default:
        }
      }

      return {
        ...acc,
        [opCapProductName]: { name: opCapProductName, tableGroup, rowGroup, columnGroup },
      }
    },
    {},
  )

  const handleDelete = meta => {
    return dispatchDeleteAssetData(meta.assetDataId).then(response => {
      if (response.error) {
        notifyError(NOTIFICATION_MSGS.DELETE_ERROR)
        return response
      } else {
        try {
          mutate(undefined, {
            rollbackOnError: true,
            populateCache: true,
            revalidate: true,
          })
        } catch (e) {
          notifyError(NOTIFICATION_MSGS.DATA_FETCH_ERROR)
          return e
        }
      }
    })
  }

  const combinedOpcapPasaSettings = !_.isEmpty(opCapAndPasaSettings)
    ? opCapAndPasaSettings.map(setting => {
        const setting1 = _.first(setting)
        const setting2 = _.get(setting, [1])
        if (_.isEmpty(setting2)) {
          return { ...setting1, assetDataId: [_.get(setting1, ASSET_DATA_ID_KEY)], tags: [_.get(setting1, 'tag')] }
        }
        const combinedSetting = { ...setting1 }
        combinedSetting.assetDataId = [combinedSetting?.assetDataId, _.get(setting2, ASSET_DATA_ID_KEY)]
        combinedSetting.tags = [_.get(setting1, 'tag'), _.get(setting2, 'tag')]
        combinedSetting.data = { ...setting1.data, ...setting2.data }
        return combinedSetting
      })
    : []

  const pastOpcapPasaSettings = combinedOpcapPasaSettings.filter(setting => {
    const startTime = _.get(setting, 'startTime')
    return _.get(setting, 'tags', []) && moment(startTime).isBefore(nextInterval)
  })
  const activeCombinedOpcapAndPasaSetting = !_.isEmpty(combinedOpcapPasaSettings) ? _.last(pastOpcapPasaSettings) : {}

  const activeOpCapSetting = !_.isEmpty(operationalCapacitySettings)
    ? _.last(
        operationalCapacitySettings.filter(opCapSetting => {
          const opCapRecordStartTime = _.get(opCapSetting, 'startTime')
          return moment(opCapRecordStartTime).isBefore(nextInterval)
        }),
      )
    : {}

  const activePasaSetting = !_.isEmpty(pasaSettings)
    ? _.last(
        pasaSettings.filter(pasaSetting => {
          const pasaRecordStartTime = _.get(pasaSetting, 'startTime')
          return moment(pasaRecordStartTime).isBefore(nextInterval)
        }),
      )
    : {}

  const activeOpCapProductSettings = _.get(activeOpCapSetting, 'data')
  const activePasaProductSettings = _.get(activePasaSetting, 'data')

  const activeOpCapMWValues = _.keys(activeOpCapProductSettings).reduce((acc, product) => {
    const activeOperationalCapacityKW = _.get(activeOpCapProductSettings, product)
    const isProductEnergy = product.split('_')[0] === PRODUCT_NAMES.ENERGY
    // round down for renewable or BESS FCAS (e.g. 4999--> 4MW). round up if >=50kW (e.g. 4049-->4MW, 4050-->5MW) for S BESS energy. NS-BESS keep 3 digits in MW
    const convertKwToMwFunction =
      isScheduledBattery && isProductEnergy ? convertBessEnergyKwToMw : convertKwToMWAndTruncate
    const precision = isNsBattery && isProductEnergy ? 3 : 0
    const activeOperationalCapacityMW = _.isNumber(activeOperationalCapacityKW)
      ? convertKwToMwFunction(activeOperationalCapacityKW, precision)
      : null
    return { ...acc, [product]: activeOperationalCapacityMW }
  }, {})

  const activePasaMWValues = _.keys(activePasaProductSettings).reduce((acc, product) => {
    const activePasaKW = _.get(activePasaProductSettings, product)
    const activePasaMW = _.isNumber(activePasaKW) ? convertKwToMWAndTruncate(activePasaKW) : null
    return { ...acc, [product]: activePasaMW }
  }, {})

  const activeItemTableConfig = getOpcapTableConfig(
    activeCombinedOpcapAndPasaSetting,
    isRenewableAsset,
    isScheduledBattery,
    isNsBattery,
    is1SecProductActiveForAsset,
    operationalCapacityMarketAssetDataNameMap,
  )
  const activeSinceTime = _.get(activeCombinedOpcapAndPasaSetting, 'startTime')
    ? moment(_.get(activeCombinedOpcapAndPasaSetting, 'startTime'))
    : getTradingStart(
        market,
        getCalendarDate(moment(_.get(asset, 'data.commercial_online_date'), DATE_FORMAT_DAY), false, market),
      )
  const handleSubmit = async request => {
    const { startTime, endTime, newInputValues } = request
    const submitTimes = [moment(startTime).valueOf()]
    if (!_.isNil(endTime)) {
      const endTimestamp = moment(endTime).valueOf()
      submitTimes.push(endTimestamp)
    }

    const { opCapDataToSubmit, pasaDataToSubmit } = extractSubmitData(
      asset,
      newInputValues,
      endTime,
      operationalCapacityMarketAssetDataNameMap,
    )

    const displaySnackbarForResponse = response => {
      if (response.error) {
        notifyError(NOTIFICATION_MSGS.SAVE_ERROR)
        return response
      } else {
        notifySuccess(NOTIFICATION_MSGS.SAVE_SUCCESS)
      }
    }
    return Promise.all(
      submitTimes.map((time, idx) => {
        const listOfDataToSubmit = []
        const tags = []
        if (!_.isNil(opCapDataToSubmit[idx])) {
          listOfDataToSubmit.push(opCapDataToSubmit[idx])
          tags.push(OPERATIONAL_CAPACITY_SETTING_TAG)
        }
        if (!_.isNil(pasaDataToSubmit[idx])) {
          listOfDataToSubmit.push(pasaDataToSubmit[idx])
          tags.push(PASA_SETTING_TAG)
        }
        return dispatchCreateAssetData(asset.assetId, tags, time, listOfDataToSubmit)
      }),
    ).then(response => {
      if (_.get(response, 'error', false)) {
        // all POST responses failed, display one failed message
        displaySnackbarForResponse(response)
        return response
      } else if (!_.get(response, 'error', false)) {
        // all POST responses succeeded, display one success message
        displaySnackbarForResponse(response)
      }
      try {
        mutate(undefined, {
          rollbackOnError: true,
          populateCache: true,
          revalidate: true,
        })
      } catch (e) {
        notifyError(NOTIFICATION_MSGS.DATA_FETCH_ERROR)
        return e
      }
    })
  }

  const activeItem = {
    title: 'ACTIVE SINCE',
    showDot: false,
    value: [
      {
        time: activeSinceTime,
        value: <OpcapTable data={activeItemTableConfig} />,
      },
    ],
  }

  const upcomingOperationalCapacitySettings = !_.isEmpty(combinedOpcapPasaSettings)
    ? combinedOpcapPasaSettings.filter(
        operationalCapacitySetting => moment(operationalCapacitySetting.startTime) >= nextInterval,
      )
    : []

  const getInputConfig = (dataFieldKey, displayLabel, additionalData = {}) => {
    return {
      label: _.toUpper(dataFieldKey),
      displayLabel: _.toUpper(displayLabel),
      current: getCurrentValue(activeOpCapMWValues, activePasaMWValues, dataFieldKey),
      endTextFieldLabel: `${RETURN_PREFIX} ${_.toUpper(dataFieldKey)}`,
      endTextFieldDisplayLabel: `${RETURN_PREFIX} ${_.toUpper(displayLabel)}`,
      emptyStartDefaultInputValue: true,
      emptyEndDefaultInputValue: false,
      disableImplied: true,
      inputAdornment: '',
      allowNegativeInput: false,
      precision: isNsBattery && dataFieldKey.split('_')[0] === PRODUCT_NAMES.ENERGY ? 3 : 0,
      allowSubmitEmptyInput: !(isNsBattery && dataFieldKey.split('_')[0] === PRODUCT_NAMES.ENERGY),
      emptyInputHelperText: 'Will bid registered capacity',
      maxValueLimit: getMaxValueLimit(asset, configuration, dataFieldKey),
      ...additionalData,
    }
  }

  const denseTableConfig = isRenewableAsset
    ? {}
    : {
        tableName: 'OPERATIONAL CAPACITY',
        columnGroupKey: 'columnGroup',
        columnSortBy: (colAname, colBname) => sortProductTableHorizontal(colAname, colBname),
        rowGroupKey: 'rowGroup',
        rowSortBy: (rowAName, rowBName) => {
          const rowSortOrder = {
            [PRODUCT_TYPES.GEN]: 0,
            [PRODUCT_TYPES.LOAD]: 1,
            [ROW_RAISE_NAME]: 2,
            [ROW_LOWER_NAME]: 3,
          }
          const aOrder = _.get(rowSortOrder, rowAName)
          const bOrder = _.get(rowSortOrder, rowBName)
          return simpleCompareAsc(aOrder, bOrder)
        },
        tableGroupKey: 'tableGroup',
        cellSortBy: (a, b) => sortProductTableHorizontal(_.get(a, 'columnGroup'), _.get(b, 'columnGroup')),
      }

  const inputConfigs = isRenewableAsset
    ? [getInputConfig(schedulableOperaiontalCapacityProducts.ENERGY_GEN, 'OPERATIONAL CAPACITY')]
    : _.values(operationalCapacityTableData).map(product => getInputConfig(product.name, product.name, product))

  const items = upcomingOperationalCapacitySettings.map(item => {
    const tableData = getOpcapTableConfig(
      item,
      isRenewableAsset,
      isScheduledBattery,
      isNsBattery,
      is1SecProductActiveForAsset,
      operationalCapacityMarketAssetDataNameMap,
    )
    const value = <OpcapTable variant="dim" data={tableData} />
    return {
      deleteTitle: 'Remove scheduled change',
      id: _.get(item, ASSET_DATA_ID_KEY).join('_'),
      onDelete: handleDelete,
      meta: {
        assetDataId: item.assetDataId,
      },
      showDelete,
      time: moment(item.startTime),
      value,
      valueLabels: VALUE_LABELS,
    }
  })
  const isLoading = opCapAndPasaSettingsIsLoading
  return (
    <>
      <ChangesCard
        activeItem={activeItem}
        onActionClick={() => setIsSchedulingModalOpen(prev => !prev)}
        hideAction={false}
        isLoading={isLoading}
        activeValueTypographyVariant="h4"
        isSmallCard
        items={items}
        marketStartHour={marketStartHour}
        timezone={mtz}
        title={CARD_TITLE}
        titleTypographyProps={{ variant: 'h3' }}
        useInfoModal
        infoModalHelperText={isRenewableAsset ? RENEWABLE_INFO_MODAL_TEXT : BESS_INFO_MODAL_TEXT}
        infoModalTitle="Operational Capacity Adjustment Information"
      />
      <SchedulingModal
        denseTableConfig={denseTableConfig}
        description={isRenewableAsset ? RENEWABLE_SCHEDULE_DESCRIPTION_TEXT : BESS_SCHEDULE_DESCRIPTION_TEXT}
        disableSubmitIfCurrentPriceIsSameAsNewPrice={false}
        endTimeDatePickerLabelPrefix="RETURN"
        getMarketPriceCap={() =>
          isRenewableAsset
            ? convertKwToMWAndTruncate(energyGenRegisteredLimitKw)
            : getNumberTruncatedAfterXthDecimalPlace(getAssetMWCapacity(asset), 3)
        }
        hasScheduleEndTime
        inputConfigs={inputConfigs}
        checkValidInputCrossValidation={isRenewableAsset ? () => true : checkValidInputCrossValidation}
        inputDecimalLimit={0}
        includeFieldNameInSubmittedValue
        largeInputTextField
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleSubmit}
        precision={0}
        title="Schedule Operational Capacity Adjustment"
        variant={isRenewableAsset ? 'standard' : 'dense'}
        wideModal={!isRenewableAsset}
      />
    </>
  )
}

/*
  BESS Schedule Card Functions
*/

const extractSubmitData = (asset, newInputValues, endTime, operationalCapacityMarketAssetDataNameMap) => {
  const newInputValuesObject = newInputValues.reduce((acc, item) => Object.assign(acc, { ...item }), {})
  const opCapDataToSubmit = []
  const pasaDataToSubmit = []
  const isInputValueNullOrEmpty = inputValue => _.isNil(inputValue) || isNaN(inputValue)
  const startSubmitDataOpCap = _.values(operationalCapacityMarketAssetDataNameMap).reduce((acc, productName) => {
    const inputValue = _.get(newInputValuesObject, productName)
    const submitValue = isInputValueNullOrEmpty(inputValue) ? null : convertMWToKW(inputValue)
    return { ...acc, [productName]: submitValue }
  }, {})

  opCapDataToSubmit.push(startSubmitDataOpCap)
  if (isAssetScheduledBattery(asset) || isAssetNonScheduledBattery(asset)) {
    const startSubmitDataPasa = _.values(PASA_SETTINGS).reduce((acc, productName) => {
      const inputValue = _.get(newInputValuesObject, productName)
      const submitValue = isInputValueNullOrEmpty(inputValue) ? null : convertMWToKW(inputValue)
      return { ...acc, [productName]: submitValue }
    }, {})
    pasaDataToSubmit.push(startSubmitDataPasa)
  }

  if (!_.isNil(endTime)) {
    const endSubmitDataOpCap = _.values(operationalCapacityMarketAssetDataNameMap).reduce((acc, productName) => {
      const inputValue = _.get(newInputValuesObject, `${RETURN_PREFIX} ${productName}`)
      const submitValue = isInputValueNullOrEmpty(inputValue) ? null : convertMWToKW(inputValue)
      return { ...acc, [productName]: submitValue }
    }, {})
    // always attach a new end data if the start data is new and modified vs the current record
    opCapDataToSubmit.push(endSubmitDataOpCap)
    if (isAssetScheduledBattery(asset) || isAssetNonScheduledBattery(asset)) {
      const endSubmitDataPasa = _.values(PASA_SETTINGS).reduce((acc, productName) => {
        const inputValue = _.get(newInputValuesObject, `${RETURN_PREFIX} ${productName}`)
        const submitValue = isInputValueNullOrEmpty(inputValue) ? null : convertMWToKW(inputValue)
        return { ...acc, [productName]: submitValue }
      }, {})
      pasaDataToSubmit.push(endSubmitDataPasa)
    }
  }

  return { opCapDataToSubmit, pasaDataToSubmit }
}

const checkValidInputCrossValidation = (values, inputConfig, inputFloatValue, inputLabel) => {
  // Product Input Cross Validation: PASA_GEN/PASA_LOAD >= ENERGY_GEN/ENERGY_LOAD
  const { label } = inputConfig
  const [productName, productType] = label.split('_')
  const isInputProductEnergy = productName === PRODUCT_NAMES.ENERGY
  const isInputProductPasa = productName === GENERIC_PASA_NAME
  if (isInputProductEnergy || isInputProductPasa) {
    const inputPrefix = inputLabel.replace(label, '') // label is the product name that makes up the postfix of the inputLabel
    const correspondingProduct = isInputProductEnergy ? GENERIC_PASA_NAME : PRODUCT_NAMES.ENERGY
    const correspondingKey = `${inputPrefix}${correspondingProduct}_${productType}`
    const correspondingInputValue = _.get(values, correspondingKey)
    const pasaInputValue = isInputProductEnergy ? correspondingInputValue : inputFloatValue
    const energyInputValue = isInputProductEnergy ? inputFloatValue : correspondingInputValue
    if (!isNaN(parseFloat(pasaInputValue)) && !isNaN(parseFloat(energyInputValue))) {
      return parseFloat(energyInputValue) <= parseFloat(pasaInputValue)
    } else if (isNaN(parseFloat(energyInputValue)) && !isNaN(parseFloat(pasaInputValue))) {
      return false
    }
  }
  return true
}

const getMaxValueLimit = (asset, configuration, dataFieldKey) => {
  const productName = dataFieldKey.split('_')[0]
  // Find PASA Limit
  if (productName === GENERIC_PASA_NAME) {
    const assetModelPasaFieldKey = ['data', 'properties', 'energy_bid_unit_limits', `${_.toLower(dataFieldKey)}_kw`]
    const assetPasaLimit = _.get(asset, assetModelPasaFieldKey)
    return convertKwToMWAndTruncate(assetPasaLimit)
  }
  // Edge case for NS BESS
  if (isAssetNonScheduledBattery(asset) && productName === PRODUCT_NAMES.ENERGY) {
    return getNumberTruncatedAfterXthDecimalPlace(getAssetMWCapacity(asset), 3)
  }

  // Find Market Product Limit
  return convertKwToMWAndTruncate(_.get(configuration, dataFieldKey))
}

const getCurrentValue = (activeOpCapMWValues, activePasaMWValues, dataFieldKey) => {
  const productName = dataFieldKey.split('_')[0]
  return productName === GENERIC_PASA_NAME
    ? _.get(activePasaMWValues, dataFieldKey)
    : _.get(activeOpCapMWValues, dataFieldKey)
}

const simpleCompareAsc = (a, b) => {
  if (a < b || a > b) {
    return a < b ? -1 : 1
  } else {
    return 0
  }
}

const fcasContingencyColSortOrder = {
  [COL_ENERGY_NAME]: 0,
  [COL_PASA_NAME]: 1,
  [COL_LOWER_REG_NAME]: 2,
  [COL_RAISE_REG_NAME]: 3,
}

const compareTimeValueInProductName = (a, b) => {
  const aFoundTimeValue = a.match(/\d+/g)[0]
  const bFoundTimeValue = b.match(/\d+/g)[0]
  if (_.isNil(aFoundTimeValue) && _.isNil(bFoundTimeValue)) {
    return simpleCompareAsc(a, b)
  }
  // compute time value in seconds
  let aSeconds = 0
  let bSeconds = 0
  _.keys(PRODUCT_TIME_LABEL_TO_SEC).forEach(timeLabel => {
    if (a.includes(timeLabel)) {
      aSeconds = _.get(PRODUCT_TIME_LABEL_TO_SEC, timeLabel) * parseInt(aFoundTimeValue)
    }
    if (b.includes(timeLabel)) {
      bSeconds = _.get(PRODUCT_TIME_LABEL_TO_SEC, timeLabel) * parseInt(bFoundTimeValue)
    }
  })
  return simpleCompareAsc(aSeconds, bSeconds)
}

const sortProductTableHorizontal = (colAname, colBname) => {
  const aOrder = _.get(fcasContingencyColSortOrder, colAname)
  const bOrder = _.get(fcasContingencyColSortOrder, colBname)
  if (_.isNil(aOrder) || _.isNil(bOrder)) {
    return compareTimeValueInProductName(colAname, colBname)
  }
  return simpleCompareAsc(aOrder, bOrder)
}

/*
  BESS ChangesCard Table Functions
*/

const getOpcapTableConfig = (
  opcapOverride,
  isRenewable,
  isScheduledBattery,
  isNonScheduledBattery,
  is1SecProductActiveForAsset,
  operationalCapacityMarketAssetDataNameMap,
) => {
  if (_.isEmpty(opcapOverride)) {
    return
  }
  let displayMatrix = [
    {
      labels: [COL_ENERGY_NAME],
      rows: [{ name: '', values: new Map([[operationalCapacityMarketAssetDataNameMap.ENERGY_GEN, '']]) }],
    },
  ]
  if (isScheduledBattery) {
    const t1r1Name = 'GEN'
    const t1r1Values = new Map([
      ['ENERGY_GEN', ''],
      ['PASA_GEN', ''],
      ['LOWERREG_GEN', ''],
      ['RAISEREG_GEN', ''],
    ])
    const t1r2Name = 'LOAD'
    const t1r2Values = new Map([
      ['ENERGY_LOAD', ''],
      ['PASA_LOAD', ''],
      ['LOWERREG_LOAD', ''],
      ['RAISEREG_LOAD', ''],
    ])

    // t2r1
    const t2r1Name = 'C-RAISE'
    const t2r1KeyValuePairs = [
      ['RAISE6SEC_GEN', ''],
      ['RAISE60SEC_GEN', ''],
      ['RAISE5MIN_GEN', ''],
    ]

    // t2r2
    const t2r2Name = 'C-LOWER'
    const t2r2KeyValuePairs = [
      ['LOWER6SEC_LOAD', ''],
      ['LOWER60SEC_LOAD', ''],
      ['LOWER5MIN_LOAD', ''],
    ]

    if (is1SecProductActiveForAsset) {
      t2r1KeyValuePairs.unshift(['RAISE1SEC_GEN', ''])
      t2r2KeyValuePairs.unshift(['LOWER1SEC_LOAD', ''])
    }

    // create value map
    const t2r1Values = new Map(t2r1KeyValuePairs)
    const t2r2Values = new Map(t2r2KeyValuePairs)

    displayMatrix = [
      {
        labels: [COL_ENERGY_NAME, COL_PASA_NAME, COL_LOWER_REG_NAME, COL_RAISE_REG_NAME],
        rows: [
          { name: t1r1Name, values: t1r1Values },
          { name: t1r2Name, values: t1r2Values },
        ],
      },
      {
        labels: ['6SEC', '60SEC', '5MIN'],
        rows: [
          { name: t2r1Name, values: t2r1Values },
          { name: t2r2Name, values: t2r2Values },
        ],
      },
    ]
    if (is1SecProductActiveForAsset) {
      displayMatrix[1].labels.unshift('1SEC')
    }
  } else if (isNonScheduledBattery) {
    // t1r1
    const t1r1Name = 'GEN'
    const t1r1Values = new Map([['ENERGY_GEN', '']])

    // t1r2
    const t1r2Name = 'LOAD'
    const t1r2Values = new Map([['ENERGY_LOAD', '']])

    // t2r1
    const t2r1Name = 'C-RAISE'
    const t2r1KeyValuePairs = [
      ['RAISE6SEC_LOAD', ''],
      ['RAISE60SEC_LOAD', ''],
      ['RAISE5MIN_LOAD', ''],
    ]

    // t2r2
    const t2r2Name = 'C-LOWER'
    const t2r2KeyValuePairs = [
      ['LOWER6SEC_LOAD', ''],
      ['LOWER60SEC_LOAD', ''],
      ['LOWER5MIN_LOAD', ''],
    ]
    if (is1SecProductActiveForAsset) {
      t2r1KeyValuePairs.unshift(['RAISE1SEC_LOAD', ''])
      t2r2KeyValuePairs.unshift(['LOWER1SEC_LOAD', ''])
    }
    const t2r1Values = new Map(t2r1KeyValuePairs)
    const t2r2Values = new Map(t2r2KeyValuePairs)

    displayMatrix = [
      {
        labels: ['ENERGY'],
        rows: [
          { name: t1r1Name, values: t1r1Values },
          { name: t1r2Name, values: t1r2Values },
        ],
      },
      {
        labels: ['6SEC', '60SEC', '5MIN'],
        rows: [
          { name: t2r1Name, values: t2r1Values },
          { name: t2r2Name, values: t2r2Values },
        ],
      },
    ]
    if (is1SecProductActiveForAsset) {
      displayMatrix[1].labels.unshift('1SEC')
    }
  }
  updateDisplayMatrix(displayMatrix, opcapOverride, isRenewable, isScheduledBattery, isNonScheduledBattery)
  return displayMatrix
}

const updateDisplayMatrix = (
  displayMatrix,
  operationalCapacitySetting,
  isRenewable,
  isScheduledBattery,
  isNonScheduledBattery,
) => {
  displayMatrix.forEach((table, tableIndex) => {
    table.rows.forEach((row, rowIndex) => {
      const values = row?.values || new Map()
      for (const key of values.keys()) {
        const value = operationalCapacitySetting?.data?.[key]
        let displayValue = ''
        if (_.isNumber(value)) {
          displayValue = getOpcapDisplayValue(value, key, isRenewable, isScheduledBattery, isNonScheduledBattery)
        } else {
          displayValue = _.isNull(value) ? '-' : ''
        }
        values.set(key, displayValue)
      }
    })
  })
}

OperationalCapacityCard.displayName = DISPLAY_NAME

const mapDispatchToProps = dispatch => {
  return {
    dispatchDeleteAssetData: assetDataId => dispatch(deleteMultipleAssetDataResource.put(assetDataId)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(postMultipleAssetDataResource.post(assetId, tag, startTime, data)),
  }
}

export default connect(null, mapDispatchToProps)(OperationalCapacityCard)
