import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { Button } from '@fluence/core'
import Card from '../Card'

const DISPLAY_NAME = 'PPACardInfo'

const useStyles = makeStyles(
  theme => ({
    clear: {
      flex: '0 1 auto',
    },
    closeBtn: {
      position: 'absolute',
      top: theme.spacing(-3.5),
      right: theme.spacing(-0.75),
    },
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: theme.spacing(92),
      position: 'absolute',
      top: '15%',
      right: 0,
      left: 0,
    },
    card: {
      maxWidth: 856,
      maxHeight: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    cardHeader: {
      paddingBottom: theme.spacing(0.75),
      borderBottom: `1px solid ${theme.palette.background.paper}`,
    },
    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1.25),
      paddingRight: 0,
      paddingLeft: 0,
      '&:last-child': {
        paddingBottom: theme.spacing(1.25),
      },
    },
    textBody: {
      paddingLeft: theme.spacing(2),
      overflow: 'auto',
      whiteSpace: 'pre-wrap',
    },
    box: {
      paddingTop: theme.spacing(2),
    },
    smallTitle: {
      fontSize: 16,
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    definition: {
      color: theme.palette.text.secondary,
    },
    footer: {
      maxWidth: 856,
      width: '100%',
      borderTop: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
      padding: theme.spacing(2),
      flex: '1 0 auto',
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    primaryBtn: {
      height: theme.spacing(4),
      padding: theme.spacing(0.5, 1.75),
    },
  }),
  { name: DISPLAY_NAME },
)

const PPACardInfo = React.forwardRef(function DownloadBids(props, ref) {
  const { onClose } = props
  const classes = useStyles()

  return (
    <div className={classes.root} ref={ref}>
      <Box className={classes.clear}>
        <IconButton className={classes.closeBtn} size="small" onClick={onClose}>
          <Clear fontSize="small" />
        </IconButton>
      </Box>
      <Card
        title="PPA Terms"
        classes={{
          root: classes.card,
          header: classes.cardHeader,
          content: classes.cardContent,
        }}
        titleTypographyProps={{ variant: 'h3' }}
      >
        <div className={classes.textBody}>
          <Box>
            <Typography className={classes.smallTitle}>PPA Price</Typography>
            <Typography variant="body1" className={classes.definition}>
              Total realised price received for actual generation
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>Floating Price Floor</Typography>
            <Typography variant="body1" className={classes.definition}>
              Lower floating price limit
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>Floating Price Ceiling</Typography>
            <Typography variant="body1" className={classes.definition}>
              Upper floating price limit
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>Not Paid Below</Typography>
            <Typography variant="body1" className={classes.definition}>
              Market price below which PPA will not pay out
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>LGC Recipient</Typography>
            <Typography variant="body1" className={classes.definition}>
              Determines if Offtaker or Generator is entitled to LGCs from generation under contract
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>Coverage Ratio</Typography>
            <Typography variant="body1" className={classes.definition}>
              Percent of asset’s generation covered by PPA contract
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.smallTitle}>FCAS Cost Sensitivity</Typography>
            <Typography variant="body1" className={classes.definition}>
              Sensitivity to FCAS prices. A lower value equates to asset being less sensitive to FCAS prices
            </Typography>
          </Box>
        </div>
      </Card>
      <div className={classes.footer}>
        <Button variant="primary" className={classes.primaryBtn} onClick={onClose}>
          CLOSE
        </Button>
      </div>
    </div>
  )
})

PPACardInfo.displayName = DISPLAY_NAME

export default PPACardInfo
