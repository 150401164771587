import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { formatMarketValue } from '../../utility/utility'

const HORIZONS = ['5m', '30m', '1h', '3h', '12h', '24h']
const COLUMNS = ['Fluence', 'AEMO', 'difference']
const DISPLAY_NAME = 'SingleMonthTable'

const useStyles = makeStyles(
  theme => ({
    root: {},
    firstHeaderCell: {
      borderBottom: 'none',
    },
    firstColumnCell: {
      minWidth: theme.typography.pxToRem(32),
      maxWidth: theme.typography.pxToRem(32),
      width: theme.typography.pxToRem(32),
    },
    borderless: {
      border: 'none',
    },
    spacerColumn: {
      border: 'none',
      padding: 0,
      margin: 0,
      minWidth: theme.spacing(2),
    },
    tableContainer: {
      '& table td, & table th': {
        padding: theme.spacing(0.75, 0.5, 0.75, 0.5),
      },
    },
    fontWhite: {
      color: theme.custom.palette.colors.WHITE,
    },
    fontBlue: {
      color: theme.custom.palette.colors.CORNFLOWER_BLUE,
    },
    fontOrange: {
      color: theme.custom.palette.colors.CALIFORNIA,
    },
  }),
  { name: DISPLAY_NAME },
)

function FirstHeader(props) {
  const classes = useStyles(props)
  return (
    <TableRow>
      <TableCell className={classes.firstHeaderCell} />
      <TableCell className={classes.spacerColumn} />
      <TableCell className={classes.firstHeaderCell} colSpan={6}>
        Fluence
      </TableCell>
      <TableCell className={classes.spacerColumn} />
      <TableCell className={classes.firstHeaderCell} colSpan={6}>
        AEMO
      </TableCell>
      <TableCell className={classes.spacerColumn} />
      <TableCell className={classes.firstHeaderCell} colSpan={6}>
        DIFFERENCE
      </TableCell>
    </TableRow>
  )
}

function SecondHeader(props) {
  const classes = useStyles(props)
  return (
    <>
      <TableRow>
        <TableCell className={classes.firstColumnCell}>DAY</TableCell>
        <TableCell className={classes.spacerColumn} />
        {HORIZONS.map((horizon, index) => (
          <TableCell key={`ams-${horizon}-${index}`}>{horizon}</TableCell>
        ))}
        <TableCell className={classes.spacerColumn} />
        {HORIZONS.map((horizon, index) => (
          <TableCell key={`aemo-${horizon}-${index}`}>{horizon}</TableCell>
        ))}
        <TableCell className={classes.spacerColumn} />
        {HORIZONS.map((horizon, index) => (
          <TableCell key={`difference-${horizon}-${index}`}>{horizon}</TableCell>
        ))}
      </TableRow>
    </>
  )
}

function SingleMonthTable(props) {
  const classes = useStyles(props)
  const { isLoading, selectedMetric, tableData, timezone, yearMonth } = props

  const getRows = () => {
    if (isLoading) {
      // wait for the data to fully load before rendering the table
      return
    }
    const startTime = moment.tz(yearMonth + '-01', timezone)
    const daysInMonth = moment(startTime).add(1, 'months').subtract(1, 'hours').date()
    const factor = selectedMetric === 'MAE' ? 1000 : 100
    const fix = selectedMetric === 'MAE' ? 2 : 1

    return _.range(0, daysInMonth).map(dayIndex => {
      const dayValue = moment(startTime).add(dayIndex, 'd').format('DD')
      const day = (
        <TableCell className={classes.firstColumnCell} key={'day'}>
          {dayValue}
        </TableCell>
      )
      const row = []

      COLUMNS.forEach((column, columnIndex) => {
        row.push(<TableCell className={classes.spacerColumn} key={columnIndex} />)
        HORIZONS.forEach((horizon, horizonIndex) => {
          const horizonData = _.get(tableData, [horizonIndex, dayIndex], [null, null])
          const [amsValue, aemoValue] = horizonData

          let value = null
          let colorClass = classes.fontWhite
          if (column === 'difference' && !_.isNil(aemoValue || null) && !_.isNil(amsValue || null)) {
            value = (aemoValue || []) - (amsValue || [])
            if (value > 0) {
              colorClass = classes.fontBlue
            } else if (value < 0) {
              colorClass = classes.fontOrange
            }
          } else if (`${column}`.toLowerCase() !== 'difference') {
            value = horizonData[columnIndex] || null
          }
          row.push(
            <TableCell className={colorClass} key={column + horizon}>
              {_.isNil(value) ? '-' : formatMarketValue(value, factor, fix)}
            </TableCell>,
          )
        })
      })
      return (
        <TableRow hover key={dayIndex}>
          {_.concat(day, row)}
        </TableRow>
      )
    })
  }

  return (
    <div className={classes.tableContainer} style={{ overflowX: 'auto' }}>
      <Table size="small" aria-label="price forecast table">
        <TableHead>
          <FirstHeader />
          <SecondHeader />
        </TableHead>
        <TableBody>{!isLoading && getRows()}</TableBody>
      </Table>
    </div>
  )
}

SingleMonthTable.displayName = DISPLAY_NAME

export default SingleMonthTable

// const mapStateToProps = (state, ownProps) => {
//   const data = !_.isNil(ownProps.yearMonth)
//     ? getChartData(state.forecast, ownProps.keyPrefix, moment(ownProps.yearMonth + '-01'))
//     : {}
//   return {
//     isLoading: _.isEmpty(data) || data.isLoading,
//     tableData: _.get(data, 'data', []),
//   }
// }

// export default connect(mapStateToProps)(SingleMonthTable)
