import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { InputBase as MuiInputBase } from '@material-ui/core'

const DISPLAY_NAME = 'AmsInputBase'

const InputBase = withStyles(theme => ({
  input: {},
  root: {
    borderRadius: 500,
    position: 'relative',
    backgroundColor: '#434C57',
    border: 'none',
    color: 'white',
    fontSize: 14,
    maxHeight: theme.typography.pxToRem(32),
    minHeight: theme.typography.pxToRem(32),
    padding: theme.spacing(0, 0.5, 0, 1.375),
    '&:focus': {
      borderRadius: '500px',
      borderColor: 'none',
      backgroundColor: '#434C57',
    },
  },
}))(MuiInputBase)

InputBase.displayName = DISPLAY_NAME

InputBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
}

export default InputBase
