import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Select as MuiSelect, Tooltip } from '@material-ui/core'
import InputBase from './InputBase'

const DISPLAY_NAME = 'AmsSelect'
let uid = 1

const useStyles = makeStyles(theme => ({
  orangeDropdownSelected: {
    '&& .Mui-selected': {
      backgroundColor: theme.custom.palette.colors.BRIGHT_ORANGE,
    },
  },
}))

export default function Select(props) {
  const {
    className: classNameProp,
    children,
    id = `ams-select-input-${uid++}`,
    tooltip = '',
    value = '',
    shouldHighlight = false,
    disabled = false,
    ...rest
  } = props
  const classes = useStyles()
  const overrideMenuClass = shouldHighlight ? classes.orangeDropdownSelected : ''
  return (
    <>
      <Tooltip title={tooltip} placement="top">
        <MuiSelect
          MenuProps={{
            classes: { paper: overrideMenuClass },
          }}
          className={classNameProp}
          id={id}
          value={value}
          input={<InputBase />}
          disabled={disabled}
          {...rest}
        >
          {children}
        </MuiSelect>
      </Tooltip>
    </>
  )
}

Select.displayName = DISPLAY_NAME

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
