import React, { useState } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Button } from '@fluence/core'
import { validatePassword } from '../../utility/user-utils'

const useStyles = makeStyles(theme => ({
  head: {
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.background.paper}`,
  },
  body: {
    padding: theme.spacing(1, 2, 6),
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 608,
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  form: {},
  formControl: {
    display: 'flex',
  },
  foot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.background.paper}`,
  },
  marginLeft: {
    marginLeft: theme.spacing(),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

function ChangePassword(props) {
  const { open = false, onClose, onSubmit } = props
  const classes = useStyles()

  const initialValues = {
    showNewPassword: false,
    newPassword: '',
    confirmPassword: '',
  }
  const [values, setValues] = useState(initialValues)

  const initialTouched = {
    newPassword: false,
    confirmPassword: false,
  }
  const [touched, setTouched] = useState(initialTouched)

  const handleToggleValue = name => () => {
    setValues({ ...values, [name]: !values[name] })
  }
  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleChange = name => event => {
    const value = _.get(event, 'target.value')
    setValues({ ...values, [name]: value })
    setTouched({ ...touched, [name]: true })
  }

  const handleOnBlur = name => event => {
    setTouched({ ...touched, [name]: true })
  }

  const handleClose = event => {
    setValues(initialValues)
    setTouched(initialTouched)
    onClose()
  }

  const checkPassword = () => {
    onSubmit(values.newPassword).then(response => {
      if (response.error) {
        console.error('Unable to reset password')
      } else {
        handleClose()
      }
    })
  }

  const handleSubmit = () => {
    checkPassword()
  }

  const newPasswordDisabled = !touched.newPassword
  const passwordMissing = validatePassword(values.newPassword)
  const validationErrors = passwordMissing.rules
    .filter(rule => !rule.verified)
    .map(rule => {
      if (rule.items) {
        return rule.description + rule.condensedItemSummary
      }
      return rule.description
    })

  const isValidationError = !passwordMissing.verified
  const showValidationError = isValidationError && touched.newPassword

  const confirmPasswordDisabled =
    (_.isEmpty(values.newPassword) || newPasswordDisabled || isValidationError) && !touched.confirmPassword

  const passwordsMatch = values.newPassword === values.confirmPassword
  const showPasswordMatchError = touched.newPassword && touched.confirmPassword && !passwordsMatch
  const submitDisabled =
    Object.values(touched).some(fieldValue => fieldValue === false) || showPasswordMatchError || isValidationError

  return (
    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open}>
      <div className={classes.paper}>
        <div className={classes.head}>
          <Typography variant="h2">Change password</Typography>
        </div>

        <form noValidate autoComplete="off">
          <div className={classes.body}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Box height={128}>
                    <FormControl
                      className={classNames(classes.formControl, classes.marginBottom)}
                      error={showValidationError}
                    >
                      <InputLabel htmlFor="cpwd-new-password">New password</InputLabel>
                      <Input
                        id="cpwd-new-password"
                        type={values.showNewPassword ? 'text' : 'password'}
                        value={values.newPassword}
                        onChange={handleChange('newPassword')}
                        onBlur={handleOnBlur('newPassword')}
                        spellCheck="false"
                        inputProps={{ maxLength: 128 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleToggleValue('showNewPassword')}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Fade in={showValidationError}>
                        <FormHelperText data-test-id="confirm-password-error" error>
                          {_.first(validationErrors)}
                        </FormHelperText>
                      </Fade>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    disabled={confirmPasswordDisabled}
                    error={showPasswordMatchError}
                  >
                    <InputLabel htmlFor="cpwd-confirm-password">Confirm password</InputLabel>
                    <Input
                      id="cpwd-confirm-password"
                      type={values.showNewPassword ? 'text' : 'password'}
                      value={values.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                      onBlur={handleOnBlur('confirmPassword')}
                      spellCheck="false"
                      inputProps={{ maxLength: 128 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleToggleValue('showNewPassword')}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Fade in={showPasswordMatchError}>
                      <FormHelperText data-test-id="confirm-password-error" error>
                        Passwords don't match
                      </FormHelperText>
                    </Fade>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.foot}>
            <Button variant="primary" className={classes.marginLeft} disabled={submitDisabled} onClick={handleSubmit}>
              change
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

ChangePassword.displayName = 'ChangePassword'

export default ChangePassword
