import PropTypes from 'prop-types'

const MarketManualBids = {
  getManualBidsByProductIds: {
    name: 'getManualBidsByProductIds',
    method: 'GET',
    url: ({ assetId }) => `/market/bids/manual/asset/${assetId}/range`,
    pathParams: { assetId: PropTypes.number },
    queryParams: {
      'productIds': PropTypes.arrayOf(PropTypes.number).isRequired,
      'startTime': PropTypes.string.isRequired,
      'endTime': PropTypes.string.isRequired,
      'hideDeleted': PropTypes.bool.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketManualBids
