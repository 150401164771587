import moment from 'moment-timezone'
import _ from 'lodash'
import numeral from 'numeral'
import {
  monthDayFormat,
  paddedHourMinuteFormat,
  paddedMonthDayHourMinuteFormat,
  yearFormat,
  yearMonthFormat,
} from '../../utility/formats'
import { formatCalendarDateAsTradingDay, formatValue as formatForNaN } from '../../utility/utility'

export const getXAxisBreakpoint = ({ start, end }) => {
  if (!_.isFinite(start) || !_.isFinite(end)) {
    return null
  }
  const delta = Math.abs(end - start)
  return xAxisBreakpoints.find(breakpoint => breakpoint.maximumDuration >= delta)
}

export const getYAxisBreakpoint = ({ minimum, maximum }, height, includeZero) => {
  if (!_.isFinite(minimum) || !_.isFinite(maximum) || !_.isFinite(height)) {
    return null
  }
  const delta = Math.abs(maximum - minimum)
  const breakpoint = yAxisBreakpoints.find(breakpoint => breakpoint.maximum >= delta) || {
    maximum: Number.MAX_SAFE_INTEGER,
    tickInterval: (maximum / 5.0).toFixed(0),
  }

  return breakpoint
}

export const formatAndPadValue =
  ({ format, prefix, suffix, maxLength }) =>
  e => {
    const numberFormatted = numeral(e.value).format(format || '0,0')
    const formatted = `${prefix || ''}${numberFormatted}${suffix || ''}`

    // using zero width joiner to create anchor for padding
    // https://htmlhelp.org/reference/html40/entities/special.html
    const result = '\u200d' + formatted.padStart(maxLength || 12)
    return result
  }

export const formatValue =
  ({ format, prefix, suffix }) =>
  value => {
    const numberFormatted = formatForNaN(value)
    const result = `${prefix || ''}${numberFormatted}${suffix || ''}`
    return result
  }

const formatter =
  format =>
  (timezone = 'Etc/GMT-10', marketStartHour = 4) =>
  e =>
    formatCalendarDateAsTradingDay(moment.unix(e.value), timezone, marketStartHour, format)

const hourMinuteFormatter = formatter(paddedHourMinuteFormat)
const dayHourMinuteFormatter = formatter(paddedMonthDayHourMinuteFormat)
const dayFormatter = formatter(monthDayFormat)

const xAxisBreakpoints = [
  {
    maximumDuration: moment.duration(2, 'hour').asSeconds(),
    tickInterval: moment.duration(5, 'minute').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(6, 'hour').asSeconds(),
    tickInterval: moment.duration(15, 'minute').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(12, 'hour').asSeconds(),
    tickInterval: moment.duration(30, 'minute').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(12, 'hour').asSeconds(),
    tickInterval: moment.duration(30, 'minute').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(1, 'day').asSeconds(),
    tickInterval: moment.duration(1, 'hour').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(2, 'day').asSeconds(),
    tickInterval: moment.duration(2, 'hour').asSeconds(),
    timeFormatter: hourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(4, 'day').asSeconds(),
    tickInterval: moment.duration(4, 'hour').asSeconds(),
    timeFormatter: dayHourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(8, 'day').asSeconds(),
    tickInterval: moment.duration(12, 'hour').asSeconds(),
    timeFormatter: dayHourMinuteFormatter,
  },
  {
    maximumDuration: moment.duration(1, 'month').asSeconds(),
    tickInterval: moment.duration(1, 'day').asSeconds(),
    timeFormatter: dayFormatter,
  },
  {
    maximumDuration: moment.duration(3, 'month').asSeconds(),
    tickInterval: moment.duration(7, 'day').asSeconds(),
    timeFormatter: dayFormatter,
  },
  {
    maximumDuration: moment.duration(1, 'year').asSeconds(),
    tickInterval: moment.duration(1, 'month').asSeconds(),
    timeFormatter: formatter(yearMonthFormat),
  },
  {
    maximumDuration: moment.duration(3, 'year').asSeconds(),
    tickInterval: moment.duration(3, 'month').asSeconds(),
    timeFormatter: formatter(yearMonthFormat),
  },
  {
    maximumDuration: Number.MAX_SAFE_INTEGER,
    tickInterval: moment.duration(1, 'year').asSeconds(),
    timeFormatter: formatter(yearFormat),
  },
]

export const yAxisBreakpoints = [
  {
    maximum: 3,
    tickInterval: 1,
  },
  {
    maximum: 5,
    tickInterval: 1,
  },
  {
    maximum: 10,
    tickInterval: 2,
  },
  {
    maximum: 50,
    tickInterval: 10,
  },
  {
    maximum: 100,
    tickInterval: 20,
  },
  {
    maximum: 500,
    tickInterval: 100,
  },
  {
    maximum: 1000,
    tickInterval: 200,
  },
  {
    maximum: 2500,
    tickInterval: 500,
  },
  {
    maximum: 5000,
    tickInterval: 1000,
  },
  {
    maximum: 10000,
    tickInterval: 2000,
  },
  {
    maximum: 25000,
    tickInterval: 5000,
  },
  {
    maximum: 50000,
    tickInterval: 10000,
  },
  {
    maximum: 100000,
    tickInterval: 20000,
  },
  {
    maximum: 500000,
    tickInterval: 100000,
  },
  {
    maximum: 1000000,
    tickInterval: 200000,
  },
  {
    maximum: 2500000,
    tickInterval: 500000,
  },
  {
    maximum: 5000000,
    tickInterval: 1000000,
  },
  {
    maximum: 10000000,
    tickInterval: 2000000,
  },
]
