import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, Grid } from '@material-ui/core'
import Card from '../Card'
import Toggle from '../Toggle'
import KeyboardDatePicker from '../KeyboardDatePicker'
import { getCardSubtitleByDate, getTradingStart } from '../../utility/utility'
import MarketIntelligenceGraphs from './graphs/MarketIntelligenceGraphs'

const DISPLAY_NAME = 'MarketIntelligenceCard'

const useStyles = makeStyles(
  theme => ({
    root: {
      height: 821,
    },
    cardAction: {
      padding: theme.spacing(2, 0, 1, 0),
      flex: '0 1 auto',
    },
    cardHeader: {
      flexWrap: 'wrap',
    },
    cardHeaderContent: {
      paddingBottom: theme.spacing(),
    },
    cardTitle: {
      minWidth: theme.spacing(25),
    },
    fallbackContainer: {
      minHeight: theme.spacing(42),
    },
    datePickerGrid: {
      maxHeight: theme.spacing(6),
    },
  }),
  { name: DISPLAY_NAME },
)

const MarketIntelligenceCard = React.memo(props => {
  const classes = useStyles(props)
  const {
    className: classNameProp,
    currentAsOf,
    defaultAsset,
    getRegionProductIntervalData,
    market,
    regions = [],
  } = props

  const regionOrder = _.get(market, 'data.map_region_order', [])
  const regionNames = regions.map(r => _.get(r, 'data.display_name'))
  const regionsMapByName = _.keyBy(regions, 'name')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const marketTimezone = _.get(market, 'data.timezone')
  moment.tz.setDefault(marketTimezone)

  const initialSelectedDate = getTradingStart(market)
  const [values, setValues] = useState({
    startDate: initialSelectedDate,
  })

  const selectedDateTimestamp = moment(getTradingStart(market, _.get(values, 'startDate'))).valueOf()

  const [showActiveRegionName, setShowActiveRegionName] = useState()
  const [regionOptions, setRegionOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const enableRegionalOptions = useCallback(() => {
    if (!_.isEmpty(regionOptions) && regionOptions.some(opt => opt.disabled)) {
      setRegionOptions(prevOptions => {
        const result = prevOptions.map(option => ({
          ...option,
          disabled: false,
        }))
        return result
      })
    }
  }, [regionOptions])

  useEffect(() => {
    if (!_.isNil(market) && _.isNil(values.startDate)) {
      setValues(prev => ({
        ...prev,
        startDate: getTradingStart(market),
      }))
    }
  }, [market, values.startDate])

  const regionNamesJoined = regionNames.join('___')
  const regionOrderJoined = regionOrder.join('___')
  const regionsStringified = JSON.stringify(regions)
  useEffect(() => {
    const regionNames = regionNamesJoined.split('___')
    const regionOrder = regionOrderJoined.split('___')
    const regions = JSON.parse(regionsStringified)
    if (!_.isEmpty(regionNames) && !_.isEmpty(regionOrder)) {
      const orderedRegions = createOrderedRegionList(regions, regionOrder)
      setRegionOptions(
        orderedRegions.map(region => {
          return {
            text: _.get(region, 'data.display_name', ''),
            key: _.get(region, 'name', ''),
            toolTip: _.get(region, 'data.display_name', ''),
          }
        }),
      )
    }
  }, [regionNamesJoined, regionOrderJoined, regionsStringified])

  useEffect(() => {
    if (!_.isEmpty(regionOptions) && _.isNil(showActiveRegionName)) {
      setShowActiveRegionName(_.get(_.first(regionOptions), 'key'))
    }

    // TODO: move this to an "onReady" hook when graph is done loading.
    enableRegionalOptions()
  }, [regionOptions, showActiveRegionName, enableRegionalOptions])

  const regionId = _.get(regionsMapByName, [showActiveRegionName, 'regionId'])
  useEffect(() => {
    if (
      !_.isEmpty(defaultAsset) &&
      !_.isNil(showActiveRegionName) &&
      !_.isNil(selectedDateTimestamp) &&
      !_.isNil(regionId)
    ) {
      setIsLoading(true)
      getRegionProductIntervalData(defaultAsset, regionId, selectedDateTimestamp).then(() => {
        setIsLoading(false)
      })
    }
  }, [defaultAsset, showActiveRegionName, selectedDateTimestamp, regions.size, regionId, getRegionProductIntervalData])

  const handleChangeDatePicker = date => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')

    setValues({
      ...values,
      startDate: newDate,
    })
  }

  const handleRegionChange = (e, show) => {
    disableRegionalOptions(show)
    if (!_.isNil(show)) {
      setShowActiveRegionName(show)
    }
  }

  const disableRegionalOptions = except => {
    if (!_.isEmpty(regionOptions)) {
      setRegionOptions(prevOptions => {
        const result = prevOptions.map(option => ({
          ...option,
          disabled: option.key !== except,
        }))
        return result
      })
    }
  }

  const subHeader = getCardSubtitleByDate(null, null, currentAsOf, marketTimezone, marketStartHour)

  const minDate = moment(initialSelectedDate).subtract(3, 'days').startOf('day')
  const maxDate = moment(initialSelectedDate)

  const region = _.get(regionsMapByName, [showActiveRegionName])
  const inProgress =
    isLoading ||
    _.isEmpty(defaultAsset) ||
    _.isEmpty(marketTimezone) ||
    _.isNil(showActiveRegionName) ||
    _.isEmpty(regionsMapByName)
  return (
    <Card
      classes={{
        root: classes.root,
        header: classes.cardHeader,
        headerContent: classes.cardHeaderContent,
        title: classes.cardTitle,
        action: classes.cardAction,
      }}
      className={clsx(classes.root, classNameProp)}
      title="NEM Price and Demand"
      titleTypographyProps={{ variant: 'h3' }}
      subheader={subHeader}
      inProgress={inProgress}
      action={
        <Box pt={0.5} pr={1}>
          <Grid container spacing={1} wrap="wrap">
            <Grid item className={classes.datePickerGrid}>
              {!_.isNil(marketTimezone) && !_.isNil(marketStartHour) && (
                <KeyboardDatePicker
                  timezone={marketTimezone}
                  marketStartHour={marketStartHour}
                  id="schedule-modal-start-date"
                  minDate={minDate}
                  maxDate={maxDate}
                  minDateMessage="Cannot select date older than 3 days"
                  selectedDate={values.startDate}
                  onChange={handleChangeDatePicker}
                  ariaLabel="change market prices start date"
                />
              )}
            </Grid>
            <Grid item>
              <Box display="inline-flex">
                <Toggle values={regionOptions} selected={showActiveRegionName} onChange={handleRegionChange} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
    >
      <Box minHeight="726px">
        {!(
          _.isNil(region) ||
          _.isEmpty(defaultAsset) ||
          _.isEmpty(marketTimezone) ||
          _.isNil(showActiveRegionName) ||
          _.isEmpty(regionsMapByName)
        ) && (
          <MarketIntelligenceGraphs
            asset={defaultAsset}
            inProgress={inProgress}
            marketStartHour={marketStartHour}
            region={region}
            timezone={marketTimezone}
            selectedDate={values.startDate}
            onReady={enableRegionalOptions}
          />
        )}
      </Box>
    </Card>
  )
})

MarketIntelligenceCard.displayName = DISPLAY_NAME

MarketIntelligenceCard.propTypes = {
  className: PropTypes.string,
  currentAsOf: PropTypes.object,
  defaultAsset: PropTypes.object,
  getRegionProductIntervalData: PropTypes.func,
  market: PropTypes.object,
  regions: PropTypes.array,
}

function createOrderedRegionList(regions = [], regionOrder) {
  const orderedRegions = _.cloneDeep(regions)
  if (!_.isEmpty(regions) && !_.isEmpty(regionOrder)) {
    orderedRegions.sort((a, b) => {
      const indexOfA = regionOrder.indexOf(_.get(a, 'data.display_name'))
      const indexOfB = regionOrder.indexOf(_.get(b, 'data.display_name'))
      return indexOfA - indexOfB
    })
  }
  return orderedRegions
}

export default MarketIntelligenceCard
