import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles/index'
import { Hidden, MenuItem } from '@material-ui/core'
import Toggle from '../Toggle'
import Select from '../Select'
import { getSortedProductNames, getProductNameWithIcon } from '../../utility/utility'
import { PRODUCT_FCAS, PRODUCT_NAME_ENERGY } from '../../pages/Bids'
import { extractActiveProductNamesFromList } from '../../utility/asset-utils'

const DISPLAY_NAME = 'ProductToggle'

const useStyles = makeStyles(
  theme => ({
    select: {
      maxWidth: 380,
    },
    selectInput: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selectInputBase: {
      maxWidth: 380,
    },
    brightOrange: {
      backgroundColor: theme.custom.palette.colors.BRIGHT_ORANGE,
    },
    darkOrange: {
      backgroundColor: theme.custom.palette.colors.DARK_ORANGE,
    },
    blue: {
      backgroundColor: theme.custom.palette.colors.DARK_CERULEAN,
    },
  }),
  { name: DISPLAY_NAME },
)

const checkDisableExpansionForEmptyFCAS = (productNames, disabledProductNames) => {
  return (
    disabledProductNames.filter(productName => productName !== PRODUCT_NAME_ENERGY).length ===
    productNames.filter(productName => productName !== PRODUCT_NAME_ENERGY).length
  )
}
function ProductToggle(props) {
  const {
    asset,
    selected,
    selectedTime = moment(),
    onChange,
    disabledProductNames: initDisabledProductNames = [],
    onCollapse,
    productNames: initProductNames = [],
    productDisplayNames = {},
    exclusive = true,
    variant = 'primary',
    disabled = false,
    highlightedProductNames = [],
  } = props

  const classes = useStyles()
  const [disabledProductNames, setDisabledProductNames] = useState(initDisabledProductNames)
  const activeProductNames = !_.isNil(asset)
    ? extractActiveProductNamesFromList(asset, selectedTime, initProductNames)
    : initProductNames
  const [productNames, setProductNames] = useState(activeProductNames)
  const [disableExpansionForEmptyFCAS, setDisableExpansionForEmptyFCAS] = useState(() =>
    checkDisableExpansionForEmptyFCAS(activeProductNames, initDisabledProductNames),
  )

  useEffect(() => {
    if (!_.isEqual(activeProductNames, productNames) || !_.isEqual(initDisabledProductNames, disabledProductNames)) {
      setDisabledProductNames(initDisabledProductNames)
      setProductNames(activeProductNames)
      setDisableExpansionForEmptyFCAS(checkDisableExpansionForEmptyFCAS(activeProductNames, initDisabledProductNames))
    }
  }, [activeProductNames, initDisabledProductNames, productNames, disabledProductNames])

  const sortedProductNames = getSortedProductNames(productNames)
  const productOptions = sortedProductNames.map(name => ({
    value: name,
    displayName: productDisplayNames[name],
  }))

  const options = sortedProductNames.map(name => {
    return {
      text: getProductNameWithIcon(name),
      key: name,
      toolTip: `Product: ${productDisplayNames[name]}`,
      disabled: disabledProductNames ? disabledProductNames.includes(name) : false,
    }
  })

  const handleOnChange = (event, productName) => {
    if (!_.isNil(productName)) {
      onChange(event, productName)
    }
  }

  const handleProductChangeWithSelect = event => {
    if (!_.isNil(event.target.value)) {
      onChange(event, event.target.value)
    }
  }

  const handleFcasClick = (event, productName) => {
    if (_.isFunction(onCollapse)) {
      onCollapse(event, productName)
    }
  }
  const hasOrangeVariant = variant === 'orange'
  const shouldHighlight = hasOrangeVariant || highlightedProductNames.includes(selected)
  const collapsedProductDropdownButtonClass = clsx({
    [classes.brightOrange]: shouldHighlight,
    [classes.blue]: !shouldHighlight,
    [classes.selectInputBase]: true,
  })

  return (
    <>
      <Hidden mdUp>
        <Select
          className={collapsedProductDropdownButtonClass}
          classes={{ root: classes.select, select: classes.selectInput }}
          multiple={!exclusive}
          value={selected}
          onChange={handleProductChangeWithSelect}
          shouldHighlight={shouldHighlight}
          disabled={disabled}
        >
          {productOptions.map(opt => {
            const isDisabledItem = disabledProductNames ? disabledProductNames.includes(opt.value) : false
            const expandedProductDropdownColorClass = clsx({
              [classes.brightOrange]: highlightedProductNames.includes(opt.value) && opt.value === selected,
              [classes.darkOrange]:
                (highlightedProductNames.includes(opt.value) || hasOrangeVariant) && opt.value !== selected,
            })
            return (
              <MenuItem
                disabled={isDisabledItem}
                value={opt.value}
                key={opt.value}
                className={expandedProductDropdownColorClass}
              >
                {opt.displayName}
              </MenuItem>
            )
          })}
        </Select>
      </Hidden>
      <Hidden smDown>
        {disableExpansionForEmptyFCAS && (
          <Toggle
            values={[
              {
                text: 'Energy',
                key: PRODUCT_NAME_ENERGY,
                toolTip: 'Energy',
              },
              {
                text: PRODUCT_FCAS,
                key: PRODUCT_FCAS,
                toolTip: PRODUCT_FCAS,
              },
            ]}
            selected={PRODUCT_NAME_ENERGY}
            onChange={handleFcasClick}
            variant={variant}
            disabled={disabled}
            highlightedItems={highlightedProductNames}
            disableFCASExpansion={disableExpansionForEmptyFCAS}
          />
        )}
        {!disableExpansionForEmptyFCAS && (
          <Toggle
            exclusive={exclusive}
            values={options}
            selected={selected}
            onChange={handleOnChange}
            variant={variant}
            disabled={disabled}
            highlightedItems={highlightedProductNames}
          />
        )}
      </Hidden>
    </>
  )
}

ProductToggle.displayName = DISPLAY_NAME

export default ProductToggle
