const types = {
  REGULATION_THROUGHPUT_REQUEST: {
    request: 'REGULATION_THROUGHPUT_REQUEST',
    success: 'REGULATION_THROUGHPUT_SUCCESS',
    error: 'REGULATION_THROUGHPUT_ERROR',
  },
  REGULATION_THROUGHPUT_LATEST_REQUEST: {
    request: 'REGULATION_THROUGHPUT_LATEST_REQUEST',
    success: 'REGULATION_THROUGHPUT_LATEST_SUCCESS',
    error: 'REGULATION_THROUGHPUT_LATEST_ERROR',
  },
  REGULATION_THROUGHPUT_RANGE_REQUEST: {
    request: 'REGULATION_THROUGHPUT_RANGE_REQUEST',
    success: 'REGULATION_THROUGHPUT_RANGE_SUCCESS',
    error: 'REGULATION_THROUGHPUT_RANGE_ERROR',
  },
  REGULATION_THROUGHPUT_CREATE_REQUEST: {
    request: 'REGULATION_THROUGHPUT_CREATE_REQUEST',
    success: 'REGULATION_THROUGHPUT_CREATE_SUCCESS',
    error: 'REGULATION_THROUGHPUT_CREATE_ERROR',
  },
  REGULATION_THROUGHPUT_DELETE_REQUEST: {
    request: 'REGULATION_THROUGHPUT_DELETE_REQUEST',
    success: 'REGULATION_THROUGHPUT_DELETE_SUCCESS',
    error: 'REGULATION_THROUGHPUT_DELETE_ERROR',
  },
}

export default types
