import { createMuiTheme, fade } from '@material-ui/core/styles'
import colors from '../config/colors'

const htmlFontSize = 16
const fontSize = 14
const coef = fontSize / 14
const pxToRem = size => `${(size / htmlFontSize) * coef}rem`

const APP_HEADER_HEIGHT = 64
const ASSET_HEADER_HEIGHT = 48
const NAV_DRAWER_WIDTH = 72

const SELECTED_OPACITY = 0.16

export const custom = {
  palette: {
    action: {
      hover: 'rgba(53, 88, 129, 0.5)',
    },
    background: {
      chrome: {
        primary: colors.NERO,
        secondary: colors.CINDER,
        tertiary: colors.BLACK_PEARL,
      },
      contrastText: '#fff',
      tooltip: colors.TOOLTIP,
    },
    colors,
    graphs: {
      gridColor: '#272e37',
      labelColor: '#808697',
      primaryFontFamily: "'Open Sans', Arial, sans-serif",
      monospaceFontFamily: "'Droid Sans Mono', monospace",
      seriesColors: [
        '#47AEBC',
        '#6476FF',
        '#FF6E6E',
        '#EDB449',
        '#BF5D30',
        '#99AC4E',
        '#355881',
        colors.CORNFLOWER_BLUE,
      ],
      assetOperationsSeriesColors: [
        colors.SKY_BLUE,
        colors.YALE_BLUE,
        colors.FERN_GREEN,
        colors.SKY_MAGENTA,
        colors.MEDIUM_SEA_GREEN,
        colors.DARK_CERULEAN,
        colors.SOFT_RED,
        colors.LAVENDER,
      ],
    },
    table: {
      row: {
        background: {
          secondary: colors.WHITE_LIGHTEN_06,
        },
      },
    },
  },
  overrides: {
    App: {
      paddingLeft: NAV_DRAWER_WIDTH,
    },
    AssetHeader: {
      height: ASSET_HEADER_HEIGHT,
      minHeight: ASSET_HEADER_HEIGHT,
      navItem: {
        width: NAV_DRAWER_WIDTH,
      },
    },
    NavDrawer: {
      width: NAV_DRAWER_WIDTH,
      paddingTop: APP_HEADER_HEIGHT,
    },
    PageHeader: {
      minHeight: APP_HEADER_HEIGHT,
    },
    Notifier: {
      root: {
        fontSize: 14,
      },
      action: {
        icon: {
          fontSize: 24,
        },
        root: {
          padding: 4,
        },
      },
    },
  },
}

/*
 ** MAIN ENTRY POINT FOR THEME **
 */
const createTheme = (type = 'dark') => {
  const typography = {
    pxToRem,
    fontSize,
    htmlFontSize,
    fontFamily: [
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 'bold',
      fontSize: pxToRem(26),
      letterSpacing: '0em',
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 600,
      fontSize: pxToRem(22),
      lineHeight: 1,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontWeight: 600,
      fontSize: pxToRem(18),
      lineHeight: 1.04,
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: 1.17,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: 1.17,
      letterSpacing: '0.00735em',
    },
    h6: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: 1.17,
      letterSpacing: '0.00735em',
    },
    body1: {
      fontWeight: 400,
      fontSize: pxToRem(14),
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontWeight: 600,
      fontSize: pxToRem(14),
      lineHeight: 1.5,
      letterSpacing: '0.01071em',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: pxToRem(12),
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 'bold',
      fontSize: pxToRem(14),
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: pxToRem(10.5),
      lineHeight: 1,
      letterSpacing: '0.00938em',
      textTransform: 'uppercase',
    },
  }

  const theme = createMuiTheme({
    palette: {
      type,
      primary: {
        main: colors.CORNFLOWER_BLUE,
        contrastText: colors.GUNMETAL,
      },
      secondary: {
        main: colors.CALIFORNIA,
        contrastText: colors.GUNMETAL,
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(255, 255, 255, 0.7)',
        tertiary: 'rgba(255, 255, 255, 0.5)',
        disabled: 'rgba(255, 255, 255, 0.5)',
      },
      background: {
        default: colors.GUNMETAL,
        dark: colors.GUNMETAL,
        paper: colors.CINDER,
      },
      action: {
        hover: colors.LIGHT_CERULEAN,
        selected: colors.DARK_CERULEAN,
        selectedOpacity: SELECTED_OPACITY,
      },
      error: {
        main: colors.BITTERSWEET,
        contrastText: colors.CINDER,
      },
    },
    typography,
    props: {
      MuiCheckbox: {
        color: 'primary',
      },
      MuiTooltip: {
        arrow: true,
      },
    },
    overrides: {
      MuiAccordion: {
        root: {
          '&$expanded': {
            margin: 0,
            paddingBottom: pxToRem(8),
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&$expanded': {
            minHeight: pxToRem(40),
          },
        },
        content: {
          '&$expanded': {
            margin: 0,
          },
        },
      },
      MuiCardContent: {
        root: {
          '&:last-child': {
            paddingBottom: pxToRem(16),
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: pxToRem(8),
        },
        sizeSmall: {
          padding: pxToRem(4),
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '1rem',
          fontWeight: 600,
        },
      },
      MuiPickersBasePicker: {
        container: {
          backgroundColor: colors.RHINO,
        },
      },
      MuiPickersMonth: {
        monthSelected: {
          textTransform: 'uppercase',
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: colors.RHINO,
        },
      },
      MuiTableRow: {
        root: {
          '&$selected, &$selected:hover': {
            backgroundColor: fade(colors.CORNFLOWER_BLUE, SELECTED_OPACITY),
          },
        },
      },
      MuiTableCell: {
        sizeSmall: {
          paddingTop: pxToRem(6),
          paddingRight: pxToRem(12),
          paddingBottom: pxToRem(6),
          paddingLeft: pxToRem(8),
        },
        head: {
          fontSize: '0.75rem',
          color: 'rgba(255, 255, 255, 0.7)',
        },
        body: {
          fontWeight: 400,
        },
      },
      MuiToggleButton: {
        root: {
          backgroundColor: colors.RHINO,
          border: 0,
          height: 32,
          borderLeft: '1px solid #30353c',
          textTransform: 'none',
          '&$selected': {
            backgroundColor: 'rgba(53,88,129,1)',
            '&.MuiToggleButton-root.Mui-selected:hover': {
              backgroundColor: 'rgba(53,88,129,1)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(53,88,129,0.2)',
          },
        },
        sizeSmall: {
          padding: '0 11px',
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: pxToRem(14),
        },
        popper: {
          '& .MuiBox-root': {
            ...typography.body1,
            color: colors.white,
            lineHeight: '1.6rem',
          },
          '& li': {
            ...typography.body1,
            color: colors.white,
            lineHeight: '1.6rem',
          },
        },
      },
      MuiSelect: {
        root: {
          fontWeight: 600,
        },
      },
    },
    custom,
  })

  if (process.env.NODE_ENV === 'development' && process.browser) {
    console.info(
      `%c Developer note: you can access the \`theme\` object directly in the console.`,
      'font-family:monospace;color:#1976d2;font-size:12px;',
    )
  }
  if (process.browser) {
    window.theme = theme
  }
  return theme
}

export default createTheme
