import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import KeyboardDatePicker from './KeyboardDatePicker'

const DISPLAY_NAME = 'DateRangePicker'

const useStyles = makeStyles(
  theme => ({
    root: {},
    datePicker: {
      marginBottom: 0,
    },
    pillContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '0 11px 0 12px',
      height: 32,
      borderRadius: '15px 0 0 15px',
      borderTopRightRadius: '0 0',
      borderBottomRightRadius: '0 0',
      backgroundColor: '#434C57',
    },
  }),
  { name: DISPLAY_NAME },
)

const DateRangePicker = React.forwardRef(function DateRangePicker(props, ref) {
  const {
    className: classNameProp,
    timezone,
    marketStartHour,
    startMaxDate: startMaxDateProp,
    startDate,
    endDate,
    endMaxDate: endMaxDateProp,
    onStartDateChange,
    onEndDateChange,
  } = props
  const classes = useStyles(props)

  moment.tz.setDefault(timezone)

  const endDateMarketDay = moment(endDate).tz(timezone).subtract(1, 'days')

  // TODO: update these to reflect market day
  const startMaxDate = moment(startMaxDateProp) || moment().tz(timezone)
  const endMaxDate = moment(endMaxDateProp) || moment().add(1, 'day')

  return (
    <Box className={classNameProp} display="flex" flexWrap="wrap" maxHeight={48}>
      <Box display="flex" flexWrap="nowrap" alignItems="center" ml={0.5}>
        <Box className={classes.pillContainer}>
          <Typography component="span" variant="body2">
            From
          </Typography>
        </Box>
        <KeyboardDatePicker
          className={classes.datePicker}
          timezone={timezone}
          marketStartHour={marketStartHour}
          id="date-range-picker-start-date"
          inputVariant="standard"
          ariaLabel="change start date"
          maxDate={startMaxDate}
          selectedDate={startDate}
          onChange={onStartDateChange}
        />
      </Box>
      <Box display="flex" flexWrap="nowrap" alignItems="center" ml={1}>
        <Box className={classes.pillContainer}>
          <Typography component="span" variant="body2">
            To{' '}
          </Typography>
        </Box>
        <KeyboardDatePicker
          className={classes.datePicker}
          timezone={timezone}
          marketStartHour={marketStartHour}
          id="date-range-picker-end-date"
          inputVariant="standard"
          ariaLabel="change start date"
          maxDate={endMaxDate}
          selectedDate={endDateMarketDay}
          onChange={onEndDateChange}
        />
      </Box>
    </Box>
  )
})

DateRangePicker.displayName = DISPLAY_NAME

DateRangePicker.propTypes = {
  className: PropTypes.string,
}

export default DateRangePicker
