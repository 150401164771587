import { combineReducers } from 'redux'
import { ResourceAction } from '../api'
import { intervalStateCreator, getIntervalsNew } from './interval'

const assetProductAction = new ResourceAction({
  type: 'GROUP_COLLATED_PRODUCT_ASSET_INTERVAL',
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + '&productId=' + productId, '')
    return {
      endpoint: `/market/frontend/asset/${
        intervalConfig.assetId
      }/products/interval/collated?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&tag=${
        intervalConfig.tag
      }${productsQuery}&forecastStartTime=${encodeURIComponent(intervalTimeConfig.forecastStartTime.toISOString())}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

const productRegionAction = new ResourceAction({
  type: 'GROUP_COLLATED_REGION_PRODUCT_INTERVAL',
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + '&productId=' + productId, '')
    return {
      endpoint: `/market/frontend/products/region/${
        intervalConfig.regionId
      }/interval/collated?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&regionCollationType=${
        intervalConfig.tag
      }${productsQuery}&forecastStartTime=${encodeURIComponent(intervalTimeConfig.forecastStartTime.toISOString())}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

const productAssetMixedRegionCollationAction = new ResourceAction({
  type: 'GROUP_MIXED_COLLATED_ASSET_PRODUCT_INTERVAL',
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + '&productId=' + productId, '')
    return {
      endpoint: `/market/frontend/asset/${
        intervalConfig.assetId
      }/regionCollation/mixForecastTypes/products/interval/collated?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&primaryRegionCollationType=${
        intervalConfig.tag1
      }&backupRegionCollationType=${intervalConfig.tag1}${productsQuery}&forecastStartTime=${encodeURIComponent(
        intervalTimeConfig.forecastStartTime.toISOString(),
      )}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

export const assetIntervalAction = new ResourceAction({
  type: 'GET_COLLATED_ASSET_INTERVAL',
  config: (intervalConfig, intervalTimeConfig) => {
    return {
      endpoint: `/market/frontend/asset/${intervalConfig.assetId}/interval/collated?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&tag=${
        intervalConfig.tag
      }&forecastStartTime=${encodeURIComponent(intervalTimeConfig.forecastStartTime.toISOString())}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

export const getContractIntervals = contractType =>
  new ResourceAction({
    type: `GET_CONTRACT_${contractType.toUpperCase()}_INTERVAL`,
    config: (intervalConfig, intervalTimeConfig) => {
      return {
        endpoint: `/market/contracts/asset/${intervalConfig.assetId}/activeInRange?startTime=${encodeURIComponent(
          intervalTimeConfig.startTime.toISOString(),
        )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&contractType=${contractType}`,
        meta: {
          intervalConfig,
          intervalTimeConfig,
        },
      }
    },
  })

export const warrantyIntervalsResource = getContractIntervals('warranty')

export const getCollatedAssetIntervals = getIntervalsNew(assetIntervalAction, 'collated')
export const getCollatedAssetProductIntervals = getIntervalsNew(assetProductAction, 'collated')
export const getCollatedRegionProductIntervals = getIntervalsNew(productRegionAction, 'collated')
export const getWarrantyConstraintIntervals = getIntervalsNew(warrantyIntervalsResource, 'collated')
export const getMixedProductAssetRegionCollationIntervals = getIntervalsNew(
  productAssetMixedRegionCollationAction,
  'collated',
)

const actions = [
  assetProductAction,
  productRegionAction,
  assetIntervalAction,
  warrantyIntervalsResource,
  productAssetMixedRegionCollationAction,
]
const intervals = intervalStateCreator(actions)

export default combineReducers({
  intervals,
})
