import React, { useCallback, useRef, useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardContent as MuiCardContent,
  Tooltip,
  Typography,
} from '@material-ui/core'
import LinearProgress from './LinearProgress'

const DISPLAY_NAME = 'MarketCard'

const useStyles = makeStyles(
  theme => ({
    root: {
      borderRadius: 0,
      minHeight: 82,
    },
    header: {
      padding: theme.spacing(0, 2),
      borderBottom: `2px solid ${theme.palette.background.default}`,
      alignItems: 'flex-end',
    },
    headerContent: {
      minWidth: 0,
      padding: theme.spacing(2, 0, 1, 0),
    },
    title: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(18),
    },
    subheader: {
      paddingRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      color: 'rgba(255,255,255,0.7)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    content: {
      padding: props => ((props as any).isFullBleed ? theme.spacing(2, 0) : theme.spacing(2)),
      paddingTop: 0,
      paddingBottom: 80,
      overflowY: 'auto',
      maxHeight: 'max-content',
    },
    action: {
      alignSelf: 'center',
    },
  }),
  { name: DISPLAY_NAME },
)

interface CardProps {
  action?: React.ReactNode
  avatar?: React.ReactNode
  children?: React.ReactNode
  classes?: object
  className?: string
  disableTypography?: boolean
  forceHeader?: boolean
  headerClassName?: string
  inProgress?: boolean
  tabIndex?: number
  title?: React.ReactNode
  titleTypographyProps?: object
  subheader?: string | React.ReactNode
  subheaderTypographyProps?: object
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
  const classes = useStyles(props)
  const {
    action,
    avatar,
    children,
    className: classNameProp,
    disableTypography = false,
    forceHeader,
    headerClassName,
    inProgress = false,
    tabIndex = -1,
    title,
    titleTypographyProps,
    subheader: subheaderProp,
    subheaderTypographyProps,
  } = props
  const [isOverflow, subRef] = useIsBoxOverflow()

  const headerHasContent = [title, subheaderProp, action, headerClassName].filter(str => str !== undefined).length > 0
  const showHeader = forceHeader === true || headerHasContent

  let subheader = subheaderProp
  if (subheader != null && (subheader as any)?.type !== Typography && !disableTypography) {
    const tooltipTitle = isOverflow ? subheader : ''
    subheader = (
      <Tooltip title={tooltipTitle}>
        <Typography
          className={classes.subheader}
          color="textSecondary"
          component="span"
          display="block"
          variant={avatar ? 'body2' : 'body1'}
          ref={subRef}
          {...subheaderTypographyProps}
        >
          {subheader}
        </Typography>
      </Tooltip>
    )
  }
  return (
    <MuiCard ref={ref} className={classNameProp} classes={{ root: classes.root }} tabIndex={tabIndex}>
      {showHeader && (
        <MuiCardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={titleTypographyProps}
          classes={{
            root: classes.header,
            content: classes.headerContent,
            title: classes.title,
            action: classes.action,
          }}
          action={action}
        />
      )}
      <Box position="relative" maxHeight={0} width="100%">
        <Box position="absolute" top={-2} width="100%" minHeight={2} zIndex="100">
          {inProgress && (
            <Box width="100%">
              <LinearProgress data-testid="market-card-progress-bar" />
            </Box>
          )}
        </Box>
      </Box>
      <MuiCardContent classes={{ root: classes.content }}>{children}</MuiCardContent>
    </MuiCard>
  )
})

Card.displayName = DISPLAY_NAME

export default Card

function useIsBoxOverflow(): [boolean, (node: HTMLSpanElement | null) => void] {
  const [isOverflow, setIsOverflow] = useState(false)
  const resizeObserverRef = useRef(
    new ResizeObserver(entries => {
      const offsetWidth = _.get(entries, [0, 'target', 'offsetWidth'])
      const scrollWidth = _.get(entries, [0, 'target', 'scrollWidth'])
      const scrollIsBigger = scrollWidth > offsetWidth
      setIsOverflow(scrollIsBigger)
    }),
  )
  const ref = useCallback((node: HTMLSpanElement | null) => {
    if (node !== null) {
      resizeObserverRef.current.observe(node)
    }
  }, [])
  return [isOverflow, ref]
}
