import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItem,
  ListItemText,
  Menu,
  Toolbar,
  IconButton,
  AppBar,
  Button,
  Modal,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import { MaterialLogo } from '@fluence/core'
import '@fluence/core/build/core-fonts.css'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import auth from '../../utility/auth'
import ROUTE_PATHS from '../../config/routes'
import NavLinkRef from '../NavLinkRef'
import AcceptTCModal from '../AcceptTCModal'
import { WEB_API_ROOT } from '../../utility/constants'
import { userCanAdminister } from '../userManagement/userManagementUtility'
import { hasApiAccess } from '../../utility/user-utils'

const useStyles = makeStyles(theme => ({
  appBar: {
    overflow: 'hidden',
    backgroundColor: theme.custom.palette.background.chrome.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: theme.custom.overrides.PageHeader.minHeight,
    whiteSpace: 'nowrap',
  },
  logo: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
    },
  },
  title: {
    display: 'flex',
    flex: '1 1 auto',
  },
  assetInfoText: {
    display: 'flex',
    flex: '1 0 auto',
    fontFamily: 'Roboto',
    alignItems: 'center',
    cursor: 'default',
  },
  assetInfoButton: {
    '&:disabled': {
      color: 'white',
      border: '1px solid rgba(255, 255, 255, 0.3)',
    },
  },
  accountSettings: {
    display: 'flex',
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  userAvatar: {
    width: '1.8rem',
    height: '1.8rem',
    backgroundColor: 'white',
  },
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 608,
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
  },
  preDiv: {
    display: 'flex',
    flex: '1 0 auto',
    margin: theme.spacing(1, 1.5, 0, 3),
  },
  preToken: {
    overflow: 'auto',
    paddingBottom: theme.spacing(1.5),
    fontSize: '1rem',
    margin: theme.spacing(0, 1.5, 0, 0),
  },
  copyButton: {
    height: 44,
    bottom: theme.spacing(1.5),
  },
}))

const ICON_TEXT = 'Copy to clipboard'

function PageHeader(props) {
  const { assets, displayIsLiveView, user, toggleIsLive, isAgreed } = props
  const classes = useStyles(props)

  const [userOpen, setUserOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)
  const [aboutModalOpen, setAboutModalOpen] = useState(false)

  const [apiAuthModalOpen, setApiAuthModalOpen] = useState(false)
  const [token, setToken] = useState('')

  const handleUserClick = event => {
    setAnchorEl(!userOpen ? event.currentTarget : null)
    setUserOpen(!userOpen)
  }

  const handleLogout = () => {
    auth.deleteAuthToken()
    setRedirectToLogin(true)
  }

  const handleTC = () => {
    if (isAgreed) {
      setAnchorEl(null)
      setUserOpen(false)
      setModalOpen(true)
    }
  }

  const handleAboutModalOpen = () => {
    if (isAgreed) {
      setAnchorEl(null)
      setUserOpen(false)
      setAboutModalOpen(true)
    }
  }
  const handleAboutModalClose = () => {
    setAboutModalOpen(false)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleApiAuthModalToggle = () => {
    setApiAuthModalOpen(prev => {
      if (!prev) {
        setToken(auth.getToken())
      }
      return !prev
    })
  }

  const handleAPIClick = () => {
    const link = `${WEB_API_ROOT}/markets/swagger`
    window.open(link)
  }

  const menuId = 'primary-account-menu'
  const userName = user.isError ? '' : _.get(user, 'name', 'User').substr(0, 1).toUpperCase()
  const canAdministerUsers = userCanAdminister(user)

  const assetInfoText = displayIsLiveView ? 'Live Assets' : 'Simulated Assets'

  const liveAssetSize = !_.isNil(assets) && _.size(assets.filter(asset => _.get(asset, 'data.is_live') === true))
  const simulatedAssetSize = !_.isNil(assets) && _.size(assets) - liveAssetSize

  const { REACT_APP_GIT_COMMIT: commit = '', REACT_APP_VERSION: version = '' } = window
  const hasCommitMsg = _.isString(commit) && commit.length > 0 && commit !== 'None' && commit !== '-'
  const gitCommitMsg = hasCommitMsg && `(build ${_.slice(commit.split('_'), -2).join('-')})`
  const versionMsg = `Version ${version} ${hasCommitMsg ? gitCommitMsg : ''}`

  const canAccessAPI = hasApiAccess(user.permissions)
  return (
    <>
      {redirectToLogin && <Redirect push to={ROUTE_PATHS.LOGIN} />}
      <AppBar position="static" className={classes.appBar}>
        <Toolbar disableGutters className={classes.toolbar}>
          <div className={classes.title}>
            <MaterialLogo
              variant="light"
              className={classes.logo}
              component="span"
              titleVariant="h1"
              title="NEM Bidding Application"
              buttonStyles={{ paddingLeft: 0 }}
              buttonProps={{ href: '/markets' }}
            />
            <Box className={classes.assetInfoText}>
              {typeof displayIsLiveView === 'boolean' && (
                <Button variant="outlined" disabled className={classes.assetInfoButton}>
                  {assetInfoText}
                </Button>
              )}
            </Box>
          </div>
          <div className={classes.accountSettings}>
            <IconButton
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleUserClick}
              color="inherit"
            >
              <Avatar className={classes.userAvatar}>{userName}</Avatar>
            </IconButton>
          </div>
        </Toolbar>
        {!!anchorEl && (
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            open={userOpen}
            onClose={handleUserClick}
          >
            {isAgreed && (
              <ListItem
                selected={false}
                button
                component={NavLinkRef}
                to={ROUTE_PATHS.PREFERENCES}
                onClick={handleUserClick}
              >
                <ListItemText primary="Preferences" />
              </ListItem>
            )}
            {isAgreed && canAdministerUsers && (
              <ListItem
                selected={false}
                button
                component={NavLinkRef}
                to={ROUTE_PATHS.USER_MANAGEMENT}
                onClick={handleUserClick}
              >
                <ListItemText primary="User Management" />
              </ListItem>
            )}
            {canAccessAPI && (
              <ListItem button onClick={handleAPIClick}>
                <ListItemText primary="Explore API" />
              </ListItem>
            )}
            {canAccessAPI && (
              <ListItem button onClick={handleApiAuthModalToggle}>
                <ListItemText primary="API Authorization" />
              </ListItem>
            )}
            <ListItem selected={false} button onClick={handleLogout}>
              <ListItemText primary="Log out" />
            </ListItem>

            {isAgreed && <Divider />}
            {isAgreed && (
              <ListItem
                disabled={liveAssetSize === 0}
                selected={displayIsLiveView}
                button
                component={NavLinkRef}
                to={ROUTE_PATHS.DASHBOARD}
                onClick={() => {
                  toggleIsLive(true)
                  setUserOpen(!userOpen)
                }}
              >
                <ListItemText primary="Live Assets" />
              </ListItem>
            )}
            {isAgreed && (
              <ListItem
                disabled={simulatedAssetSize === 0}
                selected={!displayIsLiveView}
                button
                component={NavLinkRef}
                to={ROUTE_PATHS.DASHBOARD}
                onClick={() => {
                  toggleIsLive(false)
                  setUserOpen(!userOpen)
                }}
              >
                <ListItemText primary="Simulated Assets" />
              </ListItem>
            )}

            <ListItem selected={false} button onClick={handleTC}>
              <ListItemText primary="Terms & Conditions" />
            </ListItem>
            <ListItem selected={false} button onClick={handleAboutModalOpen}>
              <ListItemText primary="About" />
            </ListItem>
          </Menu>
        )}
        <Modal open={modalOpen}>
          <Box tabIndex="-1">
            <AcceptTCModal className={classes.root} requiresAgreement={false} closeModal={closeModal} />
          </Box>
        </Modal>
        <Dialog
          open={apiAuthModalOpen}
          onClose={handleApiAuthModalToggle}
          TransitionProps={{
            onExited: () => {
              setToken('')
            },
          }}
          maxWidth="sm"
        >
          <DialogTitle>API Authorization</DialogTitle>
          <div className={classes.preDiv}>
            <pre className={classes.preToken}>
              <code>{token}</code>
            </pre>
            <IconButton
              className={classes.copyButton}
              onClick={() => {
                navigator.clipboard.writeText(token)
              }}
            >
              <Tooltip title={ICON_TEXT} placement="top-end">
                <FileCopyIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        </Dialog>
        <Dialog open={aboutModalOpen} onClose={handleAboutModalClose} maxWidth="sm">
          <DialogTitle>NEM Bidding Application</DialogTitle>
          <DialogContent>
            <DialogContentText>{versionMsg}</DialogContentText>
          </DialogContent>
        </Dialog>
      </AppBar>
    </>
  )
}

PageHeader.propTypes = {
  user: PropTypes.object.isRequired,
}

export default PageHeader
