import _ from 'lodash'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { apiMiddleware } from 'redux-api-middleware'
import rootReducer from './features'
import { cachingMiddleware } from './cachingMiddleware'
import { loadState, saveState } from './localStorage'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadState()

const initStore = () => {
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, apiMiddleware, cachingMiddleware)),
  )

  // Persist state hand picked state properties to local storage
  // TODO: tie into hot reload
  let currentValue
  const handleStateChange = _.debounce(() => {
    const previousValue = currentValue
    currentValue = store.getState().dashboard.view

    if (!_.isEqual(previousValue, currentValue)) {
      saveState({
        dashboard: {
          view: currentValue,
        },
      })
    }
  }, 1000)

  store.subscribe(handleStateChange)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./features', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

let store = null

const getStore = () => {
  if (_.isNil(store)) {
    store = initStore()
  }
  return store
}

export default getStore
