import PropTypes from 'prop-types'

const MarketContracts = {
  getLastContracts: {
    name: 'getLastContracts',
    method: 'GET',
    url: ({ assetId }) => `/market/contracts/asset/${assetId}/last`,
    pathParams: { assetId: PropTypes.number },
    queryParams: {
      'startTime': PropTypes.string.isRequired,
      'contractType': PropTypes.oneOf(['warranty', 'ppa_swap']),
      'asOf': PropTypes.string,
      'deleted': PropTypes.bool,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketContracts
