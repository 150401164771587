import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import Routes from './Routes'
import getStore from './redux/store'

// import reportWebVitals from './reportWebVitals'

const rootEl = document.getElementById('root')
export const store = getStore()

ReactDOM.render(
  <ReduxProvider store={store}>
    <Routes />
  </ReduxProvider>,
  rootEl,
)

if (module.hot) {
  module.hot.accept('./Routes', () => {
    ReactDOM.render(
      <ReduxProvider store={store}>
        <Routes />
      </ReduxProvider>,
      rootEl,
    )
  })
}
