// NOTE: This is a legacy file that we should be looking to migrate to the new graph patterns.
import _ from 'lodash'
import moment from 'moment-timezone'
import { colors, timeFormat } from './utility'
import { getTickInterval } from './syncHandler'
import { stripLineTemplate, tooltipContentFormatter } from './graphTemplate'

export const getTimeSeriesGraph = (
  graphTemplate,
  key,
  lineTitles,
  intervals,
  timezone,
  dataIntervalSeconds,
  extras,
) => {
  graphTemplate.key = key
  graphTemplate.options.toolTip.contentFormatter = tooltipContentFormatter(graphTemplate.name, timezone)
  graphTemplate.options.axisX.labelFormatter = e => moment.unix(e.value).tz(timezone).format(timeFormat)

  const extendLineIndexes = _.get(extras, 'extendLineIndexes', [])
  const latestVals = _.get(extras, 'yesterdayLastVal', [])
  lineTitles.forEach((lineTitle, index) => {
    let latestVal = _.get(latestVals, index)
    // add dataInterval to make time Interval-ending time AMS-5806
    let extendToFuture = false
    const startTime = moment(intervals.startTime).tz(timezone).add(dataIntervalSeconds, 's')
    const values =
      _.get(intervals, 'values', []).length && intervals.startTime ? _.get(intervals, ['values', index], []) : []
    if (extendLineIndexes.includes(index)) {
      extendToFuture = true
    }
    const data = values.map((value, index) => {
      let y = null
      if (!_.isNil(value) && typeof value === 'number') {
        y = value * graphTemplate.seriesOption.factor
        latestVal = y
      } else if (!_.isNil(value) && typeof value === 'object') {
        y = value.map(val => (_.isNil(val) ? null : val * graphTemplate.seriesOption.factor))
        latestVal = y
      } else if (
        extendToFuture &&
        moment(startTime)
          .add(index * dataIntervalSeconds, 's')
          .isAfter(moment())
      ) {
        y = latestVal
      }

      return {
        x: moment(startTime)
          .add(index * dataIntervalSeconds, 's')
          .unix(),
        y,
      }
    })

    if (_.isFunction(extras.saveLatestValsFn)) {
      extras.saveLatestValsFn(latestVal, index)
    }
    const options = Object.assign({}, graphTemplate.seriesOption, {
      dataPoints: data,
      name: lineTitle,
      fillOpacity: graphTemplate.seriesOption.fillOpacity || 0.3,
      color: (extras || {}).colors ? extras.colors[index] : graphTemplate.seriesOption.color || colors[index],
    })
    graphTemplate.series.push({ options })
  })

  return graphTemplate
}

// ensures selected graph and zoom position remain the same
export const updateGraph = (graph, graphMetadata, viewPort, syncHandler, timezone) => {
  const outputGraph = _.cloneDeep(graph)
  if (!_.isEmpty(graphMetadata)) {
    graphMetadata.series.forEach(series => {
      const matchingSeries = outputGraph.series.find(graphSeries => graphSeries.options.name === series.name)
      if (!_.isNil(matchingSeries)) {
        matchingSeries.options.visible = series.visible
      }
    })
  }
  outputGraph.options.axisX.viewportMinimum = viewPort.xMin
  outputGraph.options.axisX.viewportMaximum = viewPort.xMax

  if (!_.isEmpty(outputGraph.options.axisX2)) {
    outputGraph.options.axisX2.viewportMinimum = viewPort.xMin
    outputGraph.options.axisX2.viewportMaximum = viewPort.xMax
  }

  if (viewPort.wasXYZoom) {
    outputGraph.options.axisY.viewportMinimum = viewPort.yMin
    outputGraph.options.axisY.viewportMaximum = viewPort.yMax
  }

  if (viewPort.wasZoomed) {
    const breakpoint = syncHandler.getViewportBreakpoint(viewPort.xMin, viewPort.xMax)
    outputGraph.options.axisX.interval = breakpoint.tickInterval
    outputGraph.options.axisX.labelFormatter = e => breakpoint.labelFormatter(e, timezone)
    if (!_.isEmpty(outputGraph.options.axisX2)) {
      outputGraph.options.axisX2.interval = breakpoint.tickInterval
    }
  }

  // set y min and max
  // have a graph with both stacked and line and we need to set min, max and tick interval
  const isStackedGraph = outputGraph.series.some(series => series.options.type.toLowerCase().includes('stacked'))
  const isLineGraph = outputGraph.series.some(series => series.options.type.toLowerCase().includes('line'))
  const isRangeGraph = outputGraph.series.some(series => series.options.type.toLowerCase().includes('range'))
  const is100Percent = outputGraph.series.some(series => series.options.type.toLowerCase().includes('100'))

  let axisY = {}
  if (isStackedGraph && !isLineGraph && !isRangeGraph && !is100Percent) {
    axisY = getStackedYMinAndMax(outputGraph)
  } else if (!isStackedGraph && isLineGraph && !isRangeGraph && !is100Percent) {
    axisY = getLineYMinAndMax(outputGraph)
  } else if (!isStackedGraph && !isLineGraph && isRangeGraph && !is100Percent) {
    axisY = getRangeChartYMinAndMax(outputGraph)
  } else if ((isStackedGraph || isLineGraph || isRangeGraph) && !is100Percent) {
    const stackedAxisY = getStackedYMinAndMax(outputGraph)
    const lineAxisY = getLineYMinAndMax(outputGraph)
    const rangeAxisY = getRangeChartYMinAndMax(outputGraph)
    const max = Math.max(stackedAxisY.maximum || 0, lineAxisY.maximum || 0, rangeAxisY.maximum || 0)
    const min = Math.min(stackedAxisY.minimum || 0, lineAxisY.minimum || 0, rangeAxisY.minimum || 0)
    const tickInterval = getTickInterval(max - min)
    if (tickInterval !== undefined) {
      axisY.interval = tickInterval
      axisY.maximum = Math.ceil(max / tickInterval) * tickInterval
      axisY.minimum = Math.min(0, Math.floor(min / tickInterval) * tickInterval)
    }
  } else if (is100Percent) {
    axisY.interval = 10
    axisY.labelFormatter = e => e.value + '%'
  }

  outputGraph.options.axisY = Object.assign({}, outputGraph.options.axisY, axisY)

  return outputGraph
}

// ensures selected graph and zoom position remain the same
export const updateLegend = (legend, currentLegend) => {
  const outputLegend = _.cloneDeep(legend)
  if (!_.isEmpty(currentLegend)) {
    currentLegend.series.forEach(series => {
      const matchingSeries = outputLegend.series.filter(
        legend => legend.name === series.name || legend.name.split(' ')[0] === series.name,
      )
      if (!_.isNil(matchingSeries)) {
        matchingSeries.forEach(match => (match.visible = series.visible))
      }
    })
  }

  return outputLegend
}

export const getGraphMetaData = graph => {
  return {
    name: graph.name,
    series: graph.series.map(series => ({
      name: series.options.name,
      canToggle: series.options.canToggle,
      color: series.options.color,
      legend: series.options.legend,
      visible: series.options.visible,
    })),
  }
}

export const graphMetaDataFromTemplates = graphs => {
  const present = {}
  const metaData = { series: [] }
  graphs.forEach(graph => {
    graph.series.forEach(series => {
      if (!present.hasOwnProperty(series.options.name)) {
        metaData.series.push({
          name: series.options.name,
          canToggle: series.options.canToggle,
          color: series.options.color,
          legend: series.options.legend,
          visible: series.options.visible,
        })
        present[series.options.name] = 1
      }
    })
  })

  return metaData
}

export const getForecastStripLineData = (startTime, endTime, forecastStart, actualLabel, forecastLabel) => {
  const stripLines = [
    {
      ...stripLineTemplate,
      startValue: startTime.unix(),
      endValue: forecastStart.unix(),
      color: 'transparent',
      opacity: 0.4,
      label: actualLabel || 'Actual',
      showOnTop: true,
      labelMaxWidth: 300,
    },
    {
      ...stripLineTemplate,
      startValue: forecastStart.unix(),
      endValue: endTime.unix(),
      color: '#fdfdfd',
      label: forecastStart.isSame(endTime) ? '' : forecastLabel || 'Forecast',
      opacity: 0.04,
      showOnTop: true,
      labelMaxWidth: 300,
    },
  ]

  const series = {
    options: {
      type: 'line',
      name: 'stripLine',
      visible: false,
      axisXType: 'secondary',
      axisYType: 'secondary',
    },
  }

  return {
    stripLines,
    series,
  }
}

export const getLineYMinAndMax = graph => {
  const series = graph.series.filter(series => series.options.type.toLowerCase().includes('line'))
  const seriesMin = series
    .map(series => _.minBy(series.options.dataPoints, dataPoint => dataPoint.y))
    .filter(dataPoint => !_.isNil(dataPoint))
  const seriesMax = series
    .map(series => _.maxBy(series.options.dataPoints, dataPoint => dataPoint.y))
    .filter(dataPoint => !_.isNil(dataPoint))
  const axisY = {}

  if (seriesMin.length > 0 && seriesMax.length > 0) {
    const actualMin = _.minBy(seriesMin, data => data.y).y
    const min = actualMin > -0.01 ? 0 : actualMin
    const max = Math.max(2, _.maxBy(seriesMax, data => data.y).y)
    const tickInterval = getTickInterval(max - min)
    if (tickInterval !== undefined && min !== max) {
      axisY.interval = tickInterval
      axisY.maximum = Math.ceil(max / tickInterval) * tickInterval
      axisY.minimum = Math.min(0, Math.floor(min / tickInterval) * tickInterval)
    }

    if (graph.name === 'Market Demand' && axisY.interval) {
      axisY.minimum = actualMin - (actualMin % axisY.interval)
    }
  }
  return axisY
}

export const getRangeChartYMinAndMax = graph => {
  const series = graph.series.filter(series => series.options.type.toLowerCase().includes('range'))
  const seriesMin = series
    .map(series => _.minBy(series.options.dataPoints, dataPoint => dataPoint.y[0]))
    .filter(dataPoint => !_.isNil(dataPoint))
  const seriesMax = series
    .map(series => _.maxBy(series.options.dataPoints, dataPoint => dataPoint.y[1]))
    .filter(dataPoint => !_.isNil(dataPoint))
  const axisY = {}

  if (seriesMin.length > 0 && seriesMax.length > 0) {
    const actualMin = _.minBy(seriesMin, data => data.y[0]).y
    const min = actualMin[0] > -0.01 ? 0 : actualMin[0]
    const max = Math.max(2, _.maxBy(seriesMax, data => data.y[1]).y[1])
    const tickInterval = getTickInterval(max - min)
    if (tickInterval !== undefined && min !== max) {
      axisY.interval = tickInterval
      axisY.maximum = Math.ceil(max / tickInterval) * tickInterval
      axisY.minimum = Math.min(0, Math.floor(min / tickInterval) * tickInterval)
    }
  }
  return axisY
}

export const getStackedYMinAndMax = graph => {
  const axisY = {}
  const negativeData = []
  const positiveData = graph.series[0].options.dataPoints.map((val, index) => {
    let positiveSums = 0
    let negativeSums = 0
    graph.series.forEach(series => {
      if (series.options.type.toLowerCase().includes('stacked')) {
        const value = series.options.dataPoints[index].y || 0
        if (value < 0) {
          negativeSums += value
        } else {
          positiveSums += value
        }
      }
    })
    negativeData.push(negativeSums)
    return positiveSums
  })
  const min = _.min(negativeData) || 0
  const max = Math.max(2, _.max(positiveData) || 0)
  const tickInterval = getTickInterval(max - min)

  if (tickInterval !== undefined && min !== max) {
    axisY.interval = tickInterval
    axisY.maximum = Math.ceil(max / tickInterval) * tickInterval
    axisY.minimum = Math.min(0, Math.floor(min / tickInterval) * tickInterval)
  }
  return axisY
}
