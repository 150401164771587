import React, { useState } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core'
// @ts-ignore
import { Button, XCircle } from '@fluence/core'
import { formatCalendarDateAsTradingDay } from '../../utility/utility'
import Card from '../Card'
import { DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import { Variant } from '../../typings/mui'
import HelperTextModal from '../Bid/HelperTextModal'

const DISPLAY_NAME = 'ChangesCard'

const useStyles = makeStyles(
  theme => ({
    card: {
      background: _.get(theme, 'palette.background.paper', '#1D2126'),
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    cardHeader: {
      borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    },
    slider: {
      color: '#B0BEC4',
    },
    activeGrid: {
      alignItems: 'flex-end',
      display: 'flex',
    },
    activeBoxLarger: {
      display: 'flex',
      flexBasis: '100%',
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(1),
    },
    activeDotBox: {
      width: theme.spacing(2),
      alignSelf: 'center',
      display: 'flex',
    },
    activeItem: {
      padding: theme.spacing(1, 2),
    },
    activeDate: {
      fontSize: 13,
    },
    timeAndDateBox: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    activeDateContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    activeDot: {
      color: theme.palette.primary.main,
      position: 'relative',
      fontSize: 'inherit',
    },
    activeTitle: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
    },
    upcomingItem: {
      marginRight: theme.spacing(),
      fontSize: 14,
    },
    activeItemDivider: {
      height: 2,
    },
    activeValue: {
      lineHeight: 1.45,
    },
    item: {
      padding: theme.spacing(1, 2),
    },
    iconBox: {
      display: 'flex',
      flex: '0 1 auto',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(-0.5),
      height: theme.spacing(2.25),
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    fromDot: {
      justifyContent: 'flex-end',
      display: 'flex',
    },
    fromDotBox: {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: theme.spacing(20),
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
    },
    dotBox: {
      width: theme.spacing(2),
      alignSelf: 'center',
    },
    dot: {
      color: theme.palette.primary.main,
      position: 'relative',
      fontSize: 'inherit',
    },
    table: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    hidden: {
      visibility: 'hidden',
    },
    visible: {
      visibility: 'visible',
    },
  }),
  { name: DISPLAY_NAME },
)

interface ActiveItemValue {
  time: string | Moment
  value: string | number
}

interface ActiveItem {
  dotTitle?: string
  title?: string
  showDot?: boolean
  value: ActiveItemValue[]
}

interface Item {
  deleteTitle: string
  id: string | number | null
  onDelete: (meta: any) => {}
  meta: any
  showDelete?: boolean
  time: string | Moment | null
  value: string[] | number[] | null
  valueLabels: string[] | null
}

interface Props {
  activeItem: ActiveItem
  onActionClick: (e: any) => any
  timezone?: string
  hideAction?: boolean
  isLoading?: boolean
  items: Item[]
  marketStartHour?: number
  title?: string
  titleTypographyProps?: object
  activeValueTypographyVariant?: Variant
  useInfoModal?: boolean
  infoModalTitle?: boolean
  infoModalHelperText?: string
}

const ChangesCard: React.FC<Props> = props => {
  const {
    activeItem = {
      dotTitle: '',
      title: null,
      value: null,
      showDot: false,
    },
    onActionClick = () => {},
    hideAction = false,
    isLoading = false,
    items = [],
    marketStartHour = 4,
    timezone = 'Etc/GMT-10',
    title = '',
    titleTypographyProps = { variant: 'h3' },
    activeValueTypographyVariant = 'h3',
    useInfoModal = false,
    infoModalTitle = false,
    infoModalHelperText = '',
  } = props
  const classes = useStyles()
  const activeRowTitle = _.get(activeItem, 'title', 'ACTIVE SINCE')
  const activeItemConfigs = _.get(activeItem, 'value', null)
  const firstActiveItemValue = _.get(activeItem, 'value[0].value', null)
  const firstActiveTime = _.get(activeItem, 'value[0].time', null)

  const initialVisibleState = () =>
    items.reduce((acc, next) => {
      acc[next.id] = false
      return acc
    }, {})

  const [deleteVisible, setDeleteVisible] = useState(initialVisibleState())
  const handleRowEnter = id => {
    setDeleteVisible(prev => {
      const newDict = _.clone(prev)
      newDict[id] = true
      return newDict
    })
  }
  const handleRowLeave = id => {
    setDeleteVisible(prev => {
      const newDict = _.clone(prev)
      newDict[id] = false
      return newDict
    })
  }
  const getHoverClassName = id => {
    return deleteVisible[id] ? classes.visible : classes.hidden
  }

  const ActiveItemComponent = React.isValidElement(firstActiveItemValue) ? (
    firstActiveItemValue
  ) : (
    <Typography
      className={clsx(classes.noWrap, classes.activeValue)}
      variant={activeValueTypographyVariant}
      color="textPrimary"
    >
      {firstActiveItemValue}
    </Typography>
  )

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: clsx(classes.cardContent),
          header: classes.cardHeader,
        }}
        title={
          <Box display="inline-flex">
            <Box display="flex" flex="0 1 auto">
              <Typography variant="h3">{title}</Typography>
            </Box>
            {useInfoModal && (
              <Box className={classes.iconBox}>
                <HelperTextModal helperText={infoModalHelperText} title={infoModalTitle} />
              </Box>
            )}
          </Box>
        }
        titleTypographyProps={titleTypographyProps}
        action={
          !hideAction && (
            <Button variant="text" onClick={onActionClick}>
              edit
            </Button>
          )
        }
        inProgress={isLoading}
      >
        <div>
          {!_.isNil(activeItemConfigs) && (
            <>
              <Typography variant="body1" color="textSecondary" className={classes.activeTitle}>
                {activeRowTitle}
              </Typography>
              <Box id="ocContainer" display="flex" flex="1 1 100%">
                <Box flex="0 0 100px" className={classes.timeAndDateBox}>
                  <Typography className={classes.activeDate} variant="body1" component="span" color="textSecondary">
                    {moment(firstActiveTime).isValid()
                      ? formatCalendarDateAsTradingDay(firstActiveTime, timezone, marketStartHour, DATE_FORMAT_DAY)
                      : '-'}
                  </Typography>
                </Box>
                <Box flex="0 0 50px" className={classes.timeAndDateBox}>
                  <Typography variant="body1" component="span" color="textSecondary">
                    {moment(firstActiveTime).isValid() ? moment(firstActiveTime).tz(timezone).format(TIME_FORMAT) : '-'}
                  </Typography>
                </Box>
                <Box display="flex" flex="1 0 auto" justifyContent="center" className={classes.table}>
                  {_.isNil(ActiveItemComponent) ? null : <>{ActiveItemComponent}</>}
                </Box>
                <Box display="flex" flex="0 0 50px" justifyContent="center" alignItems="center" />
              </Box>
            </>
          )}

          {!_.isEmpty(items) && items.length > 0 && (
            <Grid item xs={12}>
              <Divider variant="fullWidth" className={classes.activeItemDivider} />
            </Grid>
          )}

          {!_.isEmpty(items) &&
            items.map(
              (
                {
                  deleteTitle = 'Remove scheduled change',
                  id = null,
                  onDelete,
                  meta,
                  showDelete = false,
                  time = null,
                  value = null,
                  valueLabels = null,
                },
                key,
                items,
              ) => {
                const handleDelete = () => {
                  if (_.isFunction(onDelete)) {
                    onDelete(meta)
                  }
                }
                return (
                  <React.Fragment key={id}>
                    <Box
                      id="ocContainer"
                      display="flex"
                      flex="1 1 100%"
                      onMouseEnter={() => handleRowEnter(id)}
                      onMouseLeave={() => handleRowLeave(id)}
                    >
                      <Box flex="0 0 100px" className={classes.timeAndDateBox}>
                        <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                          {moment(time).isValid() &&
                            formatCalendarDateAsTradingDay(time, timezone, marketStartHour, DATE_FORMAT_DAY)}
                        </Typography>
                      </Box>
                      <Box flex="0 0 50px" className={classes.timeAndDateBox}>
                        <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                          {moment(time).isValid() && moment(time).tz(timezone).format(TIME_FORMAT)}
                        </Typography>
                      </Box>
                      <Box display="flex" flex="1 0 auto" justifyContent="center" className={classes.table}>
                        {_.isNil(value) ? null : <>{value}</>}
                      </Box>
                      <Box display="flex" flex="0 0 50px" justifyContent="center" alignItems="center">
                        {showDelete && (
                          <IconButton
                            aria-label={deleteTitle}
                            size="small"
                            onClick={handleDelete}
                            className={getHoverClassName(id)}
                          >
                            <XCircle color="primary" fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    {key !== items.length - 1 && (
                      <Grid item xs={12}>
                        <Divider variant="middle" />
                      </Grid>
                    )}
                  </React.Fragment>
                )
              },
            )}
        </div>
      </Card>
    </>
  )
}

ChangesCard.displayName = DISPLAY_NAME

export default ChangesCard
