import React, { Component } from 'react'
import _ from 'lodash'
import { getGraphMetaData, updateGraph, updateLegend } from '../../utility/graphUtility'
import GraphLegend from './GraphLegend'
import { Graph } from './Graph'

export default class StackedGraph extends Component {

  constructor(props) {
    super(props)
    const initialState = this.getInitialState(props)
    this.state = {
      ...initialState,
      isFirstLoad: true,
      isLoading: props.isLoading
    }
  }

  render() {
    const { syncHandler, titles } = this.props
    const { legendMetaData, graphs, rawGraphs } = this.state

    return (
      <section style={{ overflow: "auto", height: "100%" }}>
        {graphs.map((graph, index) => <div key={index} className={"panel-body pb-none" + (index === 0 ? " pt-none" : "")} style={{ height: "calc(100% - 50px)", minHeight: 320, marginRight: 4 }}>
          {titles[index] && <h6 className="mr-md mb-xs mt-none" style={{ fontSize: "1rem" }}>{titles[index]}</h6>}
          <Graph
            ref={graph => rawGraphs[index] = graph}
            graphKey={graph.key}
            height={graph.height}
            series={graph.series}
            options={graph.options}
            syncHandler={syncHandler}
          />
        </div>)}
        {legendMetaData.series.length > 0 && <GraphLegend graph={legendMetaData} toggleSeries={(e, seriesName) => this.toggleSeries(e, seriesName)}/>}
      </section>
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading) {
      const { viewPort, syncHandler, graphs, legendMetaData, intervention, timezone } = nextProps
      const updatedLegend = updateLegend(legendMetaData, this.state.legendMetaData)

      // this can happen when you toggle from one asset to another
      const shouldReInitializeState = !_.isEqual(updatedLegend, this.state.legendMetaData) || graphs.length !== this.state.graphs.length || intervention !== this.props.intervention
      if (shouldReInitializeState) {
        this.setState(this.getInitialState(nextProps, updatedLegend))
      } else {
        const updatedGraphs = graphs.map((graph, index) => updateGraph(graph, this.state.metaDataList[index], viewPort, syncHandler, timezone))
        if (!_.isEqual(updatedGraphs, this.state.graphs)) {
          this.setState({
            graphs: updatedGraphs,
            isFirstLoad: false,
            isLoading: false
          })
        }
      }
    } else {
      this.setState({ isLoading: true })
    }
  }

  getInitialState(props, updatedLegend) {
    const { viewPort, syncHandler, graphs, legendMetaData, timezone } = props
    const metaDataList = []
    const updatedGraphs = graphs.map(graph => {
      const newMetaData = updateLegend(getGraphMetaData(graph), updatedLegend || legendMetaData)
      metaDataList.push(newMetaData)
      return updateGraph(graph, newMetaData, viewPort, syncHandler, timezone)
    })
    return {
      isFirstLoad: false,
      graphs: updatedGraphs,
      legendMetaData: updatedLegend || legendMetaData,
      rawGraphs: updatedGraphs.map(() => null),
      metaDataList
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState.graphs, this.state.graphs) || !_.isEqual(nextState.legendMetaData, this.state.legendMetaData) || nextState.isLoading !== this.state.isLoading
  }

  toggleSeries(e, seriesName) {
    const { toggleSeries } = this.props
    const { metaDataList, legendMetaData, rawGraphs } = this.state

    const updateMetaData = (metaDataList, legendMetaData) => this.setState({
      metaDataList,
      legendMetaData
    })
    toggleSeries(e, seriesName, metaDataList, legendMetaData, rawGraphs, updateMetaData)
  }
}