import React, { useEffect } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined'
import CachedIcon from '@material-ui/icons/Cached'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import Card from '../Card'
import { formatPercentage, dateTimeFormat } from '../../utility/utility'
import DetailList from '../DetailList'
import { getLastWarrantyContracts, selectors as assetOperationSelectors } from '../../redux/features/asset-operation'

const DISPLAY_NAME = 'WarrantyDetailsCard'
const TITLE = 'Warranty Details'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 300,
    },
    tableCardContent: {
      minHeight: 200,
      padding: 0,
    },
    action: {
      paddingRight: theme.spacing(1),
    },
    actionBtn: {
      cursor: 'pointer',
    },
    secondary: {
      color: theme.palette.text.tertiary,
    },
    muiListItemIconRoot: {
      color: theme.palette.text.tertiary,
      minWidth: 40,
    },
  }),
  { name: DISPLAY_NAME },
)

const WarrantyDetailsCard = props => {
  const { assetId, timezone = 'Etc/GMT-10', startTime } = props
  const { warranty, dispatchGetLastWarrantyContracts } = props
  const classes = useStyles()
  moment.tz.setDefault(timezone)

  useEffect(() => {
    if (!_.isNil(assetId) && !_.isNil(startTime)) {
      dispatchGetLastWarrantyContracts(assetId, startTime)
    }
  }, [assetId, dispatchGetLastWarrantyContracts, startTime])

  const startOfWarrantyYear = moment(_.get(warranty, 'data.start_of_warranty_year', null))
  const finalEndOfWarranty = moment(_.get(warranty, 'endTime', null))
  const dailyCycleLimit = _.get(warranty, 'data.daily_cycle_limit', null)
  const annualCycleLimit = _.get(warranty, 'data.annual_cycle_limit', null)
  const socMaxLimit = _.get(warranty, 'data.soc_max_limit', null)
  const socMinLimit = _.get(warranty, 'data.soc_min_limit', null)
  const hasValidWarranty =
    moment(startTime).isBetween(startOfWarrantyYear, finalEndOfWarranty, undefined, '[]')

  const subheader = hasValidWarranty ? `ACTIVE SINCE ${startOfWarrantyYear.format(dateTimeFormat)}` : `NOT ACTIVE`
  const maxCyclesPerDay = hasValidWarranty && !_.isNil(dailyCycleLimit) ? dailyCycleLimit : 'N/A'
  const maxCyclesPerYear = hasValidWarranty && !_.isNil(annualCycleLimit) ? annualCycleLimit : 'N/A'
  const maxSoePercent = hasValidWarranty && !_.isNil(socMaxLimit) ? formatPercentage(socMaxLimit, 0) : 'N/A'
  const minSoePercent = hasValidWarranty && !_.isNil(socMinLimit) ? formatPercentage(socMinLimit, 0) : 'N/A'

  const cycleDetails = [
    {
      primary: maxCyclesPerDay,
      secondary: 'MAX CYCLES/DAY',
      IconComponent: CachedIcon,
    },
    {
      primary: maxCyclesPerYear,
      secondary: 'MAX CYCLES/YEAR',
      IconComponent: CachedIcon,
    },
  ]

  const soeDetails = [
    {
      primary: maxSoePercent,
      secondary: `MAX SOE`,
      IconComponent: OfflineBoltOutlinedIcon,
    },
    {
      primary: minSoePercent,
      secondary: `MIN SOE`,
      IconComponent: OfflineBoltOutlinedIcon,
    },
  ]

  return (
    <Card classes={{ content: classes.tableCardContent }} title={TITLE} subheader={subheader} className={classes.card}>
      <Grid container>
        <Box clone>
          <Grid item container xs={12} xl={6}>
            <DetailList listItems={cycleDetails} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item container xs={12} xl={6}>
            <DetailList listItems={soeDetails} />
          </Grid>
        </Box>
      </Grid>
    </Card>
  )
}

WarrantyDetailsCard.displayName = DISPLAY_NAME

const mapStateToProps = () => {
  return state => ({
    warranty: assetOperationSelectors.selectLastWarrantyContract(state),
  })
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetLastWarrantyContracts: (assetId, startTime) => dispatch(getLastWarrantyContracts(assetId, startTime)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyDetailsCard)
