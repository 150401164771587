import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Grid, IconButton, Typography, Tooltip } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { Button, useNotifier, XCircle } from '@fluence/core'

import {
  marketAssetDataBepResource,
  upsertAssetDataResource,
  bepEntriesSelector,
  lgcEntriesSelector,
  endOfIntervalSelector,
  bepImpliedResource,
  currentBepImpliedResource,
  allActiveBepResource,
  deleteAssetDataResource,
} from '../../redux/features/setting'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { globalAppSelectors } from '../../redux/features/app'
import {
  convertPricePerMWhTokWh,
  formatMoney,
  perKWToPerMW,
  ZERO,
  formatCalendarDateAsTradingDay,
} from '../../utility/utility'
import { canAdjustRiskAppetite } from '../../utility/user-utils'

import Card from '../Card'

import { DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'BEPCard'

const useStyles = makeStyles(
  theme => ({
    card: {
      background: _.get(theme, 'palette.background.paper', '#1D2126'),
    },
    cardContent: {
      padding: 0,
      maxHeight: theme.spacing(20),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    cardHeader: {
      borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    },
    slider: {
      color: '#B0BEC4',
    },
    activeGrid: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      display: 'flex',
    },
    activeBox: {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: theme.spacing(20),
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(1),
    },
    activeDotBox: {
      width: theme.spacing(2),
      alignSelf: 'center',
      display: 'flex',
    },
    activeItem: {
      padding: theme.spacing(1, 2),
    },
    activeDate: {
      marginRight: theme.spacing(),
    },
    activeDot: {
      color: theme.palette.primary.main,
      position: 'relative',
      fontSize: 'inherit',
    },
    activeItemDivider: {
      height: 2,
    },
    activeValue: {
      lineHeight: 1.45,
    },
    bepItem: {
      padding: theme.spacing(1, 2),
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    fromDot: {
      justifyContent: 'flex-end',
      display: 'flex',
    },
    fromDotBox: {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: theme.spacing(20),
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
    },
    dotBox: {
      width: theme.spacing(2),
      alignSelf: 'center',
    },
    dot: {
      color: theme.palette.primary.main,
      position: 'relative',
      fontSize: 'inherit',
    },
    showChildOnHover: {
      '& > div:last-child > div > div:last-child': {
        visibility: 'hidden',
      },
      '&:hover > div:last-child > div > div:last-child': {
        visibility: 'visible',
      },
    },
    visibilityHidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'BEPCard' },
)

function BEPCard(props) {
  // NOTE: endOfInterval triggers re-render when interval changes. We want that.
  const { endOfInterval } = props
  const { asset, bepEntries, bepImplied, currentBepImplied, allActiveBepData, permissions } = props
  const {
    dispatchCreateAssetData,
    dispatchDeleteAssetData,
    getBEPEntries,
    getBEPImplied,
    getCurrentBepImplied,
    getAllActiveBep,
    getMarketPriceCap,
    lgcEntries,
    ppaData,
  } = props
  const { notifySuccess, notifyError } = useNotifier()
  const classes = useStyles()

  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const descriptionText =
    'Asset’s Break Even Price above which to maximise generation. BEP Implied is derived from LGC and PPA Terms. ' +
    'For assets with PPA Terms defined, enabling BEP Override will optimise the asset as merchant until BEP Implied is restored. ' +
    'BEP Implied values are estimates based on current configurations. Changes to LGC or PPA Terms will change BEP Implied value. ' +
    'Adjustments can take effect in the next available dispatch interval or in the future.'

  const bepEntriesByTime = bepEntries
    ? bepEntries.reduce((acc, next) => {
        return {
          ...acc,
          [moment(next.startTime).valueOf()]: next,
        }
      }, {})
    : {}

  const assetId = _.get(asset, 'assetId')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)
  const allActiveBep = _.isArray(allActiveBepData) ? allActiveBepData.filter(bep => !bep.deleted) : []

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)

  useEffect(() => {
    if (!_.isNil(assetId)) {
      getBEPEntries(assetId, moment())
      getAllActiveBep(assetId)
    }
  }, [assetId, endOfInterval, getAllActiveBep, getBEPEntries])

  const handleBEPSubmit = request => {
    const { startTime, newInputValues, impliedValuesToSubmit } = request
    const startTimestamp = moment(startTime).valueOf()
    const existingAssetData = _.get(bepEntriesByTime, `${startTimestamp}.data`, {})
    const assetData = {
      ...existingAssetData,
      'break_even_price_per_kwh': _.isNil(newInputValues[0]) ? null : convertPricePerMWhTokWh(newInputValues[0]),
      'usedImpliedValue': impliedValuesToSubmit[0],
    }
    return dispatchCreateAssetData(asset.assetId, 'break_even_setting', startTimestamp, assetData).then(response => {
      if (response.error) {
        notifyError('Unable to save new BEP entry')
        return response
      } else {
        return getBEPEntries(asset.assetId, moment()).then(getBEPResponse => {
          if (getBEPResponse.error) {
            notifyError('Unable to retrieve updated BEP values')
          }
          notifySuccess('BEP adjustment scheduled successfully')
          return getBEPResponse
        })
      }
    })
  }

  const handleBEPDelete = assetDataId => {
    return dispatchDeleteAssetData(assetDataId).then(response => {
      if (response.error) {
        notifyError('Unable to delete BEP entry')
        return response
      } else {
        return getBEPEntries(asset.assetId, moment()).then(getBEPResponse => {
          if (getBEPResponse.error) {
            notifyError('Unable to retrieve updated BEP values')
          }
          return getBEPResponse
        })
      }
    })
  }

  const currentDatetime = moment().tz(mtz)
  const activeBEP =
    !_.isEmpty(bepEntries) && _.last(bepEntries.filter(bep => moment(bep.startTime).isBefore(currentDatetime)))
  const upcomingBEPs =
    !_.isEmpty(bepEntries) && bepEntries.filter(bep => moment(bep.startTime).isAfter(currentDatetime))

  const activeLGC =
    !_.isEmpty(lgcEntries) && _.last(lgcEntries.filter(lgc => moment(lgc.startTime).isBefore(currentDatetime)))
  const activeLgcValue = _.get(activeLGC, 'data.lgc_price_per_kwh', null)
  const hasPPA = ppaData.length > 0
  const finalValueToOverride = hasPPA
    ? bepImplied || null
    : !_.isNil(activeLgcValue) && _.isNumber(activeLgcValue)
    ? -activeLgcValue
    : null
  const disableImplied = _.isNil(activeLgcValue) && !hasPPA

  let checkImpliedTime = moment().valueOf()
  if (!_.isEmpty(allActiveBep) && _.get(activeBEP, 'data.break_even_price_per_kwh') !== null) {
    const lastNonOverrideRecordIndex = _.findLastIndex(
      allActiveBep,
      bep => _.get(bep, 'data.break_even_price_per_kwh') === null,
    )
    const nextOverrideRecordOfLastNonOverrideRecord =
      lastNonOverrideRecordIndex === -1 ? null : allActiveBep[lastNonOverrideRecordIndex + 1]
    checkImpliedTime = nextOverrideRecordOfLastNonOverrideRecord
      ? moment(_.get(nextOverrideRecordOfLastNonOverrideRecord, 'startTime')).subtract(ZERO, 'm').valueOf()
      : moment()
  }

  const endOfIntervalTimeStamp = moment(endOfInterval).valueOf()

  useEffect(() => {
    if (!_.isNil(assetId) && !_.isEmpty(allActiveBepData)) {
      getBEPImplied(assetId, moment(checkImpliedTime))
      getCurrentBepImplied(assetId, moment())
    }
  }, [
    assetId,
    allActiveBepData,
    checkImpliedTime,
    endOfIntervalTimeStamp,
    getBEPImplied,
    getCurrentBepImplied,
    endOfInterval,
  ])

  const calcBepValue = bep => {
    const kwH = _.get(bep, 'data.break_even_price_per_kwh')
    const usedImplied = _.get(bep, 'data.usedImpliedValue')
    if (kwH !== null) {
      return _.isFinite(kwH) && kwH
    }
    return usedImplied || bepImplied
  }
  const inputConfigs = [
    {
      label: 'BEP',
      current: currentBepImplied * perKWToPerMW,
      override: true,
      overrideLabel: 'BEP IMPLIED',
      overrideValue: finalValueToOverride,
      disableImplied: disableImplied,
      inputAdornment: '$',
    },
  ]

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
        }}
        title="BEP ($/MWh)"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          canAdjustTradeSettings && (
            <Button variant="text" onClick={() => setIsSchedulingModalOpen(true)}>
              edit
            </Button>
          )
        }
      >
        <Box p={0}>
          <Grid container spacing={0}>
            {!_.isEmpty(activeBEP) && (
              <Grid container spacing={0} item xs={12} className={classes.activeItem} alignItems="flex-end">
                <Grid container spacing={0} item xs={7}>
                  <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary">
                      ACTIVE SINCE
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.activeDate} variant="body1" component="span" color="textSecondary">
                      {formatCalendarDateAsTradingDay(activeBEP.startTime, mtz, marketStartHour, DATE_FORMAT_DAY)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1" component="span" color="textSecondary">
                      {formatCalendarDateAsTradingDay(activeBEP.startTime, mtz, marketStartHour, TIME_FORMAT)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={5} className={classes.activeGrid}>
                  {_.isNumber(currentBepImplied) && (
                    <Box className={classes.activeBox}>
                      <Box className={classes.activeDotBox}>
                        {_.get(activeBEP, 'data.break_even_price_per_kwh') !== null && (
                          <Tooltip title="BEP Override">
                            <FiberManualRecordIcon className={classes.activeDot} />
                          </Tooltip>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          className={clsx(classes.noWrap, classes.activeValue)}
                          variant="h2"
                          color="textPrimary"
                        >
                          {formatMoney(currentBepImplied * perKWToPerMW, 2)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}

            {!_.isEmpty(upcomingBEPs) && upcomingBEPs.length > 0 && (
              <Grid item xs={12}>
                <Divider variant="fullWidth" className={classes.activeItemDivider} />
              </Grid>
            )}

            {!_.isEmpty(upcomingBEPs) &&
              upcomingBEPs.map((bepEntry, key, beps) => (
                <React.Fragment key={key}>
                  <Grid container spacing={0} item xs={12} className={clsx(classes.bepItem, classes.showChildOnHover)}>
                    <Grid container spacing={0} item xs={7}>
                      <Grid item xs={8}>
                        <Typography variant="body1" color="textSecondary">
                          {formatCalendarDateAsTradingDay(bepEntry.startTime, mtz, marketStartHour, DATE_FORMAT_DAY)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" color="textSecondary">
                          {formatCalendarDateAsTradingDay(bepEntry.startTime, mtz, marketStartHour, TIME_FORMAT)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={5} className={classes.fromDot}>
                      <Box className={classes.fromDotBox}>
                        <Box display="flex">
                          <Box className={classes.dotBox}>
                            {_.get(bepEntry, 'data.break_even_price_per_kwh') !== null && (
                              <Tooltip title="BEP Override">
                                <FiberManualRecordIcon className={classes.dot} />
                              </Tooltip>
                            )}
                          </Box>
                          <Typography className={classes.noWrap} variant="body1" color="textSecondary">
                            {formatMoney(calcBepValue(bepEntry) * perKWToPerMW, 2)}
                          </Typography>
                        </Box>
                        {canAdjustTradeSettings && (
                          <Box display="flex" flex="0 1 auto">
                            <IconButton
                              aria-label="Remove scheduled Break Even Price adjustment"
                              size="small"
                              onClick={() => {
                                handleBEPDelete(bepEntry.assetDataId)
                              }}
                            >
                              <XCircle color="primary" fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {key !== beps.length - 1 && (
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </Box>
      </Card>
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleBEPSubmit}
        title="Schedule BEP adjustment"
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        description={descriptionText}
        precision={2}
        getMarketPriceCap={getMarketPriceCap}
      />
    </>
  )
}

BEPCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    const bepImplied = _.get(state, 'setting.bepImplied')
    const bepImpliedValue = _.get(bepImplied, 'payload.value', null)
    const currentBepImpliedValue = _.get(state, 'setting.currentBepImplied.payload.value', null)
    const allActiveBepData = _.get(state, 'setting.allActiveBep.payload', [])
    const ppaData = _.get(state, 'setting.ppa.payload', [])
    const getMarketPriceCap = globalAppSelectors.getMarketPriceCapFn(state)
    const permissions = currentUserPermissionsSelector(state)

    return {
      bepEntries: bepEntriesSelector(state),
      lgcEntries: lgcEntriesSelector(state),
      ppaData: ppaData,
      endOfInterval: endOfIntervalSelector(state),
      bepImplied: bepImpliedValue,
      currentBepImplied: currentBepImpliedValue,
      allActiveBepData,
      getMarketPriceCap,
      permissions,
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { asset } = ownProps
  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  return {
    getBEPEntries: (assetId, startTime) =>
      dispatch(marketAssetDataBepResource.get(assetId, startTime.subtract(ZERO, 'minutes'))),
    getBEPImplied: (assetId, startTime, productId = 1) =>
      dispatch(bepImpliedResource.get(assetId, startTime, productId)),
    getCurrentBepImplied: (assetId, startTime, productId = 1) =>
      dispatch(currentBepImpliedResource.get(assetId, startTime, productId)),
    getAllActiveBep: assetId => {
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        dispatch(allActiveBepResource.get(assetId, startTime, moment().subtract(ZERO, 'minutes')))
      }
    },
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchDeleteAssetData: assetDataId => dispatch(deleteAssetDataResource.put(assetDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(BEPCard)
