import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Button } from '@fluence/core'
import { isSelectedDateActiveMarketDay, getMarketStartGivenTimestamp } from '../../utility/time-utils'
import {
  convertkWToMW,
  formatCalendarDateAsTradingDay,
  getProductIdToProductNameMap,
  getAllEnabledProductIds,
} from '../../utility/utility'
import { DATE_FORMAT, DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import KeyboardDatePicker from '../KeyboardDatePicker'
import Card from '../Card'
import ManualBidCombinedProductToggle from '../toggles/ManualBidCombinedProductToggle'
import HelperTextModal from './HelperTextModal'

const DISPLAY_NAME = 'ManualBidDelete'
const NUM_COLUMNS = 17

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      position: 'absolute',
      minWidth: 500,
      maxWidth: 'calc(100vw - 32px)',
      marginLeft: 'auto',
      marginRight: 'auto',
      top: 64,
      right: 0,
      left: 0,

      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    },
    smallLabel: {
      fontSize: theme.typography.pxToRem(10),
      marginBottom: theme.spacing(0.375),
      color: theme.custom.palette.graphs.labelColor,
    },
    cardContent: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    container: {
      maxHeight: 'calc(100vh - 364px)',
      minHeight: 'calc(100vh - 364px)',
    },
    expandCol: {
      minWidth: 40,
      maxWidth: 40,
      width: 40,
    },
    selectCol: {
      minWidth: 60,
      maxWidth: 60,
      width: 60,
    },
    startTimeCol: {
      minWidth: theme.spacing(14),
      maxWidth: theme.spacing(14),
      width: theme.spacing(14),
    },
    overrideCol: {
      minWidth: 95,
      maxWidth: 95,
      width: 95,
    },
    userCol: {
      width: '12%',
    },
    maxAvailCol: {
      width: '6%',
    },
    reasonCol: {
      width: '20%',
    },
    footer: {
      borderTop: `2px solid ${theme.palette.background.default}`,
    },
    footerGrid: {
      marginTop: theme.spacing(2),
      gap: theme.spacing(2),
    },
    expandableRow: {
      backgroundColor: theme.custom.palette.table.row.background.secondary,
    },
    table: {
      tableLayout: 'fixed',
      minWidth: 650,
      '& th': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    expandedTable: {
      tableLayout: 'fixed',
      backgroundColor: 'inherit',
      '& td': {
        color: theme.palette.text.disabled,
        backgroundColor: theme.custom.palette.table.row.background.secondary,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    manualBidRow: {
      cursor: 'pointer',
    },
    contiguousBidRow: {},
    reason: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    username: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    maxAvail: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    manualBidSubheader: {
      fontSize: 12,
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    overrideButton: {
      paddingTop: 2,
      paddingBottom: 2,
      whiteSpace: 'nowrap',
    },
    helperText: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(2),
    },
  }),
  { name: DISPLAY_NAME },
)

const ManualBidDelete = React.forwardRef(function ManualBidDelete(props, ref) {
  const classes = useStyles()
  const {
    action: actionProp,
    asset,
    bids: bidsProp = [],
    cutoffTime,
    endTime: endTimeProp,
    marketTimezone,
    onChangeDate,
    onChangeProductName: onChangeProductNameProp,
    onChangeProductType: onChangeProductTypeProp,
    onClose,
    onDelete,
    productName,
    productType,
    startTime: startTimeProp,
    tradingDay: tradingDayProp,
    bidsMappedByManualBidIdStartTime,
    disabledProductTypeToggleNames,
    showProductTypeToggle,
  } = props

  const { assetId } = asset
  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNames = _.get(asset, 'market.data.product_display_names')
  const productTypes = _.get(asset, 'productTypes', [])
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})
  const disabledProductNames = asset.productNames.filter(
    productName => _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) === 0,
  )
  const productIdToProductNameMap = getProductIdToProductNameMap(asset)
  const allEnabledProductIds = getAllEnabledProductIds(asset, moment(startTimeProp))

  // Interval Range Picker Props
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const timezone = _.get(asset, 'market.data.timezone')

  // Manual Bid List Display Options
  const [form, setForm] = useState({
    showAll: true,
    selected: {},
  })
  const [expand, setExpand] = useState({})
  const [expandOverrides, setExpandOverrides] = useState({})
  const [bidsDisplay, setBidsDisplay] = useState([])
  const [initialStartTime] = useState(startTimeProp)
  const [initialEndTime] = useState(endTimeProp)
  const [tradingDay, setTradingDay] = useState(moment(tradingDayProp))
  const [onDeleteInProgress, setOnDeleteInProgress] = useState(false)

  const bidsPropFiltered = useMemo(() => {
    return bidsProp.filter(bid => {
      const deleted = bid.deleted
      const showAll = form.showAll
      const isInTimeInterval =
        moment(bid.startTime) >= moment(initialStartTime) && moment(bid.startTime) < moment(initialEndTime)
      return !deleted && (showAll || isInTimeInterval)
    })
  }, [bidsProp, form.showAll, initialEndTime, initialStartTime])

  const selectedBidsToDelete = useMemo(() => {
    const selectedBidSet = new Set()
    if (!_.isEmpty(form.selected)) {
      const selectedBids = bidsPropFiltered.filter(bid => {
        return form.selected[getBidId(bid)]
      })
      selectedBids.forEach(bid => selectedBidSet.add(`${bid.manualBidId}_${bid.startTime}`))
    }
    return selectedBidSet
  }, [bidsPropFiltered, form.selected])

  const highlightedProducts = useMemo(() => {
    // Find affected products based on the bids users have selected, so we can color product toggle
    const highlightedProductTypeNamesSet = new Set()
    const highlightedProductNamesSet = new Set()
    const affectedProductIds = new Set()
    for (const bid of Object.values(bidsPropFiltered)) {
      if (form.selected[getBidId(bid)]) {
        const manualBidKey = [`${assetId}`, bid.manualBidId, bid.startTime, 'bids']
        const allApplicableProductIds = _.keyBy(_.get(bidsMappedByManualBidIdStartTime, manualBidKey), 'productId')
        const applicableProductIds = _.keys(allApplicableProductIds)
        applicableProductIds.forEach(affectedProductIds.add, affectedProductIds)
        // Optimization: If it's known that all productIds were affected, just stop collating affected productIds
        if (affectedProductIds.size === allEnabledProductIds.length) {
          break
        }
      }
    }
    affectedProductIds.forEach(productId => {
      const { productName: bidProductName, productTypeName: bidProductTypeName } = productIdToProductNameMap[productId]
      highlightedProductTypeNamesSet.add(bidProductTypeName)
      if (bidProductTypeName === productType) {
        highlightedProductNamesSet.add(bidProductName)
      }
    })
    const highlightedProductTypeNames = affectedProductIds.size > 1 ? Array.from(highlightedProductTypeNamesSet) : []
    const highlightedProductNames = affectedProductIds.size > 1 ? Array.from(highlightedProductNamesSet) : []
    return { highlightedProductTypeNames, highlightedProductNames }
  }, [
    bidsPropFiltered,
    form.selected,
    assetId,
    bidsMappedByManualBidIdStartTime,
    allEnabledProductIds.length,
    productIdToProductNameMap,
    productType,
  ])

  const getDeletableIntervalBids = (assetId, selectedBidsToDelete, bidsMappedByManualBidIdStartTime, cutoffTime) => {
    const collatedBidsToDelete = []
    if (!_.isNil(selectedBidsToDelete)) {
      selectedBidsToDelete.forEach(bidTime => {
        // applicable zeroMaxAvail bids to be deleted are those that are created at the same time
        const [manualBidId, startTime] = bidTime.split('_')
        const applicableZeroMaxAvailBidsToDelete = _.get(bidsMappedByManualBidIdStartTime, [
          `${assetId}`,
          manualBidId,
          startTime,
          'bids',
        ])
        if (!_.isNil(applicableZeroMaxAvailBidsToDelete) && moment(startTime).isSameOrAfter(moment(cutoffTime))) {
          const mappedBids = applicableZeroMaxAvailBidsToDelete.map(bid => ({
            assetId,
            productId: bid.productId,
            startTime: bid.startTime,
            manualBidId: bid.manualBidId,
          }))
          collatedBidsToDelete.push(...mappedBids)
        }
      })
    }
    return collatedBidsToDelete
  }

  useEffect(() => {
    setTradingDay(moment(tradingDayProp))
  }, [tradingDayProp])

  useEffect(() => {
    const allIds = bidsPropFiltered.filter(bid => !bid.deleted).map(bid => getBidId(bid))

    setForm(prev => {
      const showAll = prev.showAll
      const selected = {}
      allIds.forEach(id => {
        selected[id] = !!prev.selected[id]
      })
      return { showAll, selected }
    })

    const reverseSortedIndex = (arr, value, getValue) => {
      for (let i = 0; i < arr.length; i++) {
        const iterValue = getValue(arr[i])
        if (value > iterValue) {
          return i
        }
      }
      return arr.length
    }

    setBidsDisplay(prev => {
      const bidsMapByStartTime = {}
      bidsPropFiltered.forEach(bid => {
        if (!bid.deleted) {
          if (bidsMapByStartTime[bid.startTime] === undefined) {
            bidsMapByStartTime[bid.startTime] = []
          }

          const bids = bidsMapByStartTime[bid.startTime]
          const sortIndex = reverseSortedIndex(bids, moment(bid.createdOn).valueOf(), b =>
            moment(b.createdOn).valueOf(),
          )
          bids.splice(sortIndex, 0, bid)
        }
      })
      const newBidsDisplay = _.values(bidsMapByStartTime).sort(
        (a, b) => moment(_.first(a).startTime) - moment(_.first(b).startTime),
      )

      const bidsWithOverrides = newBidsDisplay.map(bids => {
        const [bid, ...rest] = bids
        const newBid = { bid }
        if (!_.isEmpty(rest)) {
          newBid.override = rest.map(bid => ({ bid, overridden: true }))
        }
        return newBid
      })

      const collapsedBids = []
      if (bidsWithOverrides.length) {
        const firstBidNode = bidsWithOverrides.shift()
        collapsedBids.push(firstBidNode)

        let prevTime = firstBidNode.bid.startTime
        let prevValues = firstBidNode.bid.values
        let prevMaxAvail = firstBidNode.bid.maxAvailablePower
        bidsWithOverrides.forEach(bidNode => {
          const startTime = moment(bidNode.bid.startTime)
          const isContinuation =
            prevTime &&
            moment(prevTime).add(5, 'minutes').isSame(startTime, 'minute') &&
            _.isEqual(prevValues, bidNode.bid.values) &&
            _.isEqual(prevMaxAvail, bidNode.bid.maxAvailablePower)

          if (isContinuation) {
            if (!collapsedBids[collapsedBids.length - 1].contiguousBids) {
              collapsedBids[collapsedBids.length - 1].contiguousBids = []
            }
            collapsedBids[collapsedBids.length - 1].contiguousBids.push(bidNode)
          } else {
            collapsedBids.push(bidNode)
          }
          prevTime = bidNode.bid.startTime
          prevValues = bidNode.bid.values
          prevMaxAvail = bidNode.bid.maxAvailablePower
        })
      }

      return collapsedBids
    })
  }, [bidsPropFiltered])

  const handleCloseClick = disableSubmit => e => {
    if (disableSubmit) {
      setForm({ showAll: false, selected: {} })
      if (_.isFunction(onClose)) {
        onClose()
      }
    } else {
      setForm(prev => {
        const selected = unselectAll(prev.selected)
        return { ...prev, selected }
      })
    }
  }

  const handleIsIdSelected = ids => {
    if (_.isNil(ids)) {
      return null
    }
    if (_.isArray(ids)) {
      return ids.map(id => form.selected[id])
    }
    return form.selected[ids]
  }

  const handleSelectionClick = (id, allIds) => event => {
    event.stopPropagation()
    setForm(prev => {
      const prevSelected = prev.selected
      const isSelected = !_.get(prevSelected, id)
      const newSelected = allIds ? _.mapValues(_.keyBy(allIds), () => isSelected) : { [id]: isSelected }
      return {
        ...prev,
        selected: { ...prevSelected, ...newSelected },
      }
    })
  }

  const toggleSelectAll = selectableItems => {
    if (_.isEmpty(selectableItems)) {
      return {}
    }

    // If all true return all false
    if (_.values(selectableItems).every(value => value)) {
      return _.mapValues(selectableItems, () => false)
      // If all false return all true
    } else if (_.values(selectableItems).every(value => !value)) {
      return _.mapValues(selectableItems, () => true)
      // If mixed true false
    } else if (_.values(selectableItems).some(value => value)) {
      return _.mapValues(selectableItems, () => true)
    }
  }

  const unselectAll = selectableItems => {
    if (_.isEmpty(selectableItems)) {
      return {}
    }

    return _.mapValues(selectableItems, () => false)
  }

  const handleSelectAllClick = event => {
    setForm(prev => {
      const selected = toggleSelectAll(prev.selected)
      return { ...prev, selected }
    })
  }

  const handleExpandRow = rowId => event => {
    setExpand(prev => {
      const newValue = !prev[rowId]
      if (!newValue) {
        setExpandOverrides(prevOverrides => ({ ...prevOverrides, [rowId]: newValue }))
      }
      return { ...prev, [rowId]: newValue }
    })
  }

  const handleExpandOverride = uniqueBidId => () => {
    setExpandOverrides(prev => {
      return { ...prev, [uniqueBidId]: !prev[uniqueBidId] }
    })
  }

  const handleSubmit = async () => {
    if (!_.isEmpty(selectedBidsToDelete)) {
      try {
        setOnDeleteInProgress(true)
        // collate bids to be deleted across multiple products
        const collatedBidsToDelete = getDeletableIntervalBids(
          assetId,
          selectedBidsToDelete,
          bidsMappedByManualBidIdStartTime,
          cutoffTime,
        )
        // regroup bids by productId for deletion (Kotlin API allows batch bid deletion only for unique product IDs)
        const groupedBidsByIdToDelete = _.groupBy(collatedBidsToDelete, 'productId')
        onDelete(groupedBidsByIdToDelete).finally(() => {
          setOnDeleteInProgress(false)
        })
      } catch (e) {
        console.info('Manual Bid Delete - error handling submit', e)
      }
    }
  }

  const handleDeletableBidsIntervalOnChange = newDate => {
    onChangeDate(newDate)
  }

  const calcSubtitle = (startTime, endTime, showAll) => {
    const isCurrentTradingDay = isSelectedDateActiveMarketDay(tradingDay, marketStartHour)
    const suffix = isCurrentTradingDay ? ` - Active view only includes future intervals` : ''
    if (showAll) {
      return `${formatCalendarDateAsTradingDay(moment(tradingDay), timezone, marketStartHour, DATE_FORMAT_DAY)} ALL DAY`
    }
    const isStartEarlierThanCutoff = moment(startTime) < moment(cutoffTime)
    if (moment(initialStartTime).isValid() && moment(endTime).isValid()) {
      return `${formatCalendarDateAsTradingDay(
        initialStartTime,
        timezone,
        marketStartHour,
        DATE_FORMAT,
      )} TO ${formatCalendarDateAsTradingDay(endTime, timezone, marketStartHour, DATE_FORMAT)} ${
        isStartEarlierThanCutoff ? suffix : ''
      }`
    }
    return formatCalendarDateAsTradingDay(moment(tradingDay), timezone, marketStartHour, DATE_FORMAT)
  }

  const handleProductTypeChange = (event, productType) => {
    setForm(prev => ({ ...prev, selected: {} }))
    onChangeProductTypeProp(event, productType)
  }

  const handleProductNameChange = (e, productName, newProductType) => {
    setForm(prev => ({ ...prev, selected: {} }))
    onChangeProductNameProp(e, productName, newProductType)
  }

  const isAllChecked = _.values(form.selected).some(x => x)
  const isAllIndeterminate = isAllChecked && _.values(form.selected).some(x => !x)
  const checked = isAllIndeterminate ? false : isAllChecked
  const title = `Manual Bids`
  const subtitle = calcSubtitle(initialStartTime, initialEndTime, form.showAll)
  const disableSubmit = _.values(form.selected).every(x => !x)
  const invalidInterval = !moment(initialStartTime).isValid() || !moment(initialEndTime).isValid()

  const isPastEndTime = moment(initialEndTime).isBefore(moment(cutoffTime))
  const helperText = `Active manual bids are scheduled for future intervals of the selected trading day. Expand each manual bid to review all applicable intervals. Select manual bids, or portions of, to delete.\n    • When creating a new manual bid for a period where an existing manual bid is already scheduled, it is advised to first DELETE the existing manual bid before scheduling the new manual bid.\n    • Selecting manual bid(s) that affect multiple products will highlight the affected products in orange.`

  let noBidsMessage = ''
  if (invalidInterval) {
    if (form.showAll) {
      noBidsMessage = `No data to show. Invalid interval.`
    } else {
      noBidsMessage = 'Invalid start and end time. Maybe check "Show all day" option?'
    }
  } else {
    if (isPastEndTime) {
      noBidsMessage = `Selected interval is in the past.`
    }
    noBidsMessage = `No manual bids were created for selected trading day`
  }
  const minEndDate = getMarketStartGivenTimestamp(moment(), marketStartHour, timezone)
  const maxEndDate = moment(minEndDate).add(2, 'years')
  const inProgress = onDeleteInProgress || bidsMappedByManualBidIdStartTime.isLoading
  return (
    <Card
      tabIndex={-1}
      classes={{
        root: classes.card,
        content: classes.cardContent,
      }}
      title={
        <Box>
          <Box display="inline" pr={0.5}>
            {title}
          </Box>
          <HelperTextModal helperText={helperText} title="Manual Bid Deletion Instructions" />
        </Box>
      }
      titleTypographyProps={{ variant: 'h3' }}
      subheader={subtitle}
      action={!onDeleteInProgress && actionProp}
      inProgress={inProgress}
      ref={ref}
    >
      <Grid container spacing={0} mt={2} mb={1}>
        <Grid item>
          <Box minWidth="220px" pl={2}>
            <Typography className={classes.smallLabel}>Trading Date</Typography>
            <KeyboardDatePicker
              id="schedule-modal-start-date"
              timezone={marketTimezone}
              inputVariant="standard"
              marketStartHour={marketStartHour}
              ariaLabel="change market trading date"
              minDate={minEndDate}
              maxDate={maxEndDate}
              selectedDate={tradingDay}
              onChange={handleDeletableBidsIntervalOnChange}
              disabled={onDeleteInProgress}
            />
          </Box>
        </Grid>
        <Grid item md={8}>
          <ManualBidCombinedProductToggle
            asset={asset}
            selectedTime={startTimeProp}
            // Product Type Toggle Props
            disableProductTypeToggle={onDeleteInProgress}
            selectedProductType={productType}
            onChangeProductType={handleProductTypeChange}
            productTypes={productTypes}
            productTypeDisplayNames={productTypeDisplayNames}
            highlightedProductTypeNames={highlightedProducts.highlightedProductTypeNames}
            // Product Toggle Props
            disableProductToggle={onDeleteInProgress}
            selectedProductName={productName}
            onChangeProductName={handleProductNameChange}
            disabledProductNames={disabledProductNames}
            productNames={productNames}
            productDisplayNames={productDisplayNames}
            highlightedProductNames={highlightedProducts.highlightedProductNames}
            disabledProductTypeToggleNames={disabledProductTypeToggleNames}
            showProductTypeToggle={showProductTypeToggle}
          />
        </Grid>
      </Grid>

      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small" stickyHeader>
          <ColGroup />
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Checkbox
                  size="small"
                  checked={checked}
                  disableRipple
                  indeterminate={isAllIndeterminate}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  disabled={onDeleteInProgress}
                />
              </TableCell>
              <TableCell>Period</TableCell>
              <TableCell align="center">Overrides</TableCell>
              <TableCell>PB1</TableCell>
              <TableCell>PB2</TableCell>
              <TableCell>PB3</TableCell>
              <TableCell>PB4</TableCell>
              <TableCell>PB5</TableCell>
              <TableCell>PB6</TableCell>
              <TableCell>PB7</TableCell>
              <TableCell>PB8</TableCell>
              <TableCell>PB9</TableCell>
              <TableCell>PB10</TableCell>
              <TableCell>MaxAvail</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.isEmpty(bidsDisplay) && (
              <TableRow>
                <TableCell align="center" colSpan={NUM_COLUMNS}>
                  <Typography variant="h4">{noBidsMessage}</Typography>
                </TableCell>
              </TableRow>
            )}
            {bidsDisplay.map((bidNode, index) => {
              const { bid, contiguousBids, override } = bidNode
              const uniqueBidId = `${bid.startTime}-${bid.manualBidId}`
              const isItemSelected = _.get(form, ['selected', uniqueBidId], false)
              const expanded = expand[uniqueBidId]
              const overridesExpanded = expandOverrides[uniqueBidId]
              return (
                <React.Fragment key={uniqueBidId}>
                  <ManualBidRow
                    bid={bidNode}
                    onCalcIdSelected={handleIsIdSelected}
                    isItemSelected={isItemSelected}
                    expand={expanded}
                    expandOverrides={overridesExpanded}
                    onExpand={handleExpandRow(uniqueBidId)}
                    onExpandOverrides={handleExpandOverride(uniqueBidId)}
                    onRowClick={handleSelectionClick}
                    disabled={
                      onDeleteInProgress ||
                      (bidsMappedByManualBidIdStartTime.isLoading && !_.isEmpty(selectedBidsToDelete))
                    }
                  />
                  {override && (
                    <OverrideRows
                      bids={override}
                      expand={expandOverrides[uniqueBidId]}
                      form={form}
                      onRowClick={handleSelectionClick}
                    />
                  )}
                  {contiguousBids && (
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={NUM_COLUMNS}>
                        {expand[uniqueBidId] && (
                          <Table className={classes.table} size="small" aria-label="bid details">
                            <ColGroup />
                            <TableBody>
                              {contiguousBids.map(childBidNode => {
                                const uniqueContiguousBidId = `${childBidNode.bid.startTime}-${childBidNode.bid.manualBidId}`
                                const isItemSelected = _.get(form, ['selected', uniqueContiguousBidId], false)
                                return (
                                  <React.Fragment key={uniqueContiguousBidId}>
                                    <ManualBidRow
                                      className={classes.contiguousBidRow}
                                      key={uniqueContiguousBidId}
                                      bid={childBidNode}
                                      onCalcIdSelected={handleIsIdSelected}
                                      isItemSelected={isItemSelected}
                                      expandOverrides={expandOverrides[uniqueContiguousBidId]}
                                      onExpandOverrides={handleExpandOverride(uniqueContiguousBidId)}
                                      onRowClick={handleSelectionClick}
                                      disabled={onDeleteInProgress}
                                    />
                                    {childBidNode.override && (
                                      <OverrideRows
                                        bids={childBidNode.override}
                                        expand={expandOverrides[uniqueContiguousBidId]}
                                        form={form}
                                        onRowClick={handleSelectionClick}
                                      />
                                    )}
                                  </React.Fragment>
                                )
                              })}
                            </TableBody>
                          </Table>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.footer} pr={2} pl={2}>
        <Box display="flex" flexDirection="row-reverse" className={classes.footerGrid}>
          {!disableSubmit && (
            <Button onClick={handleSubmit} disabled={disableSubmit || onDeleteInProgress}>
              delete
            </Button>
          )}
          <Button variant="secondary" onClick={handleCloseClick(disableSubmit)} disabled={onDeleteInProgress}>
            {disableSubmit ? 'close' : 'cancel'}
          </Button>
        </Box>
      </Box>
    </Card>
  )
})

const ColGroup = props => {
  const classes = useStyles()
  return (
    <colgroup>
      <col className={classes.expandCol} />
      <col className={classes.selectCol} />
      <col className={classes.startTimeCol} />
      <col className={classes.overrideCol} />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col />
      <col className={classes.maxAvailCol} />
      <col className={classes.userCol} />
      <col className={classes.reasonCol} />
    </colgroup>
  )
}

const ManualBidRow = React.memo(props => {
  const {
    bid: bidProp,
    className: classNameProp,
    expand = false,
    expandOverrides = false,
    isItemSelected,
    onExpand = () => {},
    onExpandOverrides = () => {},
    onRowClick = () => {},
    onCalcIdSelected = () => {},
    disabled,
  } = props
  const classes = useStyles()
  const { bid, contiguousBids, override, overridden } = bidProp
  const uniqueBidId = getBidId(bid)

  const VARIANTS = {
    HAS_NO_CHILD: 'has_no_child',
    HAS_OVERRIDES: 'has_overrides',
    HAS_CONTIGUOUS: 'has_contiguous',
    HAS_BOTH: 'has_both',
    OVERRIDDEN: 'overridden',
  }

  const handleExpandClick = e => {
    e.stopPropagation()
    if (_.isFunction(onExpand)) {
      onExpand(e)
    }
  }

  const handleExpandOverrides = e => {
    e.stopPropagation()
    if (_.isFunction(onExpandOverrides)) {
      onExpandOverrides(e)
    }
  }

  const hasContiguousBids = !_.isEmpty(contiguousBids)
  const hasOverrides = !_.isEmpty(override)
  const startTime = moment(bid.startTime).format(TIME_FORMAT)
  const endTime =
    hasContiguousBids && !expand && !expandOverrides
      ? moment(_.last(contiguousBids).bid.endTime).format(TIME_FORMAT)
      : moment(bid.startTime).add(5, 'minutes').format(TIME_FORMAT)
  const username = bid.user.name
  const usernames = (hasContiguousBids && _.intersection(contiguousBids.map(c => c.bid.user.name))) || ''
  const hasMultipleUserNames = usernames.length > 1
  const usernamesDisplay = hasContiguousBids && hasMultipleUserNames ? `(${usernames.join(') (')})` : _.first(usernames)
  const reasons = (hasContiguousBids && _.intersection(contiguousBids.map(c => c.bid.reason))) || ''
  const hasMultipleReasons = reasons.length > 1
  const reasonsDisplay = hasContiguousBids && hasMultipleReasons ? `(${reasons.join(') (')})` : _.first(reasons)
  const labelId = `delete-manual-bid-checkbox-${uniqueBidId}`

  let variant = null
  if (overridden) {
    variant = VARIANTS.OVERRIDDEN
  } else if (!hasContiguousBids && !hasOverrides) {
    variant = VARIANTS.HAS_NO_CHILD
  } else if (!hasContiguousBids && hasOverrides) {
    variant = VARIANTS.HAS_OVERRIDES
  } else if (hasContiguousBids && !hasOverrides) {
    variant = VARIANTS.HAS_CONTIGUOUS
  } else if (hasContiguousBids && hasOverrides) {
    variant = VARIANTS.HAS_BOTH
  }

  const isOverrideParent = !expandOverrides && hasOverrides
  const isContiguousParent = !expand && hasContiguousBids

  const [managedIds, setManagedIds] = useState()

  const contiguousChildIds = useMemo(() => {
    if (contiguousBids) {
      const getContiguousIds = bids => {
        let result = []
        for (const bid of bids) {
          result.push(getBidId(bid.bid))
          if (_.get(bid, 'contiguousBids.length')) {
            result = [...result, ...getContiguousIds(bid.contiguousBids)]
          }
          if (_.get(bid, 'override.length')) {
            result = [...result, ...getContiguousIds(bid.override)]
          }
        }
        return result
      }
      const ids = getContiguousIds(contiguousBids)
      return ids
    }
  }, [contiguousBids])

  const overrideIds = useMemo(() => {
    return override && override.map(({ bid }) => getBidId(bid))
  }, [override])

  useEffect(() => {
    setManagedIds(() => {
      if (!isContiguousParent && !isOverrideParent) {
        return
      }
      let result = [uniqueBidId]
      if (isContiguousParent && contiguousChildIds) {
        result = [...result, ...contiguousChildIds]
      }
      if (isOverrideParent && overrideIds) {
        result = [...result, ...overrideIds]
      }
      return result
    })
  }, [isOverrideParent, isContiguousParent, uniqueBidId, contiguousChildIds, overrideIds])

  const selectedManagedIdsMean = _.mean(onCalcIdSelected(managedIds))
  const isIndeterminate = (!expand || !expandOverrides) && selectedManagedIdsMean > 0 && selectedManagedIdsMean < 1

  const checked = isIndeterminate ? false : isItemSelected
  const maxAvailMW = _.isNil(bid.maxAvailablePower) ? '-' : convertkWToMW(bid.maxAvailablePower)

  return (
    <TableRow
      hover
      className={clsx(classNameProp, classes.manualBidRow)}
      tabIndex={-1}
      role="checkbox"
      onClick={onRowClick(uniqueBidId, managedIds)}
      aria-checked={isItemSelected}
      selected={isItemSelected}
    >
      <TableCell>
        {hasContiguousBids && (
          <IconButton aria-label="expand row" size="small" onClick={handleExpandClick}>
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox
          size="small"
          checked={checked}
          disableRipple
          indeterminate={isIndeterminate}
          inputProps={{ 'aria-label': labelId }}
          value={uniqueBidId}
          disabled={disabled}
        />
      </TableCell>
      <TableCell id={labelId} scope="row">
        {startTime} - {endTime}
      </TableCell>
      <TableCell align="center">
        {variant === VARIANTS.HAS_BOTH && !expand && '...'}
        {(variant === VARIANTS.HAS_OVERRIDES || (variant === VARIANTS.HAS_BOTH && expand)) && (
          <Button
            variant="text"
            className={classes.overrideButton}
            aria-label="expand override column"
            onClick={handleExpandOverrides}
          >
            {expandOverrides ? 'Hide' : `${override.length}`}
          </Button>
        )}
        {overridden && 'inactive'}
      </TableCell>
      {bid.values.map((value, index) => {
        return <TableCell key={index}>{convertkWToMW(value)}</TableCell>
      })}
      <TableCell>
        <Collapse in={!hasContiguousBids || (hasContiguousBids && expand)} timeout="auto" unmountOnExit>
          <Tooltip title={maxAvailMW}>
            <Box className={classes.maxAvail}>{maxAvailMW}</Box>
          </Tooltip>
        </Collapse>
        {hasContiguousBids && (
          <Collapse in={hasContiguousBids && !expand} timeout="auto" unmountOnExit>
            <Tooltip title={maxAvailMW}>
              <Typography>{maxAvailMW}</Typography>
            </Tooltip>
          </Collapse>
        )}
      </TableCell>
      <TableCell>
        <Collapse in={!hasContiguousBids || (hasContiguousBids && expand)} timeout="auto" unmountOnExit>
          <Tooltip title={username}>
            <Box className={classes.username}>{username}</Box>
          </Tooltip>
        </Collapse>
        {hasContiguousBids && (
          <Collapse in={hasContiguousBids && !expand} timeout="auto" unmountOnExit>
            <Tooltip title={usernamesDisplay}>
              <Typography>{usernamesDisplay}</Typography>
            </Tooltip>
          </Collapse>
        )}
      </TableCell>
      <TableCell>
        <Collapse in={!hasContiguousBids || (hasContiguousBids && expand)} timeout="auto" unmountOnExit>
          <Tooltip title={bid.reason}>
            <Box className={classes.reason}>{bid.reason}</Box>
          </Tooltip>
        </Collapse>
        {hasContiguousBids && (
          <Collapse in={hasContiguousBids && !expand} timeout="auto" unmountOnExit>
            <Tooltip title={reasonsDisplay}>
              <Box className={classes.reason}>
                <Typography>{reasonsDisplay}</Typography>
              </Box>
            </Tooltip>
          </Collapse>
        )}
      </TableCell>
    </TableRow>
  )
})

const OverrideRows = React.memo(props => {
  const classes = useStyles()
  const { bids, onCalcIdSelected, expand, form, onRowClick } = props
  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={NUM_COLUMNS}>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <Table className={classes.expandedTable} size="small" aria-label="bid details">
            <ColGroup />
            <TableBody>
              {bids.map(bid => {
                const uniqueOverrideBidId = `${bid.bid.startTime}-${bid.bid.manualBidId}`
                const isItemSelected = _.get(form, ['selected', uniqueOverrideBidId], false)
                return (
                  <ManualBidRow
                    key={uniqueOverrideBidId}
                    bid={bid}
                    isItemSelected={isItemSelected}
                    onRowClick={onRowClick}
                    onCalcIdSelected={onCalcIdSelected}
                  />
                )
              })}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  )
})

const getBidId = bid => `${bid.startTime}-${bid.manualBidId}`

ManualBidDelete.displayName = DISPLAY_NAME

export default ManualBidDelete
