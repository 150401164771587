import { useEffect, useRef } from 'react'
import moment from 'moment-timezone'

// Simple hook created by Dan Abramov for this purpose.
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay, initialDelay = 0) {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    let intervalId = null
    let timeoutId = null
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(tick, delay)
      }, initialDelay)
      return () => {
        clearTimeout(timeoutId)
        clearInterval(intervalId)
      }
    }
  }, [delay, initialDelay])
}

export function useOnClockTick(callback, clockHand = 'second') {
  const savedCallback = useRef()

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    let tickHandle = null
    const tick = () => {
      const t = moment()
      const waitTime = moment(t).startOf(clockHand).add(1, `${clockHand}s`).valueOf() - t.valueOf()

      tickHandle = setTimeout(() => {
        savedCallback.current()
        tick()
      }, waitTime)
    }

    const time = moment()
    const timeUntilFirst = moment(time).startOf(clockHand).add(1, `${clockHand}s`).valueOf() - time.valueOf()
    const initialTimeoutHandle = setTimeout(() => tick(), timeUntilFirst)

    return () => {
      clearTimeout(initialTimeoutHandle)
      clearTimeout(tickHandle)
    }
  }, [clockHand])
}

const exports = {
  useInterval,
}
export default exports
