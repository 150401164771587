import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function CircleIcon(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <circle cx="3" cy="3" r="3" transform="translate(7,7)" fill={props.htmlColor} />
      <path fill="none" d="M1168 664h16v16h-16z" />
    </SvgIcon>
  )
}
