import _ from 'lodash'
import moment from 'moment-timezone'
import { custom as customTheme } from '../../../theme/theme'
import {
  DATE_FORMAT_DAY_OF_WEEK,
  DATE_FORMAT_FULL_WITH_SEC,
  MINUTES_IN_HOUR,
  TRADING_DAY_START_HOUR,
} from '../../../utility/constants'
import * as defaults from '../../../graphs/utility/defaults'
import { formatYAxis } from '../../../graphs/common'
import { formatAndPadValue, formatValue } from '../../../graphs/utility/breakpoints'

const CanvasJS = require('../../../assets/scripts/canvasjs.min.js')
const { formatNumber } = CanvasJS.Chart ? CanvasJS : window.CanvasJS
const { seriesColors } = customTheme.palette.graphs
const actualTypes = ['ACTUAL']
const forecastTypes = ['Fluence', 'PRE-DISPATCH']
const products = ['Energy']

export const formatTooltip = (forecastStart, colors) => ({ timezone }) => {
  return {
    ...defaults.tooltip,
    contentFormatter: priceForecastContentFormatter(timezone, forecastStart, colors),
  }
}

export const priceForecastContentFormatter = (timezone, forecastStart, colors) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const tradingDateTime =
    time.hours() * MINUTES_IN_HOUR + time.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(time).subtract(1, 'days')
      : time
  const title = `<h3 style="color: white; margin: 0;">${tradingDateTime.format(DATE_FORMAT_FULL_WITH_SEC)}</h3>`
  const subtitle = `<div style='color: white; margin-top: 0; margin-bottom: 5px'>${time.format(
    DATE_FORMAT_DAY_OF_WEEK,
  )}</div>`

  const types = !time.isSameOrAfter(forecastStart) ? actualTypes : forecastTypes

  const productValues = {}
  products.forEach(product => (productValues[product] = types.map(() => null)))

  let seriesOutput = ''
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  visibleSeries.forEach(entry => {
    const seriesName = entry.dataSeries.name
    const displayName = entry.dataSeries.name.split(' ')[0]

    // do not use table tooltip, if only one data point
    if (types.length === 1) {
      const formattedValue = _.isFinite(entry.dataPoint.y)
        ? formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
        : 'No Data'
      seriesOutput =
        `<div><span style='color: #808697'>${displayName}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>` +
        seriesOutput

      // if multiple datapointss, add it to productValues, which will be used to create tooltip table
    } else {
      const formattedValue =
        _.isFinite(entry.dataPoint.y) || !_.isNil(entry.dataPoint.y)
          ? formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
          : 'No Data'
      const typeIndex = types.findIndex(type => seriesName.includes(type))
      productValues[displayName][typeIndex] = formattedValue
    }
  })

  // use productValues to create table if needed
  if (seriesOutput === '') {
    let table = `<table><thead><tr style='color: #808697'><th style="padding-right: 4px"> </th>`
    types.forEach(type => (table += `<th style="padding-right: 4px; font-weight: normal">${type}</th>`))
    table += `</tr></thead><tbody>`

    products.forEach(product => {
      if (!productValues[product].every(value => _.isNil(value))) {
        table += `<tr style='color: ${
          colors[product.toUpperCase()]
        }'><th style='color: #808697; padding-right: 4px; font-weight: normal'>${product}</th>`
        productValues[product].forEach((value, idx) => {
          const color = colors[product.toUpperCase()]
          table += `<th style='color: ${color}; padding-right: 4px; font-weight: normal'>${
            _.isNil(value) ? 'No data' : value
          }</th>`
        })
        table += '</tr>'
      }
    })

    seriesOutput = table + '</tbody></table>'
  }
  return `${title}${subtitle}${seriesOutput}`
}

export const formatDemandSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'demand.striplineOptions', { type: 'line', visible: false }),
    _.get(data, 'demand.energyOptions', { type: 'line', visible: false }),
    _.get(data, 'demand.preMarketEnergyOptions', { type: 'line', visible: false }),
  ]
  return datum
}

export const formatDemandX2Axis = startValue => ({ range }) => {
  // if (_.isNil(range)) {
  //   console.warn('formatDemandX2Axis() range is not well defined')
  //   return [{ ...defaults.x2Axis }]
  // }
  return [
    {
      ...defaults.x2Axis,
      viewportMinimum: range.start,
      viewportMaximum: range.end,
      stripLines: [
        {
          ...defaults.stripLines,
          startValue,
          endValue: range.end,
          color: 'white',
          opacity: 0.04,
        },
      ],
    },
  ]
}

export const formatDemandYAxis = args => {
  return {
    ...formatYAxis(args, ({ bounds }) => {
      return {
        minimum: bounds.minimum - bounds.minimum * 0.1,
        maximum: bounds.maximum + bounds.maximum * 0.1,
      }
    }),
    labelFormatter: formatAndPadValue({ suffix: 'MW', maxLength: 12 }),
    formatValue: formatValue({ suffix: 'MW' }),
  }
}

export const demandLegendItems = [
  {
    id: 'systemLoad',
    name: 'System load',
    color: seriesColors[0],
  },
  {
    id: 'renewableGeneration',
    name: 'Renewable generation',
    color: seriesColors[1],
  },
  {
    id: 'netLoad',
    name: 'Net load',
    color: seriesColors[2],
  },
]

export const formatPricesSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'prices.striplineOptions', { type: 'line', visible: false }),
    _.get(data, 'prices.energyOptions', { type: 'line', visible: false }),
    _.get(data, 'prices.preMarketEnergyOptions', { type: 'line', visible: false }),
  ]
  return datum
}

export const formatPricesYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '/MWh', maxLength: 12 }),
    formatValue: formatValue({ suffix: '/MWh', prefix: '$' }),
  }
}

export const pricesLegendItems = [
  {
    id: 'np15',
    name: 'NP15',
    color: seriesColors[0],
  },
  {
    id: 'sp15',
    name: 'SP15',
    color: seriesColors[1],
  },
]

export const combine = nodes => {
  const keys = _.keys(nodes)
  return _.values(nodes)[0].map((value, index) => {
    let sum = null
    keys.forEach(key => {
      const value = _.get(nodes[key][index], 'y')
      if (_.isFinite(value)) {
        sum += value
      }
    })
    return {
      x: value.x,
      y: sum,
    }
  })
}
