import React from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Grid } from '@material-ui/core'
import { configurationsResource, mlfRangeResource } from '../../redux/features/setting'
import ConfigurationLog from './ConfigurationLog'
import MarketRegistrations from './MarketRegistrations'
import MLFCardBESS from './MLFCardBESS'
import MLFCard from './MLFCard'

const DISPLAY_NAME = 'Registrations'

function Registrations(props) {
  const { asset } = props
  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'

  const dispatch = useDispatch()
  const dispatchConfigurationResource = (asset, startTime) =>
    dispatch(configurationsResource.get(asset.assetId, startTime, moment()))

  const dispatchMlfRangeResource = (asset, startTime) =>
    dispatch(mlfRangeResource.get(asset.assetId, startTime, moment()))

  // Update log with latest configuration changes
  const handleSaveRegistrationChange = () => {
    if (commercialOnlineDate) {
      const startTime = moment(commercialOnlineDate, 'YYYY-MM-DD')
      if (startTime.isValid()) {
        dispatchConfigurationResource(asset, startTime, moment())
      } else {
        console.warn(`Asset commercial online date (${commercialOnlineDate}) has unexpected format.`)
      }
    }
  }

  // Update log with latest MLF changes
  const handleMlfIntervalChange = () => {
    if (commercialOnlineDate) {
      const startTime = moment(commercialOnlineDate, 'YYYY-MM-DD')
      if (startTime.isValid()) {
        dispatchMlfRangeResource(asset, startTime, moment())
      } else {
        console.warn(`Asset commercial online date (${commercialOnlineDate}) has unexpected format.`)
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MarketRegistrations asset={asset} onSave={handleSaveRegistrationChange} />
      </Grid>
      <Grid container spacing={0} item xs={12} md={6} lg={3}>
        <Grid item xs={12}>
          {isBattery ? (
            <MLFCardBESS asset={asset} onIntervalUpdate={handleMlfIntervalChange} />
          ) : (
            <MLFCard asset={asset} onIntervalUpdate={handleMlfIntervalChange} />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <ConfigurationLog asset={asset} />
      </Grid>
    </Grid>
  )
}

Registrations.displayName = DISPLAY_NAME

export default Registrations
