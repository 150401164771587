import React from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Grid, IconButton, Typography, Tooltip } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Button, XCircle } from '@fluence/core'
import { formatCalendarDateAsTradingDay } from '../../utility/utility'
import Card from '../Card'
import { DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import HelperTextModal from '../Bid/HelperTextModal'

const DISPLAY_NAME = 'UpcomingChangesCard'

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  normalHeight: {
    height: theme.spacing(32),
  },
  smallHeight: {
    height: theme.spacing(16),
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
  },
  slider: {
    color: '#B0BEC4',
  },
  activeGrid: {
    alignItems: 'flex-end',
    display: 'flex',
  },
  activeBoxLarger: {
    display: 'flex',
    flexBasis: '100%',
    maxWidth: theme.spacing(20),
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
  },
  activeBoxSmaller: {
    display: 'flex',
    flexBasis: '100%',
    maxWidth: theme.spacing(16.25),
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  activeDotBox: {
    width: theme.spacing(2),
    alignSelf: 'center',
    display: 'flex',
  },
  activeItem: {
    padding: theme.spacing(1, 2),
  },
  activeDate: {
    marginRight: theme.spacing(),
    fontSize: 13,
  },
  activeDot: {
    color: theme.palette.primary.main,
    position: 'relative',
    fontSize: 'inherit',
  },
  activeTitle: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: 1,
  },
  upcomingItem: {
    marginRight: theme.spacing(),
    fontSize: 14,
  },
  activeItemDivider: {
    height: 2,
  },
  activeValue: {
    lineHeight: 1.45,
  },
  item: {
    padding: theme.spacing(1, 2),
  },
  iconBox: {
    display: 'flex',
    flex: '0 1 auto',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-0.5),
    height: theme.spacing(2.25),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  fromDot: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  fromDotBox: {
    display: 'flex',
    flexBasis: '100%',
    maxWidth: theme.spacing(20),
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1),
  },
  dotBox: {
    width: theme.spacing(2),
    alignSelf: 'center',
  },
  dot: {
    color: theme.palette.primary.main,
    position: 'relative',
    fontSize: 'inherit',
  },
  showChildOnHover: {
    '& > div:last-child > div:last-child > div:last-child': {
      visibility: 'hidden',
    },
    '&:hover > div:last-child > div:last-child > div:last-child': {
      visibility: 'visible',
    },
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
}))

function UpcomingChangesCard(props) {
  const {
    activeItem = {
      dotTitle: '',
      title: null,
      value: null,
      time: null,
      showDot: false,
    },
    onActionClick = () => {},
    hideAction = false,
    items = [],
    marketStartHour = 4,
    timezone = 'Etc/GMT-10',
    title = '',
    titleTypographyProps = { variant: 'h3' },
    activeValueTypographyVariant = 'h3',
    isSmallCard = false,
    useInfoModal = false,
    infoModalTitle = false,
    infoModalHelperText = '',
  } = props
  const classes = useStyles()
  const activeRowTitle = _.get(activeItem, 'title', 'ACTIVE SINCE')
  const isSameMomentTime = (firstTime, secondTime) => {
    return moment(firstTime).isSame(moment(secondTime))
  }
  const activeItemConfigs = _.get(activeItem, 'value', null)
  const activeItemConfigLength = _.get(activeItemConfigs, 'length', 0)
  const secondActiveItem = _.get(activeItem, 'value[1]', null)
  const firstActiveItemValue = _.get(activeItem, 'value[0].value', null)
  const secondActiveItemValue = _.get(secondActiveItem, 'value', null)
  const firstActiveTime = _.get(activeItem, 'value[0].time', null)
  const secondActiveTime = _.get(activeItem, 'value[1].time', null)

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: clsx({
            [classes.cardContent]: true,
            [classes.normalHeight]: !isSmallCard,
            [classes.smallHeight]: isSmallCard,
          }),
          header: classes.cardHeader,
        }}
        title={
          <Box display="inline-flex">
            <Box display="flex" flex="0 1 auto">
              <Typography variant="h3">{title}</Typography>
            </Box>
            {useInfoModal && (
              <Box className={classes.iconBox}>
                <HelperTextModal helperText={infoModalHelperText} title={infoModalTitle} />
              </Box>
            )}
          </Box>
        }
        titleTypographyProps={titleTypographyProps}
        action={
          !hideAction && (
            <Button variant="text" onClick={onActionClick}>
              edit
            </Button>
          )
        }
      >
        <Box p={0}>
          <Grid container spacing={0}>
            {!_.isNil(activeItemConfigs) && (
              <Grid container spacing={0} className={classes.activeItem}>
                <Grid container spacing={0} item xs={8} alignItems="flex-start">
                  <Grid container spacing={0} item>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="textSecondary" className={classes.activeTitle}>
                        {activeRowTitle}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.activeDate} variant="body1" component="span" color="textSecondary">
                        {moment(firstActiveTime).isValid()
                          ? formatCalendarDateAsTradingDay(firstActiveTime, timezone, marketStartHour, DATE_FORMAT_DAY)
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body1" component="span" color="textSecondary">
                        {moment(firstActiveTime).isValid()
                          ? moment(firstActiveTime).tz(timezone).format(TIME_FORMAT)
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!isSameMomentTime(firstActiveTime, secondActiveTime) && !_.isNil(secondActiveItem) && (
                    <Grid container spacing={0} item xs={12}>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                          {/* {activeRowTitle} */}-
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          className={classes.activeDate}
                          variant="body1"
                          component="span"
                          color="textSecondary"
                        >
                          {moment(secondActiveTime).isValid()
                            ? formatCalendarDateAsTradingDay(
                                secondActiveTime,
                                timezone,
                                marketStartHour,
                                DATE_FORMAT_DAY,
                              )
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" component="span" color="textSecondary">
                          {moment(secondActiveTime).isValid()
                            ? moment(secondActiveTime).tz(timezone).format(TIME_FORMAT)
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={4} className={classes.activeGrid}>
                  {_.isNil(activeItemConfigs) ? null : (
                    <Box
                      className={clsx({
                        [classes.activeBoxLarger]: activeItemConfigLength > 1,
                        [classes.activeBoxSmaller]: activeItemConfigLength === 1,
                      })}
                    >
                      {/* Case 1: accomodate two rows of values and labels */}
                      {_.isArray(activeItemConfigs) && activeItemConfigLength > 1 ? (
                        <>
                          <Grid container spacing={0} item justify="flex-end">
                            <Box display="flex" justifyContent="flex-end">
                              <Box display="flex" flexWrap="wrap" maxWidth="130px" pl={1} pr={2}>
                                <Box display="flex" flex="1 1 100%">
                                  <Typography
                                    className={clsx(classes.noWrap, classes.activeValue)}
                                    variant={activeValueTypographyVariant}
                                    color="textPrimary"
                                  >
                                    {firstActiveItemValue}
                                  </Typography>
                                </Box>
                                <Box display="flex" flex="1 1 100%">
                                  <Typography variant="body1" component="span" color="textSecondary">
                                    {_.get(activeItem, 'value[0].label', '-')}
                                  </Typography>
                                </Box>
                                {!_.isNil(secondActiveItem) && (
                                  <Box display="flex" flex="1 1 100%">
                                    <Typography
                                      className={clsx(classes.noWrap, classes.activeValue)}
                                      variant={activeValueTypographyVariant}
                                      color="textPrimary"
                                    >
                                      {secondActiveItemValue}
                                    </Typography>
                                  </Box>
                                )}
                                {!_.isNil(secondActiveItem) && (
                                  <Box display="flex" flex="1 1 100%">
                                    <Typography variant="body1" component="span" color="textSecondary">
                                      {_.get(activeItem, 'value[1].label', '-')}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        // Case 2: This is rendered when there is only one value to display in activeItemConfigs. Does not accomodate any labels
                        <>
                          <Grid container item xs={10}>
                            <Box className={classes.activeDotBox}>
                              {_.get(activeItem, 'showDot') && (
                                <Tooltip title={_.get(activeItem, 'dotTitle')}>
                                  <FiberManualRecordIcon className={classes.activeDot} />
                                </Tooltip>
                              )}
                            </Box>
                            <Box display="flex" flexBasis="100%" justifyContent="flex-start">
                              <Typography
                                className={clsx(classes.noWrap, classes.activeValue)}
                                variant={activeValueTypographyVariant}
                                color="textPrimary"
                              >
                                {firstActiveItemValue}
                              </Typography>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}

            {!_.isEmpty(items) && items.length > 0 && (
              <Grid item xs={12}>
                <Divider variant="fullWidth" className={classes.activeItemDivider} />
              </Grid>
            )}

            {!_.isEmpty(items) &&
              items.map(
                (
                  {
                    deleteTitle = 'Remove scheduled change',
                    id = null,
                    onDelete,
                    meta,
                    showDelete = false,
                    time = null,
                    value = null,
                    valueLabels = null,
                  },
                  key,
                  items,
                ) => {
                  const handleDelete = () => {
                    if (_.isFunction(onDelete)) {
                      onDelete(meta)
                    }
                  }
                  const valueArrayLength = _.size(value)
                  const replaceNilWithHyphen = inputValue => (_.isNil(inputValue) ? '-' : inputValue)
                  return (
                    <React.Fragment key={id}>
                      <Grid container spacing={0} item xs={12} className={clsx(classes.item, classes.showChildOnHover)}>
                        <Grid container spacing={0} item xs={8}>
                          <Grid item xs={7}>
                            <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                              {moment(time).isValid() &&
                                formatCalendarDateAsTradingDay(time, timezone, marketStartHour, DATE_FORMAT_DAY)}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                              {moment(time).isValid() && moment(time).tz(timezone).format(TIME_FORMAT)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={0} justify="flex-end">
                          <Grid item xs={10}>
                            <Box display="flex">
                              <Box display="flex" flexWrap="wrap" maxWidth="130px" pl={1}>
                                <Box display="flex" flex="1 1 100%" pl={1}>
                                  <Typography
                                    className={clsx(classes.noWrap, classes.upcomingItem)}
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {replaceNilWithHyphen(_.get(value, 0, ''))}
                                  </Typography>
                                </Box>
                                <Box display="flex" flex="1 1 100%" pl={1}>
                                  <Typography
                                    className={clsx(classes.noWrap, classes.upcomingItem)}
                                    variant="body1"
                                    color="textSecondary"
                                  >
                                    {_.get(valueLabels, 0, '')}
                                  </Typography>
                                </Box>
                                {valueArrayLength > 1 && (
                                  <Box display="flex" flex="1 1 100%" pl={1}>
                                    <Typography
                                      className={clsx(classes.noWrap, classes.upcomingItem)}
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      {replaceNilWithHyphen(_.get(value, 1, '-'))}
                                    </Typography>
                                  </Box>
                                )}

                                {valueArrayLength > 1 && (
                                  <Box display="flex" flex="1 1 100%" pl={1}>
                                    <Typography
                                      className={clsx(classes.noWrap, classes.upcomingItem)}
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      {_.get(valueLabels, 1, '')}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box display="flex" height="100%" alignItems="center">
                              <Box
                                display="flex"
                                flexBasis="100%"
                                height="20px"
                                maxWidth="150px"
                                justifyContent="flex-end"
                              >
                                {showDelete && (
                                  <Box display="flex" flex="0 1 auto" className={classes.deleteBox}>
                                    <IconButton aria-label={deleteTitle} size="small" onClick={handleDelete}>
                                      <XCircle color="primary" fontSize="small" />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {key !== items.length - 1 && (
                        <Grid item xs={12}>
                          <Divider variant="middle" />
                        </Grid>
                      )}
                    </React.Fragment>
                  )
                },
              )}
          </Grid>
        </Box>
      </Card>
    </>
  )
}

UpcomingChangesCard.displayName = DISPLAY_NAME

export default UpcomingChangesCard
