import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Modal, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import { Button } from '@fluence/core'
import bidDistributionCurve from '../../assets/images/bid-dist-curve.svg'

const HEADER_HEIGHT = 56
const FOOTER_HEIGHT = 64

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(HEADER_HEIGHT),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  body: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + theme.spacing(8)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1, 2, 6),
  },
  paper: {
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  foot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: FOOTER_HEIGHT,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3.5),
    right: theme.spacing(-0.75),
  },
  primaryBtn: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1.75),
  },
}))

function BiddingRiskAppetiteInfo(props) {
  const { open = false, onClose } = props
  const classes = useStyles()

  const handleClose = event => {
    onClose(event)
  }

  const rootClasses = clsx(classes.root, classes.paper)

  return (
    <Modal aria-labelledby="appetite-info-title" aria-describedby="appetite-body" open={open} onClose={onClose}>
      <div className={rootClasses}>
        <div className={classes.head}>
          <Box flex="0 1 auto">
            <Typography id="appetite-info-title" variant="h2" className={classes.title}>
              Bidding risk appetite
            </Typography>
          </Box>
          <Box flex="0 1 auto">
            <IconButton className={classes.closeBtn} size="small" onClick={handleClose}>
              <Clear fontSize="small" />
            </IconButton>
          </Box>
        </div>

        <div id="appetite-body" className={classes.body}>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Configure our optimization based on your desired bid distribution across forecasted price bands. The
              specified distribution for each product influences the bidding behavior within the asset’s specified
              market registrations and operational constraints.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h6" className={classes.title}>
              Bid distribution curve
            </Typography>
          </Box>
          <Box pl={2} mb={3}>
            <img src={bidDistributionCurve} alt="Bid distribution curve" style={{ width: '100%' }} />
          </Box>
          <Box mb={1}>
            <Typography variant="h6" className={classes.title}>
              High risk value
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              A higher risk value will distribute your bids closer to the forecasted price values. This equates to Price
              Maker behavior and has a high possible reward in the market.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h6" className={classes.title}>
              Low risk value
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              A lower risk value will distribute your bids closer to the opportunity price value. This equates to Price
              Taker behavior and has a more stable, yet lower, possible market reward.
            </Typography>
          </Box>
        </div>

        <div className={classes.foot}>
          <Button variant="primary" className={classes.primaryBtn} onClick={handleClose}>
            close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

BiddingRiskAppetiteInfo.displayName = 'BiddingRiskAppetiteInfo'

export default BiddingRiskAppetiteInfo
