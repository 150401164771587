import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import clsx from 'clsx'
import {
  makeStyles,
  TableCell,
  TextField,
  Box,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  findProduct,
  getFormattedPriceBands,
  getIndex,
  getSortedProductNames,
  getTradingStart,
  SETTLEMENT_INTERVAL_MINS,
} from '../../utility/utility'
import {
  removeNSBESSHiddenProducts,
  getFirstActiveProductName,
  getFirstActiveProductTypeName,
  isAssetNonScheduledBattery,
} from '../../utility/asset-utils'
import { PRODUCT_FCAS } from '../../pages/Bids'
import { MARKET_PRICE_ENERGY_MINIMUM, MARKET_PRICE_FCAS_MINIMUM } from '../../utility/constants'
import { getPriceBands, updatePriceBands } from '../../redux/features/bid'
import { globalAppSelectors } from '../../redux/features/app'
import Card from '../Card'
import ProductToggle from '../toggles/ProductToggle'
import ProductTypeToggle from '../toggles/ProductTypeToggle'
import KeyboardDatePicker from '../KeyboardDatePicker'
import InputNumberFormat from '../InputNumberFormat'

const DISPLAY_NAME = 'EditPriceBands'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: `calc(100vw - ${theme.spacing(4)}px)`,
      position: 'absolute',
      top: '20%',
      right: 0,
      left: 0,
      maxWidth: 1100,
    },
    card: {
      maxHeight: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
    },
    modalHeader: {
      marginTop: theme.spacing(0.5),
      lineHeight: '28px',
    },
    text: {
      marginBottom: theme.spacing(2),
    },
    box: {
      paddingLeft: 0,
      marginRight: theme.spacing(1),
    },
    smallLabel: {
      fontSize: theme.typography.pxToRem(10),
      marginLeft: theme.spacing(0.375),
      marginBottom: theme.spacing(0.375),
      color: theme.custom.palette.graphs.labelColor,
    },
    picker: {
      marginRight: theme.spacing(7.5),
      width: theme.spacing(31),
    },
    gridContainer: {
      marginBottom: theme.spacing(2),
    },
    datePicker: {
      maxWidth: theme.spacing(31),
    },
    pickerInputComp: {
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(6),
    },
    tableGrid: {
      overflow: 'auto',
    },
    table: {
      tableLayout: 'fixed',
      minWidth: theme.spacing(108),
    },
    rowFields: {
      paddingRight: 0,
      borderBottom: 'none',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    number: {
      width: '100%',
      backgroundColor: '#282d36',
      color: '#FFF',
    },
    numberInput: {
      marginLeft: theme.spacing(1),
    },
    checkBox: {
      height: theme.spacing(4.5),
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
    footer: {
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(2),
      flex: '1 0 auto',
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row-reverse',
      borderTop: `2px solid ${theme.palette.background.default}`,
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    errorMessage: {
      marginLeft: theme.spacing(1),
      height: theme.spacing(2),
    },
    message: {
      fontSize: theme.typography.subtitle1.fontSize,
      color: theme.palette.error.main,
    },
  }),
  { name: DISPLAY_NAME },
)

const EditPriceBands = React.forwardRef(function EditPriceBands(props, ref) {
  const classes = useStyles()
  const {
    asset,
    productTypes,
    productDisplayNames,
    productNames,
    marketTimezone,
    marketStartHour,
    initialSelectedDate,
    onClose,
    showProductTypeToggle,
    disabledProductTypeToggleNames,
  } = props
  const {
    allActivePriceBands,
    allSelectedPriceBands,
    getActivePriceBands,
    getMarketPriceCap,
    getSelectedPriceBands,
    updatePriceBands,
  } = props
  const { notifySuccess, notifyError } = useNotifier()

  const [collapsed, setCollapsed] = useState(true)
  const [modalProductType, setModalProductType] = useState(getFirstActiveProductTypeName(asset))
  const allProducts = _.get(asset, 'products', [])
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})

  const timeNow = moment().tz(marketTimezone)
  const startOfCurrentDate = getTradingStart(asset.market, timeNow)

  const isBeforeNoon = timeNow.isBefore(moment(startOfCurrentDate).add(8, 'h'))
  const nextTradingDay = moment(startOfCurrentDate).add(1, 'd')
  const nextNextTradingDay = moment(nextTradingDay).add(1, 'd')
  const beforeNoonSelection = initialSelectedDate.isBefore(nextTradingDay) ? nextTradingDay : initialSelectedDate
  const afterNoonSelection = initialSelectedDate.isBefore(nextNextTradingDay) ? nextNextTradingDay : initialSelectedDate
  const datePickerInitialSelectedDate = isBeforeNoon ? beforeNoonSelection : afterNoonSelection

  const [selectedDate, setSelectedDate] = useState(moment(datePickerInitialSelectedDate))
  const startOfSelectedDate = getTradingStart(asset.market, moment(selectedDate))

  const [modalProductName, setModalProductName] = useState(
    getFirstActiveProductName(asset, modalProductType, startOfSelectedDate),
  )
  const productIndex = allProducts.findIndex(
    product => product.name === modalProductName && product.data.type === modalProductType,
  )
  const activePriceBands = _.get(allActivePriceBands, [productIndex, 'data', 'values'], {})
  const selectedDatePriceBands = _.get(allSelectedPriceBands, [productIndex, 'data', 'values'], {})
  const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
  const emptyPriceBands = priceBandNames.reduce((accumulator, name) => {
    accumulator[name] = null
    return accumulator
  }, {})

  const [newPriceBands, setNewPriceBands] = useState(emptyPriceBands)
  const [selectedProduct, setSelectedProduct] = useState(asset.products[productIndex])
  const [ifFillCurrentValues, setIfFillCurrentValues] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const minDatePickerDate = isBeforeNoon
    ? moment(startOfCurrentDate).add(1, 'd')
    : moment(startOfCurrentDate).add(2, 'd')

  const startOfCurrentDateValue = startOfCurrentDate.valueOf()
  useEffect(() => {
    if (!_.isNil(asset)) {
      getActivePriceBands(asset, moment(startOfCurrentDateValue))
    }
  }, [asset, getActivePriceBands, startOfCurrentDateValue])

  const selectedDateValue = selectedDate.valueOf()
  useEffect(() => {
    if (!_.isNil(asset)) {
      getSelectedPriceBands(asset, moment(selectedDateValue))
    }
  }, [asset, getSelectedPriceBands, selectedDateValue])

  const onDateChange = (event, date) => {
    setErrorMessage('')
    date = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    if (moment.duration(moment(date).diff(startOfCurrentDate)).asDays() === 0) {
      const currentIndex = getIndex(moment(), date, moment(date).add(1, 'd'), SETTLEMENT_INTERVAL_MINS)
      date = moment(date).add(currentIndex * SETTLEMENT_INTERVAL_MINS, 'minutes')
    }
    setSelectedDate(date)
  }

  const handleChangeProductName = (event, productName, productType) => {
    const type = _.isNil(productType) ? modalProductType : productType
    if (!_.isNil(productName)) {
      const selectedProduct = findProduct(asset, productName, type)
      setModalProductName(productName)
      setSelectedProduct(selectedProduct)
      if (ifFillCurrentValues) {
        const productIndex = allProducts.findIndex(
          product => product.name === productName && product.data.type === type,
        )
        const newPriceBands = _.get(allActivePriceBands, [productIndex, 'data', 'values'], {})
        setNewPriceBands(newPriceBands)
      }
    }
  }

  const handleChangeProductType = (event, productType) => {
    if (!_.isNil(productType)) {
      // ensure disabled product is not selected
      let nextProductName = selectedProduct.name
      const disabledProducts = productNames.filter(
        productName => asset.data.configuration[`${productName}_${productType}`] === 0,
      )
      if (disabledProducts.includes(selectedProduct.name)) {
        nextProductName = getSortedProductNames(productNames).find(product => !disabledProducts.includes(product))
        setModalProductType(productType)
        handleChangeProductName(event, nextProductName, productType)
      } else {
        const selectedProduct = findProduct(asset, modalProductName, productType)
        setModalProductType(productType)
        setSelectedProduct(selectedProduct)

        if (ifFillCurrentValues) {
          const productIndex = allProducts.findIndex(
            product => product.name === modalProductName && product.data.type === productType,
          )
          const newPriceBands = _.get(allActivePriceBands, [productIndex, 'data', 'values'], {})
          setNewPriceBands(newPriceBands)
        }
      }
    }
  }

  const handleChangeCheckbox = event => {
    const checked = _.get(event, 'target.checked')
    setIfFillCurrentValues(checked)
    const displayPriceBands = checked ? activePriceBands : emptyPriceBands
    setNewPriceBands(displayPriceBands)
    setErrorMessage('')
  }

  const disabledProductNames = productNames.filter(
    productName => _.get(asset, ['data', 'configuration', `${productName}_${modalProductType}`]) === 0,
  )

  const expandFCAS = (event, productName) => {
    if (productName === PRODUCT_FCAS) {
      setCollapsed(false)
    }
  }

  const getPriceBandNamesHeader = (asset, classNameProp, classNamePropSecond) => {
    const priceBandNames = _.get(asset, 'market.data.price_band_display_names', [])
    return priceBandNames.map((band, index) => (
      <TableCell className={clsx(classNameProp, classNamePropSecond)} key={index}>
        {' '}
        {band}
      </TableCell>
    ))
  }
  const PriceBandFirstHeader = React.memo(function PriceBandFirstHeader() {
    return <TableRow>{getPriceBandNamesHeader(asset)}</TableRow>
  })

  const getPriceBandValuesHeader = (asset, priceBands, thStyle, classNameProp, classNamePropSecond) => {
    const formattedValues = getFormattedPriceBands(
      priceBandNames.map(name => priceBands[name]),
      false,
    )
    return priceBandNames.map((name, index) => (
      <TableCell
        className={clsx(classNameProp, classNamePropSecond)}
        key={index}
        style={Object.assign({ color: '#FFFFFF' }, thStyle || {})}
      >
        {formattedValues[index]}
      </TableCell>
    ))
  }
  const PriceBandSecondHeader = React.memo(function PriceBandSecondHeader() {
    return <TableRow>{getPriceBandValuesHeader(asset, activePriceBands, { border: '0px' })}</TableRow>
  })

  const handlePbOnChange = name => event => {
    const eventValue = _.get(event, 'target.value')
    const value = _.isEmpty(eventValue) ? null : parseFloat(eventValue)
    setErrorMessage('')
    setNewPriceBands(prev => {
      return {
        ...prev,
        [name]: value,
      }
    })
  }

  const getErrorMessage = priceBands => {
    const ifNotAscending = priceBandNames.reduce((accumulator, name, index) => {
      return accumulator || priceBands[priceBandNames[index]] >= priceBands[priceBandNames[index + 1]]
    }, false)
    const repeatedPbSet = _.isEqual(selectedDatePriceBands, newPriceBands)
    const isEnergy = productIndex === 0 || productIndex === 9

    const MARKET_PRICE_MAXIMUM = getMarketPriceCap(selectedDate)

    if (selectedDate.isBefore(minDatePickerDate)) {
      return 'Invalid date'
    } else if (isEnergy && _.get(priceBands, 'PRICEBAND1') < MARKET_PRICE_ENERGY_MINIMUM) {
      return `PB1 cannot be less than ${MARKET_PRICE_ENERGY_MINIMUM}`
    } else if (!isEnergy && _.get(priceBands, 'PRICEBAND1') < MARKET_PRICE_FCAS_MINIMUM) {
      return `PB1 cannot be less than ${MARKET_PRICE_FCAS_MINIMUM}`
    } else if (_.get(priceBands, 'PRICEBAND10') > MARKET_PRICE_MAXIMUM) {
      return `PB10 cannot be greater than ${numeral(MARKET_PRICE_MAXIMUM).format('$0,0[.]00')}`
    } else if (_.size(priceBands) !== 10) {
      return 'Please enter 10 numbers'
    } else if (ifNotAscending) {
      return 'Values must be in numerical order'
    } else if (repeatedPbSet) {
      return 'Values are identical to last scheduled price bands'
    }

    return ''
  }

  const submitNewPriceBands = () => {
    const errorMessage = getErrorMessage(newPriceBands)
    setErrorMessage(errorMessage)

    const newPriceBandsArray = priceBandNames.map(name => newPriceBands[name])
    const pbInput = {
      'assetId': asset.assetId,
      'productId': allProducts[productIndex].productId,
      'startTime': moment(startOfSelectedDate).format(),
      'data': { 'values': newPriceBandsArray },
      'tag': 'price_band',
    }

    if (errorMessage === '') {
      updatePriceBands(pbInput).then(response => {
        if (response.error) {
          notifyError('Something went wrong. Please try again.')
        } else {
          onClose()
          notifySuccess('Price Band change scheduled successfully.')
          getActivePriceBands(asset, initialSelectedDate)
        }
      })
    }
  }

  const text =
    'Price Band changes can only be submitted to AEMO in initial bids. Initial bids are created each day at 12:00 for future trading days. ' +
    'Price Band changes made before 12:00 can be scheduled for the next trading day. Price band changes made after 12:00 must be scheduled for the following trading day (+2 trading days).'

  const disableSubmit = _.some(newPriceBands, value => !_.isNumber(value)) || errorMessage !== ''
  const filteredProductNames = isAssetNonScheduledBattery(asset)
    ? removeNSBESSHiddenProducts(productNames)
    : productNames

  return (
    <div className={classes.root} ref={ref}>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        title={<div className={classes.modalHeader}> Schedule Price Band change </div>}
        titleTypographyProps={{ variant: 'h3' }}
      >
        <Typography className={classes.text}>{text}</Typography>
        <Grid container className={classes.gridContainer} spacing={0} wrap="wrap">
          {showProductTypeToggle && (
            <Grid item>
              <Typography className={classes.smallLabel}>Select Gen vs Load</Typography>
              <Box display="inline-flex" pl={1.5} className={classes.box}>
                <ProductTypeToggle
                  disabledTypes={collapsed ? disabledProductTypeToggleNames : []}
                  selected={modalProductType}
                  productTypes={productTypes}
                  productTypeDisplayNames={productTypeDisplayNames}
                  onChange={(e, type) => handleChangeProductType(e, type)}
                />
              </Box>
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.smallLabel}>Select Product</Typography>
            <Box display="inline-flex" pl={1.5} className={classes.box}>
              <ProductToggle
                asset={asset}
                selectedTime={startOfSelectedDate}
                selected={modalProductName}
                productNames={filteredProductNames}
                productDisplayNames={productDisplayNames}
                onChange={handleChangeProductName}
                disabledProductNames={disabledProductNames}
                onCollapse={expandFCAS}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Box className={classes.picker}>
            <Typography className={classes.smallLabel}>EFFECTIVE TRADING DATE</Typography>
            <KeyboardDatePicker
              timezone={marketTimezone}
              marketStartHour={marketStartHour}
              selectedDate={selectedDate}
              onChange={(event, date) => onDateChange(event, date)}
              minDate={minDatePickerDate}
              inputVariant="standard"
              ariaLabel="change date for manual bid"
              classes={{
                datePicker: classes.datePicker,
                pickerInputComp: classes.pickerInputComp,
              }}
            />
          </Box>
        </Grid>
        <Typography className={classes.smallLabel}>PRICE BAND VALUES (without MLF adjustment)</Typography>
        <div className={classes.tableGrid}>
          <Table className={classes.table} size="small">
            <TableHead>
              <PriceBandFirstHeader />
              <PriceBandSecondHeader />
            </TableHead>
            <TableBody>
              <TableRow>
                {priceBandNames.map(name => {
                  const textValue = newPriceBands[name] === null ? '' : `${newPriceBands[name]}`
                  return (
                    <TableCell key={name} className={classes.rowFields}>
                      <TextField
                        className={classes.number}
                        InputProps={{
                          classes: { input: classes.numberInput },
                          inputComponent: InputNumberFormat,
                          inputProps: { decimalScale: 2 },
                        }}
                        value={textValue}
                        onChange={handlePbOnChange(name)}
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div>
          <Box mt={0.5} className={classes.checkBox}>
            <FormControl>
              <div>
                <FormControlLabel
                  color="primaryText"
                  control={
                    <Checkbox
                      checked={ifFillCurrentValues}
                      onChange={handleChangeCheckbox}
                      value="ifFillCurrentValues"
                      color="primary"
                    />
                  }
                  label={<Typography>Fill Current Values</Typography>}
                />
              </div>
            </FormControl>
          </Box>
        </div>
        <div className={classes.errorMessage}>
          <label className={classes.message}>{errorMessage}</label>
        </div>
      </Card>
      <div className={classes.footer}>
        <div>
          <Button variant="secondary" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            variant="primary"
            disabled={disableSubmit}
            onClick={submitNewPriceBands}
            className={classes.marginLeft}
          >
            SCHEDULE
          </Button>
        </div>
      </div>
    </div>
  )
})

const mapStateToProps = state => {
  const activePriceBands = _.get(state, 'bid.modalActivePriceBands')
  const selectedPriceBands = _.get(state, 'bid.modalSelectedPriceBands')
  const getMarketPriceCap = globalAppSelectors.getMarketPriceCapFn(state)

  return {
    allActivePriceBands: _.get(activePriceBands, 'payload', []),
    allSelectedPriceBands: _.get(selectedPriceBands, 'payload', []),
    getMarketPriceCap,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActivePriceBands: (asset, startTime) => {
      dispatch(getPriceBands('active').get(asset, startTime, null, false))
    },
    getSelectedPriceBands: (asset, startTime) => {
      dispatch(getPriceBands('selected').get(asset, startTime, null, false))
    },
    updatePriceBands: values => dispatch(updatePriceBands.post(values)),
  }
}

EditPriceBands.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(EditPriceBands)
