import _ from 'lodash'
import moment from 'moment-timezone'
import { getMarketStartGivenTimestamp } from './time-utils'
import { INTERVAL_SIZE_MINS } from './constants'

export const showManualBidOnBidsPage = (bid, selectedDate) => {
  const createdOn = moment(_.get(bid, 'createdOn'))
  const timeAfterCreation = moment.duration(moment().diff(createdOn)).asMinutes()
  if (moment(selectedDate).isBefore(moment())) {
    // for current trading day
    const shouldShow = timeAfterCreation > 0 && timeAfterCreation < 5
    return shouldShow
  }

  // for future trading day
  // 12:30pm before the goal trading day is the last chance to create initial bids, otherwise bids get picked up during 12am-4am as rebids
  const initialBidLimitTime = moment(selectedDate).startOf('day').subtract(11, 'h').subtract(30, 'm')
  let disappearTime
  if (createdOn.isBefore(initialBidLimitTime)) {
    const createdOnTradingDayNoon = moment(createdOn).startOf('day').add(12, 'h').add(30, 'm')
    if (createdOn.isBefore(createdOnTradingDayNoon)) {
      disappearTime = createdOnTradingDayNoon
    } else {
      disappearTime = moment(createdOnTradingDayNoon).add(1, 'd')
    }
  } else {
    disappearTime = moment(selectedDate)
  }
  return moment().isBefore(disappearTime)
}

export const getReasonIndex = (bid, selectedDate, bidCreatorStartCutoffSecs, bidCollationInterval) => {
  let effectiveStartIndex
  const createdOn = moment(_.get(bid, 'createdOn'))

  // 5 minute settlement
  const difference = moment.duration(createdOn.diff(moment(selectedDate))).asMinutes()
  const createdIntervalStartIndex = Math.floor(difference / INTERVAL_SIZE_MINS)
  const minsBeforeNextBidInterval = (createdOn.minutes() + createdOn.seconds() / 60) % INTERVAL_SIZE_MINS
  if (
    moment.duration(minsBeforeNextBidInterval, 'minutes').asSeconds() >= bidCreatorStartCutoffSecs &&
    minsBeforeNextBidInterval < INTERVAL_SIZE_MINS
  ) {
    effectiveStartIndex = createdIntervalStartIndex + 2
  } else {
    effectiveStartIndex = createdIntervalStartIndex + 1
  }

  if (moment(selectedDate).isAfter(moment())) {
    return 0
  }
  return effectiveStartIndex
}

export const getCombinedBidsFromBidFilesAndManualBids = (
  lastSubmittedCombinedBids,
  manualBidData,
  selectedDate,
  bidCreatorStartCutoffSecs,
  marketStartHour = 4,
  marketTimezone,
) => {
  if (_.isEmpty(lastSubmittedCombinedBids)) {
    return []
  }
  const { data = [] } = manualBidData
  const currentTradingDay = getMarketStartGivenTimestamp(moment(), marketStartHour, marketTimezone)
  const selectedTradingDay = getMarketStartGivenTimestamp(selectedDate, marketStartHour, marketTimezone)
  const currentDayIsSelected = moment(currentTradingDay).isSame(selectedTradingDay, 'day')

  const sortedManualBids = currentDayIsSelected
    ? data
        .filter(manualBid => {
          const createdOn = moment(_.get(manualBid, 'createdOn'))
          const timeAfterCreation = moment.duration(moment().diff(createdOn)).asMinutes()
          const durationIsWithin5Mins = timeAfterCreation > 0 && timeAfterCreation < 5

          const notDeleted = !manualBid.deleted
          return notDeleted && durationIsWithin5Mins
        })
        .sort((x, y) => {
          return moment(x.createdOn) - moment(y.createdOn)
        }) // ascending order so late ones can override early ones
    : []

  const lastUpdatedOn = moment(_.get(lastSubmittedCombinedBids, 'createdOn'))
  const submittedCombinedBids = []
  lastSubmittedCombinedBids.bids.forEach((bid, index) => {
    submittedCombinedBids[index] = { ...bid, createdOn: lastUpdatedOn.toISOString() }
  })

  if (!_.isNil(sortedManualBids) && !_.isEmpty(sortedManualBids)) {
    // attach the manual bid reason to latest effective interval
    sortedManualBids.forEach(manualBid => {
      let bidIndex =
        moment.duration(moment(manualBid.startTime).diff(moment(selectedDate))).asMinutes() / INTERVAL_SIZE_MINS
      const createdOn = moment(_.get(manualBid, 'createdOn'))

      // Not needed in manual bid modal, should we still keep?
      if (moment(manualBid.startTime).isSameOrBefore(createdOn)) {
        const difference = moment.duration(createdOn.diff(moment(manualBid.startTime))).asMinutes()
        bidIndex += Math.ceil(difference / INTERVAL_SIZE_MINS)
        const minsBeforeNextInterval =
          (createdOn.minutes() + createdOn.seconds() / moment.duration(1, 'minutes').asSeconds()) % INTERVAL_SIZE_MINS
        if (
          moment.duration(minsBeforeNextInterval, 'minutes').asSeconds() >= bidCreatorStartCutoffSecs &&
          minsBeforeNextInterval < INTERVAL_SIZE_MINS
        ) {
          bidIndex++
        }
      }

      // Attach manual bid data to collated bids that will be shown on bids table
      if (!_.isNil(submittedCombinedBids[bidIndex])) {
        const newBid = { ...submittedCombinedBids[bidIndex] }
        const shouldShow = showManualBidOnBidsPage(manualBid, selectedTradingDay)
        if (shouldShow) {
          // Show manual bid if (1) it's scheduled for current day
          // and it's submitted < 5 minutes ago
          // OR (2) it's scheduled for future trading days
          newBid.values = manualBid.values
          newBid.maxAvailablePower = manualBid.maxAvailablePower
          newBid.createdOn = manualBid.createdOn
          newBid.isManualBid = true
          newBid.manualBidReason = manualBid.reason
        } else if (
          !_.isNil(submittedCombinedBids[bidIndex].values) &&
          !_.isNil(manualBid.values) &&
          submittedCombinedBids[bidIndex].values.every((priceBand, i) => manualBid.values[i] === priceBand) &&
          manualBid.values.every((priceBand, i) => submittedCombinedBids[bidIndex].values[i] === priceBand)
        ) {
          // Show a past manual bid if the manual bid was successfully submitted
          // by verifying if the manual bid's price band array === the actually submitted price band array
          newBid.isManualBid = true
          newBid.manualBidReason = manualBid.reason
        }
        submittedCombinedBids[bidIndex] = newBid
      }
    })
  }
  return submittedCombinedBids
}

export const getValuesForBidIndex = (bidIndex, bids, manualBids = [], selectedDate) => {
  const sortedManualBids = manualBids
    .filter(manualBid => {
      const manualBidIndex =
        moment.duration(moment(manualBid.startTime).diff(moment(selectedDate))).asMinutes() / INTERVAL_SIZE_MINS

      const createdOn = moment(_.get(manualBid, 'createdOn'))
      const timeAfterCreation = moment.duration(moment().diff(createdOn)).asMinutes()
      const durationIsWithin5Mins = timeAfterCreation > 0 && timeAfterCreation < 5

      const notDeleted = !manualBid.deleted
      return notDeleted && durationIsWithin5Mins && manualBidIndex === bidIndex
    })
    .sort((x, y) => {
      return moment(x.createdOn) - moment(y.createdOn)
    }) // ascending order so late ones can override early ones

  const bid = _.get(bids, bidIndex)
  if (_.isEmpty(sortedManualBids)) {
    return _.get(bid, 'values')
  }

  sortedManualBids.forEach(manualBid => {
    if (showManualBidOnBidsPage(manualBid, selectedDate)) {
      bid.values = manualBid.values
    }
  })
  return bid.values
}

export const getCollatedBidsByProductId = (
  submittedBids,
  manualBids,
  productIds,
  selectedDate,
  bidCreatorStartCutoffSecs,
  marketStartHour,
  marketTimezone,
) => {
  const result = {}
  for (const productId of productIds) {
    const submittedBidFile = _.get(submittedBids, [])
    const lastSubmittedCombinedBids = _.get(submittedBidFile)
    const manualBidData = _.get()
    const bidFile = getCombinedBidsFromBidFilesAndManualBids(
      lastSubmittedCombinedBids,
      manualBidData,
      selectedDate,
      bidCreatorStartCutoffSecs,
      marketStartHour,
      marketTimezone,
    )
    result[productId] = bidFile
  }
}

export const getMaxAvailFromAssetProductId = (asset, productId) => {
  if (_.isNil(asset) || !_.isNumber(productId)) {
    return
  }
  const product = _.get(asset, 'products', []).find(prod => prod.productId === productId)
  const productName = _.get(product, 'name')
  const productType = _.get(product, 'data.type')
  return getMaxAvailFromAsset(asset, productName, productType)
}

export const getMaxAvailFromAsset = (asset, productName, productType) => {
  return _.get(asset, `data.configuration[${productName}_${productType}]`)
}

/**
 *
 * @param {string} productType e.g. "GEN" | "LOAD"
 * @param {string} productName e.g. "ENERGY" | "RAISEREG"
 * @param {string[]} priceBandNames e.g. ['PRICEBAND1', 'PRICEBAND2', ...]
 * @param {number} registeredMaxAvail The maximum availability
 * @returns {Object} An object with price band names as keys and price band allocations as values
 */
export const getDefaultZeroBidValues = (productType, productName, priceBandNames, registeredMaxAvail) => {
  const defaultAllocationPbForGen = 'PRICEBAND10'
  const defaultAllocationPbForLoadEnergy = 'PRICEBAND1'
  const defaultAllocationPbForLoadFcas = 'PRICEBAND10'

  const defaultZeroBidValues = _.mapValues(_.keyBy(priceBandNames), () => 0)
  if (productType === 'GEN') {
    defaultZeroBidValues[defaultAllocationPbForGen] = registeredMaxAvail
  } else if (productType === 'LOAD') {
    if (productName === 'ENERGY') {
      defaultZeroBidValues[defaultAllocationPbForLoadEnergy] = registeredMaxAvail
    } else {
      defaultZeroBidValues[defaultAllocationPbForLoadFcas] = registeredMaxAvail
    }
  }

  return defaultZeroBidValues
}
