import moment from 'moment-timezone'
import { THROUGHPUT_TAG } from '../../../utility/constants'
import { API } from '../../api'
import TYPES from './types'

export const createRegulationThroughput = (assetId, productId, startTime, value) => {
  const body = {
    assetId,
    productId,
    tag: THROUGHPUT_TAG,
    startTime: moment(startTime).toISOString(),
    data: { value },
    deleted: false,
  }
  return API.marketAssetProductData.createAssetProductData({
    types: TYPES.REGULATION_THROUGHPUT_CREATE_REQUEST,
    body,
    cache: false,
    meta: { updatedOn: moment() },
  })
}

export const deleteRegulationThroughput = assetProductDataId => {
  const queryParams = {
    assetProductDataId,
  }
  return API.marketAssetProductData.deleteAssetProductData({
    queryParams,
    types: TYPES.REGULATION_THROUGHPUT_DELETE_REQUEST,
  })
}

export const getRegulationThroughputLatest = (assetId, productId, startTime, deleted = false) => {
  const queryParams = {
    tag: THROUGHPUT_TAG,
    startTime: moment(startTime).toISOString(),
    deleted,
  }
  return API.marketAssetProductData.getLatestAssetProductData({
    pathParams: { assetId, productId },

    queryParams,
    types: TYPES.REGULATION_THROUGHPUT_LATEST_REQUEST,
    cache: false,
    meta: { assetId, productId, updatedOn: moment() },
  })
}

export const getRegulationThroughputRange = (assetId, productId, startTime, deleted = false) => {
  const queryParams = {
    tag: THROUGHPUT_TAG,
    startTime: moment(startTime).toISOString(),
    endTime: moment(startTime).add(1, 'years').toISOString(),
    deleted,
  }
  return API.marketAssetProductData.getAssetProductDataRange({
    pathParams: { assetId, productId },

    queryParams,
    types: TYPES.REGULATION_THROUGHPUT_RANGE_REQUEST,
    cache: false,
    meta: { assetId, productId, updatedOn: moment() },
  })
}

const operations = {
  createRegulationThroughput,
  deleteRegulationThroughput,
  getRegulationThroughputLatest,
  getRegulationThroughputRange,
}

export default operations
