import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment-timezone'
import { convertMWToKW } from '../../../utility/utility'

const selectEnergyPriceCaps = state => _.get(state, 'globalApp.marketData.max_energy_price')
const getMarketPriceCapFn = createSelector([selectEnergyPriceCaps], (energyPriceCaps = {}) => {
  return datetime => {
    const selectedDate = moment(datetime)
    if (!selectedDate.isValid()) {
      return
    }
    let res = { date: '2016-06-30T18:00:00Z', value: 14.0 }
    for (const [mcpDate, value] of Object.entries(energyPriceCaps)) {
      if (moment(mcpDate).isSameOrBefore(moment(datetime)) && moment(mcpDate).isAfter(moment(res.date))) {
        res = { date: mcpDate, value: convertMWToKW(value) }
      }
    }
    return res.value
  }
})

const selectSettlementIntervalByTime = state => _.get(state, 'globalApp.marketData.settlement_interval_by_time')

const getSettlementIntervalFn = createSelector([selectSettlementIntervalByTime], (settlementIntervalsByTime = {}) => {
  return datetime => {
    const selectedDate = moment(datetime)
    if (!selectedDate.isValid()) {
      return
    }
    let res = { date: '2000-01-01T18:00:00Z', value: 30 }
    for (const [date, value] of Object.entries(settlementIntervalsByTime)) {
      if (moment(date).isSameOrBefore(moment(datetime)) && moment(date).isAfter(moment(res.date))) {
        res = { date, value: value }
      }
    }
    return res.value
  }
})

const selectors = {
  getSettlementIntervalFn,
  getMarketPriceCapFn,
}

export default selectors
