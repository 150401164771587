import { combineReducers } from "redux"
import { makeResourceReducer, ResourceAction } from "../api"

export const getMarketScenarioForDate = new ResourceAction({
  type: "MARKET_SCENARIO",
  config: (assetId, scenarioType, startTime) => {
    return {
      endpoint: `/market/scenario/asset/${assetId}/revenue/startTime?scenarioType=${scenarioType}&startTime=${encodeURIComponent(startTime.toISOString())}`,
      meta: {
        assetId,
        startTime
      }
    }
  }
})

export const getMarketScenarioForAssetsAndMonths = new ResourceAction({
  type: "MARKET_SCENARIO_MONTH",
  config: (assetIds, scenarioType, yearMonths) => {
    const assetIdQuery = assetIds.reduce((acc, assetId) => acc + "&assetIds=" + assetId, "")
    const yearMonthsQuery = yearMonths.reduce((acc, yearMonth) => acc + "&yearMonths=" + yearMonth, "")
    return {
      endpoint: `/market/scenario/revenue/months?${assetIdQuery}&scenarioType=${scenarioType}${yearMonthsQuery}`,
      meta: {
        assetIds,
        yearMonths
      }
    }
  }
})

export const marketScenarioDate = makeResourceReducer(getMarketScenarioForDate)
export const marketScenarioMonths = makeResourceReducer(getMarketScenarioForAssetsAndMonths)

export default combineReducers({
  marketScenarioDate,
  marketScenarioMonths
})
