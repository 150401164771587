import { combineReducers } from 'redux'
import { ResourceAction } from '../api'
import { financeSettlementDayGroup } from '../../utility/utility'
import { getIntervals, intervalStateCreator } from './interval'

const settlementIntervalAction = new ResourceAction({
  type: 'FINANCE_SETTLEMENT_INTERVALS',
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + '&productId=' + productId, '')
    return {
      endpoint: `/market/frontend/asset/${intervalConfig.assetId}/products/interval/settlement/${intervalConfig.meta.group === financeSettlementDayGroup ? 'days' : 'month'}?yearMonth=${intervalTimeConfig.yearMonth}${productsQuery}`,
      meta: {
        intervalConfig,
        intervalTimeConfig
      }
    }
  }
})

export const getSettlementIntervals = getIntervals(settlementIntervalAction, 'finance', '00:05:00', '01:00:00')
const resources = [settlementIntervalAction]
const intervals = intervalStateCreator(resources)

export default combineReducers({
  intervals
})

