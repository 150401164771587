export const yearFormat = 'YYYY'
export const shortMonthFormat = 'MMM'
export const yearMonthFormat = `${shortMonthFormat} ${yearFormat}`
export const monthDayFormat = 'M/D'
export const paddedHourMinuteFormat = 'HH:mm'
export const fullTimeFormat = 'HH:mm:ss'
export const paddedMonthDayHourMinuteFormat = `${monthDayFormat} ${paddedHourMinuteFormat}`
export const shortDateFormat = 'M/D/YYYY'
export const paddedFullDateTimeFormat = `${shortDateFormat} ${paddedHourMinuteFormat}`
export const dayOfWeekFormat = 'dddd'
