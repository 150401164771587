// Adapted from https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt, useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Typography } from '@material-ui/core'
import ConfirmationBox from './ConfirmationBox'

const DISPLAY_NAME = 'RouteLeavingGuard'
const DEFAULT_TITLE = 'Leave page?'

export const RouteLeavingGuardContext = React.createContext({
  lastLocation: null,
  modifiedIds: [],
})

export const RouteLeavingGuardProvider = props => {
  const history = useHistory()
  const [state, setState] = useState({
    ids: {},
    descriptions: {},
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const value = useMemo(
    () => ({
      state,
      setId: (id, value = true) => {
        if (!_.has(state, ['ids', id]) || _.get(state, ['ids', id]) !== value) {
          setState(prev => {
            const ids = { ...prev.ids, [id]: value }
            return { ...prev, ids }
          })
        }
      },
    }),
    [state],
  )

  const when = _.values(state.ids).some(id => id)
  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && when) {
      setModalVisible(true)
      setLastLocation(nextLocation)

      // show a prompt to the user
      return false
    }

    // allow the transition
    return true
  }

  const handleConfirmNavigationClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  const handleSecondaryClick = () => {
    setModalVisible(false)
    setConfirmedNavigation(false)
  }

  const path = _.get(lastLocation, 'pathname')
  useEffect(() => {
    if (confirmedNavigation && path) {
      setConfirmedNavigation(false)
      setState(prev => ({
        ...prev,
        ids: _.mapValues(prev.ids, () => false),
      }))

      // Navigate to the previous blocked location with your navigate function
      history.push(path)
    }
  }, [confirmedNavigation, history, path])

  const handleCloseModal = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    if (_.values(state.ids).some(id => id)) {
      window.onbeforeunload = () => true
    }
    return () => (window.onbeforeunload = undefined)
  }, [state.ids])

  const blockedIds = []
  for (const id in state.ids) {
    if (state.ids[id]) {
      blockedIds.push(id)
    }
  }

  return (
    <RouteLeavingGuardContext.Provider value={value}>
      <Prompt when={when} message={handleBlockedNavigation} />

      <ConfirmationBox
        open={modalVisible}
        title={DEFAULT_TITLE}
        message={
          <>
            <Typography variant="body2" color="textPrimary">
              These forms have unsaved information:
            </Typography>
            <div>
              <ul>
                {blockedIds.map(id => (
                  <li key={id}>
                    <Typography variant="body2" color="textPrimary">
                      {id}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
            <Typography variant="body2" color="textPrimary">
              Are you sure you want to leave?
            </Typography>
          </>
        }
        secondaryButtonText="Cancel"
        primaryButtonText="Leave"
        onClose={handleCloseModal}
        onPrimary={handleConfirmNavigationClick}
        onSecondary={handleSecondaryClick}
      />
      {props.children}
    </RouteLeavingGuardContext.Provider>
  )
}

const RouteLeavingGuard = React.memo(props => {
  const { description, when } = props

  const guardContext = React.useContext(RouteLeavingGuardContext)
  if (guardContext === null) {
    throw new Error('guardContext must be used within a <RouteLeavingGuardProvider> tag')
  }

  useEffect(() => {
    if (!_.isNil(guardContext) && when !== _.get(guardContext, ['state', 'ids', description])) {
      guardContext.setId(description, when)
    }
  }, [when, guardContext, description])

  return null
})
RouteLeavingGuard.displayName = DISPLAY_NAME

RouteLeavingGuard.propTypes = {
  description: PropTypes.string.isRequired,
  when: PropTypes.bool,
}

export default RouteLeavingGuard
