import React from 'react'
import { TableCell } from '@material-ui/core'
import PropTypes from 'prop-types'

const PriceBandFirstHeader = ({ priceBandNames, className }) => {
  return (
    <>
      {priceBandNames.map((band, index) => (
        <TableCell className={className} key={index}>
          {' '}
          {band}
        </TableCell>
      ))}
      <TableCell key="max" className={className} />
    </>
  )
}

PriceBandFirstHeader.propTypes = {
  className: PropTypes.string,
  priceBandNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default PriceBandFirstHeader
