import React from 'react'
import { NavLink } from 'react-router-dom'

const NavLinkRef = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  )
})

NavLinkRef.displayName = 'NavLinkRef'

export default NavLinkRef
