import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Card from '../Card'
import { getCardSubtitleByDate } from '../../utility/utility'
import MarketResultsGraphs from './graphs/MarketResultsGraphs'
import SettlementsGraph from './graphs/SettlementsGraph'

const DISPLAY_NAME = 'ResultsChartsCard'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 400,
    },
    switchBox: {
      paddingRight: theme.spacing(1),
    },
  }),
  { name: DISPLAY_NAME },
)

function ResultsChartsCard(props) {
  const classes = useStyles(props)
  const { className: classNameProp, asset, inProgress, startTime, endTime, lastUpdated } = props

  const [displayRange, setDisplayRange] = useState({
    start: null,
    end: null,
  })
  const [intervention, setIntervention] = useState(false)

  const timezone = _.get(asset, 'market.data.timezone')
  moment.tz.setDefault(timezone)
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')

  useEffect(() => {
    if (!_.isNil(startTime) && moment(startTime).isValid()) {
      setDisplayRange({
        start: moment(startTime).unix(),
        end: moment(endTime).unix(),
      })
    }
  }, [endTime, startTime])

  const handleTogglePriceIntervention = () => {
    setIntervention(prev => !prev)
  }

  const handleSetDisplayRange = useCallback((start, end) => {
    setDisplayRange({ start, end })
  }, [])

  const title = 'Market Prices ($/MWh), Enablements (MW), Settlements ($)'
  const fromTime = moment.unix(displayRange.start)
  const toTime = moment.unix(displayRange.end)
  const subTitle = getCardSubtitleByDate(fromTime, toTime, lastUpdated, timezone, marketStartHour)
  return (
    <Box className={clsx(classes.root, classNameProp)}>
      <Card
        title={title}
        subheader={subTitle}
        inProgress={inProgress}
        action={
          <div className={classes.switchBox}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  label="Price Intervention"
                  labelPlacement="start"
                  value="intervention"
                  control={
                    <Switch
                      checked={intervention}
                      color="primary"
                      onChange={handleTogglePriceIntervention}
                      value="Price Intervention"
                      inputProps={{ 'aria-label': 'toggle price intervention' }}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
          </div>
        }
        className={classes.card}
      >
        <Box>
          <Box>
            <MarketResultsGraphs
              asset={asset}
              intervention={intervention}
              startTime={startTime}
              endTime={endTime}
              displayRange={displayRange}
              setDisplayRange={handleSetDisplayRange}
            />
          </Box>
          <Box>
            <SettlementsGraph
              asset={asset}
              startTime={startTime}
              endTime={endTime}
              displayRange={displayRange}
              setDisplayRange={handleSetDisplayRange}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  )
}
ResultsChartsCard.displayName = DISPLAY_NAME
ResultsChartsCard.propTypes = {
  className: PropTypes.string,
}

export default ResultsChartsCard
