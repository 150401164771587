import React from 'react'
import { formatMapData } from '../../../utility/utility'

export default function InterConnectorPopup(props) {

    const { interConnector } = props
    const powerFlow = interConnector.data.mw_flow
    return (
      <>
        {getComponent(
          powerFlow,
          interConnector.data.map_data.orientation,
          interConnector.data.marginal_value > 0,
        )}
      </>
    )
}

const getComponent = (powerFlow, orientation, isConstrained) => {

  if (powerFlow >= 0 && orientation === 'north') {
    return getDirection(0, powerFlow, isConstrained)
  }

  if (powerFlow < 0 && orientation === 'north') {
    return getDirection(180, -powerFlow, isConstrained)
  }

  if (powerFlow >= 0 && orientation === 'north-west') {
    return getDirection(-45, powerFlow, isConstrained, '65px')
  }
  if (powerFlow < 0 && orientation === 'north-west') {
    return getDirection(135, -powerFlow, isConstrained, '65px')
  }

  return <div />
}

const getDirection = (degrees, value, isConstrained, fontSize) => {
  return (
    <div data-testid="qa-map-inter-connector" className="inter-connector-popup">
      <div style={{ transform: `rotate(${degrees}deg)` }}>
        {isConstrained
          ? <span className="inter-connector-direction" style={{ fontSize }}>&#10514; </span>
          : <span className="inter-connector-direction"> &#8593; </span>
        }
      </div>
      <span className="inter-connector-value">{formatMapData(value, 1, 4)}</span>
    </div>
  )
}
