import React, { useState } from 'react'
import { connect } from 'react-redux'
import download from 'downloadjs'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { Button } from '@fluence/core'
import {
  dateFormatNoSeparator,
  dateTimeFormatWithSecondsNoSeparator,
  getBidCSV,
  downloadZip,
} from '../../utility/utility'
import { apiRoot } from '../../redux/api.js'
import Card from '../Card'

const DISPLAY_NAME = 'DownloadBids'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '38%',
      position: 'absolute',
      top: '30%',
      right: 0,
      left: 0,
    },
    card: {
      maxWidth: 856,
      maxHeight: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      '&:last-child': {
        paddingBottom: theme.spacing(1.25),
      },
    },
    textBody: {
      overflow: 'auto',
      paddingTop: 0,
      whiteSpace: 'pre-wrap',
      height: theme.spacing(28),
    },
    footer: {
      maxWidth: 856,
      width: '100%',
      borderTop: `2px solid ${theme.palette.background.default}`,
      overflow: 'hidden',
      padding: theme.spacing(2),
      flex: '1 0 auto',
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'right',
    },
    continue: {
      float: 'right',
      width: 112,
      height: theme.spacing(4),
    },
    close: {
      float: 'right',
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    selector: {
      cursor: 'pointer',
      marginBottom: theme.spacing(1.25),
    },
    formControl: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
    formControlLabelCsv: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      height: theme.spacing(3.5),
      paddingBottom: theme.spacing(2),
    },
    formControlLabel: {
      marginLeft: theme.spacing(1),
      height: theme.spacing(3.5),
    },
    dayOption: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      height: theme.spacing(3.5),
    },
    formControlLabelAck: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      height: theme.spacing(3.5),
    },
    expansionPanelRoot: {
      '&:before': {
        height: 0,
      },
    },
    expansionPanelRootBid: {
      '&:before': {
        height: 0,
      },
    },
    expansionSummaryRoot: {
      padding: 0,
      minHeight: theme.spacing(5),
    },
    expansionSummaryContent: {
      margin: 0,
    },
    expansionDetailsRoot: {
      padding: 0,
    },
    radioGroup: {
      paddingLeft: theme.spacing(5),
    },
    intervalOption: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      height: theme.spacing(3.5),
    },
    text: {
      marginBottom: theme.spacing(1.25),
      marginLeft: theme.spacing(2),
    },
    note: {
      fontSize: 12,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
    },
    modalHeader: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
    error: {
      marginLeft: theme.spacing(2),
      color: theme.palette.error.main,
    },
    ackError: {
      marginTop: theme.spacing(-0.5),
      marginLeft: theme.spacing(2),
      color: theme.palette.error.main,
    },
  }),
  { name: DISPLAY_NAME },
)

const BID_SELECTION = {
  INTERVAL: 'INTERVAL',
  DAY: 'DAY',
}

const ACK_SELECTION = {
  INTERVAL: 'INTERVAL',
  DAY: 'DAY',
}

const DownloadBids = React.forwardRef(function DownloadBids(props, ref) {
  const classes = useStyles()
  const { asset, onClose, disableAck } = props

  const [selected, setSelected] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [bidSelection, setBidSelection] = useState(BID_SELECTION.INTERVAL)
  const [ackSelection, setAckSelection] = useState(ACK_SELECTION.INTERVAL)

  const [inProgress, setInProgress] = useState(false)

  const handleCheckboxChange = key => event => {
    setErrorMessage('')
    let newSelected = _.cloneDeep(selected)
    if (event.target.checked) {
      newSelected.push(key)
    } else {
      newSelected = newSelected.filter(id => id !== key)
    }
    setSelected(newSelected)
  }

  const handleRadioChange = key => event => {
    setErrorMessage('')
    const newValue = _.get(event, 'target.value')
    if (key === 'bid') {
      setBidSelection(newValue)
    } else {
      setAckSelection(newValue)
    }
  }

  const onDownloadClick = () => {
    const { asset, selectedDate, index, interval, getBidFiles, getAckFiles } = props
    const bidStartTime =
      bidSelection === BID_SELECTION.DAY ? moment(selectedDate) : moment(selectedDate).add(interval * index, 'minutes')
    const bidEndTime =
      bidSelection === BID_SELECTION.DAY
        ? moment(selectedDate).add(1, 'days')
        : moment(bidStartTime).add(interval, 'minutes')
    const bidFilename =
      bidSelection === BID_SELECTION.DAY
        ? `bid_files_${moment(selectedDate).format('YYYY_MM_DD')}.zip`
        : `bid_files_${moment(selectedDate).format('YYYY_MM_DD')}_${moment(bidStartTime).format(
            'HH-mm-ss',
          )}_to_${moment(bidEndTime).format('HH-mm-ss')}.zip`
    const ackStartTime =
      ackSelection === ACK_SELECTION.DAY ? moment(selectedDate) : moment(selectedDate).add(interval * index, 'minutes')
    const ackEndTime =
      ackSelection === ACK_SELECTION.DAY
        ? moment(selectedDate).add(1, 'days')
        : moment(ackStartTime).add(interval, 'minutes')
    const ackFilename =
      ackSelection === ACK_SELECTION.DAY
        ? `ack_files_${moment(selectedDate).format('YYYY_MM_DD')}.zip`
        : `ack_files_${moment(selectedDate).format('YYYY_MM_DD')}_${moment(ackStartTime).format(
            'HH-mm-ss',
          )}_to_${moment(ackEndTime).format('HH-mm-ss')}.zip`

    setInProgress(true)
    Promise.all([
      selected.includes('csv') && downloadBids(),
      selected.includes('bidFile') && getBidFiles(asset, selectedDate, bidStartTime, bidEndTime, bidFilename),
      selected.includes('ackFile') && getAckFiles(asset, selectedDate, ackStartTime, ackEndTime, ackFilename),
    ])
      .then(response => {
        if (response.error) {
          console.info('Error')
        } else {
          handleOnClose()
        }
      })
      .catch(error => {
        setInProgress(false)
        setErrorMessage(error.message)
      })
  }

  const handleOnClose = () => {
    setInProgress(false)
    if (_.isFunction(onClose)) {
      onClose()
    }
  }

  const downloadBids = () => {
    const {
      asset,
      priceBands,
      selectedDate,
      selectedProductName,
      selectedProductType,
      bids,
      interval,
      lastUpdated,
    } = props
    const timezone = _.get(asset, 'market.data.timezone')
    const output = getBidCSV(
      asset,
      priceBands,
      selectedDate,
      selectedProductName,
      selectedProductType,
      bids,
      interval,
      lastUpdated,
    )
    const formattedTime = `${selectedDate.format(dateFormatNoSeparator)}_${moment()
      .tz(timezone)
      .format(dateTimeFormatWithSecondsNoSeparator)}`
    const fileName = `Bids_${selectedProductType}_${selectedProductName}_${asset.assetId}_${formattedTime}.csv`
    download(output, fileName, 'text/csv')
  }

  const bidError = errorMessage.includes('bid')
  const ackError = errorMessage.includes('acknowledgment') || errorMessage === 'Sorry, something went wrong.'

  return (
    <div className={classes.root} ref={ref}>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        title={<div className={classes.modalHeader}>Download bid files: {asset.name}</div>}
        titleTypographyProps={{ variant: 'h3' }}
        inProgress={inProgress}
      >
        <div className={classes.textBody}>
          <Typography variant="body1" className={classes.text}>
            You will receive zip files containing a csv of the bid table, the bid json file(s), and/or the
            acknowledgment file(s) from AEMO.
          </Typography>
          <FormControl className={classes.formControl} margin="dense">
            <FormControlLabel
              className={classes.formControlLabelCsv}
              control={
                <Checkbox
                  checked={selected.includes('csv')}
                  onChange={event => handleCheckboxChange('csv')(event)}
                  color="primary"
                />
              }
              label="Bids table current view (csv)"
            />
            <Accordion
              expanded={selected.includes('bidFile')}
              classes={{
                root: classes.expansionPanelRootBid,
              }}
              elevation={0}
              square
            >
              <AccordionSummary
                classes={{
                  root: classes.expansionSummaryRoot,
                  content: classes.expansionSummaryContent,
                }}
                aria-controls="panel-bid-file"
              >
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox
                      checked={selected.includes('bidFile')}
                      onChange={event => handleCheckboxChange('bidFile')(event)}
                      color="primary"
                    />
                  }
                  label="Bid file (zip)"
                />
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.expansionDetailsRoot,
                }}
              >
                <RadioGroup
                  aria-label="bid-file-download-option"
                  className={classes.radioGroup}
                  value={bidSelection}
                  onChange={handleRadioChange('bid')}
                >
                  <FormControlLabel
                    value={BID_SELECTION.INTERVAL}
                    control={<Radio color="primary" />}
                    label="For Selected Interval"
                    className={classes.intervalOption}
                  />
                  <FormControlLabel
                    value={BID_SELECTION.DAY}
                    control={<Radio color="primary" />}
                    label="For Trading Day"
                    className={classes.dayOption}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            {bidError && <Typography className={classes.error}>{errorMessage}</Typography>}
            <Accordion
              expanded={selected.includes('ackFile')}
              classes={{
                root: classes.expansionPanelRoot,
              }}
              elevation={0}
              square
            >
              <AccordionSummary
                classes={{
                  root: classes.expansionSummaryRoot,
                  content: classes.expansionSummaryContent,
                }}
                aria-controls="panel-ack-file"
              >
                <FormControlLabel
                  className={classes.formControlLabelAck}
                  control={
                    <Checkbox
                      disabled={disableAck}
                      checked={selected.includes('ackFile')}
                      onChange={event => handleCheckboxChange('ackFile')(event)}
                      color="primary"
                    />
                  }
                  label="AEMO acknowledgment file (zip)"
                />
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.expansionDetailsRoot,
                }}
              >
                <RadioGroup
                  aria-label="ack-file-download-option"
                  className={classes.radioGroup}
                  value={ackSelection}
                  onChange={handleRadioChange('ack')}
                >
                  <FormControlLabel
                    value={ACK_SELECTION.INTERVAL}
                    control={<Radio color="primary" />}
                    label="For Selected Interval"
                    className={classes.intervalOption}
                  />
                  <FormControlLabel
                    value={ACK_SELECTION.DAY}
                    control={<Radio color="primary" />}
                    label="For Trading Day"
                    className={classes.dayOption}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            {disableAck && (
              <div>
                <Typography className={classes.note}>
                  Note: There are no acknowledgment files for simulated assets since bid files are not delivered to AEMO
                </Typography>
              </div>
            )}
            {ackError && <Typography className={classes.ackError}>{errorMessage}</Typography>}
          </FormControl>
        </div>
      </Card>
      <div className={classes.footer}>
        <Button
          variant="primary"
          className={classes.marginLeft}
          disabled={selected.length === 0 || inProgress}
          onClick={onDownloadClick}
        >
          DOWNLOAD
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={inProgress}>
          CANCEL
        </Button>
      </div>
    </div>
  )
})

const mapDispatchToProps = () => {
  return {
    getBidFiles: (asset, tradingDate, startTime, endTime, filename) => {
      const url = `${apiRoot}/market/bidFiles/asset/${asset.assetId}/download/bidFiles?tradingDate=${encodeURIComponent(
        tradingDate.toISOString(),
      )}&startTime=${encodeURIComponent(startTime.toISOString())}&endTime=${encodeURIComponent(endTime.toISOString())}`
      return downloadZip(url, filename)
    },
    getAckFiles: (asset, tradingDate, startTime, endTime, filename) => {
      const url = `${apiRoot}/market/bidFiles/asset/${asset.assetId}/download/ackFiles?tradingDate=${encodeURIComponent(
        tradingDate.toISOString(),
      )}&startTime=${encodeURIComponent(startTime.toISOString())}&endTime=${encodeURIComponent(endTime.toISOString())}`
      return downloadZip(url, filename)
    },
  }
}

const mapStateToProps = state => {
  const index = _.get(state, 'bid.view.selectedBidIndex', '')

  return {
    index,
  }
}

DownloadBids.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBids)
