import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Route } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import NavLinkRef from '../NavLinkRef'

const useNavStyles = makeStyles(theme => {
  return {
    rootItem: {
      backgroundColor: theme.custom.palette.background.chrome.secondary,
    },
    subItem: {
      backgroundColor: theme.custom.palette.background.chrome.tertiary,
    },
  }
})

const NavItems = props => {
  const classes = useNavStyles()
  const { routes } = props

  return (
    <>
      {routes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          exact={route.exact}
          render={({ match, location }) => (
            <>
              {route.prepend}
              <NavButton
                className={route.subItem ? classes.subItem : classes.rootItem}
                match={match}
                location={location}
                exact={route.exact}
                icon={route.icon}
                text={route.text}
                to={route.to}
                divider={route.divider}
                subItem={route.subItem}
              />
              {route.append}
            </>
          )}
        />
      ))}
    </>
  )
}

const styles = theme => ({
  drawer: {
    height: theme.custom.overrides.NavDrawer.height,
    backgroundColor: theme.custom.palette.background.chrome.secondary,
    flex: `0 0 ${theme.custom.overrides.NavDrawer.width}px`,
    width: theme.custom.overrides.NavDrawer.width,
    position: 'fixed',
    zIndex: theme.zIndex.drawer,
  },
  drawerHidden: {
    overflow: 'hidden',
  },
  drawerOpen: {
    overflow: 'visible',
  },
  list: {
    // added width for safari because background color not showing when overflow is visible
    // width: '-webkit-max-content',
    width: theme.spacing(37),
    zIndex: 10,
    backgroundColor: theme.custom.palette.background.chrome.secondary,
  },
  leftPad: {
    paddingLeft: theme.spacing(3),
  },
})

class NavDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      ...getNavBarState(
        props.theme.custom.overrides.PageHeader.minHeight,
        props.theme.custom.overrides.AssetHeader.height,
      ),
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    this.setState({
      ...getNavBarState(
        this.props.theme.custom.overrides.PageHeader.minHeight,
        this.props.theme.custom.overrides.AssetHeader.height,
      ),
    })
  }

  handleOnMouseEnter = () => {
    this.setState({ open: true })
  }

  handleOnMouseLeave = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, location, routes } = this.props

    return (
      <Grid
        container
        direction="column"
        className={classNames(classes.drawer, {
          [classes.drawerHidden]: !this.state.open,
          [classes.drawerOpen]: this.state.open,
        })}
        style={{
          top: this.state.top,
          minHeight: this.state.minHeight,
        }}
      >
        <List
          disablePadding
          className={classes.list}
          onMouseLeave={this.handleOnMouseLeave}
          onMouseEnter={this.handleOnMouseEnter}
        >
          <NavItems routes={routes} location={location} />
        </List>
      </Grid>
    )
  }
}

NavDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    paddingLeft: theme.spacing(3),
  },
  activeItem: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  disabledFont: {
    color: theme.palette.text.disabled,
  },
  inheritColor: {
    color: 'inherit',
  },
}))

const NavButton = props => {
  const {
    className: classNameProp,
    icon: Icon,
    text,
    to,
    divider = false,
    exact = false,
    subItem = false,
    location,
  } = props
  const classes = useStyles()

  const markDisabled = !subItem && location.pathname.split('/').length > 2
  const itemClasses = classNames(classes.root, classNameProp, markDisabled && classes.disabledFont)
  return (
    <ListItem
      button
      divider={divider}
      exact={exact}
      className={itemClasses}
      component={NavLinkRef}
      activeClassName={classes.activeItem}
      to={to}
    >
      <ListItemIcon classes={{ root: classes.inheritColor }}>
        <Icon color="inherit" fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'h4' }} />
    </ListItem>
  )
}

NavButton.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  to: PropTypes.string,
}

export default withStyles(styles, { withTheme: true })(NavDrawer)

function getNavBarState(pageHeaderHeight, assetHeaderHeight) {
  return {
    top: Math.max(assetHeaderHeight, pageHeaderHeight + assetHeaderHeight - window.pageYOffset),
    minHeight: `calc(100vh - ${Math.max(
      assetHeaderHeight,
      pageHeaderHeight + assetHeaderHeight - window.pageYOffset,
    )}px)`,
  }
}
