import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Input, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import { upsertAssetDataResource } from '../../redux/features/setting'
import { assetOperations } from '../../redux/features/asset'
import { convertkWToMW, convertMWToKW } from '../../utility/utility'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import Card from '../Card'
import InputNumberFormat from '../InputNumberFormat'
import RouteLeavingGuard from '../RouteLeavingGuard'
import { FCAS_CONTINGENCY_PRODUCT_NAME } from '../../utility/constants'

const DISPLAY_NAME = 'MarketRegistration'
const CAPACITY_INPUT_PROPS = {
  allowNegative: false,
  decimalScale: 0,
}
const FCAS_INPUT_PROPS = {
  allowNegative: false,
  decimalScale: 0,
}
const ROC_INPUT_PROPS = {
  allowNegative: false,
  decimalScale: 0,
}

const useStyles = makeStyles(
  theme => ({
    root: {},
    disabled: {},
    fontWhite: { color: '#FFF' },
    borderLeft: {
      borderLeft: '1px solid rgba(81, 81, 81, 1)',
    },
    borderRight: {
      borderRight: '1px solid rgba(81, 81, 81, 1)',
    },
    borderBottom: {
      borderBottom: '1px solid rgba(81, 81, 81, 1)',
    },
    border: {
      border: '1px solid rgba(81, 81, 81, 1)',
    },
    cardContent: {
      padding: 0,
      minHeight: 335,
    },
    table: {
      borderCollapse: 'separate' /* Don't collapse */,
      borderSpacing: 0,
      textTransform: 'uppercase',
      '& tbody:hover td[rowspan], tr:hover td': {
        backgroundColor: theme.palette.action.hover,
      },
      '& td': {
        padding: theme.spacing(0.75, 1),
      },
      '& td.input-cell': {
        padding: theme.spacing(0, 1),
      },
      '& th': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
      },
      '& thead > tr:nth-child(1) > th:first-child': {
        maxWidth: 128,
        width: 128,
        minWidth: 128,
        paddingLeft: theme.spacing(2),
      },
      '& tbody > tr > td:first-child': {
        paddingLeft: theme.spacing(2),
      },
    },
    tableRocPrimaryHeader: {
      paddingBottom: 0,
      borderBottom: 'none',
    },
    tableRocSecondaryHeader: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      borderBottom: 'none',
    },
    tableFcas: {
      minWidth: 830,
      [theme.breakpoints.up('md')]: {
        minWidth: 600,
      },
    },
    tableFcasPrimaryHeader: {
      paddingBottom: 0,
      borderBottom: 'none',
    },
    tableFcasSecondaryHeader: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
      borderBottom: 'none',
    },
    tableParentGroup: {
      paddingBottom: 0,
      borderBottom: 'none',
    },
    tableHeadProductGroupEnergy: {
      paddingBottom: 6,
      verticalAlign: 'bottom',
    },
    underline: {
      marginBottom: -1,
      padding: '6px 0px 6px 2px',
      '&:before': {
        borderBottom: '1px solid rgba(81, 81, 81, 1)',
      },
      '&:after': {
        borderBottom: '1px solid #75ACED',
      },
      '&:hover:not($disabled):before': {
        borderBottom: `1px solid`,
      },
    },
    textColorDefault: {
      color: theme.palette.text.secondary,
    },
    textColorModified: {
      color: '#EDB449',
    },
    smallFont: { fontSize: 14 },
    inputCell: {
      paddingBottom: 0,
    },
  }),
  { name: DISPLAY_NAME },
)

const MarketRegistration = React.memo(props => {
  const { asset, createConfiguration, onSave, permissions } = props
  const { dispatchUpdateAssetConfiguration } = props
  const { notifySuccess, notifyError } = useNotifier()

  const classes = useStyles()
  const hasPermission = canAdjustRiskAppetite(permissions, asset)

  const [state, setState] = useState({
    errorMessage: '',
    isEditing: false,
    newConfiguration: {},
  })
  const [savedConfigurationMW, setSavedConfigurationMW] = useState({})
  const [requestInProgress, setRequestInProgress] = useState(false)

  const assetId = _.get(asset, 'assetId')
  const configuration = _.get(asset, 'data.configuration')
  const maxCapacity = convertkWToMW(
    _.get(asset, 'data.inverter_discharge_rate') * _.get(asset, 'data.number_inverters'),
  )
  const products = _.get(asset, 'market.data.product_sub_groups', {})
  const productNames = _.get(asset, 'productNames', [])
  const productTypes = _.get(asset, 'productTypes', [])
  const productGroupsOrder = _.get(asset, 'market.data.product_groups_order', [])
  const productSubGroupsOrder = _.get(asset, 'market.data.product_sub_groups_order', [])

  useEffect(() => {
    if (configuration) {
      const convertedConfigurationMW = convertValueskWToMW(configuration)
      if (!_.isEqual(convertedConfigurationMW, savedConfigurationMW)) {
        setSavedConfigurationMW(() => {
          if (!_.isEqual(convertedConfigurationMW, state.newConfiguration) && !state.isEditing) {
            setState(prev => ({ ...prev, newConfiguration: { ...convertedConfigurationMW } }))
          }
          return convertedConfigurationMW
        })
      }
    }
  }, [configuration, savedConfigurationMW, state])

  const onEditClick = () => {
    setState({
      ...state,
      isEditing: true,
    })
  }

  const handleCancel = () => {
    const newConfiguration = convertValueskWToMW(configuration)
    if (_.isEqual(newConfiguration, state.newConfiguration)) {
      setState(prev => ({ ...prev, errorMessage: '', isEditing: false }))
    } else {
      setState(prev => ({ ...prev, errorMessage: '', isEditing: false, newConfiguration }))
    }
  }

  const handleSave = () => {
    const errorMessage = validateCapacity() || validateFCASSettings() || validateROCSettings()
    if (!_.isEmpty(errorMessage)) {
      setState(prev => ({ ...prev, errorMessage }))
      return
    }
    return sendUpdateSettingsRequest()
  }

  const sendUpdateSettingsRequest = () => {
    const { newConfiguration } = state
    setRequestInProgress(true)
    const convertedValues = convertValuesMWTokW(newConfiguration)
    createConfiguration(convertedValues, assetId).then(response => {
      if (response.error) {
        notifyError('Something went wrong. Please try again')
        setRequestInProgress(false)
      } else {
        notifySuccess('Market registration updated successfully')
        if (_.isFunction(onSave)) {
          onSave()
        }
        setState(prev => {
          dispatchUpdateAssetConfiguration(assetId, convertedValues)
          setRequestInProgress(false)
          return { ...prev, errorMessage: '', newConfiguration: {}, isEditing: false }
        })
      }
    })
  }

  const validateCapacity = () => {
    const { newConfiguration } = state
    let errorMessage = ''
    for (const productName of productNames) {
      for (const productType of productTypes) {
        const product = `${productName}_${productType}`
        if (newConfiguration.hasOwnProperty(product)) {
          errorMessage = validate(
            newConfiguration[product],
            ['Registered Capacity', ...product.split('_')].join(' > ') + ' Capacity',
          )
          if (errorMessage !== '') {
            return errorMessage
          }
        } else {
          return `Please enter a valid value for Registered Capacity > ${productName} > ${productType}`
        }
      }
    }
    return errorMessage
  }

  const validateFCASSettings = () => {
    const { newConfiguration } = state
    const groups = [productGroupsOrder[1], productGroupsOrder[2]]
    let errorMessage = ''

    for (const group of groups) {
      for (const productType of productTypes) {
        for (const attr of ['Min', 'Max']) {
          if (newConfiguration.hasOwnProperty(group)) {
            const value = newConfiguration[group][productType][`enablement_${attr.toLowerCase()}`]
            errorMessage = validate(value, [group, productType, 'Enablement', attr].join(' > ') + ' setting')
            if (errorMessage !== '') {
              return errorMessage
            }
          } else {
            return `Please enter a valid value for ${group} > ${productType} > ${attr}`
          }
        }
        for (const attr of ['Low', 'High']) {
          if (newConfiguration.hasOwnProperty(group)) {
            const value = newConfiguration[group][productType][`breakpoint_${attr.toLowerCase()}`]
            errorMessage = validate(value, [group, productType, 'Breakpoint', attr].join(' > ') + ' setting')
            if (errorMessage !== '') {
              return errorMessage
            }
          } else {
            return `Please enter a valid value for ${group} > ${productType} > ${attr}`
          }
        }
      }
    }
    return errorMessage
  }

  const obtainROCData = () => {
    const { newConfiguration } = state
    const productGroup = _.first(productGroupsOrder)
    return [newConfiguration, productGroup]
  }
  const validateROCSettings = () => {
    const [newConfiguration, productGroup] = obtainROCData()
    let errorMessage = ''

    const directions = ['Up', 'Down']
    const groups = ['Max']
    for (const direction of directions) {
      for (const group of groups) {
        if (
          newConfiguration.hasOwnProperty(productGroup) &&
          newConfiguration[productGroup].hasOwnProperty(direction.toLowerCase())
        ) {
          const value = newConfiguration[productGroup][direction.toLowerCase()][group.toLowerCase()]
          errorMessage = validate(value, ['ROC', direction, group].join(' > ') + ' setting')
          if (errorMessage !== '') {
            return errorMessage
          }
        } else {
          errorMessage = `Please enter a valid value for ${productGroup} > ${direction} > ${group}`
        }
      }
    }

    for (const direction of directions) {
      if (
        newConfiguration.hasOwnProperty(productGroup) &&
        newConfiguration[productGroup].hasOwnProperty(direction.toLowerCase())
      ) {
        const rocs = newConfiguration[productGroup][direction.toLowerCase()]
        const maxRoc = newConfiguration['ENERGY_GEN']
        if (+rocs['max'] < 3) {
          return `Please enter a value greater than or equal to 3MW for ROC > ${direction} > Max`
        }
        if (+rocs['max'] > maxRoc) {
          return `ROC > ${direction} > Max setting cannot be greater than registered capacity ${maxRoc} MW/min`
        }
      } else {
        errorMessage = `Please enter a valid value for ROC > ${direction} > Max`
      }
    }

    return errorMessage
  }

  const validate = (value, title) => {
    if (+value > maxCapacity) {
      return `${title} must be less than or equal to asset capacity (${maxCapacity} MW)`
    } else if (value === '' || value === undefined || isNaN(value)) {
      return `Please enter a valid value for ${title}`
    }
    return ''
  }

  const { errorMessage, isEditing } = state
  const modified = !_.isEqual(state.newConfiguration, savedConfigurationMW) || requestInProgress
  const ActionBtn = isEditing ? (
    <>
      <Button variant="text" disabled={requestInProgress} onClick={handleCancel}>
        cancel
      </Button>
      <Button variant="text" disabled={!modified} onClick={handleSave}>
        Save
      </Button>
    </>
  ) : (
    <Button variant="text" onClick={onEditClick}>
      Edit
    </Button>
  )

  return (
    <>
      <RouteLeavingGuard description="Market Registrations" when={modified} />
      <Card
        classes={{ content: classes.cardContent }}
        title="Market Registrations"
        action={hasPermission && ActionBtn}
        inProgress={_.isEmpty(asset) || requestInProgress}
      >
        <Grid container spacing={0}>
          {isEditing && errorMessage !== '' && (
            <Grid item xs={12}>
              <Box className={classes.borderBottom} pt={1} pb={1} pl={2}>
                <Typography variant="body1" color="error">
                  {errorMessage}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mb={0}>
              {!_.isEmpty(asset) && (
                <CapacityTable
                  asset={asset}
                  isEditing={false}
                  newConfiguration={state.newConfiguration}
                  products={products}
                  productTypes={productTypes}
                  productSubGroupsOrder={productSubGroupsOrder}
                  setValues={setState}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            {!_.isEmpty(asset) && (
              <FCASTable
                asset={asset}
                isEditing={false}
                newConfiguration={state.newConfiguration}
                productGroupsOrder={productGroupsOrder}
                productTypes={productTypes}
                setValues={setState}
              />
            )}
          </Grid>
          <Grid item xs={12} md={2}>
            {!_.isEmpty(asset) && (
              <RocTable
                isEditing={isEditing}
                newConfiguration={state.newConfiguration}
                productGroupsOrder={productGroupsOrder}
                setValues={setState}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  )
})

const orderFCASContingency = names => {
  const grouped = _.groupBy(names, name => name.toUpperCase().startsWith('RAISE') ? 'RAISE' : 'LOWER')
  return _.concat(grouped['RAISE'], grouped['LOWER'])
}

const CapacityTable = React.memo(props => {
  const {
    asset,
    isEditing,
    newConfiguration = {},
    products,
    productSubGroupsOrder,
    productTypes = [],
    setValues,
  } = props
  const classes = useStyles()

  const [displayRowsByType, setDisplayRowsByType] = useState([])

  useEffect(() => {
    const rowsByType = productTypes.map(type => {
      return {
        type,
        cells: productSubGroupsOrder.reduce((acc, group) => {
          const { productNames = [] } = getProductData(asset.market.data.product_sub_groups[group], asset, group)
          const cellDetails = productNames.map(name => {
            const configKey = `${name}_${type}`
            return {
              type,
              name,
              configKey,
            }
          })
          return [...acc, ...cellDetails]
        }, []),
      }
    })
    setDisplayRowsByType(rowsByType)
  }, [asset, productSubGroupsOrder, productTypes])

  const handleOnChange = key => event => {
    const value = parseFloat(_.get(event, 'target.value'))
    setValues(prevState => ({
      ...prevState,
      newConfiguration: { ...prevState.newConfiguration, [key]: value },
    }))
  }

  return (
    <Table className={classes.table} size="small" aria-label="Capacity Table">
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2}>Registered Capacity (MW)</TableCell>
          {productSubGroupsOrder.map(group =>
            group === 'Energy' ? (
              <TableCell
                key={group}
                classes={{ head: clsx(classes.borderLeft, classes.tableHeadProductGroupEnergy) }}
                colSpan={getProductData(products[group], asset, group).productNames.length}
                rowSpan="2"
              >
                {group}
              </TableCell>
            ) : (
              <TableCell
                classes={{ head: clsx(classes.borderLeft, classes.fontWhite, classes.tableParentGroup) }}
                key={group}
                colSpan={getProductData(products[group], asset, group).productNames.length}
              >
                {group}
              </TableCell>
            ),
          )}
        </TableRow>
        <TableRow>
          {_.concat(
            productSubGroupsOrder.slice(1).map(group =>
              getProductData(products[group], asset, group).displayNames.map((product, index) => (
                <TableCell classes={{ head: clsx({ [classes.borderLeft]: index === 0 }) }} key={product}>
                  {product}
                </TableCell>
              )),
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {displayRowsByType.map(displayRowData => {
          return (
            <TableRow key={displayRowData.type}>
              <TableCell>{displayRowData.type}</TableCell>
              {displayRowData.cells?.map((cell, index) => {
                return (
                  <IndividualInputCell
                    key={index}
                    inputProps={CAPACITY_INPUT_PROPS}
                    disabled={!isEditing}
                    type="text"
                    value={newConfiguration[cell.configKey]}
                    onChange={handleOnChange(cell.configKey)}
                  />
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
})
CapacityTable.displayName = 'CapacityTable'

const mapStateToProps = state => ({
  permissions: _.get(state, 'user.current.payload.permissions', {}),
})

const mapDispatchToProps = dispatch => {
  return {
    createConfiguration: (newConfiguration, assetId) =>
      dispatch(upsertAssetDataResource.post(assetId, 'configuration', moment(), newConfiguration)),
    dispatchUpdateAssetConfiguration: (assetId, configuration) =>
      dispatch(assetOperations.updateAssetData(assetId, 'configuration', configuration)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketRegistration)

const FCASTable = props => {
  const { isEditing, newConfiguration = {}, productGroupsOrder, productTypes, setValues } = props
  const classes = useStyles()

  const SECONDARY_HEADER_NAMES = ['Enablement', 'Enablement', 'Breakpoint', 'Breakpoint']
  const TERTIARY_HEADER_NAMES = ['Min', 'Max', 'Low', 'High']

  const handleFcasOnChange = (group, productType, configKey) => event => {
    const value = _.get(event, 'target.value')
    setValues(prevState => {
      const prevGroup = _.get(prevState.newConfiguration, group, {})
      const prevProductType = _.get(prevGroup, productType, {})

      const newConfiguration = {
        ...prevState.newConfiguration,
        [group]: {
          ...prevGroup,
          [productType]: {
            ...prevProductType,
            [configKey]: parseFloat(value),
          },
        },
      }
      return {
        ...prevState,
        newConfiguration,
      }
    })
  }
  return (
    <Table className={clsx(classes.table, classes.tableFcas)} size="small" aria-label="FCAS Table">
      <TableHead>
        <TableRow>
          <TableCell rowSpan={3} key="fcas settings">
            FCAS Settings (MW)
          </TableCell>
          {productGroupsOrder.slice(1).map(group => (
            <TableCell
              colSpan={4}
              key={group}
              classes={{
                head: clsx(classes.tableParentGroup, classes.borderLeft, classes.borderBottom, classes.fontWhite),
              }}
            >
              {group}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {['Enablements', 'Breakpoint', 'Enablements', 'Breakpoint'].map((value, index) => (
            <TableCell
              colSpan={2}
              key={`${value}-${index}`}
              classes={{
                head: clsx(
                  { [classes.borderLeft]: index % 2 === 0 },
                  classes.tableParentGroup,
                  classes.tableFcasSecondaryHeader,
                ),
              }}
            >
              {value}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {_.concat(
            productGroupsOrder.slice(1).map(group =>
              TERTIARY_HEADER_NAMES.map((value, index) => (
                <TableCell key={group + value} classes={{ head: clsx({ [classes.borderLeft]: index === 0 }) }}>
                  {value}
                </TableCell>
              )),
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {productTypes.map(productType => (
          <TableRow key={productType}>
            <TableCell>{productType}</TableCell>
            {productGroupsOrder.slice(1).map(group => {
              return TERTIARY_HEADER_NAMES.map((tertiaryHeaderName, idx) => {
                const configIndex3 = `${_.get(
                  SECONDARY_HEADER_NAMES,
                  idx,
                  '',
                ).toLowerCase()}_${tertiaryHeaderName.toLowerCase()}`
                const configValue = _.get(newConfiguration, [group, productType, configIndex3])
                return (
                  <IndividualInputCell
                    key={`${group}-${idx}`}
                    inputProps={FCAS_INPUT_PROPS}
                    disabled={!isEditing}
                    type="text"
                    value={configValue}
                    onChange={handleFcasOnChange(group, productType, configIndex3)}
                  />
                )
              })
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const RocTable = React.memo(props => {
  const { isEditing, newConfiguration, productGroupsOrder, setValues } = props
  const classes = useStyles()

  return (
    <Table className={classes.table} size="small" aria-label="ROC Table">
      <TableHead>
        <TableRow>
          <TableCell
            classes={{
              head: clsx(classes.tableRocPrimaryHeader, classes.borderLeft, classes.fontWhite),
            }}
          >
            ROC Settings
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            classes={{
              head: clsx(
                classes.borderLeft,
                classes.tableParentGroup,
                classes.tableRocSecondaryHeader,
                classes.fontWhite,
              ),
            }}
          >
            <Box pl={1}>(MW/min)</Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            classes={{
              head: clsx(classes.borderLeft),
            }}
          >
            <Box pl={1}>Direction</Box>
          </TableCell>
          <TableCell>Max</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <ROCRows
          isEditing={isEditing}
          newConfiguration={newConfiguration}
          productGroupsOrder={productGroupsOrder}
          setValues={setValues}
        />
      </TableBody>
    </Table>
  )
})

const ROCRows = props => {
  const { isEditing, newConfiguration, productGroupsOrder, setValues } = props
  const classes = useStyles()
  const productGroup = _.first(productGroupsOrder)
  const directions = ['Up', 'Down']
  const groups = ['Max']

  const handleRocOnChange = (group, direction, boundry) => event => {
    const value = _.get(event, 'target.value')
    setValues(prevState => {
      const prevGroup = _.get(prevState.newConfiguration, group, {})
      const prevDirectionGroup = _.get(prevGroup, direction, {})
      const newConfiguration = {
        ...prevState.newConfiguration,
        [group]: {
          ...prevGroup,
          [direction]: {
            ...prevDirectionGroup,
            [boundry]: parseFloat(value),
          },
        },
      }
      return {
        ...prevState,
        newConfiguration,
      }
    })
  }

  if (_.isEmpty(newConfiguration)) {
    return null
  }

  return directions.map(direction => (
    <TableRow key={direction}>
      <TableCell classes={{ body: classes.borderLeft }}>{direction}</TableCell>
      {groups.map(group => {
        const configValue = _.get(newConfiguration, [productGroup, direction.toLowerCase(), group.toLowerCase()])

        return (
          <IndividualInputCell
            key={`${direction}-${group}`}
            inputProps={ROC_INPUT_PROPS}
            disabled={!isEditing}
            type="text"
            value={configValue}
            onChange={handleRocOnChange(productGroup, direction.toLowerCase(), group.toLowerCase())}
          />
        )
      })}
    </TableRow>
  ))
}

const convertValueskWToMW = obj => {
  const newObj = _.cloneDeep(obj)
  for (const key in newObj) {
    if (_.isObject(newObj[key])) {
      newObj[key] = convertValueskWToMW(newObj[key])
    } else {
      newObj[key] = convertkWToMW(newObj[key])
    }
  }
  return newObj
}

const convertValuesMWTokW = obj => {
  const newObj = _.cloneDeep(obj)
  for (const key in newObj) {
    if (_.isObject(newObj[key])) {
      newObj[key] = convertValuesMWTokW(newObj[key])
    } else {
      newObj[key] = convertMWToKW(newObj[key])
    }
  }
  return newObj
}

const getProductData = (products, asset, group) => {
  const output = {
    displayNames: [],
    productNames: [],
  }
  products.forEach(product => {
    if (!output.productNames.includes(product.name)) {
      output.productNames.push(product.name)
      output.displayNames.push(asset.market.data.product_display_names[product.name])
    }
  })
  if (group === FCAS_CONTINGENCY_PRODUCT_NAME) {
    return getOrderedProductData(output)
  }
  return output
}

const getOrderedProductData = productData => {
  let orderedProductData = _.cloneDeep(productData)
  _.forEach(_.keys(productData), key => {
    orderedProductData[key] = orderFCASContingency(orderedProductData[key])
  })
  return orderedProductData
}

const IndividualInputCell = React.memo(props => {
  const { config, component, id = '', inputProps, value, modified = false, onChange, index, ...rest } = props
  const classes = useStyles()

  const Component = component || TableCell
  return (
    <Component className="input-cell" key={id} variant="body" size="small">
      <Input
        inputComponent={InputNumberFormat}
        variant="filled"
        inputProps={inputProps}
        onChange={onChange}
        value={isNaN(value) ? '' : `${value}`} // sending as a string. seems most generic way to go about it
        classes={{
          underline: classes.underline,
        }}
        className={clsx(
          {
            [classes.textColorDefault]: !modified,
            [classes.textColorModified]: modified,
          },
          classes.smallFont,
        )}
        {...rest}
      />
    </Component>
  )
})
