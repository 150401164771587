import moment from 'moment'
import _ from 'lodash'
import { CACHE_API } from '../cachingMiddleware'
import { getIndex } from '../../utility/utility'

// pricesForecastAction, "forecast", "00:05:00", "01:00:00"
export const getIntervals =
  (getIntervalAction, container, shortExpiration, longExpiration) =>
  (
    intervalConfig, // tag, horizon, region etc
    intervalTimeConfig, // from when to when
  ) => {
    let daysWithOutData = []
    return {
      [CACHE_API]: {
        mapStateToCache: state => {
          daysWithOutData = getDaysWithoutData(
            state[container],
            intervalConfig,
            intervalTimeConfig,
            shortExpiration || '00:01:00',
            longExpiration || '00:30:00',
          )
          return daysWithOutData.length > 0 ? null : 'do not make api call'
        },
        fetchAction: dispatch =>
          fetchData(dispatch, daysWithOutData, getIntervalAction, intervalConfig, intervalTimeConfig),
      },
    }
  }

export const getIntervalsNew =
  (getIntervalAction, container, shortExpiration, longExpiration) =>
  (
    intervalConfig, // tag, horizon, region etc
    intervalTimeConfig, // from when to when
  ) => {
    return (dispatch, getState) => {
      const state = getState()
      let daysWithOutData = []
      daysWithOutData = getDaysWithoutData(
        state[container],
        intervalConfig,
        intervalTimeConfig,
        shortExpiration || '00:01:00',
        longExpiration || '00:30:00',
      )
      if (daysWithOutData.length > 0) {
        return fetchData(dispatch, daysWithOutData, getIntervalAction, intervalConfig, intervalTimeConfig)
      }
    }
  }

// get dates(startTime and endTime) without data in cache or cache has expired
const getDaysWithoutData = (stateContainer, intervalConfig, intervalTimeConfig, shortExpiration, longExpiration) => {
  const daysWithOutData = []
  if (_.isNil(intervalTimeConfig.startTime)) {
    console.warn(`getDaysWithoutData() 'startTime' is undefined`)
    return daysWithOutData
  }
  let startTime = moment(intervalTimeConfig.startTime)
  let currentEnd = null
  const now = moment()

  while (startTime.isBefore(intervalTimeConfig.endTime)) {
    const invalidateAfter = startTime.clone().add(1, 'd').isSameOrBefore(now) ? longExpiration : shortExpiration
    const entity = getChartData(stateContainer, intervalConfig.keyPrefix, startTime)
    const entityExpired = entity.updatedOn
      ? now.utc().diff(moment(entity.updatedOn)) > moment.duration(invalidateAfter).asMilliseconds()
      : true
    if (entityExpired) {
      if (daysWithOutData.length % 2 === 0) {
        daysWithOutData.push(startTime)
        currentEnd = startTime.clone().add(1, 'd')
      } else if (currentEnd.diff(daysWithOutData[daysWithOutData.length - 1], 'days') >= 5) {
        daysWithOutData.push(currentEnd)
        daysWithOutData.push(startTime)
        currentEnd = startTime.clone().add(1, 'd')
      } else {
        currentEnd.add(1, 'd')
      }
    } else if (daysWithOutData.length % 2 === 1) {
      daysWithOutData.push(currentEnd)
    }

    startTime = startTime.clone().add(1, 'd')
  }

  if (daysWithOutData.length % 2 === 1) {
    daysWithOutData.push(currentEnd)
  }
  return daysWithOutData
}

const fetchData = (dispatch, daysToFetch, getIntervalAction, intervalConfig, intervalTimeConfig) => {
  const promises = []
  _.range(0, daysToFetch.length / 2).forEach(index => {
    const startTime = daysToFetch[index * 2]
    const endTime = daysToFetch[index * 2 + 1]
    const config = Object.assign({}, intervalTimeConfig)
    config.startTime = startTime
    config.endTime = endTime
    promises.push(dispatch(getIntervalAction.get(intervalConfig, config)))
  })
  return promises
}

export const intervalStateCreator =
  actions =>
  (state = {}, dispatchedAction) => {
    const meta = dispatchedAction.meta
    // eslint-disable-next-line no-unused-vars
    for (const action of actions) {
      switch (dispatchedAction.type) {
        case action.request:
          return Object.assign({}, state, getRequestState(meta.intervalConfig, meta.intervalTimeConfig))
        case action.success:
          return Object.assign(
            {},
            state,
            getSuccessState(dispatchedAction.payload, meta.updatedOn, meta.intervalConfig, meta.intervalTimeConfig),
          )
        default:
      }
    }
    return state
  }

const getRequestState = (intervalConfig, intervalTimeConfig) => {
  let startTime = intervalTimeConfig.startTime.clone()
  const endTime = intervalTimeConfig.endTime
  const state = {}
  while (startTime.isBefore(endTime)) {
    const key = getKey(intervalConfig.keyPrefix, startTime)
    state[key] = { isLoading: true }
    startTime = startTime.clone().add(1, 'd')
  }
  return state
}

const getSuccessState = (payload, updatedOn, intervalConfig, intervalTimeConfig) => {
  let startTime = intervalTimeConfig.startTime.clone()
  const endTime = intervalTimeConfig.endTime
  const payloadIsArray = _.isArray(payload)

  // for data like those on finance page, where we get one month data and not 5 or 30-mins interval
  // the data will still be cached using start time but it data is for one month
  if (endTime.diff(startTime, 'days') === 1) {
    const key = getKey(intervalConfig.keyPrefix, startTime)
    return {
      [key]: {
        isLoading: false,
        data: payload,
        updatedOn,
      },
    }
  } else {
    const state = {}
    while (startTime.isBefore(endTime)) {
      const startIndex = getIndex(
        startTime,
        intervalTimeConfig.startTime,
        intervalTimeConfig.endTime,
        intervalTimeConfig.duration,
      )
      const endIndex = getIndex(
        moment(startTime).add(1, 'd').subtract(intervalTimeConfig.duration, 'minutes'),
        intervalTimeConfig.startTime,
        intervalTimeConfig.endTime,
        intervalTimeConfig.duration,
      )
      const key = getKey(intervalConfig.keyPrefix, startTime)
      const data = payloadIsArray
        ? getChunk(payload, startIndex, endIndex)
        : processObjectResponse(payload, startTime, startIndex, endIndex)
      state[key] = {
        isLoading: false,
        data,
        updatedOn,
      }
      startTime = moment(startTime).add(1, 'd')
    }
    return state
  }
}

// expecting format of payload to be
// { startTime , values -> List<DoubleArray>, forecastStartTime}
const processObjectResponse = (payload, startTime, startIndex, endIndex) => {
  const state = _.cloneDeep(payload)
  state.startTime = startTime.toISOString()
  state.values = state.values.map(arr => getChunk(arr, startIndex, endIndex))
  return state
}

const getChunk = (arrayPayLoad, startIndex, endIndex) =>
  _.range(startIndex, endIndex + 1).map(index => arrayPayLoad[index])

export const getKey = (keyPrefix, startTime) => `${keyPrefix}_${moment(startTime).toISOString()}`
export const getIntervalKey = getKey
export const getChartData = (state, keyPrefix, startTime) => {
  if (_.isEmpty(keyPrefix) || _.isNil(startTime) || !moment(startTime).isValid()) {
    return
  }
  const key = getKey(keyPrefix, startTime)
  return (state.intervals || {})[key] || {}
}

export class IntervalTimeConfig {
  constructor(startTime, endTime, forecastStartTime, yearMonth, horizon, duration) {
    this.startTime = startTime
    this.endTime = endTime
    this.forecastStartTime = forecastStartTime
    this.yearMonth = yearMonth
    this.horizon = horizon
    this.duration = duration
  }
}

export class IntervalConfig {
  // key prefix is added to the start time to get the key in state
  constructor(keyPrefix, tag, assetId, productId, productIds, regionId, meta) {
    this.keyPrefix = keyPrefix
    this.tag = tag
    this.assetId = assetId
    this.productId = productId
    this.productIds = productIds
    this.regionId = regionId
    this.meta = meta
  }
}

export class MixedTagIntervalConfig {
  // key prefix is added to the start time to get the key in state
  constructor(keyPrefix, tag1, tag2, assetId, productId, productIds, regionId, meta) {
    this.keyPrefix = keyPrefix
    this.tag1 = tag1
    this.tag2 = tag2
    this.assetId = assetId
    this.productId = productId
    this.productIds = productIds
    this.regionId = regionId
    this.meta = meta
  }
}
