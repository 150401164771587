import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Modal, Typography } from '@material-ui/core'
import { Info, Button } from '@fluence/core'

const HEADER_HEIGHT = 56
const FOOTER_HEIGHT = 64

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(HEADER_HEIGHT),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  body: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + theme.spacing(8)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1, 2, 1),
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  primaryBtn: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1.75),
  },
  foot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: FOOTER_HEIGHT,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  paragraph: {
    whiteSpace: 'break-spaces',
    padding: theme.spacing(2),
    fontSize: 15,
    lineHeight: 2,
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
}))

function HelperTextModal(props) {
  const { helperText = 'Placeholder Text Body', title = 'Placeholder' } = props
  const classes = useStyles()
  const [helperTextModalOpen, setHelperTextModalOpen] = useState(false)
  const handleClose = () => {
    setHelperTextModalOpen(false)
  }
  const handleOpen = () => {
    setHelperTextModalOpen(true)
  }
  return (
    <>
      <Box className={classes.icon}>
        <IconButton aria-label="helper-text" size="small" onClick={handleOpen}>
          <Info color="primary" fontSize="small" />
        </IconButton>
      </Box>
      <Modal open={helperTextModalOpen} onClose={handleClose}>
        <Box flex="0 1 auto" className={classes.body}>
          <Typography variant="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            {helperText}
          </Typography>
          <Box className={classes.foot}>
            <Button variant="primary" onClick={handleClose}>
              close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

HelperTextModal.displayName = 'HelperTextModal'

export default HelperTextModal
