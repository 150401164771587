import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { dateTimeFormatWithSeconds, getAllPriceProductIds, refreshInterval } from '../utility/utility'
import { AMS_FORECAST_TAG, PRE_DISPATCH_PRICE_TAG, PRE_DISPATCH_DEMAND_TAG } from '../utility/constants'
import { getCollatedRegionProductIntervals } from '../redux/features/collated-interval'
import { IntervalConfig, IntervalTimeConfig } from '../redux/features/interval'
import { allMarkets, getAssets, mapInfo } from '../redux/features/dashboard'
import Card from '../components/Card'
import AssetTable from '../components/dashboard/AssetTable'
import Map from '../components/dashboard/map/Map'
import MarketIntelligenceCard from '../components/dashboard/MarketIntelligenceCard'
import { canOnlyPauseBidDelivery } from '../utility/user-utils'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: 'none',
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}))

const Dashboard = props => {
  const { defaultAsset, market, regions, currentAsOf, currentAsOfEndTime, canViewMarketIntelligence = false } = props
  const { dispatchGetAllMarkets, dispatchGetAssets, dispatchGetMapInfo, getRegionProductIntervalData } = props
  const classes = useStyles()

  useEffect(() => {
    dispatchGetAllMarkets()
    dispatchGetAssets()
    const getAssetHandle = setInterval(dispatchGetAssets, refreshInterval)
    return () => clearInterval(getAssetHandle)
  }, [dispatchGetAllMarkets, dispatchGetAssets])

  useEffect(() => {
    if (!_.isNil(market.name) && !_.isEmpty(market.name)) {
      dispatchGetMapInfo(market.name)
    }
  }, [market.name, dispatchGetMapInfo])

  function getValidForText(startTime) {
    if (startTime) {
      return `LAST UPDATED: ${startTime.format(dateTimeFormatWithSeconds)}`
    }
    return 'LAST UPDATED: '
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card title="Asset Portfolio">
          <AssetTable />
        </Card>
      </Grid>
      {canViewMarketIntelligence && (
        <Grid item xs={12} lg={6} xl={5}>
          <Box>
            <Card
              classes={{
                header: classes.header,
                content: classes.content,
              }}
              title="NEM Dispatch Overview"
              subheader={getValidForText(currentAsOf)}
              isFullBleed
            >
              <Map market={market} />
            </Card>
          </Box>
        </Grid>
      )}
      {canViewMarketIntelligence && (
        <Grid item xs={12} lg={6} xl={7}>
          <MarketIntelligenceCard
            defaultAsset={defaultAsset}
            market={market}
            regions={regions}
            currentAsOf={currentAsOf}
            currentAsOfEndTime={currentAsOfEndTime}
            getRegionProductIntervalData={getRegionProductIntervalData}
          />
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = state => {
  const user = _.get(state, 'user.current.payload', {})
  const markets = _.get(state, 'dashboard.markets.payload', [])
  const marketsMapByName = _.keyBy(markets, m => m.name.toLowerCase())
  const market = _.get(marketsMapByName, 'aemo', {})
  const mapInfo = _.get(state, 'dashboard.map.payload', {})
  const latestIntervalTime = mapInfo.latestIntervalTime
  const regions = _.get(mapInfo, 'regions', [])
  const assets = _.get(state, 'dashboard.assets.payload', [])
  const defaultAsset = _.first(assets)
  const canViewMarketIntelligence = !assets.some(asset => canOnlyPauseBidDelivery(user, asset))

  let currentAsOf = null
  let currentAsOfEndTime = null
  if (!!latestIntervalTime && markets.length > 0) {
    currentAsOf = moment(latestIntervalTime).tz(market.data.timezone)
    currentAsOfEndTime = currentAsOf.clone().add(market.data.bid_interval, 'minutes')
  }

  return {
    currentAsOf,
    currentAsOfEndTime,
    defaultAsset,
    market,
    regions,
    canViewMarketIntelligence,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchGetAllMarkets: () => {
    dispatch(allMarkets.get())
  },
  dispatchGetAssets: () => {
    dispatch(getAssets())
  },
  dispatchGetMapInfo: marketName => {
    dispatch(mapInfo.get(marketName, moment()))
  },
  getRegionProductIntervalData: (asset, regionId, today) => {
    const now = moment().add(asset.market.data.bid_interval, 'minutes')
    const productIds = getAllPriceProductIds(asset)
    const intervalTimeConfig = new IntervalTimeConfig(moment(today), moment(today).add(1, 'd'), now)
    const p1 = dispatch(
      getCollatedRegionProductIntervals(
        new IntervalConfig(
          `${regionId}_${AMS_FORECAST_TAG}_allProducts`,
          AMS_FORECAST_TAG,
          null,
          null,
          productIds,
          regionId,
        ),
        intervalTimeConfig,
      ),
    )
    const p2 = dispatch(
      getCollatedRegionProductIntervals(
        new IntervalConfig(
          `${regionId}_${PRE_DISPATCH_PRICE_TAG}_allProducts`,
          PRE_DISPATCH_PRICE_TAG,
          null,
          null,
          productIds,
          regionId,
        ),
        intervalTimeConfig,
      ),
    )
    const p3 = dispatch(
      getCollatedRegionProductIntervals(
        new IntervalConfig(
          `${regionId}_${PRE_DISPATCH_DEMAND_TAG}_allProducts`,
          PRE_DISPATCH_DEMAND_TAG,
          null,
          null,
          productIds,
          regionId,
        ),
        intervalTimeConfig,
      ),
    )
    return Promise.all([p1, p2, p3])
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
