import PropTypes from 'prop-types'

const MarketAssetData = {
  getMostRecentAssetDataRecord: {
    name: 'getMostRecentAssetDataRecord',
    method: 'GET',
    url: ({ assetId }) => `/market/assets/asset/${assetId}/assetData/last`,
    pathParams: { assetId: PropTypes.number },
    queryParams: {
      'tag': PropTypes.string.isRequired,
      'startTime': PropTypes.string.isRequired,
      'asOf': PropTypes.string.isRequired,
      'deleted': PropTypes.bool.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getAssetById: {
    name: 'getAssetById',
    method: 'GET',
    url: ({ assetId }) => `/market/assets/${assetId}`,
    pathParams: { assetId: PropTypes.number },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketAssetData
