import { format } from 'util'
import _ from 'lodash'
import passwordSheriff from 'password-sheriff'
import {
  CAN_ACCESS_API,
  CAN_ACCESS_UI,
  CAN_ADJUST_PRICE_BANDS,
  CAN_ADJUST_RISK_APPETITE,
  CAN_DO_ANYTHING_PERMISSION,
  CAN_ONLY_PAUSE_BID_DELIVERY,
  CAN_PAUSE_BID_DELIVERY,
  CAN_SUBMIT_MANUAL_BIDS,
} from './constants'

const PASSWORD_POLICY_DESC_BY_CODE = {
  lengthAtLeast: 'Password length',
  containsAtLeast: 'Must contain',
  identicalChars: 'Duplicate characters',
}
const PasswordPolicy = passwordSheriff.PasswordPolicy

const charsets = passwordSheriff.charsets

const upperCase = charsets.upperCase
const lowerCase = charsets.lowerCase
const numbers = charsets.numbers
const specialCharacters = charsets.specialCharacters

const excellentPasswordPolicy = new PasswordPolicy({
  length: { minLength: 10 },
  containsAtLeast: {
    atLeast: 3,
    expressions: [lowerCase, upperCase, numbers, specialCharacters],
  },
  identicalChars: { max: 2 },
})

export const validatePassword = password => {
  const passwordPolicyMissing = excellentPasswordPolicy.missing(password)
  passwordPolicyMissing.rules.forEach(rule => {
    rule.description = format.apply(null, [rule.message].concat(rule.format))
    rule.summary = PASSWORD_POLICY_DESC_BY_CODE[rule.code]
    if (rule.items) {
      rule.condensedItemSummary = ''
      rule.items.forEach(item => {
        rule.condensedItemSummary += ' - ' + item.message
      })
    }
  })
  return passwordPolicyMissing
}

export const canAdjustPriceBands = (permissions, asset) => {
  return canAccess(CAN_ADJUST_PRICE_BANDS, permissions, asset)
}

export const canPauseBidDelivery = (permissions, asset) => {
  return canAccess(CAN_PAUSE_BID_DELIVERY, permissions, asset)
}

export const canOnlyPauseBidDelivery = (currentUser, asset) => {
  // When canOnlyPauseBidDelivery permission is true, this bid-only user can only pause/unpause bid delivery
  // and can only access the Home page, and Bids page for a particular asset.
  // Also, this bid-only user will not be allowed to have any other permissions (e.g. canSubmitManualBids) for a particular asset/customer.

  const canAccessUi = _.get(currentUser, CAN_ACCESS_UI)
  const canPauseBidDelivery = canAccess(CAN_PAUSE_BID_DELIVERY, _.get(currentUser, 'permissions', {}), asset)
  return !canAccessUi && canPauseBidDelivery
}

export const canSubmitManualBids = (permissions, asset) => {
  return canAccess(CAN_SUBMIT_MANUAL_BIDS, permissions, asset)
}

export const canAdjustRiskAppetite = (permissions, asset) => {
  return canAccess(CAN_ADJUST_RISK_APPETITE, permissions, asset)
}

export const hasApiAccess = permissions => {
  return canAccess(CAN_ACCESS_API, permissions)
}

export const hasRootLevelPermission = (permissionName, permissions) => {
  const marketPermissions = _.get(permissions, 'marketPermissions', [])
  return marketPermissions.includes(permissionName) || marketPermissions.includes(CAN_DO_ANYTHING_PERMISSION)
}

export const canAccessImpl = (permissionNameProp, permissions, asset) => {
  const hasRootLevelAccess = hasRootLevelPermission(permissionNameProp, permissions)
  if (hasRootLevelAccess) {
    return hasRootLevelAccess
  }
  const customerPermissions = _.get(permissions, 'customerPermissions', [])
  const assetPermissions = _.get(permissions, 'assetPermissions', [])

  if (_.isNil(asset)) {
    const hasAnyCustomerLevel = _.some(customerPermissions, permissionObj =>
      permissionObj.permissions.includes(permissionNameProp),
    )
    const hasAnyAssetLevel = _.some(assetPermissions, permissionObj =>
      permissionObj.permissions.includes(permissionNameProp),
    )
    return hasAnyCustomerLevel || hasAnyAssetLevel
  } else {
    const customerId = _.get(asset, 'data.customer_id')
    const customerPermissionObj = customerPermissions.find(c => c.customerId === customerId)
    const hasCustomerLevelAccess =
      !_.isNil(customerId) && _.get(customerPermissionObj, 'permissions', []).includes(permissionNameProp)
    if (hasCustomerLevelAccess) {
      return hasCustomerLevelAccess
    }
    const assetId = _.get(asset, 'assetId')
    const assetPermissionsObj = assetPermissions.find(c => c.assetId === assetId)
    const hasAssetLevelPermissions =
      !_.isNil(assetId) && _.get(assetPermissionsObj, 'permissions', []).includes(permissionNameProp)

    return hasAssetLevelPermissions
  }
}

export const canAccess = _.memoize(canAccessImpl, (permissionName, permissions, asset) => {
  if ([permissionName, permissions].some(_.isNil)) {
    return ''
  }
  const cacheKey = `${permissionName}.${_.get(asset, 'assetId', '')}.${JSON.stringify(permissions)}`
  return cacheKey
})

export const canAccessRoutePermission = (permissionName, user, asset) => {
  const userPermissions = user.permissions
  let negation = false
  if (permissionName[0] === '!') {
    permissionName = permissionName.substring(1)
    negation = true
  }
  if (permissionName === CAN_ONLY_PAUSE_BID_DELIVERY) {
    return negation ? !canOnlyPauseBidDelivery(user, asset) : canOnlyPauseBidDelivery(user, asset)
  } else {
    return negation
      ? !canAccess(permissionName, userPermissions, asset)
      : canAccess(permissionName, userPermissions, asset)
  }
}
