import PropTypes from 'prop-types'

const MarketOptimizations = {
  getOptimizationRecordsRange: {
    name: 'getOptimizationRecordsRange',
    method: 'GET',
    url: ({ assetId }) => `/market/optimizations/asset/${assetId}/range`,
    pathParams: { assetId: PropTypes.number },
    queryParams: {
      'startTime': PropTypes.string.isRequired,
      'endTime': PropTypes.string.isRequired,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketOptimizations
