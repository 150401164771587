import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, Grid, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  availabilityTag,
  findProduct,
  getTradingStart,
  marketPriceTag,
  settlementTag,
  enablementTag,
  PRODUCT_NAMES_SORT_ORDER,
  getCardSubtitleByDate,
} from '../../utility/utility'
import {
  getDisabledProductTypeToggleNames,
  getFirstActiveProductTypeName,
  shouldShowProductTypeToggle,
  isAssetNonScheduledBattery,
} from '../../utility/asset-utils'
import { IntervalConfig, IntervalTimeConfig } from '../../redux/features/interval'
import { getAssetProductInterval, getPriceForecastsAll } from '../../redux/features/forecast'
import Card from '../Card'
import Select from '../Select'
import ProductToggle from '../toggles/ProductToggle'
import ProductTypeToggle from '../toggles/ProductTypeToggle'
import HorizonToggle from '../toggles/HorizonToggle'
import { HORIZONS, HORIZON_DURATIONS, PRODUCT_TYPES } from '../../utility/constants'
import DateRangePicker from '../DateRangePicker'
import ForecastGraphs from './graphs/ForecastGraphs'

const DISPLAY_NAME = 'AssetForecastProducts'
const MAX_DATE_RANGE = moment.duration(3, 'months')

const getQuickPickStartEndTimes = (quickPick, market) => {
  const tz = _.get(market, 'data.timezone', 'Etc/GMT-10')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const result = {
    startTime: null,
    endTime: null,
  }
  const currentStart = getTradingStart(market)
  const startOfMonth = moment(currentStart).tz(tz).startOf('month').add(marketStartHour, 'hours')

  switch (quickPick) {
    case QUICK_PICKS.TOMORROW: {
      const startTime = moment(currentStart).add(1, 'd')
      const endTime = moment(startTime).add(1, 'd')
      return { startTime, endTime }
    }
    case QUICK_PICKS.TODAY: {
      const startTime = moment(currentStart)
      const endTime = moment(startTime).add(1, 'days')
      return { startTime, endTime }
    }
    case QUICK_PICKS.YESTERDAY: {
      const startTime = moment(currentStart).subtract(1, 'days')
      const endTime = moment(startTime).add(1, 'days')
      return { startTime, endTime }
    }
    case QUICK_PICKS.LAST3: {
      const startTime = moment(currentStart).subtract(2, 'days')
      const endTime = moment(currentStart).add(1, 'days')
      return { startTime, endTime }
    }
    case QUICK_PICKS.MTD: {
      const startTime = moment(startOfMonth)
      const endTime = moment(startOfMonth).add(1, 'month')
      return { startTime, endTime }
    }
    case QUICK_PICKS.YESTERMONTH: {
      const startTime = moment(startOfMonth).subtract(1, 'months')
      const endTime = moment(startOfMonth)
      return { startTime, endTime }
    }
    default:
      return result
  }
}

const QUICK_PICKS = {
  TOMORROW: 'tomorrow',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST3: 'last3',
  MTD: 'month',
  YESTERMONTH: 'yestermonth',
  CUSTOM: 'custom',
}
const DATE_QUICK_PICK_OPTS = [
  { value: 'today', display: 'Today' },
  { value: 'tomorrow', display: 'Tomorrow' },
  { value: 'yesterday', display: 'Yesterday' },
  { value: 'last3', display: 'Last 3 days' },
  { value: 'month', display: 'Current Month' },
  { value: 'yestermonth', display: 'Last Month' },
  { value: 'custom', display: 'Custom' },
]

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 400,
    },
  }),
  { name: DISPLAY_NAME },
)

function AssetForecastProducts(props) {
  const classes = useStyles(props)
  const { className: classNameProp, asset, market, marketStartHour, timezone = 'Etc/GMT-10' } = props
  const assetId = _.get(asset, 'assetId')
  const { getAssetForecastProductIntervalData } = props
  moment.tz.setDefault(timezone)
  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNames = _.get(asset, 'market.data.product_display_names', {})
  const productTypes = _.get(asset, 'productTypes', [])
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})

  const [selectedTime, setSelectedTime] = useState({
    startTime: null,
    endTime: null,
  })
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedProductType, setSelectedProductType] = useState()
  const [selectedHorizon, setSelectedHorizon] = useState(0)

  const [dateQuickPick, setDateQuickPick] = useState('today')
  const [displayRange, setDisplayRange] = useState({
    start: null,
    end: null,
  })
  const [lastUpdated, setLastUpdated] = useState()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!_.isEmpty(productTypes)) {
      setSelectedProductType(getFirstActiveProductTypeName(asset))
    }
  }, [productTypes, asset])

  useEffect(() => {
    if (!_.isEmpty(asset) && !_.isEmpty(market) && _.isNil(selectedTime.startTime)) {
      const initialSelectedDate = getTradingStart(market)
      setSelectedTime(() => {
        const start = moment(initialSelectedDate)
        const end = moment(initialSelectedDate).add(1, 'day')
        setDisplayRange({ start: start.unix(), end: end.unix() })
        return {
          startTime: start,
          endTime: end,
        }
      })
    }
  }, [asset, market, marketStartHour, selectedTime])

  useEffect(() => {
    if (_.isNil(selectedProduct) && !_.isEmpty(productNames)) {
      // _.intersection() order is determined by the first array.
      const validProductNamesSorted = _.intersection(PRODUCT_NAMES_SORT_ORDER, productNames)
      setSelectedProduct(_.first(validProductNamesSorted))
    }
  }, [selectedProduct, productNames])

  useEffect(() => {
    if (
      !_.isEmpty(asset) &&
      ![selectedTime.startTime, selectedProduct, selectedProductType, selectedHorizon].some(_.isNil) &&
      productTypes.includes(selectedProductType)
    ) {
      const result = getAssetForecastProductIntervalData(
        asset,
        selectedTime.startTime,
        selectedTime.endTime,
        selectedProduct,
        selectedProductType,
        selectedHorizon,
      )

      Promise.resolve(result).then(() => {
        setLoading(false)
        setLastUpdated(moment().tz(timezone))
      })
    }
  }, [
    asset,
    assetId,
    getAssetForecastProductIntervalData,
    productTypes,
    selectedProduct,
    selectedTime,
    selectedProductType,
    selectedHorizon,
    timezone,
  ])

  const handleProductChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedProduct(newSelection)
    }
  }

  const handleProductTypeChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedProductType(newSelection)
    }
  }

  const handleHorizonChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedHorizon(newSelection)
    }
  }

  const handleDatePick = event => {
    const value = _.get(event, 'target.value')
    if (!_.isNil(value)) {
      setLoading(() => {
        setDateQuickPick(value)
        return value !== 'custom'
      })
    }
    const newSelectedDateRange = getQuickPickStartEndTimes(value, market)
    if (newSelectedDateRange.startTime && newSelectedDateRange.endTime) {
      setLoading(() => {
        setSelectedTime(() => {
          setDisplayRange({ start: newSelectedDateRange.startTime.unix(), end: newSelectedDateRange.endTime.unix() })
          return newSelectedDateRange
        })
        return true
      })
    }
  }

  const startMaxDate = !_.isEmpty(market) ? getTradingStart(market).add(1, 'days').valueOf() : moment().valueOf()
  const lastValidTradingDay = moment(startMaxDate).endOf('day').valueOf()

  const handleStartDateChange = date => {
    if (moment(date).isValid()) {
      const proposedNewStartTime = moment(date).startOf('day').add(marketStartHour, 'hours')

      const newStartTime = moment(Math.min(proposedNewStartTime.valueOf(), startMaxDate))

      if (!moment(newStartTime).isSame(selectedTime.startTime, 'day')) {
        setLoading(() => {
          setSelectedTime(prevSelectedTime => {
            const timeDifference = moment.duration(moment(prevSelectedTime.endTime).diff(newStartTime))
            let newEndTime = null
            if (timeDifference.valueOf() > MAX_DATE_RANGE.valueOf()) {
              newEndTime = moment(newStartTime).add(3, 'months')
            } else if (timeDifference.valueOf() < 0) {
              newEndTime = moment(newStartTime)
            } else {
              newEndTime = prevSelectedTime.endTime
            }

            setDisplayRange({ start: newStartTime.unix(), end: newEndTime.unix() })
            return {
              startTime: newStartTime,
              endTime: newEndTime,
            }
          })
          return true
        })
      }
    }
  }

  const handleEndDateChange = date => {
    if (moment(date).isValid()) {
      const newProposedEndTime = moment(date).add(1, 'd').startOf('d').add(marketStartHour, 'hours')

      const actualMaxEndDate = moment(lastValidTradingDay).add(1, 'd').startOf('d').add(marketStartHour, 'hours')
      const newEndTime = Math.min(newProposedEndTime.valueOf(), actualMaxEndDate)

      if (!moment(newEndTime).isSame(selectedTime.endTime, 'day')) {
        setSelectedTime(prevSelectedTime => {
          const timeDifference = moment.duration(moment(newEndTime).diff(prevSelectedTime.startTime))
          let newStartTime = null
          if (timeDifference.valueOf() > MAX_DATE_RANGE.valueOf()) {
            newStartTime = getTradingStart(market, moment(newEndTime).subtract(3, 'months'))
          } else if (newEndTime < moment(prevSelectedTime.startTime).valueOf()) {
            newStartTime = getTradingStart(market, moment(newEndTime))
          } else {
            newStartTime = prevSelectedTime.startTime
          }

          setDisplayRange({ start: newStartTime.unix(), end: moment(newEndTime).unix() })
          return {
            startTime: newStartTime,
            endTime: moment(newEndTime),
          }
        })
      }
    }
  }

  const handleSetDisplayRangeChange = useCallback((start, end) => setDisplayRange({ start, end }), [setDisplayRange])

  const horizonIndex = HORIZON_DURATIONS.findIndex(h => h === selectedHorizon)
  const horizonDisplay = HORIZONS[horizonIndex] || ''
  const productDisplay = productDisplayNames[selectedProduct] || ''
  const productTypeDisplayName = _.get(productTypeDisplayNames, selectedProductType)
  const productTypeDisplay = _.isNil(productTypeDisplayName) ? '' : `${productTypeDisplayName}-Side`
  const title = `${horizonDisplay} Ahead Forecasts: ${productTypeDisplay} ${productDisplay}`

  const fromTime = moment.unix(displayRange.start)
  const toTime = moment.unix(displayRange.end)
  const subTitle = getCardSubtitleByDate(fromTime, toTime, lastUpdated, timezone, marketStartHour)

  const inProgress = loading || _.isEmpty(asset) || _.isNil(selectedTime.startTime)
  const disabledProductTypeToggleNames = getDisabledProductTypeToggleNames(asset)
  const showProductTypeToggle = shouldShowProductTypeToggle(asset)
  return (
    <Box className={clsx(classes.root, classNameProp)}>
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item>
            <Select value={dateQuickPick} onChange={handleDatePick}>
              {DATE_QUICK_PICK_OPTS.map(opt => (
                <MenuItem value={opt.value} key={opt.value}>
                  {opt.display}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {dateQuickPick === 'custom' && (
            <Grid item>
              <Box maxHeight="48">
                <DateRangePicker
                  startDate={selectedTime.startTime}
                  startMaxDate={startMaxDate}
                  endDate={selectedTime.endTime}
                  endMaxDate={lastValidTradingDay}
                  marketStartHour={marketStartHour}
                  timezone={timezone}
                  onStartDateChange={handleStartDateChange}
                  onEndDateChange={handleEndDateChange}
                />
              </Box>
            </Grid>
          )}

          {showProductTypeToggle && (
            <Grid item>
              <ProductTypeToggle
                disabledTypes={disabledProductTypeToggleNames}
                selected={selectedProductType}
                productTypes={productTypes}
                productTypeDisplayNames={productTypeDisplayNames}
                onChange={handleProductTypeChange}
              />
            </Grid>
          )}
          <Grid item>
            <HorizonToggle
              selected={selectedHorizon}
              horizonDurations={HORIZON_DURATIONS}
              horizonDisplayNames={HORIZONS}
              onChange={handleHorizonChange}
            />
          </Grid>
          <Grid item>
            <ProductToggle
              asset={asset}
              selectedTime={selectedTime.endTime}
              selected={selectedProduct}
              productNames={productNames}
              productDisplayNames={productDisplayNames}
              onChange={handleProductChange}
            />
          </Grid>
          <Grid item />
        </Grid>
      </Box>
      <Card title={title} subheader={subTitle} action="" className={classes.card}>
        <ForecastGraphs
          asset={asset}
          inProgress={inProgress}
          horizon={selectedHorizon}
          timezone={timezone}
          productName={selectedProduct}
          productType={selectedProductType}
          displayRange={displayRange} // visible range (zoom etc)
          setDisplayRange={handleSetDisplayRangeChange}
          startTime={selectedTime.startTime} // data start
          endTime={selectedTime.endTime} // data end
        />
      </Card>
    </Box>
  )
}
AssetForecastProducts.displayName = DISPLAY_NAME

const mapStateToProps = state => ({
  forecast: state.forecast,
})

const mapDispatchToProps = (dispatch, ownState) => ({
  getAssetForecastProductIntervalData: (asset, start, end, productName, productType, horizon) => {
    const isCurrentAssetNonScheduledBattery = isAssetNonScheduledBattery(asset)
    const timezone = _.get(asset, 'market.data.timezone')
    const tradingHourStart = _.get(asset, 'market.data.trading_day_start_hour')
    let actualStartTime = moment(start).tz(timezone).startOf('day').add(tradingHourStart, 'hours')
    if (actualStartTime.isAfter(start)) {
      actualStartTime = actualStartTime.subtract(1, 'd')
    }

    const productId = findProduct(asset, productName, productType).productId
    const priceProductId = findProduct(asset, productName, asset.market.data.default_product_type).productId

    const intervalTimeConfig = new IntervalTimeConfig(
      actualStartTime,
      end,
      null,
      null,
      horizon,
      asset.market.data.bid_interval,
    )
    const p1 = dispatch(
      getPriceForecastsAll(
        new IntervalConfig(
          `${asset.assetId}_${priceProductId}_${marketPriceTag}_${horizon}`,
          marketPriceTag,
          asset.assetId,
          priceProductId,
          null,
          null,
        ),
        intervalTimeConfig,
      ),
    )
    const p2 = dispatch(
      getAssetProductInterval(
        new IntervalConfig(
          `${asset.assetId}_${productId}_${settlementTag}_${horizon}`,
          settlementTag,
          asset.assetId,
          productId,
          null,
        ),
        intervalTimeConfig,
      ),
    )
    const p3 = dispatch(
      getAssetProductInterval(
        new IntervalConfig(
          `${asset.assetId}_${productId}_${enablementTag}_${horizon}`,
          enablementTag,
          asset.assetId,
          productId,
          null,
        ),
        intervalTimeConfig,
      ),
    )
    const p4 = dispatch(
      getAssetProductInterval(
        new IntervalConfig(
          `${asset.assetId}_${productId}_${availabilityTag}_${horizon}`,
          availabilityTag,
          asset.assetId,
          productId,
          null,
        ),
        intervalTimeConfig,
      ),
    )

    const promises = [..._.values(p1), ..._.values(p2), ..._.values(p3), ..._.values(p4)]

    if (isCurrentAssetNonScheduledBattery) {
      const streams = [settlementTag, enablementTag, availabilityTag]
      const otherProductType = productType === PRODUCT_TYPES.GEN ? PRODUCT_TYPES.LOAD : PRODUCT_TYPES.GEN
      const productId = findProduct(asset, productName, otherProductType).productId

      streams.forEach(tag => {
        const q = dispatch(
          getAssetProductInterval(
            new IntervalConfig(`${asset.assetId}_${productId}_${tag}_${horizon}`, tag, asset.assetId, productId, null),
            intervalTimeConfig,
          ),
        )
        promises.push(..._.values(q))
      })
    }

    return Promise.all(promises)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetForecastProducts)
