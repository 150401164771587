import _ from 'lodash'
import moment from 'moment-timezone'
import { OPTIMIZATION_SETTINGS_TAG } from '../../../utility/constants'
import { API } from '../../api'
import { CACHE_API } from '../../cachingMiddleware'
import actions from './actions'
import TYPES from './types'

export const getAllAssets = (shouldCache = true) => (dispatch, getState) => {
  const action = API.marketFrontendAssets.all('AEMO', TYPES.AF_GET_ALL_ASSETS, shouldCache)
  return dispatch(action)
}

export const getAllAssetsCached = () => {
  return {
    [CACHE_API]: {
      mapStateToCache: state => _.get(state, 'asset.assets.all'),
      mapStateToUpdatedOn: state => _.get(state, 'asset.assets.allUpdatedOn'),
      fetchAction: dispatch => dispatch(API.marketFrontendAssets.all('AEMO', TYPES.AF_GET_ALL_ASSETS)),
      invalidateAfter: '00:05:00',
    },
  }
}

export const selectAssetId = assetId => dispatch => dispatch(actions.selectAssetId(assetId))

export const updateRiskSetting = (assetId, riskSetting) => dispatch => {
  dispatch(actions.updateRiskSetting(assetId, riskSetting))
}

export const updateAssetData = (assetId, property, data) => dispatch => {
  dispatch(actions.updateAssetData(assetId, property, data))
}

const fetchAssetOptimizationDisabled = (
  assetId,
  startTime,
  asOf,
  cache = false,
  tag = OPTIMIZATION_SETTINGS_TAG,
  deleted = false,
) => {
  const startTimeFormatted = moment(startTime).toISOString()
  const asOfFormatted = moment(asOf).toISOString()

  return API.marketAssetData.getMostRecentAssetDataRecord({
    pathParams: { assetId },
    queryParams: {
      tag,
      startTime: startTimeFormatted,
      asOf: asOfFormatted,
      deleted,
    },
    types: TYPES.ASSET_GET_OPT_DISABLED,
    meta: {
      updated: moment().format(),
      assetId,
      startTime: startTimeFormatted,
    },
    cache,
  })
}

export const getAssetOptimizationDisabled = (assetId, startTime, asOf) => dispatch => {
  return dispatch(fetchAssetOptimizationDisabled(assetId, startTime, asOf))
}

const operations = {
  getAllAssets,
  getAllAssetsCached,
  getAssetOptimizationDisabled,
  selectAssetId,
  updateRiskSetting,
  updateAssetData,
}

export default operations
