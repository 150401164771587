import PropTypes from 'prop-types'
import { propTypeIsoString } from '../../utility/custom-proptypes'
/**
 * Get aggregated intervals.
 * tags (examples):
 *     customer_battery_kw_hf4
 *     customer_battery_kwh_hf4
 *     customer_battery_available_capacity_kwh_hf4
 *     customer_battery_available_discharge_kw_hf4
 *     customer_battery_available_charge_kw_hf4
 * duration: ammount of time (seconds) between the returned datapoints. e.g. duration = 60 would return a dataset with values 1 minute apart.
 * aggregationFunction:
 *     'sum': [DEFAULT] data for each interval will be the sum of all raw data points over that time. (todo: confirm this)
 *     'average': data for each interval will be the average value of the raw data.
 * filterDirection:
 *     'none': [DEFAULT] No filter will be applied.
 *     'positive': Only positive numbers will be returned.
 *     'negative': Only negative numbers will be returned.
 *
 * e.g. curl -X GET --header 'Accept: application/json' --header 'Authorization: Bearer XXXXXXXX' 'https://markets-staging.amsarmada.com/api/market/intervals/asset/79/range/aggregated?tag=customer_battery_kw_hf4&startTime=2021-12-17T18%3A00%3A00.000Z&endTime=2021-12-18T18%3A00%3A00.000Z&duration=300&aggregationFunction=sum&filterDirection=none'
 */
const MarketIntervals = {
  getAssetIntervals: {
    name: 'getAssetIntervals',
    method: 'GET',
    url: ({ assetId }) => `/market/intervals/asset/${assetId}/range`,
    pathParams: { assetId: PropTypes.number.isRequired },
    queryParams: {
      'tag': PropTypes.string.isRequired,
      'startTime': propTypeIsoString,
      'endTime': propTypeIsoString,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getAssetAggregatedIntervals: {
    name: 'getAssetAggregatedIntervals',
    method: 'GET',
    url: ({ assetId }) => `/market/intervals/asset/${assetId}/range/aggregated`,
    pathParams: { assetId: PropTypes.number.isRequired },
    queryParams: {
      'tag': PropTypes.string.isRequired,
      'startTime': propTypeIsoString,
      'endTime': propTypeIsoString,
      'duration': PropTypes.number,
      'aggregationFunction': PropTypes.oneOf(['sum', 'average']),
      'filterDirection': PropTypes.oneOf(['none', 'positive', 'negative']),
      'ifFillNaN': PropTypes.bool,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getAssetAggregatedIntervalsMultiple: {
    name: 'getAssetAggregatedIntervalsMultiple',
    method: 'GET',
    url: ({ assetId }) => `/market/intervals/asset/${assetId}/range/aggregated/tags`,
    pathParams: { assetId: PropTypes.number.isRequired },
    queryParams: {
      'tags': PropTypes.array.isRequired,
      'startTime': propTypeIsoString,
      'endTime': propTypeIsoString,
      'duration': PropTypes.number,
      'aggregationFunction': PropTypes.oneOf(['sum', 'average']),
      'filterDirection': PropTypes.oneOf(['none', 'positive', 'negative']),
      'ifFillNaN': PropTypes.bool,
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getRangeOfActiveAssetScheduledData: {
    name: 'getRangeOfActiveAssetScheduledData',
    method: 'GET',
    url: ({ assetId }) => `/market/frontend/assets/asset/${assetId}/assetScheduledData/range`,
    pathParams: { assetId: PropTypes.number.isRequired },
    queryParams: {
      'startTime': propTypeIsoString,
      'endTime': propTypeIsoString,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketIntervals
