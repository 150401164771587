import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Graph, GraphGroup, Legend } from '../../graph/GraphGroup'
import * as common from '../../../graphs/common'
import { getIndex, getTimeAtStartOf, settlementTag } from '../../../utility/utility'
import { FIVE_MINUTE_DURATION, ONE_HOUR_DURATION } from '../../../utility/constants'
import { getTimeSeriesGraph } from '../../../utility/graphUtility'
import { settlementGraphTemplate } from '../../../utility/graphTemplate'
import { getIntervalKey } from '../../../redux/features/interval'
import * as marketResultsGraphConfigs from './marketResultsGraphConfigs'

const DISPLAY_NAME = 'SettlementsGraph'

const SettlementsGraph = props => {
  const { asset, displayRange, setDisplayRange, startTime, endTime } = props

  const { collatedIntervalsSettlements } = props

  const timezone = _.get(asset, 'market.data.timezone')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const groupColors = _.get(asset, 'market.data.group_colors', {})
  const productNames = _.get(asset, 'productNames', [])
  const productTypes = _.get(asset, 'productTypes', [])
  const productGroups = _.get(asset, 'market.data.product_groups', {})
  const productGroupOrder = _.get(asset, 'market.data.product_groups_order', [])
  const bidInterval = _.get(asset, 'market.data.bid_interval')

  const range = {
    start: moment(startTime).unix(),
    end: moment(endTime).unix(),
  }

  const graphConfigs = useMemo(
    () =>
      createGraph(
        collatedIntervalsSettlements,
        startTime,
        bidInterval,
        timezone,
        productGroupOrder,
        productGroups,
        productTypes,
        productNames,
        groupColors,
      ),
    [
      collatedIntervalsSettlements,
      startTime,
      bidInterval,
      timezone,
      productGroupOrder,
      productGroups,
      productTypes,
      productNames,
      groupColors,
    ],
  )

  const FIVE_MINUTES = FIVE_MINUTE_DURATION.asMilliseconds()
  const getEndOfInterval = time => {
    if (!_.isNil(time)) {
      const elapsedTimeInMs = time.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(time.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      return newEndOfInterval
    }
  }

  const nextInterval = getEndOfInterval(moment()).unix()

  const context = useMemo(() => ({ timezone: timezone, marketStartHour, nowFloorDuration: ONE_HOUR_DURATION }), [
    marketStartHour,
    timezone,
  ])
  return (
    <GraphGroup
      currentTime={getTimeAtStartOf(moment(), FIVE_MINUTE_DURATION.asMinutes()).unix()}
      setDisplayRange={setDisplayRange}
      displayRange={displayRange}
      range={range}
      context={context}
    >
      <Graph
        name="Settlements"
        height={200}
        getData={graphConfigs.getData}
        formatSeries={marketResultsGraphConfigs.formatSettlementsSeries}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatSettlementsYAxis}
        formatTooltip={common.formatTooltip}
      />
      <Legend items={_.get(graphConfigs, 'legendItems', [])} />
    </GraphGroup>
  )
}

SettlementsGraph.displayName = DISPLAY_NAME

SettlementsGraph.propTypes = {
  asset: PropTypes.object,
  displayRange: PropTypes.object.isRequired,
  endTime: PropTypes.object,
  setDisplayRange: PropTypes.func.isRequired,
  startTime: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  const { asset, startTime } = ownProps
  const assetId = _.get(asset, 'assetId')
  const collatedIntervals = _.get(state, 'collated.intervals')

  const keyPrefix = `${assetId}_${settlementTag}_allProducts`
  const key = getIntervalKey(keyPrefix, moment(startTime))
  const collatedIntervalsSettlements = _.get(collatedIntervals, key)

  return {
    collatedIntervalsSettlements,
  }
}

export default connect(mapStateToProps)(SettlementsGraph)

const createGraph = (
  collatedIntervalsSettlements,
  startTime,
  bidInterval,
  timezone,
  productGroupOrder,
  productGroups,
  productTypes,
  productNames,
  groupColors,
) => {
  let values = null
  const isLoading = _.get(collatedIntervalsSettlements, 'isLoading')
  if (!_.isNil(isLoading) && !isLoading) {
    // end time exclusive and intervalMinutes must be less than a day
    const time = moment(startTime).tz(timezone).add(1, 'days').subtract(bidInterval, 'minutes')
    const start = moment(startTime).tz(timezone)
    const end = moment(startTime).tz(timezone).add(1, 'days')
    const intervalMinutes = bidInterval
    const endIndex = getIndex(time, start, end, intervalMinutes)
    values = productGroupOrder.map(group =>
      _.range(0, endIndex + 1).map(intervalIndex => {
        let sum = 0
        productGroups[group].forEach(product => {
          if (productTypes.includes(product.type)) {
            const productTypeIndex = productTypes.findIndex(productType => productType === product.type)
            const productNameIndex = productNames.findIndex(productName => productName === product.name)
            const productIndex = productTypes.length * productNameIndex + productTypeIndex
            sum += _.get(collatedIntervalsSettlements, ['data', 'values', productIndex, intervalIndex], 0)
          }
        })
        return sum
      }),
    )
  }

  const settlementIntervals = {
    startTime: _.get(collatedIntervalsSettlements, 'data.startTime'),
    values,
    forecastStartTime: _.get(collatedIntervalsSettlements, 'data.forecastStartTime'),
  }

  const extras = {
    colors: productGroupOrder.map(group => groupColors[group]),
  }
  const graph =
    _.isEmpty(values) || _.isEmpty(collatedIntervalsSettlements) || isLoading
      ? {}
      : getTimeSeriesGraph(
          _.cloneDeep(settlementGraphTemplate),
          `settlement-graph`,
          productGroupOrder,
          settlementIntervals,
          timezone,
          bidInterval * 60,
          extras,
        )

  const legendItems = productGroupOrder.map(productGroup => {
    return {
      id: productGroup,
      name: productGroup,
      color: groupColors[productGroup],
    }
  })

  const stripLineOptions = {
    type: 'line',
    name: 'stripLine',
    visible: false,
    axisXType: 'secondary',
    axisYType: 'secondary',
  }

  const getData = async ({ range }) => {
    if (_.isNil(graph)) {
      return
    }
    const lines = _.get(graph, 'series', [])
    const result = lines.reduce((acc, nextLine) => {
      const options = _.get(nextLine, 'options', {})
      acc[options.name] = options
      return acc
    }, {})
    result['stripLineOptions'] = stripLineOptions
    return result
  }

  return {
    graph,
    getData,
    legendItems,
  }
}
