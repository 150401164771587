import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

export default function ShotClock(props) {
  const {
    height,
    width,
    strokeWidth,
    radius,
    sectorPercentages,
    currentPercent,
    separatorPercent = 0.01,
    donutStartDeg = 0,
    actualStartOffset = 0,
    sectorColor = '#717881',
    color = '#EDB449',
    showSelected = true,
    selectedColor = 'white',
    selectedCircleRadius = 6,
  } = props
  const strokeDashArray = (2 * 22 * radius) / 7
  const remainingWidthPercent = 1 - sectorPercentages.length * separatorPercent
  const actualSectors = sectorPercentages.map(percent => percent * remainingWidthPercent)
  const actualPercent = currentPercent * remainingWidthPercent

  return (
    <svg width={width} height={height} viewBox="0 0 100 100" transform={`rotate(${-90})`}>
      {actualSectors.map((percent, index) => (
        <circle
          key={index}
          cx="50"
          cy="50"
          r={radius}
          stroke={sectorColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={strokeDashArray}
          strokeDashoffset={getStrokeDashOffset(strokeDashArray, percent)}
          transform={getTransform(actualSectors, index, separatorPercent, donutStartDeg)}
        />
      ))}

      {getCurrentTimeSVGs(
        actualPercent,
        actualSectors,
        separatorPercent,
        donutStartDeg,
        actualStartOffset,
        radius,
        color,
        strokeWidth,
        strokeDashArray,
      )}

      {showSelected && <circle cx="50" cy="50" r={selectedCircleRadius} fill={selectedColor} />}
    </svg>
  )
}

ShotClock.propTypes = {
  currentPercent: PropTypes.number.isRequired,
  donutStartDeg: PropTypes.number,
  height: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  sectorPercentages: PropTypes.array.isRequired,
  separatorPercent: PropTypes.number,
  strokeWidth: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

const getTransformAngle = (sectors, index, separatorPercent, donutStartDeg, isSeparator) => {
  const prevSectorTotal = _.sum(sectors.slice(0, isSeparator ? index + 1 : index))
  const totalPrevPercent = prevSectorTotal + separatorPercent * index
  return donutStartDeg + totalPrevPercent * 360
}

const getTransform = (sectors, index, separatorPercent, donutStartDeg, isSeparator) => {
  const transformAngle = getTransformAngle(
    sectors,
    index,
    separatorPercent,
    donutStartDeg,
    isSeparator,
  )
  return `rotate(${transformAngle} 50 50)`
}

const getStrokeDashOffset = (strokeDashArray, percent) =>
  strokeDashArray - strokeDashArray * percent

const getStartSector = (actualSectors, separatorPercent, donutStartDeg, actualStartOffset) => {
  const offset = actualStartOffset < 0 ? 360 + actualStartOffset : actualStartOffset
  if (offset >= 360 || offset <= -360) {
    throw new Error('Offset angle should be between -360 and  360')
  }
  // eslint-disable-next-line no-unused-vars
  for (const [index, percent] of actualSectors.entries()) {
    const actualStart = getTransformAngle(actualSectors, index, separatorPercent, donutStartDeg)
    const start = actualStart - donutStartDeg
    const end = percent * 360 + start
    if (end >= offset && offset >= start) {
      const fractionLeft = (end - offset) / (end - start)
      const startAngle = actualStart + (1 - fractionLeft) * (end - start)
      return [index, fractionLeft, startAngle]
    }
  }
  throw new Error('Offset angle is on a separator')
}

const getCurrentTimeSVGs = (
  currentPercent,
  actualSectors,
  separatorPercent,
  donutStartDeg,
  actualStartOffset,
  radius,
  sectorColor,
  strokeWidth,
  strokeDashArray,
) => {
  let [startIndex, fractionLeft, startAngle] = getStartSector(
    actualSectors,
    separatorPercent,
    donutStartDeg,
    actualStartOffset,
  )
  const SVGs = []

  while (currentPercent > 0) {
    startIndex = startIndex % actualSectors.length

    const transform =
      SVGs.length === 0
        ? startAngle
        : getTransformAngle(actualSectors, startIndex, separatorPercent, donutStartDeg)
    const percent =
      SVGs.length === 0
        ? Math.min(fractionLeft * actualSectors[startIndex], currentPercent)
        : Math.min(actualSectors[startIndex], currentPercent)

    currentPercent -= percent
    startIndex++

    SVGs.push(
      <circle
        key={transform.toString() + strokeDashArray}
        cx="50"
        cy="50"
        r={radius}
        stroke={sectorColor}
        strokeWidth={strokeWidth}
        fill="transparent"
        strokeDasharray={strokeDashArray}
        strokeDashoffset={getStrokeDashOffset(strokeDashArray, percent)}
        transform={`rotate(${transform} 50 50)`}
      />,
    )
  }
  return SVGs
}
