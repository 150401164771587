import React from 'react'
import AcceptTCModal from './AcceptTCModal.js'

function UserAgreementManager(props) {
  const { children, user, isAgreed, handleOnSubmit, versionId, userAgreementIsLoading } = props

  return (
    <>
      {!userAgreementIsLoading && !isAgreed && (
        <AcceptTCModal currentUser={user} versionId={versionId} action={handleOnSubmit} requiresAgreement />
      )}
      {!userAgreementIsLoading && isAgreed && children}
    </>
  )
}

export default UserAgreementManager
