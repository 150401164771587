import { combineReducers } from 'redux'

const DI_SET_CURRENT_TIME = 'DI_SET_CURRENT_TIME'
export const setCurrentTime = time => ({
  type: DI_SET_CURRENT_TIME,
  payload: time,
})

export const DI_SET_END_OF_INTERVAL = 'DI_SET_END_OF_INTERVAL'
export const setEndOfInterval = time => ({
  type: DI_SET_END_OF_INTERVAL,
  payload: time,
})

const status = (
  state = {
    endOfInterval: null,
    lastIntervalChange: null,
    startTime: null,
    endTime: null,
    realTimeStatus: null,
    cutoffTimes: [],
    cutoffTimePercentages: [],
  },
  action,
) => {
  switch (action.type) {
    case DI_SET_END_OF_INTERVAL:
      if (action.payload === state.endOfInterval) {
        return state
      }
      return {
        ...state,
        endOfInterval: action.payload,
      }
    default:
      return state
  }
}

export default combineReducers({
  status,
})
