import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, IconButton, Slider, Typography } from '@material-ui/core'
import { Button, Info, useNotifier } from '@fluence/core'
import { upsertAssetDataResource, risksResource } from '../../redux/features/setting'
import { assetOperations } from '../../redux/features/asset'
import { PRODUCT_NAMES, PRODUCT_TYPES } from '../../utility/constants'
import { getDisabledProductNames, isAssetRenewable } from '../../utility/asset-utils'
import { canAdjustRiskAppetite } from '../../utility/user-utils'

import Card from '../Card'
import RouteLeavingGuard from '../RouteLeavingGuard'
import BiddingRiskAppetiteInfo from './BiddingRiskAppetiteInfo'

const DISPLAY_NAME = 'RiskAppetite'

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
  },
  slider: {
    color: '#B0BEC4',
  },
}))

function RiskAppetite(props) {
  const { asset, permissions, createRiskSetting, dispatchGetAllRiskRecords, dispatchUpdateRiskSetting } = props
  const { notifySuccess, notifyError } = useNotifier()

  const classes = useStyles()

  const assetId = _.get(asset, 'assetId')
  const assetRiskSetting = _.get(asset, 'data.risk_setting')
  const hasPermission = canAdjustRiskAppetite(permissions, asset)

  const [riskSetting, setRiskSetting] = useState(_.cloneDeep(assetRiskSetting))
  const [modified, setModified] = useState(false)
  const [appetiteInfoOpen, setAppetiteInfoOpen] = useState(false)
  const [requestInProgress, setRequestInProgress] = useState(false)

  useEffect(() => {
    if (!_.isNil(assetRiskSetting)) {
      setRiskSetting(_.cloneDeep(assetRiskSetting))
    }
  }, [assetRiskSetting])

  useEffect(() => {
    setModified(false)
    setAppetiteInfoOpen(false)
  }, [assetId])

  const handleSubmit = () => {
    if (modified) {
      submit()
    }
  }

  const submit = () => {
    setRequestInProgress(true)
    return createRiskSetting(riskSetting).then(response => {
      if (response.error) {
        setRequestInProgress(false)
        notifyError('Something went wrong. Please try again')
      } else {
        dispatchUpdateRiskSetting(assetId, riskSetting)
        setModified(false)
        setRequestInProgress(false)
        dispatchGetAllRiskRecords(asset)
        notifySuccess('Bidding risk appetite updated successfully')
      }
      return response
    })
  }

  const handleSliderChange = productName => (event, value) => {
    if (hasPermission) {
      const newRiskSetting = {
        ...riskSetting,
        [productName]: value / 100,
      }
      setRiskSetting(newRiskSetting)
      setModified(!_.isEqual(newRiskSetting, assetRiskSetting))
    }
  }

  const CATEGORIES = ['ENERGY', 'RAISE', 'LOWER']
  const productNames = _.get(asset, 'productNames', [])
  const disabledProductNamesGEN = getDisabledProductNames(asset, PRODUCT_TYPES.GEN)
  const disabledProductNamesLOAD = getDisabledProductNames(asset, PRODUCT_TYPES.LOAD)
  const disabledProductNamesByConfigurationData = _.intersection(disabledProductNamesGEN, disabledProductNamesLOAD)
  const disabledProductNames = isAssetRenewable(asset)
    ? disabledProductNamesByConfigurationData.concat(
        _.filter(productNames, productName => productName !== PRODUCT_NAMES.ENERGY),
      )
    : disabledProductNamesByConfigurationData
  const productListsByCategory = CATEGORIES.map(category => {
    return productNames.filter(name => name.startsWith(category) && !disabledProductNames.includes(name))
  })
  const disableSave = requestInProgress || _.isEqual(assetRiskSetting, riskSetting)
  const sliderClasses = productName =>
    _.get(riskSetting, productName) === _.get(assetRiskSetting, productName) ? { root: classes.slider } : {}

  const handleSliderMethodsByName = productNames.reduce((acc, productName) => {
    acc[productName] = handleSliderChange(productName)
    return acc
  }, {})

  const title = 'Bidding Risk Appetite'
  return (
    <>
      <RouteLeavingGuard description={title} when={modified} />
      <Card
        classes={{ root: classes.card, content: classes.cardContent, header: classes.cardHeader }}
        title={
          <Box display="inline-flex" alignItems="center">
            <Box display="flex" flex="0 1 auto" mr={2}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box display="flex" flex="0 1 auto">
              <IconButton
                aria-label="Bidding Risk Appetite information"
                size="small"
                onClick={() => setAppetiteInfoOpen(true)}
              >
                <Info color="primary" fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
        action={
          hasPermission && (
            <Button variant="text" disabled={disableSave} onClick={handleSubmit}>
              save
            </Button>
          )
        }
      >
        <Box pt={4} pr={0} pb={4} pl={4} mr={-5} width="100%" overflow="hidden">
          <Grid container spacing={6}>
            {productListsByCategory.map((productList, key) => {
              return (
                <Grid item xs={12} container spacing={6} key={key}>
                  {productList.map(productName => {
                    const productDisplayName = _.get(asset, `market.data.product_display_names[${productName}]`)
                    const productDisplayValue = _.isNaN(_.get(riskSetting, productName) * 100)
                      ? 0
                      : Number.parseInt((_.get(riskSetting, productName) * 100).toFixed(0))
                    const isAssetMultiProduct = _.flatten(productListsByCategory).length > 1
                    const rowContainsFewProducts = productList.length < 3
                    const sliderMdValue =
                      isAssetMultiProduct && rowContainsFewProducts
                        ? Math.floor(12 / (productList.length * 3))
                        : Math.floor(12 / productList.length)

                    const sliderSmValue =
                      isAssetMultiProduct && rowContainsFewProducts
                        ? Math.floor(12 / (productList.length * 3))
                        : Math.floor(12 / productList.length)
                    return (
                      <Grid item key={productName} xs={12} sm={sliderSmValue} md={sliderMdValue}>
                        <Box display="flex" justifyContent="space-between">
                          <Typography id={`slider-${productName}`}>{productDisplayName}</Typography>
                          <Typography>{productDisplayValue}%</Typography>
                        </Box>
                        <Slider
                          classes={sliderClasses(productName)}
                          aria-labelledby={`slider-${productName}`}
                          valueLabelDisplay="off"
                          defaultValue={0}
                          step={5}
                          min={0}
                          max={100}
                          value={productDisplayValue}
                          onChange={handleSliderMethodsByName[productName]}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Card>
      <BiddingRiskAppetiteInfo open={appetiteInfoOpen} onClose={() => setAppetiteInfoOpen(false)} />
    </>
  )
}

RiskAppetite.displayName = DISPLAY_NAME

const mapStateToProps = state => ({
  permissions: _.get(state, 'user.current.payload.permissions', {}),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createRiskSetting: riskSetting =>
      dispatch(upsertAssetDataResource.post(ownProps.asset.assetId, 'risk_setting', moment(), riskSetting)),
    dispatchGetAllRiskRecords: asset => {
      const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        return Promise.resolve(dispatch(risksResource.get(asset.assetId, startTime, moment())))
      } else {
        return Promise.reject(new Error('Unable to update risk appetite records'))
      }
    },
    dispatchUpdateRiskSetting: (assetId, riskSetting) =>
      dispatch(assetOperations.updateRiskSetting(assetId, riskSetting)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskAppetite)
