import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Graph, GraphGroup, Legend } from '../../graph/GraphGroup'
import * as common from '../../../graphs/common'
import {
  AMSForecastTag,
  optimizationPriceMadeTag,
  enablementTag,
  getTimeAtStartOf,
  marketPriceInterventionTag,
  preDispatchPriceTag,
} from '../../../utility/utility'
import {
  isAssetNonScheduledBattery,
  isAssetScheduledBattery,
  extractActiveProductNamesFromListOfObjects,
  sortProductNamesFromListOfObjects,
} from '../../../utility/asset-utils'
import { performObjectsWithArraysArithmetic, SUBTRACTION_TYPE } from '../../../utility/data-stream-utils'
import {
  FIVE_MINUTE_DURATION,
  ONE_HOUR_DURATION,
  MARKET_PRICE_ENERGY_MINIMUM,
  MARKET_PRICE_FCAS_MINIMUM,
  PRODUCT_GROUP_NAMES,
  PRODUCT_NAMES,
  RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES,
} from '../../../utility/constants'
import { getTimeSeriesGraph } from '../../../utility/graphUtility'
import { enablementGraphTemplate, priceGraphTemplate } from '../../../utility/graphTemplate'
import { getIntervalKey } from '../../../redux/features/interval'
import { globalAppSelectors } from '../../../redux/features/app'
import { PRODUCT_TYPE_LOAD } from '../../../pages/Bids'
import * as marketResultsGraphConfigs from './marketResultsGraphConfigs'

const TITLES = ['Market Prices', 'Enablements', 'Fluence']
const [TITLE_PRICES, TITLE_ENABLEMENTS, TITLE_COMPANY] = TITLES

const DISPLAY_NAME = 'MarketResultsGraph'

const MarketResultsGraph = props => {
  const { asset, endTime, displayRange, getMarketPriceCap, intervention, setDisplayRange, startTime } = props

  const {
    actualIntervalsPriceIntervention,
    collatedIntervalsAmsPrice,
    collatedIntervalsPreDispatchPrice,
    collatedIntervalsEnablements,
  } = props

  const timezone = _.get(asset, 'market.data.timezone')
  const productGroupOrder = _.get(asset, 'market.data.product_groups_order', [])

  const start = !_.isNil(startTime) ? moment(startTime).unix() : null
  const end = !_.isNil(endTime) ? moment(endTime).unix() : null
  const range = !_.isNil(startTime) && {
    start,
    end,
  }

  const bidInterval = _.get(asset, 'market.data.bid_interval') * 60

  const createGraphs = (
    asset,
    productGroupOrder,
    collatedIntervalsAmsPrice,
    collatedIntervalsPreDispatchPrice,
    collatedIntervalsEnablements,
    actualIntervalsPriceIntervention,
    intervention,
    bidInterval,
    timezone,
    time,
  ) => {
    const amsPriceData = _.cloneDeep(collatedIntervalsAmsPrice)
    const preDispatchPriceData = _.cloneDeep(collatedIntervalsPreDispatchPrice)
    const enablementsData = _.cloneDeep(collatedIntervalsEnablements)
    const priceInterventionData = intervention
      ? _.cloneDeep(actualIntervalsPriceIntervention)
      : {
          isLoading: false,
          data: { values: [] },
        }

    const graphsByProductGroup = {}

    // e.g. ['Energy', 'Raise FCAS', 'Lower FCAS']
    productGroupOrder.forEach(productGroup => {
      const isLoading = [amsPriceData, preDispatchPriceData, enablementsData, priceInterventionData].some(
        i => _.isNil(i) || i.isLoading,
      )

      const titles = getTitles(productGroup, asset)
      const [priceTitle, ...enablementsTitles] = titles
      const groupMetaData = getGroupMetaData(asset, productGroup, titles.length, intervention, time)

      let priceData = {}
      let enablementsDataList = []
      graphsByProductGroup[productGroup] = {
        graphs: [],
        groupMetaData,
      }
      if (!_.isNil(isLoading) && !isLoading) {
        priceData = combinePriceData(
          priceTitle,
          groupMetaData.priceProductIndices,
          amsPriceData,
          preDispatchPriceData,
          priceInterventionData,
          bidInterval,
          intervention,
        )

        // Make graph data objects
        const graphTemplate = _.cloneDeep(getTemplate(priceData.title))
        const extras = { colors: getColors(asset, priceData.title, groupMetaData.displayNamesList[0]) }
        const priceGraph = getTimeSeriesGraph(
          graphTemplate,
          priceData.title,
          groupMetaData.displayNamesList[0],
          priceData || {},
          timezone,
          bidInterval,
          extras,
        )

        priceGraph.forecastStartTime = moment(_.get(amsPriceData, 'data.forecastStartTime'))
        graphsByProductGroup[productGroup].graphs.push(priceGraph)
        enablementsDataList = extractEnablementData(
          enablementsTitles,
          groupMetaData.enablementTypesProductIndices,
          enablementsData,
          productGroup,
          isAssetNonScheduledBattery(asset),
        )

        enablementsDataList.forEach(enablementsData => {
          const displayNamesListIndex = enablementsData.displayNamesListIndex
          const graphTemplate = _.cloneDeep(getTemplate(enablementsData.title))
          const extras = {
            colors: getColors(asset, enablementsData.title, groupMetaData.displayNamesList[displayNamesListIndex + 1]),
          }
          const enablementsGraph = getTimeSeriesGraph(
            graphTemplate,
            enablementsData.title,
            groupMetaData.displayNamesList[displayNamesListIndex + 1],
            enablementsData || {},
            timezone,
            bidInterval,
            extras,
          )
          enablementsGraph.forecastStartTime = moment(_.get(enablementsData, 'data.forecastStartTime'))
          graphsByProductGroup[productGroup].graphs.push(enablementsGraph)
        })
      } else {
        graphsByProductGroup[productGroup].graphs.push(null)
        enablementsTitles.forEach(() => {
          graphsByProductGroup[productGroup].graphs.push(null)
        })
      }
    })

    const stripLineOptions = {
      type: 'line',
      name: 'stripLine',
      visible: false,
      axisXType: 'secondary',
      axisYType: 'secondary',
    }

    const createGetData =
      graph =>
      async ({ range }) => {
        if (_.isNil(graph)) {
          return
        }
        const series = _.get(graph, 'series', [])
        if (_.isEmpty(series)) {
          return {}
        }
        const data = series.reduce(
          (acc, nextLine) => {
            const options = _.get(nextLine, 'options', {})
            acc[options.name] = options
            return acc
          },
          { stripLineOptions },
        )
        return data
      }
    const [energyPriceGraph, enablementsGenOrNSBESSGraph, enablementsLoadGraph] = _.get(
      graphsByProductGroup,
      'Energy.graphs',
      [],
    )
    const [lowerPriceGraph, lowerFcasGenOrNSBESSGraph, lowerFcasLoadGraph] = _.get(
      graphsByProductGroup,
      'Lower FCAS.graphs',
      [],
    )
    const [raisePriceGraph, raiseFcasGenOrNSBESSGraph, raiseFcasLoadGraph] = _.get(
      graphsByProductGroup,
      'Raise FCAS.graphs',
      [],
    )

    const energyPriceGetData = createGetData(energyPriceGraph)
    const energyEnablementsGenOrNSBESSGetData = createGetData(enablementsGenOrNSBESSGraph)
    const energyEnablementsLoadGetData = createGetData(enablementsLoadGraph)

    const raisePriceGetData = createGetData(raisePriceGraph)
    const raiseEnablementsGenOrNSBESSGetData = createGetData(raiseFcasGenOrNSBESSGraph)
    const raiseEnablementsLoadGetData = createGetData(raiseFcasLoadGraph)

    const lowerPriceGetData = createGetData(lowerPriceGraph)
    const lowerEnablementsGenOrNSBESSGetData = createGetData(lowerFcasGenOrNSBESSGraph)
    const lowerEnablementsLoadGetData = createGetData(lowerFcasLoadGraph)

    return {
      graphsByProductGroup,
      energyPriceGetData,
      energyPriceGraph,
      energyEnablementsGenOrNSBESSGetData,
      enablementsGenOrNSBESSGraph,
      energyEnablementsLoadGetData,
      enablementsLoadGraph,
      raisePriceGetData,
      raisePriceGraph,
      raiseEnablementsGenOrNSBESSGetData,
      raiseFcasGenOrNSBESSGraph,
      raiseEnablementsLoadGetData,
      raiseFcasLoadGraph,
      lowerPriceGetData,
      lowerPriceGraph,
      lowerEnablementsGenOrNSBESSGetData,
      lowerFcasGenOrNSBESSGraph,
      lowerEnablementsLoadGetData,
      lowerFcasLoadGraph,
    }
  }
  const allGraphs = useMemo(
    () =>
      createGraphs(
        asset,
        productGroupOrder,
        collatedIntervalsAmsPrice,
        collatedIntervalsPreDispatchPrice,
        collatedIntervalsEnablements,
        actualIntervalsPriceIntervention,
        intervention,
        bidInterval,
        timezone,
        endTime,
      ),
    [
      asset,
      collatedIntervalsAmsPrice,
      collatedIntervalsPreDispatchPrice,
      collatedIntervalsEnablements,
      actualIntervalsPriceIntervention,
      bidInterval,
      intervention,
      productGroupOrder,
      timezone,
      endTime,
    ],
  )
  const FIVE_MINUTES = FIVE_MINUTE_DURATION.asMilliseconds()
  const getEndOfInterval = time => {
    if (!_.isNil(time)) {
      const elapsedTimeInMs = time.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(time.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      return newEndOfInterval
    }
  }

  const nextInterval = getEndOfInterval(moment()).unix()
  const actualTypes = intervention ? ['ACTUAL', 'INTERVENTION'] : ['ACTUAL']
  const forecastTypes = [TITLE_COMPANY, 'PRE-DISPATCH']
  const actualSeriesName = TITLE_COMPANY

  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const context = useMemo(
    () => ({ timezone, marketStartHour, nowFloorDuration: ONE_HOUR_DURATION }),
    [marketStartHour, timezone],
  )
  const energyLegendItems = _.get(allGraphs.graphsByProductGroup, 'Energy.groupMetaData.legendItems', [])
  const raiseFcasLegendItems = _.get(allGraphs.graphsByProductGroup, 'Raise FCAS.groupMetaData.legendItems', [])
  const lowerFcasLegendItems = _.get(allGraphs.graphsByProductGroup, 'Lower FCAS.groupMetaData.legendItems', [])
  const MARKET_PRICE_MAXIMUM = getMarketPriceCap(end)

  return (
    <GraphGroup
      currentTime={getTimeAtStartOf(moment(), FIVE_MINUTE_DURATION.asMinutes()).unix()}
      setDisplayRange={setDisplayRange}
      displayRange={displayRange}
      range={range}
      context={context}
    >
      <Graph
        name={_.get(allGraphs.energyPriceGraph, 'key', TITLE_PRICES)}
        height={200}
        getData={allGraphs.energyPriceGetData}
        formatSeries={marketResultsGraphConfigs.formatMarketPricesSeries(_.get(asset, 'market.data.product_colors'))}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatMarketPricesYAxis(
          MARKET_PRICE_MAXIMUM,
          MARKET_PRICE_ENERGY_MINIMUM,
        )}
        formatTooltip={marketResultsGraphConfigs.formatTooltip(
          _.get(allGraphs, 'energyPriceGraph.forecastStartTime'),
          _.get(allGraphs.graphsByProductGroup, 'Energy.groupMetaData.products', []),
          actualTypes,
          forecastTypes,
          _.get(asset, 'market.data.product_colors'),
          actualSeriesName,
        )}
      />
      <Graph
        name={_.get(allGraphs.enablementsGenOrNSBESSGraph, 'key', TITLE_ENABLEMENTS)}
        height={200}
        getData={allGraphs.energyEnablementsGenOrNSBESSGetData}
        formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
        formatTooltip={common.formatTooltip}
      />
      {!_.isEmpty(allGraphs.enablementsLoadGraph) && (
        <Graph
          name={_.get(allGraphs.enablementsLoadGraph, 'key', TITLE_ENABLEMENTS)}
          height={200}
          getData={allGraphs.energyEnablementsLoadGetData}
          formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
          formatXAxis={common.formatXAxis}
          formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
          formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
          formatTooltip={common.formatTooltip}
        />
      )}
      {energyLegendItems.length > 0 && <Legend items={energyLegendItems} />}

      <Graph
        name={_.get(allGraphs.raisePriceGraph, 'key', TITLE_PRICES)}
        height={200}
        getData={allGraphs.raisePriceGetData}
        formatSeries={marketResultsGraphConfigs.formatMarketPricesSeries(_.get(asset, 'market.data.product_colors'))}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatMarketPricesYAxis(MARKET_PRICE_MAXIMUM, MARKET_PRICE_FCAS_MINIMUM)}
        formatTooltip={marketResultsGraphConfigs.formatTooltip(
          _.get(allGraphs, 'raisePriceGraph.forecastStartTime'),
          _.get(allGraphs.graphsByProductGroup, 'Raise FCAS.groupMetaData.products', []),
          actualTypes,
          forecastTypes,
          _.get(asset, 'market.data.product_colors'),
          actualSeriesName,
        )}
      />
      <Graph
        name={_.get(allGraphs.raiseFcasGenOrNSBESSGraph, 'key', TITLE_ENABLEMENTS)}
        height={200}
        getData={allGraphs.raiseEnablementsGenOrNSBESSGetData}
        formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
        formatTooltip={common.formatTooltip}
      />
      {!_.isEmpty(allGraphs.raiseFcasLoadGraph) && (
        <Graph
          name={_.get(allGraphs.raiseFcasLoadGraph, 'key', TITLE_ENABLEMENTS)}
          height={200}
          getData={allGraphs.raiseEnablementsLoadGetData}
          formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
          formatXAxis={common.formatXAxis}
          formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
          formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
          formatTooltip={common.formatTooltip}
        />
      )}
      {raiseFcasLegendItems.length > 0 && <Legend items={raiseFcasLegendItems} />}

      <Graph
        name={_.get(allGraphs.lowerPriceGraph, 'key', TITLE_PRICES)}
        height={200}
        getData={allGraphs.lowerPriceGetData}
        formatSeries={marketResultsGraphConfigs.formatMarketPricesSeries(_.get(asset, 'market.data.product_colors'))}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatMarketPricesYAxis(MARKET_PRICE_MAXIMUM, MARKET_PRICE_FCAS_MINIMUM)}
        formatTooltip={marketResultsGraphConfigs.formatTooltip(
          _.get(allGraphs, 'lowerPriceGraph.forecastStartTime'),
          _.get(allGraphs.graphsByProductGroup, 'Lower FCAS.groupMetaData.products', []),
          actualTypes,
          forecastTypes,
          _.get(asset, 'market.data.product_colors'),
          actualSeriesName,
        )}
      />
      <Graph
        name={_.get(allGraphs.lowerFcasGenOrNSBESSGraph, 'key', TITLE_ENABLEMENTS)}
        height={200}
        getData={allGraphs.lowerEnablementsGenOrNSBESSGetData}
        formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
        formatXAxis={common.formatXAxis}
        formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
        formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
        formatTooltip={common.formatTooltip}
      />
      {!_.isEmpty(allGraphs.lowerFcasLoadGraph) && (
        <Graph
          name={_.get(allGraphs.lowerFcasLoadGraph, 'key', TITLE_ENABLEMENTS)}
          height={200}
          getData={allGraphs.lowerEnablementsLoadGetData}
          formatSeries={marketResultsGraphConfigs.formatEnablementsSeries}
          formatXAxis={common.formatXAxis}
          formatX2Axis={marketResultsGraphConfigs.formatX2Axis(nextInterval)}
          formatYAxis={marketResultsGraphConfigs.formatEnablementsYAxis}
          formatTooltip={common.formatTooltip}
        />
      )}
      {lowerFcasLegendItems.length > 0 && <Legend items={lowerFcasLegendItems} />}
    </GraphGroup>
  )
}

MarketResultsGraph.displayName = DISPLAY_NAME

MarketResultsGraph.propTypes = {
  asset: PropTypes.object,
  displayRange: PropTypes.object.isRequired,
  endTime: PropTypes.object,
  setDisplayRange: PropTypes.func.isRequired,
  startTime: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  const { asset, startTime } = ownProps
  const assetId = _.get(asset, 'assetId')
  const regionId = _.get(asset, 'region.regionId')
  const collatedIntervals = _.get(state, 'collated.intervals')
  const actualIntervals = _.get(state, 'actual.intervals')

  const amsPriceKeyPrefix = `${assetId}_${optimizationPriceMadeTag}_${AMSForecastTag}_allProducts`
  const amsPriceKey = getIntervalKey(amsPriceKeyPrefix, moment(startTime))
  const collatedIntervalsAmsPrice = _.get(collatedIntervals, amsPriceKey)

  const preDispatchPriceKeyPrefix = `${regionId}_${preDispatchPriceTag}_allProducts`
  const preDispatchPriceKey = getIntervalKey(preDispatchPriceKeyPrefix, moment(startTime))
  const collatedIntervalsPreDispatchPrice = _.get(collatedIntervals, preDispatchPriceKey)

  const priceInterventionKeyPrefix = `${regionId}_${marketPriceInterventionTag}_allProducts`
  const priceInterventionKey = getIntervalKey(priceInterventionKeyPrefix, moment(startTime))
  const actualIntervalsPriceIntervention = _.get(actualIntervals, priceInterventionKey)

  const enablementsKeyPrefix = `${assetId}_${enablementTag}_allProducts`
  const enablementsKey = getIntervalKey(enablementsKeyPrefix, moment(startTime))
  const collatedIntervalsEnablements = _.get(collatedIntervals, enablementsKey)

  const getMarketPriceCap = globalAppSelectors.getMarketPriceCapFn(state)

  return {
    actualIntervalsPriceIntervention,
    collatedIntervalsAmsPrice,
    collatedIntervalsEnablements,
    collatedIntervalsPreDispatchPrice,
    getMarketPriceCap,
  }
}

export default connect(mapStateToProps)(MarketResultsGraph)

const getTitles = (productGroup, asset) => {
  const titles = [`${productGroup} - Market Prices`]
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names')

  _.get(asset, 'productTypes', []).forEach(type => {
    const title = `${productGroup} - Enablements${
      asset.productTypes.length === 1 ? '' : ` (${productTypeDisplayNames[type]})`
    }`
    titles.push(title)
  })
  return titles
}
export const getGroupMetaData = (asset, productGroup, graphCount, intervention, time) => {
  // first on the list is market prices, then enablements
  const amsPrice = []
  const preDispatchPrice = []
  const interventionPrice = []
  const products = []
  const legendItemsMapByName = {}
  // displayNamesList index 0 is price graph display names, index 1 is gen enablements, and index 2 is load enablements
  const displayNamesList = _.range(0, graphCount).map(() => [])
  const priceProductIndices = []
  const enablementTypesProductIndices = asset.productTypes.map(_ => [])

  const productDisplayNames = _.get(asset, 'market.data.product_display_names')
  const productColors = _.get(asset, 'market.data.product_colors', {})

  const assetMarketDataProductGroups = extractAssetMarketDataProductGroups(asset, productGroup, time)
  // the order of productGroup in market.market determines the order of legends displayed on graphs
  assetMarketDataProductGroups
    .filter(product => asset.productTypes.includes(product.type))
    .forEach(product => {
      const productDisplayName = productDisplayNames[product.name]
      const productTypeIndex = asset.productTypes.findIndex(productType => productType === product.type)
      const productNameIndex = asset.productNames.findIndex(productName => productName === product.name)
      displayNamesList[productTypeIndex + 1].push(productDisplayName)
      // generate index that corresponds to the order of productIds sent in getCollatedAssetProductIntervals in MarketResults.js
      enablementTypesProductIndices[productTypeIndex].push(
        asset.productTypes.length * productNameIndex + productTypeIndex,
      )

      if (!amsPrice.includes(`${productDisplayName} (${TITLE_COMPANY})`)) {
        amsPrice.push(`${productDisplayName} (${TITLE_COMPANY})`)
        preDispatchPrice.push(`${productDisplayName} (PRE-DISPATCH)`)
        if (intervention) {
          interventionPrice.push(`${productDisplayName} (INTERVENTION)`)
        }
        products.push(productDisplayName)
        priceProductIndices.push(productNameIndex)
      }
      legendItemsMapByName[product.name] = {
        id: product.name,
        name: productDisplayName,
        color: _.get(productColors, product.name, '#FFF'),
      }
    })
  displayNamesList[0] = _.concat(preDispatchPrice, interventionPrice, amsPrice)
  return {
    displayNamesList,
    products,
    priceProductIndices,
    enablementTypesProductIndices,
    legendItems: _.values(legendItemsMapByName),
  }
}

const extractAssetMarketDataProductGroups = (asset, productGroup, time) => {
  const productGroupArrayKey = ['market', 'data', 'product_groups', productGroup]
  const productGroupArray = _.get(asset, productGroupArrayKey)
  const activeProductGroupArray = extractActiveProductNamesFromListOfObjects(
    asset,
    moment(time),
    productGroupArray,
    'name',
    'type',
  )
  const sortedActiveProductGroupArray = sortProductNamesFromListOfObjects(activeProductGroupArray, 'name', true)
  if (isAssetNonScheduledBattery(asset)) {
    return sortedActiveProductGroupArray.filter(
      product => product.name !== PRODUCT_NAMES.RAISEREG && product.name !== PRODUCT_NAMES.LOWERREG,
    )
  } else if (isAssetScheduledBattery(asset) && _.toUpper(productGroup) === PRODUCT_GROUP_NAMES.RAISE_FCAS) {
    return sortedActiveProductGroupArray.filter(
      product => !(RAISE_FCAS_CONTINGENCY_PRODUCT_NAMES.has(product.name) && product.type === PRODUCT_TYPE_LOAD),
    )
  }
  return sortedActiveProductGroupArray
}

const combinePriceData = (
  title,
  productIndices,
  amsPriceData,
  preDispatchPriceData,
  priceInterventionData,
  bidIntervalSeconds,
  intervention,
) => {
  const priceData = {}
  const amsPriceDataStartTime = _.get(amsPriceData, 'data.startTime')
  const start = moment(amsPriceDataStartTime)
  const forecastStart = moment(_.get(amsPriceData, 'data.forecastStartTime'))

  const amsPriceValues = []
  const preDispatchPriceValues = []
  const priceInterventionValues = []

  productIndices.forEach(index => {
    amsPriceValues.push(_.get(amsPriceData, ['data', 'values', index]))
    preDispatchPriceValues.push(_.get(preDispatchPriceData, ['data', 'values', index]))
    if (intervention) {
      priceInterventionValues.push(_.get(priceInterventionData, ['data', 'values', index]))
    }
  })

  // fill period in pre-dispatch price data where ams and pre-dispatch data actual price overlap with null
  _.range(0, (forecastStart.unix() - start.unix()) / bidIntervalSeconds).forEach(index => {
    preDispatchPriceValues.forEach(data => (data[index] = null))
  })

  priceData.title = title
  priceData.startTime = start.format()
  priceData.forecastStartTime = forecastStart.format()
  priceData.values = _.concat(preDispatchPriceValues, priceInterventionValues, amsPriceValues)
  return priceData
}

const extractEnablementData = (
  titles,
  enablementTypesProductIndices,
  enablementsData,
  productGroup,
  isCurrentAssetNonScheduledBattery,
) => {
  // enablementTypesProductIndices is calculated in getGroupMetaData. It contains productIndices for a productGroup that can extract graph data from enablementsData
  const enablementsDataList = enablementTypesProductIndices.map((productIndices, index) => {
    const data = {}
    data.title = titles[index]
    data.startTime = moment(_.get(enablementsData, 'data.startTime'))
    data.forecastStartTime = moment(_.get(enablementsData, 'data.forecastStartTime'))
    data.values = productIndices.map(index => _.get(enablementsData, ['data', 'values', index], []))
    return data
  })
  if (isCurrentAssetNonScheduledBattery) {
    const GEN_ENABLEMENTS_DATA_IDX = 0
    const LOAD_ENABLEMENTS_DATA_IDX = 1
    const NSBESSTitleName = `${productGroup} - Enablements`
    const genSideEnablementsData = _.get(enablementsDataList, GEN_ENABLEMENTS_DATA_IDX)
    const loadSideEnablementsData = _.get(enablementsDataList, LOAD_ENABLEMENTS_DATA_IDX)
    switch (_.toUpper(productGroup)) {
      case PRODUCT_GROUP_NAMES.ENERGY: {
        const subtractedObj = performObjectsWithArraysArithmetic(
          genSideEnablementsData,
          loadSideEnablementsData,
          ['values'],
          true,
          SUBTRACTION_TYPE,
        )
        return [{ ...subtractedObj, 'title': NSBESSTitleName, 'displayNamesListIndex': GEN_ENABLEMENTS_DATA_IDX }]
      }
      case PRODUCT_GROUP_NAMES.RAISE_FCAS:
        return [
          { ...loadSideEnablementsData, 'title': NSBESSTitleName, 'displayNamesListIndex': LOAD_ENABLEMENTS_DATA_IDX },
        ]
      case PRODUCT_GROUP_NAMES.LOWER_FCAS:
        return [
          { ...loadSideEnablementsData, 'title': NSBESSTitleName, 'displayNamesListIndex': LOAD_ENABLEMENTS_DATA_IDX },
        ]
      default:
    }
  }
  enablementsDataList.forEach((enablementsData, index) => _.set(enablementsData, 'displayNamesListIndex', index))
  return enablementsDataList
}

const getTemplate = title => {
  if (title.endsWith('Market Prices')) {
    return priceGraphTemplate
  }
  return enablementGraphTemplate
}

const getColors = (asset, title, displayNames) => {
  const colors = asset.market.data.product_colors
  return displayNames.map(displayName => {
    const key = title.endsWith('Market Prices') ? displayName.split(' ')[0] : displayName
    return colors[key.toUpperCase()]
  })
}
