import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Box } from '@material-ui/core'
import { useNotifier } from '@fluence/core'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { endOfIntervalSelector } from '../../redux/features/setting'
import { regThroughputOperations, regThroughputSelectors } from '../../redux/features/regulation-throughput'
import { getProductIdByGivenNameType } from '../../utility/asset-utils'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { PRODUCT_NAMES, PRODUCT_TYPES } from '../../utility/constants'
import { percentageToDecimal, decimalToPercentage } from '../../utility/utility'

import UpcomingChangesCard from './UpcomingChangesCard'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'RaiseRegCard'

function RaiseRegCard(props) {
  // NOTE: endOfInterval triggers re-render when interval changes. We want that.
  const { endOfInterval } = props
  const { asset, permissions } = props
  const {
    dispatchCreateRegThroughput,
    dispatchDeleteAssetProductData,
    dispatchGetAssetProductDataRange,
    dispatchGetLatestAssetProductData,
  } = props
  const { notifySuccess, notifyError } = useNotifier()

  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const products = _.get(asset, 'products')
  const raiseRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.GEN, products)
  const raiseRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.RAISEREG, PRODUCT_TYPES.LOAD, products)

  const descriptionText = (
    <>
      <Box mb={1}>
        RAISEREG Utilisation represents the anticipated percentage of enabled RAISEREG MW that AEMO will utilise in each
        interval. Mosaic uses this input during optimisation to estimate the projected SOC changes in each interval
        where RAISEREG participation is planned, in conjunction with any ENERGY and LOWERREG participation.
      </Box>
      <Box>
        NOTE that actual utilisation will vary throughout each dispatch interval based on actual system frequency.
      </Box>
    </>
  )
  const assetId = _.get(asset, 'assetId')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)

  const raiseRegGenThroughputLatest = useSelector(
    regThroughputSelectors.selectLatestRegulationThroughput(assetId, raiseRegGenProductId),
  )
  const raiseRegLoadThroughputLatest = useSelector(
    regThroughputSelectors.selectLatestRegulationThroughput(assetId, raiseRegLoadProductId),
  )
  const raiseRegThroughputRange = useSelector(
    regThroughputSelectors.selectRegulationThroughputSchedule(assetId, raiseRegGenProductId, raiseRegLoadProductId),
  )

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)

  useEffect(() => {
    const currentDatetime = moment().tz(mtz)
    if (!_.isNil(assetId)) {
      dispatchGetLatestAssetProductData(assetId, raiseRegGenProductId, currentDatetime)
      dispatchGetLatestAssetProductData(assetId, raiseRegLoadProductId, currentDatetime)
      dispatchGetAssetProductDataRange(assetId, raiseRegGenProductId, currentDatetime)
      dispatchGetAssetProductDataRange(assetId, raiseRegLoadProductId, currentDatetime)
    }
  }, [
    assetId,
    dispatchGetAssetProductDataRange,
    dispatchGetLatestAssetProductData,
    endOfInterval,
    mtz,
    raiseRegGenProductId,
    raiseRegLoadProductId,
  ])

  const handleSubmit = async request => {
    const { startTime, newInputValues } = request
    const startTimestamp = moment(startTime).valueOf()
    const genRequest = dispatchCreateRegThroughput(
      assetId,
      raiseRegGenProductId,
      startTimestamp,
      percentageToDecimal(newInputValues[0], 4),
    )
    const loadRequest = dispatchCreateRegThroughput(
      assetId,
      raiseRegLoadProductId,
      startTimestamp,
      percentageToDecimal(newInputValues[1], 4),
    )

    let responses
    try {
      responses = [await genRequest, await loadRequest]
      if (responses.some(response => response.error)) {
        notifyError('Unable to scheduled regulation throughput')
      } else {
        try {
          const currentDatetime = moment().tz(mtz)
          const getLatestGenReq = dispatchGetLatestAssetProductData(assetId, raiseRegGenProductId, currentDatetime)
          const getLatestLoadReq = dispatchGetLatestAssetProductData(assetId, raiseRegLoadProductId, currentDatetime)
          const getGenRangeReq = dispatchGetAssetProductDataRange(assetId, raiseRegGenProductId, currentDatetime)
          const getLoadRangeReq = dispatchGetAssetProductDataRange(assetId, raiseRegLoadProductId, currentDatetime)
          await Promise.all([getLatestGenReq, getLatestLoadReq, getGenRangeReq, getLoadRangeReq])
        } catch (error) {
          notifyError('Unable to retrieve new scheduled Regulation Raise Utilisation adjustment from server')
        }
        notifySuccess('Regulation Raise Utilisation adjustment scheduled successfully')
      }
    } catch (e) {
      notifyError('Something went wrong. Please try again')
    }

    return responses
  }

  const handleDelete = async meta => {
    try {
      const qs = []
      meta.assetProductDataIds.forEach(assetProductDataId => {
        if (_.isNumber(assetProductDataId)) {
          qs.push(dispatchDeleteAssetProductData(assetProductDataId))
        }
      })
      await Promise.all(qs)
      try {
        const currentDatetime = moment().tz(mtz)
        const getLatestGenReq = dispatchGetLatestAssetProductData(assetId, raiseRegGenProductId, currentDatetime)
        const getLatestLoadReq = dispatchGetLatestAssetProductData(assetId, raiseRegLoadProductId, currentDatetime)
        const getGenRangeReq = dispatchGetAssetProductDataRange(assetId, raiseRegGenProductId, currentDatetime)
        const getLoadRangeReq = dispatchGetAssetProductDataRange(assetId, raiseRegLoadProductId, currentDatetime)
        await Promise.all([getLatestGenReq, getLatestLoadReq, getGenRangeReq, getLoadRangeReq])
      } catch (error) {
        notifyError('Unable to retrieve latest Raise Regulation schedule from server')
      }
    } catch (e) {
      notifyError('Unable to delete Raise Regulation Utilization')
    }
  }

  const inputConfigs = [
    {
      label: 'GEN',
      current: decimalToPercentage(raiseRegGenThroughputLatest.value, 2),
      disableImplied: true,
      inputAdornment: '',
      endAdornment: '%',
      allowNegativeInput: false,
    },
    {
      label: 'LOAD',
      current: decimalToPercentage(raiseRegLoadThroughputLatest.value, 2),
      disableImplied: true,
      inputAdornment: '',
      endAdornment: '%',
      allowNegativeInput: false,
    },
  ]

  const activeItem = {
    dotTitle: 'RAISEREG Utilisation',
    title: 'ACTIVE SINCE',
    time: null,
    showDot: false,
    value: [
      {
        label: 'GEN',
        time: moment(raiseRegGenThroughputLatest.startTime),
        value: numeral(raiseRegGenThroughputLatest.value).format('0[.]00%'),
      },
      {
        label: 'LOAD',
        time: moment(raiseRegLoadThroughputLatest.startTime),
        value: numeral(raiseRegLoadThroughputLatest.value).format('0[.]00%'),
      },
    ],
  }

  const items = raiseRegThroughputRange.map(item => {
    const values = item.value.map(value => numeral(value).format('0[.]00%'))
    return {
      deleteTitle: 'Remove scheduled change',
      id: moment(item.startTime).valueOf(),
      onDelete: handleDelete,
      meta: {
        assetProductDataIds: item.assetProductDataIds,
      },
      showDelete: !!canAdjustTradeSettings,
      time: moment(item.startTime),
      value: item.value.map(value => numeral(value).format('0[.]00%')),
      valueLabels: [_.isNil(values[0]) ? '' : 'GEN', _.isNil(values[1]) ? '' : 'LOAD'],
    }
  })

  return (
    <>
      <UpcomingChangesCard
        activeItem={activeItem}
        onActionClick={() => setIsSchedulingModalOpen(prev => !prev)}
        hideAction={false}
        items={items}
        marketStartHour={marketStartHour}
        timezone={mtz}
        title="RAISEREG Utilisation"
        titleTypographyProps={{ variant: 'h3' }}
      />
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleSubmit}
        title="Schedule Regulation Raise Utilisation adjustment"
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        description={descriptionText}
        precision={2}
        inputDecimalLimit={2}
        getMarketPriceCap={() => {
          return 100
        }}
      />
    </>
  )
}

RaiseRegCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    return {
      endOfInterval: endOfIntervalSelector(state),
      permissions: currentUserPermissionsSelector(state),
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatchGetLatestAssetProductData: async (assetId, productId, startTime) =>
      dispatch(regThroughputOperations.getRegulationThroughputLatest(assetId, productId, startTime)),
    dispatchGetAssetProductDataRange: async (assetId, productId, startTime) =>
      dispatch(regThroughputOperations.getRegulationThroughputRange(assetId, productId, startTime)),
    dispatchCreateRegThroughput: async (assetId, productId, startTime, value) =>
      dispatch(regThroughputOperations.createRegulationThroughput(assetId, productId, startTime, value)),
    dispatchDeleteAssetProductData: async assetProductDataId =>
      dispatch(regThroughputOperations.deleteRegulationThroughput(assetProductDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(RaiseRegCard)
