import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(
  theme => ({
    tableContainer: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    table: {
      minWidth: 350,
    },
    dim: {
      opacity: 0.75,
    },
  }),
  { name: 'DenseTable' },
)

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none',
    padding: 0,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(TableRow)

interface DenseTableProps {
  data?: object[]
  variant?: 'dim' | 'normal'
}

const DenseTable: React.FC<DenseTableProps> = ({ data, variant = 'normal' }) => {
  const classes = useStyles()

  const t1Labels = _.get(data, '[0].labels', [])
  const t1Rows = _.get(data, '[0].rows', [])
  const t2Labels = _.get(data, '[1].labels', [])
  const t2Rows = _.get(data, '[1].rows', [])

  return (
    <div>
      <TableContainer component={Paper} className={clsx(classes.tableContainer, { [classes.dim]: variant === 'dim' })}>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              {t1Labels.map(label => (
                <StyledTableCell key={label} align="right">
                  {label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {t1Rows?.map(row => (
              <TableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                {[...row?.values?.entries()].map(([key, value]) => (
                  <StyledTableCell key={key} align="right">
                    {value}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              {t2Labels.map(label => (
                <StyledTableCell key={label} align="right">
                  {label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {t2Rows?.map(row => (
              <TableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                {[...row?.values?.entries()].map(([key, value]) => (
                  <StyledTableCell key={key} align="right">
                    {value}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default DenseTable
