import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, CircularProgress, FormControlLabel, Grid, RadioGroup, Radio, Typography } from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  bidStrategySettingResource,
  bidStrategySettingSelector,
  allFCASResource,
  upsertAssetDataResource,
} from '../../redux/features/setting'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { BID_STRATEGIES, BID_STRATEGY_SETTING_TAG } from '../../utility/constants'
import Card from '../Card'
import RouteLeavingGuard from '../RouteLeavingGuard'

const DISPLAY_NAME = 'DefaultBidCard'

const useStyles = makeStyles(
  theme => ({
    card: {
      background: _.get(theme, 'palette.background.paper', '#1D2126'),
    },
    cardContent: {
      padding: 0,
      minHeight: theme.spacing(22),
      maxHeight: theme.spacing(32),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    cardHeader: {
      borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    },
    activeItem: {
      padding: theme.spacing(1, 1, 1, 2),
    },
    action: {
      paddingLeft: theme.spacing(0.5),
    },
    radio: {
      marginBottom: 0,
    },
  }),
  { name: DISPLAY_NAME },
)

function DefaultBidCard(props) {
  const {
    asset,
    bidStrategySetting,
    bidStrategySettingIsLoading,
    dispatchCreateAssetData,
    getBidStrategy,
    getBidStrategySettings,
    permissions,
  } = props
  const { notifySuccess, notifyError } = useNotifier()
  const classes = useStyles()

  const assetId = _.get(asset, 'assetId')
  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [selectedBidStrategy, setSelectedBidStrategy] = useState()
  const [currentBidMethod, setCurrentBidMethod] = useState()
  const modified = selectedBidStrategy !== currentBidMethod

  // TODO: Remove this if/when opportunity price is considered a feature.
  const [showOpportunityPrice, setShowOpportunityPrice] = useState(false)
  useEffect(() => {
    if (currentBidMethod === BID_STRATEGIES.OPPORTUNITY_PRICE) {
      setShowOpportunityPrice(true)
    }
  }, [currentBidMethod])

  useEffect(() => {
    getBidStrategy(assetId, moment()).then(response => {
      if (response.error) {
        notifyError('Unable to retrieve data from server. Please try again')
      } else {
        setCurrentBidMethod(() => {
          const bidStrat = _.get(response, 'payload.data.default_bid_method')
          setSelectedBidStrategy(bidStrat)
          return bidStrat
        })
      }
    })
  }, [assetId, getBidStrategy, notifyError])

  const handleChange = event => {
    const newValue = _.get(event, 'target.value')
    setSelectedBidStrategy(newValue)
  }

  const handleSubmit = () => {
    if (modified) {
      onSave()
    }
  }

  const onSave = async () => {
    if (!modified) {
      return Promise.reject(new Error('Error: Nothing to save'))
    }

    const assetData = {
      ...bidStrategySetting,
      'default_bid_method': selectedBidStrategy,
    }

    setSaveInProgress(true)
    const now = moment()

    const response = await dispatchCreateAssetData(asset.assetId, BID_STRATEGY_SETTING_TAG, moment(now), assetData)

    if (response.error) {
      notifyError('Something went wrong. Please try again')
      setSaveInProgress(false)
    } else {
      const bidStrategyResponse = await getBidStrategy(assetId, moment())
      notifySuccess(`Default Bid saved successfully`)
      setSaveInProgress(false)
      getBidStrategySettings(asset)
      if (!bidStrategyResponse.error) {
        setCurrentBidMethod(selectedBidStrategy)
      }
    }
  }

  const showLoading = !saveInProgress && bidStrategySettingIsLoading

  return (
    <>
      <RouteLeavingGuard description="Default Bid Card" when={modified} />
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
          action: classes.action,
        }}
        title="Default Bid"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          canAdjustTradeSettings && (
            <Button variant="text" onClick={handleSubmit} disabled={!modified || saveInProgress}>
              SAVE
            </Button>
          )
        }
        inProgress={bidStrategySettingIsLoading}
      >
        <Grid container className={classes.activeItem} direction="column">
          <Typography variant="body1" color="textSecondary">
            Price Band where all capacity will bid by default
          </Typography>
          {showLoading ? (
            <Box position="relative" p={3.5}>
              <Box position="absolute" zIndex="100" left="calc( 50% - 20px )" top="40%">
                <CircularProgress />
              </Box>
            </Box>
          ) : (
            <Box pt={1} display="flex" justifyContent="flex-start">
              <RadioGroup
                aria-label="forecast-option"
                value={_.isNil(selectedBidStrategy) ? '' : selectedBidStrategy}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={BID_STRATEGIES.PRICE_BAND_1}
                  control={<Radio color="primary" />}
                  label="Price Band 1"
                  className={classes.radio}
                  disabled={bidStrategySettingIsLoading || saveInProgress}
                />
                {showOpportunityPrice && (
                  <FormControlLabel
                    value={BID_STRATEGIES.OPPORTUNITY_PRICE}
                    control={<Radio color="primary" />}
                    label="Opportunity Price"
                    className={classes.radio}
                    disabled={bidStrategySettingIsLoading || saveInProgress}
                  />
                )}
                <FormControlLabel
                  value={BID_STRATEGIES.BREAK_EVEN_PRICE}
                  control={<Radio color="primary" />}
                  label="Break Even Price"
                  className={classes.radio}
                  disabled={bidStrategySettingIsLoading || saveInProgress}
                />
              </RadioGroup>
            </Box>
          )}
        </Grid>
      </Card>
    </>
  )
}

const mapStateToProps = () => {
  return state => ({
    bidStrategySetting: bidStrategySettingSelector(state),
    bidStrategySettingIsLoading: _.get(state, 'setting.bidStrategySettings.isLoading'),
    permissions: currentUserPermissionsSelector(state),
  })
}

const mapDispatchToProps = dispatch => {
  return {
    getBidStrategy: (assetId, asOf) => dispatch(bidStrategySettingResource.get(assetId, asOf)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    getBidStrategySettings: asset => {
      const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        return Promise.resolve(dispatch(allFCASResource.get(asset.assetId, startTime, moment())))
      } else {
        return Promise.reject(new Error('Unable to update FCAS records'))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultBidCard)
