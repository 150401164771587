import { combineReducers } from "redux"

export const SELECT_MARKET_RESULT_VIEW = "SELECT_MARKET_RESULT_VIEW"
export const SELECT_TABLE_VIEW = "SELECT_TABLE_VIEW"
export const SELECT_ALL_PRODUCTS_VIEW = "SELECT_ALL_PRODUCTS_VIEW"
export const SELECT_CURRENT_DATE = "SELECT_ENABLEMENT_CURRENT_DATE"

export const selectView = (selectedKey) => ({
  type: SELECT_MARKET_RESULT_VIEW,
  selectedKey
})

export const selectTableView = (selectedKey) => ({
  type: SELECT_TABLE_VIEW,
  selectedKey
})

export const selectCurrentDate = (date) => ({
  type: SELECT_CURRENT_DATE,
  date
})

export const selectProduct = (product) => ({
  type: SELECT_ALL_PRODUCTS_VIEW,
  product
})

const view = (state = {
  selectedView: null,
  selectedTableView: null,
  selectedDate: null,
  allProductsView: []
}, action) => {
  switch (action.type) {
    case SELECT_MARKET_RESULT_VIEW:
      return Object.assign({}, state, {
        selectedView: action.selectedKey,
      })
    case SELECT_TABLE_VIEW:
      return Object.assign({}, state, {
        selectedTableView: action.selectedKey,
      })
    case SELECT_CURRENT_DATE:
      return Object.assign({}, state, {
        selectedDate: action.date,
      })
    case SELECT_ALL_PRODUCTS_VIEW:
      let previousView = state.allProductsView.slice()
      if (typeof action.product === "object") {
        //when an array is dispatched
        previousView = action.product
      } else if (previousView.includes(action.product)) {
        previousView = previousView.filter(product => product !== action.product)
      } else {
        previousView.push(action.product)
      }
      return Object.assign({}, state, {
        allProductsView: previousView.sort() || [],
      })
    default:
      return state
  }
}

export default combineReducers({
  view
})

