import React from 'react'
import { Paper, Typography, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const classes = theme => ({
  error: {
    width: '100%',
    padding: theme.spacing(2),

    '& h1': {
      marginBottom: theme.spacing(1),
    },
  },
})

const ErrorNotFound = props => {
  const { classes } = props

  return (
    <Paper className={classes.error}>
      <Typography variant="h2" component="h1">
        Page not found
      </Typography>

      <Typography variant="body1">
        This page does not exist. Please check the address and try again. Contact support at{' '}
        <Link href="mailto:support.nem@fluenceenergy.com" target="_blank" rel="noopener">
          support.nem@fluenceenergy.com
        </Link>{' '}
        if the problem persists.
      </Typography>
    </Paper>
  )
}

export default withStyles(classes, { withTheme: true })(ErrorNotFound)
