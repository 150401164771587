import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Box } from '@material-ui/core'
import { useNotifier } from '@fluence/core'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import {
  endOfIntervalSelector,
  deleteAssetDataResource,
  marketAssetDataCostSettingResource,
  selectCostSetting,
  upsertAssetDataResource,
} from '../../redux/features/setting'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { getNextIntervalTime } from '../../utility/time-utils'
import { DEGRADATION_COST_SETTING } from '../../utility/constants'
import { convertPricePerkWhToMWh, convertPricePerMWhTokWh } from '../../utility/utility'
import { globalAppSelectors } from '../../redux/features/app'

import UpcomingChangesCard from './UpcomingChangesCard'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'ThroughputCostCard'
const CARD_TITLE = 'Throughput Cost ($/MWh)'
const MODAL_TITLE = 'Schedule Throughput Cost adjustment'
const MODAL_DESCRIPTION = (
  <>
    <Box mb={1}>
      This parameter is a means for users to increase or decrease the likelihood of Mosaic’s optimisation process
      choosing to cycle the BESS. A lower $MWh value on both charge/discharge cost inputs will result in an increased
      willingness to cycle the battery to maximise revenue, whilst higher values will cause the optimisation to be more
      conservative in cycling, in order to delay degrading the asset over time. Any variance between the two directional
      values will skew the operating behaviour accordingly (e.g. quicker to charge, slower to discharge).
    </Box>
  </>
)
const NOTIFICATION_MSGS = {
  DATA_FETCH_ERROR: 'Something went wrong. Please try again',
  DELETE_ERROR: 'Something went wrong. Please try again',
  SAVE_ERROR: 'Something went wrong. Please try again',
  SAVE_SUCCESS: 'Throughput Cost adjustment scheduled successfully',
}
const VALUE_LABELS = ['CHARGE', 'DISCHARGE']

function ThroughputCostCard(props) {
  // NOTE: endOfInterval triggers re-render when interval changes. We want that.
  const { endOfInterval } = props
  const { asset, permissions } = props
  const {
    costSettings,
    dispatchCreateAssetData,
    dispatchDeleteAssetData,
    dispatchGetCostSettingSchedule,
    getMarketPriceCap,
  } = props
  const { notifySuccess, notifyError } = useNotifier()
  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const assetId = _.get(asset, 'assetId')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)
  const nextInterval = getNextIntervalTime(moment(), 300)

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)

  useEffect(() => {
    const currentDatetime = moment().tz(mtz)
    if (!_.isNil(assetId)) {
      dispatchGetCostSettingSchedule(assetId, currentDatetime)
    }
  }, [assetId, dispatchGetCostSettingSchedule, endOfInterval, mtz])

  const costSettingsByTime = costSettings.reduce((acc, next) => {
    return {
      ...acc,
      [moment(next.startTime).valueOf()]: next,
    }
  }, {})

  const handleSubmit = async request => {
    const { startTime, newInputValues = [] } = request
    const [charge, discharge] = newInputValues
    const convertedCharge = convertPricePerMWhTokWh(charge, 2)
    const convertedDischarge = convertPricePerMWhTokWh(discharge, 2)
    const startTimestamp = moment(startTime).valueOf()
    const existingAssetData = _.get(costSettingsByTime, `${startTimestamp}`, {})
    const assetData = {
      ...existingAssetData,
      data: { charge: convertedCharge, discharge: convertedDischarge },
    }
    return dispatchCreateAssetData(asset.assetId, DEGRADATION_COST_SETTING, startTimestamp, assetData.data).then(
      response => {
        if (response.error) {
          notifyError(NOTIFICATION_MSGS.SAVE_ERROR)
          return response
        } else {
          return dispatchGetCostSettingSchedule(assetId, moment()).then(resp => {
            if (resp.error) {
              notifyError(NOTIFICATION_MSGS.DATA_FETCH_ERROR)
            }
            notifySuccess(NOTIFICATION_MSGS.SAVE_SUCCESS)
            return resp
          })
        }
      },
    )
  }

  const handleDelete = meta => {
    return dispatchDeleteAssetData(meta.assetDataId).then(response => {
      if (response.error) {
        notifyError('Unable to delete LGC entry')
        return response
      } else {
        return dispatchGetCostSettingSchedule(assetId, moment()).then(resp => {
          if (resp.error) {
            notifyError(NOTIFICATION_MSGS.DATA_FETCH_ERROR)
          }
          return resp
        })
      }
    })
  }

  const activeCostSetting = !_.isEmpty(costSettings)
    ? _.last(
        costSettings.filter(costSetting =>
          moment(costSetting.startTime).isBefore(nextInterval),
        ),
      )
    : {}

  const activeChargeCost = _.get(activeCostSetting, 'data.charge')
  const activeDischargeCost = _.get(activeCostSetting, 'data.discharge')
  const currentChargeCost = _.isNumber(activeChargeCost) ? convertPricePerkWhToMWh(activeChargeCost) : null
  const currentDischargeCost = _.isNumber(activeDischargeCost) ? convertPricePerkWhToMWh(activeDischargeCost) : null
  const inputConfigs = [
    {
      label: 'Charge Cost',
      current: currentChargeCost,
      disableImplied: true,
      inputAdornment: '$',
      allowNegativeInput: false,
    },
    {
      label: 'Discharge Cost',
      current: currentDischargeCost,
      disableImplied: true,
      inputAdornment: '$',
      allowNegativeInput: false,
    },
  ]

  const activeChargeDisplayValue = _.isNumber(currentChargeCost) ? numeral(currentChargeCost).format('$0[.]00') : '-'
  const activeDischargeDisplayValue = _.isNumber(currentDischargeCost)
    ? numeral(currentDischargeCost).format('$0[.]00')
    : '-'

  const activeItem = {
    title: 'ACTIVE SINCE',
    time: null,
    showDot: false,
    value: [
      {
        label: 'CHARGE',
        time: moment(_.get(activeCostSetting, 'startTime')),
        value: activeChargeDisplayValue,
      },
      {
        label: 'DISCHARGE',
        time: moment(_.get(activeCostSetting, 'startTime')),
        value: activeDischargeDisplayValue,
      },
    ],
  }

  const upcomingCostSettings = !_.isEmpty(costSettings)
    ? costSettings.filter(
        costSetting => moment(costSetting.startTime) >= nextInterval,
      )
    : []

  const items = upcomingCostSettings.map(item => {
    const value = [
      numeral(convertPricePerkWhToMWh(_.get(item, 'data.charge'))).format('$0[.]00'),
      numeral(convertPricePerkWhToMWh(_.get(item, 'data.discharge'))).format('$0[.]00'),
    ]
    return {
      deleteTitle: 'Remove scheduled change',
      id: moment(item.startTime).valueOf(),
      onDelete: handleDelete,
      meta: {
        assetDataId: item.assetDataId,
      },
      showDelete: !!canAdjustTradeSettings,
      time: moment(item.startTime),
      value,
      valueLabels: VALUE_LABELS,
    }
  })

  return (
    <>
      <UpcomingChangesCard
        activeItem={activeItem}
        onActionClick={() => setIsSchedulingModalOpen(prev => !prev)}
        hideAction={false}
        items={items}
        marketStartHour={marketStartHour}
        timezone={mtz}
        title={CARD_TITLE}
        titleTypographyProps={{ variant: 'h3' }}
      />
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleSubmit}
        title={MODAL_TITLE}
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        description={MODAL_DESCRIPTION}
        precision={2}
        inputDecimalLimit={2}
        getMarketPriceCap={getMarketPriceCap}
      />
    </>
  )
}

ThroughputCostCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    const getMarketPriceCap = globalAppSelectors.getMarketPriceCapFn(state)
    return {
      costSettings: selectCostSetting(state),
      endOfInterval: endOfIntervalSelector(state),
      getMarketPriceCap,
      permissions: currentUserPermissionsSelector(state),
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatchGetCostSettingSchedule: async (assetId, startTime) =>
      dispatch(marketAssetDataCostSettingResource.get(assetId, startTime)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchDeleteAssetData: assetDataId => dispatch(deleteAssetDataResource.put(assetDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ThroughputCostCard)
