import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { UserManagementTable } from '@fluence/core'
import { useGetAdministrableUsers, useGetCurrentUser } from '../redux/api.js'
import { CAN_ADMINISTER_USERS, CAN_DO_ANYTHING_PERMISSION } from '../utility/constants'
import { userCanAdminister } from '../components/userManagement/userManagementUtility'
import ROUTES, { editUserBase } from '../config/routes'

const DISPLAY_NAME = 'UserManagement'

const isUserSuperAdmin = user => {
  const userMarketPermissions = _.get(user, 'permissions.marketPermissions', [])
  return (
    userMarketPermissions.includes(CAN_ADMINISTER_USERS) || userMarketPermissions.includes(CAN_DO_ANYTHING_PERMISSION)
  )
}

const UserManagement = props => {
  const timezone = useSelector(state => _.get(state, 'globalApp.marketData.timezone', 'Etc/GMT-10'))
  const { data: allUsers } = useGetAdministrableUsers()
  const { data: currentUser } = useGetCurrentUser()

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const isSuperAdmin = isUserSuperAdmin(currentUser)

  return (
    <>
      {!_.isEmpty(currentUser) && !_.isNil(currentUser) ? (
        userCanAdminister(currentUser) &&
        !_.isNil(allUsers) && (
          <UserManagementTable
            timezone={timezone}
            currentUser={currentUser}
            createModalOpen={createModalOpen}
            setCreateModalOpen={setCreateModalOpen}
            isSuperAdmin={isSuperAdmin}
            allUsers={allUsers}
            createUserRoute={ROUTES.CREATE_USER}
            editUserBase={editUserBase}
            includeSearch
          />
        )
      ) : (
        <></>
      )}
    </>
  )
}

UserManagement.displayName = DISPLAY_NAME

export default UserManagement
