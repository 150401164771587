import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from "@material-ui/pickers"
import { yearMonthFormat, findProduct } from '../../utility/utility'
import { DATE_FORMAT_DAY, DATE_FORMAT_FULL_WITH_SEC } from '../../utility/constants'
import { getChartData, getIntervalKey, IntervalConfig, IntervalTimeConfig } from '../../redux/features/interval'
import { getPricePerformance } from '../../redux/features/forecast'
import Card from '../Card'
import Toggle from '../Toggle'
import ProductToggle from '../toggles/ProductToggle'
import SingleMonthTable from './SingleMonthTable'

const DISPLAY_NAME = 'PriceForecastPerformance'

const METRIC_OPTIONS = [
  {
    text: 'MAE',
    key: 'MAE',
    toolTip: 'Metric: MAE ($/MWh)',
  },
  {
    text: 'SMAPE',
    key: 'SMAPE',
    toolTip: 'Metric: SMAPE (%)',
  },
  {
    text: 'EHR',
    key: 'EHR',
    toolTip: 'Metric: Envelope Hit Rate (%)',
  },
  {
    text: 'LHR',
    key: 'LHR',
    toolTip: 'Metric: LGC Hit Rate (%)',
  },
]

const INTERVALS_OPTIONS = [
  {
    text: 'All',
    key: 'AllInterval',
    toolTip: 'All Intervals',
  },
  {
    text: '< -300',
    key: 'LessThanNegative300',
    toolTip: 'Less than -$300/MWh',
  },
  {
    text: '-300 to 300',
    key: 'BetweenPlusAndMinus300',
    toolTip: 'Between -$300/MWh and +$300/MWh',
  },
  {
    text: '> 300',
    key: 'GreaterThan300',
    toolTip: 'Greater than $300/MWh',
  },
]

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: theme.spacing(150),
    },
    datePicker: {
      width: theme.spacing(13),
      height: theme.spacing(4),
      marginTop: 0,
    },
    pickerInput: {
      maxHeight: theme.spacing(4),
      paddingLeft: theme.spacing(1.25),
    },
  }),
  { name: DISPLAY_NAME },
)

const PriceForecastPerformance = React.memo(props => {
  const classes = useStyles(props)
  const { asset, market, timezone = 'Etc/GMT-10' } = props
  const { forecastState, getData } = props
  moment.tz.setDefault(timezone)

  const [selectedYearMonths, setSelectedYearMonths] = useState()
  const [selectedMonth, setSelectedMonth] = useState(moment())
  const [selectedMetric, setActiveMetric] = useState('MAE')
  const [selectedIntervals, setSelectedIntervals] = useState('AllInterval')
  const [selectedProduct, setSelectedProduct] = useState()
  const [forecastChartData, setForecastChartData] = useState({})

  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNames = _.get(market, 'data.product_display_names')
  const productOptions = productNames.map(name => ({ value: name, displayName: productDisplayNames[name] }))
  const defaultProductType = _.get(asset, 'market.data.default_product_type')
  const productId = _.get(findProduct(asset, selectedProduct, defaultProductType), 'productId', '')
  const tag =
    _.isEmpty(selectedIntervals) || _.isNil(selectedMetric) || _.isEmpty(selectedYearMonths)
      ? ''
      : `${selectedIntervals}_${selectedMetric}_${selectedYearMonths.length === 1 ? 'days' : 'month'}`
  const keyPrefix =
    _.isNil(productId) || _.isEmpty(asset) || _.isEmpty(tag)
      ? ''
      : `${productId}_${_.get(asset, 'region.regionId')}_${tag}`

  const intervalKey = getIntervalKey(keyPrefix, moment(_.first(selectedYearMonths) + '-01'))
  const updatedOn = _.get(forecastState, ['intervals', intervalKey, 'updatedOn'])

  useEffect(() => {
    if (!_.isEmpty(forecastState)) {
      const data = !_.isNil(_.first(selectedYearMonths))
        ? getChartData(forecastState, keyPrefix, moment(_.first(selectedYearMonths) + '-01'))
        : {}
      setForecastChartData(data)
    }
  }, [forecastState, keyPrefix, selectedYearMonths])

  useEffect(() => {
    if (!_.isEmpty(asset)) {
      const mtz = moment().tz(timezone)
      const year = moment(mtz).year()
      const month = moment(mtz).month()
      const yearMonth = getYearMonth(year, month + 1)
      setSelectedYearMonths([yearMonth])
    }
  }, [asset, timezone])

  useEffect(() => {
    if (_.isNil(selectedProduct) && !_.isEmpty(productOptions)) {
      setSelectedProduct(_.get(productOptions, [0, 'value']))
    }
  }, [asset, selectedProduct, productOptions])

  useEffect(() => {
    if (!_.isEmpty(asset) && !_.isNil(selectedProduct) && !_.isNil(selectedIntervals) && !_.isNil(selectedMetric)) {
      getData(asset, selectedYearMonths, selectedProduct, selectedIntervals, selectedMetric)
    }
  }, [asset, getData, selectedYearMonths, selectedProduct, selectedIntervals, selectedMetric, timezone])

  const handleMetricChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setActiveMetric(newSelection)
    }
  }

  const handleIntervalsChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedIntervals(newSelection)
    }
  }

  const handleProductChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedProduct(newSelection)
    }
  }

  const handleMonthChange = month => {
    setSelectedMonth(month)
    const newMonth = getYearMonth(month.year(), month.month() + 1)
    setSelectedYearMonths([newMonth])
  }

  const minMonth = _.get(asset, 'data.commercial_online_date', moment())
  const fromTime = moment(selectedMonth, 'YYYY-MM').isValid()
    ? moment(selectedMonth, 'YYYY-MM').startOf('month').format(DATE_FORMAT_DAY)
    : '---- -- --'
  const toTime = moment(fromTime).isValid() ? moment(fromTime).endOf('month').format(DATE_FORMAT_DAY) : '---- -- --'
  const lastUpdatedDisplay = updatedOn ? `, LAST UPDATED: ${moment(updatedOn).format(DATE_FORMAT_FULL_WITH_SEC)}` : ''
  const subTitle = `${fromTime} TO ${toTime}${lastUpdatedDisplay}`
  const isLoading = _.get(forecastChartData, 'isLoading', true)

  return (
    <Box mt={3}>
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item>
            <DatePicker
              autoOk
              hiddenLabel
              openTo="month"
              variant="inline"
              inputVariant="filled"
              views={["year", "month"]}
              format={yearMonthFormat}
              minDate={minMonth}
              maxDate={moment()}
              value={selectedMonth}
              onChange={handleMonthChange}
              className={classes.datePicker}
              InputProps={{ classes: { root: classes.pickerInput } }}
            />
          </Grid>
          <Grid item>
            <Toggle values={METRIC_OPTIONS} selected={selectedMetric} onChange={handleMetricChange} />
          </Grid>
          <Grid item>
            <Toggle values={INTERVALS_OPTIONS} selected={selectedIntervals} onChange={handleIntervalsChange} />
          </Grid>
          <Grid item>
            <ProductToggle
              selected={selectedProduct}
              productNames={productNames}
              productDisplayNames={productDisplayNames}
              onChange={handleProductChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Card
        action={''}
        className={classes.card}
        inProgress={isLoading}
        subheader={subTitle}
        title={'Price Forecast Performance'}
      >
        <SingleMonthTable
          isLoading={isLoading}
          selectedMetric={selectedMetric}
          tableData={forecastChartData.data}
          timezone={timezone}
          yearMonth={_.first(selectedYearMonths)}
        />
      </Card>
    </Box>
  )
})

const getYearMonth = (year, month) => {
  return year + '-' + (month < 10 ? '0' + month : month)
}

const fetchPriceForecastData = (dispatch, asset, yearMonths, product, range, metric) => {
  const tag = `${range}_${metric}_` + (yearMonths.length === 1 ? 'days' : 'month')
  const productId = findProduct(asset, product, asset.market.data.default_product_type).productId
  const meta = { horizons: [5 - 5, 30 - 5, 60 - 5, 180 - 5, 720 - 5, 1440 - 5] }
  return yearMonths.map(yearMonth => {
    const startTime = moment(yearMonth + '-01')
    const intervalTimeConfig = new IntervalTimeConfig(startTime, startTime.clone().add(1, 'd'), null, yearMonth)
    const intervalConfig = new IntervalConfig(
      `${productId}_${asset.region.regionId}_${tag}`,
      tag,
      null,
      productId,
      null,
      asset.region.regionId,
      meta,
    )
    return dispatch(getPricePerformance(intervalConfig, intervalTimeConfig))
  })
}

PriceForecastPerformance.displayName = DISPLAY_NAME

const mapStateToProps = state => {
  return {
    forecastState: state.forecast,
  }
}

const mapDispatchToProps = dispatch => ({
  getData: (asset, yearMonths, product, range, metric) =>
    fetchPriceForecastData(dispatch, asset, yearMonths, product, range, metric),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceForecastPerformance)
