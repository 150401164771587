import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { dayOfWeekFormat, dateTimeFormatWithSeconds } from '../../utility/utility'

let CanvasJS = require('../../assets/scripts/canvasjs.min')
CanvasJS = CanvasJS.Chart ? CanvasJS : window.CanvasJS
export const NO_DATA_TEXT = 'No data'

export const defaultContentFormatter = showTotal => e => {
  const time = moment.unix(_.get(e, 'entries', 0, 'dataPoint', 'x'))
  const title = `<h6 class='mt-xs mb-none'>${time.format(dateTimeFormatWithSeconds)}</h6>`
  const subtitle = `<div class='mb-xs text-xs' style='color: #808697'>${time.format(dayOfWeekFormat)}</div>`
  const visibleSeries = e.entries.filter(entry => _.get(entry, 'dataSeries', 'visible'))
  const series = visibleSeries.reduce((output, entry) => {
    const formattedValue = _.isFinite(entry.dataPoint.y)
      ? CanvasJS.formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
      : NO_DATA_TEXT
    return (
      `<div><span style='color: #808697'>${_.get(entry, 'dataSeries', 'name')}:</span> <span style='color: ${_.get(
        entry,
        'dataSeries',
        'color',
      )};'>${formattedValue}</span></div>` + output
    )
  }, '')
  let total = ''
  if (showTotal && !!visibleSeries.length) {
    const formatString = visibleSeries[0].dataSeries.yValueFormatString
    const sum = _.sumBy(visibleSeries, entry =>
      _.isFinite(_.get(entry, 'dataPoint', 'y')) ? _.get(entry, 'dataPoint', 'y') : 0,
    )
    total = `<div class=''><span style='color: #808697'>Total:</span> <span class='text-dark'>${CanvasJS.formatNumber(
      sum,
      formatString,
    )}</span></div>`
  }
  return title + subtitle + series + total
}

export const primaryFontFamily = `'Open Sans', Arial, sans-serif`
export const monospaceFontFamily = `'Droid Sans Mono', monospace`

export const axisXDefault = {
  intervalType: 'second',
  gridThickness: 1,
  tickThickness: 1,
  lineThickness: 1,
  lineColor: '#3a414a',
  gridColor: '#3a414a',
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 11,
  labelFontColor: '#808697',
  titleFontFamily: monospaceFontFamily,
  titleFontSize: 13,
  titleFontColor: '#808697',
  tickColor: 'transparent',
  tickLength: 7,
}

export const axisX2Default = {
  gridThickness: 0,
  tickThickness: 0,
  lineThickness: 0,
  labelFontSize: 0,
  tickColor: 'transparent',
  tickLength: 0,
  stripLines: [],
  margin: 0,
}

export const axisYDefault = {
  gridThickness: 1,
  tickThickness: 1,
  lineThickness: 1,
  lineColor: '#3a414a',
  gridColor: '#3a414a',
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 11,
  labelFontColor: '#808697',
  titleFontFamily: monospaceFontFamily,
  titleFontSize: 13,
  titleFontColor: '#808697',
  tickColor: 'transparent',
  tickLength: 0,
}

export const tooltipDefault = {
  cornerRadius: 0,
  borderThickness: 1,
  shared: true,
  fontSize: 12,
  fontStyle: 'normal',
  reversed: false,
  fontFamily: primaryFontFamily,
  animationEnabled: false,
  backgroundColor: '#282d36',
  borderColor: '#3b414a',
}

/**
 * Props:
 *
 *  graphKey
 *    A unique logical ID for this graph, to distinguish it from others on the page.
 *    This should remain constant regardless of the data being displayed.
 *  height
 *    The height of the div containing the chart.
 *  series
 *    The data series to display
 *  options
 *    Extra options about the chart
 */
export class Graph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      graph: null,
    }
  }

  render() {
    const { height, graphKey } = this.props

    // notice x scrollbar was showing on windows when not needed, changing width to 100.1% solved the issue
    // overflow is needed to set a min width on chart as the screen size reduces
    return (
      <div style={{ overflow: 'auto', height: '100%' }}>
        <div id={graphKey} style={{ height }} />
      </div>
    )
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.series, this.state.series) || !_.isEqual(this.props.options, this.state.options)) {
      const { series, trendLinesData, options } = this.props
      this.setState(
        prevState => {
          prevState.graph.options = this.makeChartOptions(series, trendLinesData, options, this.props.syncHandler)
          return {
            graphKey: this.props.graphKey,
            series: series,
            options,
            graph: prevState.graph,
          }
        },
        () => {
          this.refresh()
        },
      )
    }
  }

  componentDidMount() {
    const { syncHandler } = this.props
    if (this.isZoomEnabled()) {
      syncHandler.addGraph(this)
    }
    if (this.isZoomYEnabled()) {
      this.initializeZoomModifier()
    }
    this.initializeGraph()
  }

  componentWillUnmount() {
    this.props.syncHandler.removeGraph(this)
    if (this.isZoomYEnabled()) {
      document.removeEventListener('keydown', this.onKeyDown)
      document.removeEventListener('keyup', this.onKeyUp)
    }
  }

  isZoomEnabled() {
    const { options } = this.props
    return _.isUndefined(options.zoomEnabled) || options.zoomEnabled
  }

  isZoomYEnabled() {
    const { options } = this.props
    return (_.isUndefined(options.zoomType) || options.zoomType === 'xy') && this.isZoomEnabled()
  }

  shouldShowTooltipTotal() {
    const { options } = this.props
    return !_.isUndefined(options.showTooltipTotal) && options.showTooltipTotal
  }

  initializeZoomModifier() {
    this.onKeyDown = e => {
      if (e.key === 'Shift') {
        this.handleZoomType(true)
      }
    }
    document.addEventListener('keydown', this.onKeyDown)

    this.onKeyUp = e => {
      if (e.key === 'Shift') {
        this.handleZoomType(false)
      }
    }
    document.addEventListener('keyup', this.onKeyUp)
  }

  initializeGraph() {
    const { graphKey, series, options, trendLinesData } = this.props
    const chartOptions = this.makeChartOptions(series, trendLinesData, options, this.props.syncHandler)
    const graph = new CanvasJS.Chart(graphKey, chartOptions)
    this.setState(
      {
        series,
        graph,
        graphKey,
        options,
      },
      () => {
        this.refresh()
      },
    )
  }

  toggleSeries(seriesName) {
    this.state.graph.options.data
      .filter(line => line.name === seriesName)
      .forEach(line => (line.visible = !line.visible))
  }

  toggleSeriesLineType(oldType, newType) {
    this.state.graph.options.data.forEach(line => {
      if (line.type === oldType) {
        line.type = newType
      }
    })
  }

  refresh() {
    if (_.has(this.state, 'graph')) {
      this.state.graph.render()
    }
  }

  handleZoomType(isShiftDown) {
    const zoomType = isShiftDown ? 'xy' : 'x'

    // We shouldn't manipulate state like this. will refactor when this becomes a functional component.
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.graph.zoomType = zoomType

    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.graph.options.zoomType = zoomType
  }

  makeChartOptions(allSeries, trendLinesData, options, syncHandler) {
    const chartData = allSeries.map(series =>
      Object.assign(
        {
          visible: true,
        },
        series.options || {},
      ),
    )

    const axisX = Object.assign({}, axisXDefault, options.axisX || {})
    const axisX2 = Object.assign({}, axisX2Default, options.axisX2 || {})
    const axisY = Object.assign({}, axisYDefault, options.axisY || {})
    const userTooltip = options.toolTip || {}
    const toolTip = Object.assign(
      {},
      tooltipDefault,
      _.isUndefined(userTooltip.content)
        ? { contentFormatter: defaultContentFormatter(this.shouldShowTooltipTotal()) }
        : {},
      userTooltip,
    )

    return {
      data: chartData,
      zoomType: 'x',
      rangeChanged: syncHandler.zoomHandler(),
      animationEnabled: false,
      backgroundColor: '#2e353e',
      axisX,
      axisX2,
      axisY,
      toolTip,
      zoomEnabled: this.isZoomEnabled(),
      hasSubMinuteData: !!options.hasSubMinuteData,
      dataPointWidth: options.dataPointWidth,
    }
  }
}
