import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Typography, ListItem, List, ListItemText, ListItemIcon, Tooltip, makeStyles } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import {
  KW_TO_MW,
  SETTLEMENT_INTERVAL_MINS,
  getIndex,
  getTradingStart,
  formatCalendarDateAsTradingDay,
  INTERVAL_SIZE_MINS,
} from '../../utility/utility'
import { DATE_FORMAT_DAY, DATE_FORMAT_FULL_WITH_SEC, TIME_FORMAT_WITH_SEC } from '../../utility/constants'

import Card from '../Card'

const DISPLAY_NAME = 'ManualBidLog'

const useStyles = makeStyles(
  theme => ({
    cardHeader: {
      paddingTop: theme.spacing(1.5),
      paddingRight: theme.spacing(3),
    },
    headerContent: {
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
    },
    logContent: {
      height: 'calc(100vh - 270px)',
      minHeight: 218,
      paddingRight: 0,
    },
    logBox: {
      height: '100%',
      overflow: 'auto',
    },
    list: {
      padding: 0,
    },
    item: {
      padding: 0,
    },
    text: {
      marginTop: 0,
    },
    icon: {
      fontSize: theme.typography.pxToRem(8),
    },
    listItemIconRoot: {
      minWidth: theme.typography.pxToRem(24),
    },
    pauseIcon: {
      fontSize: theme.spacing(4),
    },
    tooltip: {
      padding: theme.spacing(0.5),
    },
    tipWrapper: {
      maxWidth: 600,
    },
  }),
  { name: DISPLAY_NAME },
)

function ManualBidLog(props) {
  const classes = useStyles()
  const {
    asset,
    selectedProductName,
    selectedProductType,
    selectedDate,
    marketTimezone,
    marketStartHour,
    lastUpdated,
    manualBidsForLog,
    priceBandsRange,
    settlementInterval,
  } = props
  const bids = _.get(manualBidsForLog, 'payload', [])
  const priceBands = _.get(priceBandsRange, 'payload', [])
  const sortedBidsAndPb = bids.concat(priceBands).sort((x, y) => {
    return moment(x.createdOn) - moment(y.createdOn)
  })
  const isLoading = manualBidsForLog.isLoading || _.get(priceBandsRange, 'isLoading')
  const subheader = moment(lastUpdated).isValid()
    ? `LAST UPDATED ${formatCalendarDateAsTradingDay(
        lastUpdated,
        marketTimezone,
        marketStartHour,
        DATE_FORMAT_FULL_WITH_SEC,
      )}`
    : ''

  const ValueText = ({ record }) => {
    const isManualBid = !_.isNil(_.get(record, 'manualBidId'))
    const priceBandDisplayNames = _.get(asset, 'market.data.price_band_display_names', [])
    const priceBandNames = _.get(asset, 'market.data.price_band_names')
    const startTime = moment(_.get(record, 'startTime')).tz(marketTimezone)
    const endTime = moment(_.get(record, 'endTime')).tz(marketTimezone)
    let valueList = ''
    if (isManualBid) {
      priceBandDisplayNames.forEach((name, index) => {
        const number = _.get(record, ['values', index]) * KW_TO_MW
        valueList = `${valueList}${name}: ${number}${priceBandDisplayNames.length - 1 === index ? '' : ', '}`
      })
      const startInterval =
        settlementInterval === INTERVAL_SIZE_MINS
          ? getIndex(startTime, selectedDate, moment(selectedDate).add(1, 'd'), INTERVAL_SIZE_MINS) + 1
          : getIndex(startTime, selectedDate, moment(selectedDate).add(1, 'd'), SETTLEMENT_INTERVAL_MINS) + 1
      const endInterval =
        settlementInterval === INTERVAL_SIZE_MINS
          ? getIndex(
              moment(endTime).subtract(INTERVAL_SIZE_MINS, 'minutes'),
              selectedDate,
              moment(selectedDate).add(1, 'd'),
              INTERVAL_SIZE_MINS,
            ) + 1
          : getIndex(
              moment(endTime).subtract(SETTLEMENT_INTERVAL_MINS, 'minutes'),
              selectedDate,
              moment(selectedDate).add(1, 'd'),
              SETTLEMENT_INTERVAL_MINS,
            ) + 1

      const intervalText =
        startInterval === endInterval ? `Interval: ${startInterval}` : `Intervals: ${startInterval} to ${endInterval}`

      return (
        <div>
          <Typography>{intervalText}</Typography>
          <Typography>{valueList}</Typography>
          <Typography>Reason: {_.get(record, 'reason')}</Typography>
        </div>
      )
    } else {
      priceBandDisplayNames.forEach((name, index) => {
        const pbKey = _.get(priceBandNames, index)

        const number = _.get(record, ['data', 'values', pbKey])
        valueList = `${valueList}${name}: ${number}${priceBandDisplayNames.length - 1 === index ? '' : ', '}`
      })
      return (
        <div>
          <Typography>Effective: {startTime.format(DATE_FORMAT_DAY)}</Typography>
          <Typography>{valueList}</Typography>
          <Typography>Price Band Change</Typography>
        </div>
      )
    }
  }

  const getDataTip = record => {
    const createdOn = _.get(record, 'createdOn')
    const tradingDate = getTradingStart(asset.market, createdOn).format(DATE_FORMAT_DAY)
    const timeWithoutDate = moment(createdOn).tz(marketTimezone).format(TIME_FORMAT_WITH_SEC)
    const createdBy = !_.isNil(_.get(record, 'manualBidId'))
      ? _.get(record, 'user.name')
      : _.get(record, 'createdBy.name')

    return (
      <div className={classes.tooltip}>
        <Typography variant="h5">
          {tradingDate} {timeWithoutDate} - {createdBy}
        </Typography>
        <Typography>
          {_.get(asset, ['market', 'data', 'product_type_display_names', selectedProductType])}-Side{' '}
          {_.get(asset, ['market', 'data', 'product_display_names', selectedProductName])}
        </Typography>
        <div>
          <ValueText record={record} />
        </div>
      </div>
    )
  }

  return (
    <Card
      inProgress={isLoading}
      classes={{
        content: classes.logContent,
        header: classes.cardHeader,
        headerContent: classes.headerContent,
      }}
      title="Bid Log"
      subheader={subheader}
    >
      <div className={classes.logBox}>
        {sortedBidsAndPb.length <= 0 ? (
          <Typography>No manual bids</Typography>
        ) : (
          <List className={classes.list}>
            {sortedBidsAndPb.map((record, index) => {
              const createdOn = _.get(record, 'createdOn')
              const tradingDate = getTradingStart(asset.market, createdOn).format(DATE_FORMAT_DAY)
              const timeWithoutDate = moment(createdOn).tz(marketTimezone).format(TIME_FORMAT_WITH_SEC)
              const createdBy = !_.isNil(_.get(record, 'manualBidId'))
                ? _.get(record, 'user.name')
                : _.get(record, 'createdBy.name')
              return (
                <Tooltip
                  title={getDataTip(record)}
                  placement="left-start"
                  key={index}
                  classes={{ tooltip: classes.tipWrapper }}
                >
                  <ListItem className={classes.item}>
                    <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                      <FiberManualRecordIcon className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${tradingDate} ${timeWithoutDate} - ${createdBy}`}
                      className={classes.text}
                    />
                  </ListItem>
                </Tooltip>
              )
            })}
          </List>
        )}
      </div>
    </Card>
  )
}

ManualBidLog.displayName = DISPLAY_NAME

export default ManualBidLog
