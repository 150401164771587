import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { useOnClockTick } from '../hooks/hooks'
import { setEndOfInterval } from '../redux/features/dispatch-interval'
import { assetOperations, assetSelectors } from '../redux/features/asset'
import { shotclockOperations } from '../redux/features/shotclock'
import { timeFormatWithSeconds, getTimeAtStartOf, timeFormat } from '../utility/utility'
import ShotClock from './ShotClock'

const START_MINS = 0
const TOTAL_MINS = 5
const BIDFILE_WINDOW = 3

const useMarketShotClockStyles = makeStyles(theme => {
  return {
    root: {
      display: 'inline-flex',
      alignItems: 'flex-end',
      width: 168,
      margin: theme.spacing(0, 1, 0, 2),
    },
    infoBox: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },
    shotClockBox: {
      display: 'flex',
    },
    timeDisplay: {
      color: theme.palette.common.white,
      lineHeight: '1rem',
      margin: 0,
      fontSize: '0.875rem',
    },
    statusMsg: {
      color: theme.palette.text.tertiary,
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
    tooltip: {
      backgroundColor: theme.custom.palette.background.tooltip,
    },
    tooltipLabel: {
      fontSize: '0.875rem',
      color: theme.palette.common.white,
      lineHeight: '1.6rem',
    },
  }
})

function MarketShotClock(props) {
  const { asset, endOfInterval } = props
  const {
    dispatchGetAssetOptimizationDisabled,
    dispatchSetEndOfInterval,
    dispatchGetOptimizationRecordsRange,
    isOptimizationDisabled,
  } = props
  const classes = useMarketShotClockStyles()
  const assetId = _.get(asset, 'assetId')
  const timezone = _.get(asset, 'market.data.timezone')

  const [currentTime, setCurrentTime] = useState(moment().tz(timezone))

  const {
    'battery_opt_start_cutoff_secs': batteryOptStartCutoffSecs,
    'bid_creator_start_cutoff_secs': bidCreatorStartCutoffSecs,
    'bid_delivery_cutoff_secs': bidDeliveryCutoffSecs,
    // 'renewable_opt_start_cutoff_secs': renewableOptStartCutoffSecs,
  } = _.get(asset, 'market.data.gate_closure_cutoffs', {})

  const PRICE_COLOR_CHANGE_CAP_MINS = moment.duration(batteryOptStartCutoffSecs, 'seconds').asMinutes()
  const BID_COLOR_CHANGE_CAP_MINS = moment.duration(bidCreatorStartCutoffSecs, 'seconds').asMinutes()
  const MARKETS_CLOSED_CAP_MINS = moment.duration(bidDeliveryCutoffSecs, 'seconds').asMinutes()

  const PRICE_CREATION_CAP_MILLIS = moment.duration(batteryOptStartCutoffSecs, 'seconds').asMilliseconds()
  const BID_CREATION_CAP_MILLIS = moment.duration(bidCreatorStartCutoffSecs, 'seconds').asMilliseconds()

  // We have a 5 minute window. Each of these integers represents the minute value of a time slice edge (e.g. The 'Awaiting Data' slice is from 0 to 1.5)
  const CUTOFF_DEFAULTS_IN_MINUTES = [
    START_MINS,
    PRICE_COLOR_CHANGE_CAP_MINS,
    BID_COLOR_CHANGE_CAP_MINS,
    MARKETS_CLOSED_CAP_MINS,
    TOTAL_MINS,
  ]

  useOnClockTick(() => {
    if (!_.isNil(timezone)) {
      const newTime = moment().tz(timezone)
      setCurrentTime(newTime)
      const elapsedTimeInMs = newTime.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(newTime.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      dispatchSetEndOfInterval(newEndOfInterval.valueOf())
    }
  })

  useEffect(() => {
    const now = moment().valueOf()
    dispatchGetAssetOptimizationDisabled(assetId, now, now)
  }, [assetId, endOfInterval, dispatchGetAssetOptimizationDisabled])

  const timezoneDisplayName = _.get(asset, 'market.data.timezone_display_name')
  const bidInterval = _.get(asset, 'market.data.bid_interval', 5)
  const startTime = getTimeAtStartOf(currentTime, bidInterval).tz(timezone)
  const endTime = _.hasIn(startTime, 'clone') ? startTime.clone().add(5, 'minutes') : null

  const calcCutoffs = cutoffs => {
    return cutoffs.map((cutoff, index) =>
      _.isNil(cutoff) ? CUTOFF_DEFAULTS_IN_MINUTES[index] : Math.min(cutoff, CUTOFF_DEFAULTS_IN_MINUTES[index]),
    )
  }

  const cutoffTimes = calcCutoffs([
    START_MINS,
    PRICE_CREATION_CAP_MILLIS,
    BID_CREATION_CAP_MILLIS,
    MARKETS_CLOSED_CAP_MINS,
    TOTAL_MINS,
  ])

  const cutoffTimePercentages = _.range(0, cutoffTimes.length - 1).map(
    index => Math.abs(cutoffTimes[index + 1] - cutoffTimes[index]) / TOTAL_MINS,
  )

  const elapsedTime = currentTime.valueOf() % FIVE_MINUTES
  const elapsedTimeInMinutes = moment.duration(elapsedTime).asMinutes()

  const currentWindow = cutoffTimes.findIndex(cutoff => cutoff >= elapsedTimeInMinutes)

  const [checkBidFileInProgress, setCheckBidFileInProgress] = useState(false)
  const [isBidCreated, setIsBidCreated] = useState(true)
  useEffect(() => {
    if (currentWindow === BIDFILE_WINDOW && !isOptimizationDisabled) {
      setCheckBidFileInProgress(true)
      const startTime = moment(endOfInterval).subtract(5, 'minutes')
      const endTime = moment(endOfInterval)
      dispatchGetOptimizationRecordsRange(assetId, startTime, endTime)
        .then(response => {
          if (response.error) {
            // TODO: Think of something intelligent to do here.
          } else {
            const createdOn = _.get(response, 'payload[0].createdOn', null)
            const endOfLastInterval = moment(endOfInterval).subtract(5, 'minutes')
            if (moment(createdOn).isValid() && moment(createdOn).valueOf() > endOfLastInterval.valueOf()) {
              setIsBidCreated(true)
            } else {
              setIsBidCreated(false)
            }
          }
        })
        .finally(() => {
          setCheckBidFileInProgress(false)
        })
    }
  }, [assetId, currentWindow, dispatchGetOptimizationRecordsRange, endOfInterval, isOptimizationDisabled])

  if (_.isEmpty(asset) || _.isNil(timezone) || _.isNil(currentTime)) {
    return null
  }

  const realTimeStatus = getShotClockStatus(
    currentTime,
    cutoffTimes,
    cutoffTimePercentages,
    isBidCreated,
    isOptimizationDisabled,
  )

  const TOOLTIP_LABEL_TITLE = 'CURRENT INTERVAL'
  const tooltipLabelDescription = `${startTime.format(timeFormat)} - ${endTime.format(
    timeFormat,
  )} ${timezoneDisplayName}`

  const tooltipMsg = (
    <Box p={0.5}>
      <Box className={classes.tooltipLabel}>{TOOLTIP_LABEL_TITLE}</Box>
      <Box className={classes.tooltipLabel}>{tooltipLabelDescription}</Box>
    </Box>
  )

  return (
    <Tooltip
      title={tooltipMsg}
      placement="top-end"
      enterDelay={300}
      leaveDelay={200}
      classes={{ tooltip: classes.tooltip }}
    >
      <Box className={classes.root}>
        <Box className={classes.shotClockBox}>
          <ShotClock height={36} width={36} strokeWidth={14} radius={35} {...realTimeStatus} />
        </Box>
        <Box className={classes.infoBox}>
          <Box className={classes.timeDisplay} lineHeight="8px">
            {currentTime.format(timeFormatWithSeconds)}
          </Box>
          <Box className={classes.statusMsg}>{checkBidFileInProgress ? '...' : realTimeStatus.message}</Box>
        </Box>
      </Box>
    </Tooltip>
  )
}

MarketShotClock.displayName = 'MarketShotClock'

const FIVE_MINUTES = moment.duration(5, 'minutes').asMilliseconds()

const getShotClockStatus = (currentTime, cutoffTimes, sectorPercentages, bidCreated, isOptimizationDisabled) => {
  const elapsedTime = currentTime.valueOf() % FIVE_MINUTES
  const elapsedTimeInMinutes = moment.duration(elapsedTime).asMinutes()
  const currentPercent = elapsedTime / FIVE_MINUTES
  return {
    sectorPercentages,
    currentPercent,
    ...getStatusConfig(elapsedTimeInMinutes, cutoffTimes, bidCreated, isOptimizationDisabled),
    showSelected: false,
  }
}

const getStatusConfig = (minutesIntoInterval, cutoffs, bidCreated, isOptimizationDisabled) => {
  let statusConfig = { color: '#DDDDDD', message: 'CONNECTING' }
  if (minutesIntoInterval < cutoffs[1]) {
    statusConfig = { color: '#DDDDDD', message: 'Awaiting Data' }
  } else if (minutesIntoInterval < cutoffs[2]) {
    if (isOptimizationDisabled) {
      statusConfig = { color: '#DDDDDD', message: 'Optimization OFF' }
    } else {
      statusConfig = { color: '#EA9A3C', message: 'Optimizing Bids' }
    }
  } else if (minutesIntoInterval < cutoffs[3]) {
    statusConfig =
      isOptimizationDisabled || bidCreated
        ? { color: '#75ACED', message: 'Bidding Complete' }
        : { color: '#FF6E6E', message: 'Bidding Failed' }
  } else if (minutesIntoInterval >= cutoffs[3]) {
    statusConfig = { color: '#DDDDDD', message: 'Market Closed' }
  }
  return statusConfig
}

const mapStateToProps = state => {
  const endOfInterval = _.get(state, 'dispatchInterval.status.endOfInterval')
  const isOptimizationDisabled = assetSelectors.selectIsOptimizationDisabled(state)

  return {
    endOfInterval,
    isOptimizationDisabled,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchSetEndOfInterval: time => dispatch(setEndOfInterval(time)),
    dispatchGetAssetOptimizationDisabled: (assetId, startTime, asOf) =>
      dispatch(assetOperations.getAssetOptimizationDisabled(assetId, startTime, asOf)),
    dispatchGetOptimizationRecordsRange: (assetId, startTime, endTime) =>
      dispatch(shotclockOperations.getOptimizationRecordsRange(assetId, startTime, endTime)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketShotClock)
