import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { getChartData } from '../../redux/features/interval'
import { enablementGraphTemplate } from '../../utility/graphTemplate'
import { getForecastStripLineData, getGraphMetaData, getTimeSeriesGraph } from '../../utility/graphUtility'
import {
  enablementTag,
  marketsFix,
  secondsInMinute,
  INTERVAL_SIZE_MINS,
  SETTLEMENT_INTERVAL_MINS,
  formatMarketValue,
  getTimeAtStartOf,
} from '../../utility/utility'
import StackedGraphWrapper from './StackedGraphWrapper'

const DISPLAY_NAME = 'StackedBarGraph'

class StackedBarGraph extends StackedGraphWrapper {
  getGraphData(data) {
    const { asset } = this.props
    const timezone = asset.market.data.timezone
    const colors = [
      '#084187',
      '#1E5BA2',
      '#3a88bb',
      '#87bdd9',
      '#6ed0e0',
      '#CBE1EE',
      '#fdd6c0',
      '#f29b77',
      '#d05544',
      '#a91726',
      '#fdfdfd',
      '#e24d42',
    ]
    const extras = { colors }
    const graph = getTimeSeriesGraph(
      _.cloneDeep(enablementGraphTemplate),
      'BidStackGraph',
      data.data.lineNames || [],
      data.data,
      timezone,
      data.collationInterval * secondsInMinute,
      extras,
    )
    graph.name = 'Bids'
    graph.height = '100%'
    graph.options.axisX.gridThickness = graph.options.axisY.gridThickness = 0
    graph.options.axisY.margin = -40

    if (graph.series.length > 0) {
      asset.market.data.price_band_names.forEach((pb, index) => {
        graph.series[index].options.type = 'stackedColumn'
      })
      graph.series[data.data.lineNames.length - 2].options.type = graph.series[
        data.data.lineNames.length - 1
      ].options.type = 'line'
      graph.series[data.data.lineNames.length - 2].options.lineThickness = graph.series[
        data.data.lineNames.length - 1
      ].options.lineThickness = 2

      // add dataInterval to make time Interval-ending time AMS-5806
      const startTime = data.data.startTime.add(data.collationInterval, 'minutes')
      const endTime = data.data.startTime.clone().add(1, 'd').subtract(data.collationInterval, 'minutes')
      const factor =
        data.collationInterval > asset.market.data.bid_interval ? 2 * data.collationInterval : data.collationInterval
      const forecastStartTime = getTimeAtStartOf(data.data.forecastStartTime, data.collationInterval).add(
        factor,
        'minutes',
      )
      const stripLinesData = getForecastStripLineData(startTime, endTime, forecastStartTime)

      // a series that uses the secondary axis is required for the secondary axis stripLines to show
      graph.options.axisX2 = {
        stripLines: stripLinesData.stripLines,
        interval: graph.options.axisX.interval,
      }
      graph.series.push(stripLinesData.series)
    }

    return {
      graphs: [graph],
      legendMetaData: getGraphMetaData(graph),
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    asset,
    interval,
    selectedProductName,
    selectedProductType,
    selectedDate,
    marketTimezone,
    finalCombinedBids,
    bidIsLoading,
    priceBands,
  } = ownProps

  const settlementData = getChartData(state.collated, `${asset.assetId}_${enablementTag}_allProducts`, selectedDate)
  const isLoading = bidIsLoading || settlementData.isLoading
  let data = { startTime: selectedDate }
  if (!isLoading) {
    data = combineData(
      asset,
      selectedDate,
      selectedProductName,
      selectedProductType,
      interval,
      finalCombinedBids,
      settlementData,
      priceBands,
    )
  }

  return {
    isLoading,
    timezone: marketTimezone,
    data: {
      data,
      collationInterval: interval,
    },
  }
}

StackedBarGraph.displayName = DISPLAY_NAME

export default connect(mapStateToProps)(StackedBarGraph)

const combineData = (
  asset,
  selectedDate,
  productName,
  productType,
  collationInterval,
  productBidData,
  settlementData,
  priceBands,
) => {
  const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
  const values = _.range(0, priceBandNames.length + 2).map(i => [])
  const timezone = _.get(asset, 'market.data.timezone')
  const startTime = moment(selectedDate).tz(timezone)

  productBidData.forEach(bid => {
    _.get(bid, 'values', []).forEach((value, index) => {
      values[index].push(value)
    })
    values[priceBandNames.length].push(bid.maxAvailablePower)
  })

  const productTypeIndex = asset.productTypes.findIndex(type => type === productType)
  const productNameIndex = asset.productNames.findIndex(name => name === productName)
  const productIndex = asset.productTypes.length * productNameIndex + productTypeIndex
  values[values.length - 1] =
    collationInterval === INTERVAL_SIZE_MINS
      ? _.get(settlementData, `data.values[${productIndex}]`, [])
      : bidIntervalDataToSettlementInterval(asset, settlementData.data.values[productIndex])

  const lineNames = asset.market.data.price_band_display_names.map((priceBand, index) => {
    const priceBandDisplay = formatMarketValue(priceBands[priceBandNames[index]], 1, marketsFix, false, true)
    return `${priceBand} (${priceBandDisplay})`
  })

  lineNames.push('Max Avail')
  lineNames.push('Enablement')

  return {
    startTime: startTime,
    values,
    lineNames,
    forecastStartTime: moment(_.get(settlementData, 'data.forecastStartTime')),
  }
}

const bidIntervalDataToSettlementInterval = (asset, values) => {
  const count = SETTLEMENT_INTERVAL_MINS / INTERVAL_SIZE_MINS
  return _.range(0, values.length / count).map(index => {
    const startIndex = index * count
    let sum = 0.0
    _.range(startIndex, startIndex + count).forEach(valueIndex => {
      sum += values[valueIndex] || 0
    })
    return sum / count
  })
}
