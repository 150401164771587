import React from 'react'
import { StaticMap } from 'react-map-gl'
import _ from 'lodash'
import OverlaysWrapper from './OverlaysWrapper'
import './styles.scss'

const { MAPBOX_API_KEY } = window

function Map (props) {
  const { market } = props
  const { map_latitude, map_longitude, map_zoom } = market.data || {}

  return (
    <div data-testid="qa-market-map-container" id="market-map">
      {_.isEmpty(market) && <div style={mapPlaceholderStyle} />}
      {!_.isEmpty(market) && (
        <StaticMap
          attributionControl={false}
          width={mapWidth}
          style={{"min-width": "600px"}}
          height={mapHeight}
          latitude={map_latitude}
          longitude={map_longitude}
          zoom={map_zoom}
          mapboxApiAccessToken={MAPBOX_API_KEY}
          mapStyle={defaultMapStyle}
        >
          <OverlaysWrapper />
        </StaticMap>
      )}
    </div>
  )
}
Map.displayName = 'Map'

export default Map

const defaultMapStyle = 'mapbox://styles/samfredrickson/cjk1nio9wavfc2rk8b7smsmvb'
const mapHeight = 750
const mapWidth = '100%'
const mapPlaceholderStyle = {
  width: '100%',
  height: mapHeight,
}
