import _ from 'lodash'
import moment from 'moment-timezone'
import {
  DATE_FORMAT_DAY_OF_WEEK,
  DATE_FORMAT_FULL_WITH_SEC,
  MINUTES_IN_HOUR,
  TRADING_DAY_START_HOUR,
  MARKET_PRICE_CHART_INTERVAL,
} from '../utility/constants'
import { custom as customTheme } from '../theme/theme'
import { optForecastOrP50Title } from '../components/asset-forecast/graphs/ForecastGraphs'
import * as defaults from './utility/defaults'
import { formatYAxis } from './common'
import { formatAndPadValue, formatValue } from './utility/breakpoints'
const CanvasJS = require('../assets/scripts/canvasjs.min.js')
const { formatNumber } = CanvasJS.Chart ? CanvasJS : window.CanvasJS

const { seriesColors } = customTheme.palette.graphs

export const formatTooltip = ({ timezone, range, p50CutoffDate }) => {
  return {
    ...defaults.tooltip,
    contentFormatter: tooltipContentFormatter(timezone, moment.unix(range.start), p50CutoffDate),
  }
}

export const tooltipContentFormatter = (timezone, selectedDate, p50CutoffDate) => e => {
  const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
  const tradingDateTime =
    time.hours() * MINUTES_IN_HOUR + time.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(time).subtract(1, 'days')
      : time
  const title = `<h3 style="color: white; margin: 0;">${tradingDateTime.format(DATE_FORMAT_FULL_WITH_SEC)}</h3>`
  const subtitle = `<div style='color: white; margin-top: 0; margin-bottom: 5px'>${time.format(
    DATE_FORMAT_DAY_OF_WEEK,
  )}</div>`
  const seriesToolTip = {}
  e.entries
    .filter(entry => entry.dataSeries.visible)
    .forEach(entry => {
      // if line is a range chart
      if (_.isArray(entry.dataPoint.y)) {
        const lines = entry.dataSeries.name.split(',')
        lines.forEach((line, index) => {
          const val = lines.length === 1 ? entry.dataPoint.y[1] - entry.dataPoint.y[0] : entry.dataPoint.y[index]
          const formattedValue = _.isFinite(val) ? formatNumber(val, entry.dataSeries.yValueFormatString) : 'No Data'
          seriesToolTip[
            line
          ] = `<div><span style='color: #808697'>${line}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>`
        })
      } else {
        const formattedValue = _.isFinite(entry.dataPoint.y)
          ? formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
          : 'No Data'
        seriesToolTip[
          entry.dataSeries.name
        ] = `<div><span style='color: #808697'>${entry.dataSeries.name}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>`
      }
    })
  const displayOrder = ['Actual', 'AEMO Pre-Dispatch', 'Fluence P90', optForecastOrP50Title(selectedDate, p50CutoffDate), 'Fluence P10']
  const series = displayOrder.reduce(
    (output, series) => (seriesToolTip.hasOwnProperty(series) ? output + seriesToolTip[series] : output),
    '',
  )
  return title + subtitle + series
}

export const formatDemandSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'demand.striplineOptions'),
    _.get(data, 'demand.energyOptions'),
    _.get(data, 'demand.preMarketEnergyOptions'),
  ]
  return datum
}

export const formatDemandX2Axis = startValue => ({ range }) => {
  return [
    {
      ...defaults.x2Axis,
      viewportMinimum: range.start,
      viewportMaximum: range.end,
      stripLines: [
        {
          ...defaults.stripLines,
          startValue,
          endValue: range.end,
          color: 'white',
          opacity: 0.04,
        },
      ],
    },
  ]
}

export const formatPricesSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'p10P90Options'),
    _.get(data, 'p50Options'),
    _.get(data, 'preDispatchOptions'),
    _.get(data, 'actualOptions'),
    _.get(data, 'stripLineOptions'),
  ]
  return datum
}

export const formatEnablementsSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'enablementsForecastOptions'),
    _.get(data, 'enablementsActualOptions'),
    _.get(data, 'enablementsForecastAvailabilityOptions'),
    _.get(data, 'enablementsActualAvailabilityOptions'),
    _.get(data, 'stripLineOptions'),
  ]
  return datum
}

export const formatSettlementsSeries = ({ data, legend }) => {
  const datum = [
    _.get(data, 'settlementsForecastOptions'),
    _.get(data, 'settlementsActualOptions'),
    _.get(data, 'stripLineOptions'),
  ]
  return datum
}

export const formatPricesYAxis = (marketPriceMaximum, marketPriceMinimum) => args => {
  const allData = _.get(args, 'data')
  let max = marketPriceMinimum
  let min = marketPriceMaximum
  Object.keys(allData).forEach(options => {
    const dataPoints = _.get(allData[options], 'dataPoints')
    if (!_.isNil(dataPoints) && _.isArray(dataPoints)) {
      const yAxisValuesMax = dataPoints
        .filter(point => point.y !== null)
        .map(point => {
          const isP10P90 = _.isArray(point.y)
          return isP10P90 ? Math.max(...point.y) : point.y
        })
      const yAxisValuesMin = dataPoints
        .filter(point => point.y !== null)
        .map(point => {
          const isP10P90 = _.isArray(point.y)
          return isP10P90 ? Math.min(...point.y) : point.y
        })
      const localMax = Math.max(...yAxisValuesMax)
      const localMin = Math.min(...yAxisValuesMin)
      if (localMax > max) {
        max = localMax
      }
      if (localMin < min) {
        min = localMin
      }
    }
  })

  // Use log and some adjustments to get a kind of proportion, for proper and fulfilling display
  // We compare vMax and 10, as the smallest label the chart can auto-display for Y axis is 10
  const difference = max - min
  const mathInterval = Math.pow(MARKET_PRICE_CHART_INTERVAL, Math.floor(Math.log10(difference) - 0.5))
  const interval = Math.min(MARKET_PRICE_CHART_INTERVAL, mathInterval)
  const vMax = Math.ceil(max / interval) * interval
  const vMin = Math.floor(min / interval) * interval - 0.1 * (vMax < 10 ? 10 : mathInterval)

  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '/MWh', maxLength: 12 }),
    formatValue: formatValue({ suffix: '/MWh', prefix: '$' }),
    viewportMaximum: vMax < 10 ? null : vMax,
    viewportMinimum: vMin,
  }
}

export const formatEnablementsYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '', suffix: ' MW', maxLength: 12 }),
    formatValue: formatValue({ suffix: 'MW', prefix: '' }),
  }
}

export const formatSettlementsYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '', maxLength: 12 }),
    formatValue: formatValue({ suffix: '', prefix: '$' }),
  }
}

export const pricesLegendItems = [
  {
    id: 'np15',
    name: 'NP15',
    color: seriesColors[0],
  },
  {
    id: 'sp15',
    name: 'SP15',
    color: seriesColors[1],
  },
]

export const combine = nodes => {
  const keys = _.keys(nodes)
  return _.values(nodes)[0].map((value, index) => {
    let sum = null
    keys.forEach(key => {
      const value = _.get(nodes[key][index], 'y')
      if (_.isFinite(value)) {
        sum += value
      }
    })
    return {
      x: value.x,
      y: sum,
    }
  })
}
