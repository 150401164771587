import React, { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { Button, CheckBox, CheckBoxOutlineBlank } from '@fluence/core'
import Card from '../Card'
import { getAssetIcon, getUnitlessAssetCapacityText, getDailyRevenueDisplayValue } from '../../utility/utility'
import RouteLeavingGuard from '../RouteLeavingGuard'

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    overflow: 'auto',
  },
  cardContent: {
    paddingTop: theme.spacing(),

    paddingLeft: 0,
    paddingRight: 0,

    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  table: {
    '& td': {
      borderBottom: '1px solid  #30353c',
      padding: theme.spacing(0.5, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0.5),
      },
    },
    '& tr:last-child > td': {
      borderBottom: 0,
    },
    '& th': {
      padding: 4,
      borderBottom: '1px solid  #30353c',
    },
  },
}))

function AssetSelection(props) {
  const {
    assets = [],
    initialSelectedIds,
    userIsLoading = true,
    getUser,
    setUser,
    saveUser,
    notifySuccess,
    notifyError,
  } = props
  const classes = useStyles()

  const [localInitialSelectedIds, setLocalInitialSelectedIds] = useState(initialSelectedIds)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')

  const allUntouched = assets.reduce((accumulator, nextAsset) => {
    return { ...accumulator, [nextAsset.assetId]: false }
  }, {})
  const selectedIds = initialSelectedIds.reduce((accumulator, next) => {
    return { ...accumulator, [next]: true }
  }, allUntouched)

  const [values, setValues] = useState({ selectedIds })
  const [touched, setTouched] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)

  useEffect(() => {
    if (Object.keys(values.selectedIds).length === 0) {
      setValues({ selectedIds })
    }
  }, [selectedIds, values])

  useEffect(() => {
    if (!userIsLoading) {
      setInitialLoadComplete(true)
    }
  }, [userIsLoading])

  // When assets change, make sure we have an entry in touched for them
  useEffect(() => {
    const allUntouched = assets.reduce((accumulator, nextAsset) => {
      return { ...accumulator, [nextAsset.assetId]: false }
    }, {})

    // when assets change, make sure we add them to the touched list but keep any previous values
    setTouched(prevTouched => ({ ...allUntouched, ...prevTouched }))
  }, [assets])

  function handleSortClick(property) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleCheckboxChange = assetId => event => {
    const selectedIds = { ...values.selectedIds, [assetId]: event.target.checked }
    setValues({
      ...values,
      selectedIds,
    })
    setTouched({ ...touched, [assetId]: true })
  }

  const isDirty = () => {
    // We're going to compare by converting ids to strings then compare the arrays
    const currentlySelectedIds = Object.keys(values.selectedIds)
      .reduce((acc, next) => {
        return values.selectedIds[next] ? [...acc, next] : acc
      }, [])
      .sort()
    const initialSelectedAsStrings = localInitialSelectedIds.map(id => id + '').sort()

    return !_.isEqual(currentlySelectedIds, initialSelectedAsStrings)
  }

  const selectedIdsToArray = () => {
    return Object.keys(values.selectedIds)
      .reduce((acc, next) => {
        return values.selectedIds[next] ? [...acc, parseInt(next)] : acc
      }, [])
      .sort()
  }

  const handleSubmit = event => {
    event.preventDefault()
    setInProgress(true)

    getUser().then(userResponse => {
      if (userResponse.error) {
        notifyError('Save was unsuccessful')
        setInProgress(false)
      } else {
        const userToSave = _.cloneDeep(userResponse.payload)
        _.set(userToSave, 'preferences.marketAlerts.assetIds', selectedIdsToArray())

        return saveUser(userToSave).then(saveUserResponse => {
          if (saveUserResponse.error) {
            notifyError('Something went amiss. User was not saved.')
            setInProgress(false)
          } else {
            notifySuccess('Preferences saved')
            setTouched({})

            // Update local initial selected IDs, so isDirty() can check on most recently saved values
            setLocalInitialSelectedIds(selectedIdsToArray())

            // Update redux with our changes instead of making yet another ajax call to refresh
            setUser(userToSave)
            setInProgress(false)
          }
        })
      }
    })
  }

  const isReady = () => !_.isEmpty(assets) && !_.isEmpty(values.selectedIds) && initialLoadComplete

  const disableSave =
    !isReady() ||
    inProgress ||
    (!_.isEmpty(touched) && _.keys(touched).every(key => touched[key] === false)) ||
    !isDirty()

  const displayAssets = assets.map(asset => ({
    assetId: asset.assetId,
    name: asset.name,
    selected: values.selectedIds[asset.assetId],
    handleOnClick: handleCheckboxChange(asset.assetId),
    icon: getAssetIcon(_.get(asset, 'data.tag_prefix'), _.get(asset, 'data.asset_type')),
    type: _.get(asset, 'data.tag_prefix'),
    capacity: parseInt(getUnitlessAssetCapacityText(asset).kw),
    region: _.get(asset, 'region.data.display_name', ''),
    dailyRevenueDisplay: getDailyRevenueDisplayValue(asset),
    dailyRevenue: _.get(asset, 'data.daily_revenue', ''),
  }))

  return (
    <>
      <RouteLeavingGuard description="Preferred Assets" when={!disableSave} />
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        title="Preferred assets"
        titleTypographyProps={{ variant: 'h3' }}
        subheader="SELECT FOR EMAIL AND SMS ALERTS"
        action={
          <Button variant="text" disabled={disableSave} onClick={handleSubmit}>
            save
          </Button>
        }
      >
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableHeadRow order={order} orderBy={orderBy} handleSortClick={handleSortClick} />
          </TableHead>
          <TableBody>
            {isReady() &&
              _.orderBy(displayAssets, [orderBy], [order]).map(asset => (
                <Row key={asset.assetId} asset={asset} selected={asset.selected} onClick={asset.handleOnClick} />
              ))}
          </TableBody>
        </Table>
      </Card>
    </>
  )
}

AssetSelection.displayName = 'AssetSelection'

export default memo(AssetSelection, areEqual)

function areEqual(prevProps, nextProps) {
  const { assets: prevAssets = [] } = prevProps
  const { assets: nextAssets = [] } = nextProps
  return _.isEqual(prevAssets.map(a => a.assetId).sort(), nextAssets.map(a => a.assetId).sort())
}

const headers = [
  { name: '', key: 'selected' },
  { name: 'Type', key: 'type' },
  { name: 'ASSET', key: 'name' },
  { name: 'SIZE (MW)', key: 'capacity' },
  { name: 'REGION', key: 'region' },
  { name: 'DAILY REVENUE', key: 'dailyRevenue' },
]

const TableHeadRow = ({ order, orderBy, handleSortClick }) => (
  <TableRow>
    {headers.map(header => (
      <TableCell key={header.key} sortDirection={orderBy === header.key ? order : false}>
        <TableSortLabel active={orderBy === header.key} direction={order} onClick={() => handleSortClick(header.key)}>
          {header.name}
        </TableSortLabel>
      </TableCell>
    ))}
  </TableRow>
)

const Row = props => {
  const { asset } = props

  return (
    <TableRow hover>
      <TableCell>
        <Checkbox
          size="small"
          checked={!!asset.selected}
          onChange={asset.handleOnClick}
          checkedIcon={<CheckBox fontSize="small" />}
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          value="dnd"
          color="primary"
        />
      </TableCell>
      <TableCell style={{ lineHeight: '0.5rem' }}>{asset.icon}</TableCell>
      <TableCell>{asset.name}</TableCell>
      <TableCell>{asset.capacity}</TableCell>
      <TableCell>{asset.region}</TableCell>
      <TableCell>{asset.dailyRevenueDisplay}</TableCell>
    </TableRow>
  )
}
