import PropTypes from 'prop-types'
import { propTypeIsoString, propTypeDayFormat } from '../../utility/custom-proptypes'

const MarketBidFiles = {
  getLastBidFile: {
    name: 'getLastBidFile',
    method: 'GET',
    url: ({ assetId, productId }) => `/market/bidFiles/asset/${assetId}/product/${productId}/last`,
    pathParams: { assetId: PropTypes.number, productId: PropTypes.number },
    queryParams: {
      'tradingDay': propTypeDayFormat,
      'asOf': propTypeIsoString,
      'bidCollationInterval': PropTypes.oneOf(['SettlementInterval', 'BidInterval']),
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getLastBidFilesForProducts: {
    name: 'getLastBidFile',
    method: 'GET',
    url: ({ assetId }) => `/market/bidFiles/asset/${assetId}/last`,
    pathParams: { assetId: PropTypes.number, productId: PropTypes.number },
    queryParams: {
      'productIds': PropTypes.arrayOf(PropTypes.number),
      'tradingDay': propTypeDayFormat,
      'asOf': propTypeIsoString,
      'bidCollationInterval': PropTypes.oneOf(['SettlementInterval', 'BidInterval']),
    },
    headers: { 'Content-Type': 'application/json' },
  },
  getLastBidFilesForFutureDaysByProducts: {
    name: 'getLastBidFile',
    method: 'GET',
    url: ({ assetId }) => `/market/bidFiles/asset/${assetId}/future/last`,
    pathParams: { assetId: PropTypes.number, productId: PropTypes.number },
    queryParams: {
      'productIds': PropTypes.arrayOf(PropTypes.number),
      'tradingDay': propTypeDayFormat,
    },
    headers: { 'Content-Type': 'application/json' },
  },
}

export default MarketBidFiles
