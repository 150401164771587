import _ from 'lodash'

// Field Constants
export const ALLOW_NEGATIVE_CONFIG_FIELD = 'allowNegativeInput'
export const ALLOW_EMPTY_SUBMIT_FIELD = 'allowSubmitEmptyInput'
export const OVERRIDE_CHECKBOX_CONFIG_FIELD = 'overrideCheckbox'
export const PRECISION_FIELD = 'precision'
export const MAX_VALUE_LIMIT_FIELD = 'maxValueLimit'

/**
 * Checks if input value has error against inputConfig
 *
 * @param {object} values Input values object that records form values
 * @param {object} config an input config object
 * @param {string} inputLabel config label key
 * @param {number} inputDecimalLimit number of decimals allowed
 * @param {Function} hasValidInputCrossValidation additional input cross validation function
 * @returns {boolean} whether there is a value error
 */
export const hasPriceError = (
  values: object,
  config: object,
  inputLabel: string,
  inputDecimalLimit: number,
  hasValidInputCrossValidation: Function = () => true,
): boolean => {
  const price = _.get(values, `input_${inputLabel}`)
  const priceString = _.toString(price)
  const priceArray = !_.isNil(price) ? priceString.split('.') : []
  const allowNegativeInput = _.get(values, `${ALLOW_NEGATIVE_CONFIG_FIELD}_${inputLabel}`)
  const allowSubmitEmptyInput = _.get(config, ALLOW_EMPTY_SUBMIT_FIELD, false)
  const isLegalEmptyInput = _.get(priceString, 'length', 0) === 0 && allowSubmitEmptyInput
  const priceIsEmptyOrNan = (isNaN(parseFloat(price)) || _.get(priceString, 'length', 0) === 0) && !isLegalEmptyInput
  const priceExceedsLengthLimit = priceArray.length > 1 && priceArray[1].length > inputDecimalLimit
  const hasIllegalNegativeInput = !allowNegativeInput && price < 0
  const priceGreaterThanMax = !_.isNil(_.get(config, MAX_VALUE_LIMIT_FIELD))
    ? price > _.get(config, MAX_VALUE_LIMIT_FIELD)
    : false
  return (
    priceIsEmptyOrNan ||
    priceExceedsLengthLimit ||
    hasIllegalNegativeInput ||
    priceGreaterThanMax ||
    !hasValidInputCrossValidation(values, config, parseFloat(price), `input_${inputLabel}`)
  )
}

/**
 * Generate inputConfigs that will check input errors and output config data that will be used on the text field React component
 *
 * @param {object} values Input values object that records form values
 * @param {object} config config object that records config information
 * @param {object} touched touched object that record whether form values were modified
 * @param {string} inputFieldLabel label type (e.g. return text field)
 * @param {number} inputDecimalLimit number of decimals allowed
 * @param {Function} hasValidInputCrossValidation additional input cross validation function
 * @returns {object} input configs that will be used on the React component
 */
export const getInputTextFieldProps = (
  values: object,
  config: object,
  touched: object,
  inputFieldLabel: string,
  inputDecimalLimit: number,
  hasValidInputCrossValidation: Function = () => true,
): InputTextFieldProps => {
  const inputLabel = `input_${inputFieldLabel}`
  const overrideCheckboxLabel = `${OVERRIDE_CHECKBOX_CONFIG_FIELD}_${inputFieldLabel}`
  const allowNegativeInput = _.get(values, `${ALLOW_NEGATIVE_CONFIG_FIELD}_${inputFieldLabel}`)
  const isOverrideCheckboxChecked = _.get(values, overrideCheckboxLabel)
  const isInputValueError = hasPriceError(
    values,
    config,
    inputFieldLabel,
    _.get(config, PRECISION_FIELD, inputDecimalLimit),
    hasValidInputCrossValidation,
  )
  const showInputValueError = isInputValueError && _.get(touched, inputLabel)
  const showBepPriceError = !isOverrideCheckboxChecked && showInputValueError
  return {
    inputLabel,
    allowNegativeInput,
    showBepPriceError,
    showInputValueError,
    overrideCheckboxLabel,
    isOverrideCheckboxChecked,
  }
}

interface InputTextFieldProps {
  inputLabel: string
  allowNegativeInput: boolean
  showBepPriceError: boolean
  showInputValueError: boolean
  overrideCheckboxLabel: string
  isOverrideCheckboxChecked: boolean
}
