import React, { useRef, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSWR, { SWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { RSAA } from 'redux-api-middleware'
import { BATTERY_COMMAND_SETTING_TAG, OPERATIONAL_CAPACITY_SETTING_TAG, PASA_SETTING_TAG } from '../utility/constants'
import auth from '../utility/auth'

import * as apiDefs from './api/'

export const { WEB_API_ROOT: apiRoot, PLAT_API_ROOT: platApiRoot } = window

/**
 * Facilitates defining actions against Armada API resources.
 *
 * @example
 * // an action to get a resource
 * const foos = new ResourceAction({
 *   type: "FOO",
 *   config: (fooId) => ({
 *     endpoint: `/foos/${fooId}`,
 *   }),
 * })
 * // ...
 * dispatch(foos.get(1))
 *
 * @example
 * // actions to get and post a resource
 * const bars = new ResourceAction({
 *   type: "BAR",
 *   method: "GET",
 *   config: (barId) => ({
 *     endpoint: `/bars/${barId}`,
 *     meta: { barId },
 *   }),
 * })
 * const newBars = new ResourceAction({
 *   type: "CREATE_BAR",
 *   method: "POST",
 *   config: (bar) => ({
 *     endpoint: `/bars`,
 *     body: JSON.stringify(bar),
 *   }),
 * })
 * // ...
 * dispatch(newBars.post(myBar))
 */
export class ResourceAction {
  /**
   * Create an object for acquiring some type of resource from the Armada API.
   *
   * @param {object} params - Configuration.
   * @param {string} params.type - The prefix of action types for resource
   *     request, success, and error. Required.
   * @param {(object | ResourceAction~configCallback)} params.config - Either
   *     a partial redux-api-middleware action object, or a callback to create
   *     one. Required.
   * @param {string} [params.method] - The HTTP method to use against the
   *     resource. Defaults to "GET".
   * @param {string} [params.contentType] - The Content-Type to request.
   *     Defaults to "application/json".
   */
  constructor({
    config = {},
    contentType = 'application/json',
    method = 'GET',
    type = null,
    shouldCache = false,
    cacheKey,
  }) {
    if (_.isNil(type)) {
      throw new Error('"type" property must be provided for ResourceAction')
    }
    this.config = config
    this.contentType = contentType
    this.request = `${type}_REQUEST`
    this.success = `${type}_SUCCESS`
    this.error = `${type}_ERROR`
    this.method = method.toUpperCase()
    this.shouldCache = shouldCache
    this.cacheKey = cacheKey || config.endpoint

    this[this.method.toLowerCase()] = function createResourceAction() {
      return this.createAction(arguments)
    }
  }

  /**
   * Generate an action to request the resource.
   *
   * @param {...*} arguments - Parameters applied to `params.config` if it is
   *     a function.
   * @returns {Object} A redux-api-middleware action to request the resource.
   */
  createAction = args => {
    // using an arrow function here allows us access to the class 'this' context.

    // get configuration specific for this action
    const paramConfig = this.config
    const userConfig = { ...(_.isFunction(paramConfig) ? paramConfig(...args) : paramConfig) }

    // prepend the API root to the endpoint
    const webApiRoot = _.get(userConfig, 'apiRoot', apiRoot)
    delete userConfig.apiRoot
    userConfig.endpoint = `${webApiRoot}${userConfig.endpoint}`

    // if extra metadata tags, save them and remove
    const extraMeta = _.get(userConfig, 'meta', {})
    delete userConfig.meta

    const baseConfig = {
      method: this.method || 'GET',
      headers: {
        'Content-Type': this.contentType,
        Authorization: `Bearer ${auth.getToken()}`,
      },

      types: [
        {
          type: this.request,
          meta: {
            ...extraMeta,
            startedOn: moment().utc(),
          },
        },
        {
          type: this.success,
          meta: (action, state, res) => {
            return {
              cache: this.shouldCache,
              cacheKey: userConfig.endpoint,
              statusText: res.statusText,
              ...extraMeta,
              updatedOn: moment().utc(),
            }
          },
        },
        {
          type: this.error,
          meta: { ...extraMeta },
        },
      ],
    }

    return {
      [RSAA]: { ...baseConfig, ...userConfig },
    }
  }
}

/**
 *
 * @callback ResourceAction~configCallback
 * @param {...*} arguments - Parameters to generate an endpoint for a particular resource.
 * @return {ResourceActionConfig} - A partial redux-api-middleware action object, which at minimum contains an `endpoint` property.
 */

/**
 * A partial redux-api-middleware action object, which at minimum contains an
 * `endpoint` property.
 *
 * @typedef {Object} ResourceActionConfig
 * @property {string} endpoint - The API endpoint to fetch the resource from.
 *     Required.
 */

/**
 * Create a Redux reducer that stores the payload of an Armada API action.
 * TODO: add ability to inject custom handling for each case?
 *
 * @param resourceAction  The ResourceAction whose lifecycle and payload will be managed.
 * @param defaultPayload  The default value for the payload. Defaults to `undefined`.
 * @returns {ResourceActionReducer} A reducer
 */
export const makeResourceReducer = (resourceAction, defaultPayload = undefined, onErrorSetToDefaultPayload = false) => {
  return (state = { isLoading: true, isError: false, payload: defaultPayload }, action) => {
    switch (action.type) {
      case resourceAction.success: {
        const payload = _.isEqual(state.payload, action.payload) ? state.payload : action.payload
        return {
          isLoading: false,
          isError: false,
          payload,
          updatedOn: _.get(action, 'meta.updatedOn'),
        }
      }
      case resourceAction.request:
        return {
          ...state,
          isLoading: true,
        }
      case resourceAction.error: {
        const newState = {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
          payload: defaultPayload,
        }
        if (onErrorSetToDefaultPayload) {
          newState.payload = defaultPayload
        }
        return newState
      }
      default:
        return state
    }
  }
}

/**
 * @typedef {Function} ResourceActionReducer
 * @param {boolean} state.isLoading - When `true`, a request to the API is
 *     pending. The previous payload, if any, will remain untouched, until
 *     the response comes back.
 * @param {boolean} state.isError - When `true`, the most recent request failed,
 *     and the payload represents the error that occurred.
 * @param {Object | Error} state.payload - The result of the request, either a
 *     valid Object, or an Error.
 * @param {Object} action - The dispatched action.
 */

export const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${auth.getToken()}`,
  }
}

export const getJsonHeader = () => {
  return Object.assign({}, getAuthHeader(), {
    'Content-Type': 'application/json',
  })
}

export const getCSVHeader = () => {
  return Object.assign({}, getAuthHeader(), {
    'Content-Type': 'application/json',
    Accept: 'text/csv',
  })
}

export const API = {
  bess: {
    postSchedule: (
      assetId,
      startTime,
      endTime,
      schedule,
      data,
      scheduleType,
      tag = 'battery_command_setting',
      options = {
        request: 'BESS_POST_SCHEDULE_REQUEST',
        success: 'BESS_POST_SCHEDULE_SUCCESS',
        error: 'BESS_POST_SCHEDULE_ERROR',
        meta: {},
      },
    ) => {
      const endpoint = `${apiRoot}/market/assets/assetScheduledData`
      return {
        [RSAA]: {
          endpoint,
          method: 'POST',
          headers: { 'Content-Type': 'application/json', ...getAuthHeader() },
          fetch,
          types: [
            options.request,
            { type: options.success, meta: { updatedOn: moment().utc(), ...options.meta } },
            options.error,
          ],
          body: JSON.stringify({
            assetId,
            tag,
            startTime,
            endTime,
            scheduleType,
            schedule,
            data,
          }),
        },
      }
    },
    deleteSchedule: (
      assetScheduledDataId,
      options = {
        request: 'BESS_DELETE_SCHEDULE_REQUEST',
        success: 'BESS_DELETE_SCHEDULE_SUCCESS',
        error: 'BESS_DELETE_SCHEDULE_ERROR',
        meta: {},
      },
    ) => {
      const queryString = createParamString({
        assetScheduledDataId: assetScheduledDataId,
      })
      const endpoint = `${apiRoot}/market/assets/assetScheduledData/delete${queryString}`
      return {
        [RSAA]: {
          endpoint,
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', ...getAuthHeader() },
          fetch,
          types: [
            options.request,
            { type: options.success, meta: { updatedOn: moment().utc(), ...options.meta } },
            options.error,
          ],
        },
      }
    },
  },
  marketContracts: {
    getLastContracts: createApiActionFromDefinition(apiDefs.marketContracts.getLastContracts),
  },
  marketFrontendMarkets: {
    marketName: (
      marketName = 'AEMO',
      options = {
        request: 'MARKETS_MARKETNAME_REQUEST',
        success: 'MARKETS_MARKETNAME_SUCCESS',
        error: 'MARKETS_MARKETNAME_ERROR',
        meta: {},
      },
      cache = false,
    ) => {
      const endpoint = `${apiRoot}/market/markets/marketName/${marketName}`
      const cacheKey = endpoint
      const types = createRsaaTypes({ cache, cacheKey, ...options })
      return {
        [RSAA]: {
          endpoint,
          method: 'GET',
          headers: getAuthHeader(),
          types,
        },
      }
    },
  },
  marketFrontendAssets: {
    all: (
      marketName = 'AEMO',
      options = { request: 'ASSETS_REQUEST', success: 'ASSETS_SUCCESS', error: 'ASSETS_ERROR', meta: {} },
      cache = false,
    ) => {
      const endpoint = `${apiRoot}/market/frontend/assets?marketName=${marketName}`
      const cacheKey = endpoint
      return {
        [RSAA]: {
          endpoint,
          method: 'GET',
          headers: getAuthHeader(),
          types: createRsaaTypes({ cache, cacheKey, ...options }),
        },
      }
    },
    get: (
      assetId,
      options = { request: 'ASSET_REQUEST', success: 'ASSET_SUCCESS', error: 'ASSET_ERROR', meta: {} },
    ) => ({
      [RSAA]: {
        endpoint: `${apiRoot}/market/frontend/asset/${assetId}`,
        method: 'GET',
        headers: getAuthHeader(),
        types: [
          options.request,
          { type: options.success, meta: { updatedOn: moment().utc(), ...options.meta } },
          options.error,
        ],
      },
    }),
  },
  marketFrontendInterval: {
    product: {
      region: {
        collated: {
          get: (
            regionId,
            startTime,
            endTime,
            tag,
            productIds,
            forecastStartTime,
            options = {
              request: 'GROUP_COLLATED_REGION_PRODUCT_INTERVAL_REQUEST',
              success: 'GROUP_COLLATED_REGION_PRODUCT_INTERVAL_SUCCESS',
              error: 'GROUP_COLLATED_REGION_PRODUCT_INTERVAL_ERROR',
              meta: {},
            },
          ) => {
            const queryString = createParamString({
              startTime: startTime.toISOString(),
              endTime: endTime.toISOString(),
              productId: productIds,
              regionCollationType: tag,
              forecastStartTime: forecastStartTime.toISOString(),
            })
            return {
              [RSAA]: {
                endpoint: `${apiRoot}/market/frontend/products/region/${regionId}/interval/collated${queryString}`,
                method: 'GET',
                headers: getAuthHeader(),
                bailout: false, // todo: check if already cached
                types: [
                  options.request,
                  { type: options.success, meta: { updatedOn: moment().utc(), ...options.meta } },
                  options.error,
                ],
              },
            }
          },
        },
      },
    },
  },
  marketAssetData: {
    getMostRecentAssetDataRecord: createApiActionFromDefinition(apiDefs.marketAssetData.getMostRecentAssetDataRecord),
    getAssetById: createApiActionFromDefinition(apiDefs.marketAssetData.getAssetById),
  },
  marketAssetProductData: {
    getLatestAssetProductData: createApiActionFromDefinition(apiDefs.marketAssetProductData.getLatestAssetProductData),
    getAssetProductDataRange: createApiActionFromDefinition(apiDefs.marketAssetProductData.getAssetProductDataRange),
    createAssetProductData: createApiActionFromDefinition(apiDefs.marketAssetProductData.createAssetProductData),
    deleteAssetProductData: createApiActionFromDefinition(apiDefs.marketAssetProductData.deleteAssetProductData),
  },
  marketBidFiles: {
    getLatestBidFile: createApiActionFromDefinition(apiDefs.marketBidFiles.getLastBidFile),
    getLastBidFilesForProducts: createApiActionFromDefinition(apiDefs.marketBidFiles.getLastBidFilesForProducts),
    getLatestBidFilesForFutureDayByProducts: createApiActionFromDefinition(
      apiDefs.marketBidFiles.getLastBidFilesForFutureDaysByProducts,
    ),
  },
  marketIntervals: {
    getAssetIntervals: createApiActionFromDefinition(apiDefs.marketIntervals.getAssetIntervals),
    getAssetAggregatedIntervals: createApiActionFromDefinition(apiDefs.marketIntervals.getAssetAggregatedIntervals),
    getAssetAggregatedIntervalsMultiple: createApiActionFromDefinition(
      apiDefs.marketIntervals.getAssetAggregatedIntervalsMultiple,
    ),
    getRangeOfActiveAssetScheduledData: createApiActionFromDefinition(
      apiDefs.marketIntervals.getRangeOfActiveAssetScheduledData,
    ),
  },
  marketManualBids: {
    getManualBidsByProductIds: createApiActionFromDefinition(apiDefs.marketManualBids.getManualBidsByProductIds),
  },
  marketOptimizations: {
    getOptimizationRecordsRange: createApiActionFromDefinition(apiDefs.marketOptimizations.getOptimizationRecordsRange),
  },
}

function createApiActionFromDefinition(def) {
  return (requestParameters = {}) => {
    const {
      body,
      pathParams = {},
      queryParams = {},
      types = {
        request: 'FORGOT_TO_DEFINE_TYPE_REQUEST',
        success: 'FORGOT_TO_DEFINE_TYPE_SUCCESS',
        error: 'FORGOT_TO_DEFINE_TYPE_ERROR',
      },
      meta = {},
      cache = false,
      cacheKey: cacheKeyProp,
    } = requestParameters
    const {
      headers = {},
      method = 'GET',
      name = 'Api definition missing name',
      pathParams: pathParamsDef,
      queryParams: queryParamsDef,
      url,
    } = def
    const endpoint = `${apiRoot}${url(pathParams)}${createParamString(queryParams)}`
    const cacheKey = cacheKeyProp || endpoint

    PropTypes.checkPropTypes(pathParamsDef, pathParams, 'pathParams', name)
    PropTypes.checkPropTypes(queryParamsDef, queryParams, 'queryParams', name)

    return {
      [RSAA]: {
        endpoint,
        method,
        headers: { ...headers, ...getAuthHeader() },
        bailout: false, // todo: Check if already cached
        body: JSON.stringify(body),
        types: createRsaaTypes({ cache, cacheKey, ...types, meta }),
      },
    }
  }
}

/**
 *
 * @param {cache, cacheBy, request, success, error, meta} args
 */
const createRsaaTypes = args => {
  return [
    {
      type: args.request,
      meta: (action, state) => {
        return {
          updatedOn: moment().utc(),
          ...args.meta,
        }
      },
    },
    {
      type: args.success,
      meta: (action, state, res) => {
        return {
          cache: args.cache,
          cacheKey: args.cacheKey,
          updatedOn: moment().utc(),
          statusText: res.statusText,
          ...args.meta,
        }
      },
    },
    args.error,
  ]
}

/**
 * @param params {Object} Object with key/value pairs to be transformed into field/values  e.g. { fieldName1: fieldValue1, fieldName2: fieldValue2 }
 * NOTE: values can be 1 dimensional arrays.  e.g. { fieldName: [fieldValue1, fieldValue2] }
 * @returns {string} Query string which can be appended to a request url.
 * @example
 * // returns '?startTime=123&tag=tag1&tag=tag2'
 * createParamString({ startTime: 123, tag: ['tag1','tag2']})
 */
export const createParamString = params => {
  const allPairs = []
  if (_.isEmpty(params)) {
    return ''
  }

  if (_.isPlainObject(params)) {
    const paramToList = _.toPairs(params)

    for (let i = 0; i < paramToList.length; i++) {
      const listItem = paramToList[i]
      const [label, paramValue] = listItem
      if (_.isArray(paramValue)) {
        for (let k = 0; k < paramValue.length; k++) {
          const paramArrayValue = paramValue[k]
          allPairs.push([label, encodeURIComponent(paramArrayValue)])
        }
      } else {
        if (paramValue !== null) {
          allPairs.push([label, encodeURIComponent(paramValue)])
        }
      }
    }

    return `?${allPairs.map(p => p.join('=')).join('&')}`
  }
}
export const fetcher = (...args) => {
  const [url, ...rest] = args
  const headers = {
    ...getAuthHeader(),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  return fetch(url, { headers, ...rest }).then(res => res.json())
}
export const multiFetcher = (...args) => {
  const urls = _.flatten(args)

  const headers = {
    ...getAuthHeader(),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  const f = url => fetch(url, { headers }).then(res => res.json())
  return Promise.all(urls.map(f))
}

export const SWRGlobalConfig = props => {
  return <SWRConfig value={{ fetcher }}>{props.children}</SWRConfig>
}

export const useGetAssetDataRange = (assetId, startTime, endTime, tag) => {
  const url = `${apiRoot}/market/assets/asset/${assetId}/assetData/range?tag=${tag}&startTime=${encodeURIComponent(
    moment(startTime).toISOString(),
  )}&endTime=${encodeURIComponent(moment(endTime).toISOString())}`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)

  const endOfInterval = useSelector(state => _.get(state, 'dispatchInterval.status.endOfInterval'))
  const enableEndOfIntervalRevalidation = useRef(false)
  useEffect(() => {
    if (_.isNumber(endOfInterval)) {
      // The first time endOfInterval is set as a number, we don't want to revalidate
      if (enableEndOfIntervalRevalidation.current) {
        mutate()
      } else {
        // After the first time endOfInterval is set as a number, we want to revalidate
        enableEndOfIntervalRevalidation.current = true
      }
    }
  }, [endOfInterval, mutate])

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetAssetDataSchedule = (assetId, startTime, tag) => {
  // This selectetor is used to trigger a revalidation when the endOfInterval changes
  useSelector(state => _.get(state, 'dispatchInterval.status.endOfInterval'))
  const { data, error, isLoading, mutate } = useSWR(
    () =>
      `${apiRoot}/market/frontend/asset/${assetId}/assetData/schedule?tag=${tag}&startTime=${encodeURIComponent(
        moment(startTime).toISOString(),
      )}`,
    fetcher,
    {
      dedupingInterval: 1000,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  )

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetMultipleAssetDataRange = (assetId, startTime, endTime, tags) => {
  // This selectetor is used to trigger a revalidation when the endOfInterval changes
  useSelector(state => _.get(state, 'dispatchInterval.status.endOfInterval'))
  const paramString = createParamString({
    tags,
    startTime: moment(startTime).toISOString(),
    endTime: moment(endTime).toISOString(),
    deleted: false,
  })
  const url = `${apiRoot}/market/frontend/asset/${assetId}/assetData/multiple/range${paramString}`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    dedupingInterval: 1000,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetMultipleAssetDataSchedule = (assetId, startTime, tags) => {
  // This selectetor is used to trigger a revalidation when the endOfInterval changes
  useSelector(state => _.get(state, 'dispatchInterval.status.endOfInterval'))
  const paramString = createParamString({
    tags,
    startTime: moment(startTime).toISOString(),
  })
  const url = `${apiRoot}/market/frontend/asset/${assetId}/assetData/multiple/schedule${paramString}`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    dedupingInterval: 1000,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetAssetScheduledDataResource = (assetId, startTime, endTime, tag) => {
  const url = `${apiRoot}/market/assets/asset/${assetId}/assetScheduledData/range?startTime=${encodeURIComponent(
    moment(startTime).toISOString(),
  )}&endTime=${encodeURIComponent(moment(endTime).toISOString())}&tag=${tag}`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetAssetDataLastResource = (assetId, startTime, tag) => {
  const startTimeStr = encodeURIComponent(moment(startTime).toISOString())
  const url = `${apiRoot}/market/assets/asset/${assetId}/assetData/last?startTime=${startTimeStr}&tag=${tag}&asOf=${startTimeStr}&deleted=false`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

export const useGetAllActiveProducts = (marketId, asOf) => {
  const asOfStr = encodeURIComponent(moment(asOf).toISOString())
  const url = `${apiRoot}/market/products/market/${marketId}/active?asOf=${asOfStr}`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}

/**
 * Market Asset Data
 */
export const useGetOperationalCapacitySettingsRange = (assetId, startTime, endTime) => {
  return useGetAssetDataRange(assetId, startTime, endTime, OPERATIONAL_CAPACITY_SETTING_TAG)
}

export const useGetPasaSettingRange = (assetId, startTime, endTime) => {
  return useGetAssetDataRange(assetId, startTime, endTime, PASA_SETTING_TAG)
}

export const useGetPasaLast = (assetId, startTime) => {
  return useGetAssetDataLastResource(assetId, startTime, PASA_SETTING_TAG)
}

export const useGetOpCapAndPasaAssetDataRange = (assetId, startTime, endTime) => {
  return useGetMultipleAssetDataRange(assetId, startTime, endTime, [OPERATIONAL_CAPACITY_SETTING_TAG, PASA_SETTING_TAG])
}

/**
 * Market Asset Scheduled Data
 */
export const useGetBatteryCommandSettings = (assetId, startTime, endTime) => {
  return useGetAssetScheduledDataResource(assetId, startTime, endTime, BATTERY_COMMAND_SETTING_TAG)
}

/**
 * Market Asset Data Schedule (<-- what is up with this naming? how is it different from Asset Scheduled Data?)
 */
export const useGetOperationalCapacityAssetDataSchedule = (assetId, startTime) => {
  return useGetAssetDataSchedule(assetId, startTime, OPERATIONAL_CAPACITY_SETTING_TAG)
}

export const useGetPasaSettingAssetDataSchedule = (assetId, startTime) => {
  return useGetAssetDataSchedule(assetId, startTime, PASA_SETTING_TAG)
}

export const useGetOpCapAndPasaAssetDataSchedule = (assetId, startTime) => {
  return useGetMultipleAssetDataSchedule(assetId, startTime, [OPERATIONAL_CAPACITY_SETTING_TAG, PASA_SETTING_TAG])
}

// PLAT api
export const useGetAdministrableUsers = () => {
  const url = `${platApiRoot}/users/administrable`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)
  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}
export const useGetCurrentUser = () => {
  const url = `${platApiRoot}/users/current`
  const { data, error, isLoading, mutate } = useSWR(url, fetcher)
  return {
    data,
    isLoading,
    isError: error,
    mutate,
  }
}
const customFetcher = url => {
  const headers = {
    ...getAuthHeader(),
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  return fetch(url, { headers }).then(res => res.json())
}

export const useFetchMarketLevelChildren = () => {
  const url = `${platApiRoot}/permissions/administrable/customers?iso=AEMO`
  return useSWRMutation(url, fetcher)
}

export const useFetchGlobalPermissions = () => {
  const url = `${platApiRoot}/permissions/administrable/globalPermissions`
  return useSWRMutation(url, fetcher)
}

const basicAppendURLFetcher = (url, { arg: id }) => {
  const urlWithParams = `${url}/${id}`
  return customFetcher(urlWithParams)
}
export const useFetchCustomerLevelPermissions = () => {
  const url = `${platApiRoot}/permissions/administrable/customer`
  return useSWRMutation(url, basicAppendURLFetcher)
}

const customerChildrenFetcher = (url, { arg: customerId }) => {
  const urlWithParams = `${url}/${customerId}/assets`
  return customFetcher(urlWithParams)
}
export const useFetchCustomerChildren = () => {
  const url = `${platApiRoot}/permissions/administrable/customers`
  return useSWRMutation(url, customerChildrenFetcher)
}

export const useFetchAssetChildren = () => {
  const url = `${platApiRoot}/permissions/administrable/assets/`
  return useSWRMutation(url, customerChildrenFetcher)
}

export const useFetchAssetPermission = () => {
  const url = `${platApiRoot}/permissions/administrable/assets`
  return useSWRMutation(url, basicAppendURLFetcher)
}

const post = (url, body) => {
  const params = {
    method: 'POST',
    headers: {
      ...getAuthHeader(),
      'Content-Type': 'application/json',
    },
  }
  if (!_.isNil(body)) {
    params.body = JSON.stringify(body)
  }
  return fetch(url, { ...params }).then(res => {
    return res
  })
}
const put = async (url, body) => {
  const headers = {
    ...getAuthHeader(),
    'Content-Type': 'application/json',
  }
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(body) }).then(res => {
    return res
  })
}

export const resetPassword = email => {
  const url = `${platApiRoot}/users/reset/${email}`
  return post(url)
}

export const resendMFA = email => {
  const url = `${platApiRoot}/users/mfa/enrollment/${email}`
  return post(url)
}

export const createUser = body => {
  const url = `${platApiRoot}/users`
  return post(url, body)
}
export const updateUser = body => {
  const url = `${platApiRoot}/users`
  return put(url, body)
}

export const activate = userId => {
  const url = `${platApiRoot}/users/${userId}/activate`
  return post(url)
}
export const deactivate = userId => {
  const url = `${platApiRoot}/users/${userId}/deactivate`
  return post(url)
}

export const enableMfa = (userId, hasMfa) => {
  const url = `${platApiRoot}/users/${userId}/enableMfa?value=${hasMfa}`
  return post(url)
}
