import _ from 'lodash'
import moment from 'moment-timezone'
import * as defaults from '../../../graphs/utility/defaults'
import { formatYAxis as formatYAxisDefault } from '../../../graphs/common'
import {
  formatAndPadValue,
  formatValue,
  yAxisBreakpoints as defaultYAxisBreakpoints,
} from '../../../graphs/utility/breakpoints'
import { MINUTES_IN_HOUR, TRADING_DAY_START_HOUR } from '../../../utility/constants'
import { dayOfWeekFormat } from '../../../utility/formats'
import { dateTimeFormatWithSeconds, convertMWToKW, convertkWToMW } from '../../../utility/utility'

export const formatX2Axis =
  startValue =>
  ({ range }) => {
    return [
      {
        ...defaults.x2Axis,
        viewportMinimum: range.start,
        viewportMaximum: range.end,
        stripLines: [
          {
            ...defaults.stripLines,
            startValue,
            endValue: range.end,
            color: 'white',
            opacity: 0.04,
          },
        ],
      },
    ]
  }

const legendMapping = {
  'powerOptions': 'POWER',
  'dischargePowerCapacityLimitOptions': 'DISCHARGE_POWER_CAP_LIMIT',
  'chargePowerCapacityLimitOptions': 'CHARGE_POWER_CAP_LIMIT',
  'overrideDischargePowerCapacityLimitOptions': 'OVERRIDE_DISCHARGE_POWER_CAP_LIMIT',
  'overrideChargePowerCapacityLimitOptions': 'OVERRIDE_CHARGE_POWER_CAP_LIMIT',
  'minimumActivePowerLimitOptions': 'MIN_MAX_ACTIVE_POWER',
  'maximumActivePowerLimitOptions': 'MIN_MAX_ACTIVE_POWER',
  'availabilityOptions': 'ACTUAL_AVAILABILITY',
  'powerForecastOptions': 'FORECAST_AVAILABILITY',
  'soeOptions': 'SOE',
  'soeMaxOptions': 'SOE_MINMAX',
  'soeMinOptions': 'SOE_MINMAX',
  'consumedReserveOptions': 'CONSUMED_RESERVE',
  'disChargeLimitOptions': 'SCHEDULED_DISCHARGE_LIMIT',
  'chargeLimitOptions': 'SCHEDULED_CHARGE_LIMIT',
  'minSOEOptions': 'SCHEDULED_MIN_SOE',
  'maxSOEOptions': 'SCHEDULED_MAX_SOE',
}

export const formatSeries = ({ data, legend }) => {
  for (const options in data) {
    if (_.has(legendMapping, options)) {
      data[options].visible = legend[legendMapping[options]]
    }
  }
  return _.reverse(_.values(data))
}

export const formatPowerYAxis = args => {
  return {
    ...formatYAxisDefault(args),
    labelFormatter: formatAndPadValue({ prefix: '', suffix: ' MW', maxLength: 12 }),
    formatValue: formatValue({ prefix: '', suffix: ' MW' }),
  }
}

export const formatSoeYAxis = (capacityKw, minSoeKwValueFromChartData) => args => {
  const topBoundary = convertkWToMW(capacityKw * 1.2)
  const maxInterval = parseInt(capacityKw / 5.0)
  const breakpoint = yAxisBreakpoints.find(breakpoint => breakpoint.maximum >= topBoundary) || {
    maximum: Number.MAX_SAFE_INTEGER,
    tickInterval: maxInterval,
  }
  const shouldAttachYMin = minSoeKwValueFromChartData < -1 * capacityKw
  return {
    ...defaults.yAxis,
    ...(shouldAttachYMin && { minimum: convertkWToMW(capacityKw) * -0.1 }),
    maximum: topBoundary,
    interval: breakpoint.tickInterval,
    labelFormatter: formatAndPadValue({ prefix: '', suffix: ' MWh', maxLength: 12 }),
    formatValue: formatValue({ prefix: '', suffix: ' MWh' }),
  }
}

export const formatSOETooltip =
  capacity =>
  ({ timezone }) => {
    return {
      ...defaults.tooltip,
      contentFormatter: soeContentFormatter(timezone, capacity),
    }
  }

const soeContentFormatter = (timezone, capacity) => e => {
  if (_.isNil(timezone)) {
    console.debug(`Variable 'timezone' is null or undefined in soeContentFormatter()`)
  }
  const timestamp = e.entries[0].dataPoint.x
  const localTime = moment.unix(timestamp).tz(timezone)
  const tradingDateTime =
    localTime.hours() * MINUTES_IN_HOUR + localTime.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(localTime).subtract(1, 'days')
      : localTime
  const formattedLocalTime = tradingDateTime.format(dateTimeFormatWithSeconds)
  const titleText = `<h3 style="color: white; margin: 0;">${formattedLocalTime}</h3>`
  const dayOfWeek = localTime.format(dayOfWeekFormat)
  const subtitleText = `<div style='color: white; margin-bottom: 5px'>${dayOfWeek}</div>`
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  const seriesText = visibleSeries.reduce((output, entry) => {
    return output + getSOEToolTipLine(entry, entry.dataSeries.name, entry.dataPoint.y, capacity)
  }, '')
  return titleText + subtitleText + seriesText
}

const getSOEToolTipLine = (entry, name, value, capacity) => {
  let ratio = 0
  if (_.isNumber(value)) {
    ratio = capacity ? Math.round((convertMWToKW(value) * 100) / capacity) : 0
  }
  return _.isNil(value)
    ? ''
    : `<div><span style='color: rgba(255,255,255,0.5)'>${name}:</span> <span style='color: ${
        entry.dataSeries.color
      };'>${entry.dataSeries.axisY.options.formatValue(value)} (${ratio}%)</span></div>`
}

const yAxisBreakpoints = _.sortBy(
  [
    {
      maximum: 200,
      tickInterval: 30,
    },
    {
      maximum: 300,
      tickInterval: 40,
    },
    ...defaultYAxisBreakpoints,
  ],
  'maximum',
)
