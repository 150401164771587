import { combineReducers } from 'redux'

const shotclock = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  shotclock,
})
