import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { Box } from '@material-ui/core'
import { useNotifier } from '@fluence/core'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { endOfIntervalSelector } from '../../redux/features/setting'
import { regThroughputOperations, regThroughputSelectors } from '../../redux/features/regulation-throughput'
import { getProductIdByGivenNameType } from '../../utility/asset-utils'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { PRODUCT_NAMES, PRODUCT_TYPES } from '../../utility/constants'
import { percentageToDecimal, decimalToPercentage } from '../../utility/utility'

import UpcomingChangesCard from './UpcomingChangesCard'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'LowerRegCard'

function LowerRegCard(props) {
  // NOTE: endOfInterval triggers re-render when interval changes. We want that.
  const { endOfInterval } = props
  const { asset, permissions } = props
  const {
    dispatchCreateRegThroughput,
    dispatchDeleteAssetProductData,
    dispatchGetAssetProductDataRange,
    dispatchGetLatestAssetProductData,
  } = props
  const { notifySuccess, notifyError } = useNotifier()
  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const products = _.get(asset, 'products')
  const lowerRegGenProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.GEN, products)
  const lowerRegLoadProductId = getProductIdByGivenNameType(PRODUCT_NAMES.LOWERREG, PRODUCT_TYPES.LOAD, products)

  const descriptionText = (
    <>
      <Box mb={1}>
        LOWERREG Utilisation represents the anticipated percentage of enabled LOWERREG MW that AEMO will utilise in each
        interval. Mosaic uses this input during optimisation to estimate the projected SOC changes in each interval
        where LOWERREG participation is planned, in conjunction with any ENERGY and RAISEREG participation.
      </Box>
      <Box>
        NOTE that actual utilisation will vary throughout each dispatch interval based on actual system frequency.
      </Box>
    </>
  )
  const assetId = _.get(asset, 'assetId')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)

  const lowerRegGenThroughputLatest = useSelector(
    regThroughputSelectors.selectLatestRegulationThroughput(assetId, lowerRegGenProductId),
  )
  const lowerRegLoadThroughputLatest = useSelector(
    regThroughputSelectors.selectLatestRegulationThroughput(assetId, lowerRegLoadProductId),
  )
  const lowerRegThroughputRange = useSelector(
    regThroughputSelectors.selectRegulationThroughputSchedule(assetId, lowerRegGenProductId, lowerRegLoadProductId),
  )

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)

  useEffect(() => {
    const currentDatetime = moment().tz(mtz)
    if (!_.isNil(assetId)) {
      dispatchGetLatestAssetProductData(assetId, lowerRegGenProductId, currentDatetime)
      dispatchGetLatestAssetProductData(assetId, lowerRegLoadProductId, currentDatetime)
      dispatchGetAssetProductDataRange(assetId, lowerRegGenProductId, currentDatetime)
      dispatchGetAssetProductDataRange(assetId, lowerRegLoadProductId, currentDatetime)
    }
  }, [
    assetId,
    dispatchGetAssetProductDataRange,
    dispatchGetLatestAssetProductData,
    endOfInterval,
    mtz,
    lowerRegGenProductId,
    lowerRegLoadProductId,
  ])

  const handleSubmit = request => {
    const { startTime, newInputValues } = request
    const startTimestamp = moment(startTime).valueOf()
    const genRequest = dispatchCreateRegThroughput(
      assetId,
      lowerRegGenProductId,
      startTimestamp,
      percentageToDecimal(newInputValues[0], 4),
    )
    const loadRequest = dispatchCreateRegThroughput(
      assetId,
      lowerRegLoadProductId,
      startTimestamp,
      percentageToDecimal(newInputValues[1], 4),
    )

    return Promise.all([genRequest, loadRequest])
      .then(responses => {
        if (responses.some(response => response.error)) {
          notifyError('Unable to schedule Regulation Lower Utilisation')
        } else {
          const currentDatetime = moment().tz(mtz)
          const getLatestGenReq = dispatchGetLatestAssetProductData(assetId, lowerRegGenProductId, currentDatetime)
          const getLatestLoadReq = dispatchGetLatestAssetProductData(assetId, lowerRegLoadProductId, currentDatetime)
          const getGenRangeReq = dispatchGetAssetProductDataRange(assetId, lowerRegGenProductId, currentDatetime)
          const getLoadRangeReq = dispatchGetAssetProductDataRange(assetId, lowerRegLoadProductId, currentDatetime)
          return Promise.all([getLatestGenReq, getLatestLoadReq, getGenRangeReq, getLoadRangeReq])
            .then(res => {
              notifySuccess('Regulation Lower Utilisation adjustment scheduled successfully')
            })
            .catch(e => {
              notifyError('Unable to retrieve new scheduled Regulation Lower Utilisation adjustment from server')
            })
        }
      })
      .catch(e => {
        notifyError('Something went wrong. Please try again')
      })
  }

  const handleDelete = async meta => {
    try {
      const qs = []
      meta.assetProductDataIds.forEach(assetProductDataId => {
        if (_.isNumber(assetProductDataId)) {
          qs.push(dispatchDeleteAssetProductData(assetProductDataId))
        }
      })
      await Promise.all(qs)
      try {
        const currentDatetime = moment().tz(mtz)
        const getLatestGenReq = dispatchGetLatestAssetProductData(assetId, lowerRegGenProductId, currentDatetime)
        const getLatestLoadReq = dispatchGetLatestAssetProductData(assetId, lowerRegLoadProductId, currentDatetime)
        const getGenRangeReq = dispatchGetAssetProductDataRange(assetId, lowerRegGenProductId, currentDatetime)
        const getLoadRangeReq = dispatchGetAssetProductDataRange(assetId, lowerRegLoadProductId, currentDatetime)
        await Promise.all([getLatestGenReq, getLatestLoadReq, getGenRangeReq, getLoadRangeReq])
      } catch (error) {
        notifyError('Unable to retrieve latest Regulation Lower schedule from server')
      }
    } catch (e) {
      notifyError('Unable to delete Regulation Lower Utilization')
    }
  }

  const inputConfigs = [
    {
      label: 'GEN',
      current: decimalToPercentage(lowerRegGenThroughputLatest.value, 2),
      disableImplied: true,
      inputAdornment: '',
      endAdornment: '%',
      allowNegativeInput: false,
    },
    {
      label: 'LOAD',
      current: decimalToPercentage(lowerRegLoadThroughputLatest.value, 2),
      disableImplied: true,
      inputAdornment: '',
      endAdornment: '%',
      allowNegativeInput: false,
    },
  ]

  const activeItem = {
    dotTitle: 'LOWERREG Utilisation',
    title: 'ACTIVE SINCE',
    time: null,
    showDot: false,
    value: [
      {
        label: 'GEN',
        time: moment(lowerRegGenThroughputLatest.startTime),
        value: numeral(lowerRegGenThroughputLatest.value).format('0[.]00%'),
      },
      {
        label: 'LOAD',
        time: moment(lowerRegLoadThroughputLatest.startTime),
        value: numeral(lowerRegLoadThroughputLatest.value).format('0[.]00%'),
      },
    ],
  }

  const items = lowerRegThroughputRange.map(item => {
    const values = item.value.map(value => numeral(value).format('0[.]00%'))
    return {
      deleteTitle: 'Remove scheduled change',
      id: moment(item.startTime).valueOf(),
      onDelete: handleDelete,
      meta: {
        assetProductDataIds: item.assetProductDataIds,
      },
      showDelete: !!canAdjustTradeSettings,
      time: moment(item.startTime),
      value: values,
      valueLabels: [_.isNil(values[0]) ? '' : 'GEN', _.isNil(values[1]) ? '' : 'LOAD'],
    }
  })

  return (
    <>
      <UpcomingChangesCard
        activeItem={activeItem}
        onActionClick={() => setIsSchedulingModalOpen(prev => !prev)}
        hideAction={false}
        items={items}
        marketStartHour={marketStartHour}
        timezone={mtz}
        title="LOWERREG Utilisation"
        titleTypographyProps={{ variant: 'h3' }}
      />
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleSubmit}
        title="Schedule Regulation Lower Utilisation adjustment"
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        description={descriptionText}
        precision={2}
        inputDecimalLimit={2}
        getMarketPriceCap={() => {
          return 100
        }}
      />
    </>
  )
}

LowerRegCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    return {
      endOfInterval: endOfIntervalSelector(state),
      permissions: currentUserPermissionsSelector(state),
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    dispatchGetLatestAssetProductData: async (assetId, productId, startTime) =>
      dispatch(regThroughputOperations.getRegulationThroughputLatest(assetId, productId, startTime)),
    dispatchGetAssetProductDataRange: async (assetId, productId, startTime) =>
      dispatch(regThroughputOperations.getRegulationThroughputRange(assetId, productId, startTime)),
    dispatchCreateRegThroughput: async (assetId, productId, startTime, value) =>
      dispatch(regThroughputOperations.createRegulationThroughput(assetId, productId, startTime, value)),
    dispatchDeleteAssetProductData: async assetProductDataId =>
      dispatch(regThroughputOperations.deleteRegulationThroughput(assetProductDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(LowerRegCard)
