import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles/index'
import { TableCell, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getFormattedPriceBands } from '../../utility/utility'

const DISPLAY_NAME = 'PriceBandSecondHeader'

const useStyles = makeStyles(
  theme => ({
    root: {},
    noBorder: { border: 0 },
    cell: {
      color: _.get(theme, 'custom.palette.colors.WHITE', '#FFFFFF'),
    },
  }),
  { name: DISPLAY_NAME },
)

export const PriceBandSecondHeader = ({
  className,
  maxAvailLabel = 'Max',
  maxAvailToolTip = '',
  noBorder = false,
  priceBandNames = [],
  priceBandValues = {},
  showAutoCalc = false,
}) => {
  const classes = useStyles()
  const formattedValues = getFormattedPriceBands(
    priceBandNames.map(name => _.get(priceBandValues, name)),
    false,
  )
  const cellClassNames = clsx(classes.cell, { [className]: className, [classes.noBorder]: noBorder })
  return (
    <>
      {priceBandNames.map((name, index) => (
        <TableCell className={cellClassNames} key={name}>
          {formattedValues[index]}
        </TableCell>
      ))}
      <Tooltip title={maxAvailToolTip} placement="top">
        <TableCell className={cellClassNames} key="avail">
          {maxAvailLabel}
        </TableCell>
      </Tooltip>
      {showAutoCalc && (
        <TableCell className={cellClassNames} key="autoCalc">
          Auto-Calc
        </TableCell>
      )}
    </>
  )
}

PriceBandSecondHeader.propTypes = {
  className: PropTypes.string,
  maxAvailLabel: PropTypes.string,
  maxAvailToolTip: PropTypes.string,
  noBorder: PropTypes.bool,
  priceBandNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  priceBandValues: PropTypes.object.isRequired,
}

export default PriceBandSecondHeader
