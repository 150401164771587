import _ from 'lodash'
import moment from 'moment-timezone'
import { dateFormat, timeFormat } from './utility'

const breakpoints = [{
  maximumDuration: moment.duration(2, 'hour').asSeconds(),
  tickInterval: moment.duration(5, 'minute').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(6, 'hour').asSeconds(),
  tickInterval: moment.duration(15, 'minute').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(12, 'hour').asSeconds(),
  tickInterval: moment.duration(30, 'minute').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(1, 'day').asSeconds(),
  tickInterval: moment.duration(1, 'hour').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(2, 'day').asSeconds(),
  tickInterval: moment.duration(2, 'hour').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(4, 'day').asSeconds(),
  tickInterval: moment.duration(4, 'hour').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(timeFormat)
}, {
  maximumDuration: moment.duration(8, 'day').asSeconds(),
  tickInterval: moment.duration(12, 'hour').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format("D/M HH:mm")
}, {
  maximumDuration: moment.duration(1, 'month').asSeconds(),
  tickInterval: moment.duration(1, 'day').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format("D/M")
}, {
  maximumDuration: moment.duration(3, 'month').asSeconds(),
  tickInterval: moment.duration(7, 'day').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format(dateFormat)
}, {
  maximumDuration: moment.duration(1, 'year').asSeconds(),
  tickInterval: moment.duration(1, 'month').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format("MMM/YY")
}, {
  maximumDuration: moment.duration(3, 'year').asSeconds(),
  tickInterval: moment.duration(3, 'month').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format("MMM YYYY")
}, {
  maximumDuration: Number.MAX_SAFE_INTEGER,
  tickInterval: moment.duration(1, 'year').asSeconds(),
  labelFormatter: (e, timezone) => moment.unix(e.value).tz(timezone).format("YYYY")
},]

export const getTickInterval = (maximum) => {
  if (_.isNil(maximum)) {
    return null
  }
  if (maximum < 10000000) {
    return (yBreakpoints.find(breakpoint => breakpoint.maximum >= maximum)).tickInterval
  }
  return Number.parseInt(maximum / 5)
}

export const yBreakpoints = [{
  maximum: 5,
  tickInterval: 1,
}, {
  maximum: 10,
  tickInterval: 2,
}, {
  maximum: 50,
  tickInterval: 10,
}, {
  maximum: 100,
  tickInterval: 20,
}, {
  maximum: 500,
  tickInterval: 100,
}, {
  maximum: 1000,
  tickInterval: 200,
}, {
  maximum: 2500,
  tickInterval: 500,
}, {
  maximum: 5000,
  tickInterval: 1000,
}, {
  maximum: 10000,
  tickInterval: 2000,
}, {
  maximum: 25000,
  tickInterval: 5000,
}, {
  maximum: 50000,
  tickInterval: 10000,
}, {
  maximum: 100000,
  tickInterval: 20000,
}, {
  maximum: 500000,
  tickInterval: 100000,
}, {
  maximum: 1000000,
  tickInterval: 200000,
}, {
  maximum: 2500000,
  tickInterval: 500000,
}, {
  maximum: 5000000,
  tickInterval: 1000000,
}, {
  maximum: 10000000,
  tickInterval: 2000000,
}]

export class SyncHandler {

  constructor(onZoom) {
    this.graphs = []
    this.onZoom = []
    if (onZoom) {
      this.addOnZoom(onZoom)
    }
  }

  addGraph(graph) {
    this.graphs.push(graph)
  }

  removeGraph(graph) {
    const index = this.graphs.indexOf(graph)
    this.graphs.splice(index, 1)
  }

  addOnZoom(onZoom) {
    this.onZoom.push(onZoom)
  }

  clearOnZoom() {
    this.onZoom = []
  }

  zoomHandler() {
    return (e) => {
      const sourceGraph = e.chart
      const xMin = sourceGraph.axisX[0].viewportMinimum
      const xMax = sourceGraph.axisX[0].viewportMaximum
      const yAxisViewportMinimum = sourceGraph.axisY[0].viewportMinimum
      const yAxisViewportMaximum = sourceGraph.axisY[0].viewportMaximum
      const wasXYZoom = sourceGraph.zoomType === "xy"
      this.onZoom.forEach(onZoomHandler => onZoomHandler(xMin, xMax, yAxisViewportMinimum, yAxisViewportMaximum, wasXYZoom, true))
    }
  }

  getViewportBreakpoint(xMin, xMax) {
    const viewportDuration = xMax - xMin
    return _.find(breakpoints, (breakpoint) => viewportDuration <= breakpoint.maximumDuration)
  }

  toggleSeriesLineType(oldType, newType) {
    this.graphs.forEach(graph => {
      graph.toggleSeriesLineType(oldType, newType)
      graph.refresh()
    })
  }
}
