import React, { useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import { Button } from '@fluence/core'
import colors from '../config/colors'
import Card from './Card'
import AgreementText from './AgreementText.js'

const DISPLAY_NAME = 'AcceptTCModal'

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: 32,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    card: {
      maxWidth: 856,
      maxHeight: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
      outline: 'none',
    },

    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },

    textBody: {
      overflow: 'auto',
      padding: theme.spacing(2, 0),
      paddingTop: 0,
      whiteSpace: 'pre-wrap',
    },

    formControl: {
      marginTop: 30,
      marginBottom: 30,
    },

    footer: {
      width: 856,
      borderTop: `2px solid ${colors.GUNMETAL}`,
      overflow: 'hidden',
      padding: 16,
      flex: '1 0 auto',
      background: colors.CINDER,
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'right',
    },

    continue: {
      float: 'right',
      width: 112,
      height: 32,
    },

    close: {
      float: 'right',
    },

    policy: {
      marginRight: 16,
      background: colors.CINDER,
      border: 'none',
    },

    link: {
      color: colors.CORNFLOWER_BLUE,
      textDecoration: 'none',
    },
  }),
  { name: DISPLAY_NAME },
)

const AcceptTCModal = React.forwardRef(function AcceptTCModal(props, ref) {
  const [isAgreed, setIsAgreed] = useState(false)
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const { action, requiresAgreement, closeModal } = props
  const classes = useStyles()
  const decision =
    'I acknowledge that I have read and understand the AMS Platform Terms of Use and agree to be bound by the terms and conditions contained therein.'
  const check = event => {
    const checked = _.get(event, 'target.checked')
    setIsAgreed(checked)
  }
  const text = <AgreementText />

  const onSubmitAgreement = () => {
    setSubmitInProgress(true)
    action()
  }

  return (
    <Box className={classes.root} tabIndex={-1}>
      <Card
        ref={ref}
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        inProgress={submitInProgress}
        title="AMS Platform Terms of Use"
        titleTypographyProps={{ variant: 'h3' }}
      >
        <>
          <div data-testid="atcm-text-body" className={classes.textBody}>
            <div className={classes.text}>{text}</div>
            {requiresAgreement && (
              <Box>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="atcm-agree-cbx"
                        className={classes.check}
                        onChange={check}
                        value="agree"
                        color="primary"
                      />
                    }
                    label={decision}
                  />
                </FormControl>
              </Box>
            )}
          </div>
        </>
      </Card>
      <div className={classes.footer}>
        <div>
          <Button data-testid="atcm-privacy-policy-btn" className={classes.policy}>
            <a
              className={classes.link}
              href="https://fluenceenergy.com/website-privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              OPEN PRIVACY POLICY
            </a>
          </Button>
          {requiresAgreement && (
            <Button
              data-testid="atcm-continue-btn"
              disabled={!isAgreed || submitInProgress}
              className={classes.continue}
              onClick={onSubmitAgreement}
              tabIndex="0"
            >
              CONTINUE
            </Button>
          )}
          {!requiresAgreement && (
            <Button
              type="submit"
              data-testid="atcm-close-btn"
              className={classes.close}
              onClick={closeModal}
              tabIndex="0"
            >
              CLOSE
            </Button>
          )}
        </div>
      </div>
    </Box>
  )
})

AcceptTCModal.displayName = DISPLAY_NAME

export default AcceptTCModal
