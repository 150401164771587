import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles/index'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
  },
  listItem: {
    padding: '0 16px',
  },
  listItemIcon: {
    minWidth: 24,
    marginRight: theme.spacing(1),
  },
  listItemText: {
    color: theme.palette.text.primary,
    margin: 0,
  },
  primaryText: {
    lineHeight: '1rem',
  },
  secondaryText: {
    textTransform: 'uppercase',
    color: theme.palette.text.tertiary,
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
}))

function DetailItem(props) {
  const { icon, primaryText, secondaryText } = props
  const classes = useStyles()
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {icon && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          className={classes.listItemText}
          classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
        />
      </ListItem>
    </List>
  )
}

DetailItem.propTypes = {
  icon: PropTypes.any,
  primaryText: PropTypes.any,
  secondaryText: PropTypes.any,
}

export default DetailItem
