import _ from 'lodash'
import React from 'react'
import { formatMapData, perKWToPerMW } from '../../../utility/utility'

export default function RegionPopup(props) {
  const { region, market } = props
  const mapProducts = market.data.map_product_order
  const prices = region.data.prices

  return (
    <div data-testid="qa-map-region" className={'region-marker'}>
      <section className="panel panel-primary mb-none">
        <header className="panel-heading">
          <h6 className="panel-title">
            {region.data.display_name}
            <span className="price">{formatMapData(prices[0], perKWToPerMW, 10)}</span>
          </h6>
        </header>
        <div className="panel-body">
          <table className="table table-condensed mb-none">
            <thead>
              <tr>
                <th />
                <th>{mapProducts[0]}</th>
                { !_.isNil(mapProducts[4]) && (
                  <th>{mapProducts[4]}</th>
                )}
                <th>{mapProducts[1]}</th>
                <th>{mapProducts[2]}</th>
                <th>{mapProducts[3]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>↑</td>
                <td>{formatMapData(prices[1], perKWToPerMW)}</td>
                { !_.isNil(mapProducts[4]) && !_.isNil(prices[9]) && (
                  <td>{formatMapData(prices[9], perKWToPerMW)}</td>
                )}
                <td>{formatMapData(prices[2], perKWToPerMW)}</td>
                <td>{formatMapData(prices[3], perKWToPerMW)}</td>
                <td>{formatMapData(prices[4], perKWToPerMW)}</td>
              </tr>
              <tr>
                <td>↓</td>
                <td>{formatMapData(prices[5], perKWToPerMW)}</td>
                { !_.isNil(mapProducts[4]) && !_.isNil(prices[10]) && (
                  <td>{formatMapData(prices[10], perKWToPerMW)}</td>
                )}
                <td>{formatMapData(prices[6], perKWToPerMW)}</td>
                <td>{formatMapData(prices[7], perKWToPerMW)}</td>
                <td>{formatMapData(prices[8], perKWToPerMW)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  )
}
