import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { LoginPage, LoginTempCredentials, NotifierProvider, SessionCountdown } from '@fluence/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import ROUTES from './config/routes'
import auth from './utility/auth'
import { LOGIN_REDIRECT_ENABLED } from './utility/constants'
import { apiRoot, SWRGlobalConfig } from './redux/api.js'
import createTheme from './theme/theme'
import GlobalCss from './theme/GlobalCss'
import App from './App'
import ErrorNotFound from './components/error/ErrorNotFound'
import Asset from './pages/Asset'
import Dashboard from './pages/Dashboard'
import Preferences from './pages/Preferences'
import UserManagement from './pages/UserManagement'
import { RouteLeavingGuardProvider } from './components/RouteLeavingGuard'
import CreateUser from './pages/CreateUser'
import EditUser from './pages/EditUser'

const theme = createTheme()
const { login: handleLogin, mfaResponse: handleMfaResponse, forgotPassword: handleForgotPassword } = auth
const handleTempSetToken = token => auth.setToken(token, { id: 'temp' }, moment().add(1, 'days').valueOf())
// TODO: Create endpoint to specifically check the validity of a token.
const handleVerifyToken = token =>
  fetch(`${apiRoot}/market/assets`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
    },
  })
const NEM_APP_TITLE = 'NEM Bidding Application'

const Routes = () => (
  <BrowserRouter basename={ROUTES.BASENAME}>
    <MuiThemeProvider theme={theme}>
      <SWRGlobalConfig>
        <NotifierProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <GlobalCss />
            <RouteLeavingGuardProvider>
              <Switch>
                <Route path={ROUTES.LOGIN}>
                  <LoginPage
                    headerProps={{ title: NEM_APP_TITLE }}
                    onLogin={handleLogin}
                    onMfa={handleMfaResponse}
                    onPasswordReset={handleForgotPassword}
                    showLoginRedirect={LOGIN_REDIRECT_ENABLED}
                    supportEmail="support.nem@fluenceenergy.com"
                  />
                </Route>
                <Route path={ROUTES.TEMP_CREDENTIALS}>
                  <LoginTempCredentials
                    headerProps={{ title: NEM_APP_TITLE }}
                    redirectPath={ROUTES.DASHBOARD}
                    loginPagePath={ROUTES.LOGIN}
                    onVerify={handleTempSetToken}
                    verifyAsyncFn={handleVerifyToken}
                  />
                </Route>
                <PrivateRoute path={ROUTES.DASHBOARD} exact component={Dashboard} />
                <PrivateRoute path={ROUTES.PREFERENCES} exact component={Preferences} />
                <PrivateRoute path={ROUTES.USER_MANAGEMENT} exact component={UserManagement} />
                <PrivateRoute path={ROUTES.CREATE_USER} exact component={CreateUser} />
                <PrivateRoute path={ROUTES.EDIT_USER} exact component={EditUser} />
                <PrivateRoute path={ROUTES.ASSET.path} component={Asset} />
                <PrivateRoute path="*" component={ErrorNotFound} />
              </Switch>
            </RouteLeavingGuardProvider>
          </MuiPickersUtilsProvider>
        </NotifierProvider>
      </SWRGlobalConfig>
    </MuiThemeProvider>
  </BrowserRouter>
)

export default Routes

function PrivateRoute({ component: Component, ...rest }) {
  const exp = auth.getTokenExpiration()
  const handleDeleteToken = auth.deleteAuthToken
  return (
    <Route
      {...rest}
      render={props =>
        auth.isLoggedIn() ? (
          <SessionCountdown
            tokenExpiration={exp}
            location={props.location}
            loginUrl={ROUTES.LOGIN}
            onClearSession={handleDeleteToken}
          >
            <App>
              <Component {...props} {...rest} />
            </App>
          </SessionCountdown>
        ) : (
          <Redirect push to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
}
