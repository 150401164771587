import React from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import { getInputTextFieldProps } from './ScheduledChangesInputUtil'
import EditableTable from './EditableTable'

const DISPLAY_NAME = 'DenseScheduledChangesInput'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      overflow: 'auto',
      paddingRight: theme.spacing(2),
    },
    inputContainers: {
      flex: '0 0 264px',
    },
    secondInputContainer: {
      display: 'flex',
      flex: '1 0 min-content',
      justifyContent: 'center',
    },
    flexContainer: {
      justifyContent: 'flexStart',
    },
    editTableContainer: {
      paddingBottom: 16,
    },
  }),
  { name: DISPLAY_NAME },
)

const DenseScheduledChangesInput: React.FC<DenseScheduledChangesProps> = props => {
  const {
    denseTableConfig,
    handleChange,
    inputConfigs = [],
    checkValidInputCrossValidation,
    inputDecimalLimit = 8,
    isEndDateEnabled = false,
    isReturnInput = false,
    touched,
    values,
  } = props
  const classes = useStyles()
  const { tableGroupKey, tableName } = denseTableConfig
  const tables = _.groupBy(inputConfigs, tableGroupKey)
  const getInputCellProps = (inputConfig: InputConfig) => {
    const { label, endTextFieldLabel } = inputConfig
    const labelToUse = isReturnInput ? endTextFieldLabel : label
    return {
      inputValues: values,
      ...getInputTextFieldProps(
        values,
        inputConfig,
        touched,
        labelToUse,
        inputDecimalLimit,
        checkValidInputCrossValidation,
      ),
    }
  }
  const disableInputTextFields = !isEndDateEnabled && isReturnInput
  const currentTableLabel = `CURRENT ${tableName}`
  const editableTableLabel = isReturnInput ? `RETURN ${tableName}` : `NEW ${tableName}`
  return (
    <Box className={classes.root}>
      {!isReturnInput && (
        <Box className={classes.inputContainers} mr={2}>
          <Typography>{currentTableLabel}</Typography>
          {_.keys(tables).map(key => (
            <div key={key} className={classes.editTableContainer}>
              <EditableTable
                denseTableConfig={denseTableConfig}
                disabled
                getInputCellProps={getInputCellProps}
                handleTextFieldChange={() => {}}
                key={key}
                submitSettings={_.get(tables, key)}
              />
            </div>
          ))}
        </Box>
      )}
      <Box display="flex" className={clsx({ [classes.secondInputContainer]: !isReturnInput })}>
        <Box flexBasis={264}>
          <Typography>{editableTableLabel}</Typography>
          {_.keys(tables).map(key => (
            <div key={key} className={classes.editTableContainer}>
              <EditableTable
                denseTableConfig={denseTableConfig}
                disabled={disableInputTextFields}
                getInputCellProps={getInputCellProps}
                handleTextFieldChange={handleChange}
                key={key}
                submitSettings={_.get(tables, key)}
              />
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

interface InputConfig {
  allowSubmitEmptyInput: boolean
  current: number
  disableImplied: boolean
  emptyInputHelperText: string
  endAdornment: string
  endTextFieldLabel: string
  inputAdornment: string
  label: string
  override: boolean
  overrideLabel: string
  overrideValue: number
}

interface DenseScheduledChangesProps {
  denseTableConfig: DenseTableConfig
  handleChange: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void
  inputConfigs: Array<InputConfig>
  checkValidInputCrossValidation: Function
  inputDecimalLimit: number
  isEndDateEnabled: boolean
  isReturnInput: boolean
  touched: object
  values: object
}

interface DenseTableConfig {
  tableGroupKey: string
  tableName: string
  rowGroupKey: string
  columnGroupKey: string
  rowSortBy: Function
  columnSortBy: Function
}

DenseScheduledChangesInput.displayName = DISPLAY_NAME

export default DenseScheduledChangesInput
