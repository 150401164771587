import React, { Component } from 'react'
import _ from 'lodash'
import StackedGraph from './StackedGraph'

export default class StackedGraphWrapper extends Component {

  constructor(props) {
    super(props)
    const graphData = this.getGraphData(props.data)
    this.state = {
      graphs: graphData.graphs,
      legendMetaData: graphData.legendMetaData || { series: [] }
    }
  }

  render() {
    const { graphs, legendMetaData } = this.state
    const { isLoading, syncHandler, viewPort, data, intervention, timezone } = this.props

    return <StackedGraph
      isLoading={isLoading}
      graphs={graphs}
      titles={data.titles || []}
      legendMetaData={legendMetaData}
      viewPort={viewPort}
      syncHandler={syncHandler}
      toggleSeries={_.bind(this.toggleSeries, this)}
      intervention={intervention}
      timezone={timezone}
    />
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && !_.isEqual(nextProps.data, this.props.data)) {
      const graphData = this.getGraphData(nextProps.data)
      this.setState({
        graphs: graphData.graphs,
        legendMetaData: graphData.legendMetaData || { series: [] }
      })
    }
  }

  toggleSeries(e, seriesName, metaDataList, legendMetaData, rawGraphs, updateMetaData) {
    const metaDataListCopy = _.cloneDeep(metaDataList)
    const legendMetaDataCopy = _.cloneDeep(legendMetaData) || { series: [] }

    metaDataListCopy.forEach((metaData, index) => {
      const series = metaData.series.filter(series => series.name === seriesName)[0]
      if (series) {
        series.visible = !series.visible
        rawGraphs[index].toggleSeries(seriesName)
        rawGraphs[index].refresh()
      }
    })

    const legendSeries = legendMetaDataCopy.series.filter(series => series.name === seriesName)[0]
    if (legendSeries) {
      legendSeries.visible = !legendSeries.visible
    }

    updateMetaData(metaDataListCopy, legendMetaDataCopy)
  }
}