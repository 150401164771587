import React from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Box } from '@material-ui/core'
import Toggle from '../components/Toggle'
import Strategy from '../components/trade-settings/Strategy'
import Registrations from '../components/trade-settings/Registrations'
import Overrides from '../components/trade-settings/Overrides'

const TradeSettings = React.memo(props => {
  const { asset } = props

  const { path, url } = useRouteMatch()

  const matchRegistrations = useRouteMatch({
    path: `${path}/registrations`,
  })
  const matchStrategy = useRouteMatch({
    path: `${path}/strategy`,
  })

  const options = [
    {
      text: 'Overrides',
      key: 'overrides',
      toolTip: ``,
      component: Link,
      to: url,
    },
    {
      text: 'Strategy',
      key: 'strategy',
      toolTip: `Strategic settings`,
      component: Link,
      to: `${url}/strategy`,
    },
    {
      text: 'Registrations',
      key: 'registrations',
      toolTip: `Registration settings`,
      component: Link,
      to: `${url}/registrations`,
    },
  ]

  const getSelected = () => {
    if (matchRegistrations) {
      return 'registrations'
    } else if (matchStrategy) {
      return 'strategy'
    }
    return 'overrides'
  }

  const selectedView = getSelected()
  return (
    <>
      <Box mb={2}>
        <Toggle values={options} selected={selectedView} />
      </Box>
      <Switch>
        <Route exact path={path}>
          <Overrides asset={asset} />
        </Route>
        <Route path={`${path}/strategy`}>
          <Strategy asset={asset} />
        </Route>
        <Route path={`${path}/registrations`}>
          <Registrations asset={asset} />
        </Route>
      </Switch>
    </>
  )
})

export default TradeSettings
