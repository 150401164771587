import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Day as MuiDay, KeyboardDatePicker as MuiKeyboardDatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { dateFormat } from '../utility/utility'
import { DATE_FORMAT_DAY } from '../utility/constants'

const DISPLAY_NAME = 'KeyboardDatePicker'

const useStyles = makeStyles(
  theme => ({
    root: {},
    datePicker: {
      maxWidth: theme.spacing(21),
      marginTop: 0,
      marginBottom: theme.spacing(1.5),
    },
    pickerInput: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
    pickerInputComp: {
      maxHeight: theme.spacing(4),
      fontWeight: 600,
    },
    error: {
      position: 'absolute',
      fontSize: 12,
      marginTop: -5,
    },
    dayWithDotContainer: {
      position: 'relative',
    },
    dayWithDot: {
      position: 'absolute',
      height: 0,
      width: 0,
      border: '2px solid',
      borderRadius: 4,
      borderColor: theme.palette.secondary.main,
      right: '50%',
      transform: 'translateX(1px)',
      top: '80%',
    },
  }),
  { name: DISPLAY_NAME },
)

const KeyboardDatePicker = props => {
  const {
    className: classNameProp,
    id = '',
    label = '',
    inputVariant = 'filled',
    ariaLabel,
    timezone = 'Etc/GMT-10',
    marketStartHour,
    selectedDate,
    onChange,
    renderDayProps,
    minDate,
    maxDate,
    minDateMessage = 'Cannot select past date',
    maxDateMessage = 'Cannot select future date',
    classes: classesProp,
    ...rest
  } = props
  const classes = useStyles(props)

  const startOfDay = moment().tz(timezone).startOf('day').add(marketStartHour, 'h')
  const currentStartDay = moment().tz(timezone).isSameOrAfter(startOfDay) ? startOfDay : startOfDay.subtract(1, 'd')
  const [dateError, setDateError] = useState()
  const [lastValidDate, setLastValidDate] = useState(moment(selectedDate).isValid() && selectedDate)
  const [lastDateString, setLastDateString] = useState()

  const selectedDateTimestamp = moment(selectedDate).valueOf()
  useEffect(() => {
    if (moment(selectedDateTimestamp).isValid()) {
      setLastValidDate(moment(selectedDateTimestamp).tz(timezone))
    }
  }, [selectedDateTimestamp, timezone])

  const renderDay = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const { disabled, hidden, selected, children } = dayComponent.props

    const day = moment(date).add(marketStartHour, 'h')
    const currentEndDay = moment(currentStartDay).add(1, 'd')
    const isCurrent = day.isSameOrAfter(currentStartDay) && day.isBefore(currentEndDay)

    if (
      !_.isNil(renderDayProps) &&
      dayInCurrentMonth &&
      renderDayProps.daysWithDots.includes(date.format(DATE_FORMAT_DAY))
    ) {
      return (
        <div className={classes.dayWithDotContainer}>
          <MuiDay disabled={disabled} current={isCurrent} hidden={hidden} selected={selected}>
            {children}
          </MuiDay>
          <div className={classes.dayWithDot} />
        </div>
      )
    }
    return (
      <MuiDay disabled={disabled} current={isCurrent} hidden={hidden} selected={selected}>
        {children}
      </MuiDay>
    )
  }

  const handleChange = (newDate, dateString) => {
    // This wrapper is intended to handle the use case where user is entering date manually and we don't want to lose the start TIME resolution.
    if (_.isFunction(onChange)) {
      if (moment(dateString).isValid() && !moment(lastDateString).isValid()) {
        const targetDate = moment(newDate)
          .hour(lastValidDate.hours())
          .minute(lastValidDate.minutes())
          .second(lastValidDate.seconds())
        onChange(targetDate, dateString)
      } else {
        onChange(newDate, dateString)
      }
      setLastDateString(dateString)
    } else {
      console.info('No onChange handler provided for KeyboardDatePicker?')
    }
  }

  return (
    <MuiKeyboardDatePicker
      id={id}
      className={classNameProp}
      autoOk
      required
      hiddenLabel={!label}
      label={label}
      disableToolbar
      variant="inline"
      inputVariant={inputVariant}
      format={dateFormat}
      value={selectedDate}
      renderDay={renderDay}
      disabled={_.isNil(selectedDate)}
      minDate={minDate}
      maxDate={maxDate}
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
      InputAdornmentProps={{ position: 'start' }}
      classes={{ root: classes.datePicker }}
      onChange={handleChange}
      KeyboardButtonProps={{
        'aria-label': ariaLabel,
      }}
      InputProps={{ classes: { root: classes.pickerInputComp } }}
      inputProps={{ classes: { root: classes.pickerInput } }}
      helperText={
        (!!dateError || _.isNil(selectedDate) || !moment(selectedDate).isValid()) && (
          <span className={classes.error} id="error">
            {dateError}
          </span>
        )
      }
      onError={error => {
        setDateError(error)
      }}
      {...rest}
    />
  )
}

KeyboardDatePicker.displayName = DISPLAY_NAME

KeyboardDatePicker.propTypes = {
  ariaLabel: PropTypes.string,
  handleChangeDatePicker: PropTypes.func,
  id: PropTypes.string,
  inputVariant: PropTypes.string,
  label: PropTypes.string,
  marketStartHour: PropTypes.number,
  maxDate: PropTypes.object,
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  renderDayProps: PropTypes.shape({
    daysWithDots: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedDate: PropTypes.object,
  timezone: PropTypes.string,
}

export default KeyboardDatePicker
