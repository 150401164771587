import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { Button } from '@fluence/core'
import Card from '../Card'
import { isStringEmptyOrSpace } from '../../utility/utility'
import RouteLeavingGuard from '../RouteLeavingGuard'

const timezones = moment.tz.names()

// one liner to create an array with all times of the day in 24 hour format
const timeOptions = _.range(0, 24, 0.5).map(
  x => `${String(Math.trunc(x)).padStart(2, 0)}:${String((x % 1) * 60).padStart(2, 0)}`,
)

const useStyles = makeStyles(theme => ({
  root: {},
  card: {},
  formControl: {
    width: `100%`,
  },
  cardContent: {
    paddingTop: theme.spacing(),
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  changePassword: {
    whiteSpace: 'nowrap',
  },
  menu: {
    width: 200,
  },
  timeField: {
    minWidth: 84,
  },
}))
const initialValuesState = (name, email, phone, timezone, start, end) => ({
  name: name,
  email: email,
  dnd: !!start && !!end,
  password: 'password',
  phone: phone,
  timezone: timezone,
  blackoutStartTime: start,
  blackoutEndTime: end,
})

function GeneralInfo(props) {
  const {
    initialName,
    initialEmail,
    initialBlackoutStartTime,
    initialBlackoutEndTime,
    initialPhone,
    initialTimezone,
    saveUser,
    notifySuccess,
    notifyError,
    getUser,
    setUser,
    onChangePasswordOpen,
  } = props
  const classes = useStyles()

  const [inProgress, setInProgress] = useState(false)
  const [values, setValues] = useState({
    email: '',
    dnd: false,
    name: '',
    password: 'password',
    phone: '',
    timezone: '',
    blackoutStartTime: '',
    blackoutEndTime: '',
  })

  const untouched = {
    email: false,
    dnd: false,
    name: false,
    password: false,
    phone: false,
    timezone: false,
    blackoutStartTime: false,
    blackoutEndTime: false,
  }
  const [touched, setTouched] = useState(untouched)
  const [errors, setErrors] = useState({ name: '', phone: '' })

  const initialValues = initialValuesState(
    initialName,
    initialEmail,
    initialPhone,
    initialTimezone,
    initialBlackoutStartTime,
    initialBlackoutEndTime,
  )

  useEffect(() => {
    setValues(
      initialValuesState(
        initialName,
        initialEmail,
        initialPhone,
        initialTimezone,
        initialBlackoutStartTime,
        initialBlackoutEndTime,
      ),
    )
  }, [initialName, initialEmail, initialBlackoutStartTime, initialBlackoutEndTime, initialPhone, initialTimezone])

  const handleChange = name => event => {
    switch (name) {
      case 'name':
        const nameError = _.isEmpty(event.target.value)
          ? 'Cannot be empty'
          : isStringEmptyOrSpace(event.target.value)
          ? 'Valid name required'
          : ''
        setErrors({ ...errors, [name]: nameError })
        break
      case 'phone':
        const phoneError =
          isStringEmptyOrSpace(event.target.value) && !_.isEmpty(event.target.value)
            ? 'Valid phone number required'
            : ''
        setErrors({ ...errors, [name]: phoneError })
        break
      default:
        break
    }
    const value = _.get(event, 'target.value')
    setValues({ ...values, [name]: value })
  }

  const handleCheckboxChange = name => event => {
    let blackoutStartTime = ''
    if (event.target.checked) {
      if (_.isEmpty(values.blackoutStartTime)) {
        if (_.isEmpty(initialBlackoutStartTime)) {
          blackoutStartTime = '22:00'
        } else {
          blackoutStartTime = initialBlackoutStartTime
        }
      } else {
        blackoutStartTime = values.blackoutStartTime
      }
    } else {
      blackoutStartTime = ''
    }

    let blackoutEndTime = ''
    if (event.target.checked) {
      if (_.isEmpty(values.blackoutEndTime)) {
        if (_.isEmpty(initialBlackoutEndTime)) {
          blackoutEndTime = '07:00'
        } else {
          blackoutEndTime = initialBlackoutEndTime
        }
      } else {
        blackoutEndTime = values.blackoutEndTime
      }
    } else {
      blackoutEndTime = ''
    }

    const checked = _.get(event, 'target.checked')
    setValues({
      ...values,
      [name]: checked,
      blackoutStartTime,
      blackoutEndTime,
    })
  }

  const handleOnBlur = name => event => {
    const value = _.get(event, 'target.value')
    setTouched({ ...touched, [name]: value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setInProgress(true)

    getUser().then(userResponse => {
      if (userResponse.error) {
        notifyError('Save was unsuccessful')
        setInProgress(false)
      } else {
        const newUser = {
          name: values.name,
          email: values.email,
          phoneNumber: values.phone,
          preferences: {
            marketAlerts: {
              timezone: values.timezone,
            },
          },
        }
        const userToSave = _.merge({}, userResponse.payload, newUser)

        // Remove start and end time if enforce do-not-disturb is false
        if (!_.isEmpty(values.blackoutStartTime)) {
          _.set(userToSave, 'preferences.marketAlerts.blackoutStartTime', values.blackoutStartTime)
        } else {
          if (_.has(userToSave, 'preferences.marketAlerts.blackoutStartTime')) {
            delete userToSave.preferences.marketAlerts.blackoutStartTime
          }
        }
        if (!_.isEmpty(values.blackoutEndTime)) {
          _.set(userToSave, 'preferences.marketAlerts.blackoutEndTime', values.blackoutEndTime)
        } else {
          if (_.has(userToSave, 'preferences.marketAlerts.blackoutEndTime')) {
            delete userToSave.preferences.marketAlerts.blackoutEndTime
          }
        }

        saveUser(userToSave).then(saveUserResponse => {
          if (saveUserResponse.error) {
            notifyError('Something went amiss. User was not saved.')
            setInProgress(false)
          } else {
            notifySuccess('Preferences saved')
            setTouched({})
            setInProgress(false)

            // Update Redux with our changes instead of making yet another ajax call to refresh
            setUser(userToSave)
          }
        })
      }
    })
  }

  const isFormValid = () => {
    return (
      Object.keys(errors).reduce((acc, next) => {
        const err = errors[next]
        if (!_.isEmpty(err)) {
          acc.push(err)
        }
        return acc
      }, []).length === 0
    )
  }

  const isTouched = () => Object.values(touched).some(fieldValue => fieldValue === true)
  const isDirty = () => !_.isEqual(initialValues, values)
  const saveDisabled = inProgress || !isFormValid() || (!isTouched() && !isDirty())
  const showDndOptions = !!values.dnd

  return (
    <>
      <RouteLeavingGuard description="General" when={!saveDisabled} />
      <Card
        classes={{ root: classes.card, content: classes.cardContent }}
        title="General"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          <Button variant="text" onClick={handleSubmit} disabled={saveDisabled}>
            save
          </Button>
        }
      >
        <form noValidate autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={7}>
              <TextField
                id="name"
                label="Name"
                className={classes.formControl}
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleOnBlur('name')}
                margin="dense"
                error={!_.isEmpty(errors['name'])}
                helperText={errors['name']}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl className={classes.formControl} margin="dense">
                <InputLabel htmlFor="age-simple">Timezone</InputLabel>
                <Select
                  value={values.timezone}
                  onChange={handleChange('timezone')}
                  onBlur={handleOnBlur('timezone')}
                  inputProps={{
                    name: 'timezone',
                    id: 'timezone',
                  }}
                >
                  {timezones.map(tz => (
                    <MenuItem key={tz} value={tz}>
                      {tz}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                id="email"
                disabled
                label="Email"
                className={classes.formControl}
                value={values.email}
                onChange={handleChange('email')}
                onBlur={handleOnBlur('email')}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="phone"
                label="Phone"
                className={classes.formControl}
                value={values.phone}
                onChange={handleChange('phone')}
                onBlur={handleOnBlur('phone')}
                margin="dense"
                error={!_.isEmpty(errors['phone'])}
                helperText={errors['phone']}
              />
            </Grid>

            <Grid item xs={12} sm={7}>
              <FormControl className={classes.formControl} margin="dense">
                <InputLabel htmlFor="change-password">Password</InputLabel>
                <Input
                  id="password"
                  type="password"
                  value={values.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button
                        className={classes.changePassword}
                        variant="text"
                        style={{ marginBottom: 10 }}
                        onClick={onChangePasswordOpen}
                        aria-label="toggle password visibility"
                      >
                        change password
                      </Button>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box>
                <Tooltip
                  title="Notifications will not be sent during these hours in your specified timezone"
                  placement="top"
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <FormControl className={classes.formControl} margin="dense">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.dnd}
                          onChange={e => {
                            handleCheckboxChange('dnd')(e)
                            handleOnBlur('dnd')(e)
                          }}
                          value="dnd"
                          color="primary"
                        />
                      }
                      label="Enforce do-not-disturb"
                    />
                  </FormControl>
                </Tooltip>
              </Box>
              <Fade in={showDndOptions}>
                <Box>
                  <Box display="inline-block" mr={3}>
                    <TextField
                      id="gi-start-time"
                      select
                      label="START TIME"
                      className={classes.timeField}
                      value={values.blackoutStartTime}
                      onChange={handleChange('blackoutStartTime')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                    >
                      {timeOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  <TextField
                    id="gi-end-time"
                    select
                    label="END TIME"
                    className={classes.timeField}
                    value={values.blackoutEndTime}
                    onChange={handleChange('blackoutEndTime')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                  >
                    {timeOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  )
}

GeneralInfo.displayName = 'GeneralInfo'

export default GeneralInfo
