import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import download from 'downloadjs'
import { Box, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Download as DownloadIcon } from '@fluence/core'
import {
  chartsTag,
  enablementTag,
  marketPriceTag,
  settlementTag,
  KW_TO_MW,
  perKWToPerMW,
  optimizationPriceMadeTag,
  AMSForecastTag,
  formatMarketValue,
  getAssetCapacityText,
  getIndex,
  dateFormat,
  dateFormatNoSeparator,
  dateTimeFormatWithSeconds,
  dateTimeFormatWithSecondsNoSeparator,
  marketsFix,
  timeFormat,
  getCardSubtitleByDate,
} from '../../utility/utility'
import {
  extractActiveProductNamesFromList,
  getActiveProductTimeMapFromAsset,
  isProductNameActive,
} from '../../utility/asset-utils'

import Card from '../Card'
import Toggle from '../Toggle'
import ProductToggle from '../toggles/ProductToggle'
import { getChartData } from '../../redux/features/interval'
import { globalAppSelectors } from '../../redux/features/app'
import ComparisonTable from './ComparisonTable'
import ResultsTable from './ResultTable'

const marketResultsCategoryOptions = [
  {
    text: 'Enablements',
    key: enablementTag,
    toolTip: `Enablements`,
  },
  {
    text: 'Market Prices',
    key: marketPriceTag,
    toolTip: `Market Prices`,
  },
  {
    text: 'Settlements',
    key: settlementTag,
    toolTip: `Settlements`,
  },
  {
    text: 'All',
    key: 'all',
    toolTip: `All`,
  },
]

const DISPLAY_NAME = 'ResultsTableCard'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      // marginTop: theme.spacing(2),
      minHeight: 415,
    },
    tableCardContent: {
      height: 'calc(100vh - 236px)',
      minHeight: 200,
      overflow: 'hidden',
      padding: 0,
    },
    action: {
      paddingRight: theme.spacing(1),
    },
    actionBtn: {
      cursor: 'pointer',
    },
  }),
  { name: DISPLAY_NAME },
)

function ResultsTableCard(props) {
  const classes = useStyles(props)
  const { asset, inProgress, startTime, endTime, lastUpdated, collatedIntervals, getSettlementIntervalByTime } = props

  const assetId = _.get(asset, 'assetId')
  const assetName = _.get(asset, 'name')
  const bidInterval = _.get(asset, 'market.data.bid_interval')
  const settlementInterval = _.get(asset, 'market.data.settlement_interval')
  const productNames = _.get(asset, 'productNames', [])
  const productDisplayNames = _.get(asset, 'market.data.product_display_names', {})
  const settlementIntervalByTime = getSettlementIntervalByTime(startTime)

  const [selectedResultCategory, setSelectedResultCategory] = useState(enablementTag)
  const [selectedProducts, setSelectedProducts] = useState(['ENERGY'])

  const timezone = _.get(asset, 'market.data.timezone')
  moment.tz.setDefault(timezone)
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')

  const marketPriceReduxKey = `${assetId}_${optimizationPriceMadeTag}_${AMSForecastTag}_allProducts`
  const currentTableDataReduxKey =
    selectedResultCategory === marketPriceTag ? marketPriceReduxKey : `${assetId}_${selectedResultCategory}_allProducts`
  const allProductData = getChartData(collatedIntervals, currentTableDataReduxKey, startTime)
  const intervals = _.get(allProductData, 'data', {})

  let comparisonIntervals = []
  if (selectedResultCategory === 'all') {
    const enablementsData = getChartData(collatedIntervals, `${assetId}_${enablementTag}_allProducts`, startTime)
    const settlementsData = getChartData(collatedIntervals, `${assetId}_${settlementTag}_allProducts`, startTime)
    const pricesData = getChartData(collatedIntervals, marketPriceReduxKey, startTime)

    const compareDataLoaded = [enablementsData, settlementsData, pricesData].every(
      i => !_.isNil(i) && i.isLoading === false,
    )
    if (compareDataLoaded) {
      comparisonIntervals = getComparisonIntervals(
        asset,
        startTime,
        enablementsData.data,
        pricesData.data,
        settlementsData.data,
        selectedProducts,
        settlementIntervalByTime,
      )
    }
  }

  const handleSelectedViewOnChange = (e, newSelection) => {
    if (!_.isNil(newSelection)) {
      setSelectedResultCategory(newSelection)
    }
  }

  const handleProductChange = (e, newSelections) => {
    if (!_.isNil(newSelections)) {
      setSelectedProducts(newSelections)
    }
  }
  const handleDownloadClick = category => () => {
    if (category === 'all') {
      downloadComparisonTableIntervals(selectedProducts, asset, startTime, lastUpdated)
    } else {
      downloadResultTableIntervals(category, asset, startTime, lastUpdated)
    }
  }

  const downloadResultTableIntervals = (selectedResultCategory, asset, selectedDate, lastUpdated) => {
    const formatData = getFormatData(selectedResultCategory)
    const endIndex = getIndex(
      moment(selectedDate).add(1, 'd').subtract(bidInterval, 'minutes'),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )
    const forecastStartIndex = getIndex(
      moment(intervals.forecastStartTime),
      selectedDate,
      moment(selectedDate).add(1, 'd'),
      bidInterval,
    )
    const fileName = `${formatData.fileName}_${assetId}_${selectedDate.format(dateFormatNoSeparator)}_${moment()
      .tz(timezone)
      .format(dateTimeFormatWithSecondsNoSeparator)}.csv`
    let output = `Asset: ${assetName}\nSize/Duration: ${
      getAssetCapacityText(asset).both
    }\nTrading Date: ${selectedDate.format(dateFormat)}\n`
    output += `Data Current as Of: ${lastUpdated.format(dateTimeFormatWithSeconds)}\nData Type: ${formatData.title}\n`

    const products = []
    const activeProductNames = extractActiveProductNamesFromList(asset, selectedDate, asset.productNames)
    activeProductNames.forEach(product => {
      if (selectedResultCategory === marketPriceTag || !asset.dualType) {
        products.push(product)
      } else {
        asset.productTypes.forEach(type => products.push(`${product} (${type})`))
      }
    })
    const showTotalsColumn = selectedResultCategory === settlementTag
    if (showTotalsColumn) {
      asset.dualType ? asset.productTypes.forEach(type => products.push(`TOTAL (${type})`)) : products.push('TOTAL')
    }

    output +=
      settlementIntervalByTime === bidInterval
        ? _.concat('Interval', 'Time Window', products).join(',') + '\n'
        : _.concat('Trading Interval', 'Dispatch Interval', 'Time Window', products).join(',') + '\n'

    _.range(0, endIndex + 1).forEach(intervalIndex => {
      const rowInterval = intervalIndex + 1
      const dispatchInterval = (intervalIndex % (settlementInterval / bidInterval)) + 1
      const tradingInterval = parseInt(intervalIndex / 6) + 1
      const timezone = asset.market.data.timezone
      const startTime = moment(selectedDate)
        .add(intervalIndex * bidInterval, 'minutes')
        .tz(timezone)
      const timeWindow = `${startTime.format(timeFormat)} to ${moment(startTime)
        .add(bidInterval, 'minutes')
        .format(timeFormat)}`
      const values = []
      const total = []
      asset.productTypes.forEach((productType, productTypeIndex) => {
        total[productTypeIndex] = 0
      })
      const activeProductTimeMap = getActiveProductTimeMapFromAsset(asset)
      asset.productNames.forEach((productName, productNameIndex) => {
        asset.productTypes.forEach((productType, productTypeIndex) => {
          const isProductNameActiveWithSelectedTime = isProductNameActive(
            selectedDate,
            activeProductTimeMap,
            productName,
            productType,
          )
          // create only row column for price product (gen and load have same price)
          if (
            isProductNameActiveWithSelectedTime &&
            (selectedResultCategory !== marketPriceTag || productTypeIndex === 0)
          ) {
            let value = null
            if (!_.isEmpty(intervals) && (intervalIndex < forecastStartIndex || forecastStartIndex === -1)) {
              const valueIndex =
                selectedResultCategory === marketPriceTag
                  ? productNameIndex
                  : asset.productTypes.length * productNameIndex + productTypeIndex
              value = intervals.values[valueIndex][intervalIndex]
            }
            total[productTypeIndex] += value
            values.push(
              formatMarketValue(value, formatData.factor, marketsFix, true, selectedResultCategory !== enablementTag),
            )
          }
        })
      })
      if (showTotalsColumn) {
        total.forEach(sum => {
          values.push(
            formatMarketValue(
              Math.round(sum),
              formatData.factor,
              marketsFix,
              true,
              selectedResultCategory !== enablementTag,
            ),
          )
        })
      }
      output +=
        settlementIntervalByTime === bidInterval
          ? _.concat(rowInterval, timeWindow, values).join(',') + '\n'
          : _.concat(tradingInterval, dispatchInterval, timeWindow, values).join(',') + '\n'
    })
    download(output, fileName, 'text/csv')
  }

  const downloadComparisonTableIntervals = (selectedProducts, asset, selectedDate, lastUpdated) => {
    // const { selectedProducts, asset, selectedDate, lastUpdated } = props
    const timezone = _.get(asset, 'market.data.timezone')
    const settlementInterval = _.get(asset, 'market.data.settlement_interval')
    const bidInterval = _.get(asset, 'market.data.bid_interval')
    const getFactors = dualAssetClass =>
      dualAssetClass ? [perKWToPerMW, KW_TO_MW, KW_TO_MW, 1, 1] : [perKWToPerMW, KW_TO_MW, 1]
    const getColumnIsDollarsList = dualAssetClass =>
      dualAssetClass ? [true, false, false, true, true] : [true, false, true]

    const fileName = `${selectedProducts.join('_')}_${asset.assetId}_${selectedDate.format(
      dateFormatNoSeparator,
    )}_${moment().tz(timezone).format(dateTimeFormatWithSecondsNoSeparator)}.csv`
    let output = `Asset: ${asset.name}\nSize/Duration: ${
      getAssetCapacityText(asset).both
    }\nTrading Date: ${selectedDate.format(dateFormat)}\n`
    output += `Data Current as Of: ${lastUpdated.format(
      dateTimeFormatWithSeconds,
    )}\nData Type: ${'Market Prices, Enablements (MW), and Estimated Settlements ($)'}\n`
    output += `products: ${selectedProducts.join(' ')}\n`

    const products = []
    selectedProducts.forEach(product => {
      products.push(`${product} ($/Mwh)`)
      asset.productTypes.forEach(type =>
        products.push(asset.productTypes.length === 1 ? `${product} (MW)` : `${product}-${type} (MW)`),
      )
      asset.productTypes.forEach(type =>
        products.push(asset.productTypes.length === 1 ? `${product} ($)` : `${product}-${type} ($)`),
      )
    })
    const revenues = []

    if (asset.productTypes.length > 1) {
      asset.productTypes.forEach(type => revenues.push('Revenue-' + type))
    }
    revenues.push('Revenue-Total')

    output +=
      settlementIntervalByTime === bidInterval
        ? _.concat('Interval', 'Time Window', products, revenues).join(',') + '\n'
        : _.concat('Trading Interval', 'Dispatch Interval', 'Time Window', products, revenues).join(',') + '\n'

    comparisonIntervals.forEach((interval, index) => {
      const rowInterval = index + 1
      const dispatchInterval = (index % (settlementInterval / bidInterval)) + 1
      const timeWindow = `${moment(interval.startTime).tz(timezone).format(timeFormat)} - ${moment(interval.endTime)
        .tz(timezone)
        .format(timeFormat)}`

      const factors = getFactors(asset.dualType)
      const isDollars = getColumnIsDollarsList(asset.dualType)
      const numberOfRevenueColumns = asset.dualType ? 3 : 1
      const values = interval.values.map((value, index) => {
        const valueIndex =
          index >= interval.values.length - numberOfRevenueColumns ? factors.length - 1 : index % factors.length
        const convertedValue = _.isNil(value) ? null : value * factors[valueIndex]
        return formatMarketValue(convertedValue, 1, marketsFix, true, isDollars[valueIndex])
      })

      output +=
        settlementIntervalByTime === bidInterval
          ? _.concat(rowInterval, timeWindow, values).join(',') + '\n'
          : _.concat(interval.tradingInterval, dispatchInterval, timeWindow, values).join(',') + '\n'
    })

    download(output, fileName, 'text/csv')
  }

  const title = _.get(getFormatData(selectedResultCategory), 'title', '')
  const subTitle = getCardSubtitleByDate(startTime, endTime, lastUpdated, timezone, marketStartHour)
  return (
    <>
      <Grid item>
        <Toggle
          values={marketResultsCategoryOptions}
          selected={selectedResultCategory}
          onChange={handleSelectedViewOnChange}
        />
      </Grid>
      {selectedResultCategory === 'all' && (
        <Grid item>
          <ProductToggle
            asset={asset}
            selectedTime={startTime}
            exclusive={false}
            selected={selectedProducts}
            productNames={productNames}
            productDisplayNames={productDisplayNames}
            onChange={handleProductChange}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Card
          classes={{ content: classes.tableCardContent }}
          title={title}
          subheader={subTitle}
          inProgress={inProgress}
          action={
            <Box className={classes.action}>
              <IconButton
                title="Download"
                className={classes.actionBtn}
                onClick={handleDownloadClick(selectedResultCategory)}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
          }
          className={classes.card}
        >
          {!_.isNil(asset) && selectedResultCategory !== 'all' && (
            <ResultsTable
              asset={asset}
              selectedResultCategory={selectedResultCategory}
              selectedDate={startTime}
              allProductData={allProductData}
              settlementIntervalByTime={settlementIntervalByTime}
            />
          )}
          {!_.isNil(asset) && selectedResultCategory === 'all' && (
            <ComparisonTable
              asset={asset}
              intervals={comparisonIntervals}
              selectedProducts={selectedProducts}
              selectedDate={startTime}
              settlementIntervalByTime={settlementIntervalByTime}
            />
          )}
        </Card>
      </Grid>
    </>
  )
}
ResultsTableCard.displayName = DISPLAY_NAME
ResultsTableCard.propTypes = {
  asset: PropTypes.object,
  inProgress: PropTypes.bool,
}

const mapStateToProps = state => {
  const collatedIntervals = _.get(state, 'collated')
  const getSettlementIntervalByTime = globalAppSelectors.getSettlementIntervalFn(state)

  return {
    collatedIntervals,
    getSettlementIntervalByTime,
  }
}

export default connect(mapStateToProps)(ResultsTableCard)

const getFormatData = selectedKey => {
  switch (selectedKey) {
    case enablementTag:
      return {
        title: 'Enablements (MW)',
        fileName: 'Enablements',
        factor: KW_TO_MW,
        colSpan: 1,
      }
    case marketPriceTag:
      return {
        title: 'Market Price ($/MWh)',
        fileName: 'MarketPrices',
        factor: perKWToPerMW,
        colSpan: 2,
      }
    case settlementTag:
      return {
        title: 'Estimated Settlements ($)',
        fileName: 'Settlements',
        factor: 1,
        colSpan: 1,
      }
    case chartsTag:
      return {
        title: 'Charts',
      }
    case 'all':
      return {
        title: 'Market Prices, Enablements (MW), and Estimated Settlements ($)',
      }
    default:
      return null
  }
}

const getComparisonIntervals = (
  asset,
  selectedDate,
  enablements,
  marketPrices,
  settlements,
  selectedProducts,
  settlementIntervalByTime,
) => {
  const productTypes = _.get(asset, 'productTypes', [])
  const bidInterval = _.get(asset, 'market.data.bid_interval')
  const WINDOWS_PER_INTERVAL = settlementIntervalByTime === bidInterval ? 1 : 6
  const endIndex = getIndex(
    moment(selectedDate).add(1, 'd').subtract(bidInterval, 'minutes'),
    selectedDate,
    moment(selectedDate).add(1, 'd'),
    bidInterval,
  )
  const forecastStartIndex = getIndex(
    moment(enablements.forecastStartTime),
    selectedDate,
    moment(selectedDate).add(1, 'd'),
    bidInterval,
  )

  return _.range(0, endIndex + 1).map(intervalIndex => {
    const tableRow = {
      tradingInterval: parseInt(intervalIndex / WINDOWS_PER_INTERVAL) + 1,
      startTime: moment(selectedDate).add(intervalIndex * bidInterval, 'minutes'),
      endTime: moment(selectedDate).add((intervalIndex + 1) * bidInterval, 'minutes'),
      values: [],
    }
    const isActualInterval = intervalIndex < forecastStartIndex || forecastStartIndex === -1
    const productTypeTotals = []
    selectedProducts.forEach(productName => {
      const productNameIndex = asset.productNames.findIndex(name => name === productName)
      const price = isActualInterval ? marketPrices.values[productNameIndex][intervalIndex] : null
      tableRow.values.push(price)

      productTypes.forEach((productType, productTypeIndex) => {
        const productIndex = productTypes.length * productNameIndex + productTypeIndex
        const enablement = isActualInterval ? enablements.values[productIndex][intervalIndex] : null
        tableRow.values.push(enablement)
      })

      productTypes.forEach((productType, productTypeIndex) => {
        const productIndex = productTypes.length * productNameIndex + productTypeIndex
        const settlement = isActualInterval ? settlements.values[productIndex][intervalIndex] : null
        productTypeTotals[productTypeIndex] = (productTypeTotals[productTypeIndex] || 0) + (settlement || 0)
        tableRow.values.push(settlement)
      })
    })
    tableRow.values = _.concat(
      tableRow.values,
      asset.dualType ? productTypeTotals : [],
      productTypeTotals.reduce((acc, value) => acc + value, 0),
    )

    return tableRow
  })
}
