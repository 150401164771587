import React from 'react'
import download from 'downloadjs'
import _ from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import {
  BatteryNonScheduled as BatteryNonScheduledIcon,
  BatteryScheduled as BatteryScheduledIcon,
  Hydro as HydroIcon,
  Solar as SolarIcon,
  Wind as WindIcon,
} from '@fluence/core'
import {
  KW_TO_MW,
  DATE_FORMAT,
  DATE_FORMAT_FULL_WITH_SEC,
  MINUTES_IN_HOUR,
  FIVE_MINUTE_DURATION,
  BATTERY_NON_SCHEDULED_ASSET_TYPE,
  BATTERY_ASSET_TYPE,
  SOLAR_ASSET_TYPE,
  WIND_ASSET_TYPE,
  HYDRO_ASSET_TYPE,
} from './constants'
import auth from './auth'

export { INTERVAL_SIZE_MINS, KW_TO_MW, SETTLEMENT_INTERVAL_MINS, ZERO } from './constants'

export const shortMonthFormat = 'MMM'
export const dayOfWeekFormat = 'dddd'
export const timeFormat = 'HH:mm'
export const timeFormatWithSeconds = 'HH:mm:ss'
export const yearMonthFormat = 'YYYY-MM'
export const dateFormat = 'YYYY-MM-DD'
export const dateFormatNoSeparator = 'YYYYMMDD'
export const dateTimeFormatWithSecondsNoSeparator = 'YYYYMMDDHHmmss'
export const dateTimeFormatWithSeconds = 'YYYY-MM-DD HH:mm:ss'
export const dateTimeFormat = 'YYYY-MM-DD HH:mm'
export const FORMAT_ISO_NO_TZ = 'YYYY-MM-DDTHH:mm:ss'

export const powerTag = '_kw'
export const soeTag = '_kwh'
export const enablementTag = 'enablement'
export const settlementTag = 'settlement'
export const availabilityTag = 'actual_availability'
export const financeSettlementDayGroup = 'finance_days'
export const financeSettlementMonthGroup = 'finance_months'
export const marketPriceTag = 'market_price'
export const chartsTag = 'charts'
export const tablesTag = 'tables'
export const AMSForecastTag = 'AMSPriceForecast'
export const preDispatchPriceTag = 'PreDispatchPrice'
export const preDispatchDemandTag = 'PreDispatchDemand'
export const optimizationPriceMadeTag = 'OptimizationPriceMade'
export const marketPriceInterventionTag = 'market_price_intervention'
export const MAX_AVAIL = 'Max Avail'

export const secondsInMinute = 60
export const hoursInDay = 24

export const formatKwToMW = value => (_.isNil(value) ? '' : formatValue(value * KW_TO_MW))

export const formatValue = value => {
  if (!_.isNumber(value)) {
    return ''
  }
  const actualValue = Number.parseFloat(value.toFixed(4))
  return numeral(actualValue).format('0[.]00')
}

export const isStringEmptyOrSpace = value => {
  return _.isNil(value) || value.replace(/\s/g, '').length === 0
}

export const isNullOrUndefined = _.isNil

export const formatMoney = (amount, decimalPlace = 0) => {
  if (_.isNumber(amount)) {
    const revenueToIntl = Number(Math.abs(amount).toFixed(decimalPlace)).toLocaleString('au')
    return revenueToIntl === '0' ? `$0` : amount >= 0 ? `$${revenueToIntl}` : `-$${revenueToIntl}`
  }
  return ''
}

export const decimalToPercentage = (decimal, precision = 0) => {
  return _.round(decimal * 100, precision)
}

export const percentageToDecimal = (percentage, precision = 2) => {
  return _.round(_.divide(percentage, 100), precision)
}

export const formatPercentage = (decimal, fix) =>
  _.isNil(decimal) ? '' : `${numeral(decimal * 100).format(formats[fix] || '0.00')}%`

export const formatMarketValue = (number, factor, fix, noComma, isDollar) => {
  if (!_.isNil(number)) {
    const actualFix = Number.isInteger(+(number * factor).toFixed(fix)) ? 0 : fix
    let format = (noComma ? formatsNoComma : formats)[actualFix] || '0,0'
    if (isDollar) {
      format = '$' + format
    }

    // numeral formats something like 4.220055416226387e-14 to NaN, hence toFixed before formatting
    const actualNumber = +(number * factor).toFixed(actualFix)
    return numeral(actualNumber).format(format)
  }
  return ''
}

export const navigateTo = path => {
  // lets use the history.push() method
  // https://stackoverflow.com/questions/42701129/how-to-push-to-history-in-react-router-v4
  // browserHistory.push(path)
  // $('html,body').scrollTop(0)
}

export const findProduct = (asset, productName, productType) => {
  if (_.isEmpty(asset) || !_.isString(productName) || !_.isString(productType)) {
    return
  }
  const products = _.get(asset, 'products', [])
  return products.find(product => product.name === productName && product.data.type === productType)
}

export const getAllPriceProductIds = asset =>
  asset.productNames.map(
    productName => findProduct(asset, productName, asset.market.data.default_product_type).productId,
  )

export const getAllProductIds = asset => {
  const productIds = []
  asset.productNames.forEach(productName => {
    asset.productTypes.forEach(productType => {
      productIds.push(findProduct(asset, productName, productType).productId)
    })
  })
  return productIds
}

export const getProductIdToProductNameMap = asset => {
  const productIdToProductNameMap = {}
  asset.productNames.forEach(productName => {
    asset.productTypes.forEach(productType => {
      if (_.get(asset, ['data', 'configuration', `${productName}_${productType}`]) !== 0) {
        const productId = findProduct(asset, productName, productType).productId
        productIdToProductNameMap[productId] = { productName, productTypeName: productType }
      }
    })
  })
  return productIdToProductNameMap
}

export const getAllEnabledProductIds = (asset, pointInTime = moment('2999-01-01T00:00:00Z')) => {
  const productIds = []
  const assetProductInfo = _.keyBy(_.get(asset, 'products'), 'productId')
  asset.productNames.forEach(productName => {
    asset.productTypes.forEach(productType => {
      const productId = findProduct(asset, productName, productType).productId
      const isProductConfiguredActive = _.get(asset, ['data', 'configuration', `${productName}_${productType}`]) !== 0
      const isProductActiveDuringPointInTime = moment(_.get(assetProductInfo, [productId, 'startTime'])).isSameOrBefore(
        moment(pointInTime),
      )
      if (isProductConfiguredActive && isProductActiveDuringPointInTime) {
        productIds.push(productId)
      }
    })
  })
  return productIds
}

export const getMarketDayStartEnd = (selectedDatetime, tradingDayStartHour, timezone) => {
  if ([selectedDatetime, tradingDayStartHour, timezone].some(_.isNil)) {
    return
  }
  const startOfDay = moment(selectedDatetime).tz(timezone).startOf('day').add(tradingDayStartHour, 'h')

  if (moment(selectedDatetime).tz(timezone).isBefore(startOfDay)) {
    startOfDay.subtract(1, 'd')
  }

  // This will return end of day rather than start of next day e.g. 03:59:59
  const eod = moment(startOfDay).endOf('day').add(tradingDayStartHour, 'h')

  return {
    start: startOfDay,
    end: eod,
  }
}

// Soon to be sunset, see getMarketStartGivenTimestamp() in 5ms.js
// NOTE: When selectedDate is undefined this function will return the current trading day
export const getTradingStart = (market, selectedDate) => {
  if (_.isEmpty(market)) {
    return
  }
  const startOfDay = moment(selectedDate)
    .tz(market.data.timezone)
    .startOf('day')
    .add(market.data.trading_day_start_hour, 'h')
  return moment(selectedDate).tz(market.data.timezone).isSameOrAfter(startOfDay)
    ? startOfDay
    : startOfDay.subtract(1, 'd')
}

export const formatCalendarDateAsTradingDay = (calendarTime, timezone, tradingDayStartHour, format) => {
  if ([calendarTime, timezone, tradingDayStartHour, format].some(_.isNil) || !moment(calendarTime).isValid()) {
    return ''
  }

  const displayTimedate = moment(calendarTime)
  const startOfDay = moment(calendarTime).tz(timezone).startOf('day').add(tradingDayStartHour, 'h')
  if (displayTimedate.isBefore(startOfDay)) {
    displayTimedate.subtract(1, 'days')
  }
  return moment(displayTimedate).format(format)
}

export const formatCalendarDate = (calendarTime, timezone, format) => {
  if ([calendarTime, timezone, format].some(_.isNil) || !moment(calendarTime).isValid()) {
    return ''
  }

  const displayTimedate = moment(calendarTime).tz(timezone)
  return moment(displayTimedate).format(format)
}

export function formatTimeAsTradingDate(datetime, market, datetimeFormat) {
  const tradingDate = getTradingDate(datetime, market)
  return tradingDate.format(datetimeFormat)
}

// Similar to formatCalendarDateAsTradingDay() but has a default value for tradingDayStartHour/marketStartHour
export function getTradingDate(datetime, market) {
  const marketStartHour = _.get(market, 'data.trading_day_start_hour', 4)
  const tradingDate =
    moment(datetime).hours() * MINUTES_IN_HOUR + moment(datetime).minutes() < marketStartHour * MINUTES_IN_HOUR
      ? moment(datetime).subtract(1, 'days')
      : moment(datetime)
  return tradingDate
}

export function getCalendarDate(datetime, isEndTime, market) {
  // if converting for an endTime of interval at 4AM, it should be considered for the next trading day
  // if converting for a beginTime of interval at 4AM, it should not be considered for the next trading day
  const marketStartHour = _.get(market, 'data.trading_day_start_hour', 4)
  const calendarDate =
    moment(datetime).hours() * MINUTES_IN_HOUR + moment(datetime).minutes() <
    marketStartHour * MINUTES_IN_HOUR + isEndTime
      ? moment(datetime).add(1, 'days')
      : moment(datetime)
  return calendarDate
}

export function convertPeriodTimeToCalendarDateTime(startDateTime, dayOfWeek, hours, minutes) {
  if (!moment(startDateTime).isValid() || !_.isNumber(dayOfWeek) || !_.isNumber(hours) || !_.isNumber(minutes)) {
    return null
  }

  return moment(startDateTime).day(dayOfWeek).hour(hours).minute(minutes)
}

// can not pass timezone to datatimepicker, time shown by datatimepicker is not always in the current timezone
export const getMarketTime = (time, timezone) => {
  return moment.tz(time.format(FORMAT_ISO_NO_TZ), timezone)
}

export const getTradingActualTime = (asset, selectedTime) => {
  const marketData = asset.market.data
  return moment.tz(selectedTime.format(dateFormat), marketData.timezone).add(marketData.trading_day_start_hour, 'h')
}

export const getCurrentTradingIntervalStart = asset => {
  const timezone = asset.market.data.timezone
  const settlementInterval = asset.market.data.settlement_interval
  const now = moment()
  const sixtySeconds = 60
  return moment.unix(now.unix() - (now.unix() % (settlementInterval * sixtySeconds))).tz(timezone)
}

// percentOfYearRangeComplete returns a percetange of the year range that has passed
// Inputs
//   currentDate: moment object
//   startDate: moment object
//   endDate: moment object
// Outputs
//   percentOfYearRangeComplete: string
//   e.g.
//     percentOfYearRangeComplete(moment('2018-06-01'), moment('2018-01-01'), moment('2018-12-31'))
//     => formatPercentage(0.5, 1) => '50.0%'
export const percentOfYearRangeComplete = (currentDate, rangeStart, rangeEnd) => {
  currentDate = moment(currentDate) || moment()
  rangeStart = rangeStart || moment(currentDate).startOf('year')
  rangeEnd = rangeEnd || moment(rangeStart).add(1, 'year')
  const totalMillisInRange = rangeEnd.valueOf() - rangeStart.valueOf()
  const elapsedMillis = currentDate.valueOf() - rangeStart.valueOf()
  return formatPercentage(Math.max(0.0, Math.min(1.0, elapsedMillis / totalMillisInRange)), 1)
}

// sumDirectionalDifference sums the (either positive or negative) differences between consecutive pairs in the array
// and returns the sum of the differences
export const sumDirectionalDifference = (arr = [], direction = 'desc') => {
  if (arr.length <= 1) {
    return 0
  }
  let total = 0
  for (let i = 0; i < arr.length - 1; i++) {
    const difference = arr[i] - arr[i + 1]
    if (direction === 'desc' && difference > 0) {
      total += difference
    } else if (direction === 'asc' && difference < 0) {
      total += Math.abs(difference)
    }
  }
  return total
}

export const stringIsInList = (word, wordList) => {
  const upperCaseWord = word.toUpperCase()
  for (let i = 0; i < wordList.length; i++) {
    if (wordList[i].toUpperCase() === upperCaseWord) {
      return true
    }
  }
  return false
}

// end time exclusive and intervalMinutes must be less than a day
export const getIndex = (time, start, end, intervalMinutes) => {
  const NOT_FOUND = -1
  if ([time, start, end, intervalMinutes].some(_.isNil)) {
    return NOT_FOUND
  }
  const intervalSeconds = intervalMinutes * 60

  // Beginning of current interval
  const timeAtStartOfInterval = getTimeAtStartOf(time, intervalMinutes)

  if (moment(start).unix() <= timeAtStartOfInterval.unix() && timeAtStartOfInterval.unix() < end.unix()) {
    // seconds into the day / seconds in interval = interval #
    return (timeAtStartOfInterval.unix() - moment(start).unix()) / intervalSeconds
  }
  return NOT_FOUND
}

// TODO: i wasn't able to use _.mapValues like i wanted to here because i have to use a callback function that i did
// not want to be async, because the final value to stick into the map should not be a promise
export const awaitAllValues = async (object, func = (value, key) => value) => {
  const output = {}
  // eslint-disable-next-line no-unused-vars
  for (const key in object) {
    const value = await object[key]
    output[key] = func(value, key)
  }
  return output
}

export const fetchJSON = async (url, params) => {
  try {
    const newUrl = getUrl(url, params)
    const headers = {
      ...getAuthHeader(),
    }
    const response = await fetch(newUrl, {
      method: 'GET',
      headers: headers,
    })
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return await response.json()
  } catch (error) {
    console.error(error)
  }
}

export const getUrl = (url, params) => {
  const newUrl = new URL(url)
  _.keys(params).forEach(key => {
    const value = params[key]
    if (Array.isArray(value)) {
      value.forEach(val => newUrl.searchParams.append(key, val))
    } else {
      newUrl.searchParams.append(key, params[key])
    }
  })
  return newUrl
}

export const getTimeAtStartOf = (time, durationMinutes) => {
  // duration less than 24 hours
  if (durationMinutes < 24 * 60) {
    const seconds = moment(time).unix()
    const durationSeconds = durationMinutes * 60
    return moment.unix(seconds - (seconds % durationSeconds))
  }
}

export const getTimeAtStartOfNextInterval = (time, durationMinutes) => {
  if (durationMinutes < 24 * 60) {
    const seconds = time.unix()
    const durationSeconds = durationMinutes * 60
    if (seconds % durationSeconds === 0) {
      return moment.unix(seconds - (seconds % durationSeconds))
    } else {
      return moment.unix(seconds + durationSeconds - (seconds % durationSeconds))
    }
  }
}

export const convertToEnding = (dataSeries, sourceDuration) => {
  const sourceSeconds = sourceDuration.asSeconds()
  const timeEndingSeries = dataSeries.map(point => ({
    x: point.x + sourceSeconds,
    y: point.y,
  }))
  const startingValue = dataSeries.length > 0 ? _.clone(dataSeries[0]) : []
  return _.concat(startingValue, timeEndingSeries)
}

export const getFormattedPriceBands = (values, noComma) => {
  let hasDuplicates = false
  let currentFactor = 2
  const formattedValues = values.map(value => formatMarketValue(value, 1, currentFactor, noComma, true))
  do {
    const valueToIndexMap = {}
    formattedValues.forEach((value, index) => (valueToIndexMap[value] = _.concat(valueToIndexMap[value] || [], index)))
    const commonIndexes = Object.values(valueToIndexMap).filter(indexes => _.size(indexes) > 1)
    hasDuplicates = !_.isEmpty(commonIndexes)
    if (hasDuplicates) {
      currentFactor++
      _.flatten(commonIndexes).forEach(
        // eslint-disable-next-line no-loop-func
        index => (formattedValues[index] = formatMarketValue(values[index], 1, currentFactor, false, true)),
      )
    }
  } while (hasDuplicates && currentFactor <= 4)
  return formattedValues
}

export const colors = [
  '#89b368',
  '#eab839',
  '#b747a6',
  '#6ed0e0',
  '#e24d42',
  '#3c6ead',
  '#ef843c',
  '#289c77',
  '#7670af',
  '#fdfdfd',
]

export const getRebidInformation = (reason, previous) => {
  if (_.isEmpty(reason) || reason === previous) {
    return {
      title: '-',
      description: 'No rebid as no change from previous update',
    }
  }
  return {
    title: (reason[5] || 'M').toUpperCase(),
    description: reason,
  }
}

export const getBidCSV = (
  asset,
  priceBands,
  selectedDate,
  selectedProductName,
  selectedProductType,
  bids,
  interval,
  lastUpdated,
) => {
  const priceBandNames = asset.market.data.price_band_names
  const priceBandDisplayNames = asset.market.data.price_band_display_names
  const timezone = asset.market.data.timezone

  let output = `Asset: ${asset.name}\nSize/Duration: ${
    getAssetCapacityText(asset).both
  }\nTrading Date: ${selectedDate.format(dateFormat)}\n`
  output += `Data Current as Of: ${lastUpdated.format(
    dateTimeFormatWithSeconds,
  )}\nMarket Product: ${selectedProductName} (${selectedProductType})\n`

  const priceBandValues = getFormattedPriceBands(
    priceBandNames.map(name => priceBands[name]),
    true,
  )
  const priceBandTitles = priceBandDisplayNames.map(
    (displayName, index) => `${displayName} [${priceBandValues[index]}]`,
  )
  output +=
    _.concat(
      'Interval',
      'Time Window',
      priceBandTitles,
      'Max Avail',
      'PASA',
      'Manual Bid',
      'Rebid Class',
      'Rebid Reason',
      'Created On',
    ).join(',') + '\n'
  bids.forEach((bid, index) => {
    const createdOn = moment(bid.createdOn).tz(timezone).format(dateTimeFormat)
    const startTime = moment(selectedDate).add(interval * index, 'm')
    const timeWindow = `${moment(startTime).tz(timezone).format(timeFormat)} to ${moment(startTime)
      .add(interval, 'm')
      .tz(timezone)
      .format(timeFormat)}`
    const bidValues = priceBandNames.map((names, index) =>
      formatMarketValue(_.get(bid, ['values', index]), KW_TO_MW, marketsFix, true),
    )
    const manualBid = !_.isNil(bid.isManualBid) ? '*' : ''
    const pasa = (asset.data.inverter_discharge_rate * asset.data.number_inverters * KW_TO_MW).toFixed(0)
    const maxAvail = _.isNil(bid.maxAvailablePower)
      ? '-'
      : formatMarketValue(bid.maxAvailablePower, KW_TO_MW, marketsFix, true)
    let rebidReason = _.get(bid, 'reason', '')
    rebidReason = _.isEmpty(rebidReason) ? _.get(bid, 'data.reason', '') : rebidReason
    let previousRebidReason = _.get(bids[index - 1], 'reason', '')
    previousRebidReason = _.isEmpty(previousRebidReason)
      ? _.get(bids[index - 1], 'data.reason', '')
      : previousRebidReason
    const rebid = getRebidInformation(rebidReason, previousRebidReason)
    output +=
      _.concat(
        index + 1,
        timeWindow,
        bidValues,
        maxAvail,
        pasa,
        manualBid,
        rebid.title,
        rebid.description,
        createdOn,
      ).join(',') + '\n'
  })
  return output
}

export const getAssetCapacityText = asset => {
  const capacityText = getUnitlessAssetCapacityText(asset)
  const kw = `${capacityText.kw} MW`
  const kwh = _.isFinite(parseInt(capacityText.kwh)) ? `${capacityText.kwh} MWh` : ''
  return {
    kw,
    kwh,
    both: kw + (kwh !== '' ? ` / ${kwh}` : ''),
  }
}

export const getAssetMWCapacity = asset => {
  return _.get(asset, 'data.inverter_discharge_rate') * _.get(asset, 'data.number_inverters') * KW_TO_MW
}

export const getUnitlessAssetCapacityText = asset => {
  const kw = getAssetMWCapacity(asset).toFixed(0)
  const kwh = asset.data.asset_type.includes(BATTERY_ASSET_TYPE)
    ? `${(_.get(asset, 'data.battery_capacity_kwh') * _.get(asset, 'data.number_batteries') * KW_TO_MW).toFixed(0)}`
    : ''
  return {
    kw,
    kwh,
    both: kw + (kwh !== '' ? ` / ${kwh}` : ''),
  }
}

export const getNumberTruncatedAfterXthDecimalPlace = (value, precision) =>
  Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision)

export const getTableTextColor = value => {
  return isNonzeroValue(value) ? 'white' : 'grey'
}

export const isNonzeroValue = value => {
  const parsedValue = parseFloat(value)
  return _.isNumber(parsedValue) && parsedValue !== 0
}

export const getZeroValueClass = value => {
  return isNonzeroValue(value) ? 'nonzero-value' : 'zero-value'
}

export const mWToKW = 1000
export const perKWToPerMW = 1000
export const perMWToPerkW = 0.001
export const marketsFix = 2
export const maxFloatPrecision = 15 // 64 bit double w/ 53 bit mantissa (52 explicitly stored) has 15 decimal points of precision

export const convertMWToKW = mw => {
  return _.round(mw * mWToKW, maxFloatPrecision)
}

export const convertPricePerMWhTokWh = pricePerMWh => {
  // convert $/MWh to $/kWh, preserve 5 digits of precision - 2 for currency, 3 for multiplicative factor
  return _.round(pricePerMWh * perMWToPerkW, 5)
}

export const convertPricePerkWhToMWh = pricePerMWh => {
  return _.round(pricePerMWh * perKWToPerMW, 5)
}

export const convertkWToMW = (MW, precision = 3) => {
  if (!_.isNumber(MW)) {
    return NaN
  }
  return _.round(MW / mWToKW, precision)
}

export const convertKwToMWAndTruncate = (MW, precision = 0) => {
  if (!_.isNumber(MW)) {
    return NaN
  }
  return _.floor(_.divide(MW, mWToKW), precision)
}

// Taken from stackoverflow https://stackoverflow.com/questions/9553354/how-do-i-get-the-decimal-places-of-a-floating-point-number-in-javascript
export const precision = a => {
  if (!isFinite(a)) {
    return 0
  }
  let e = 1
  let p = 0
  while (Math.round(a * e) / e !== a) {
    e *= 10
    p++
  }
  return p
}

/**
 * This function will take a number and move the decimal place by the factor provided.
 * Inspired by lodash's _.round() function.
 * @param {*} value The value to convert. e.g. 0.7
 * @param {*} factor The factor to move the decimal place by. You can move it either left (with negative numbers) or right (with positive numbers).
 * @returns number The converted value. e.g.
 */
export const convertByFactor = (value, factor) => {
  if (!_.isNumber(value)) {
    return NaN
  }
  // Shift with exponential notation to avoid floating-point issues.
  // See [MDN](https://mdn.io/round#Examples) for more details.
  const pair = `${value}e`.split('e')
  const newValue = +`${pair[0]}e${+pair[1] + factor}`

  return newValue
}

/**
 * Scheduled BESS energy is stored in the database in kW. This function converts kW to MW and rounds it to the nearest MW in the same way that our backend does it.
 * Inspired by lodash's _.round() function.
 * e.g. 4050kW -> 5MW and 4049kW -> 4MW
 * @param {*} value killowatts to convert to megawatts.
 * @returns number The converted value in megawatts.
 */
export const convertBessEnergyKwToMw = value => {
  const ROUND_UP_THRESHOLD = 50
  const megawattFactor = -3

  if (!_.isNumber(value)) {
    return NaN
  }
  const kw = Math.abs(value)
  const isNegative = value < 0 ? -1 : 1

  // Not worried about rounding errors here because we're only dealing with integers.
  const factionalKw = kw % 1000
  const roundUp = factionalKw >= ROUND_UP_THRESHOLD

  const mw = convertByFactor(kw, megawattFactor)
  const mwTrunc = (Math.trunc(mw) + (roundUp ? 1 : 0)) * isNegative

  return mwTrunc
}

export const getOpcapDisplayValue = (value, productName, isRenewable, isScheduledBess, isNsBess) => {
  let displayValue = ''
  if (value === null) {
    return '-'
  }
  if ([value, productName, isRenewable, isScheduledBess, isNsBess].some(_.isNil)) {
    return displayValue
  }

  if (_.isNumber(value)) {
    if (productName.startsWith('ENERGY')) {
      if (isNsBess) {
        displayValue = numeral(convertKwToMWAndTruncate(value, 3)).format('0[.]000')
      } else if (isScheduledBess) {
        displayValue = numeral(convertBessEnergyKwToMw(value)).format('0')
      } else {
        // Renewable
        displayValue = numeral(convertKwToMWAndTruncate(value)).format('0')
      }
    } else {
      displayValue = numeral(convertKwToMWAndTruncate(value)).format('0')
    }
  }
  return displayValue
}

const formats = {
  0: '0,0',
  1: '0,0.0',
  2: '0,0.00',
  3: '0,0.000',
}
const formatsNoComma = {
  0: '0',
  1: '0.0',
  2: '0.00',
  3: '0.000',
}

export const safeMulitply = (a, b, defaultValue = null) => {
  // need to check for max precision
  // - max number when at max precision i.e. 10.000000000000001? 100.000000000000001?
  if (!_.isFinite(a) || !_.isFinite(b)) {
    return defaultValue
  }

  const aHasDecimals = a % 1 !== 0
  const bHasDecimals = b % 1 !== 0

  if (!aHasDecimals && !bHasDecimals) {
    return a * b
  }

  let aDecimals = 0
  if (aHasDecimals) {
    // eslint-disable-next-line no-empty
    if (a.toString().includes('e-')) {
    }
    aDecimals = `${a}`.split('.')[1].length
  }
  let bDecimals = 0
  if (bHasDecimals) {
    if (b.toString().includes('e-')) {
      bDecimals = parseInt(b.toString().split('e-')[1])
    } else {
      bDecimals = `${b}`.split('.')[1].length
    }
  }

  const argA = a * Math.pow(10, aDecimals)
  const argB = b * Math.pow(10, bDecimals)

  return _.round(argA * argB) / (Math.pow(10, aDecimals) * Math.pow(10, bDecimals))
}
window.safeMulitply = safeMulitply

export const refreshInterval = 1000 * 60 * 5

export const formatMapData = (value, factor, maxLength) => {
  if (_.isNil(value)) {
    return ''
  }
  const convertedValue = value * (factor || 1)

  maxLength = maxLength || 5
  const valueToFixed2 = convertedValue.toFixed(2)
  if (valueToFixed2.length <= maxLength) {
    return valueToFixed2
  }

  const valueToFixed1 = convertedValue.toFixed(1)
  if (valueToFixed1.length <= maxLength) {
    return valueToFixed1
  }

  if (convertedValue < 1000) {
    return convertedValue.toFixed(0)
  }
  return numeral(convertedValue).format('0.0a')
}

export function getIcon(assetCategory, assetSubcategory) {
  switch (assetCategory) {
    case BATTERY_ASSET_TYPE:
      if (assetSubcategory === BATTERY_NON_SCHEDULED_ASSET_TYPE) {
        return BatteryNonScheduledIcon
      } else {
        return BatteryScheduledIcon
      }
    case SOLAR_ASSET_TYPE:
      return SolarIcon
    case WIND_ASSET_TYPE:
      return WindIcon
    case HYDRO_ASSET_TYPE:
      return HydroIcon
    default:
      return ThreeDRotationIcon
  }
}

export const getAssetIcon = (assetCategory, assetSubcategory, size = 'small') => {
  const Icon = getIcon(assetCategory, assetSubcategory)
  return <Icon fontSize={size} />
}

export const getDailyRevenueDisplayValue = asset => {
  const dailyRevenue = _.get(asset, 'data.daily_revenue', NaN)
  const revenueToIntl = _.isNumber(dailyRevenue) ? Number(Math.abs(dailyRevenue).toFixed()).toLocaleString('au') : ''
  return _.isNumber(dailyRevenue) ? (dailyRevenue >= 0 ? `$${revenueToIntl}` : `- $${revenueToIntl}`) : ''
}

export const showSideBar = path => {
  const disabledPaths = [
    '/markets/',
    '/markets/userManagement/',
    '/markets/preferences/',
    '/markets/financialPerformance/',
  ]
  return disabledPaths.every(p => !(p === path || p.substr(0, p.length - 1) === path))
}

export const reasonsNotAllowed = ['N/A', 'None', 'No', 'No Reason', '', 'reason', 'invalid reason']

export const getCurrentAssetId = pathname => {
  const params = pathname.split('/')
  for (let i = 0; i < params.length; i++) {
    if (params[i] === 'asset' && i + 1 < params.length) {
      return Number.parseInt(params[i + 1])
    }
  }

  return null
}

export const showAssetHeader = path => {
  const disabledPaths = ['/markets/', '/markets/userManagement/', '/markets/financialPerformance/']
  return disabledPaths.every(p => !(p === path || p.substr(0, p.length - 1) === path))
}

export const PRODUCT_NAMES_SORT_ORDER = [
  'ENERGY',
  'LOWERREG',
  'LOWER1SEC',
  'LOWER6SEC',
  'LOWER60SEC',
  'LOWER5MIN',
  'RAISEREG',
  'RAISE1SEC',
  'RAISE6SEC',
  'RAISE60SEC',
  'RAISE5MIN',
]

const marketProductNamesSortOrder = [
  'Energy',
  'Regulation Lower',
  'Fast Contingency Lower',
  'Slow Contingency Lower',
  'Delayed Contingency Lower',
  'Regulation Raise',
  'Fast Contingency Raise',
  'Slow Contingency Raise',
  'Delayed Contingency Raise',
]

const productDisplayNamesMap = {
  ENERGY: 'Energy',
  LOWERREG: 'REG',
  LOWER6SEC: '6s',
  LOWER60SEC: '60s',
  LOWER5MIN: '5m',
  RAISEREG: 'REG',
  RAISE6SEC: '6s',
  RAISE60SEC: '60s',
  RAISE5MIN: '5m',
}

const productDisplayNamesWithIconMap = {
  ENERGY: 'Energy',
  LOWERREG: (
    <>
      <ArrowDownwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      REG
    </>
  ),
  LOWER1SEC: (
    <>
      <ArrowDownwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      1s
    </>
  ),
  LOWER6SEC: (
    <>
      <ArrowDownwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      6s
    </>
  ),
  LOWER60SEC: (
    <>
      <ArrowDownwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      60s
    </>
  ),
  LOWER5MIN: (
    <>
      <ArrowDownwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      5m
    </>
  ),
  RAISEREG: (
    <>
      <ArrowUpwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      REG
    </>
  ),
  RAISE1SEC: (
    <>
      <ArrowUpwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      1s
    </>
  ),
  RAISE6SEC: (
    <>
      <ArrowUpwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      6s
    </>
  ),
  RAISE60SEC: (
    <>
      <ArrowUpwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      60s
    </>
  ),
  RAISE5MIN: (
    <>
      <ArrowUpwardIcon style={{ fontSize: 'inherit', top: 2, position: 'relative' }} />
      5m
    </>
  ),
}

const PRODUCT_GROUP_ENERGY = 'PRODUCT_GROUP_ENERGY'
const PRODUCT_GROUP_FCAS = 'PRODUCT_GROUP_FCAS'
export const productGroupMap = {
  ENERGY: PRODUCT_GROUP_ENERGY,
  LOWERREG: PRODUCT_GROUP_FCAS,
  LOWER6SEC: PRODUCT_GROUP_FCAS,
  LOWER60SEC: PRODUCT_GROUP_FCAS,
  LOWER5MIN: PRODUCT_GROUP_FCAS,
  RAISEREG: PRODUCT_GROUP_FCAS,
  RAISE6SEC: PRODUCT_GROUP_FCAS,
  RAISE60SEC: PRODUCT_GROUP_FCAS,
  RAISE5MIN: PRODUCT_GROUP_FCAS,
}

const productDisplayNameIconClassMap = {
  ENERGY: null,
  LOWERREG: 'down',
  LOWER6SEC: 'down',
  LOWER60SEC: 'down',
  LOWER5MIN: 'down',
  RAISEREG: 'up',
  RAISE6SEC: 'up',
  RAISE60SEC: 'up',
  RAISE5MIN: 'up',
}

export const getSortedProductNames = names => {
  // Filter out names not explicitly passed in and return the pre-sorted array of names.
  return PRODUCT_NAMES_SORT_ORDER.filter(name => names.indexOf(name) !== -1)
}

export const getSortedProductDisplayNames = names => {
  // Return map object. JS objects do not maintain property order whereas maps do.
  const sortedNames = new Map()

  // Filter out names not explicitly passed in.
  // eslint-disable-next-line no-prototype-builtins
  PRODUCT_NAMES_SORT_ORDER.filter(name => names.hasOwnProperty(name)).forEach(name => {
    // And assign the value to the map under the appropriate key.
    sortedNames.set(name, productDisplayNamesMap[name])
  })

  return sortedNames
}

export const getSortedMarketProductNames = names => {
  // Filter out names not explicitly passed in and return the pre-sorted array of display names.
  return marketProductNamesSortOrder.filter(name => names.indexOf !== -1)
}

export const getProductNameIconClass = name => {
  return typeof productDisplayNameIconClassMap[name] !== 'undefined' ? productDisplayNameIconClassMap[name] : null
}

export const getProductNameWithIcon = name => {
  return typeof productDisplayNamesWithIconMap[name] !== 'undefined' ? productDisplayNamesWithIconMap[name] : null
}

export const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${auth.getToken()}`,
  }
}

export const downloadZip = (url, fileName, body = null) => {
  const headers = Object.assign({}, getAuthHeader(), {
    'Content-Type': 'application/zip',
  })
  let responseOk = true

  return fetch(url, {
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  })
    .then(response => {
      responseOk = response.ok
      return response.arrayBuffer()
    })
    .then(buffer => {
      console.info('Stream read complete %d bytes', buffer.byteLength)
      if (buffer.byteLength === 0) {
        console.info('No data')
        let errorMessage = ''
        if (url.includes('ackFiles')) {
          errorMessage = 'Sorry, there are no AEMO acknowledgment files for that interval.'
        } else {
          errorMessage = 'Sorry, there are no bid files created during that interval.'
        }
        return Promise.reject(new Error(errorMessage))
      } else if (!responseOk) {
        return Promise.reject(new Error('Sorry, something went wrong.'))
      }
      download(buffer, fileName, 'application/zip')
    })
}

export const isPathActive = path => {
  return window.location.pathname === path
}

export const getActivePathClass = (path, activeClass = 'nav-active') => {
  return isPathActive(path) ? activeClass : ''
}

export const getCardSubtitleByDate = (start = '', end = '', lastUpdated = '', timezone, tradingDayStartHour) => {
  if (_.isEmpty(timezone) || !_.isNumber(tradingDayStartHour)) {
    return ''
  }

  const s = moment(start).isValid()
    ? formatCalendarDateAsTradingDay(start, timezone, tradingDayStartHour, DATE_FORMAT)
    : ''

  const e =
    moment(start).isValid() && moment(end).isValid()
      ? ` TO ${formatCalendarDateAsTradingDay(end, timezone, tradingDayStartHour, DATE_FORMAT)}`
      : ''

  const l = moment(lastUpdated).isValid()
    ? `LAST UPDATED ${formatCalendarDateAsTradingDay(
        lastUpdated,
        timezone,
        tradingDayStartHour,
        DATE_FORMAT_FULL_WITH_SEC,
      )}`
    : ''

  return `${s}${e}${![s, e, l].some(_.isEmpty) ? ', ' : ''}${l}`
}

// using this to check truthiness of arrays of heterogenious dependencies
// https://gist.github.com/skoshy/69a7951b3070c2e2496d8257e16d7981
export function isFalsy(item) {
  try {
    if (
      !item || // handles most, like false, 0, null, etc
      (typeof item === 'object' &&
        Object.keys(item).length === 0 && // for empty objects, like {}, []
        !(typeof item.addEventListener === 'function')) // omit webpage elements
    ) {
      return true
    }
  } catch (err) {
    return true
  }

  return false
}

export function isTruthy(item) {
  return !isFalsy(item)
}

// TRADE SETTINGS SCHEDULED CHANGES
export function findPeriodGivenDayOfWeek(periods, selectedDayOfWeek, row, market) {
  return periods.find(period => {
    if (selectedDayOfWeek === period.fromDayOfWeek) {
      return true
    }
    const fromCalendarDateTime = convertPeriodTimeToCalendarDateTime(
      row.startTime,
      period.fromDayOfWeek,
      period.fromHour,
      period.fromMinute,
    )
    const activeDayOfWeekNumber = formatTimeAsTradingDate(fromCalendarDateTime, market, 'd')
    if (selectedDayOfWeek === Number(activeDayOfWeekNumber)) {
      return true
    }
    return false
  })
}

export function processSOELimits(capacity, time, warrantyConstraints) {
  if (capacity == null) {
    return {
      soeMax: null,
      soeMin: null,
    }
  }
  let soeMax = capacity
  let soeMin = 0
  const dataLength = _.get(warrantyConstraints, 'data.length', 0)
  for (let dataIndex = 0; dataIndex < dataLength; dataIndex++) {
    if (
      time.isBetween(
        moment(warrantyConstraints.data[dataIndex].startTime),
        moment(warrantyConstraints.data[dataIndex].endTime).add(FIVE_MINUTE_DURATION),
      )
    ) {
      if (warrantyConstraints.data[dataIndex].data.soc_max_limit !== null) {
        soeMax = warrantyConstraints.data[dataIndex].data.soc_max_limit * capacity
      }
      if (warrantyConstraints.data[dataIndex].data.soc_min_limit !== null) {
        soeMin = warrantyConstraints.data[dataIndex].data.soc_min_limit * capacity
      }
      break
    }
  }
  return {
    soeMax: soeMax,
    soeMin: soeMin,
  }
}
