import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardDatePicker from '../components/KeyboardDatePicker'
import ResultsChartsCard from '../components/market-results/ResultsChartsCard'
import ResultsTableCard from '../components/market-results/ResultsTableCard'
import Toggle from '../components/Toggle'
import {
  enablementTag,
  getAllPriceProductIds,
  getAllProductIds,
  getTradingStart,
  marketPriceInterventionTag,
  AMSForecastTag,
  preDispatchPriceTag,
  optimizationPriceMadeTag,
  settlementTag,
} from '../utility/utility'
import { IntervalConfig, IntervalTimeConfig, MixedTagIntervalConfig } from '../redux/features/interval'
import {
  getCollatedRegionProductIntervals,
  getCollatedAssetProductIntervals,
  getMixedProductAssetRegionCollationIntervals,
} from '../redux/features/collated-interval'
import { getRegionProductIntervals } from '../redux/features/actual-interval'

const DISPLAY_NAME = 'MarketResults'

const useStyles = makeStyles(
  theme => ({
    datePickerGrid: {
      maxHeight: theme.spacing(6),
    },
  }),
  { name: DISPLAY_NAME },
)

const MarketResults = props => {
  const { asset, fetchMarketResults } = props
  const classes = useStyles()

  const market = _.get(asset, 'market')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const marketTimezone = _.get(market, 'data.timezone')
  moment.tz.setDefault(marketTimezone)

  // path and url that match this route /marketResults
  const { path, url } = useRouteMatch()

  // create match object if /marketResults/tables
  const matchTables = useRouteMatch({
    path: `${path}/tables`,
  })

  const options = [
    {
      text: 'Charts',
      key: 'charts',
      toolTip: `Charts`,
      component: Link,
      to: url,
    },
    {
      text: 'Tables',
      key: 'tables',
      toolTip: `Tables`,
      component: Link,
      to: `${url}/tables`,
    },
  ]

  const getSelected = () => {
    if (matchTables) {
      return 'tables'
    }
    return 'charts'
  }

  const [inProgress, setInProgress] = useState(false)
  const selectedView = getSelected()
  const [selectedTime, setSelectedTime] = useState({
    start: null,
    end: null,
  })
  const [lastUpdated, setLastUpdated] = useState()

  useEffect(() => {
    if (!_.isNil(market) && _.isNil(selectedTime.start)) {
      setSelectedTime({
        start: getTradingStart(market),
        end: getTradingStart(market).add(1, 'days'),
      })
    }
  }, [market, selectedTime, selectedTime.start])

  useEffect(() => {
    const fetchData = async (asset, time) => {
      const now = moment().tz(marketTimezone)
      setInProgress(true)
      await fetchMarketResults(asset, time)
      setLastUpdated(now)
      setInProgress(false)
    }
    if (!_.isEmpty(asset) && !_.isNil(selectedTime.start)) {
      fetchData(asset, selectedTime.start)
    }
  }, [asset, selectedTime.start, fetchMarketResults, marketTimezone])

  const handleChangeDatePicker = date => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    setSelectedTime({
      start: newDate,
      end: moment(newDate).endOf('day').add(marketStartHour, 'hours'),
    })
  }

  const minDate = moment().subtract(3, 'years')
  const maxDate = getTradingStart(market).add(1, 'days')
  return (
    <Grid container spacing={1}>
      <Grid item className={classes.datePickerGrid}>
        <KeyboardDatePicker
          timezone={marketTimezone}
          marketStartHour={marketStartHour}
          id="schedule-modal-start-date"
          minDate={minDate}
          maxDate={maxDate}
          selectedDate={selectedTime.start}
          onChange={handleChangeDatePicker}
          ariaLabel="change market prices start date"
        />
      </Grid>
      <Grid item>
        <Toggle values={options} selected={selectedView} />
      </Grid>
      <Switch>
        <Route exact path={path}>
          <Grid item xs={12}>
            <ResultsChartsCard
              asset={asset}
              startTime={selectedTime.start}
              endTime={selectedTime.end}
              lastUpdated={lastUpdated}
              inProgress={inProgress}
            />
          </Grid>
        </Route>
        <Route path={`${path}/tables`}>
          <ResultsTableCard
            asset={asset}
            startTime={selectedTime.start}
            endTime={selectedTime.end}
            lastUpdated={lastUpdated}
            inProgress={inProgress}
          />
        </Route>
      </Switch>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchMarketResults: async (asset, startTime) => {
    const assetId = asset.assetId
    const regionId = asset.region.regionId
    let now = moment().add(asset.market.data.bid_interval, 'minutes')
    if (startTime.isAfter(moment())) {
      now = startTime
    }
    const intervalTimeConfig = new IntervalTimeConfig(startTime, moment(startTime).add(1, 'd'), now)

    const priceProductIds = getAllPriceProductIds(asset)
    const productIds = getAllProductIds(asset)

    // get collated price data
    const p1 = dispatch(
      getMixedProductAssetRegionCollationIntervals(
        new MixedTagIntervalConfig(
          `${assetId}_${optimizationPriceMadeTag}_${AMSForecastTag}_allProducts`,
          optimizationPriceMadeTag,
          AMSForecastTag,
          assetId,
          null,
          priceProductIds,
          null,
        ),
        intervalTimeConfig,
      ),
    )
    const p2 = dispatch(
      getCollatedRegionProductIntervals(
        new IntervalConfig(
          `${regionId}_${preDispatchPriceTag}_allProducts`,
          preDispatchPriceTag,
          null,
          null,
          priceProductIds,
          regionId,
        ),
        intervalTimeConfig,
      ),
    )
    const p3 = dispatch(
      getRegionProductIntervals(
        new IntervalConfig(
          `${regionId}_${marketPriceInterventionTag}_allProducts`,
          marketPriceInterventionTag,
          null,
          null,
          priceProductIds,
          regionId,
        ),
        intervalTimeConfig,
      ),
    )

    //  get collated enablement and settlements data
    const p4 = dispatch(
      getCollatedAssetProductIntervals(
        new IntervalConfig(`${assetId}_${enablementTag}_allProducts`, enablementTag, assetId, null, productIds),
        intervalTimeConfig,
      ),
    )
    const p5 = dispatch(
      getCollatedAssetProductIntervals(
        new IntervalConfig(`${assetId}_${settlementTag}_allProducts`, settlementTag, assetId, null, productIds),
        intervalTimeConfig,
      ),
    )

    const result = await Promise.all([
      ..._.values(p1),
      ..._.values(p2),
      ..._.values(p3),
      ..._.values(p4),
      ..._.values(p5),
    ])
    return result
  },
})

MarketResults.displayName = DISPLAY_NAME

export default connect(null, mapDispatchToProps)(MarketResults)
