import { combineReducers } from 'redux'
import _ from 'lodash'
import { navigateTo } from '../../utility/utility'
import { makeResourceReducer, platApiRoot, ResourceAction } from '../api.js'

export const currentUser = new ResourceAction({
  type: 'USER',
  config: {
    endpoint: '/users/current',
    apiRoot: platApiRoot,
  },
})

export const userAgreement = new ResourceAction({
  type: 'USER_AGREEMENT',
  config: userId => ({
    endpoint: `/userAgreement/user/${userId}`,
  }),
})

export const userAgreementVersion = new ResourceAction({
  type: 'USER_AGREEMENT_INFO',
  config: versionId => ({
    endpoint: `/userAgreement/version/${versionId}`,
  }),
})

export const setCurrentUser = user => ({
  type: 'USER_SUCCESS',
  payload: user,
})

export const addUserAgreement = new ResourceAction({
  type: 'CREATE_USER_AGREEMENT',
  method: 'POST',
  config: (versionId, userId) => ({
    endpoint: `/userAgreement/version/${versionId}/user/${userId}/accept`,
  }),
})

export const getUsers = new ResourceAction({
  type: 'USERS_ALL',
  config: {
    endpoint: '/users',
  },
})

export const getCustomers = new ResourceAction({
  type: 'CUSTOMERS_ALL',
  config: {
    endpoint: '/hostCustomers/market/AEMO',
  },
})

export const editUserAction = new ResourceAction({
  type: 'EDIT_USER',
  method: 'PUT',
  config: user => ({
    endpoint: '/users',
    apiRoot: platApiRoot,
    body: JSON.stringify(user),
  }),
})

export const setPasswordAction = new ResourceAction({
  type: 'SET_PASSWORD',
  method: 'POST',
  config: (email, setPasswordRequest) => ({
    endpoint: `/users/set/${email}`,
    body: JSON.stringify(setPasswordRequest),
  }),
})

const users = makeResourceReducer(getUsers, [])
const customers = makeResourceReducer(getCustomers, [])
const current = makeResourceReducer(
  currentUser,
  {
    userId: null,
    email: '',
    title: '',
    name: '',
    permissions: {},
    preferences: {},
  },
  true,
)
const agreementVersion = makeResourceReducer(userAgreementVersion, {
  versionId: 0,
})
const agreement = makeResourceReducer(userAgreement, {
  agreed: false,
  userId: 0,
  versionId: 0,
})

export const userSelector = state => state.user.current

export const currentUserPermissionsSelector = state => _.get(state, 'user.current.payload.permissions', {})

const intercept = (state = null, action) => {
  switch (action.type) {
    case currentUser.success:
      return state
    case currentUser.error:
      navigateTo('/markets/login')
      return state
    default:
      return state
  }
}

export default combineReducers({
  users,
  customers,
  current,
  intercept,
  agreementVersion,
  agreement,
})
