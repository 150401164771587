import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { Button, useNotifier, XCircle } from '@fluence/core'
import {
  perKWToPerMW,
  formatMoney,
  formatCalendarDateAsTradingDay,
  convertPricePerMWhTokWh,
  ZERO,
} from '../../utility/utility'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import {
  lgcEntriesSelector,
  endOfIntervalSelector,
  marketAssetDataLgcResource,
  upsertAssetDataResource,
  deleteAssetDataResource,
} from '../../redux/features/setting'
import { globalAppSelectors } from '../../redux/features/app'
import Card from '../Card'
import { DATE_FORMAT_DAY, LGC_SETTING_TAG, TIME_FORMAT } from '../../utility/constants'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'LGCCard'

const useStyles = makeStyles(
  theme => ({
    card: {
      background: _.get(theme, 'palette.background.paper', '#1D2126'),
    },
    cardContent: {
      padding: 0,
      maxHeight: theme.spacing(20),
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    cardHeader: {
      borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
    },
    slider: {
      color: '#B0BEC4',
    },
    activeItem: {
      padding: theme.spacing(1, 2),
    },
    activeItemDivider: {
      height: 2,
    },
    activeValue: {
      lineHeight: 1.45,
    },
    lgcItem: {
      padding: theme.spacing(1, 2),
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    boxActive: {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: 150,
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(1),
    },
    boxUpcoming: {
      display: 'flex',
      flexBasis: '100%',
      maxWidth: 150,
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(1),
    },
    deleteBox: {
      display: 'flex',
      flex: '0 1 auto',
    },
    showChildOnHover: {
      '& > div:last-child > div > div:last-child': {
        visibility: 'hidden',
      },
      '&:hover > div:last-child > div > div:last-child': {
        visibility: 'visible',
      },
    },
    visibilityHidden: {
      visibility: 'hidden',
    },
  }),
  { name: DISPLAY_NAME },
)

const LGCCard = props => {
  const {
    asset,
    dispatchCreateAssetData,
    dispatchDeleteAssetData,
    getLGCEntries,
    getMarketPriceCap,
    lgcEntries,
    permissions,
  } = props
  const classes = useStyles()

  const { notifySuccess, notifyError } = useNotifier()
  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)
  const descriptionText =
    'Large-scale Generation Certificate price realised by the asset for sent out generation. Used to determine BEP Implied. For assets ' +
    'covered by PPAs, LGC price is only used for the non-PPA covered portion of asset’s generation, or if the PPA price is non-bundled. Adjustments can take ' +
    'effect in the next available dispatch interval or in the future.'

  const lgcEntriesByTime = lgcEntries.reduce((acc, next) => {
    return {
      ...acc,
      [moment(next.startTime).valueOf()]: next,
    }
  }, {})

  const assetId = _.get(asset, 'assetId')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)

  useEffect(() => {
    if (!_.isNil(assetId)) {
      getLGCEntries(assetId, moment())
    }
  }, [assetId, getLGCEntries])

  const handleLGCSubmit = request => {
    const { startTime, newInputValues } = request
    const startTimestamp = moment(startTime).valueOf()
    const existingAssetData = _.get(lgcEntriesByTime, `${startTimestamp}.data`, {})
    const assetData = {
      ...existingAssetData,
      lgc_price_per_kwh: convertPricePerMWhTokWh(newInputValues[0]),
    }
    return dispatchCreateAssetData(asset.assetId, LGC_SETTING_TAG, startTimestamp, assetData).then(response => {
      if (response.error) {
        notifyError('Unable to save new LGC entry')
        return response
      } else {
        return getLGCEntries(asset.assetId, moment()).then(getLGCResponse => {
          if (getLGCResponse.error) {
            notifyError('Unable to retrieve updated LGC values')
          }
          notifySuccess('LGC adjustment scheduled successfully')
          return getLGCResponse
        })
      }
    })
  }

  const handleLGCDelete = assetDataId => {
    return dispatchDeleteAssetData(assetDataId).then(response => {
      if (response.error) {
        notifyError('Unable to delete LGC entry')
        return response
      } else {
        return getLGCEntries(asset.assetId, moment()).then(getLGCResponse => {
          if (getLGCResponse.error) {
            notifyError('Unable to retrieve updated LGC values')
          }
          return getLGCResponse
        })
      }
    })
  }

  const currentDatetime = moment().tz(mtz)
  const activeLGC =
    !_.isEmpty(lgcEntries) && _.last(lgcEntries.filter(lgc => moment(lgc.startTime).isBefore(currentDatetime)))
  const upcomingLGCs =
    !_.isEmpty(lgcEntries) && lgcEntries.filter(lgc => moment(lgc.startTime).isAfter(currentDatetime))

  const calcLgcValue = lgc => {
    const kwH = _.get(lgc, 'data.lgc_price_per_kwh')
    return kwH === null ? null : _.isFinite(kwH) && kwH * perKWToPerMW
  }

  const displayFormattedMoney = entry => {
    const value = calcLgcValue(entry)
    return value === null ? '-' : formatMoney(value, 2)
  }

  const inputConfigs = [{ label: 'LGC', current: calcLgcValue(activeLGC), disableImplied: true, inputAdornment: '$' }]

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
        }}
        title="LGC ($/MWh)"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          canAdjustTradeSettings && (
            <Button variant="text" onClick={() => setIsSchedulingModalOpen(true)}>
              edit
            </Button>
          )
        }
      >
        <Box p={0}>
          <Grid container spacing={0}>
            {!_.isEmpty(activeLGC) && (
              <>
                <Grid container spacing={0} item xs={12} className={classes.activeItem} alignItems="flex-end">
                  <Grid container spacing={0} item xs={7}>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="textSecondary">
                        ACTIVE SINCE
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" component="span" color="textSecondary">
                        {formatCalendarDateAsTradingDay(activeLGC.startTime, mtz, marketStartHour, DATE_FORMAT_DAY)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" component="span" color="textSecondary">
                        {formatCalendarDateAsTradingDay(activeLGC.startTime, mtz, marketStartHour, TIME_FORMAT)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} item xs={5} justify="flex-end" alignItems="flex-end">
                    <Box className={classes.boxActive}>
                      <Typography
                        className={clsx(classes.noWrap, classes.activeValue)}
                        variant="h2"
                        color="textPrimary"
                      >
                        {displayFormattedMoney(activeLGC)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

            {!_.isEmpty(upcomingLGCs) && upcomingLGCs.length > 0 && (
              <Grid item xs={12}>
                <Divider variant="fullWidth" className={classes.activeItemDivider} />
              </Grid>
            )}

            {!_.isEmpty(upcomingLGCs) &&
              upcomingLGCs.map((lgcEntry, key, lgcs) => (
                <React.Fragment key={key}>
                  <Grid container spacing={0} item xs={12} className={clsx(classes.lgcItem, classes.showChildOnHover)}>
                    <Grid container spacing={0} item xs={7}>
                      <Grid item xs={8}>
                        <Typography variant="body1" color="textSecondary">
                          {formatCalendarDateAsTradingDay(lgcEntry.startTime, mtz, marketStartHour, DATE_FORMAT_DAY)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" color="textSecondary">
                          {formatCalendarDateAsTradingDay(lgcEntry.startTime, mtz, marketStartHour, TIME_FORMAT)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} justify="flex-end" item xs={5}>
                      <Box className={classes.boxUpcoming}>
                        <Typography className={classes.noWrap} variant="body1" color="textSecondary">
                          {displayFormattedMoney(lgcEntry)}
                        </Typography>
                        {canAdjustTradeSettings && (
                          <Box className={classes.deleteBox}>
                            <IconButton
                              aria-label="Remove scheduled LGC Price adjustment"
                              size="small"
                              onClick={() => handleLGCDelete(lgcEntry.assetDataId)}
                            >
                              <XCircle color="primary" fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {key !== lgcs.length - 1 && (
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </Box>
      </Card>
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={handleLGCSubmit}
        title="Schedule LGC adjustment"
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        description={descriptionText}
        precision={2}
        getMarketPriceCap={getMarketPriceCap}
      />
    </>
  )
}

LGCCard.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    return {
      lgcEntries: lgcEntriesSelector(state),
      endOfInterval: endOfIntervalSelector(state),
      getMarketPriceCap: globalAppSelectors.getMarketPriceCapFn(state),
      permissions: currentUserPermissionsSelector(state),
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLGCEntries: (assetId, startTime) =>
      dispatch(marketAssetDataLgcResource.get(assetId, startTime.subtract(ZERO, 'minutes'))),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchDeleteAssetData: assetDataId => dispatch(deleteAssetDataResource.put(assetDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(LGCCard)
