import React, { useEffect, useRef, useState, useMemo } from 'react'
import classNames from 'classnames'
import moment from 'moment-timezone'
import _ from 'lodash'

import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { Button } from '@fluence/core'
import KeyboardDatePicker from '../KeyboardDatePicker'
import { getTimeAtStartOfNextInterval } from '../../utility/utility'
import { MARKET_PRICE_ENERGY_MINIMUM } from '../../utility/constants'
import DefaultScheduledChangesInput from './ScheduledChangesInputComponent/DefaultScheduledChangesInput'
import DenseScheduledChangesInput from './ScheduledChangesInputComponent/DenseScheduledChangesInput'
import {
  hasPriceError,
  ALLOW_NEGATIVE_CONFIG_FIELD,
  OVERRIDE_CHECKBOX_CONFIG_FIELD,
  PRECISION_FIELD,
} from './ScheduledChangesInputComponent/ScheduledChangesInputUtil'

const HEADER_HEIGHT = 56
const FOOTER_HEIGHT = 64
const INTERVAL_SIZE_MINS = 5

const DISPLAY_NAME = 'SchedulingModal'

// Field Constants
const LABEL_INPUT_FIELD = 'label'
const END_TIME_VALUE_INPUT_FIELD = 'endTextFieldLabel'
const UPLOADABLE_INPUT_FIELDS = [LABEL_INPUT_FIELD, END_TIME_VALUE_INPUT_FIELD]
const INPUT_CONFIG_FIELD = 'input'
const USE_NO_END_DATE_VALUE_FIELD = 'useNoEndDate'

// Time Constants
const START_DATE_FIELD = 'startDate'
const START_TIME_FIELD = 'startTime'
const END_DATE_FIELD = 'endDate'
const END_TIME_FIELD = 'endTime'

const newScheduledValueInputVariantComponent = {
  standard: DefaultScheduledChangesInput,
  dense: DenseScheduledChangesInput,
}

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(HEADER_HEIGHT),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  description: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    whiteSpace: 'pre-line',
  },
  body: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + theme.spacing(4)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1, 2, 6),
  },
  priceInputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  normalPaperWidth: {
    maxWidth: 650,
  },
  widePaper: {
    width: '80%',
    maxWidth: theme.spacing(160),
  },
  foot: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: FOOTER_HEIGHT,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  impliedBox: {
    minWidth: 280,
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3.5),
    right: theme.spacing(-0.75),
  },
  primaryBtn: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1.75),
  },
  secondaryBtn: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1.75),
    marginRight: theme.spacing(),
  },
  smallInputTextField: {
    maxWidth: theme.spacing(24),
  },
  largeInputTextField: {
    minWidth: theme.spacing(30),
  },
  largeInputLabelWidth: {
    minWidth: theme.spacing(30),
  },
  label: {
    marginRight: 0,
    minWidth: theme.spacing(12),
  },
  labelWide: {
    marginRight: 0,
    minWidth: theme.spacing(28),
  },
  impliedGrid: {
    width: theme.spacing(18),
  },
  impliedText: {
    fontSize: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  noImpliedSection: {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  startTime: {
    minWidth: theme.spacing(24),
  },
  datePickerGrid: {
    width: theme.spacing(28),
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: '1 0 auto',
  },
  flexFixed: {
    flex: '0 0 auto',
  },
  ml1: {
    marginLeft: theme.spacing(),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  pr4: {
    paddingRight: theme.spacing(4),
  },
}))

const SchedulingModal = React.memo(props => {
  const {
    denseTableConfig = {},
    description,
    disableSubmitIfCurrentPriceIsSameAsNewPrice = false,
    getMarketPriceCap,
    hasScheduleEndTime = false,
    includeFieldNameInSubmittedValue,
    inputConfigs = [],
    checkValidInputCrossValidation = () => true,
    largeInputTextField = false,
    marketTimezone = 'GMT',
    marketTimezoneDisplayName = '',
    open = false,
    onClose,
    onSubmit,
    title = '',
    variant = 'standard',
    wideModal = false,
  } = props
  const { marketStartHour = 4, precision, inputDecimalLimit = 8, endTimeDatePickerLabelPrefix = 'END' } = props
  const classes = useStyles()

  // setting default timezone
  moment.tz.setDefault(marketTimezone)
  const mtz = moment().tz(marketTimezone)
  const nextAvailableIntervalStart = getNextAvailableIntervalStart(mtz)
  const activeMarketStart = getMarketStartGivenTimestamp(moment(mtz), marketStartHour)
  const activeMarketEnd = moment(activeMarketStart).endOf('day').add(marketStartHour, 'hours').subtract(1, 'seconds')

  function getNextAvailableIntervalStart(time) {
    const currentHour = moment(time).hour()
    const defaultStartMinute = Math.floor(moment(time).minute() / INTERVAL_SIZE_MINS + 1) * INTERVAL_SIZE_MINS
    return moment(time).startOf('day').add(currentHour, 'hours').add(defaultStartMinute, 'minutes')
  }

  const initialStartTime = getNextSchedulableIntervalTime(moment(mtz), true).valueOf()
  const initialDate = getMarketStartGivenTimestamp(initialStartTime, marketStartHour)

  const [values, setValues] = useState(getInitialValues(inputConfigs, initialDate, initialStartTime, marketStartHour))

  const [touched, setTouched] = useState(getInitialTouched(inputConfigs))

  const [isDatePickerChange, setIsDatePickerChange] = useState(false)
  const [isTimePickerChange, setIsTimePickerChange] = useState(false)
  const [isUserTypingIncompleteDate, setIsUserTypingIncompleteDate] = useState(false)

  const handleClose = event => {
    if (_.isFunction(onClose)) {
      onClose(event)
    }
  }

  const handleSubmit = (includeFieldNameInSubmittedValue = false) => {
    const startTime = moment(values.startTime)
    const endTime = _.get(values, USE_NO_END_DATE_VALUE_FIELD, true) ? null : moment(_.get(values, 'endTime'))
    const newInputValues = UPLOADABLE_INPUT_FIELDS.reduce((acc, uploadableField) => {
      const shouldSkipEndTimeFields =
        uploadableField === END_TIME_VALUE_INPUT_FIELD && _.get(values, USE_NO_END_DATE_VALUE_FIELD)
      const extractedInputValues = shouldSkipEndTimeFields
        ? []
        : inputConfigs.map(config => {
            const hasOverride = values[`${OVERRIDE_CHECKBOX_CONFIG_FIELD}_${config.label}`]
            const uploadableFieldName = _.get(config, uploadableField)
            const parsedFloatFieldValue = parseFloat(values[`${INPUT_CONFIG_FIELD}_${uploadableFieldName}`])
            const inputValueInfo = includeFieldNameInSubmittedValue
              ? { [uploadableFieldName]: parsedFloatFieldValue }
              : parsedFloatFieldValue
            const overrideOrInputValue = hasOverride ? null : inputValueInfo
            return overrideOrInputValue
          })
      return acc.concat(extractedInputValues)
    }, [])
    const impliedValuesToSubmit = inputConfigs.map(config => {
      return values[`${OVERRIDE_CHECKBOX_CONFIG_FIELD}_${config.label}`] ? config.overrideValue : null
    })
    const request = {
      newInputValues,
      impliedValuesToSubmit,
      startTime,
      endTime,
    }
    if (_.isFunction(onSubmit)) {
      const promise = onSubmit(request)
      const then = promise.then
      if (_.isFunction(then)) {
        promise.then(response => {
          if (_.get(response, 'error', false)) {
            // keep modal open if call back returns an error
          } else {
            onClose()
          }
        })
      }
    }
  }

  const handleChange = name => event => {
    const value = _.get(event, 'target.value')
    setValues({
      ...values,
      [name]: _.toString(value),
    })
    setTouched({
      ...touched,
      [name]: true,
    })
  }

  const handleChangeCheckbox = event => {
    const value = _.get(event, 'target.value')
    const checked = _.get(event, 'target.checked')
    setValues({
      ...values,
      [value]: checked,
    })
    setTouched({
      ...touched,
      [value]: true,
    })
  }

  function createNewSelectionTime(newDay, hour = null, minute = null, marketStartHr, marketStartMin) {
    if (!_.isFinite(hour) || !_.isFinite(minute)) {
      return moment('')
    }
    const isStartHourSame = hour === marketStartHr
    const isNextDay = hour < marketStartHr || (isStartHourSame && minute < marketStartMin)
    const dayOffset = isNextDay ? 1 : 0
    return moment(newDay).startOf('day').hour(hour).minute(minute).add(dayOffset, 'days')
  }

  const handleChangeDatePicker = (dateFieldName, timeFieldName) => date => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    const currentSelectedTime = moment(_.get(values, timeFieldName)).tz(marketTimezone)
    const currentHour = currentSelectedTime.isValid() && currentSelectedTime.hour()
    const currentMinute = currentSelectedTime.isValid() && currentSelectedTime.minute()

    const maxPriceCap = getMarketPriceCap(newDate)

    const cappedInputValues = inputConfigs.reduce((acc, config) => {
      const inputLabel = `input_${config.label}`
      const currentInputValue = _.get(values, inputLabel)
      const cappedCurrentValue =
        currentInputValue !== '' && +currentInputValue > maxPriceCap ? `${maxPriceCap}` : currentInputValue
      return { ...acc, [inputLabel]: cappedCurrentValue }
    }, {})

    // If the time is in the AM before market start hour then the timestamp should be for the following day (+1)
    const marketStartInterval = marketStartHour - 1
    const proposedNewTime = createNewSelectionTime(newDate, currentHour, currentMinute, marketStartInterval, 56)

    // This happens when user is deleting and typing a date. We shoudln't update the input date time data at that moment.
    if (!moment(proposedNewTime).isValid()) {
      setIsUserTypingIncompleteDate(true)
      return
    }
    setIsUserTypingIncompleteDate(false)

    const nextSchedulableTime = getNextSchedulableIntervalTime(moment(), true)
    let newSelectedTime = moment()
    if (moment(proposedNewTime).isBefore(nextSchedulableTime)) {
      if (dateFieldName === START_DATE_FIELD) {
        newSelectedTime = nextSchedulableTime.valueOf()
      } else {
        newSelectedTime = moment(nextSchedulableTime).add(INTERVAL_SIZE_MINS, 'minutes').valueOf()
      }
    } else {
      newSelectedTime = proposedNewTime.valueOf()
    }

    setValues({
      ...values,
      ...cappedInputValues,
      [dateFieldName]: newDate,
      [timeFieldName]: newSelectedTime,
    })
    setTouched({
      ...touched,
      [dateFieldName]: true,
    })
    setIsDatePickerChange(true)
  }

  const handleChangeTime = event => {
    const value = _.get(event, 'target.value')
    const name = _.get(event, 'target.name')

    setValues({
      ...values,
      [name]: value,
    })
    setTouched({
      ...touched,
      startTime: true,
    })
    setIsTimePickerChange(true)
  }

  const rootClasses = classNames(classes.root, classes.paper, wideModal ? classes.widePaper : classes.normalPaperWidth)
  const descriptionText =
    description || 'Adjustments can take effect in the next open dispatch interval or in the future.'

  const [, setLastIntervalUpdate] = useState() // used to trigger re-renders

  const prevOpen = useRef(false)
  useEffect(() => {
    if (open && !prevOpen.current) {
      setValues(getInitialValues(inputConfigs, initialDate, initialStartTime, marketStartHour))
      setTouched(getInitialTouched(inputConfigs))
      setIsDatePickerChange(false)
      setIsTimePickerChange(false)
    }
    prevOpen.current = open
  }, [open, inputConfigs, initialDate, initialStartTime, marketStartHour])

  /**
   * TL;DR; This function will force the component to rerender on a time interval.
   *
   * The rerender is needed to force our interval list to update and invalidate old intervals in near real time.
   */
  const nextAvailableIntervalStartTimestamp = nextAvailableIntervalStart.valueOf()
  useEffect(() => {
    if (open && !_.isNil(marketTimezone)) {
      // nextAvailableIntervalStart changes every five minutes right after end of interval (endOfInterval from parent component triggers rerender)
      const waitTime = nextAvailableIntervalStartTimestamp - moment(marketTimezone).valueOf() // milliseconds until next available interval
      let fiveMinuteRefresh = null
      const timeoutHandle = setTimeout(() => {
        setLastIntervalUpdate(moment().tz(marketTimezone))

        // setLastIntervalUpdate guarantees automatic refresh every five minutes even if user does not click anything
        // and that will remove those expired intervals from list automatically
        fiveMinuteRefresh = setInterval(() => {
          setLastIntervalUpdate(moment().tz(marketTimezone))
        }, INTERVAL_SIZE_MINS * 60 * 1000)
      }, waitTime)
      return () => {
        // will trigger useEffect that manages end of interval refresh
        setIsDatePickerChange(false)
        setIsTimePickerChange(false)
        fiveMinuteRefresh !== null && clearInterval(fiveMinuteRefresh)
        clearTimeout(timeoutHandle)
      }
    }
  }, [open, marketTimezone, nextAvailableIntervalStartTimestamp])

  /**
   * Generates the intervals for display
   *
   * @param currentTime The current time of render
   * @param startDate The date selected in the date picker
   * @param marketStartHr 4am
   * @param minStartDate Minimum selectable date in date picker
   * @returns {[]} A list of intervals to display
   */
  function createIntervals(currentTime, startDate, marketStartHr, minStartDate) {
    const intervalSelection = []
    if (moment(startDate).isBefore(moment(minStartDate))) {
      return intervalSelection
    }

    // Disable closest interval at end of interval
    const useCurrentTime = moment(currentTime).isSameOrAfter(moment(startDate))
    const first = useCurrentTime ? currentTime : startDate
    const next = getNextSchedulableIntervalTime(first, useCurrentTime)
    const nextLabel = moment(next)

    const marketStart = getMarketStartGivenTimestamp(moment(next), marketStartHr)
    const end = moment(marketStart).endOf('day').add(marketStartHr, 'hours')

    while (next.diff(end) <= 0) {
      intervalSelection.push({
        value: next.valueOf(),
        label: `${nextLabel.format('HH:mm')} - ${nextLabel.add(INTERVAL_SIZE_MINS, 'minutes').format('HH:mm')}`,
      })
      next.add(INTERVAL_SIZE_MINS, 'minutes')
    }

    return intervalSelection
  }

  const timeUntilEOD = moment.duration(moment(activeMarketEnd).diff(moment(mtz)))
  // Have to match rerender time, which is end of interval
  const minDate =
    timeUntilEOD.asSeconds() < INTERVAL_SIZE_MINS * 60
      ? moment(activeMarketStart).add(1, 'day')
      : moment(activeMarketStart)

  const selectedDateMarketForStartTimePicker = useMemo(() => {
    return getMarketStartGivenTimestamp(values.startTime, marketStartHour)
  }, [marketStartHour, values.startTime])

  const selectedDateMarketForEndTimePicker = useMemo(() => {
    return getMarketStartGivenTimestamp(values.endTime, marketStartHour)
  }, [marketStartHour, values.endTime])

  const isStartPriceError = inputConfigs.some(config =>
    hasPriceError(
      values,
      config,
      _.get(config, LABEL_INPUT_FIELD),
      _.get(config, PRECISION_FIELD, inputDecimalLimit),
      checkValidInputCrossValidation,
    ),
  )
  const isEndPriceError =
    !values.useNoEndDate &&
    inputConfigs.some(config =>
      hasPriceError(
        values,
        config,
        _.get(config, END_TIME_VALUE_INPUT_FIELD),
        _.get(config, PRECISION_FIELD, inputDecimalLimit),
        checkValidInputCrossValidation,
      ),
    )

  const isNewPriceSameAsCurrentPrice = disableSubmitIfCurrentPriceIsSameAsNewPrice
    ? inputConfigs.every(inputConfig => {
        const { current } = inputConfig
        const newStartPrice = _.get(values, `input_${_.get(inputConfig, LABEL_INPUT_FIELD)}`)
        const newReturnPrice = _.get(values, `input_${_.get(inputConfig, END_TIME_VALUE_INPUT_FIELD)}`)
        const useEndDate = !_.get(values, USE_NO_END_DATE_VALUE_FIELD)
        // Using _.isEqual() here because it can deal with NaN === NaN
        return useEndDate
          ? _.isEqual(parseFloat(newStartPrice), parseFloat(current)) &&
              _.isEqual(parseFloat(newReturnPrice), parseFloat(current))
          : _.isEqual(parseFloat(newStartPrice), parseFloat(current))
      })
    : false

  const isPriceError = isStartPriceError || isEndPriceError || isNewPriceSameAsCurrentPrice

  const checkInputValueAllowed = (
    values,
    precision = Infinity,
    allowNegativeInput = true,
    maxValueLimit = MARKET_PRICE_MAXIMUM,
  ) => {
    const { formattedValue, floatValue } = values
    if (!allowNegativeInput && (_.includes(formattedValue, '-') || floatValue < 0)) {
      return false
    }
    if (precision === 0 && _.includes(formattedValue, '.')) {
      return false
    }
    const decimals = _.get(_.toString(floatValue).split('.'), 1, '')
    const isFormattedValueEmptyOrDash = formattedValue === '' || formattedValue === '-'
    const valueIsInRange = floatValue >= MARKET_PRICE_ENERGY_MINIMUM && floatValue <= maxValueLimit
    const decimalLeqPrecision = precision >= _.size(decimals)
    return (isFormattedValueEmptyOrDash || valueIsInRange) && decimalLeqPrecision
  }

  const isAllOverrideBoxchecked = inputConfigs.every(config => {
    return _.get(values, `${OVERRIDE_CHECKBOX_CONFIG_FIELD}_${config.label}`)
  })

  const startIntervalOptions = createIntervals(mtz, selectedDateMarketForStartTimePicker, marketStartHour, minDate)
  const startOfNextInterval = moment(getTimeAtStartOfNextInterval(mtz, INTERVAL_SIZE_MINS))
  const endIntervalOptions = moment(selectedDateMarketForStartTimePicker).isSameOrAfter(
    moment(selectedDateMarketForEndTimePicker),
  )
    ? createIntervals(
        moment(values.startTime).subtract(5, 'minutes'),
        selectedDateMarketForEndTimePicker,
        marketStartHour,
        selectedDateMarketForStartTimePicker,
      )
    : createIntervals(moment(), selectedDateMarketForEndTimePicker, marketStartHour, minDate)

  // Check Date/time Errors
  const hasTimeError = (timeFieldName, intervalOptions) =>
    !_.isFinite(_.get(values, timeFieldName)) ||
    _.isEmpty(intervalOptions) ||
    !intervalOptions.some(opt => opt.value === _.get(values, timeFieldName))

  const hasDateError = (dateField, minDate) =>
    _.isNil(_.get(values, dateField)) ||
    !moment(_.get(values, dateField)).isValid() ||
    moment(_.get(values, dateField)).isBefore(moment(minDate)) ||
    isTimeError
  const isUsingEndDate = !_.get(values, USE_NO_END_DATE_VALUE_FIELD)
  const isTimeError =
    hasTimeError('startTime', startIntervalOptions) || (isUsingEndDate && hasTimeError('endTime', endIntervalOptions))

  const isDateError = hasDateError('startDate', minDate) || hasDateError('endDate', minDate)

  // Display input error only if a checkbox is checked
  const isBepError = isPriceError && !isAllOverrideBoxchecked
  const override = inputConfigs.some(config => _.get(config, 'override'))
  const disableSubmit = isDateError || (override ? isBepError : isPriceError) || isUserTypingIncompleteDate

  // Manage useNextAvailable changes
  const startIntervalValues = startIntervalOptions.map(opt => opt.value)
  const endIntervalValues = endIntervalOptions.map(opt => opt.value)
  const firstStartTimeOption = _.first(startIntervalValues)
  const nextSchedulableTime = getNextSchedulableIntervalTime(moment(), INTERVAL_SIZE_MINS).valueOf()
  const isNextSchedulableTimeSelected =
    moment(getNextSchedulableIntervalTime(moment(), INTERVAL_SIZE_MINS).valueOf()).valueOf() ===
    moment(values.startTime).valueOf()
  useEffect(() => {
    if (values.useNextAvailable && !isNextSchedulableTimeSelected && moment(firstStartTimeOption).isValid()) {
      setValues(prev => {
        const nextSchedulableTime = getNextSchedulableIntervalTime(moment(), INTERVAL_SIZE_MINS).valueOf()
        return {
          ...prev,
          startDate: getMarketStartGivenTimestamp(moment(nextSchedulableTime), marketStartHour),
          startTime: moment(nextSchedulableTime).valueOf(),
        }
      })
    }
  }, [
    firstStartTimeOption,
    isNextSchedulableTimeSelected,
    marketStartHour,
    nextSchedulableTime,
    startOfNextInterval,
    values.useNextAvailable,
  ])

  // Manage end of interval refresh and consequential errors of time picker
  const isStartTimeAndEndTimeInOptions =
    startIntervalValues.includes(values.startTime) &&
    (!hasScheduleEndTime || endIntervalValues.includes(values.endTime))
  const isInitialDateSelected = values.startDate === initialDate
  useEffect(() => {
    if ((!isStartTimeAndEndTimeInOptions || !isInitialDateSelected) && !isDatePickerChange && !isTimePickerChange) {
      // this handles the case when the trade interval reaches the end of five minutes and needs refresh
      const newInitialStartTime = getNextSchedulableIntervalTime(moment(), INTERVAL_SIZE_MINS).valueOf()
      const newInitialStartDate = getMarketStartGivenTimestamp(newInitialStartTime, marketStartHour)
      const newInitialEndTime = getNextSchedulableIntervalTime(
        moment(newInitialStartTime).add(1, 'seconds'),
        false,
      ).valueOf()
      const newInitialEndDate = getMarketStartGivenTimestamp(newInitialEndTime, marketStartHour)
      setValues(prev => {
        return {
          ...prev,
          startDate: newInitialStartDate,
          startTime: newInitialStartTime,
          endDate: newInitialEndDate,
          endTime: newInitialEndTime,
        }
      })
    }
  }, [isDatePickerChange, isInitialDateSelected, isStartTimeAndEndTimeInOptions, isTimePickerChange, marketStartHour])

  // Manage time picker erroneous changes
  const isEndTimeInvalid = moment(values.startTime).isSameOrAfter(values.endTime)
  useEffect(() => {
    if (isTimePickerChange && isEndTimeInvalid) {
      // this handles the case when user changes the time picker, but the endTime is greater than startTime and is thus invalid
      const newInitialEndTime = getNextSchedulableIntervalTime(
        moment(values.startTime).add(1, 'seconds'),
        false,
      ).valueOf()
      setValues(prev => {
        return {
          ...prev,
          endDate: getMarketStartGivenTimestamp(newInitialEndTime, marketStartHour),
          endTime: newInitialEndTime,
        }
      })
    }
  }, [isEndTimeInvalid, isTimePickerChange, marketStartHour, values.startTime])

  // Manage date picker erroneous changes
  const isSelectedEndTimeInvalid = moment(_.first(endIntervalValues)).isSameOrBefore(_.first(startIntervalValues))
  useEffect(() => {
    if (isDatePickerChange && isSelectedEndTimeInvalid) {
      // this refreshes end time interval options when datepicker is changed, but the end time interval options contain intervals that should not be selectable
      const newInitialEndTime = getNextSchedulableIntervalTime(
        moment(values.startTime).add(1, 'seconds'),
        false,
      ).valueOf()
      setValues(prev => {
        return {
          ...prev,
          [END_DATE_FIELD]: getMarketStartGivenTimestamp(newInitialEndTime, marketStartHour),
          [END_TIME_FIELD]: newInitialEndTime,
        }
      })
    }
  }, [isDatePickerChange, isSelectedEndTimeInvalid, marketStartHour, values.startTime])

  const MARKET_PRICE_MAXIMUM = getMarketPriceCap(values.startDate)
  const scheduleStartDateLabel = hasScheduleEndTime ? 'START DATE' : 'TRADING DATE'
  const scheduleEndDateLabel = `${endTimeDatePickerLabelPrefix} DATE`
  const scheduleEndTimeLabel = `${hasScheduleEndTime ? `${endTimeDatePickerLabelPrefix} TIME` : 'TAKE EFFECT'} ${
    marketTimezoneDisplayName ? `(${marketTimezoneDisplayName})` : ''
  }`
  const scheduleStartTimeLabel = `${hasScheduleEndTime ? 'START TIME' : 'TAKE EFFECT'} ${
    marketTimezoneDisplayName ? `(${marketTimezoneDisplayName})` : ''
  }`
  const endDateCheckBoxLabel = `No ${_.toLower(endTimeDatePickerLabelPrefix)} date`

  const isEndDateDisabled = _.get(values, USE_NO_END_DATE_VALUE_FIELD)
  const isEndDateEnabled = !isEndDateDisabled

  const ScheduledInputComponent = newScheduledValueInputVariantComponent[variant]
  const ScheduledStartInputElement = (
    <ScheduledInputComponent
      checkInputValueAllowed={checkInputValueAllowed}
      denseTableConfig={denseTableConfig}
      handleChange={handleChange}
      handleChangeCheckbox={handleChangeCheckbox}
      inputConfigs={inputConfigs}
      checkValidInputCrossValidation={checkValidInputCrossValidation}
      inputDecimalLimit={inputDecimalLimit}
      largeInputTextField={largeInputTextField}
      precision={precision}
      touched={touched}
      values={values}
    />
  )
  const ScheduledEndInputElement = (
    <ScheduledInputComponent
      checkInputValueAllowed={checkInputValueAllowed}
      denseTableConfig={denseTableConfig}
      handleChange={handleChange}
      handleChangeCheckbox={handleChangeCheckbox}
      inputConfigs={inputConfigs}
      checkValidInputCrossValidation={checkValidInputCrossValidation}
      inputDecimalLimit={inputDecimalLimit}
      isEndDateEnabled={isEndDateEnabled}
      isReturnInput
      largeInputTextField={largeInputTextField}
      precision={precision}
      touched={touched}
      values={values}
    />
  )

  return (
    <Modal aria-labelledby="appetite-info-title" aria-describedby="appetite-body" open={open} onClose={onClose}>
      <div className={rootClasses}>
        <div className={classes.head}>
          <div>
            <Typography id="appetite-info-title" variant="h2" className={classes.title}>
              {title}
            </Typography>
          </div>
          <div>
            <IconButton className={classes.closeBtn} size="small" onClick={handleClose}>
              <Clear fontSize="small" />
            </IconButton>
          </div>
        </div>

        <form id="data-testid-schedule-modal-form" noValidate autoComplete="off">
          <div id="appetite-body" className={classes.body}>
            <div className={classes.description}>
              <Typography variant="body1" color="textPrimary" component="div">
                {descriptionText}
              </Typography>
            </div>
            <div>{ScheduledStartInputElement}</div>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink>SCHEDULE</InputLabel>
                    <div className={classes.mt2}>
                      <FormControlLabel
                        className={classes.labelWide}
                        color="primaryText"
                        control={
                          <Checkbox
                            checked={values.useNextAvailable}
                            onChange={handleChangeCheckbox}
                            value="useNextAvailable"
                            color="primary"
                          />
                        }
                        label={<Typography>Next available interval</Typography>}
                      />
                    </div>
                  </FormControl>
                </Box>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={4} className={classes.datePickerGrid}>
                  <Box mb={4}>
                    <KeyboardDatePicker
                      id="schedule-modal-start-date"
                      disabled={values.useNextAvailable}
                      timezone={marketTimezone}
                      marketStartHour={marketStartHour}
                      inputVariant="standard"
                      label={scheduleStartDateLabel}
                      ariaLabel="change start date"
                      minDate={minDate}
                      selectedDate={values.startDate}
                      onChange={handleChangeDatePicker(START_DATE_FIELD, START_TIME_FIELD)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="scheduling-modal-start-time"
                    className={classes.startTime}
                    disabled={values.useNextAvailable}
                    required
                    select
                    name="startTime"
                    label={scheduleStartTimeLabel}
                    value={values.startTime}
                    onChange={handleChangeTime}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    error={isTimeError}
                  >
                    {startIntervalOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {hasScheduleEndTime && (
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <div className={classes.mt2}>
                        <FormControlLabel
                          className={classes.labelWide}
                          color="primaryText"
                          control={
                            <Checkbox
                              checked={isEndDateDisabled}
                              onChange={handleChangeCheckbox}
                              value={USE_NO_END_DATE_VALUE_FIELD}
                              color="primary"
                            />
                          }
                          label={<Typography>{endDateCheckBoxLabel}</Typography>}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                {hasScheduleEndTime && ScheduledEndInputElement}
              </Grid>
              {hasScheduleEndTime && (
                <Grid container item xs={12}>
                  <Grid item xs={4} className={classes.datePickerGrid}>
                    <KeyboardDatePicker
                      id="schedule-modal-end-date"
                      disabled={isEndDateDisabled}
                      required={isEndDateEnabled}
                      timezone={marketTimezone}
                      marketStartHour={marketStartHour}
                      inputVariant="standard"
                      label={scheduleEndDateLabel}
                      ariaLabel="change end date"
                      minDate={moment(values.startDate)}
                      selectedDate={values.endDate}
                      onChange={handleChangeDatePicker(END_DATE_FIELD, END_TIME_FIELD)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="scheduling-modal-end-time"
                      className={classes.startTime}
                      disabled={isEndDateDisabled}
                      required={isEndDateEnabled}
                      select
                      name="endTime"
                      label={scheduleEndTimeLabel}
                      value={values.endTime}
                      onChange={handleChangeTime}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputLabelProps={{ shrink: true }}
                      margin="normal"
                      error={isTimeError}
                    >
                      {endIntervalOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>

          <div className={classes.foot}>
            <Button variant="secondary" className={classes.secondaryBtn} onClick={handleClose}>
              cancel
            </Button>
            <Button
              variant="primary"
              className={classes.primaryBtn}
              disabled={disableSubmit}
              onClick={() => handleSubmit(includeFieldNameInSubmittedValue)}
            >
              schedule
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
})

/**
 * Determines the time to begin intervals for display
 *
 * @param {moment} time Either current time of render, or market start time for future trading days
 * @param {boolean} offsetIfStartIsTooSoon True for today, and false for future trading days
 * @returns {moment} Time for the first selection in menu
 */
function getNextSchedulableIntervalTime(time, offsetIfStartIsTooSoon = false) {
  const initialMinutes = moment(time).minute()
  const initialMinutesRoundedUp = Math.ceil(initialMinutes / INTERVAL_SIZE_MINS) * INTERVAL_SIZE_MINS

  // Disable closest interval at end of interval
  const offset = offsetIfStartIsTooSoon && initialMinutesRoundedUp - initialMinutes <= 0 ? INTERVAL_SIZE_MINS : 0
  const hours = moment(time).hour()
  const minutes = initialMinutesRoundedUp + offset

  // Add 5 minutes so we can guarantee that optimization has time to pick up the change.
  return moment(time).startOf('day').add(hours, 'hours').add(minutes, 'minutes').add(INTERVAL_SIZE_MINS, 'minutes')
}

/**
 * Determine Market start time given timestamp
 *
 * @param {moment} timestamp A moment timestamp
 * @param {int} marketStartHour The integer start hour of market
 * @returns {moment} A moment time object of when market starts
 */
function getMarketStartGivenTimestamp(timestamp, marketStartHour) {
  const t = moment(timestamp)
  const startOfDay = moment(timestamp).startOf('day')
  const tSeconds = moment(timestamp).diff(startOfDay, 'seconds')
  const isBetweenMidnightAndStartHour = tSeconds < marketStartHour * 60 * 60 - 1
  return isBetweenMidnightAndStartHour
    ? t.subtract(1, 'days').startOf('day').add(marketStartHour, 'hours').subtract(INTERVAL_SIZE_MINS, 'minutes')
    : t.startOf('day').add(marketStartHour, 'hours').subtract(INTERVAL_SIZE_MINS, 'minutes')
}

/**
 * Initialize data structure for this scheduling modal to record input values
 *
 * @param {object} inputConfigs A configuration object passed in as prop
 * @param {moment} initialDate A moment time object that signifies the start date
 * @param {moment} initialStartTime A moment time object that signifies the start time
 * @param {int} marketStartHour An integer that signifies the start hour for the market
 * @returns {object} A moment time object of when market starts
 */
const getInitialValues = (inputConfigs, initialDate, initialStartTime, marketStartHour) => {
  const inputs = inputConfigs.reduce((acc, config) => {
    const defaultInputValue = _.get(config, 'override', false) ? '' : _.get(config, 'current', '')
    return {
      ...acc,
      ...getDefaultUploadableInputFieldValues(config, INPUT_CONFIG_FIELD, defaultInputValue),
    }
  }, {})
  const overrideCheckboxes = inputConfigs.reduce((acc, config) => {
    return {
      ...acc,
      ...getDefaultUploadableInputFieldValues(
        config,
        OVERRIDE_CHECKBOX_CONFIG_FIELD,
        true && !_.get(config, 'disableImplied', true),
      ),
    }
  }, {})
  const allowNegativeInputs = inputConfigs.reduce((acc, config) => {
    return {
      ...acc,
      ...getDefaultUploadableInputFieldValues(
        config,
        ALLOW_NEGATIVE_CONFIG_FIELD,
        _.get(config, ALLOW_NEGATIVE_CONFIG_FIELD, true),
      ),
    }
  }, {})
  const initialEndTime = getTimeAtStartOfNextInterval(
    moment(initialStartTime).add(1, 'seconds'),
    INTERVAL_SIZE_MINS,
  ).valueOf()
  return {
    ...inputs,
    ...overrideCheckboxes,
    ...allowNegativeInputs,
    startDate: initialDate,
    startTime: initialStartTime,
    endDate: getMarketStartGivenTimestamp(initialEndTime, marketStartHour),
    endTime: initialEndTime,
    useNextAvailable: true,
    useNoEndDate: true,
  }
}

/**
 * Initialize data structure for deciding whether an input field was modified
 *
 * @param {object} inputConfigs A configuration object passed in as prop
 * @returns {object} A configuration object that initializes for recording whether an input field was modified
 */
const getInitialTouched = inputConfigs => {
  const inputs = inputConfigs.reduce((acc, config) => {
    return {
      ...acc,
      ...getDefaultUploadableInputFieldValues(config, INPUT_CONFIG_FIELD, false),
    }
  }, {})
  const overrideCheckboxes = inputConfigs.reduce((acc, config) => {
    return {
      ...acc,
      ...getDefaultUploadableInputFieldValues(config, OVERRIDE_CHECKBOX_CONFIG_FIELD, false),
    }
  }, {})
  return {
    ...inputs,
    ...overrideCheckboxes,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
    useNextAvailable: false,
    useNoEndDate: false,
  }
}

/**
 * Generate default uploadable input field value object for getInitialTouched and getInitialValues
 *
 * @param {object} config A configuration object passed in as prop
 * @param {string} keyPrefix a key prefix that forms the entire key to be stored in the input object
 * @param {string} commonDefaultValue a default value that is initialized in the default input fields
 * @returns {object} A default uploadable input field value object for getInitialTouched and getInitialValues
 */
const getDefaultUploadableInputFieldValues = (config, keyPrefix, commonDefaultValue) => {
  return UPLOADABLE_INPUT_FIELDS.reduce((innerAcc, textField) => {
    const fieldKey = `${keyPrefix}_${_.get(config, textField)}`
    return {
      ...innerAcc,
      [fieldKey]: commonDefaultValue,
    }
  }, {})
}

SchedulingModal.displayName = DISPLAY_NAME

export default SchedulingModal
