import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import PriceForecastPerformance from '../components/asset-forecast/PriceForecastPerformance'
import AssetForecastProductsCard from '../components/asset-forecast/AssetForecastProductsCard'
import { assetOperations } from '../redux/features/asset'
import { hasRootLevelPermission } from '../utility/user-utils'
import { CAN_ACCESS_ASSET } from '../utility/constants'

const DISPLAY_NAME = 'Forecasts'

const Forecasts = props => {
  const { asset, user } = props
  const market = _.get(asset, 'market')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  const marketTimezone = _.get(market, 'data.timezone')

  const hasRoot = hasRootLevelPermission(CAN_ACCESS_ASSET, _.get(user, 'permissions', {}))
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AssetForecastProductsCard
          asset={asset}
          market={market}
          marketStartHour={marketStartHour}
          timezone={marketTimezone}
        />
      </Grid>
      {hasRoot && (
        <Grid item xs={12}>
          <PriceForecastPerformance asset={asset} market={market} timezone={marketTimezone} />
        </Grid>
      )}
    </Grid>
  )
}

Forecasts.displayName = DISPLAY_NAME

Forecasts.propTypes = {
  asset: PropTypes.shape({
    assetId: PropTypes.number,
  }),
  user: PropTypes.shape({
    permissions: PropTypes.object,
  }),
}

Forecasts.defaultProps = {
  asset: null,
  user: {},
}

const mapStateToProps = state => {
  const assetsMapByAssetId = _.get(state, 'assetForecast.assets.map')
  const currentUser = _.get(state, 'user.current.payload', {})
  const userIsLoading = _.get(state, 'user.current.isLoading', false)

  return {
    assetsMapByAssetId,
    user: currentUser,
    userIsLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchGetAssets: () => {
    dispatch(assetOperations.getAllAssetsCached())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Forecasts)
