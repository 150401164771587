import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'

const DISPLAY_NAME = 'DetailLargeList'

const useStyles = makeStyles(
  theme => ({
    root: {},
    list: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 16,
    },
    dynamic: {
      color: theme.palette.primary.main,
    },
    primary: {
      fontSize: '1.2rem',
    },
    secondary: {
      color: theme.palette.text.tertiary,
      fontSize: '.7rem',
    },
    firstItemPrimary: {
      fontSize: '0.9rem',
    },
  }),
  { name: DISPLAY_NAME },
)

const DetailListLarge = props => {
  const { listItems } = props
  const classes = useStyles()
  const firstItem = listItems.shift() || {
    primary: 'datatext',
    secondary: 'SUBTITLE',
  }

  return (
    <List style={{ width: '100%' }} className={classes.list}>
      <ListItem style={{ textAlign: 'center' }} key={firstItem.secondary}>
        <ListItemText
          primary={
            <Typography variant="body1" className={classes.firstItemPrimary}>
              {firstItem.primary}
            </Typography>
          }
          secondary={
            <Typography variant="subtitle1" className={classes.secondary}>
              {firstItem.secondary}
            </Typography>
          }
        />
      </ListItem>
      {listItems.map(listItem => (
        <ListItem style={{ textAlign: 'center' }} key={listItem.secondary}>
          <ListItemText
            primary={
              <Typography variant="body1" className={classes.primary}>
                <span className={classes.dynamic}>{listItem.dynamic}</span> {listItem.primary}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle1" className={classes.secondary}>
                {listItem.secondary}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

DetailListLarge.propTypes = {
  listItems: PropTypes.array,
}

DetailListLarge.displayName = DISPLAY_NAME

export default DetailListLarge
