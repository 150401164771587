import moment from 'moment-timezone'
import { API } from '../../api'
import TYPES from './types'

export const getOptimizationRecordsRange = (assetId, startTime, endTime) => {
  return API.marketOptimizations.getOptimizationRecordsRange({
    pathParams: { assetId },
    queryParams: {
      startTime: moment(startTime).toISOString(),
      endTime: moment(endTime).toISOString(),
    },
    types: TYPES.SHOTCLOCK_GET_BID,
    meta: {
      updated: moment().format(),
    },
    cache: false,
  })
}

const operations = {
  getOptimizationRecordsRange,
}

export default operations
