import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { Button, useNotifier, XCircle } from '@fluence/core'
import {
  marketAssetDataMlfResource,
  upsertAssetDataResource,
  mlfScheduleSelector,
  endOfIntervalSelector,
  deleteAssetDataResource,
} from '../../redux/features/setting'
import { currentUserPermissionsSelector } from '../../redux/features/user'
import { globalAppSelectors } from '../../redux/features/app'
import { DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import { getTradingStart } from '../../utility/utility'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import { usePrevious } from '../../utility/hooks'
import Card from '../Card'
import SchedulingModal from './SchedulingModal'

const DISPLAY_NAME = 'MLFCardBESS'

const calcMlfGenValue = (mlf, isGEN) => {
  if (isGEN) {
    return _.get(mlf, 'data.marginal_loss_factor_gen')
  } else {
    return _.get(mlf, 'data.marginal_loss_factor_load')
  }
}

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
    maxHeight: theme.spacing(40),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
  },
  slider: {
    color: '#B0BEC4',
  },
  activeItem: {
    padding: theme.spacing(1, 2),
  },
  activeDate: {
    marginRight: theme.spacing(),
    fontSize: 13,
  },
  upcomingItem: {
    marginRight: theme.spacing(),
    fontSize: 14,
  },
  activeItemDivider: {
    height: 2,
  },
  activeValue: {
    lineHeight: 1.45,
    fontSize: 18,
  },
  item: {
    padding: theme.spacing(1, 2),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  showChildOnHover: {
    '& > div:last-child > div:last-child > div:last-child': {
      visibility: 'hidden',
    },
    '&:hover > div:last-child > div:last-child > div:last-child': {
      visibility: 'visible',
    },
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
}))

function MLFCardBESS(props) {
  // NOTE: endOfInterval triggers re-render when interval changes. We want that.
  // eslint-disable-next-line no-unused-vars
  const { endOfInterval } = props

  const { asset, onIntervalUpdate, permissions, getMlfSchedule, mlfSchedule } = props
  const { dispatchCreateAssetData, dispatchDeleteAssetData, getMarketPriceCap } = props
  const { notifySuccess, notifyError } = useNotifier()

  const canAdjustTradeSettings = canAdjustRiskAppetite(permissions, asset)

  const mlfScheduleByTime = mlfSchedule.reduce((acc, next) => {
    return { ...acc, [moment(next.startTime).valueOf()]: next }
  }, {})

  const assetId = _.get(asset, 'assetId')
  const market = _.get(asset, 'market')
  const mtz = _.get(asset, 'market.data.timezone', 'GMT')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour', 0)
  const marketTimezoneDisplayName = _.get(asset, 'market.data.timezone_display_name', 0)

  const classes = useStyles()

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false)

  useEffect(() => {
    if (!_.isNil(assetId)) {
      getMlfSchedule(assetId, moment())
    }
  }, [assetId, getMlfSchedule])

  const prevEndOfInterval = usePrevious(endOfInterval)
  useEffect(() => {
    if (prevEndOfInterval && endOfInterval && _.isFunction(onIntervalUpdate)) {
      onIntervalUpdate()
    }
  }, [endOfInterval, onIntervalUpdate, prevEndOfInterval])

  const handleMLFDelete = assetDataId => {
    return dispatchDeleteAssetData(assetDataId).then(response => {
      if (response.error) {
        notifyError('Unable to delete MLF entry')
        return response
      } else {
        return getMlfSchedule(asset.assetId, moment()).then(getMLFResponse => {
          if (getMLFResponse.error) {
            notifyError('Unable to retrieve updated MLF values')
          }
          return getMLFResponse
        })
      }
    })
  }

  const currentDatetime = moment().tz(mtz)
  const activeMlf =
    !_.isEmpty(mlfSchedule) &&
    _.last(
      mlfSchedule.filter(mlf => moment(mlf.startTime).isBefore(currentDatetime)),
    )
  const upcomingMLFs =
    !_.isEmpty(mlfSchedule) &&
    mlfSchedule.filter(mlf => moment(mlf.startTime).isAfter(currentDatetime))

  const inputConfigs = [
    {
      label: 'GEN',
      current: calcMlfGenValue(activeMlf, true),
      disableImplied: true,
      inputAdornment: '',
      allowNegativeInput: false,
    },
    {
      label: 'LOAD',
      current: calcMlfGenValue(activeMlf, false),
      disableImplied: true,
      inputAdornment: '',
      allowNegativeInput: false,
    },
  ]

  const submit = request => {
    const { startTime, newInputValues } = request
    const startTimestamp = moment(startTime).valueOf()
    const existingAssetData = _.get(mlfScheduleByTime, `${startTimestamp}.data`, {})
    const assetData = {
      ...existingAssetData,
      marginal_loss_factor_gen: newInputValues[0],
      marginal_loss_factor_load: newInputValues[1],
    }
    return dispatchCreateAssetData(asset.assetId, 'marginal_loss_factor_setting', startTime, assetData).then(
      response => {
        if (response.error) {
          notifyError('Unable to save new MLF entry')
          return response
        } else {
          return getMlfSchedule(asset.assetId, moment()).then(response => {
            if (response.error) {
              notifyError('Unable to retrieve updated MLF values')
            }
            notifySuccess(`MLF scheduled successfully`)

            return response
          })
        }
      },
    )
  }

  return (
    <>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
        }}
        title="MLF"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          canAdjustTradeSettings && (
            <Button variant="text" onClick={() => setIsSchedulingModalOpen(true)}>
              edit
            </Button>
          )
        }
      >
        <Box p={0}>
          <Grid container spacing={0}>
            {!_.isEmpty(activeMlf) && (
              <>
                <Grid container spacing={0} item xs={12} className={classes.activeItem} alignItems="flex-start">
                  <Grid container spacing={0} item xs={6} alignItems="flex-start">
                    <Grid item xs={12}>
                      <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                        ACTIVE SINCE
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={classes.activeDate} variant="body1" component="span" color="textSecondary">
                        {getTradingStart(market, moment(activeMlf.startTime))
                          .tz(mtz)
                          .format(DATE_FORMAT_DAY)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.activeDate} variant="body1" component="span" color="textSecondary">
                        {moment(activeMlf.startTime)
                          .clone()
                          .tz(mtz)
                          .format(TIME_FORMAT)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} item xs={6} justify="flex-end">
                    <Grid item xs={10}>
                      <Box display="flex" justifyContent="flex-end">
                        <Box display="flex" flexWrap="wrap" maxWidth="130px">
                          <Box display="flex" flex="1 1 100%" pl={1}>
                            <Typography
                              className={classNames(classes.noWrap, classes.activeValue)}
                              variant="h2"
                              color="textPrimary"
                            >
                              {calcMlfGenValue(activeMlf, true)}
                            </Typography>
                          </Box>
                          <Box display="flex" flex="1 1 100%" pl={1}>
                            <Typography variant="body1" component="span" color="textSecondary">
                              GEN
                            </Typography>
                          </Box>
                          <Box display="flex" flex="1 1 100%" pl={1}>
                            <Typography
                              className={classNames(classes.noWrap, classes.activeValue)}
                              variant="h2"
                              color="textPrimary"
                            >
                              {calcMlfGenValue(activeMlf, false)}
                            </Typography>
                          </Box>
                          <Box display="flex" flex="1 1 100%" pl={1}>
                            <Typography variant="body1" component="span" color="textSecondary">
                              LOAD
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
              </>
            )}

            {!_.isEmpty(upcomingMLFs) && upcomingMLFs.length > 0 && (
              <Grid item xs={12}>
                <Divider variant="fullWidth" className={classes.activeItemDivider} />
              </Grid>
            )}

            {!_.isEmpty(upcomingMLFs) &&
              upcomingMLFs.map((entry, key, entries) => (
                <React.Fragment key={key}>
                  <Grid
                    container
                    spacing={0}
                    item
                    xs={12}
                    className={classNames(classes.item, classes.showChildOnHover)}
                  >
                    <Grid container spacing={0} item xs={6}>
                      <Grid item xs={8}>
                        <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                          {getTradingStart(market, moment(entry.startTime))
                            .tz(mtz)
                            .format(DATE_FORMAT_DAY)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" color="textSecondary" className={classes.activeDate}>
                          {moment(entry.startTime)
                            .clone()
                            .tz(mtz)
                            .format(TIME_FORMAT)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} justify="flex-end" item xs={6}>
                      <Grid item xs={10}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box display="flex" flexWrap="wrap" maxWidth="130px">
                            <Box display="flex" flex="1 1 100%" pl={1}>
                              <Typography
                                className={(classes.noWrap, classes.upcomingItem)}
                                variant="body1"
                                color="textSecondary"
                              >
                                {calcMlfGenValue(entry, true)}
                              </Typography>
                            </Box>
                            <Box display="flex" flex="1 1 100%" pl={1}>
                              <Typography
                                className={(classes.noWrap, classes.upcomingItem)}
                                variant="body1"
                                color="textSecondary"
                              >
                                GEN
                              </Typography>
                            </Box>
                            <Box display="flex" flex="1 1 100%" pl={1}>
                              <Typography
                                className={(classes.noWrap, classes.upcomingItem)}
                                variant="body1"
                                color="textSecondary"
                              >
                                {calcMlfGenValue(entry, false)}
                              </Typography>
                            </Box>
                            <Box display="flex" flex="1 1 100%" pl={1}>
                              <Typography
                                className={(classes.noWrap, classes.upcomingItem)}
                                variant="body1"
                                color="textSecondary"
                              >
                                LOAD
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box display="flex" flexBasis="100%" maxWidth="150px" justifyContent="flex-end">
                          {canAdjustTradeSettings && (
                            <Box display="flex" flex="0 1 auto" className={classes.deleteBox}>
                              <IconButton
                                aria-label="Remove scheduled marginal loss factor"
                                size="small"
                                onClick={() => handleMLFDelete(entry.assetDataId)}
                              >
                                <XCircle color="primary" fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {key !== entries.length - 1 && (
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
          </Grid>
        </Box>
      </Card>
      <SchedulingModal
        open={isSchedulingModalOpen}
        onClose={() => setIsSchedulingModalOpen(false)}
        onSubmit={submit}
        title="Schedule MLF adjustment"
        description="Adjustments can take effect in the next available dispatch interval or in the future. Be aware that MLF adjustments will impact price band calculations and therefore should only be scheduled for the start of future trading dates."
        inputAdornment=""
        inputConfigs={inputConfigs}
        marketStartHour={marketStartHour}
        marketTimezone={mtz}
        marketTimezoneDisplayName={marketTimezoneDisplayName}
        getMarketPriceCap={getMarketPriceCap}
      />
    </>
  )
}
MLFCardBESS.displayName = DISPLAY_NAME

const makeMapStateToProps = () => {
  return state => {
    return {
      mlfSchedule: mlfScheduleSelector(state),
      endOfInterval: endOfIntervalSelector(state),
      getMarketPriceCap: globalAppSelectors.getMarketPriceCapFn(state),
      permissions: currentUserPermissionsSelector(state),
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getMlfSchedule: (assetId, startTime) =>
      dispatch(marketAssetDataMlfResource.get(assetId, startTime)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchDeleteAssetData: assetDataId => dispatch(deleteAssetDataResource.put(assetDataId)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(MLFCardBESS)
