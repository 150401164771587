import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'

const DISPLAY_NAME = 'DetailList'

const useStyles = makeStyles(
  theme => ({
    root: {},
    list: {
      paddingBlockEnd: 0,
    },
    secondary: {
      color: theme.palette.text.tertiary,
    },
    muiListItemIconRoot: {
      color: theme.palette.text.tertiary,
      minWidth: 40,
    },
  }),
  { name: DISPLAY_NAME },
)

const DetailList = props => {
  const { listItems } = props
  const classes = useStyles()
  return (
    <List dense className={classes.list}>
      {listItems.map(listItem => (
        <ListItem key={listItem.secondary}>
          <ListItemIcon classes={{ root: classes.muiListItemIconRoot }}>
            <listItem.IconComponent size="small" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{listItem.primary}</Typography>}
            secondary={<Typography variant="subtitle1" className={classes.secondary}>{listItem.secondary}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  )
}

DetailList.propTypes = {
  listItems: PropTypes.array,
}

DetailList.displayName = DISPLAY_NAME

export default DetailList
