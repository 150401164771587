import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { selectBidRow } from '../../redux/features/bid'
import {
  marketsFix,
  KW_TO_MW,
  SETTLEMENT_INTERVAL_MINS,
  INTERVAL_SIZE_MINS,
  formatMarketValue,
  getTradingStart,
} from '../../utility/utility'
import { BID_INTERVAL, PRODUCT_NAME_ENERGY } from '../../pages/Bids'
import { globalAppSelectors } from '../../redux/features/app'
import { PriceBandFirstHeader, PriceBandSecondHeader } from '../price-bands'

const DISPLAY_NAME = 'BidTable'
export const RAISE_FCAS = 'Raise FCAS'
export const LOWER_FCAS = 'Lower FCAS'
const SCROLL_OFFSET_TOP = 160
const WINDOWS_PER_INTERVAL = 6

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    table: {
      borderCollapse: 'separate' /* Don't collapse */,
      borderSpacing: 0,
      textTransform: 'uppercase',
      '& tbody:hover td[rowSpan], tr:hover td': {
        backgroundColor: 'rgba(53, 88, 129, 0.5)',
      },
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    stickyHeaderSecond: {
      position: 'sticky',
      top: 21,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    manualBidDot: {
      fontSize: 12,
    },
    arrow: {
      position: 'absolute',
      top: -1,
      left: 2,
    },
    selected: {
      backgroundColor: theme.palette.action.selected,
    },
    fontGrey: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    fontWhite: {
      color: '#FFF',
    },
    past: {
      '& td': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
    current: {
      color: theme.palette.primary.main,
      '& td': {
        color: theme.palette.primary.main,
      },
    },
    future: {
      '& td': {
        color: '#FFF',
      },
    },
    paused: {
      '& td': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: DISPLAY_NAME },
)

const FirstHeader = React.memo(function FirstHeader({ asset, selectedProductName }) {
  const classes = useStyles()
  const priceBandNames = _.get(asset, 'market.data.price_band_display_names', [])
  return (
    <TableRow>
      <TableCell className={classes.stickyHeader} key="bidIntervalHeader" />
      <TableCell className={classes.stickyHeader} key="time" style={{ width: 130 }} align="center" />
      <PriceBandFirstHeader priceBandNames={priceBandNames} className={classes.stickyHeader} />
      {isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell className={classes.stickyHeader} key="pasaHeader" />
      )}
      <TableCell className={classes.stickyHeader} key="manualBidHeader" />
      <TableCell className={classes.stickyHeader} key="rebidHeader" />
      {isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell key="roc" className={classes.stickyHeader} colSpan={2}>
          ROC
        </TableCell>
      )}
      {!isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell key="fcasMin" colSpan={1} className={classes.stickyHeader}>
          EN
        </TableCell>
      )}
      {!isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell key="breakpoint" colSpan={2} className={classes.stickyHeader}>
          Breakpoint
        </TableCell>
      )}
      {!isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell key="fcasMax" colSpan={1} className={classes.stickyHeader}>
          EN
        </TableCell>
      )}
    </TableRow>
  )
})

const SecondHeader = React.memo(function SecondHeader({ asset, priceBandValues = {}, selectedProductName }) {
  const classes = useStyles()
  const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
  return (
    <TableRow>
      <TableCell className={classes.stickyHeaderSecond} key="bidIntervalSecondHeader" />
      <TableCell className={classes.stickyHeaderSecond} key="timeSecondHeader" align="left">
        Interval
      </TableCell>
      {!_.isNil(priceBandValues) && (
        <PriceBandSecondHeader
          priceBandNames={priceBandNames}
          priceBandValues={priceBandValues}
          className={classes.stickyHeaderSecond}
          maxAvailToolTip="MaxAvail"
        />
      )}
      {isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell className={classes.stickyHeaderSecond} key="pasa">
          PASA
        </TableCell>
      )}
      <Tooltip title="Manual bid active" key="manualBidTooltip">
        <TableCell className={clsx(classes.stickyHeaderSecond, classes.manualBidDot)} key="manualBidHeader">
          ●
        </TableCell>
      </Tooltip>
      <TableCell className={classes.stickyHeaderSecond} key="rebid">
        Rebid
      </TableCell>
      {isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell className={classes.stickyHeaderSecond} key="up">
          <ArrowUpwardIcon className={classes.arrow} transform="scale(0.65 0.65)" />
        </TableCell>
      )}
      {isSelectedProductNameEnergy(selectedProductName) && (
        <TableCell className={classes.stickyHeaderSecond} key="down">
          <ArrowDownwardIcon className={classes.arrow} transform="scale(0.65 0.65)" />
        </TableCell>
      )}
      {!isSelectedProductNameEnergy(selectedProductName) && (
        <>
          <TableCell className={classes.stickyHeaderSecond} key="min">
            Min
          </TableCell>
          <TableCell className={classes.stickyHeaderSecond} key="low">
            Low
          </TableCell>
          <TableCell className={classes.stickyHeaderSecond} key="high">
            High
          </TableCell>
          <TableCell className={classes.stickyHeaderSecond} key="max">
            Max
          </TableCell>
        </>
      )}
    </TableRow>
  )
})

function BidTable(props) {
  const classes = useStyles()
  const {
    asset,
    selectedDate,
    selectedProductName,
    selectedProductType,
    bidCollationInterval,
    onRowClick,
    selectedBidIndex,
    currentBidIndex,
    isBidDeliveryPaused,
    getSettlementIntervalByTime,
    isLoading,
    bids,
    priceBandValues,
  } = props
  const refsArray = useRef([])
  const [shouldScroll, setShouldScroll] = useState(true)
  const [userSelectedRow, setUserSelectedRow] = useState(null)
  const tableContainerRef = useRef()

  useEffect(() => {
    if (shouldScroll && !_.isNil(tableContainerRef) && _.get(refsArray, 'current.length', false)) {
      const offsetTop = _.get(refsArray, ['current', currentBidIndex, 'offsetTop'], 0)
      tableContainerRef.current.scrollTo(0, Math.max(offsetTop - SCROLL_OFFSET_TOP, 0))
      setShouldScroll(false)
    }
  }, [shouldScroll, tableContainerRef, currentBidIndex, isLoading, refsArray])

  useEffect(() => {
    setUserSelectedRow(null)
  }, [bidCollationInterval])

  useEffect(() => {
    if (_.isNil(userSelectedRow) && currentBidIndex !== -1 && _.isFunction(onRowClick)) {
      onRowClick(currentBidIndex)
      setShouldScroll(true)
    }
  }, [
    bidCollationInterval,
    selectedProductName,
    selectedProductType,
    currentBidIndex,
    userSelectedRow,
    onRowClick,
    asset,
  ])

  const BidRow = React.forwardRef((rowProps, ref) => {
    const { bid, index } = rowProps
    const priceBandNames = _.get(asset, 'market.data.price_band_names', [])
    const interval = bidCollationInterval === BID_INTERVAL ? INTERVAL_SIZE_MINS : SETTLEMENT_INTERVAL_MINS
    const startTime = moment(selectedDate).add(interval * index, 'm')
    const formattedTime = `${startTime.format('HH:mm')} - ${moment(startTime).add(interval, 'm').format('HH:mm')}`
    const isValidIndex = currentBidIndex !== -1
    const isCurrentIndex = index === currentBidIndex
    const isPastIndex = isValidIndex && index < currentBidIndex
    const isPausedFutureIndex =
      isBidDeliveryPaused &&
      ((isValidIndex && index > currentBidIndex) || selectedDate.isAfter(getTradingStart(asset.market)))
    const timeMatterClass = clsx({
      [classes.fontWhite]: !isPastIndex && !isCurrentIndex,
      [classes.fontGrey]: isPastIndex,
    })
    const settlementIntervalByTime = getSettlementIntervalByTime(selectedDate)

    let settlementInterval = null
    let tradingInterval = null
    if (index % WINDOWS_PER_INTERVAL === 0 && bidCollationInterval === BID_INTERVAL) {
      settlementInterval = (
        <TableCell key="settlementInterval" rowSpan={WINDOWS_PER_INTERVAL} className={timeMatterClass} align="center">
          {Number.parseInt(index / WINDOWS_PER_INTERVAL) + 1}
        </TableCell>
      )
    }
    tradingInterval = (
      <TableCell key="tradingInterval" className={timeMatterClass} align="center">
        {index + 1}
      </TableCell>
    )

    const time = (
      <TableCell key="time" className={timeMatterClass} align="left">
        {formattedTime}
      </TableCell>
    )
    const values = priceBandNames.map((name, index) => {
      const value = _.get(bid, ['values', index])
      const displayValue = formatMarketValue(value, KW_TO_MW, marketsFix)
      const rootClass = clsx({
        [classes.fontWhite]: value,
        [classes.fontGrey]: !value,
      })
      return (
        <TableCell
          key={`${name}-${index}`}
          classes={{
            root: rootClass,
          }}
        >
          {displayValue}
        </TableCell>
      )
    })

    const maxAvailablePower = _.get(bid, 'maxAvailablePower')
    const maxAvail = (
      <TableCell
        key="maxAvail"
        className={clsx({
          [classes.fontWhite]: maxAvailablePower,
          [classes.fontGrey]: !maxAvailablePower,
        })}
      >
        {_.isNil(maxAvailablePower) ? '-' : formatMarketValue(maxAvailablePower, KW_TO_MW, 0)}
      </TableCell>
    )
    const pasaValue = getPasaValue(asset, bid)

    const isPasaWhite = asset.data.inverter_discharge_rate * asset.data.number_inverters
    const pasa = (
      <TableCell
        key="pasa"
        className={clsx({
          [classes.fontWhite]: isPasaWhite,
          [classes.fontGrey]: !isPasaWhite,
        })}
      >
        {pasaValue}
      </TableCell>
    )

    const isManualBid = _.get(bid, 'isManualBid', false)
    const manualBidReason = isManualBid ? _.get(bid, 'manualBidReason') : ''
    const manualBidIndicator = '●'
    const showManualBidIndicator = isManualBid ? manualBidIndicator : ''
    const manualBid = (
      <Tooltip title={manualBidReason} key={`manualBid-tooltip-${index}`}>
        <TableCell className={timeMatterClass}>
          <Typography className={classes.manualBidDot}>{showManualBidIndicator}</Typography>
        </TableCell>
      </Tooltip>
    )

    const rebidReason = _.get(bid, 'reason', '')
    const title = _.isEmpty(rebidReason) ? '' : _.get(rebidReason, 5, 'M')
    const rebidDescription = _.isEmpty(rebidReason) ? '' : rebidReason
    const rebid = (
      <Tooltip title={rebidDescription} key={`rebid-tooltip-${index}`}>
        <TableCell className={timeMatterClass}>
          <Typography>{title}</Typography>
        </TableCell>
      </Tooltip>
    )

    const moreColumns = []
    const config = _.get(bid, 'bidConfiguration')
    const fcasTrapezium = _.get(bid, 'fcasTrapezium')
    if (!_.isEmpty(config) && !_.isNil(config)) {
      if (selectedProductName === PRODUCT_NAME_ENERGY) {
        moreColumns.push(
          getMoreCell('up', bid.bidConfiguration.Energy.up.max, KW_TO_MW, timeMatterClass),
          getMoreCell('down', bid.bidConfiguration.Energy.down.max, KW_TO_MW, timeMatterClass),
        )
      } else {
        let enablementMin = null
        let enablementMax = null
        let breakpointLow = null
        let breakpointHigh = null
        if (!_.isNil(fcasTrapezium)) {
          enablementMin = _.get(fcasTrapezium, 'enablementMin')
          breakpointLow = _.get(fcasTrapezium, 'breakpointLow')
          breakpointHigh = _.get(fcasTrapezium, 'breakpointHigh')
          enablementMax = _.get(fcasTrapezium, 'enablementMax')
        } else {
          // the FCAS Trapezium data in bidConfiguration has been deprecated and only available on older records
          const groupData = _.get(asset, ['market', 'data', 'product_groups', RAISE_FCAS], []).some(
            product => product.name === selectedProductName,
          )
            ? bid.bidConfiguration[RAISE_FCAS]
            : bid.bidConfiguration[LOWER_FCAS]
          enablementMin = groupData[selectedProductType]['enablement_min']
          breakpointLow = groupData[selectedProductType]['breakpoint_low']
          breakpointHigh = groupData[selectedProductType]['breakpoint_high']
          enablementMax = groupData[selectedProductType]['enablement_max']
        }
        moreColumns.push(
          getMoreCell('min', enablementMin, KW_TO_MW, timeMatterClass),
          getMoreCell('low', breakpointLow, KW_TO_MW, timeMatterClass),
          getMoreCell('high', breakpointHigh, KW_TO_MW, timeMatterClass),
          getMoreCell('max', enablementMax, KW_TO_MW, timeMatterClass),
        )
      }
    } else {
      // Draw empty cells to ensure the row goes end to end.
      if (selectedProductName === PRODUCT_NAME_ENERGY) {
        moreColumns.push(<TableCell key="up" />, <TableCell key="down" />)
      } else {
        moreColumns.push(
          <TableCell key="min" />,
          <TableCell key="low" />,
          <TableCell key="high" />,
          <TableCell key="max" />,
        )
      }
    }

    const isSelectedIndex = selectedBidIndex === index
    const showPasaInRowOnlyWhenEnergy = row =>
      isSelectedProductNameEnergy(selectedProductName) ? true : _.get(row, 'key') !== 'pasa'
    const rowValues = _.concat(
      settlementIntervalByTime === INTERVAL_SIZE_MINS || bidCollationInterval !== BID_INTERVAL
        ? tradingInterval
        : settlementInterval,
      time,
      values,
      maxAvail,
      pasa,
      manualBid,
      rebid,
      moreColumns,
    ).filter(rowValue => showPasaInRowOnlyWhenEnergy(rowValue))
    return (
      <TableRow
        ref={ref}
        key={index}
        onClick={() => {
          setUserSelectedRow(index)
          onRowClick(index)
        }}
        className={clsx({
          [classes.selected]: isSelectedIndex,
          [classes.current]: isCurrentIndex,
          [classes.paused]: isPausedFutureIndex,
        })}
      >
        {rowValues}
      </TableRow>
    )
  })

  const getMoreCell = (key, value, factor, classProps) => {
    const displayValue = formatMarketValue(value, factor, marketsFix)
    return (
      <TableCell key={key} className={classProps}>
        {displayValue}
      </TableCell>
    )
  }

  const settlementIntervalByTime = getSettlementIntervalByTime(selectedDate)
  const numTableBodies = bids.length / WINDOWS_PER_INTERVAL

  return (
    <div className={classes.root} ref={tableContainerRef}>
      <Table className={classes.table} size="small">
        <TableHead>
          <FirstHeader asset={asset} selectedProductName={selectedProductName} />
          <SecondHeader asset={asset} priceBandValues={priceBandValues} selectedProductName={selectedProductName} />
        </TableHead>
        {settlementIntervalByTime === INTERVAL_SIZE_MINS && (
          <TableBody>
            {bids.map((bid, bidIndex) => (
              <BidRow
                key={bidIndex}
                bid={bid}
                index={bidIndex}
                ref={ref => {
                  refsArray.current[bidIndex] = ref
                }}
              />
            ))}
          </TableBody>
        )}
        {settlementIntervalByTime !== INTERVAL_SIZE_MINS &&
          _.range(0, numTableBodies).map(tableBodyIndex => {
            return (
              <TableBody key={tableBodyIndex}>
                {_.range(0, WINDOWS_PER_INTERVAL).map(windowIndex => {
                  const bidIndex = tableBodyIndex * WINDOWS_PER_INTERVAL + windowIndex
                  return (
                    <BidRow
                      key={bidIndex}
                      bid={bids[bidIndex]}
                      index={bidIndex}
                      ref={ref => {
                        refsArray.current[bidIndex] = ref
                      }}
                    />
                  )
                })}
              </TableBody>
            )
          })}
      </Table>
    </div>
  )
}

const isSelectedProductNameEnergy = selectedProductName => selectedProductName === PRODUCT_NAME_ENERGY

const getPasaValue = (asset, bid) => {
  const registeredPasaValue = formatMarketValue(
    asset.data.inverter_discharge_rate * asset.data.number_inverters,
    KW_TO_MW,
    0,
  )
  const bidPasa = _.get(bid, 'pasa')
  return !_.isNil(bidPasa) ? formatMarketValue(bidPasa, KW_TO_MW, 0) : registeredPasaValue
}

const mapStateToProps = state => {
  const isBidDeliveryPaused =
    !_.get(state, 'setting.bidFileSettings.isLoading', false) &&
    _.get(state, 'setting.bidFileSettings.payload.data.isBidDeliveryPaused', false)
  const getSettlementIntervalByTime = globalAppSelectors.getSettlementIntervalFn(state)

  return {
    selectedBidIndex: _.get(state, 'bid.view.selectedBidIndex'),
    isBidDeliveryPaused,
    getSettlementIntervalByTime,
  }
}

const mapDispatchToProps = dispatch => ({
  onRowClick: rowIndex => {
    dispatch(selectBidRow(rowIndex))
  },
})

BidTable.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(BidTable)
