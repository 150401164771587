import { combineReducers } from "redux"
import { ResourceAction } from "../api"
import { intervalStateCreator, getIntervals } from "./interval"

const assetProductAction = new ResourceAction({
  type: "GROUP_PRODUCT_ASSET_INTERVAL",
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + "&productId=" + productId, "")
    return {
      endpoint: `/market/frontend/asset/${intervalConfig.assetId}/products/interval?startTime=${encodeURIComponent(intervalTimeConfig.startTime.toISOString())}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&tag=${intervalConfig.tag}${productsQuery}`,
      meta: {
        intervalConfig,
        intervalTimeConfig
      }
    }
  }
})

const productRegionAction = new ResourceAction({
  type: "GROUP_REGION_PRODUCT_INTERVAL",
  config: (intervalConfig, intervalTimeConfig) => {
    const productsQuery = intervalConfig.productIds.reduce((acc, productId) => acc + "&productId=" + productId, "")
    return {
      endpoint: `/market/frontend/products/region/${intervalConfig.regionId}/interval?startTime=${encodeURIComponent(intervalTimeConfig.startTime.toISOString())}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&tag=${intervalConfig.tag}${productsQuery}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      }
    }
  }
})

export const getAssetProductIntervals = getIntervals(assetProductAction, "actual")
export const getRegionProductIntervals = getIntervals(productRegionAction, "actual")

const actions = [assetProductAction, productRegionAction]
const intervals = intervalStateCreator(actions)

export default combineReducers({
  intervals
})
