import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import Card from '../Card'
import {
  formatValue,
  formatPercentage,
  dateTimeFormat,
  percentOfYearRangeComplete,
  getCardSubtitleByDate,
  convertkWToMW,
  powerTag,
  soeTag,
  sumDirectionalDifference,
  getIndex,
  INTERVAL_SIZE_MINS,
} from '../../utility/utility'
import {
  accumulatedThroughputResource,
  accumulatedThroughputResourceStart,
  annualCycleCountResource,
  GET_SOE_CALC_INTERVAL_TYPES,
  getAggregatedIntervals,
  getLastWarrantyContracts,
  selectors as assetOperationSelectors,
} from '../../redux/features/asset-operation'
import DetailLargeList from '../DetailLargeList'
import { getChartData, IntervalConfig, IntervalTimeConfig } from '../../redux/features/interval'
import { BATTERY_4SEC_TAGS, FIVE_MINUTE_DURATION } from '../../utility/constants'
import { getForecastStartTime } from './AssetOperationsChartCard'

const DISPLAY_NAME = 'TrackingCard'
const TITLE = 'Tracking'

const useStyles = makeStyles(
  theme => ({
    root: {},
    card: {
      minHeight: 200,
    },
    tableCardContent: {
      minHeight: 200,
      padding: 0,
    },
  }),
  { name: DISPLAY_NAME },
)

const TrackingCard = props => {
  const classes = useStyles()
  const { asset, startTime, endTime } = props
  const {
    accumulatedThroughputStart,
    accumulatedThroughput,
    annualCycleCount,
    collated,
    warranty,
    dispatchGetAccumulatedThroughputStart,
    dispatchGetAccumulatedThroughput,
    dispatchGetAnnualCycleCount,
    dispatchGetLastWarrantyContracts,
    dispatchGetRealTelemetryForCalc,
  } = props

  const assetId = _.get(asset, 'assetId')
  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const timezone = _.get(asset, 'market.data.timezone')
  moment.tz.setDefault(timezone)
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'
  const isLive = _.get(asset, 'data.is_live', false)
  const [soeForCalculation, setSoeForCalculation] = useState([])

  useEffect(() => {
    if (!_.isNil(assetId) && !_.isNil(endTime) && !_.isNil(startTime)) {
      dispatchGetLastWarrantyContracts(assetId, startTime)
      if (isBattery && isLive) {
        const tag = BATTERY_4SEC_TAGS.LIVE.POWER_TAG
        const intervalTimeConfig = new IntervalTimeConfig(
          moment(startTime),
          moment(endTime),
          getForecastStartTime(asset, moment(startTime), moment()),
          null,
          0,
          300,
        )
        const intervalConfig = new IntervalConfig(`${assetId}_${tag}`, tag, intervalTimeConfig)
        dispatchGetRealTelemetryForCalc(
          assetId,
          tag,
          moment(startTime),
          moment(endTime),
          intervalConfig,
          intervalTimeConfig
        ).then(telemetryResp => {
          // if current day or future, we need to use telemetry data combine with forecast data for this calculation
          const dischargingEfficiency = _.get(asset, 'data.discharging_efficiency')
          const forecastData = getChartData(collated, `${assetId}_${asset.data.tag_prefix + powerTag}`, startTime)
          // num intervals is 1 for forecast data
          const forecastVals = _.get(forecastData, 'data.values[0]', []).map(value => {
            if (!_.isNil(value) && !_.isNil(dischargingEfficiency)) {
              return (value / dischargingEfficiency) / 12.0
            } else {
              return value
            }
          })
          const numIntervals = _.get(telemetryResp, 'payload[0].data.numberOfIntervals')
          const telemetryVals = telemetryResp.payload.map(obj => {
            const value = obj.value
            if (!_.isNil(value) && !_.isNil(numIntervals) && !_.isNil(dischargingEfficiency)) {
              const batteryKw = value / numIntervals
              return (batteryKw / dischargingEfficiency) / 12.0
            } else {
              return value
            }
          })
          const forecastStartTime = moment(_.get(forecastData, 'data.forecastStartTime'))
          const forecastStartIndex = getIndex(
            forecastStartTime,
            moment(startTime),
            moment(startTime).add(1, 'days'),
            INTERVAL_SIZE_MINS,
          )
          setSoeForCalculation(() => {
            if (forecastStartIndex !== -1) {
              const tempTelemetryVals = telemetryVals.slice(0, forecastStartIndex)
              const tempForecastVals = forecastVals.slice(forecastStartIndex)
              return [...tempTelemetryVals, ...tempForecastVals]
            } else {
              return telemetryVals
            }
          })
        })
      } else {
        const chartData = getChartData(collated, `${assetId}_${asset.data.tag_prefix + soeTag}`, startTime)
        setSoeForCalculation(_.get(chartData, 'data.values[0]', []))
      }
    }
  }, [
    assetId,
    asset,
    startTime,
    dispatchGetLastWarrantyContracts,
    endTime,
    dispatchGetRealTelemetryForCalc,
    collated,
    isBattery,
    isLive
  ])

  useEffect(() => {
    if (!_.isNil(warranty) && !_.isEmpty(warranty) && !_.isNil(endTime)) {
      const startOfWarrantyYear = moment(_.get(warranty, 'data.start_of_warranty_year', null))
      const finalEndOfWarranty = moment(_.get(warranty, 'endTime', null))
      const startOfCurrentWarrantyYear = getStartOfCurrentWarrantyYear(
        startTime,
        startOfWarrantyYear,
        finalEndOfWarranty,
      )
      dispatchGetAccumulatedThroughputStart(assetId, startOfCurrentWarrantyYear)
      dispatchGetAccumulatedThroughput(assetId, endTime)
      dispatchGetAnnualCycleCount(assetId, endTime)
    }
  }, [
    warranty,
    startTime,
    endTime,
    dispatchGetAccumulatedThroughput,
    dispatchGetAccumulatedThroughputStart,
    assetId,
    dispatchGetAnnualCycleCount,
  ])

  const maxCyclesPerDay = _.get(warranty, 'data.daily_cycle_limit')
  const maxCyclesPerYear = _.get(warranty, 'data.annual_cycle_limit')
  const actualCyclesPerYearTime = _.get(annualCycleCount, 'payload.time', null)
  const actualThroughput = _.get(accumulatedThroughput, 'payload.value')
  const actualThroughputStart = _.get(accumulatedThroughputStart, 'payload.value')
  const currentAnnualActualThroughput =
    !_.isNil(actualThroughput) && !_.isNil(actualThroughputStart) ? actualThroughput - actualThroughputStart : null
  const actualThroughputTime = _.get(accumulatedThroughput, 'payload.time', null)
  // Calculate charge and discharge
  const powerChartData = getChartData(collated, `${assetId}_${asset.data.tag_prefix + powerTag}`, startTime)
  const powerStartTime = moment(_.get(powerChartData, 'data.startTime'))
  const forecastStartTime = moment(_.get(powerChartData, 'data.forecastStartTime'))

  const currentSoeValues = soeForCalculation

  const isNullSoeValues = currentSoeValues.every(element => element === null)
  const numBatteries = _.get(asset, 'data.number_batteries')
  const batteryCapacity = _.get(asset, 'data.battery_capacity_kwh')
  const totalCapacityKwh = numBatteries * batteryCapacity
  const actualCyclesPerYearCalculated =
    !_.isNil(currentAnnualActualThroughput) && !_.isNil(totalCapacityKwh)
      ? currentAnnualActualThroughput / totalCapacityKwh
      : null
  const forecastStartIntervalIndex = (forecastStartTime - powerStartTime) / FIVE_MINUTE_DURATION

  let forecastedDischargeKwhFromSoe
  let actualChargedKwhFromSoe
  if (isLive && isBattery) {
    forecastedDischargeKwhFromSoe = _.sum(currentSoeValues)
    actualChargedKwhFromSoe = _.sum(currentSoeValues.slice(0, forecastStartIntervalIndex))
  } else {
    forecastedDischargeKwhFromSoe = sumDirectionalDifference(currentSoeValues, 'desc')
    actualChargedKwhFromSoe = sumDirectionalDifference(currentSoeValues.slice(0, forecastStartIntervalIndex), 'asc')
  }

  const startOfWarrantyYear = moment(_.get(warranty, 'data.start_of_warranty_year', null))
  const finalEndOfWarranty = moment(_.get(warranty, 'endTime', null))
  const startOfCurrentWarrantyYear = getStartOfCurrentWarrantyYear(startTime, startOfWarrantyYear, finalEndOfWarranty)
  const endOfCurrentWarrantyYear = moment(startOfCurrentWarrantyYear).add(1, 'year')

  const isCurrentActualCyclesPerYear =
    !_.isNil(actualCyclesPerYearTime) &&
  moment(actualCyclesPerYearTime).isBetween(startOfWarrantyYear, finalEndOfWarranty, undefined, '[]')
  const isCurrentActualThroughput =
    !_.isNil(actualThroughputTime) &&
    moment(actualThroughputTime).isBetween(startOfWarrantyYear, finalEndOfWarranty, undefined, '[]')

  const hasValidWarranty = moment(startTime).isBetween(startOfWarrantyYear, finalEndOfWarranty, undefined, '[]')
  // dailyDetails
  const startOfTradingDay = `${moment(startTime).format(dateTimeFormat)}`
  const dailyCyclesDenominator = _.isNumber(maxCyclesPerDay) && hasValidWarranty ? `/ ${maxCyclesPerDay}` : `/ N/A`
  const forecastedDailyCyclesFromSoe = !isNullSoeValues
    ? `${formatValue(forecastedDischargeKwhFromSoe / totalCapacityKwh)}`
    : 'N/A'
  const dailyChargeMwhFromSoe = !isNullSoeValues ? `${formatValue(convertkWToMW(actualChargedKwhFromSoe))} MWh` : 'N/A'
  const forecastedDailyDischargeMwhFromSoe = !isNullSoeValues
    ? `${formatValue(convertkWToMW(forecastedDischargeKwhFromSoe))} MWh`
    : 'N/A'

  // annualDetails
  const startOfWarrantyYearString = moment(startOfCurrentWarrantyYear).isValid()
    ? `${startOfCurrentWarrantyYear.format(dateTimeFormat)}`
    : 'N/A'

  const actualCyclesPerYearString =
    _.isNumber(actualCyclesPerYearCalculated) && isCurrentActualCyclesPerYear
      ? formatValue(actualCyclesPerYearCalculated)
      : '-'
  const annualCycleCountNumerator = hasValidWarranty ? actualCyclesPerYearString : 'N/A'
  const annualCycleCountDenominator =
    _.isNumber(maxCyclesPerYear) && hasValidWarranty ? `/ ${maxCyclesPerYear}` : `/ N/A`
  const yearCompletedPercent = hasValidWarranty
    ? percentOfYearRangeComplete(startTime, startOfCurrentWarrantyYear, endOfCurrentWarrantyYear)
    : 'N/A'
  const annualCyclesUsedPercent =
    _.isNumber(actualCyclesPerYearCalculated) && _.isNumber(maxCyclesPerYear) && hasValidWarranty
      ? formatPercentage(actualCyclesPerYearCalculated / maxCyclesPerYear, 1)
      : 'N/A'
  const actualThroughputString =
    _.isNumber(currentAnnualActualThroughput) && isCurrentActualThroughput
      ? `${formatValue(convertkWToMW(currentAnnualActualThroughput))} MWh`
      : '-'
  const annualDischargeInMwh = hasValidWarranty ? actualThroughputString : 'N/A'

  // Structure the data for display
  const subheader = getCardSubtitleByDate(startTime, endTime, moment(), timezone, marketStartHour)

  const dailyDetails = [
    {
      primary: startOfTradingDay,
      secondary: 'START OF TRADING DAY',
    },
    {
      dynamic: forecastedDailyCyclesFromSoe,
      primary: dailyCyclesDenominator,
      secondary: 'FORECASTED DAILY CYCLES USED / MAX',
    },
    {
      dynamic: forecastedDailyCyclesFromSoe,
      secondary: 'FORECASTED DAILY CYCLES',
    },
    {
      primary: dailyChargeMwhFromSoe,
      secondary: 'DAILY CHARGE',
    },
    {
      dynamic: forecastedDailyDischargeMwhFromSoe,
      secondary: 'FORECASTED DAILY DISCHARGE',
    },
  ]

  const annualDetails = [
    {
      primary: startOfWarrantyYearString,
      secondary: 'START OF WARRANTY YEAR',
    },
    {
      dynamic: annualCycleCountNumerator,
      primary: annualCycleCountDenominator,
      secondary: 'ANNUAL CYCLE COUNT',
    },
    {
      primary: yearCompletedPercent,
      secondary: 'WARRANTY YEAR COMPLETED',
    },
    {
      dynamic: annualCyclesUsedPercent,
      secondary: 'PERCENTAGE OF CYCLES USED TO DATE',
    },
    {
      primary: annualDischargeInMwh,
      secondary: 'ANNUAL DISCHARGE',
    },
  ]

  return (
    <Card classes={{ content: classes.tableCardContent }} title={TITLE} subheader={subheader} className={classes.card}>
      <Grid container direction="row">
        <Box clone>
          <Grid item container xs={12}>
            <DetailLargeList listItems={dailyDetails} />
          </Grid>
        </Box>
        <Box clone>
          <Grid item container xs={12}>
            <DetailLargeList listItems={annualDetails} />
          </Grid>
        </Box>
      </Grid>
    </Card>
  )
}

const getStartOfCurrentWarrantyYear = (userSelectedTime, startOfWarrantyYear, endOfWarrantyYear) => {
  const userSelectedTimeMoment = moment(userSelectedTime)
  const startOfWarrantyYearMoment = moment(startOfWarrantyYear)
  const endOfWarrantyYearMoment = moment(endOfWarrantyYear)
  if (
    userSelectedTimeMoment.isBefore(startOfWarrantyYearMoment) ||
    userSelectedTimeMoment.isAfter(endOfWarrantyYearMoment)
  ) {
    // If the user selected date is before start of warranty or is after end of warranty, displayed value for start of warranty year parameter = N/A
    return null
  } else if (
    userSelectedTimeMoment.isSameOrAfter(moment(startOfWarrantyYearMoment)) &&
    userSelectedTimeMoment.isBefore(moment(startOfWarrantyYearMoment).add(1, 'year'))
  ) {
    // If the user selected date year is within 1 year of the start of warranty year, displayed value for start of warranty year parameter = start of warranty year
    return startOfWarrantyYearMoment
  } else if (
    userSelectedTimeMoment.isSameOrAfter(moment(startOfWarrantyYearMoment).add(1, 'year')) &&
    userSelectedTimeMoment.isSameOrBefore(endOfWarrantyYearMoment)
  ) {
    // If the user selected date year is beyond 1 year from the start of warranty year AND before end of warranty
    // displayed value for start of warranty year parameter = start of warranty year + x Years
    const yearDiff = userSelectedTimeMoment.diff(startOfWarrantyYearMoment, 'years')
    return moment(startOfWarrantyYearMoment).add(yearDiff, 'year')
  }
  return startOfWarrantyYearMoment
}

TrackingCard.displayName = DISPLAY_NAME

const mapStateToProps = () => {
  return state => ({
    accumulatedThroughputStart: _.get(state, 'assetOperation.accumulatedThroughputStart'),
    accumulatedThroughput: _.get(state, 'assetOperation.accumulatedThroughput'),
    annualCycleCount: _.get(state, 'assetOperation.annualCycleCount'),
    assetOperation: _.get(state, 'assetOperation'),
    collated: _.get(state, 'collated'),
    warranty: assetOperationSelectors.selectLastWarrantyContract(state),
  })
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchGetAccumulatedThroughputStart: (assetId, asOf) =>
      dispatch(accumulatedThroughputResourceStart.get(assetId, asOf)),
    dispatchGetAccumulatedThroughput: (assetId, asOf) => dispatch(accumulatedThroughputResource.get(assetId, asOf)),
    dispatchGetAnnualCycleCount: (assetId, asOf) => dispatch(annualCycleCountResource.get(assetId, asOf)),
    dispatchGetLastWarrantyContracts: (assetId, startTime) => dispatch(getLastWarrantyContracts(assetId, startTime)),
    dispatchGetRealTelemetryForCalc: (assetId, tag, startTime, endTime, intervalConfig, intervalTimeConfig) =>
      dispatch(
        getAggregatedIntervals(
          assetId,
          tag,
          startTime,
          endTime,
          300,
          'sum',
          'positive',
          intervalConfig,
          intervalTimeConfig,
          true,
          GET_SOE_CALC_INTERVAL_TYPES,
        ),
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingCard)
