import _ from 'lodash'
import moment from 'moment-timezone'
import { ONE_MINUTE_DURATION, MINUTES_IN_HOUR, TRADING_DAY_START_HOUR } from '../utility/constants'
import { dayOfWeekFormat } from '../utility/formats'
import { dateTimeFormatWithSeconds, getTimeAtStartOfNextInterval } from '../utility/utility'
import * as defaults from './utility/defaults'
import { formatAndPadValue, formatValue, getXAxisBreakpoint, getYAxisBreakpoint } from './utility/breakpoints'

export const formatXAxis = ({ range, timezone, marketStartHour }) => {
  if (_.isNil(range)) {
    return { ...defaults.xAxis }
  }
  const breakpoint = getXAxisBreakpoint(range)
  if (_.isNil(breakpoint)) {
    return { ...defaults.xAxis }
  }

  const timeFormatter = _.get(breakpoint, 'timeFormatter')
  return {
    ...defaults.xAxis,
    interval: _.get(breakpoint, 'tickInterval'),
    labelFormatter: timeFormatter(timezone, marketStartHour),
    viewportMinimum: range.start,
    viewportMaximum: range.end,
    margin: 10,
  }
}

export const formatX2Axis = ({ range, nowFloorDuration = ONE_MINUTE_DURATION }) => {
  if (_.isNil(range)) {
    return [...defaults.x2Axis]
  }
  const startValue = getTimeAtStartOfNextInterval(moment(), nowFloorDuration.asMinutes()).unix()
  return [
    {
      ...defaults.x2Axis,
      viewportMinimum: range.start,
      viewportMaximum: range.end,
      stripLines: [
        {
          ...defaults.stripLines,
          startValue,
          endValue: range.end,
          color: 'white',
          opacity: 0.04,
        },
      ],
    },
  ]
}

const getBounds = (series = []) => {
  const visibleYValues = series
    .filter(ser => _.get(ser, 'visible'))
    .flatMap(ser => _.get(ser, 'dataPoints'), [])
    .flatMap(point => (_.isArray((point || {}).y) ? (point || {}).y : [(point || {}).y]))
  const bounds = {
    minimum: _.min(visibleYValues),
    maximum: _.max(visibleYValues),
  }
  return bounds
}

export const formatYAxis = ({ series, height, callback = () => ({}) }) => {
  const bounds = getBounds(series)
  const breakpoint = getYAxisBreakpoint(bounds, height)
  const interval = breakpoint ? breakpoint.tickInterval : null

  return {
    ...defaults.yAxis,
    interval,
    bounds,
  }
}

const formatDefaultTooltip = timezone => e => {
  if (_.isNil(timezone)) {
    console.debug(`Variable 'timezone' is null or undefined in formatDefaultTooltip()`)
  }
  const timestamp = e.entries[0].dataPoint.x
  const localTime = moment.unix(timestamp).tz(timezone)
  const tradingDateTime =
    localTime.hours() * MINUTES_IN_HOUR + localTime.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
      ? moment(localTime).subtract(1, 'days')
      : localTime
  const formattedLocalTime = tradingDateTime.format(dateTimeFormatWithSeconds)
  const titleText = `<h3 style="color: white; margin: 0;">${formattedLocalTime}</h3>`
  const dayOfWeek = localTime.format(dayOfWeekFormat)
  const subtitleText = `<div style='color: white; margin-bottom: 5px'>${dayOfWeek}</div>`
  const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
  const seriesText = visibleSeries.reduce((output, entry) => {
    if (entry.dataSeries.type.includes('range')) {
      return output
    } else {
      return getToolTipLine(entry, entry.dataSeries.name, entry.dataPoint.y) + output
    }
  }, '')
  return titleText + subtitleText + seriesText
}

const getToolTipLine = (entry, name, value) => {
  return _.isNil(value)
    ? ''
    : `<div><span style='color: rgba(255,255,255,0.5)'>${name}:</span> <span style='color: ${
        entry.dataSeries.color
      };'>${(entry.dataSeries.axisY.options.formatValue || formatValue({}))(value)}</span></div>`
}

export const formatTooltip = ({ timezone }) => {
  return {
    ...defaults.tooltip,
    contentFormatter: formatDefaultTooltip(timezone),
  }
}

export const formatDemandYAxis = ({ series, height }) => {
  const bounds = getBounds(series)
  const breakpoint = getYAxisBreakpoint(bounds, height)
  const interval = breakpoint ? breakpoint.tickInterval : null
  return {
    ...defaults.yAxis,
    interval,
    minimum: bounds.minimum - bounds.minimum * 0.1,
    maximum: bounds.maximum + bounds.maximum * 0.1,
    labelFormatter: formatAndPadValue({ prefix: ' ', suffix: ' MW ', maxLength: 12 }),
    formatValue: formatValue({ suffix: ' MW ', prefix: ' ' }),
  }
}

export const formatPriceYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '/MWh', maxLength: 10 }),
    formatValue: formatValue({ suffix: '/MWh', prefix: '$' }),
  }
}

export const formatAwardYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ suffix: ' MW', maxLength: 10 }),
    formatValue: formatValue({ suffix: ' MW' }),
  }
}

export const formatSettlementYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', maxLength: 10 }),
    formatValue: formatValue({ prefix: '$' }),
  }
}

export const getEnergyAwards = (price, bids, priceBands) => {
  if (_.isNil(price)) {
    return 0
  }
  let index = findLastIndex(
    priceBands,
    (priceBand, index) => !_.isNil(priceBand) && priceBand >= price && bids[index] < 0,
    (prevIndex, nextIndex) => bids[nextIndex] < bids[prevIndex],
  )
  if (index === -1) {
    index = findLastIndex(
      priceBands,
      (priceBand, index) => !_.isNil(priceBand) && priceBand <= price && bids[index] > 0,
      (prevIndex, nextIndex) => bids[nextIndex] > bids[prevIndex],
    )
  }
  if (index === -1) {
    return 0
  }
  return bids[index]
}

const findLastIndex = (array, predicate, shouldReplace) => {
  let lastIndex = -1
  // eslint-disable-next-line no-unused-vars
  for (const [index, entry] of array.entries()) {
    if (predicate(entry, index) && (lastIndex === -1 || shouldReplace(lastIndex, index))) {
      lastIndex = index
    }
  }
  return lastIndex
}
