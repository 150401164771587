import _ from 'lodash'
import moment from 'moment-timezone'
import * as defaults from '../../../graphs/utility/defaults'
import {
  DATE_FORMAT_DAY_OF_WEEK,
  DATE_FORMAT_FULL_WITH_SEC,
  MINUTES_IN_HOUR,
  TRADING_DAY_START_HOUR,
} from '../../../utility/constants'
import { formatYAxis } from '../../../graphs/common'
import { formatAndPadValue, formatValue } from '../../../graphs/utility/breakpoints'
import { setRgbaValue } from '../../../utility/color'
const CanvasJS = require('../../../assets/scripts/canvasjs.min.js')
const { formatNumber } = CanvasJS.Chart ? CanvasJS : window.CanvasJS

export const formatTooltip =
  (forecastStart, products, actualTypes, forecastTypes, colors, actualSeriesName) =>
  ({ timezone }) => {
    return {
      ...defaults.tooltip,
      contentFormatter: priceForecastContentFormatter(
        timezone,
        forecastStart,
        products,
        actualTypes,
        forecastTypes,
        colors,
        actualSeriesName,
      ),
    }
  }

export const priceForecastContentFormatter =
  (timezone, forecastStart, products, actualTypes, forecastTypes, colors, actualSeriesName) => e => {
    const time = moment.unix(e.entries[0].dataPoint.x).tz(timezone)
    const tradingDateTime =
      time.hours() * MINUTES_IN_HOUR + time.minutes() < TRADING_DAY_START_HOUR * MINUTES_IN_HOUR
        ? moment(time).subtract(1, 'days')
        : time
    const title = `<h3 style="color: white; margin: 0;">${tradingDateTime.format(DATE_FORMAT_FULL_WITH_SEC)}</h3>`
    const subtitle = `<div style='color: white; margin-top: 0; margin-bottom: 5px'>${time.format(
      DATE_FORMAT_DAY_OF_WEEK,
    )}</div>`

    const types = !time.isSameOrAfter(forecastStart) ? actualTypes : forecastTypes

    const productValues = {}
    products.forEach(product => (productValues[product] = types.map(() => null)))

    let seriesOutput = ''
    const visibleSeries = e.entries.filter(entry => entry.dataSeries.visible)
    visibleSeries.forEach(entry => {
      const seriesName = entry.dataSeries.name
      const displayName = entry.dataSeries.name.split(' ')[0]

      // do not use table tooltip, if only one data point
      if (types.length === 1) {
        const formattedValue = _.isFinite(entry.dataPoint.y)
          ? formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
          : 'No Data'
        seriesOutput =
          `<div><span style='color: #808697'>${displayName}:</span> <span style='color: ${entry.dataSeries.color};'>${formattedValue}</span></div>` +
          seriesOutput

        // if multiple datapointss, add it to productValues, which will be used to create tooltip table
      } else {
        const formattedValue =
          _.isFinite(entry.dataPoint.y) || !_.isNil(entry.dataPoint.y)
            ? formatNumber(entry.dataPoint.y, entry.dataSeries.yValueFormatString)
            : 'No Data'
        const typeIndex = types.findIndex(type =>
          type === 'ACTUAL' ? seriesName.includes(actualSeriesName) : seriesName.includes(type),
        )
        productValues[displayName][typeIndex] = formattedValue
      }
    })

    // use productValues to create table if needed
    if (seriesOutput === '') {
      let table = `<table><thead><tr style='color: #808697'><th style="padding-right: 4px"> </th>`
      types.forEach(type => (table += `<th style="padding-right: 4px; font-weight: normal">${type}</th>`))
      table += `</tr></thead><tbody>`

      products.forEach(product => {
        if (!productValues[product].every(value => _.isNil(value))) {
          table += `<tr style='color: ${
            colors[product.toUpperCase()]
          }'><th style='color: #808697; padding-right: 4px; font-weight: normal'>${product}</th>`
          productValues[product].forEach((value, idx) => {
            const color =
              _.get(types, idx) === 'INTERVENTION'
                ? setRgbaValue(colors[product.toUpperCase()], 'a', 0.5)
                : colors[product.toUpperCase()]
            table += `<th style='color: ${color}; padding-right: 4px; font-weight: normal'>${
              _.isNil(value) ? 'No data' : value
            }</th>`
          })
          table += '</tr>'
        }
      })

      seriesOutput = table + '</tbody></table>'
    }
    return `${title}${subtitle}${seriesOutput}`
  }

export const formatX2Axis =
  startValue =>
  ({ range }) => {
    return [
      {
        ...defaults.x2Axis,
        viewportMinimum: range.start,
        viewportMaximum: range.end,
        stripLines: [
          {
            ...defaults.stripLines,
            startValue,
            endValue: range.end,
            color: 'white',
            opacity: 0.04,
          },
        ],
      },
    ]
  }

export const formatMarketPricesSeries =
  colors =>
  ({ data, legend }) => {
    for (const line in data) {
      if (line.includes('(PRE-DISPATCH)')) {
        data[line].lineDashType = 'dash'
      }

      data[line].yValueFormatString = '$#,##0.##'

      // TODO: get product id rather than parsing product name.
      const group = String(_.first(line.split(' '))).toUpperCase()
      data[line].visible = _.get(legend, group, true)

      if (line.includes('(INTERVENTION)')) {
        data[line].color = setRgbaValue(colors[group], 'a', 0.5)
      }
    }
    return _.values(data)
  }

export const formatEnablementsSeries = ({ data, legend }) => {
  for (const line in data) {
    data[line].yValueFormatString = '#,##0.## MW'
    const group = String(_.first(line.split(' '))).toUpperCase()
    data[line].visible = _.get(legend, group, true)
  }
  return _.reverse(_.values(data))
}

export const formatSettlementsSeries = ({ data, legend }) => {
  for (const line in data) {
    data[line].visible = _.get(legend, line, true)
  }
  return _.reverse(_.values(data))
}

export const formatMarketPricesYAxis = (marketPriceMaximum, marketPriceMinimum) => args => {
  const { bounds, ...formattedYAxis } = formatYAxis(args)
  const max = Math.max(marketPriceMinimum, bounds.maximum)
  const min = Math.min(marketPriceMaximum, bounds.minimum)

  const delta = max - min
  const paddedMax = Math.ceil(max + Math.abs(delta * 0.05))
  const vMax = paddedMax < 10 ? null : paddedMax
  const vMin = Math.floor(min - Math.abs(delta * 0.05))

  return {
    ...formattedYAxis,
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '/MWh', maxLength: 12 }),
    formatValue: formatValue({ suffix: '/MWh', prefix: '$' }),
    viewportMaximum: vMax,
    viewportMinimum: vMin,
  }
}

export const formatSettlementsYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '$', suffix: '', maxLength: 12 }),
    formatValue: formatValue({ suffix: '', prefix: '$' }),
  }
}

export const formatEnablementsYAxis = args => {
  return {
    ...formatYAxis(args),
    labelFormatter: formatAndPadValue({ prefix: '', suffix: ' MW', maxLength: 12 }),
    formatValue: formatValue({ suffix: ' MW', prefix: '' }),
  }
}
