import { combineReducers } from 'redux'
import { ResourceAction } from '../api'
import { getIntervalsNew, intervalStateCreator } from './interval'

// UI actions
const SELECT_PRODUCT_TYPE = 'SELECT_FORECAST_PRODUCT_TYPE'
const SELECT_PRODUCT_NAME = 'SELECT_FORECAST_PRODUCT_NAME'

export const selectProductType = productType => ({
  type: SELECT_PRODUCT_TYPE,
  payload: productType,
})

export const selectProductName = productName => ({
  type: SELECT_PRODUCT_NAME,
  payload: productName,
})

// API Actions
const assetProductAction = new ResourceAction({
  type: 'FORECAST_PRODUCT_ASSET_INTERVAL',
  shouldCache: true,
  config: (intervalConfig, intervalTimeConfig) => {
    return {
      endpoint: `/market/frontend/asset/${intervalConfig.assetId}/product/${
        intervalConfig.productId
      }/interval/forecast?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&tag=${intervalConfig.tag}&horizon=${
        intervalTimeConfig.horizon
      }`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

const pricesForecastAction = new ResourceAction({
  type: 'FORECAST_REGION_PRODUCT_INTERVAL',
  shouldCache: true,
  config: (intervalConfig, intervalTimeConfig) => {
    return {
      endpoint: `/market/frontend/product/${intervalConfig.productId}/region/${
        intervalConfig.regionId
      }/interval/forecast?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&horizon=${intervalTimeConfig.horizon}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

const priceForecastActionV2 = new ResourceAction({
  type: 'FORECAST_REGION_PRODUCT_INTERVAL_V2',
  shouldCache: true,
  config: (intervalConfig, intervalTimeConfig) => {
    return {
      endpoint: `/market/frontend/asset/${intervalConfig.assetId}/product/${intervalConfig.productId}/interval/forecast/all?startTime=${encodeURIComponent(
        intervalTimeConfig.startTime.toISOString(),
      )}&endTime=${encodeURIComponent(intervalTimeConfig.endTime.toISOString())}&horizon=${intervalTimeConfig.horizon}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

const priceForecastPerformanceAction = new ResourceAction({
  type: 'PRICE_FORECAST_PERFORMANCE',
  shouldCache: true,
  config: (intervalConfig, intervalTimeConfig) => {
    const horizonsQuery = intervalConfig.meta.horizons.reduce((acc, horizon) => acc + '&horizon=' + horizon, '')
    const tags = intervalConfig.tag.split('_')
    return {
      endpoint: `/market/frontend/product/${intervalConfig.productId}/region/${intervalConfig.regionId}/interval/forecast/performance/${tags[2]}?yearMonth=${intervalTimeConfig.yearMonth}${horizonsQuery}&forecastIntervalType=${tags[0]}&forecastPerformanceType=${tags[1]}`,
      meta: {
        intervalConfig,
        intervalTimeConfig,
      },
    }
  },
})

// UI reducer
// const view = (
//   state = {
//     selectedProductType: null,
//     selectedProductName: null,
//     selectedDate: null,
//   },
//   action,
// ) => {
//   switch (action.type) {
//     case SELECT_PRODUCT_TYPE:
//       return {
//         ...state,
//         selectedProductType: action.payload,
//       }
//     case SELECT_PRODUCT_NAME:
//       return {
//         ...state,
//         selectedProductName: action.payload,
//       }
//     default:
//       return state
//   }
// }

// API reducers
// export const getAssetProductInterval = getIntervals(assetProductAction, 'forecast', '00:05:00', '01:00:00')
// export const getPriceForecasts = getIntervals(pricesForecastAction, 'forecast', '00:05:00', '01:00:00')
// export const getPricePerformance = getIntervals(priceForecastPerformanceAction, 'forecast', '06:00:00', '06:00:00')

export const getAssetProductInterval = getIntervalsNew(assetProductAction, 'forecast', '00:05:00', '01:00:00')
export const getPriceForecastsNew = getIntervalsNew(pricesForecastAction, 'forecast', '00:05:00', '01:00:00')
export const getPricePerformance = getIntervalsNew(priceForecastPerformanceAction, 'forecast', '06:00:00', '06:00:00')
export const getPriceForecastsAll = getIntervalsNew(priceForecastActionV2, 'forecast', '00:05:00', '01:00:00')

const actions = [assetProductAction, pricesForecastAction, priceForecastPerformanceAction, priceForecastActionV2]
const intervals = intervalStateCreator(actions)

export default combineReducers({
  // view,
  intervals,
})
