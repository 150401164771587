import { API } from '../../api'
import types from './types'

export const getMarketByName = (marketName = 'AEMO', shouldCache = true) => dispatch => {
  const options = {
    request: types.GLOBAL_APP_MARKETS_REQUEST,
    success: types.GLOBAL_APP_MARKETS_SUCCESS,
    error: types.GLOBAL_APP_MARKETS_ERROR,
    meta: {},
  }
  const action = API.marketFrontendMarkets.marketName(marketName, options, shouldCache)
  return dispatch(action)
}

const operations = {
  getMarketByName,
}

export default operations
