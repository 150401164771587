import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { configurationsResource, mlfRangeResource } from '../../redux/features/setting'
import { getTradingStart, KW_TO_MW } from '../../utility/utility'
import { DATE_FORMAT_DAY, TIME_FORMAT } from '../../utility/constants'
import Card from '../Card'

const useStyles = makeStyles(theme => ({
  root: {},
  list: {
    marginTop: 0,
    paddingLeft: theme.spacing(2),
    cursor: 'default',
    listStyle: 'none',

    '& li::before': {
      color: '#808697',
      content: `"•"`,
      display: 'inline-block',
      fontWeight: 'bold',
      width: '1em',
      marginLeft: '-1em',
    },
  },
  tooltip: {
    maxWidth: theme.spacing(44),
  },
}))

const ConfigurationLog = props => {
  const { asset, configurations, isLoading, onInitialize, mlfRange } = props
  const classes = useStyles()
  const registrations = configurations.concat(mlfRange).sort((x, y) => {
    return moment(y.startTime) - moment(x.startTime)
  })

  const assetId = _.get(asset, 'assetId')
  const productTypes = _.get(asset, 'productTypes', [])
  const title = 'Market Registration Adjustment Log'

  const getTitle = registration => {
    const timezone = asset.market.data.timezone
    const userName = _.get(registration, 'createdBy.name', '')
    let tradingDate = ''
    let time = ''
    if (registration.tag === 'marginal_loss_factor_setting') {
      const activeTime = moment(registration.startTime)
      tradingDate = getTradingStart(_.get(asset, 'market'), activeTime).format(DATE_FORMAT_DAY)
      time = activeTime.tz(timezone).format(TIME_FORMAT)
    } else {
      tradingDate = getTradingStart(_.get(asset, 'market'), registration.startTime).format(DATE_FORMAT_DAY)
      time = moment(registration.startTime).tz(timezone).format(TIME_FORMAT)
    }
    return `${tradingDate} ${time} - ${userName}`
  }

  const getDataTip = (registrations, index) => {
    if (registrations[index].tag === 'configuration') {
      let output = ''
      const prevRegistration =
        index === registrations.length - 1
          ? {}
          : _.find(registrations.slice(index + 1, registrations.length), reg => reg.tag === 'configuration')
      const registration = registrations[index]
      let productChange = ''
      asset.productNames.forEach(productName => {
        asset.productTypes.forEach(productType => {
          const product = `${productName}_${productType}`
          const productSetting = _.get(registration, `data.${productName}_${productType}`)
          const prevProductSetting = _.get(prevRegistration, `data.${productName}_${productType}`)
          if (productSetting !== prevProductSetting) {
            const productDisplayName = asset.market.data.product_display_names[productName]
            const productTypeDisplayName = asset.market.data.product_type_display_names[productType]
            productChange += `${productDisplayName} (${productTypeDisplayName}): ${
              registration.data[product] * KW_TO_MW
            } | `
          }
        })
      })

      if (productChange !== '') {
        output += `Registered Capacity (MW): ${productChange.slice(0, productChange.length - 2)}`
      }

      let fcasChange = ''
      const attributes = ['enablement_min', 'enablement_max', 'breakpoint_low', 'breakpoint_high']
      asset.market.data.product_groups_order.slice(1).forEach(group => {
        asset.productTypes.forEach(productType => {
          attributes.forEach(attr => {
            const fcas = _.get(registration, `data.${group}.${productType}.${attr}`)
            const prevFcas = _.get(prevRegistration, `data.${group}.${productType}.${attr}`)
            if (fcas !== prevFcas) {
              const attrName = attr
                .split('_')
                .map(val => val.replace(val[0], val[0].toUpperCase()))
                .join('-')
              fcasChange += `${group} - ${productType} - ${attrName}: ${
                registration.data[group][productType][attr] * KW_TO_MW
              } | `
            }
          })
        })
      })

      if (fcasChange !== '') {
        output += `FCAS Settings (MW): ${fcasChange.slice(0, fcasChange.length - 2)}`
      }

      let rocChange = ''
      const directions = ['up', 'down']
      const types = ['min', 'max']
      directions.forEach(direction => {
        types.forEach(type => {
          const group = asset.market.data.product_groups_order[0]
          const roc = _.get(registration, `data.${group}.${direction}.${type}`)
          const prevRoc = _.get(prevRegistration, `data.${group}.${direction}.${type}`)
          if (roc !== prevRoc) {
            const directionText = direction.replace(direction[0], direction[0].toUpperCase())
            const typeText = type.replace(type[0], type[0].toUpperCase())
            rocChange += `${directionText} - ${typeText}: ${registration.data[group][direction][type] * KW_TO_MW} | `
          }
        })
      })
      if (rocChange !== '') {
        output += `ROC Settings (MW/min): ${rocChange.slice(0, rocChange.length - 2)}`
      }
      return output
    } else if (registrations[index].tag === 'marginal_loss_factor_setting') {
      const genValue = _.get(registrations[index].data, 'marginal_loss_factor_gen')
      const loadValue = _.get(registrations[index].data, 'marginal_loss_factor_load')
      const mlfValue = _.get(registrations[index].data, 'marginal_loss_factor')
      const isBESSMLFChange = _.isNil(mlfValue) && (!_.isNil(genValue) || !_.isNil(loadValue))
      if (isBESSMLFChange) {
        return `MLF GEN: ${genValue} | MLF LOAD: ${loadValue}`
      } else {
        return `MLF: ${mlfValue}`
      }
    }
  }

  const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
  useEffect(() => {
    if (!_.isNil(assetId) && !_.isNil(commercialOnlineDate)) {
      onInitialize(assetId, commercialOnlineDate)
    }
  }, [commercialOnlineDate, assetId, onInitialize])

  return (
    <Card title={title} titleTypographyProps={{ variant: 'h3' }} inProgres={isLoading}>
      <Box overflow="auto" height={productTypes.length === 1 ? '276px' : '311px'}>
        {registrations.length > 0 ? (
          <ul className={classes.list}>
            {registrations.map((registration, index) => {
              const title = getTitle(registration)
              return (
                <li key={index}>
                  <Tooltip
                    title={getDataTip(registrations, index)}
                    placement="right-start"
                    key={index}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Typography variant="body1" component="span">
                      {title}
                    </Typography>
                  </Tooltip>
                </li>
              )
            })}
          </ul>
        ) : (
          <p>None</p>
        )}
      </Box>
    </Card>
  )
}

const mapStateToProps = state => {
  const configurationsData = _.get(state, 'setting.configurations')
  const mlfData = _.get(state, 'setting.mlfRange')

  return {
    isLoading: configurationsData.isLoading || mlfData.isLoading,
    configurations: _.get(configurationsData, 'payload', []),
    mlfRange: _.get(mlfData, 'payload', []).filter(mlf => !mlf.deleted),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitialize: (assetId, commercialOnlineDate) => {
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        dispatch(configurationsResource.get(assetId, startTime, moment()))
        dispatch(mlfRangeResource.get(assetId, startTime, moment()))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationLog)
