import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Modal,
  Paper,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Info } from '@fluence/core'
import Card from '../Card'
import { getActivePPAContractResource } from '../../redux/features/setting'
import { dateTimeFormat, formatMoney } from '../../utility/utility'
import PPACardInfo from './PPACardInfo'

export const PPA_SWAP = 'ppa_swap'
const DISPLAY_NAME = 'PPACard'

const useStyles = makeStyles(
  theme => ({
    boxContainer: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    boxItem: {
      display: 'flex',
      flex: '0 1 auto',
      marginRight: theme.spacing(2),
    },
    iconBox: {
      display: 'flex',
      flex: '0 1 auto',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(-0.5),
      height: theme.spacing(2.25),
    },
    icon: {
      height: theme.spacing(3.5),
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    title: {
      height: theme.spacing(2.25),
    },
    collapsed: {
      maxHeight: theme.spacing(20),
    },
    expanded: {
      maxHeight: theme.spacing(45),
    },
    gridItem: {
      padding: theme.spacing(0.5, 2),
      borderBottom: `2px solid ${theme.palette.background.default}`,
      '&:last-child': {
        borderBottom: 0,
      },
    },
    noPPA: {
      padding: theme.spacing(1, 2),
    },
    action: {
      marginRight: 0,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    expand: {
      padding: theme.spacing(0.5),
    },
    smallRoot: {
      boxShadow: 'none',
      minHeight: theme.spacing(5),
    },
    collapseHeader: {
      padding: 0,
      borderBottom: 0,
    },
    smallCollapsedContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    smallExpandedContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    noBorder: {
      '& td': {
        borderBottom: 0,
      },
    },
    noOutline: {
      outline: 'none',
    },
    titles: {
      padding: '6px 0 6px 8px',
    },
    values: {
      '&:last-child': {
        paddingRight: 0,
      },
      padding: '6px 0 6px 8px',
    },
  }),
  { name: DISPLAY_NAME },
)

const PPACard = props => {
  const { asset, isLoading, ppaData, onInitialize } = props
  const classes = useStyles()
  const assetId = _.get(asset, 'assetId')
  const timezone = _.get(asset, 'market.data.timezone')

  const [PPAInfoOpen, setPPAInfoOpen] = useState(false)
  const hasPPA = ppaData.length > 0
  const noPPAText =
    'You have not entered any PPA Terms. Your asset will be optimised under merchant assumptions. Contact your Fluence representative to enable a PPA term.'
  const titles = [
    'Start',
    'End',
    'PPA Price',
    'Floating Price Floor',
    'Floating Price Ceiling',
    'Not Paid Below',
    'LGC Recipient',
    'Coverage Ratio',
    'FCAS Cost Sensitivity',
  ]

  const [expanded, setExpanded] = useState([])
  if (expanded.length === 0 && ppaData.length !== 0) {
    setExpanded(ppaData.map(() => false))
  }

  const handleExpandClick = contractId => {
    const lst = [...expanded]
    lst[contractId - 1] = !lst[contractId - 1]
    setExpanded(lst)
  }

  useEffect(() => {
    if (_.isNumber(assetId)) {
      onInitialize(assetId)
    }
  }, [assetId, onInitialize])

  const collapsed = expanded.filter(value => value === true).length === 0

  return (
    <div>
      <Card
        classes={{
          content: clsx(classes.cardContent, {
            [classes.collapsed]: collapsed,
            [classes.expanded]: !collapsed,
          }),
          title: classes.title,
        }}
        title={
          <Box display="inline-flex">
            <Box display="flex" flex="0 1 auto">
              <Typography variant="h3">PPA Terms</Typography>
            </Box>
            <Box className={classes.iconBox}>
              <IconButton
                className={classes.icon}
                aria-label="Bidding Risk Appetite information"
                size="small"
                onClick={() => setPPAInfoOpen(true)}
              >
                <Info color="primary" fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        <Grid className={classes.container}>
          {!isLoading &&
            hasPPA &&
            ppaData.map((contract, index) => {
              const formatTimeValue = title => {
                const value = _.get(contract, `${title}`)
                return value ? `${moment(value).tz(timezone).format(dateTimeFormat)}` : '-'
              }

              const formatMoneyValue = title => {
                const value = _.get(contract, `data.${title}`)
                return _.isNumber(value) ? `${formatMoney(value * 1000, 2)}/MWh` : '-'
              }

              const formatPercentValue = title => {
                const value = _.get(contract, `data.${title}`)
                return value ? `${_.round(value * 100, 2)}%` : '-'
              }

              const contractId = _.get(contract, 'contractId', '-')
              const start = formatTimeValue('startTime')
              const end = formatTimeValue('endTime')
              const price = formatMoneyValue('ppa_price_per_kwh')
              const priceFloor = formatMoneyValue('floating_floor_price_per_kwh')
              const priceCeiling = formatMoneyValue('floating_ceiling_price_per_kwh')
              const notPaidBelow = formatMoneyValue('not_paid_below_price_per_kwh')
              const lgcRecipient = _.get(contract, 'data.is_bundled_with_lgc') ? 'Offtaker' : 'Generator'
              const ratio = formatPercentValue('coverage_ratio')
              const fcas = formatPercentValue('fcas_cost_consideration_fraction')
              const values = [start, end, price, priceFloor, priceCeiling, notPaidBelow, lgcRecipient, ratio, fcas]

              return (
                <Grid item className={classes.gridItem} key={contractId} tabIndex={index}>
                  <Card
                    title={
                      <Typography variant="body1">
                        {_.get(asset, 'name', '')} PPA Term {index + 1}
                      </Typography>
                    }
                    action={
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded[contractId - 1],
                        })}
                        onClick={() => handleExpandClick(contractId)}
                        aria-expanded={expanded[contractId - 1]}
                        aria-label="show more"
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    }
                    classes={{
                      root: clsx(classes.smallRoot, classes.noOutline),
                      header: classes.collapseHeader,
                      content: clsx({
                        [classes.smallExpandedContent]: expanded[contractId - 1],
                        [classes.smallCollapsedContent]: !expanded[contractId - 1],
                      }),
                      action: classes.action,
                    }}
                  >
                    <Collapse in={expanded[contractId - 1]} timeout={1} unmountOnExit>
                      <Table>
                        <TableBody>
                          {titles.map((title, index) => (
                            <TableRow className={classes.noBorder} key={title}>
                              <TableCell className={classes.titles}>
                                <Typography varient="body1" color="textSecondary">
                                  {title}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.values}>
                                <Typography varient="body1" color="textSecondary">
                                  {_.get(values, `${index}`)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </Card>
                </Grid>
              )
            })}

          {!isLoading && !hasPPA && (
            <Grid item className={classes.noPPA}>
              <Typography varient="body1">No PPA Terms</Typography>
              <Typography varient="body1" color="textSecondary">
                {noPPAText}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Card>
      <Modal open={PPAInfoOpen}>
        <Paper tabIndex={-1}>
          <PPACardInfo onClose={() => setPPAInfoOpen(false)} />
        </Paper>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  const ppa = _.get(state, 'setting.ppa', [])
  const ppaData = ppa.payload
    ? ppa.payload.sort((ppa1, ppa2) => _.get(ppa1, 'contractId', 0) - _.get(ppa2, 'contractId', 0))
    : []

  return {
    isLoading: _.get(ppa, 'isLoading', true),
    ppaData: ppaData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitialize: assetId => {
      const startTime = moment()
      dispatch(getActivePPAContractResource.get(assetId, startTime, PPA_SWAP))
    },
  }
}

PPACard.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(PPACard)
