import React from 'react'
import Toggle from '../Toggle'

const DISPLAY_NAME = 'HorizonToggle'

function HorizonToggle(props) {
  const { selected, onChange, horizonDurations = [], horizonDisplayNames = {} } = props

  const options = horizonDurations.map((duration, index) => {
    return {
      text: horizonDisplayNames[index],
      key: duration,
      toolTip: `Horizon: ${horizonDisplayNames[index]}`,
    }
  })

  return (
    <div>
      <Toggle
        values={options}
        selected={selected}
        onChange={onChange}
      />
    </div>
  )
}
HorizonToggle.displayName = DISPLAY_NAME

export default HorizonToggle
