import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Modal, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import { Button } from '@fluence/core'

const DISPLAY_NAME = 'ConfirmationBox'

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  body: {
    maxHeight: `calc(100vh - ${56 + 69 + theme.spacing(8)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1, 2, 6),
  },
  paper: {
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  foot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  closeBtn: {
    position: 'absolute',
    top: -28,
    right: -6,
  },
}))

function ConfirmationBox(props) {
  const {
    open = false,
    title = '',
    message = '',
    primaryButtonText = '',
    secondaryButtonText = '',
    onPrimary,
    onSecondary,
    onClose,
  } = props
  const classes = useStyles()

  const handlePrimaryClick = event => {
    if (_.isFunction(onPrimary)) {
      onPrimary(event)
    }
  }

  const handleSecondaryClick = event => {
    if (_.isFunction(onSecondary)) {
      onSecondary(event)
    }
  }

  const handleOnClose = () => {
    if (_.isFunction(onClose)) {
      onClose()
    }
  }

  const rootClasses = clsx(classes.root, classes.paper)

  return (
    <Modal aria-labelledby="cb-modal-title" aria-describedby="cb-modal-body" open={open}>
      <div className={rootClasses}>
        <div className={classes.head}>
          <Box flex="0 1 auto">
            <Typography id="cb-modal-title" variant="h2" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Box flex="0 1 auto">
            <IconButton className={classes.closeBtn} size="small" onClick={handleOnClose}>
              <Clear fontSize="small" />
            </IconButton>
          </Box>
        </div>

        <div id="cb-modal-body" className={classes.body}>
          {message}
        </div>

        <div className={classes.foot}>
          {!_.isEmpty(primaryButtonText) && _.isFunction(onPrimary) && (
            <Button variant="primary" onClick={handlePrimaryClick}>
              {primaryButtonText}
            </Button>
          )}
          {!_.isEmpty(secondaryButtonText) && _.isFunction(onSecondary) && (
            <Box mr={2}>
              <Button variant="secondary" onClick={handleSecondaryClick}>
                {secondaryButtonText}
              </Button>
            </Box>
          )}
        </div>
      </div>
    </Modal>
  )
}

ConfirmationBox.displayName = DISPLAY_NAME

export default ConfirmationBox
