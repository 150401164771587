import _ from 'lodash'
import { custom as customTheme } from '../../theme/theme'

const {
  gridColor,
  monospaceFontFamily,
  labelColor,
  primaryFontFamily
} = _.get(customTheme, 'palette.graphs')

export const xAxis = {
  intervalType: 'second',
  gridThickness: 1,
  tickThickness: 1,
  lineThickness: 1,
  lineColor: gridColor,
  gridColor: gridColor,
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 11,
  labelFontColor: labelColor,
  titleFontFamily: monospaceFontFamily,
  titleFontSize: 13,
  titleFontColor: labelColor,
  tickColor: 'transparent',
  tickLength: 7,
}

export const x2Axis = {
  gridThickness: 0,
  tickThickness: 0,
  lineThickness: 0,
  labelFontSize: 0,
  tickColor: 'transparent',
  tickLength: 0,
  margin: 0,
}

export const yAxis = {
  gridThickness: 1,
  tickThickness: 1,
  lineThickness: 1,
  lineColor: gridColor,
  gridColor: gridColor,
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 11,
  labelFontColor: labelColor,
  titleFontFamily: monospaceFontFamily,
  titleFontSize: 13,
  titleFontColor: labelColor,
  tickColor: 'transparent',
  tickLength: 0,
}

export const tooltip = {
  cornerRadius: 0,
  borderThickness: 1,
  shared: true,
  fontSize: 12,
  fontStyle: 'normal',
  reversed: false,
  fontFamily: primaryFontFamily,
  animationEnabled: false,
  backgroundColor: '#282d36',
  borderColor: '#3b414a',
}

export const series = {
  type: 'line',
  lineDashType: 'solid',
  lineThickness: 1,
  showInLegend: false,
  markerSize: 0,
  visible: true,
  fillOpacity: 0.2,
}

export const stripLines = {
  labelPlacement: 'outside',
  thickness: 0.8,
  labelBackgroundColor: 'transparent',
  labelFontFamily: monospaceFontFamily,
  labelFontSize: 9,
  labelWrap: false,
  labelMaxWidth: 100,
  labelFontColor: 'white',
  showOnTop: true,
  color: 'transparent',
  opacity: 1,
}

export const graphOptions = {
  zoomEnabled: true,
  height: 400,
  zoomType: 'x',
  animationEnabled: false,
  backgroundColor: 'transparent',
  toolTip: {
    enabled: true,
    cornerRadius: 0,
    borderThickness: 2,
    fontStyle: 'normal',
    fontColor: 'black',
  },
  title: {
    text: '',
    horizontalAlign: 'left',
    margin: 12,
    fontFamily: 'helvetica',
    fontSize: 16,
    fontWeight: 'normal',
    fontColor: '#777777',
  },
}
