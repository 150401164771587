import React, { useState, useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import { Tune as TuneIcon } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import {
  Bids as BidsIcon,
  Forecast as ForecastIcon,
  MarketResults as MarketResultsIcon,
  useNotifier,
} from '@fluence/core'
import PageHeader from './components/header/PageHeader'
import AssetHeader from './components/header/AssetHeader'
import NavDrawer from './components/header/NavDrawer'
import {
  currentUser,
  userAgreement,
  userAgreementVersion,
  addUserAgreement,
  currentUserPermissionsSelector,
} from './redux/features/user'
import { globalAppOperations } from './redux/features/app'
import { assetOperations } from './redux/features/asset'
import { getAssets, TOGGLE_LIVE_VIEW } from './redux/features/dashboard'
import AppErrorBoundary from './components/error/AppErrorBoundary'
import { getIcon } from './utility/utility'
import { canAccessRoutePermission } from './utility/user-utils'
import UserAgreementManager from './components/UserAgreementManager.js'
import { CAN_ADJUST_RISK_APPETITE, CAN_ONLY_PAUSE_BID_DELIVERY } from './utility/constants'

window.moment = moment

const createRoutes = (user, asset) => {
  const assetId = _.get(asset, 'assetId')
  return [
    {
      path: '/asset',
      subItem: true,
      icon: BidsIcon,
      text: 'Bids',
      to: `/asset/${assetId}/bids`,
    },
    {
      path: '/asset',
      permissions: [`!${CAN_ONLY_PAUSE_BID_DELIVERY}`],
      subItem: true,
      icon: ForecastIcon,
      text: 'Forecasts',
      to: `/asset/${assetId}/forecasts`,
    },
    {
      path: '/asset',
      permissions: [`!${CAN_ONLY_PAUSE_BID_DELIVERY}`],
      subItem: true,
      icon: MarketResultsIcon,
      text: 'Market Results',
      to: `/asset/${assetId}/marketResults`,
    },
    {
      path: '/asset',
      permissions: [`!${CAN_ONLY_PAUSE_BID_DELIVERY}`],
      subItem: true,
      icon: getIcon(_.get(asset, 'data.tag_prefix'), _.get(asset, 'data.asset_type')),
      text: 'Asset Operations',
      to: `/asset/${assetId}/assetOperations`,
    },
    {
      path: '/asset',
      permissions: [CAN_ADJUST_RISK_APPETITE, `!${CAN_ONLY_PAUSE_BID_DELIVERY}`],
      subItem: true,
      icon: TuneIcon,
      text: 'Trade Settings',
      to: `/asset/${assetId}/tradingSettings`,
      divider: true,
    },
  ].filter(
    route =>
      route.permissions === undefined ||
      (_.isObject(user.permissions) && route.permissions.every(perm => canAccessRoutePermission(perm, user, asset))),
  )
}

const styles = theme => ({
  content: {
    flex: '1 1 auto',
    marginLeft: theme.custom.overrides.App.paddingLeft,
    width: '100%',
    padding: theme.spacing(2),
    overflow: 'hidden',
    minWidth: 0,
  },
})

function App(props) {
  const {
    classes,
    children,
    currentUser,
    onInitialize,
    selectAssetId,
    isLiveViewSelected,
    assets,
    toggleLiveAssets,
    permissions,
    location,
    dispatchGetUserAgreement,
    dispatchGetAgreementVersion,
    dispatchAddAgreementRecord,
    isAgreed,
  } = props
  const { assetId: assetIdParam } = useParams()
  const assetId = _.isNil(assetIdParam) ? null : parseInt(assetIdParam)
  const { notifyError } = useNotifier()

  const pathName = location.pathname
  const asset = _.find(assets, { assetId: assetId })
  const displayIsLiveView = _.isNil(assetId) ? isLiveViewSelected : _.get(asset, 'data.is_live')
  const displayAssets = assets.filter(asset => !!asset.data.is_live === displayIsLiveView)

  const [routes, setRoutes] = useState([])
  const [justAgreed, setJustAgreed] = useState(false)
  const [userAgreementIsLoading, setUserAgreementIsLoading] = useState(true)
  const [versionId, setVersionId] = useState(0)

  useEffect(() => {
    onInitialize()
  }, [onInitialize])

  useEffect(() => {
    selectAssetId(assetId)
    window.scrollTo(0, 0)
  }, [assetId, selectAssetId, pathName])

  useEffect(() => {
    setRoutes(createRoutes(currentUser, asset))
  }, [currentUser, asset])

  useEffect(() => {
    if (_.isFinite(currentUser.userId)) {
      dispatchGetUserAgreement(currentUser.userId).then(data => {
        setVersionId(_.get(data, 'payload.versionId'))

        if (!_.get(data, 'payload.agreed')) {
          dispatchGetAgreementVersion(_.get(data, 'payload.versionId')).then(getAgreementVersionResponse => {
            setUserAgreementIsLoading(false)
          })
        } else {
          setUserAgreementIsLoading(false)
        }
      })
    }
  }, [dispatchGetUserAgreement, dispatchGetAgreementVersion, dispatchAddAgreementRecord, currentUser])

  const handleOnSubmit = () => {
    dispatchAddAgreementRecord(versionId, currentUser.userId).then(response => {
      if (response.error) {
        notifyError('Request unsuccessful. Please try again.')
      } else {
        setJustAgreed(true)
      }
    })
  }

  return (
    !location.pathname.includes('/login') && (
      <>
        <Grid container direction="column">
          <PageHeader
            assets={assets}
            user={currentUser}
            displayIsLiveView={displayIsLiveView}
            toggleIsLive={toggleLiveAssets}
            isAgreed={isAgreed || justAgreed}
          />
          <UserAgreementManager
            user={currentUser}
            isAgreed={isAgreed || justAgreed}
            userAgreementIsLoading={userAgreementIsLoading}
            versionId={versionId}
            handleOnSubmit={handleOnSubmit}
          >
            <AssetHeader asset={asset} assets={displayAssets} permissions={permissions} location={location} />
            <Grid container wrap="nowrap">
              <NavDrawer asset={asset} routes={routes} />
              <main className={classes.content}>
                <AppErrorBoundary>{children}</AppErrorBoundary>
              </main>
            </Grid>
          </UserAgreementManager>
        </Grid>
      </>
    )
  )
}

App.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onInitialize: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const isLiveViewSelected = state.dashboard.view.isLiveView
  const assets = _.get(state, 'dashboard.assets.payload', [])
  const isAgreed = _.get(state, 'user.agreement.payload.agreed')

  return {
    isLiveViewSelected,
    assets,
    currentUser: _.get(state, 'user.current.payload'),
    isAgreed,
    permissions: currentUserPermissionsSelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  onInitialize: () => {
    dispatch(currentUser.get())
    dispatch(getAssets())
    dispatch(globalAppOperations.getMarketByName('AEMO'))
  },
  selectAssetId: assetId => {
    dispatch(assetOperations.selectAssetId(assetId))
  },
  toggleLiveAssets: isLive => {
    dispatch({
      type: TOGGLE_LIVE_VIEW,
      payload: isLive,
    })
  },
  dispatchAddAgreementRecord: (versionId, userId) => dispatch(addUserAgreement.post(versionId, userId)),
  dispatchGetUserAgreement: userId => dispatch(userAgreement.get(userId)),
  dispatchGetAgreementVersion: versionId => dispatch(userAgreementVersion.get(versionId)),
  dispatchGetMarketData: () => dispatch(globalAppOperations.getMarketByName('AEMO')),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App)))
