import { combineReducers } from 'redux'
import _ from 'lodash'
import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import {
  getCombinedBidsFromBidFilesAndManualBids,
  getReasonIndex,
  showManualBidOnBidsPage,
} from '../../utility/bids-utils'
import { INTERVAL_SIZE_MINS, NUM_FIVE_MIN_INTERVALS_PER_DAY } from '../../utility/constants'
import { isSelectedDateActiveMarketDay } from '../../utility/time-utils'
import { convertkWToMW, getAllEnabledProductIds } from '../../utility/utility'
import { API, ResourceAction, makeResourceReducer } from '../api'
import { CACHE_API } from '../cachingMiddleware'
import { cacheOperations } from './cache'

// UI actions
const SELECT_PRODUCT_TYPE = 'SELECT_PRODUCT_TYPE'
const SELECT_PRODUCT_NAME = 'SELECT_PRODUCT_NAME'
const SELECT_CURRENT_DATE = 'SELECT_BID_CURRENT_DATE'
const SELECT_BID_ROW = 'SELECT_BID_ROW'
const SELECT_BID_INTERVAL = 'SELECT_BID_INTERVAL'
const SELECT_BID_VIEW = 'SELECT_BID_VIEW'

export const selectProductType = productType => ({
  type: SELECT_PRODUCT_TYPE,
  payload: productType,
})

export const selectProductName = productName => ({
  type: SELECT_PRODUCT_NAME,
  payload: productName,
})

export const selectBidInterval = bidCollationInterval => ({
  type: SELECT_BID_INTERVAL,
  payload: bidCollationInterval,
})

export const selectBidView = selectedBidView => ({
  type: SELECT_BID_VIEW,
  payload: selectedBidView,
})

export const selectCurrentDate = date => ({
  type: SELECT_CURRENT_DATE,
  payload: date,
})

export const selectBidRow = index => ({
  type: SELECT_BID_ROW,
  payload: index,
})

export const selectManualBids = state => {
  return _.get(state, 'bid.manualBids')
}

export const selectBidFileMetadata = state => _.get(state, 'bid.bidFileMetadata', [])

export const selectLatestBidFile = (state, assetId, tradingDay, productId) =>
  _.get(state, ['bid', 'latestBidFile', assetId, tradingDay, productId])

export const selectSortedManualBidsData = createSelector(
  [
    (state, assetId, selectedDate, productId) =>
      getManualBidsData(selectManualBids(state), assetId, productId, selectedDate),
  ],
  manualBidData => {
    if (_.isEmpty(manualBidData)) {
      return manualBidData
    }
    const { data, ...metaData } = manualBidData
    const manualBids = data ? data.filter(x => !x.deleted) : []
    const sortedManualBids = manualBids.sort((x, y) => {
      return moment(x.createdOn) - moment(y.createdOn)
    }) // ascending order so late ones can override early ones
    return { ...metaData, sortedManualBids }
  },
)

export const selectLatestFutureBidFile = (state, assetId, tradingDay, productId) => {
  const tradingDayFormatted = moment(tradingDay).format('YYYY-MM-DD')
  const latestBidFileState = _.get(state, 'bid.latestFutureDayBidFile')
  return _.get(latestBidFileState, [assetId, tradingDayFormatted, productId])
}

export const selectCombinedBidsForCreateModal = createSelector(
  [
    (state, assetId, selectedDate, productId) =>
      selectLatestFutureBidFile(state, assetId, moment(selectedDate).format('YYYY-MM-DD'), productId),
    (state, assetId, selectedDate, productId) =>
      selectLatestBidFile(state, assetId, moment(selectedDate).format('YYYY-MM-DD'), productId),
    (state, assetId, selectedDate, productId) =>
      getManualBidsData(selectManualBids(state), assetId, productId, selectedDate),
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs, marketStartHour, marketTimezone) =>
      moment(selectedDate).valueOf(),
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs, marketStartHour, marketTimezone) =>
      bidCreatorStartCutoffSecs,
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs, marketStartHour, marketTimezone) =>
      marketStartHour,
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs, marketStartHour, marketTimezone) =>
      marketTimezone,
  ],
  (
    latestFutureDayBidFile,
    lastSubmittedCombinedBids,
    manualBidData,
    selectedDate,
    bidCreatorStartCutoffSecs,
    marketStartHour,
    marketTimezone,
  ) => {
    if (isSelectedDateActiveMarketDay(selectedDate, marketStartHour)) {
      return getCombinedBidsFromBidFilesAndManualBids(
        lastSubmittedCombinedBids,
        manualBidData,
        selectedDate,
        bidCreatorStartCutoffSecs,
        marketStartHour,
        marketTimezone,
      )
    }
    return _.get(latestFutureDayBidFile, 'bids')
  },
)

export const selectProjectedPriceBandInputs = createSelector(
  [
    selectCombinedBidsForCreateModal,
    (
      state,
      assetId,
      selectedDate,
      productId,
      bidCreatorStartCutoffSecs,
      marketStartHour,
      marketTimezone,
      startIntervalIndex,
      endIntervalIndex,
      priceBandNames,
    ) => startIntervalIndex,
    (
      state,
      assetId,
      selectedDate,
      productId,
      bidCreatorStartCutoffSecs,
      marketStartHour,
      marketTimezone,
      startIntervalIndex,
      endIntervalIndex,
      priceBandNames,
    ) => endIntervalIndex,
    (
      state,
      assetId,
      selectedDate,
      productId,
      bidCreatorStartCutoffSecs,
      marketStartHour,
      marketTimezone,
      startIntervalIndex,
      endIntervalIndex,
      priceBandNames,
    ) => priceBandNames,
  ],
  (combinedBids, startIntervalIndex, endIntervalIndex, priceBandNames) => {
    // NOTE: combinedBids will be derived from different source depending on whether it is current trading day or future day.
    if (!_.has(combinedBids, startIntervalIndex)) {
      return []
    }
    // If endTime is end of trading day time, endIntervalIndex returns -1.
    const applicableBids =
      endIntervalIndex === -1
        ? combinedBids.slice(startIntervalIndex)
        : combinedBids.slice(startIntervalIndex, endIntervalIndex)

    if (_.isEmpty(applicableBids)) {
      return null
    }

    if (applicableBids.every(bid => _.isEqual(bid['values'], _.first(applicableBids)['values']))) {
      const pbValues = _.get(combinedBids, [startIntervalIndex, 'values'])
      if (!_.isArray(pbValues) || pbValues.length > 10) {
        return null
      }
      const pbInputs = {}
      for (let i = 0; i < priceBandNames.length; i++) {
        const name = priceBandNames[i]
        pbInputs[name] = convertkWToMW(_.get(pbValues, i, 0), 0)
      }
      return pbInputs
    } else {
      const pbInputs = {}
      for (const bid of applicableBids) {
        const pbValues = _.get(bid, 'values')
        if (!_.isArray(pbValues) || pbValues.length > 10) {
          return null
        }
        for (let i = 0; i < priceBandNames.length; i++) {
          const name = priceBandNames[i]
          if (!_.isArray(pbInputs[name])) {
            pbInputs[name] = []
          }
          pbInputs[name].push(convertkWToMW(_.get(pbValues, i, 0), 0))
        }
      }
      return pbInputs
    }
  },
)

export const selectFinalCombinedBids = createSelector(
  [
    (state, assetId, selectedDate, productId) =>
      selectLatestBidFile(state, assetId, moment(selectedDate).format('YYYY-MM-DD'), productId),
    (state, assetId, selectedDate, productId) =>
      getManualBidsData(selectManualBids(state), assetId, productId, selectedDate),
    selectBidFileMetadata,
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs) => moment(selectedDate).valueOf(),
    (state, assetId, selectedDate, productId, bidCreatorStartCutoffSecs) => bidCreatorStartCutoffSecs,
  ],
  (lastSubmittedCombinedBids, manualBidData, bidFileMetadata, selectedDate, bidCreatorStartCutoffSecs) => {
    if (_.isNil(_.get(lastSubmittedCombinedBids, 'bids'))) {
      return []
    }

    const { data = [] } = manualBidData
    const manualBids = data ? data.filter(x => !x.deleted) : []
    const sortedManualBids = manualBids.sort((x, y) => {
      return moment(x.createdOn) - moment(y.createdOn)
    }) // ascending order so late ones can override early ones

    const lastUpdatedOn = moment(_.get(lastSubmittedCombinedBids, 'createdOn'))
    const submittedCombinedBids = []
    for (let i = 0; i < NUM_FIVE_MIN_INTERVALS_PER_DAY; i++) {
      submittedCombinedBids[i] = {}
    }
    lastSubmittedCombinedBids.bids.forEach((bid, index) => {
      submittedCombinedBids[index] = { ...bid, createdOn: lastUpdatedOn.toISOString() }
    })

    // construct reason column
    const metadataRebids = _.get(bidFileMetadata, 'payload', [])
    const sortedMetadataRebids = _.isArray(metadataRebids)
      ? metadataRebids.sort((x, y) => {
          return moment(x.createdOn) - moment(y.createdOn)
        }) // ascending order so late ones can override early ones
      : []
    if (!submittedCombinedBids.isLoading) {
      sortedMetadataRebids.forEach(data => {
        const createdOn = moment(_.get(data, 'createdOn'))

        const difference = moment.duration(createdOn.diff(moment(selectedDate))).asMinutes()

        // rebids with created time before trading day starts show in first interval
        let bidIndex = 0
        if (!createdOn.isBefore(moment(selectedDate))) {
          bidIndex = Math.ceil(difference / INTERVAL_SIZE_MINS)
        }
        if (bidIndex < NUM_FIVE_MIN_INTERVALS_PER_DAY) {
          submittedCombinedBids[bidIndex] = {
            ...submittedCombinedBids[bidIndex],
            reason: _.get(data, 'rebidReason', ''),
            createdOn: _.get(data, 'createdOn'),
          }
        }
      })
    }

    if (!_.isNil(sortedManualBids) && !_.isEmpty(sortedManualBids)) {
      // attach the manual bid reason to latest effective interval
      const lastManualBid = sortedManualBids[sortedManualBids.length - 1]
      const reasonIndex = getReasonIndex(lastManualBid)
      if (showManualBidOnBidsPage(lastManualBid)) {
        submittedCombinedBids[reasonIndex].reason = lastManualBid.reason
      }
      sortedManualBids.forEach(bid => {
        let bidIndex =
          moment.duration(moment(bid.startTime).diff(moment(selectedDate))).asMinutes() / INTERVAL_SIZE_MINS
        const createdOn = moment(_.get(bid, 'createdOn'))
        if (moment(bid.startTime).isSameOrBefore(createdOn)) {
          // manual bid for current interval (0-23 min)
          const difference = moment.duration(createdOn.diff(moment(bid.startTime))).asMinutes()
          bidIndex += Math.ceil(difference / INTERVAL_SIZE_MINS)
          const minsBeforeNextInterval =
            (createdOn.minutes() + createdOn.seconds() / moment.duration(1, 'minutes').asSeconds()) % INTERVAL_SIZE_MINS
          if (
            moment.duration(minsBeforeNextInterval, 'minutes').asSeconds() >= bidCreatorStartCutoffSecs &&
            minsBeforeNextInterval < INTERVAL_SIZE_MINS
          ) {
            bidIndex++
          }
        }
        // Attach manual bid data to collated bids that will be shown on bids table
        if (!_.isNil(submittedCombinedBids[bidIndex])) {
          if (showManualBidOnBidsPage(bid)) {
            // Show manual bid if (1) it's scheduled for current day
            // and it's submitted < 5 minutes ago
            // OR (2) it's scheduled for future trading days
            submittedCombinedBids[bidIndex].values = bid.values
            submittedCombinedBids[bidIndex].maxAvailablePower = bid.maxAvailablePower
            submittedCombinedBids[bidIndex].createdOn = bid.createdOn
            submittedCombinedBids[bidIndex].isManualBid = true
            submittedCombinedBids[bidIndex].manualBidReason = bid.reason
          } else if (
            !_.isNil(submittedCombinedBids[bidIndex].values) &&
            !_.isNil(bid.values) &&
            submittedCombinedBids[bidIndex].values.every((priceBand, i) => bid.values[i] === priceBand) &&
            bid.values.every((priceBand, i) => submittedCombinedBids[bidIndex].values[i] === priceBand)
          ) {
            // Show a past manual bid if the manual bid was successfully submitted
            // by verifying if the manual bid's price band array === the actually submitted price band array
            submittedCombinedBids[bidIndex].isManualBid = true
            submittedCombinedBids[bidIndex].manualBidReason = bid.reason
          }
        }
      })
    }
    return submittedCombinedBids
  },
)

export const selectActiveManualBids = createSelector(
  [
    // Get the actual manual bids from redux
    selectManualBids,

    (state, assetId, productId, startTime, nextIntervalTimeTimestamp) => {
      return assetId
    },
    (state, assetId, productId, startTime, nextIntervalTimeTimestamp) => {
      return productId
    },
    (state, assetId, productId, startTime, nextIntervalTimeTimestamp) => {
      return startTime
    },
    (state, assetId, productId, startTime, nextIntervalTimeTimestamp) => {
      return nextIntervalTimeTimestamp
    },
  ],
  (manualBids, assetId, productId, startTime, nextIntervalTimeTimestamp) => {
    const bids = getManualBidsData(manualBids, assetId, productId, startTime)
    const manualBidData = _.get(bids, 'data', [])

    return manualBidData.filter(mbd => {
      return moment(mbd.startTime) >= moment(nextIntervalTimeTimestamp) && !_.get(mbd, 'deleted', false)
    })
  },
)

export const selectIntervalZeroMaxAvailBids = createSelector(
  [
    // Get the actual manual bids from redux
    selectManualBids,
    (state, asset, tradingDayStartTime, zeroMaxAvailBidsStartTimes, nextIntervalTimeTimestamp) => {
      return asset.assetId
    },
    (state, asset, tradingDayStartTime, zeroMaxAvailBidsStartTimes, nextIntervalTimeTimestamp) => {
      return JSON.stringify(getAllEnabledProductIds(asset))
    },
    (state, asset, tradingDayStartTime, zeroMaxAvailBidsStartTimes, nextIntervalTimeTimestamp) => {
      return tradingDayStartTime
    },
    (state, asset, tradingDayStartTime, zeroMaxAvailBidsStartTimes, nextIntervalTimeTimestamp) => {
      return zeroMaxAvailBidsStartTimes
    },
    (state, asset, tradingDayStartTime, zeroMaxAvailBidsStartTimes, nextIntervalTimeTimestamp) => {
      return nextIntervalTimeTimestamp
    },
  ],
  (
    manualBids,
    assetId,
    allEnabledProductIdsStringified,
    tradingDayStartTime,
    zeroMaxAvailBidsStartTimes,
    nextIntervalTimeTimestamp,
  ) => {
    const zeroMaxAvailBids = []
    const allEnabledProductIds = JSON.parse(allEnabledProductIdsStringified)
    if (!_.isNil(zeroMaxAvailBidsStartTimes)) {
      zeroMaxAvailBidsStartTimes.forEach(intervalStartTime => {
        allEnabledProductIds.forEach(productId => {
          const bids = getManualBidsData(manualBids, assetId, productId, tradingDayStartTime)
          const manualBidData = _.get(bids, 'data', [])
          if (manualBidData.length > 0) {
            const currIntervalZeroMaxAvailBids = manualBidData
              .filter(mbd => {
                return (
                  moment(mbd.startTime).isSameOrAfter(moment(nextIntervalTimeTimestamp)) &&
                  !_.get(mbd, 'deleted', false) &&
                  moment(mbd.startTime).isSame(moment(intervalStartTime))
                )
              })
              .map(bid => ({ ...bid, productId: productId }))
            if (currIntervalZeroMaxAvailBids.length > 0) {
              zeroMaxAvailBids.push(...currIntervalZeroMaxAvailBids)
            }
          }
        })
      })
    }
    return zeroMaxAvailBids
  },
)

// UI reducer
const view = (
  state = {
    selectedProductType: null,
    selectedProductName: null,
    selectedDate: null,
    selectedBidIndex: undefined,
    bidCollationInterval: null,
    selectedBidView: null,
  },
  action,
) => {
  switch (action.type) {
    case SELECT_BID_INTERVAL:
      return {
        ...state,
        bidCollationInterval: action.payload,
      }
    case SELECT_BID_VIEW:
      return {
        ...state,
        selectedBidView: action.payload,
      }
    case SELECT_PRODUCT_TYPE:
      return {
        ...state,
        selectedProductType: action.payload,
      }
    case SELECT_PRODUCT_NAME:
      return {
        ...state,
        selectedProductName: action.payload,
      }
    case SELECT_CURRENT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      }
    case SELECT_BID_ROW:
      return {
        ...state,
        selectedBidIndex: action.payload,
      }
    case collatedAssetBids.success: {
      const currentTimeBidIndex = getCurrentTradingIntervalIndex(action.payload[0])

      // selected bid index should stay sticky once set by the user
      const newBidIndex =
        currentTimeBidIndex !== -1 && !state.selectedBidIndex ? currentTimeBidIndex : state.selectedBidIndex
      return {
        ...state,
        selectedBidIndex: newBidIndex,
      }
    }
    default:
      return state
  }
}

export const getCurrentTradingIntervalIndex = bids => {
  const now = moment().utc()

  // eslint-disable-next-line no-unused-vars
  for (const [index, bid] of bids.entries()) {
    const bidStart = new Date(bid.startTime)
    const bidEnd = new Date(bid.endTime)
    if (now.isBetween(bidStart, bidEnd)) {
      return index
    }
  }
  return -1
}

export const selectPricebandValues = createSelector(
  [
    selectLatestFutureBidFile,
    (state, assetId, productId, tradingDay, intervalIndex) => {
      return intervalIndex
    },
  ],
  (bidFile, intervalIndex) => {
    const priceBandValues = _.get(bidFile, ['bids', intervalIndex, 'values'])
    return priceBandValues
  },
)

// API actions
const BID_FILES_GET_LAST_TYPES = {
  request: 'BID_FILES_GET_LAST_REQUEST',
  success: 'BID_FILES_GET_LAST_SUCCESS',
  error: 'BID_FILES_GET_LAST_ERROR',
}

export const getLatestBidFile = (
  assetId,
  productId,
  tradingDay,
  asOf,
  bidFileSchemaId,
  bidCollationInterval,
  cache = true,
) => {
  const tradingDayFormatted = moment(tradingDay).format('YYYY-MM-DD')
  const asOfFormatted = moment(asOf).toISOString()
  return API.marketBidFiles.getLatestBidFile({
    pathParams: { assetId, productId },
    queryParams: {
      tradingDay: tradingDayFormatted,
      asOf: asOfFormatted,
      bidFileSchemaId: bidFileSchemaId,
      bidCollationInterval,
    },
    types: BID_FILES_GET_LAST_TYPES,
    meta: {
      updated: moment().format(),
      path: [assetId, tradingDayFormatted, productId],
    },
    cache,
  })
}

const BID_FILES_GET_BY_PRODUCTS_TYPES = {
  request: 'BID_FILES_GET_BY_PRODUCTS_REQUEST',
  success: 'BID_FILES_GET_BY_PRODUCTS_SUCCESS',
  error: 'BID_FILES_GET_BY_PRODUCTS_ERROR',
}

export const getLatestBidFilesByProducts = (
  assetId,
  productIds,
  tradingDay,
  asOf,
  bidCollationInterval,
  cache = true,
) => {
  const tradingDayFormatted = moment(tradingDay).format('YYYY-MM-DD')
  const asOfFormatted = moment(asOf).toISOString()
  return API.marketBidFiles.getLastBidFilesForProducts({
    pathParams: { assetId },
    queryParams: {
      productIds,
      tradingDay: tradingDayFormatted,
      asOf: asOfFormatted,
      bidCollationInterval,
    },
    types: BID_FILES_GET_BY_PRODUCTS_TYPES,
    meta: {
      updated: moment().format(),
      path: [assetId, tradingDayFormatted],
    },
    cache,
  })
}

export const lastestBidFileClearCache = () => cacheOperations.removeItemIncludes('/bidFiles/')

const LATEST_BID_FILE_TYPES = {
  RESET: 'RESET',
}

export const latestBidFileActions = {
  latestBidFileReset: {
    type: LATEST_BID_FILE_TYPES.RESET,
  },
}

const latestBidFile = (state = {}, action) => {
  switch (action.type) {
    case BID_FILES_GET_LAST_TYPES.request:
      return { ...state, isLoading: true }
    case BID_FILES_GET_LAST_TYPES.success: {
      const path = _.get(action, 'meta.path', [])
      const bidFileId = _.get(action, 'payload.bidFileId')
      const oldBidFileId = _.get(state, [...path, 'bidFileId'])
      if (_.isEmpty(action.payload) || path.length !== 3 || bidFileId === oldBidFileId) {
        return { ...state, isLoading: false }
      }
      const newState = _.set({ ...state, isLoading: false }, path, action.payload)
      return newState
    }
    case BID_FILES_GET_LAST_TYPES.error: {
      return { ...state, isLoading: false }
    }
    case BID_FILES_GET_BY_PRODUCTS_TYPES.request:
      return { ...state, isLoading: true }
    case BID_FILES_GET_BY_PRODUCTS_TYPES.success: {
      const path = _.get(action, 'meta.path', [])
      const noNewBidFiles = Object.values(action.payload).every(bid =>
        _.has(_.get(state, [...path, bid.productId]), bid.bidFileId),
      )
      if (_.isEmpty(action.payload) || path.length !== 2 || noNewBidFiles) {
        return { ...state, isLoading: false }
      }
      const prevBidFiles = _.get(state, path, {})
      const newState = _.set({ ...state, isLoading: false }, path, { ...prevBidFiles, ...action.payload })
      return newState
    }
    case BID_FILES_GET_BY_PRODUCTS_TYPES.error: {
      return { ...state, isLoading: false }
    }
    case LATEST_BID_FILE_TYPES.RESET: {
      return {}
    }
    default:
      return state
  }
}

const BID_FILES_FUTURE_DAY_BY_PRODUCTS_TYPES = {
  request: 'BID_FILES_FUTURE_DAY_BY_PRODUCTS_REQUEST',
  success: 'BID_FILES_FUTURE_DAY_BY_PRODUCTS_SUCCESS',
  error: 'BID_FILES_FUTURE_DAY_BY_PRODUCTS_ERROR',
}

export const getLatestBidFilesForFutureDayByProducts = (assetId, productIds, tradingDay, cache = true) => {
  const tradingDayFormatted = moment(tradingDay).format('YYYY-MM-DD')
  return API.marketBidFiles.getLatestBidFilesForFutureDayByProducts({
    pathParams: { assetId },
    queryParams: {
      productIds,
      tradingDay: tradingDayFormatted,
    },
    types: BID_FILES_FUTURE_DAY_BY_PRODUCTS_TYPES,
    meta: {
      updated: moment().format(),
      path: [assetId, tradingDayFormatted],
    },
    cache,
  })
}

const latestFutureDayBidFile = (state = {}, action) => {
  switch (action.type) {
    case BID_FILES_FUTURE_DAY_BY_PRODUCTS_TYPES.request:
      return { ...state, isLoading: true }
    case BID_FILES_FUTURE_DAY_BY_PRODUCTS_TYPES.success: {
      const path = _.get(action, 'meta.path', [])
      const noNewBidFiles = Object.values(action.payload).every(bid =>
        _.has(_.get(state, [...path, bid.productId]), bid.bidFileId),
      )
      if (_.isEmpty(action.payload) || path.length !== 2 || noNewBidFiles) {
        return { ...state, isLoading: false }
      }
      const prevBidFiles = _.get(state, path, {})
      const newState = _.set({ ...state, isLoading: false }, path, { ...prevBidFiles, ...action.payload })
      return newState
    }
    case BID_FILES_FUTURE_DAY_BY_PRODUCTS_TYPES.error: {
      return { ...state, isLoading: false }
    }
    default:
      return state
  }
}

const MANUAL_BIDS_GET_BY_PIDS = {
  request: 'MANUAL_BIDS_GET_BY_PIDS_REQUEST',
  success: 'MANUAL_BIDS_GET_BY_PIDS_SUCCESS',
  error: 'MANUAL_BIDS_GET_BY_PIDS_ERROR',
}

export const fetchManualBidsByProductIds = (
  assetId,
  productIds,
  startTime,
  endTime,
  hideDeleted = true,
  cache = true,
) => {
  const startTimeFormatted = moment(startTime).toISOString()
  const endTimeFormatted = moment(endTime).toISOString()

  return API.marketManualBids.getManualBidsByProductIds({
    pathParams: { assetId },
    queryParams: {
      productIds,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
      hideDeleted,
    },
    types: MANUAL_BIDS_GET_BY_PIDS,
    meta: {
      updated: moment().format(),
      assetId,
      startTime: startTimeFormatted,
    },
    cache,
  })
}

const getBidsKey = (assetId, startTime, bidCollationInterval) =>
  `${assetId}_${bidCollationInterval}_${moment(startTime).toISOString()}`
export const getBidsData = (state, assetId, startTime, bidCollationInterval) =>
  state[getBidsKey(assetId, startTime, bidCollationInterval)] || {}
const getManualBidsKey = (assetId, productId, startTime) => {
  return `${assetId}_${productId}_${moment(startTime).toISOString()}`
}
export const getManualBidsData = (state, assetId, productId, startTime) =>
  state[getManualBidsKey(assetId, productId, startTime)] || {}

const fetchManualBids = new ResourceAction({
  type: 'MANUAL_BIDS',
  config: (assetId, productId, startTime, endTime, hideDeleted = true) => ({
    endpoint: `/market/bids/manual/asset/${assetId}/product/${productId}/range?startTime=${encodeURIComponent(
      startTime.toISOString(),
    )}&endTime=${encodeURIComponent(endTime.toISOString())}&hideDeleted=true`,
    meta: {
      assetId,
      startTime,
      productId,
    },
  }),
})

const collatedAssetBids = new ResourceAction({
  type: 'COLLATED_ASSET_BIDS',
  config: (asset, startTime, endTime, bidCollationInterval) => ({
    endpoint: `/market/bids/asset/${asset.assetId}/products/collated?startTime=${encodeURIComponent(
      startTime.toISOString(),
    )}&endTime=${encodeURIComponent(endTime.toISOString())}${productsQuery(
      asset,
    )}&bidCollationInterval=${bidCollationInterval}`,
    meta: {
      assetId: asset.assetId,
      startTime,
      bidCollationInterval,
    },
  }),
})

export const getCollatedAssetBids = (asset, startTime, endTime, isBeforeToday, bidCollationInterval, clearCache) => {
  return {
    [CACHE_API]: {
      mapStateToCache: state => getBidsData(state.bid.bids, asset.assetId, startTime, bidCollationInterval),
      mapStateToUpdatedOn: state =>
        (getBidsData(state.bid.bids, asset.assetId, startTime, bidCollationInterval) || {}).updatedOn,
      createFetchAction: () => collatedAssetBids.get(asset, startTime, endTime, bidCollationInterval),
      invalidateAfter: isBeforeToday ? '05:00:00' : clearCache ? '00:00:00' : '00:30:00',
    },
  }
}

export const getLastSubmittedCombinedBids = type =>
  new ResourceAction({
    type: `LAST_SUBMITTED_COMBINED_BIDS_${type}`.toUpperCase(),
    method: 'GET',
    shouldCache: true, // to store it in state.cache using url
    config: (assetId, productId, tradingDay, asOf, bidCollationInterval) => ({
      endpoint: `/market/bidFiles/asset/${assetId}/product/${productId}/last?tradingDay=${encodeURIComponent(
        moment(tradingDay).format('YYYY-MM-DD'),
      )}&asOf=${encodeURIComponent(moment(asOf).toISOString())}&bidCollationInterval=${bidCollationInterval}`,
    }),
  })

export const getBidFileMetadata = new ResourceAction({
  type: 'BID_FILE_METADATA',
  method: 'GET',
  shouldCache: true, // to store it in state.cache using url
  config: (assetId, tradingDay, bidFileSchemaId) => ({
    endpoint: `/market/bidFiles/asset/${assetId}/metadata/tradingDay?tradingDay=${encodeURIComponent(
      moment(tradingDay).format('YYYY-MM-DD'),
    )}&bidFileSchemaId=${bidFileSchemaId}`,
  }),
})

export const getManualBids = (assetId, productId, startTime, endTime, isBeforeToday, clearCache) => {
  return {
    [CACHE_API]: {
      mapStateToCache: state => getManualBidsData(state.bid.manualBids, assetId, productId, startTime),
      mapStateToUpdatedOn: state =>
        (getManualBidsData(state.bid.manualBids, assetId, productId, startTime) || {}).updatedOn,
      createFetchAction: () => {
        return fetchManualBids.get(assetId, productId, startTime, endTime)
      },
      invalidateAfter: isBeforeToday ? '05:00:00' : clearCache ? '00:00:00' : '00:30:00',
    },
  }
}

export const getManualBidsForLog = new ResourceAction({
  type: 'MANUAL_BIDS_LOG',
  config: (assetId, productId, startTime, endTime) => ({
    endpoint: `/market/bids/manual/asset/${assetId}/product/${productId}/range/frontend?startTime=${encodeURIComponent(
      startTime.toISOString(),
    )}&endTime=${encodeURIComponent(endTime.toISOString())}`,
    meta: {
      assetId,
      startTime,
      productId,
    },
  }),
})

export const createManualBid = new ResourceAction({
  type: 'CREATE_MANUAL_BID',
  method: 'POST',
  config: bid => ({
    endpoint: '/market/bids/manual/async',
    body: JSON.stringify(bid),
  }),
})

export const deleteManualBid = new ResourceAction({
  type: 'DELETE_MANUAL_BID',
  method: 'PUT',
  config: (assetId, productId, startTime) => ({
    endpoint: `/market/bids/manual/asset/${assetId}/product/${productId}/delete?startTime=${encodeURIComponent(
      startTime.toISOString(),
    )}`,
  }),
})

export const deleteManualBids = new ResourceAction({
  type: 'DELETE_MANUAL_BIDS',
  method: 'PUT',
  config: bidsToDelete => ({
    endpoint: `/market/bids/manual/delete/async`,
    body: JSON.stringify(bidsToDelete),
  }),
})

export const updateBidDeliveryFlag = new ResourceAction({
  type: 'UPDATE_BID_DELIVERY_FLAG',
  method: 'PUT',
  config: (assetId, value) => ({
    endpoint: `/market/assets/asset/${assetId}/assetData/bidFileSettings/pauseBidDelivery?value=${value}`,
  }),
})

export const getPriceBands = type =>
  new ResourceAction({
    type: `ASSET_PRICE_BANDS_${type}`.toUpperCase(),
    method: 'GET',
    config: (asset, startTime, asOf, deleted) => {
      const asOfValue = asOf ? `&asOf=${encodeURIComponent(asOf.toISOString())}` : ''
      return {
        endpoint: `/market/assets/asset/${asset.assetId}/products/priceBands/last?startTime=${encodeURIComponent(
          startTime.toISOString(),
        )}${asOfValue}&deleted=${deleted}${productsQuery(asset)}`,
      }
    },
  })

export const getPriceBandsRange = new ResourceAction({
  type: 'GET_ALL_ASSET_PRICE_BANDS',
  method: 'GET',
  config: (asset, productId, startTime, endTime, asOf, deleted) => {
    const asOfValue = asOf ? `&asOf=${encodeURIComponent(asOf.toISOString())}` : ''
    return {
      endpoint: `/market/assets/asset/${
        asset.assetId
      }/product/${productId}/priceBands/range?startTime=${encodeURIComponent(
        startTime.toISOString(),
      )}&endTime=${encodeURIComponent(endTime.toISOString())}${asOfValue}&deleted=${deleted}${productsQuery(asset)}`,
    }
  },
})

export const getAssetByAssetId = new ResourceAction({
  type: 'GET_ASSET_BY_ID',
  method: 'GET',
  config: asset => {
    return {
      endpoint: `/market/assets/${asset.assetId}`,
    }
  },
})

export const updatePriceBands = new ResourceAction({
  type: 'UPDATE_PRICE_BANDS',
  method: 'POST',
  config: values => ({
    endpoint: `/market/assets/priceBand`,
    body: JSON.stringify(values),
  }),
})

// API reducers
export const bids = (state = {}, action) => {
  let key = ''
  switch (action.type) {
    case collatedAssetBids.request: {
      key = getBidsKey(action.meta.assetId, action.meta.startTime, action.meta.bidCollationInterval)
      const previousBid = state[key]
      return Object.assign({}, state, {
        ...state,
        [key]: {
          ...previousBid,
          isLoading: true,
        },
      })
    }
    case collatedAssetBids.success:
      key = getBidsKey(action.meta.assetId, action.meta.startTime, action.meta.bidCollationInterval)
      return Object.assign({}, state, {
        [key]: {
          isLoading: false,
          data: action.payload,
          updatedOn: action.meta.updatedOn,
        },
      })
    default:
      return state
  }
}

export const manualBids = (state = {}, action) => {
  let key = ''
  switch (action.type) {
    case fetchManualBids.request: {
      key = getManualBidsKey(action.meta.assetId, action.meta.productId, action.meta.startTime)
      const previousBid = state[key]
      return Object.assign({}, state, {
        ...state,
        [key]: {
          ...previousBid,
          isLoading: true,
        },
      }) // Have to keep old data when waiting for response, otherwise no data showing up
    }
    case fetchManualBids.success:
      key = getManualBidsKey(action.meta.assetId, action.meta.productId, action.meta.startTime)
      return Object.assign({}, state, {
        [key]: {
          isLoading: false,
          data: action.payload,
          updatedOn: action.meta.updatedOn,
        },
      })
    case MANUAL_BIDS_GET_BY_PIDS.success: {
      const { assetId, startTime, updatedOn } = _.get(action, 'meta', {})
      const newState = { ...state }
      for (const [productId, manualBids] of Object.entries(action.payload)) {
        const key = getManualBidsKey(assetId, productId, startTime)
        newState[key] = {
          isLoading: false,
          data: manualBids,
          updatedOn: updatedOn,
        }
      }
      return newState
    }
    default:
      return state
  }
}

const ZERO_MAX_TYPES = {
  reset: 'ZERO_MAX_MANUAL_BIDS_MAP_BY_START_TIME_RESET',
}

export const resetZeroMaxBidsMapByStartTime = () => ({
  type: ZERO_MAX_TYPES.reset,
})

const updateBidsMappedByManualBidIdStartTime = (prevState, assetId, productId, manualBids) => {
  const newState = { ...prevState }
  for (const bid of manualBids) {
    if (!bid.deleted) {
      const updateBidsKey = [`${assetId}`, bid.manualBidId, bid.startTime, 'bids']
      const updateBidsMapKey = [`${assetId}`, bid.manualBidId, bid.startTime, 'bids_map']
      const existingBids = _.get(newState, updateBidsKey)
      const existingBidsMap = _.get(newState, updateBidsMapKey)
      const newBidKey = `${productId}_${bid.manualBidId}_${bid.startTime}`
      const isBidInExistingBids = _.has(existingBidsMap, newBidKey)
      if (existingBids && !isBidInExistingBids) {
        const newBids = [...existingBids, { ...bid, productId }]
        const newBidsMap = new Set([...existingBidsMap, newBidKey])
        _.set(newState, updateBidsKey, newBids)
        _.set(newState, updateBidsMapKey, newBidsMap)
      } else if (!existingBids) {
        _.set(newState, updateBidsKey, [{ ...bid, productId }])
        _.set(newState, updateBidsMapKey, new Set([newBidKey]))
      }
    }
  }
  return newState
}

export const bidsMappedByManualBidIdStartTime = (state = {}, action) => {
  switch (action.type) {
    case fetchManualBids.request: {
      return { ...state, isLoading: true }
    }
    case fetchManualBids.success: {
      const { assetId, productId } = action.meta
      const manualBids = _.get(action, 'payload', [])
      const newState = updateBidsMappedByManualBidIdStartTime(state, assetId, productId, manualBids)
      return { ...newState, isLoading: false }
    }
    case MANUAL_BIDS_GET_BY_PIDS.success: {
      const { assetId } = _.get(action, 'meta', {})
      let newState = { ...state }
      for (const [productId, manualBids] of Object.entries(action.payload)) {
        newState = updateBidsMappedByManualBidIdStartTime(newState, assetId, productId, manualBids)
      }
      return { ...newState, isLoading: false }
    }
    case ZERO_MAX_TYPES.reset: {
      return {}
    }
    default:
      return state
  }
}

export const lastSubmittedCombinedBidsResource = getLastSubmittedCombinedBids('bids')
export const combinedBidsForManualBiddingResource = getLastSubmittedCombinedBids('manual')
const lastSubmittedCombinedBids = makeResourceReducer(lastSubmittedCombinedBidsResource)
const combinedBidsForManualBidding = makeResourceReducer(combinedBidsForManualBiddingResource)
const bidFileMetadata = makeResourceReducer(getBidFileMetadata)
const priceBands = makeResourceReducer(getPriceBands('NA'))
const modalActivePriceBands = makeResourceReducer(getPriceBands('active'))
const modalSelectedPriceBands = makeResourceReducer(getPriceBands('selected'))
const priceBandsRange = makeResourceReducer(getPriceBandsRange)
const bidDeliveryFlag = makeResourceReducer(updateBidDeliveryFlag)
const manualBidsForLog = makeResourceReducer(getManualBidsForLog)
const assetDetails = makeResourceReducer(getAssetByAssetId)

export default combineReducers({
  view,
  bids,
  lastSubmittedCombinedBids,
  latestBidFile,
  latestFutureDayBidFile,
  combinedBidsForManualBidding,
  bidFileMetadata,
  priceBands,
  modalActivePriceBands,
  modalSelectedPriceBands,
  priceBandsRange,
  manualBids,
  bidsMappedByManualBidIdStartTime,
  bidDeliveryFlag,
  manualBidsForLog,
  getAssetByAssetId,
  assetDetails,
})

const productsQuery = asset => asset.products.reduce((acc, product) => acc + '&productId=' + product.productId, '')
