import _ from 'lodash'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { userCanAdminister } from '../components/userManagement/userManagementUtility'
import UserForm from '../components/userManagement/UserForm'
import { useGetAdministrableUsers, useGetCurrentUser } from '../redux/api.js'

function EditUser(props) {
  const { location } = props
  const userId = _.get(_.split(location.pathname, '/editUser/'), 1)
  const { data: currentUser } = useGetCurrentUser()
  const { data: allUsers, mutate } = useGetAdministrableUsers()
  const selectedUser = _.find(allUsers, user => user.userId === parseFloat(userId))
  return (
    <>
      {!_.isEmpty(currentUser) &&
        !_.isNil(currentUser) &&
        (userCanAdminister(currentUser) ? (
          <div>
            {!_.isNil(selectedUser) && (
              <UserForm
                userId={parseInt(userId)}
                selectedUser={selectedUser}
                mutateAdministrableUsers={mutate}
                currentUser={currentUser}
              />
            )}
          </div>
        ) : (
          <Redirect to="/" />
        ))}
    </>
  )
}

export default EditUser
