import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import numeral from 'numeral'

import { makeStyles } from '@material-ui/core/styles'
import { TableCell, TableRow, IconButton, Tooltip } from '@material-ui/core'

import { XCircle } from '@fluence/core'
import { Lock } from '@material-ui/icons'
import { TIME_FORMAT, DATE_FORMAT, DAYS_OF_THE_WEEK_SHORT_ABBR, SOE_MAX, SOE_MIN } from '../../utility/constants'
import {
  convertkWToMW,
  formatTimeAsTradingDate,
  getTradingDate,
  getTradingStart,
  getCalendarDate,
  convertPeriodTimeToCalendarDateTime,
  findPeriodGivenDayOfWeek,
} from '../../utility/utility'

const useStyles = makeStyles(theme => ({
  fontWhite: {
    '& td': {
      color: theme.palette.text.primary,
    },
  },
  fontGrey: {
    '& td': {
      color: theme.palette.text.tertiary,
    },
  },
  fontBlue: {
    color: theme.palette.primary.main,
  },
  showChildOnHover: {
    '& td:last-child > button': {
      visibility: 'hidden',
    },
    '&:hover td:last-child > button': {
      visibility: 'visible',
    },
  },
  reasonIndicator: {
    fontSize: 8,
  },
}))

const getIntervalRange = (row, selectedTime, market) => {
  const scheduleType = _.get(row, 'scheduleType')
  if (scheduleType === 'event') {
    return { startTime: moment(row.startTime), endTime: moment(row.endTime) }
  } else if (scheduleType === 'weekly') {
    const periods = _.get(row, 'schedule.periods')
    const selectedDayOfWeek = moment(selectedTime.start).day()
    const period = findPeriodGivenDayOfWeek(periods, selectedDayOfWeek, row, market)
    const fromHour = _.get(period, 'fromHour')
    const fromMinute = _.get(period, 'fromMinute')
    const toHour = _.get(period, 'toHour')
    const toMinute = _.get(period, 'toMinute')
    if (_.isNil(fromHour) || _.isNil(fromMinute) || _.isNil(toHour) || _.isNil(toMinute)) {
      return null
    }
    const fromTimeAsTradingDay = moment(selectedTime.start).hour(fromHour).minute(fromMinute)
    const toTimeAsTradingDay = moment(selectedTime.start).hour(toHour).minute(toMinute)
    const fromTime = getCalendarDate(fromTimeAsTradingDay, false, market)
    const toTime = getCalendarDate(toTimeAsTradingDay, true, market)

    return { startTime: fromTime, endTime: toTime }
  }
  return 'Range Not Given'
}

const formatIntervalRange = (row, selectedTime, market) => {
  const intervalRange = getIntervalRange(row, selectedTime, market)
  if (intervalRange) {
    const startTime = getTradingDate(intervalRange.startTime, market)
    const endTime = getTradingDate(intervalRange.endTime, market)
    return `${startTime.format(TIME_FORMAT)} - ${endTime.format(TIME_FORMAT)}`
  }
  return 'Range Not Given'
}

const formatCharge = (isPowerChange, isEnergyChange, chargeKw, chargeKwh) => {
  if (isPowerChange) {
    return formatPower(chargeKw)
  } else if (isEnergyChange) {
    return formatEnergy(chargeKwh)
  } else {
    return '-'
  }
}
const formatPower = valueKw => {
  return !_.isNil(valueKw) ? `${convertkWToMW(valueKw)} MW` : `-`
}

const formatEnergy = valueKwh => {
  return !_.isNil(valueKwh) ? `${convertkWToMW(valueKwh)} MWh` : `-`
}

const formatPowerLimit = powerLimitKw => {
  return !_.isNil(powerLimitKw) ? `${convertkWToMW(powerLimitKw)} MW` : `-`
}

const formatSoc = soc => {
  return !_.isNil(soc) ? `${numeral(soc * 100).format('0')}%` : `-`
}

/**
 * @param {Object} row
 * @param {String} scheduleType
 * @param {*} market
 * @returns string {'Once', 'Daily', 'Weekly M, Tu, Th'}
 */
const formatFrequencyOfChange = (row, market) => {
  const scheduleType = _.get(row, 'scheduleType')
  let frequencyOfChangeString = scheduleType === 'event' ? 'Once' : ''
  if (scheduleType === 'weekly') {
    const periods = _.get(row, 'schedule.periods')
    if (periods.length === 7) {
      frequencyOfChangeString = 'Daily'
    } else {
      const daysScheduled = periods
        .sort((a, b) => a.fromDayOfWeek - b.fromDayOfWeek)
        .map(period => {
          const fromCalendarDateTime = convertPeriodTimeToCalendarDateTime(
            row.startTime,
            period.fromDayOfWeek,
            period.fromHour,
            period.fromMinute,
          )
          const activeDayOfWeekNumber = formatTimeAsTradingDate(fromCalendarDateTime, market, 'd')
          return DAYS_OF_THE_WEEK_SHORT_ABBR[activeDayOfWeekNumber]
        })
      frequencyOfChangeString = `Weekly ${daysScheduled.join(', ')}`
    }
  }
  return frequencyOfChangeString
}

const getRowEndDateTime = (row, selectedTime, market) => {
  const intervalRange = getIntervalRange(row, selectedTime, market)
  return _.get(intervalRange, 'endTime', null)
}

const ScheduledChangesRow = props => {
  const { row, market, selectedTime, handleDeleteScheduledChange } = props
  const classes = useStyles(props)
  const scheduleType = _.get(row, 'scheduleType')
  const chargeKw = _.get(row, 'data.charge_kw')
  const dischargeKw = _.get(row, 'data.discharge_kw')
  const chargeMinKw = _.get(row, 'data.charge_min_kw')
  const dischargeMinKw = _.get(row, 'data.discharge_min_kw')
  const chargeKwh = _.get(row, 'data.charge_kwh')
  const dischargeKwh = _.get(row, 'data.discharge_kwh')
  const chargeLimitKw = _.get(row, 'data.charge_limit_kw')
  const dischargeLimitKw = _.get(row, 'data.discharge_limit_kw')
  const maxSoc = _.get(row, 'data.soc_max')
  const minSoc = _.get(row, 'data.soc_min')
  const maxSoe = _.get(row, ['data', SOE_MAX])
  const minSoe = _.get(row, ['data', SOE_MIN])
  const reason = _.get(row, 'data.rebid_reason')

  const isPowerChange = !_.isNil(chargeKw) || !_.isNil(dischargeKw) || !_.isNil(chargeMinKw) || !_.isNil(dischargeMinKw)
  const isEnergyChange = !_.isNil(chargeKwh) || !_.isNil(dischargeKwh)

  const getDisplayClass = (changeTypeKw, changeTypeKwh) =>
    clsx({
      [classes.fontWhite]: _.isNil(changeTypeKw),
      [classes.fontBlue]: !_.isNil(changeTypeKw) || !_.isNil(changeTypeKwh),
    })

  const isRecurringClass = clsx({
    [classes.fontWhite]: scheduleType === 'event',
    [classes.fontBlue]: scheduleType === 'weekly',
  })

  const rowEndDateTime = getRowEndDateTime(row, selectedTime, market)
  const isActiveOrFutureChange = !_.isNil(rowEndDateTime) && rowEndDateTime.isAfter(moment())
  const isActiveOrFutureClass = clsx({
    [classes.fontGrey]: !isActiveOrFutureChange,
  })
  const displayIntervalRange = formatIntervalRange(row, selectedTime, market)
  const displayCharge = formatCharge(isPowerChange, isEnergyChange, chargeKw, chargeKwh)
  const displayDischarge = formatCharge(isPowerChange, isEnergyChange, dischargeKw, dischargeKwh)
  const displayMinCharge = formatCharge(isPowerChange, isEnergyChange, chargeMinKw)
  const displayMinDischarge = formatCharge(isPowerChange, isEnergyChange, dischargeMinKw)
  const displayChargeLimit = formatPowerLimit(chargeLimitKw)
  const displayDischargeLimit = formatPowerLimit(dischargeLimitKw)
  const displayMaxSoc = formatSoc(maxSoc)
  const displayMinSoc = formatSoc(minSoc)
  const displayMaxSoe = formatEnergy(maxSoe)
  const displayMinSoe = formatEnergy(minSoe)
  const displayFrequencyOfChange = formatFrequencyOfChange(row, market)
  const displayCreatedOn = formatTimeAsTradingDate(row.createdOn, market, DATE_FORMAT)
  const isActiveOrFutureTradingDate = moment(selectedTime.start).isSameOrAfter(getTradingStart(market))
  const hasReason = !_.isNil(reason) && !_.isEmpty(reason)
  const reasonIndicator = '●'
  const isRecordDeletable = _.get(row, 'deletable')

  return (
    <TableRow className={clsx(classes.showChildOnHover, isActiveOrFutureClass)}>
      <TableCell>{displayIntervalRange}</TableCell>
      <TableCell className={getDisplayClass(chargeKw, chargeKwh)}>{displayCharge}</TableCell>
      <TableCell className={getDisplayClass(dischargeKw, dischargeKwh)}>{displayDischarge}</TableCell>

      <TableCell className={getDisplayClass(chargeMinKw, null)}>{displayMinCharge}</TableCell>
      <TableCell className={getDisplayClass(dischargeMinKw, null)}>{displayMinDischarge}</TableCell>

      <TableCell className={getDisplayClass(chargeLimitKw, null)}>{displayChargeLimit}</TableCell>
      <TableCell className={getDisplayClass(dischargeLimitKw, null)}>{displayDischargeLimit}</TableCell>
      <TableCell className={getDisplayClass(minSoc, null)}>{displayMinSoc}</TableCell>
      <TableCell className={getDisplayClass(maxSoc, null)}>{displayMaxSoc}</TableCell>
      <TableCell className={getDisplayClass(minSoe, null)}>{displayMinSoe}</TableCell>
      <TableCell className={getDisplayClass(maxSoe, null)}>{displayMaxSoe}</TableCell>
      {hasReason ? (
        <Tooltip title={reason}>
          <TableCell className={classes.reasonIndicator} style={{ paddingLeft: 5 }}>
            {reasonIndicator}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell />
      )}
      <TableCell className={isRecurringClass}>{displayFrequencyOfChange}</TableCell>
      <TableCell>{displayCreatedOn}</TableCell>
      <TableCell>
        {isActiveOrFutureTradingDate &&
          isActiveOrFutureChange &&
          (isRecordDeletable ? (
            <IconButton
              aria-label="Delete scheduled change"
              size="small"
              onClick={() => {
                handleDeleteScheduledChange(row.assetScheduledDataId)
              }}
            >
              <XCircle color="primary" fontSize="small" />
            </IconButton>
          ) : (
            <IconButton aria-label="Cannot delete scheduled change" size="small" disabled>
              <Lock color="primary" fontSize="small" disabled />
            </IconButton>
          ))}
      </TableCell>
    </TableRow>
  )
}

ScheduledChangesRow.propTypes = {
  handleDeleteScheduledChange: PropTypes.func.isRequired,
  market: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  selectedTime: PropTypes.object.isRequired,
}

export default ScheduledChangesRow
