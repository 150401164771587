import moment from 'moment-timezone'
import actions from './actions'

const DEFAULT_CACHE_LIFE = moment.duration(5, 'minutes')

export const addItem = (cacheKey, updatedOn, payload) => (dispatch, getState) => {
  return dispatch(actions.addItem(cacheKey, updatedOn, payload))
}

export const removeItemIncludes = partialKey => dispatch => {
  return dispatch(actions.removeItemIncludes(partialKey))
}

export const expireOldEntries = () => dispatch => {
  const expireTime = moment().subtract(DEFAULT_CACHE_LIFE)
  dispatch(actions.expireOldEntries(expireTime))
}

const operations = {
  addItem,
  expireOldEntries,
  removeItemIncludes,
}

export default operations
