import { combineReducers } from 'redux'
import _ from 'lodash'
import types from './types'

const latest = (state = {}, action) => {
  switch (action.type) {
    case types.REGULATION_THROUGHPUT_LATEST_REQUEST.success: {
      const { assetId, productId } = _.get(action, 'meta', {})
      const path = `${assetId}_${productId}`
      const prevAssetProductDataId = _.get(state, [..._.toPath(path), 'assetProductDataId'])
      const payload = _.get(action, 'payload')
      const deleted = _.get(payload, 'deleted', false)
      if (deleted) {
        return state
      }
      const newAssetProductDataId = _.get(payload, 'assetProductDataId')
      if (_.isNil(prevAssetProductDataId) || newAssetProductDataId > prevAssetProductDataId) {
        const newState = { ...state }
        _.set(newState, path, payload)
        return newState
      }
      return state
    }
    default:
      return state
  }
}

const range = (state = {}, action) => {
  switch (action.type) {
    case types.REGULATION_THROUGHPUT_RANGE_REQUEST.success: {
      const { assetId, productId } = _.get(action, 'meta', {})
      const path = `${assetId}_${productId}`

      const prevItems = _.get(state, _.toPath(path))
      const payload = _.get(action, 'payload')
      if (!_.isEqual(payload, prevItems)) {
        const newState = { ...state }
        _.set(newState, path, payload)
        return newState
      }
      return state
    }
    default:
      return state
  }
}

export default combineReducers({
  latest,
  range,
})
