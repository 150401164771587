import _ from 'lodash'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { userCanAdminister } from '../components/userManagement/userManagementUtility'
import { useGetCurrentUser } from '../redux/api.js'
import UserForm from '../components/userManagement/UserForm'

function CreateUser() {
  const { data: currentUser } = useGetCurrentUser()

  return (
    <>
      {!_.isEmpty(currentUser) &&
        !_.isNil(currentUser) &&
        (userCanAdminister(currentUser) ? (
          <div>
            <UserForm currentUser={currentUser} />
          </div>
        ) : (
          <Redirect to="/" />
        ))}
    </>
  )
}

export default CreateUser
