import { makeStyles } from '@material-ui/core/styles'

const globalStyles = makeStyles(
  theme => ({
    // @global is handled by jss-plugin-global.
    '@global': {
      body: {
        margin: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.background.default} ${theme.palette.background.paper}`,
      },
      'body *::-webkit-scrollbar': {
        width: '12px',
        backgroundColor: theme.palette.background.paper,
      },
      'body *::-webkit-scrollbar-corner': {
        width: '12px',
        backgroundColor: theme.palette.background.default,
      },
      'body *::-webkit-scrollbar-thumb': {
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundClip: 'padding-box',
        backgroundColor: theme.palette.background.default,
        borderRadius: 5,
      },
    },
  }),
  { name: 'globalStyles' },
)

export default function GlobalCss() {
  globalStyles()
  return null
}
