import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import RegionPopup from './RegionPopup'
import InterConnectorPopup from './InterConnectorPopup'

const REGION_HEIGHT = '106px'
const INTERCONNECTOR_HEIGHT = '64px'

const useStyles = makeStyles(theme => ({
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
  },
  flexColumnContainerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(1),
  },
  flexColumnContainerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
  },
  interConnectorColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
  },
  regionContainer: {
    maxHeight: REGION_HEIGHT,
  },
  interConnectorSpace: {
    minHeight: INTERCONNECTOR_HEIGHT,
  },
  regionSpace: {
    minHeight: REGION_HEIGHT,
  },
  interConnectorContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxHeight: INTERCONNECTOR_HEIGHT,
  },
}))

function OverlaysWrapper(props) {
  const { regions, interConnectors, market } = props
  const classes = useStyles(props)

  const sa1 = _.find(regions, region => region.name === 'SA1')
  const nsw1 = _.find(regions, region => region.name === 'NSW1')
  const qld1 = _.find(regions, region => region.name === 'QLD1')
  const tas1 = _.find(regions, region => region.name === 'TAS1')
  const vic1 = _.find(regions, region => region.name === 'VIC1')

  const firstColumnRegions = !_.isNil(sa1) ? [sa1] : []
  const secondColumnRegions = [qld1, nsw1, vic1, tas1].reduce((acc, region) => {
    if (!_.isNil(region)) {
      acc.push(region)
    }
    return acc
  }, [])

  // it seems like we only support the "north" or "north-west" directions...
  // so this code will still have to be modified slightly if we add something else
  const firstInterConnectorColumn = _.filter(interConnectors, interConnector => {
    return _.get(interConnector, 'data.map_data.orientation') === 'north-west'
  })
  const secondInterConnectorColumn = _.filter(interConnectors, interConnector => {
    return _.get(interConnector, 'data.map_data.orientation') !== 'north-west'
  })

  const fullRightColumn = []
  for (let i = 0; i < secondColumnRegions.length; i++) {
    const region = secondColumnRegions[i]
    const nextRegion = i !== secondColumnRegions.length - 1 ? secondColumnRegions[i + 1] : null
    fullRightColumn.push(region)
    const allAboveIds = _.range(1, i + 1).map(index => secondColumnRegions[index].regionId)
    const allBelowIds = _.range(i, secondColumnRegions.length - 1).map(index => secondColumnRegions[index].regionId)

    const regionId = region.regionId
    const nextRegionId = _.get(nextRegion, 'regionId')

    const interConnectors = []
    _.forEach(secondInterConnectorColumn, interConnector => {
      const sourceRegionId = interConnector.sourceRegionId
      const destRegionId = interConnector.destRegionId
      if (sourceRegionId === regionId && allBelowIds.includes(destRegionId)) {
        interConnectors.push(interConnector)
      } else if ((sourceRegionId === nextRegionId && allAboveIds.includes(destRegionId)) || destRegionId === regionId) {
        interConnectors.push(interConnector)
      }
    })
    fullRightColumn.push(interConnectors)
  }

  const fullMiddleColumn = _.range(0, fullRightColumn.length).map(_ => [])

  for (let i = 0; i < fullRightColumn.length; i++) {
    _.forEach(firstInterConnectorColumn, interConnector => {
      const sourceRegionId = interConnector.sourceRegionId
      const destRegionId = interConnector.destRegionId
      const regionId = fullRightColumn[i].regionId
      if (
        regionId === sourceRegionId ||
        (regionId === destRegionId && firstColumnRegions.map(item => item.regionId).includes(sourceRegionId))
      ) {
        fullMiddleColumn[i].push(interConnector)
      }
    })
  }

  const getFirstColInterConnectorClassName = (arr, index) => {
    if (index % 2 !== 0) {
      return classes.interConnectorSpace
    } else {
      return classes.regionSpace
    }
  }

  return (
    <div data-testid="qa-map-overlays-container" className={classes.flexRowContainer}>
      <div data-testid="qa-map-overlays-first-column" className={classes.flexColumnContainerLeft}>
        {firstColumnRegions.map(region => (
          <div className={classes.regionContainer} key={region.regionId}>
            <RegionPopup key={region.regionId} region={region} market={market} />
          </div>
        ))}
      </div>
      <div data-testid="qa-map-overlays-second-column" className={classes.interConnectorColumn}>
        {fullMiddleColumn.map((arr, index) => (
          <div className={getFirstColInterConnectorClassName(arr, index)} key={index}>
            {arr.map(interConnector => (
              <InterConnectorPopup key={interConnector.interconnectorId} interConnector={interConnector} />
            ))}
          </div>
        ))}
      </div>
      <div data-testid="qa-map-overlays-third-column" className={classes.flexColumnContainerRight}>
        {fullRightColumn.map((item, index) =>
          !_.isNil(item.regionId) ? (
            <div className={classes.regionContainer} key={index}>
              <RegionPopup key={item.regionId} region={item} market={market} />
            </div>
          ) : (
            <div className={classes.interConnectorContainer} key={index}>
              {item.map(interConnector => (
                <InterConnectorPopup key={interConnector.interconnectorId} interConnector={interConnector} />
              ))}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const marketsState = state.dashboard.markets
  const map = state.dashboard.map
  const info = map.payload
  const regions = (info && info.regions) || []
  const interConnectors = (info && info.interConnectors) || []
  return {
    isLoading: map.isLoading || marketsState.isLoading,
    regions,
    interConnectors,
    market: marketsState.payload[0] || {},
  }
}

export default connect(mapStateToProps)(OverlaysWrapper)
