import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Button } from '@fluence/core'
import Card from '../Card'
import colors from '../../config/colors'

const DISPLAY_NAME = 'PauseAlgorithmicBidding'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '33%',
      position: 'absolute',
      top: '30%',
      right: 0,
      left: 0,
    },
    card: {
      maxWidth: 856,
      maxHeight: 'calc(100vh - 192px)',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    modalHeader: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      lineHeight: '28px',
    },
    textBody: {
      overflow: 'auto',
      padding: theme.spacing(2, 0),
      paddingTop: 0,
      whiteSpace: 'pre-wrap',
    },
    text: {
      marginBottom: theme.spacing(2.5),
    },
    footer: {
      maxWidth: 856,
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(2),
      flex: '1 0 auto',
      background: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row-reverse',
      borderTop: `2px solid ${theme.palette.background.default}`,
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
      backgroundColor: `${colors.CALIFORNIA}`,
      borderColor: `${colors.CALIFORNIA}`,
    },
  }),
  { name: DISPLAY_NAME },
)

const PauseAlgorithmicBidding = React.forwardRef(function PauseAlgorithmicBidding(props, ref) {
  const classes = useStyles()
  const { isBidDeliveryPaused, pauseBidDeliveryUpdate, onClose } = props

  return (
    <div className={classes.root} ref={ref}>
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
        }}
        title={
          <div className={classes.modalHeader}>
            {isBidDeliveryPaused ? 'Resume bid delivery' : 'Pause bid delivery'}{' '}
          </div>
        }
        titleTypographyProps={{ variant: 'h3' }}
      >
        {!isBidDeliveryPaused && (
          <div className={classes.textBody}>
            <Typography variant="body1" className={classes.text}>
              Pausing bid delivery will stop Fluence from sending bid files to AEMO. This includes both algorithmically
              generated bids and manual bids. The system will continue to log any bid files created during the pause,
              and will send valid bid files upon resuming bid delivery.{' '}
            </Typography>
            <Typography variant="body1">You can resume bid delivery at any time. </Typography>
          </div>
        )}
        {isBidDeliveryPaused && (
          <Typography variant="body1" className={classes.text}>
            Resuming bid delivery will allow Fluence to send algorithmically generated bids and manual bids to AEMO.{' '}
          </Typography>
        )}
      </Card>
      <div className={classes.footer}>
        {!isBidDeliveryPaused && (
          <Button variant="primary" onClick={pauseBidDeliveryUpdate} className={classes.marginLeft}>
            Pause
          </Button>
        )}
        {isBidDeliveryPaused && (
          <Button variant="primary" onClick={pauseBidDeliveryUpdate} className={classes.marginLeft}>
            Resume
          </Button>
        )}
        <Button variant="secondary" onClick={onClose}>
          CANCEL
        </Button>
      </div>
    </div>
  )
})

PauseAlgorithmicBidding.displayName = DISPLAY_NAME

export default PauseAlgorithmicBidding
