import React from 'react'
import _ from 'lodash'
import { Box, Grid } from '@material-ui/core'
import BEPCard from './BEPCard'
import DefaultBidCard from './DefaultBidCard'
import FCASCard from './FCASCard'
import LGCCard from './LGCCard'
import PPACard from './PPACard'
import PriceForecastCard from './PriceForecastCard'
import RaiseRegCard from './RaiseRegCard'
import ThroughputCostCard from './ThroughputCostCard'
import LowerRegCard from './LowerRegCard'
import RiskAppetite from './RiskAppetite'
import RiskLog from './RiskLog'

const DISPLAY_NAME = 'Strategy'

function Strategy(props) {
  const { asset } = props
  const isBattery = _.get(asset, 'data.tag_prefix') === 'battery'

  return (
    <>
      <Box mb={2}>
        <Grid container spacing={2}>
          {isBattery && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <ThroughputCostCard asset={asset} />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <RaiseRegCard asset={asset} />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <LowerRegCard asset={asset} />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <PriceForecastCard asset={asset} />
              </Grid>
              <Grid item xs={12}>
                <RiskAppetite asset={asset} />
              </Grid>
              <Grid item xs={12} md={6} lg={9}>
                <Box>
                  <RiskLog asset={asset} />
                </Box>
              </Grid>
            </>
          )}
          {!isBattery && (
            <>
              <Grid container spacing={0} item xs={12} md={6} lg={3} direction="column">
                <Grid item>
                  <Box mb={2}>
                    <BEPCard asset={asset} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <FCASCard asset={asset} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <PriceForecastCard asset={asset} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <RiskAppetite asset={asset} />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={0} item xs={12} md={6} lg={3} direction="column">
                <Grid item>
                  <Box mb={2}>
                    <LGCCard asset={asset} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <DefaultBidCard asset={asset} />
                  </Box>
                </Grid>
                <Grid item>
                  <Box mb={2}>
                    <PPACard asset={asset} />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box mb={2}>
                  <RiskLog asset={asset} />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}

Strategy.displayName = DISPLAY_NAME

export default Strategy
