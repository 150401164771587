import parse from 'parse-color'
import _ from 'lodash'

// Calculate the color between the two given colors, by the provided percent (as a decimal)
//
// minColor     {colorString} The starting color, in any form allowed by a web browser
// maxColor     {colorString} The ending color, in any form allowed by a web browser
// percentBetween   {Number}    A decimal between 0 and 1, representing how close to the minColor we want our computed color to be
//
// returns      {String}    A hex color string representing the computed color
export const calculateGradientValue = (minColor, maxColor, percentBetween) => {
  if (_.isNaN(percentBetween)) {
    return "#c3c3c3"
  }
  const parsedMinColor = parse(minColor)["rgb"]
  const parsedMaxColor = parse(maxColor)["rgb"]
  const diffs = [parsedMaxColor[0] - parsedMinColor[0], parsedMaxColor[1] - parsedMinColor[1], parsedMaxColor[2] - parsedMinColor[2]]
  const finalRGB = [parsedMinColor[0] + Math.round(percentBetween * diffs[0]), parsedMinColor[1] + Math.round(percentBetween * diffs[1]), parsedMinColor[2] + Math.round(percentBetween * diffs[2])]

  return "#" + generateNumberString(finalRGB[0], 16, 2) + generateNumberString(finalRGB[1], 16, 2) + generateNumberString(finalRGB[2], 16, 2)
}

export const calculateGradientRGBA = (minColor, maxColor, percentBetween) => {
  const parsedMinColor = parse(minColor)["rgba"]
  const parsedMaxColor = parse(maxColor)["rgba"]
  const diffs = [parsedMaxColor[0] - parsedMinColor[0], parsedMaxColor[1] - parsedMinColor[1], parsedMaxColor[2] - parsedMinColor[2], parsedMaxColor[3] - parsedMinColor[3]]
  const finalRGBA = [parsedMinColor[0] + Math.round(percentBetween * diffs[0]), parsedMinColor[1] + Math.round(percentBetween * diffs[1]), parsedMinColor[2] + Math.round(percentBetween * diffs[2]), parsedMinColor[3] + percentBetween * diffs[3]]

  return "rgba(" + finalRGBA[0] + ',' + finalRGBA[1] + ',' + finalRGBA[2] + ',' + finalRGBA[3] + ')'
}

export const generateNumberString = (num, base, digits) => {
  const convertedNum = num.toString(base)
  const padding = "0".repeat(digits - 1)
  const paddedNum = `${padding}${convertedNum}`

  return paddedNum.slice(-1 * digits)
}

export const getPortfolioStatusColor = (status, progress) => {
  if ('failed'.localeCompare(status, undefined, { sensitivity: 'base' })) {
    return 'rgb(227,74,51)'
  }
  if ('waiting'.localeCompare(status, undefined, { sensitivity: 'base' })) {
    return 'rgb(216, 200, 125)'
  }

  if (progress < 33) {
    return 'rgb(108,77,148)'
  }

  if (progress < 66) {
    return 'rgb(53,177,188)'
  }

  return 'rgb(141,214,58)'
}

export const colorToString = color => {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`
}

export const setRgbaValue = (colorStr, property, value) => {
  const properties = ['r', 'g', 'b', 'a']
  if (!properties.includes(property)) {
    throw new Error(`Value of property must be either "r", "g", "b", or "a". Found ${property}`)
  }
  if (property === 'a' && (value < 0 || value > 1)) {
    throw new Error(`Opacity must be between 0 and 1. Found ${value}`)
  }
  if (property !== 'a' && (value < 0 || value > 256)) {
    throw new Error(`rgb values must be between 0 and 256. Found ${value}`)
  }

  const color = parse(colorStr).rgba
  color[properties.indexOf(property)] = value

  return colorToString(color)
}
