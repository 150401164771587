import moment from 'moment-timezone'
export const propTypeIsoString = (props, propName, componentName) => {
  if (!isIsoDate(props[propName])) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName} Validation failed.`)
  }
}

export const propTypeDayFormat = (props, propName, componentName) => {
  if (!moment(props[propName], 'YYYY-MM-DD').isValid()) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName} Validation failed.`)
  }
}

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false
  }
  const d = new Date(str)
  return d.toISOString() === str
}
