import React, { Component } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  square: {
    width: 20,
    height: 10,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5),
    marginTop: -theme.spacing(0.125)
  },
  root: {
    textAlign: 'center'
  },
  subRoot: {
    marginTop: theme.spacing(0.625),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  colors: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.375),
    marginTop: -theme.spacing(0.125)
  },
  moreColors: {
    display: 'inline-block',
    width: 8,
    height: 2,
    verticalAlign: 'middle',
    marginTop: -theme.spacing(0.125)
  },
})

class GraphLegend extends Component {

  render() {
    const { graph, toggleSeries, classes } = this.props
    const sections = graph.sections || []

    return (
      <div className={classes.root}>
        <div>
          <div className={classes.subRoot}>
            {graph.series.filter(series => !_.isUndefined(series.legend)).map(series => (
              <div
                key={series.name}
                style={{
                  cursor: series.canToggle ? "pointer" : "default",
                  paddingBottom: "2px",
                  opacity: (series.visible ? "1" : ".5"),
                  fontSize: "12px",
                  marginRight: "12px",
                }}
                onClick={series.canToggle && (e => toggleSeries(e, series.name))}>
                {!!series.color && (
                  <span className={classes.square} style={{ background: series.color }}/>
                )}
                {!!series.colors && (
                  <span className={classes.colors}>
                    {series.colors.map(color => (
                      <span
                        key={color}
                        className={classes.moreColors}
                        style={{background: color}}
                      />
                    ))}
                  </span>
                )}
                <span>{series.name}</span>
              </div>
            ))}
          </div>
        </div>
        {!!sections.length && <div className="mt-neg-md">
          {graph.sectionsName && <h6 className="mr-md mb-none mt-none" style={{ display: "inline-block" }}>{graph.sectionsName}</h6>}
          <div className="no-text-select ml-xs mb-sm" style={{
            marginTop: "-5px",
            display: "inline-block"
          }}>
            {sections.map(section => (
              <span key={section.name} className="mr-md text-sm" style={{
                cursor: "pointer",
                paddingBottom: "2px"
              }}>
                <span style={{
                  width: 12,
                  height: 12,
                  background: section.color,
                  opacity: section.opacity,
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "3px",
                  marginTop: "-1px"
                }}/>
                <span>{section.name}</span>
              </span>
            ))}
          </div>
        </div>}
      </div>
    )
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props, nextProps)
  }
}

export default withStyles(styles)(GraphLegend)