import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ProductToggle from '../toggles/ProductToggle'
import ProductTypeToggle from '../toggles/ProductTypeToggle'

const DISPLAY_NAME = 'ManualBidCombinedProductToggle'
const useStyles = makeStyles(theme => ({
  smallLabel: {
    fontSize: theme.typography.pxToRem(10),
    marginBottom: theme.spacing(0.375),
    color: theme.custom.palette.graphs.labelColor,
  },
  box: {
    paddingLeft: 0,
    marginRight: theme.spacing(1),
  },
}))

function ManualBidCombinedProductToggle(props) {
  const classes = useStyles()
  // Product Type Toggle Props
  const {
    asset,
    selectedTime,
    disableProductTypeToggle = false,
    disabledProductTypeToggleNames = [],
    highlightedProductTypeNames = [],
    onChangeProductType,
    productTypes = [],
    productTypeDisplayNames = {},
    productTypeToggleVariant = 'primary',
    selectedProductType,
    showProductTypeToggle,
  } = props
  // Product Toggle Props
  const {
    disableProductToggle = false,
    disabledProductNames = [],
    highlightedProductNames = [],
    onChangeProductName,
    onProductToggleCollapse,
    productDisplayNames = {},
    productNames = [],
    productToggleExclusive = true,
    productToggleVariant = 'primary',
    selectedProductName,
  } = props

  return (
    <>
      <Grid container>
        {showProductTypeToggle && (
          <Grid item>
            <Typography className={classes.smallLabel}>Select Gen vs Load</Typography>
            <Box display="inline-flex" pl={1.5} className={classes.box}>
              <ProductTypeToggle
                disabledTypes={disabledProductTypeToggleNames}
                selected={selectedProductType}
                productTypes={productTypes}
                productTypeDisplayNames={productTypeDisplayNames}
                onChange={onChangeProductType}
                variant={productTypeToggleVariant}
                disable={disableProductTypeToggle}
                highlightedProductTypeNames={highlightedProductTypeNames}
              />
            </Box>
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.smallLabel}>Select Product</Typography>
          <Box display="inline-flex" pl={1.5} className={classes.box}>
            <ProductToggle
              asset={asset}
              selectedTime={selectedTime}
              selected={selectedProductName}
              productNames={productNames}
              productDisplayNames={productDisplayNames}
              onChange={onChangeProductName}
              disabledProductNames={disabledProductNames}
              onCollapse={onProductToggleCollapse}
              variant={productToggleVariant}
              disable={disableProductToggle}
              exclusive={productToggleExclusive}
              highlightedProductNames={highlightedProductNames}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

ManualBidCombinedProductToggle.displayName = DISPLAY_NAME

export default React.memo(ManualBidCombinedProductToggle)
