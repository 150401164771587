import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { INTERVAL_SIZE_MINS } from './constants'

/**
 * Determine the market start time for any given timestamp.
 *
 * @param {number} timestamp Timestamp in miliseconds
 * @param {number} marketStartHour The hour a given market starts
 * @returns {moment} Time for the first selection in menu
 */
export function getMarketStartGivenTimestamp(
  timestamp: Moment | number,
  marketStartHour: number,
  timezone: string = 'Etc/GMT-10',
): Moment {
  if (_.isNil(marketStartHour)) {
    console.error('Market start hour is required')
  }
  const t = moment(timestamp).tz(timezone)
  const startOfDay = moment(timestamp).startOf('day')
  const tMinutes = moment(timestamp).diff(startOfDay, 'minutes')
  const isBetweenMidnightAndStartHour = tMinutes < marketStartHour * 60
  const marketStart = isBetweenMidnightAndStartHour
    ? t.subtract(1, 'days').startOf('day').add(marketStartHour, 'hours')
    : t.startOf('day').add(marketStartHour, 'hours')
  return marketStart
}

/**
 * Determine if the selected date is part of the currently active trading day.
 *
 * @param {number} selectedDate Timestamp in miliseconds
 * @param {number} marketStartHour The hour a given market starts
 * @param {datetime} tradingDay Optional argument to specify a trading day other than today
 * @returns {moment} Time for the first selection in menu
 */
export function isSelectedDateActiveMarketDay(
  selectedDate: Moment | number,
  marketStartHour: number,
  tradingDay: Moment | number,
): boolean {
  if (!_.isNumber(marketStartHour)) {
    console.warn('isSelectedDateActiveMarketDay() - You did not provide market start hour parameter.')
  }
  const activeMarketStart = getMarketStartGivenTimestamp(moment(tradingDay), marketStartHour)
  const selectedDateStart = getMarketStartGivenTimestamp(selectedDate, marketStartHour)

  return (
    !_.isNil(selectedDate) &&
    moment(selectedDate).isValid() &&
    moment(selectedDateStart).isSame(activeMarketStart, 'day')
  )
}

/**
 * Generates the intervals for display
 *
 * @param nextInterval The next valid interval for startDate
 * @param startDate The date selected in the date picker
 * @param marketStartHr 4 (i.e. 04:00am)
 * @returns {[]} A list of intervals to display
 */
export const createIntervals = (
  nextInterval: Moment | number,
  startDate: Moment | number,
  marketStartHr: number,
  tz: string = 'Etc/GMT-10',
): Array<object> => {
  const nextIntervalStartDate = getMarketStartGivenTimestamp(nextInterval, marketStartHr)
  moment.tz.setDefault(tz)

  if (nextIntervalStartDate.isAfter(startDate, 'day')) {
    return []
  }

  const isSameDay = moment(nextIntervalStartDate).isSame(moment(startDate), 'day')
  const start = isSameDay ? moment(nextInterval) : moment(startDate)
  const marketStart = getMarketStartGivenTimestamp(moment(start), marketStartHr)
  const end = moment(marketStart).add(1, 'days')

  return createIntervalsFromStartEnd(start, end)
}

const createIntervalsFromStartEnd = (start: Moment | number, end: Moment | number): Array<object> => {
  const intervals: Array<object> = []
  const startTime = moment(start)
  const endTime = moment(end)

  if (!startTime.isValid() || !endTime.isValid() || endTime.isBefore(startTime)) {
    return intervals
  }

  while (startTime.diff(endTime) <= 0) {
    intervals.push({
      value: startTime.valueOf(),
      label: `${startTime.format('HH:mm')}`,
    })
    startTime.add(INTERVAL_SIZE_MINS, 'minutes')
  }
  return intervals
}

/**
 * Determines the time to begin intervals for display
 *
 * @param {moment parsable} time Either current time of render, or market start time for future trading days
 * @param {number} intervalCutoffSec Number of seconds into an interval where the next interval becomes invalid e.g. cutoff = 120 -> 04:05 becomes invalid and next interval is then 04:10
 * @returns {moment} Time for the first selection in menu
 */
export function getNextIntervalTime(time: Moment | number, intervalCutoffSec = 120): Moment | undefined {
  const input = moment(time)
  if (!input.isValid()) {
    return
  }
  const cutoffDuration = moment.duration(intervalCutoffSec, 'seconds')

  // With cutoff of 2 minutes the desired behavior: 03:57-04:01 will result in next interval 04:05
  // (Furmula assumes minute as unit of time)
  // Formula: floor(([input minute] - [interval cutoff minute]) / [interval offset]) * [interval size]
  // Examples:
  // 03:56 -> floor( ((56 - 2) / 5) + 2) * 5 -> floor((54 / 5) + 2) * 5 -> floor(10.8 + 2) * 5 -> floor(12.8) * 5 -> 12 * 5 -> 60 [next interval === 03:00 + 60 === 04:00]
  // 03:57 -> floor( ((57 - 2) / 5) + 2) * 5 -> floor((55 / 5) + 2) * 5 -> floor(11 + 2) * 5 -> floor(13) * 5 -> 13 * 5 -> 65     [next interval === 03:00 + 65 === 04:05]
  // 03:58 -> floor( ((58 - 2) / 5) + 2) * 5 -> floor((56 / 5) + 2) * 5 -> floor(11.2 + 2) * 5 -> floor(13.2) * 5 -> 13 * 5 -> 65 [next interval === 03:00 + 65 === 04:05]
  // 03:59 -> floor( ((59 - 2) / 5) + 2) * 5 -> floor((57 / 5) + 2) * 5 -> floor(11.4 + 2) * 5 -> floor(13.4) * 5 -> 13 * 5 -> 65 [next interval === 03:00 + 65 === 04:05]
  // 04:00 -> floor( ((0 - 2) / 5) + 2) * 5 -> floor((-2 / 5) + 2) * 5 -> floor(-0.4 + 2) * 5 -> floor(1.4) * 5 -> 1 * 5 -> 5     [next interval === 04:00 +  5 === 04:05]
  // 04:01 -> floor( ((1 - 2) / 5) + 2) * 5 -> floor((-1 / 5) + 2) * 5 -> floor(-0.2 + 2) * 5 -> floor(1.2) * 5 -> 1 * 5 -> 5     [next interval === 04:00 +  5 === 04:05]
  // 04:02 -> floor( ((2 - 2) / 5) + 2) * 5 -> floor((0 / 5) + 2) * 5 -> floor(0 + 2) * 5 -> floor(2) * 5 -> 2 * 5 -> 10          [next interval === 04:00 + 10 === 04:10]
  const currentHour = moment(time).hour()
  const currentMinute = moment(time).minute()
  const minute = currentMinute - cutoffDuration.asMinutes()
  const nextIntervalIndex = Math.floor(minute / INTERVAL_SIZE_MINS + 2)
  const startMinute = nextIntervalIndex * INTERVAL_SIZE_MINS

  return moment(time).startOf('day').add(currentHour, 'hours').add(startMinute, 'minutes')
}

/**
 * Determines what time the given interval will become invalid (i.e. expired).
 *
 * @param {moment parsable} time Either current time of render, or market start time for future trading days
 * @param {number} intervalCutoffSec Number of seconds into an interval where the next interval becomes invalid e.g. cutoff = 120 -> 04:05 becomes invalid and next interval is then 04:10
 * @returns {moment} Time when the next valid interval will become invalid (expire)
 */
export function getNextIntervalChange(time: Moment | number, intervalCutoffSec: number = 120): Moment {
  const nextAvailableInterval = getNextIntervalTime(time, intervalCutoffSec)
  return moment(nextAvailableInterval).subtract(INTERVAL_SIZE_MINS, 'minutes').add(intervalCutoffSec, 'seconds')
}

/**
 * Calculates whether a given time occurs during cutoff time.
 *
 * @param {moment parsable} time Either current time of render, or market start time for future trading days
 * @param {number} intervalCutoffSec Number of seconds into an interval where the next interval becomes invalid e.g. cutoff = 120 -> 04:05 becomes invalid and next interval is then 04:10
 * @param {number} marketStartHour The hour when the market day begins
 * @returns {boolean} Whether or not the given time occurs during the specified time buffer
 */
export const isPastEodCutoff = (
  time: Moment | number,
  intervalCutoffSec: number,
  marketStartHour: number = 4,
): boolean => {
  if (!moment(time).isValid) {
    return false
  }
  const activeMarketStart = getMarketStartGivenTimestamp(moment(time), marketStartHour)
  const activeMarketEnd = moment(activeMarketStart).endOf('day').add(marketStartHour, 'hours')
  const timeUntilEOD = moment.duration(moment(activeMarketEnd).diff(moment(time)))

  return timeUntilEOD.asMinutes() <= INTERVAL_SIZE_MINS - moment.duration(intervalCutoffSec, 'seconds').asMinutes()
}
