import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Checkbox, Divider, Grid, Switch, Typography } from '@material-ui/core'
import { Chat, Email } from '@material-ui/icons'
import { Button, CheckBox, CheckBoxOutlineBlank } from '@fluence/core'
import { getIcon } from '../../utility/utility'
import Card from '../Card'
import RouteLeavingGuard from '../RouteLeavingGuard'

const useStyles = makeStyles(theme => ({
  root: {},
  card: {},
  cardContent: {
    paddingTop: theme.spacing(4),
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
  },
  checkbox: {
    padding: 4,
  },
  columnHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    height: 1,
    flexBasis: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labelIcon: {
    marginRight: theme.spacing(0.5),
  },
  switchBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1.5),
  },
  subgroupDisplayName: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lastSubgroupItem: {
    marginBottom: theme.spacing(1),
  },
}))

function NotificationSelections(props) {
  const {
    alertGroups,
    getUser,
    setUser,
    saveUser,
    notifySuccess,
    notifyError,
    initialEmailSelected,
    initialSmsSelected,
  } = props
  const classes = useStyles()

  const assets = useSelector(state => _.get(state, 'dashboard.assets.payload', []))
  const ignoreNames = ['internal_alerts']
  const newAlertGroups = alertGroups.filter(alert => {
    return !ignoreNames.includes(_.get(alert, 'group_name')) && !_.get(alert, 'deprecated')
  })

  const userAssetTypes = assets.reduce((acc, next) => {
    acc.add(_.get(next, 'data.tag_prefix'))
    return acc
  }, new Set())

  const [inProgress, setInProgress] = useState(false)
  const [values, setValues] = useState({})
  const [touched, setTouched] = useState({})

  const handleChange = groupNames => event => {
    const value = _.get(event, 'target.value')
    const checked = _.get(event, 'target.checked')

    const isGroupSelectInput = _.isArray(groupNames)
    if (isGroupSelectInput) {
      const shouldSelectAll = groupNames.some(groupName => !_.get(values, `${groupName}.${value}`))
      setValues(prev => {
        const newValues = groupNames.reduce((acc, groupName) => {
          const selection = { ..._.get(prev, groupName, {}) }
          selection[value] = shouldSelectAll
          return { ...acc, [groupName]: selection }
        }, {})

        setTouched(prevTouched => {
          const newTouchedValues = groupNames.reduce((acc, groupName) => {
            const selection = { ..._.get(prevTouched, groupName, {}) }
            selection[value] = true
            return { ...acc, [groupName]: selection }
          }, {})
          return {
            ...prevTouched,
            ...newTouchedValues,
          }
        })

        return {
          ...prev,
          ...newValues,
        }
      })
      return
    }
    const groupName = groupNames
    const previousValues = values[groupName]
    setValues({
      ...values,
      [groupName]: {
        ...previousValues,
        [value]: checked,
      },
    })
    setTouched({
      ...values,
      [groupName]: {
        ...previousValues,
        [value]: true,
      },
    })
  }

  const groupNames = alertGroups
    .flatMap(group => {
      const assetTypes = _.get(group, 'alert_type_names')
      if (_.isObject(_.first(assetTypes))) {
        return assetTypes.map(type => _.get(type, 'group_name', 'name_undefined'))
      } else {
        return _.get(group, 'group_name', 'name_undefined')
      }
    }, [])
    .sort()
  const groupNamesJSON = JSON.stringify(groupNames)

  // This form shares its initial values with multiple forms, we are building in some logic here to deal with changes that may happen outside of this component
  useEffect(() => {
    if (!inProgress) {
      const groupNames = JSON.parse(groupNamesJSON)
      setTouched(prevTouched => {
        setValues(prevValues => {
          return groupNames.reduce((acc, groupName) => {
            const email = _.get(prevTouched, `${groupName}.email`, false)
              ? prevValues[groupName].email
              : initialEmailSelected.includes(groupName)
            const sms = _.get(prevTouched, `${groupName}.sms`, false)
              ? prevValues[groupName].sms
              : initialSmsSelected.includes(groupName)
            return { ...acc, [groupName]: { email, sms } }
          }, {})
        })
        return groupNames.reduce((acc, groupName) => {
          const email = _.get(prevTouched, `${groupName}.email`, false)
          const sms = _.get(prevTouched, `${groupName}.sms`, false)
          return { ...acc, [groupName]: { email, sms } }
        }, {})
      })
    }
  }, [groupNamesJSON, initialEmailSelected, initialSmsSelected, inProgress])

  const isDirty = () => {
    if (_.isEmpty(values)) {
      return false
    }
    // eslint-disable-next-line no-unused-vars
    for (const groupName of _.keys(values)) {
      const { email, sms } = values[groupName]

      const isEmailDirty = email !== initialEmailSelected.includes(groupName)
      const isSmsDirty = sms !== initialSmsSelected.includes(groupName)

      if (isEmailDirty || isSmsDirty) {
        return true
      }
    }
    return false
  }

  const handleSubmit = event => {
    event.preventDefault()
    setInProgress(true)

    getUser().then(userResponse => {
      if (userResponse.error) {
        notifyError('Save was unsuccessful')
        setInProgress(false)
      } else {
        const userToSave = _.cloneDeep(userResponse.payload)

        // We grab the whole user from latest then set only the values from this form.
        const emails = _.keys(values).filter(groupName => values[groupName].email)
        const sms = _.keys(values).filter(groupName => values[groupName].sms)
        _.set(userToSave, 'preferences.marketAlerts.email', emails)
        _.set(userToSave, 'preferences.marketAlerts.sms', sms)

        return saveUser(userToSave).then(saveUserResponse => {
          if (saveUserResponse.error) {
            notifyError('Something went amiss. User was not saved.')
            setInProgress(false)
          } else {
            notifySuccess('Preferences saved')
            setTouched({})

            // Update redux with our changes instead of making yet another ajax call to refresh
            setUser(userToSave)
            setInProgress(false)
          }
        })
      }
    })
  }

  const disableSave =
    inProgress || _.keys(touched).every(groupName => !touched[groupName].email && !touched[groupName].sms) || !isDirty()

  return (
    <>
      <RouteLeavingGuard description="Notification Selections" when={!disableSave} />
      <Card
        classes={{ root: classes.card, content: classes.cardContent }}
        title="Notification selections"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          <Button variant="text" disabled={disableSave} onClick={handleSubmit}>
            save
          </Button>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row-reverse" spacing={4}>
              <Grid item xs={5}>
                <Grid container justify="space-around">
                  <Grid item xs={6} className={classes.gridItem}>
                    <Typography className={classes.columnHeader} align="center" color="textSecondary">
                      <Email fontSize="small" className={classes.labelIcon} /> EMAIL
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.columnHeader} align="center" color="textSecondary">
                      <Chat fontSize="small" className={classes.labelIcon} /> SMS
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {_.keys(values).length > 0 &&
            newAlertGroups.map((alertGroup, index) => {
              const groupName = _.get(alertGroup, 'group_name', 'name_undefined')
              const groupNameDisplay = _.capitalize(_.get(alertGroup, 'group_name', '')).replace(/[\W_]/g, ' ')
              const description = _.get(alertGroup, 'description', '')
              const key = groupName + index
              const assetTypes = _.get(alertGroup, 'asset_types', [])
              const icons = assetTypes.reduce((acc, assetType) => {
                if (userAssetTypes.has(assetType)) {
                  acc.push(getIcon(assetType))
                }
                return acc
              }, [])
              const alertTypeNames = _.get(alertGroup, 'alert_type_names', [])
              const hasSubgroups = _.isObject(_.first(alertTypeNames))
              const subgroups = hasSubgroups && alertTypeNames
              const handleChangeInput = hasSubgroups
                ? alertTypeNames.map(alertType => _.get(alertType, 'group_name'))
                : groupName
              const emailSelected = hasSubgroups
                ? subgroups.every(subgroup => _.get(values, `${_.get(subgroup, 'group_name', 'name_undefined')}.email`))
                : _.get(values, `${groupName}.email`, false)
              const smsSelected = hasSubgroups
                ? subgroups.every(subgroup => _.get(values, `${_.get(subgroup, 'group_name', 'name_undefined')}.sms`))
                : _.get(values, `${groupName}.sms`, false)
              const hasAssetType = assetTypes.some(assetType => userAssetTypes.has(assetType))
              return (
                <React.Fragment key={key}>
                  {!!hasAssetType && (
                    <>
                      <Grid item xs={7} zeroMinWidth>
                        <Box display="flex" alignItems="center">
                          <Typography component="span" variant="h4">
                            {groupNameDisplay}
                          </Typography>
                          <Box ml={2} pt={1} flex="1 0 auto" flexWrap="nowrap">
                            {icons.map((Icon, i) => (
                              <span key={i}>
                                <Icon data-test-id={`ns-alert-type-${i}`} fontSize="small" />
                              </span>
                            ))}
                          </Box>
                        </Box>
                        <Typography variant="body1" color="textSecondary">
                          {description}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Grid container justify="space-around">
                          <Grid item xs={6}>
                            <div className={classes.switchBox}>
                              <Switch
                                checked={emailSelected}
                                color="primary"
                                onChange={handleChange(handleChangeInput)}
                                value="email"
                                inputProps={{ 'aria-label': 'toggle email' }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={classes.switchBox}>
                              <Switch
                                checked={smsSelected}
                                color="primary"
                                className={classes.switch}
                                onChange={handleChange(handleChangeInput)}
                                value="sms"
                                inputProps={{ 'aria-label': 'toggle sms notification for ${}' }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {hasSubgroups && (
                        <>
                          <Divider className={classes.divider} flexItem />
                          {subgroups.map((subgroup, i) => {
                            const groupName = _.get(subgroup, 'group_name', 'name_undefined')
                            const subEmailSelected = _.get(values, `${groupName}.email`)
                            const subSmsSelected = _.get(values, `${groupName}.sms`)

                            return (
                              <React.Fragment key={i}>
                                <Grid item xs={7} className={classes.subgroupDisplayName}>
                                  <Typography variant="body1" color="textSecondary" align="right">
                                    {_.get(subgroup, 'display_name', '')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Grid container justify="space-around">
                                    <Grid item xs={6}>
                                      <Box display="flex" justifyContent="center">
                                        <Checkbox
                                          size="small"
                                          checked={subEmailSelected}
                                          className={classes.checkbox}
                                          onChange={handleChange(groupName)}
                                          checkedIcon={<CheckBox fontSize="small" />}
                                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                                          value="email"
                                          color="primary"
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Box display="flex" justifyContent="center">
                                        <Checkbox
                                          size="small"
                                          checked={subSmsSelected}
                                          className={classes.checkbox}
                                          onChange={handleChange(groupName)}
                                          checkedIcon={<CheckBox fontSize="small" />}
                                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                                          value="sms"
                                          color="primary"
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )
                          })}
                        </>
                      )}
                      {index !== newAlertGroups.length - 1 && <Divider className={classes.divider} flexItem />}
                    </>
                  )}
                </React.Fragment>
              )
            })}
        </Grid>
      </Card>
    </>
  )
}

NotificationSelections.displayName = 'NotificationSelections'

export default NotificationSelections
