import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography, Switch } from '@material-ui/core'
import { Button, useNotifier } from '@fluence/core'
import {
  fcasSelector,
  bidStrategySettingSelector,
  bidStrategySettingResource,
  upsertAssetDataResource,
  allFCASResource,
} from '../../redux/features/setting'
import { canAdjustRiskAppetite } from '../../utility/user-utils'
import Card from '../Card'
import RouteLeavingGuard from '../RouteLeavingGuard'

const DISPLAY_NAME = 'FCASCard'

const useStyles = makeStyles(theme => ({
  card: {
    background: _.get(theme, 'palette.background.paper', '#1D2126'),
  },
  cardContent: {
    padding: 0,
    maxHeight: theme.spacing(20),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    borderBottom: `2px solid ${_.get(theme, 'palette.background.default', '#2f353d')}`,
  },
  activeItem: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  noCover: {
    marginRight: theme.spacing(1.5),
  },
}))

function FCASCard(props) {
  const { asset, useFcasCostRecovery, bidStrategySetting, getCurrentFCASSetting, permissions } = props
  const { dispatchCreateAssetData, dispatchGetAllFcasRecords } = props
  const { notifySuccess, notifyError } = useNotifier()
  const [useFcasCostRecoveryEdited, setUseFcasCostRecoveryEdited] = useState(useFcasCostRecovery)

  const classes = useStyles()

  const assetId = _.get(asset, 'assetId')
  const hasPermission = canAdjustRiskAppetite(permissions, asset)

  useEffect(() => {
    if (!_.isNil(assetId)) {
      getCurrentFCASSetting(assetId, moment())
    }
  }, [assetId, getCurrentFCASSetting])

  useEffect(() => {
    setUseFcasCostRecoveryEdited(useFcasCostRecovery)
  }, [useFcasCostRecovery])

  const onSave = () => {
    const assetData = {
      ...bidStrategySetting,
      use_fcas_cost_recovery_in_opportunity_price: useFcasCostRecoveryEdited,
    }
    return dispatchCreateAssetData(assetId, 'bid_strategy_setting', moment(), assetData).then(response => {
      if (response.error) {
        notifyError('Unable to save new CR-FCAS setting')
        return response
      } else {
        dispatchGetAllFcasRecords(asset)
        return getCurrentFCASSetting(assetId, moment()).then(response => {
          if (response.error) {
            notifyError('Unable to retrieve updated values')
          }
          notifySuccess(`CR-FCAS successfully changed to ${useFcasCostRecoveryEdited}`)
          return response
        })
      }
    })
  }

  const modified = useFcasCostRecoveryEdited !== useFcasCostRecovery

  return (
    <>
      <RouteLeavingGuard description="CR-FCAS Card" when={modified} />
      <Card
        classes={{
          root: classes.card,
          content: classes.cardContent,
          header: classes.cardHeader,
        }}
        title="CR-FCAS"
        titleTypographyProps={{ variant: 'h3' }}
        action={
          hasPermission && (
            <Button variant="text" onClick={onSave} disabled={useFcasCostRecoveryEdited === useFcasCostRecovery}>
              SAVE
            </Button>
          )
        }
      >
        <Grid container spacing={0} item xs={12} className={classes.activeItem}>
          <Grid item xs={10}>
            <Typography variant="body1" color="textPrimary">
              Optimise for CR-FCAS Cost
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.noCover}>
              Include CR-FCAS costs in optimisation decisions
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box pt={2.5} display="flex" justifyContent="flex-end">
              <Switch
                checked={useFcasCostRecoveryEdited}
                color="primary"
                onChange={() => setUseFcasCostRecoveryEdited(!useFcasCostRecoveryEdited)}
                value="optimise"
                inputProps={{ 'aria-label': 'toggle optimise for cr-fcase cost' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
FCASCard.displayName = DISPLAY_NAME

const mapStateToProps = state => {
  const mappedState = {
    useFcasCostRecovery: fcasSelector(state),
    bidStrategySetting: bidStrategySettingSelector(state),
    permissions: _.get(state, 'user.current.payload.permissions', {}),
  }
  return mappedState
}

const mapDispatchToProps = dispatch => {
  return {
    getCurrentFCASSetting: (assetId, asOf) => dispatch(bidStrategySettingResource.get(assetId, asOf)),
    dispatchCreateAssetData: (assetId, tag, startTime, data) =>
      dispatch(upsertAssetDataResource.post(assetId, tag, startTime, data)),
    dispatchGetAllFcasRecords: asset => {
      const commercialOnlineDate = _.get(asset, 'data.commercial_online_date')
      if (commercialOnlineDate) {
        const startTime = moment(commercialOnlineDate)
        return Promise.resolve(dispatch(allFCASResource.get(asset.assetId, startTime, moment())))
      } else {
        return Promise.reject(new Error('Unable to update FCAS records'))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FCASCard)
