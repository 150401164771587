import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Modal, Typography } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import { Button } from '@fluence/core'

const HEADER_HEIGHT = 56
const FOOTER_HEIGHT = 64

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(HEADER_HEIGHT),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.background.paper}`,
  },
  body: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + theme.spacing(8)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1, 2, 6),
  },
  paper: {
    position: 'absolute',
    top: '51%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: 400,
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
  foot: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: FOOTER_HEIGHT,
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.paper}`,
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3.5),
    right: theme.spacing(-0.75),
  },
  primaryBtn: {
    height: theme.spacing(4),
    padding: theme.spacing(0.5, 1.75),
  },
}))

function ScheduledChangesInfo(props) {
  const { open = false, onClose } = props
  const classes = useStyles()

  const handleClose = event => {
    onClose(event)
  }

  const rootClasses = clsx(classes.root, classes.paper)

  return (
    <Modal aria-labelledby="appetite-info-title" aria-describedby="appetite-body" open={open} onClose={onClose}>
      <div className={rootClasses}>
        <div className={classes.head}>
          <Box flex="0 1 auto">
            <Typography id="appetite-info-title" variant="h2" className={classes.title}>
              Scheduled changes
            </Typography>
          </Box>
          <Box flex="0 1 auto">
            <IconButton className={classes.closeBtn} size="small" onClick={handleClose}>
              <Clear fontSize="small" />
            </IconButton>
          </Box>
        </div>

        <div id="appetite-body" className={classes.body}>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Select a day from the calendar drop-down to see your charge or discharge scheduled changes sorted by
              creation date.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Interval
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Scheduled Change Intervals report the initial scheduled start time and end time as 'Start Time - End
              Time.' Newer scheduled changes will supercede any overlap between the conflicting effective times.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Limits
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Scheduled changes that restrict the power throughput to be no greater than the specified MW value (achieved by changing the bid MaxAvail).
            </Typography>
          </Box><Box mb={1}>
          <Typography variant="h4" className={classes.title}>
            State of Charge
          </Typography>
        </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Scheduled changes that restrict optimisation so that it operates within the specified charge levels, expressed as a percentage of the asset’s energy capacity.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Charge and Discharge
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Scheduled Changes that change Power are reported in MW. Scheduled Changes that change Energy are reported
              in MWh.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Frequency of Change
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Scheduled Changes may be scheduled as a single event or as a recurring event on a weekly basis.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Created
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              The Created column reports the creation time. The entries are sorted from newest to oldest by creation
              time.
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="h4" className={classes.title}>
              Deletion
            </Typography>
          </Box>
          <Box pt={2} mb={4}>
            <Typography variant="body1" color="textPrimary">
              Deleting a recurring scheduled change will delete the selected scheduled change and future occurrences of
              the scheduled change. Deleted scheduled changes and their history will no longer be visible.
            </Typography>
          </Box>
        </div>

        <div className={classes.foot}>
          <Button variant="primary" className={classes.primaryBtn} onClick={handleClose}>
            close
          </Button>
        </div>
      </div>
    </Modal>
  )
}

ScheduledChangesInfo.displayName = 'ScheduledChangesInfo'

export default ScheduledChangesInfo
