import React from 'react'
import NumberFormat from 'react-number-format'

const DISPLAY_NAME = 'InputNumberFormat'

function InputNumberFormat(props) {
  const { inputRef, isNumericString = true, onChange, prefix = '', thousandSeparator = true, ...other } = props

  const handleOnValueChange = values => {
    if (onChange) {
      onChange({
        target: {
          value: values.value,
        },
      })
    }
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleOnValueChange}
      thousandSeparator={thousandSeparator}
      isNumericString={isNumericString}
      prefix={prefix}
    />
  )
}

InputNumberFormat.displayName = DISPLAY_NAME

export default InputNumberFormat
