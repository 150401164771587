import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableHead, TableRow, TableCell, Input, InputAdornment } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import colors from '../../../config/colors'

const useStyles = makeStyles(
  theme => ({
    disabled: {},
    topPadding: {
      paddingTop: theme.spacing(1),
    },
    table: {
      borderCollapse: 'separate',
      '& td': {
        verticalAlign: 'bottom',
        borderBottom: `1px solid`,
        padding: theme.spacing(1),
        fontSize: 14,
      },
      '& td.editable': {
        padding: theme.spacing(0, 1),
        fontWeight: 'bold',
      },
      '& td:first-child': {
        textTransform: 'uppercase',
        textAlign: 'right',
        fontSize: 12,
        borderRight: `1px solid`,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid`,
      },
      '& th': {
        padding: '8px 10px 8px 6px',
        borderBottom: `1px solid`,
      },
      '& th:first-child': {
        borderBottom: `1px solid`,
      },
      '& th:last-child': {
        paddingRight: 8,
      },
    },
    InputRoot: {
      padding: '1px 8px 2px 1px',
    },
    input: {
      direction: 'ltr',
      textAlign: 'right',
      padding: '6px 4px 4px',
    },
    underline: {
      marginBottom: -1,
      padding: '6px 0px 6px 2px',
      '&:before': {
        borderBottom: '1px solid',
      },
      '&:after': {
        borderBottom: `1px solid ${colors.CORNFLOWER_BLUE}`,
      },
      '&:hover:not($disabled):before': {
        borderBottom: `1px solid`,
      },
    },
    firstHeaderCell: {
      minWidth: 75,
    },
    headerCell: {
      minWidth: 65,
    },
    editableCell: {
      '&td': {
        padding: theme.spacing(0, 0.5),
      },
    },
    textColorDefault: {
      color: theme.palette.text.secondary,
    },
    textColorModified: {
      color: colors.CALIFORNIA,
    },
    smallFont: { fontSize: 14 },
    test: {},
  }),
  { name: 'EditableTable' },
)

export default function EditableTable(props) {
  const {
    checkInputValueAllowed = () => true,
    denseTableConfig,
    disabled,
    getInputCellProps,
    handleTextFieldChange,
    submitSettings,
  } = props
  const classes = useStyles(props)
  const { rowGroupKey, cellSortBy, columnGroupKey, rowSortBy, columnSortBy } = denseTableConfig
  const submitSettingsGroupedByColumns = _.groupBy(submitSettings, columnGroupKey)
  const submitSettingsGroupedByRows = _.groupBy(submitSettings, rowGroupKey)
  const sortedColumnItems = _.keys(submitSettingsGroupedByColumns).sort(columnSortBy)
  const sortedRowItems = _.keys(submitSettingsGroupedByRows).sort(rowSortBy)

  return (
    <Table size="small" className={clsx(classes.table, classes.topPadding)}>
      <TableHead>
        <TableHeadRow headers={sortedColumnItems} />
      </TableHead>
      <TableBody>
        {!_.isEmpty(submitSettings) &&
          sortedRowItems.map(key => {
            return (
              <EditTableRow
                checkInputValueAllowed={checkInputValueAllowed}
                disabled={disabled}
                getInputCellProps={getInputCellProps}
                handleTextFieldChange={handleTextFieldChange}
                key={key}
                newInput={submitSettings}
                rowName={key}
                settings={submitSettingsGroupedByRows[key]}
                sortSettingsBy={cellSortBy}
              />
            )
          })}
      </TableBody>
    </Table>
  )
}

EditableTable.propTypes = {
  checkInputValueAllowed: PropTypes.func,
  denseTableConfig: PropTypes.object,
  disabled: PropTypes.bool,
  getInputCellProps: PropTypes.func,
  handleTextFieldChange: PropTypes.func,
  submitSettings: PropTypes.array,
}

export const TableHeadRow = props => {
  const classes = useStyles()
  const { headers } = props
  return (
    <TableRow>
      <TableCell className={classes.firstHeaderCell} rowSpan={2} align="right" />
      {headers.map(header => (
        <TableCell className={classes.headerCell} key={header} align="right">
          {header}
        </TableCell>
      ))}
    </TableRow>
  )
}

const EditTableRow = props => {
  const {
    checkInputValueAllowed,
    disabled,
    getInputCellProps,
    handleTextFieldChange,
    rowName,
    settings,
    sortSettingsBy,
    newInput,
  } = props
  const classes = useStyles()
  const sortedSettings = settings.sort(sortSettingsBy)
  return (
    <TableRow>
      <TableCell>{rowName}</TableCell>
      {sortedSettings.map((setting, index) => {
        const { inputValues, inputLabel, showInputValueError } = getInputCellProps(setting)
        const userInputValue = parseFloat(_.get(inputValues, inputLabel))
        const initialValue = parseFloat(_.get(setting, 'current'))
        const displayInputValue = disabled ? initialValue : userInputValue
        return (
          !_.isNil(newInput) && (
            <IndividualInputCell
              checkInputValueAllowed={checkInputValueAllowed}
              classes={classes}
              disabled={disabled}
              error={showInputValueError && !disabled}
              handleTextFieldChange={handleTextFieldChange}
              key={_.concat(_.get(setting, rowName), index)}
              initialValue={initialValue}
              inputLabel={inputLabel}
              index={index}
              newInput={displayInputValue}
              setting={setting}
            />
          )
        )
      })}
    </TableRow>
  )
}
EditTableRow.propTypes = {
  checkInputValueAllowed: PropTypes.func,
  getInputCellProps: PropTypes.func,
  handleTextFieldChange: PropTypes.func,
  newInput: PropTypes.array,
  settings: PropTypes.array,
}

const IndividualInputCell = props => {
  const {
    checkInputValueAllowed,
    classes,
    disabled,
    error = false,
    handleTextFieldChange,
    index,
    initialValue,
    inputLabel,
    newInput,
    setting,
  } = props
  // TODO: Replace
  const numDecimalPlaces = 3
  const { endAdornment = '', inputAdornment = '$' } = setting

  return (
    <TableCell key={index} size="small" className="editable">
      <Input
        disabled={disabled}
        error={error}
        endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : null}
        startAdornment={inputAdornment ? <InputAdornment position="start">{inputAdornment}</InputAdornment> : null}
        inputComponent={InputNumberFormat}
        inputProps={{
          decimalScale: numDecimalPlaces,
          isAllowed: values => checkInputValueAllowed(setting, values, inputLabel),
        }}
        onChange={handleTextFieldChange(inputLabel)}
        value={!_.isNil(parseFloat(newInput)) ? parseFloat(newInput) : '-'}
        classes={{
          root: classes.InputRoot,
          input: classes.input,
          underline: classes.underline,
        }}
        className={clsx(
          {
            [classes.textColorDefault]: parseFloat(initialValue) === parseFloat(newInput),
            [classes.textColorModified]: checkInitialInputModified(initialValue, newInput),
          },
          classes.smallFont,
        )}
      />
    </TableCell>
  )
}

function InputNumberFormat(props) {
  const { inputRef, onChange, prefix = '', ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      isNumericString
      prefix={prefix}
    />
  )
}

const checkInitialInputModified = (initialValue, newInput) => {
  const floatInitialValue = parseFloat(initialValue)
  const floatInputInitialValue = parseFloat(newInput)
  return (
    parseFloat(initialValue) !== parseFloat(newInput) && (!isNaN(floatInitialValue) || !isNaN(floatInputInitialValue))
  )
}

InputNumberFormat.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
}

IndividualInputCell.propTypes = {
  classes: PropTypes.object,
  handleTextFieldChange: PropTypes.func,
  index: PropTypes.number,
  initialValue: PropTypes.number,
  newInput: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setting: PropTypes.object,
}
