import { combineReducers } from 'redux'
import _ from 'lodash'
import TYPES from './types'

const assets = (state = { all: [], map: {} }, action) => {
  switch (action.type) {
    case TYPES.AF_GET_ALL_ASSETS.success:
      if (_.isEqual(action.payload, state.all)) {
        return state
      }
      return {
        ...state,
        all: action.payload,
        map: _.keyBy(action.payload, 'assetId'),
        allUpdatedOn: _.get(action, 'meta.updatedOn'),
      }
    case TYPES.AF_UPDATE_RISK.root: {
      // NOTE: This will also update the 'all' assets as 'map' is a reference to the 'all' objects
      const newState = { ...state }
      _.set(newState, ['map', action.payload.assetId, 'data', 'risk_setting'], action.payload.riskSetting)
      return newState
    }
    case TYPES.AF_UPDATE_ASSETDATA.root: {
      // NOTE: This will also update the 'all' assets as 'map' is a reference to the 'all' objects
      const newState = { ...state }
      const assetData = _.get(state, ['map', action.payload.assetId, 'data'])
      const newAssetData = {
        ...assetData,
        [action.payload.property]: action.payload.data,
      }
      _.set(newState, ['map', action.payload.assetId, 'data'], newAssetData)
      return newState
    }
    default:
      return state
  }
}

const selected = (state = { assetId: null, isOptimizationDisabled: null }, action) => {
  switch (action.type) {
    case 'SELECT_ASSET_ID':
      return {
        ...state,
        assetId: action.payload,
      }
    case TYPES.ASSET_GET_OPT_DISABLED.success:
      return {
        ...state,
        isOptimizationDisabled: _.get(action, 'payload.data.isOptimizationDisabled', false),
      }
    default:
      return state
  }
}

export default combineReducers({
  assets,
  selected,
})
