import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Graph, GraphGroup } from '../../graph/GraphGroup'
import * as common from '../../../graphs/common'
import * as assetForecastGraphConfigs from '../../../graphs/assetForecast'
import {
  availabilityTag,
  enablementTag,
  findProduct,
  getTimeAtStartOf,
  isFalsy,
  marketPriceTag,
  settlementTag,
} from '../../../utility/utility'
import { performObjectsWithArraysArithmetic, ADDITION_TYPE, SUBTRACTION_TYPE } from '../../../utility/data-stream-utils'
import {
  FIVE_MINUTE_DURATION,
  ONE_HOUR_DURATION,
  MARKET_PRICE_ENERGY_MINIMUM,
  MARKET_PRICE_FCAS_MINIMUM,
  PRODUCT_TYPES,
  PRODUCT_NAMES,
} from '../../../utility/constants'
import { isAssetNonScheduledBattery } from '../../../utility/asset-utils'
import { getTimeSeriesGraph } from '../../../utility/graphUtility'
import { enablementGraphTemplate, priceGraphTemplate, settlementGraphTemplate } from '../../../utility/graphTemplate'
import { getChartData } from '../../../redux/features/interval'
import { globalAppSelectors } from '../../../redux/features/app'

const TITLES = ['Market Prices', 'Enablements and Availability', 'Settlements']
const [TITLE_PRICES, TITLE_ENABLEMENTS, TITLE_SETTLEMENTS] = TITLES

const DISPLAY_NAME = 'ForecastGraphs'
const IS_LOADING_OBJ = { isLoading: true }

const stripLineOptions = {
  type: 'line',
  name: 'stripLine',
  visible: false,
  axisXType: 'secondary',
  axisYType: 'secondary',
}

export const showP50Data = (selectedDate, p50CutoffDate) => {
  const releaseDate = moment(p50CutoffDate)
  return selectedDate.isBefore(releaseDate)
}
export const optForecastOrP50Title = (selectedDate, p50CutoffDate) => {
  return showP50Data(selectedDate, p50CutoffDate) ? 'Fluence P50' : 'Fluence Weighted'
}

const ForecastGraphs = props => {
  const {
    asset,
    displayRange,
    endTime,
    getMarketPriceCap,
    horizon,
    inProgress,
    p50CutoffDate,
    productName,
    productType,
    setDisplayRange,
    startTime,
    timezone,
  } = props

  const { forecastIntervals } = props
  const range = {
    start: moment(startTime).unix(),
    end: moment(endTime).unix(),
  }

  const marketStartHour = _.get(asset, 'market.data.trading_day_start_hour')
  const regionId = _.get(asset, 'region.regionId')
  const bidInterval = _.get(asset, 'market.data.bid_interval') * 60
  const product = findProduct(asset, productName, productType)
  const productId = _.get(product, 'productId')
  const priceProduct = findProduct(asset, productName, _.get(asset, 'market.data.default_product_type'))
  const priceProductId = _.get(priceProduct, 'productId')

  const priceData = React.useMemo(() => {
    if (
      inProgress ||
      _.isEmpty(forecastIntervals) ||
      _.isEmpty(asset) ||
      _.isNil(regionId) ||
      !moment(startTime).isValid() ||
      !moment(endTime).isValid()
    ) {
      return IS_LOADING_OBJ
    }
    const priceIntervalKey = `${asset.assetId}_${priceProductId}_${marketPriceTag}_${horizon}`
    const intervalDataFromState = getIntervalDataFromState(
      forecastIntervals,
      asset,
      startTime,
      endTime,
      priceIntervalKey,
    )
    return intervalDataFromState
  }, [forecastIntervals, asset, startTime, priceProductId, horizon, endTime, inProgress, regionId])

  const enablementData = useMemo(() => {
    if (
      inProgress ||
      [asset, forecastIntervals, moment(startTime).isValid(), moment(endTime).isValid()].some(isFalsy)
    ) {
      return IS_LOADING_OBJ
    }
    return extractIntervalDataFromState(
      asset,
      enablementTag,
      productId,
      productName,
      horizon,
      forecastIntervals,
      startTime,
      endTime,
      false,
    )
  }, [inProgress, asset, forecastIntervals, startTime, endTime, productId, horizon, productName])

  const availabilityData = useMemo(() => {
    if (
      inProgress ||
      [asset, forecastIntervals, moment(startTime).isValid(), moment(endTime).isValid()].some(isFalsy)
    ) {
      return IS_LOADING_OBJ
    }
    return extractIntervalDataFromState(
      asset,
      availabilityTag,
      productId,
      productName,
      horizon,
      forecastIntervals,
      startTime,
      endTime,
      false,
    )
  }, [inProgress, asset, forecastIntervals, startTime, endTime, productId, horizon, productName])

  const settlementData = useMemo(() => {
    if (
      inProgress ||
      [asset, forecastIntervals, moment(startTime).isValid(), moment(endTime).isValid()].some(isFalsy)
    ) {
      return IS_LOADING_OBJ
    }
    return extractIntervalDataFromState(
      asset,
      settlementTag,
      productId,
      productName,
      horizon,
      forecastIntervals,
      startTime,
      endTime,
      true,
    )
  }, [inProgress, asset, forecastIntervals, startTime, endTime, productId, horizon, productName])

  const [priceGraphData, setPriceGraphData] = useState({
    pricesActual: null,
    p10P90: null,
    p50: null,
    preDispatch: null,
  })

  const [enablementsGraphData, setEnablementsGraphData] = useState({
    enablementsActual: null,
    enablementsActualAvailability: null,
    enablementsForecast: null,
    enablementsForecastAvailability: null,
  })

  const [settlementsGraphData, setSettlementsGraphData] = useState({
    settlementsActual: null,
    settlementsForecast: null,
  })

  useEffect(() => {
    if ([asset, availabilityData, enablementData, priceData, settlementData].some(_.isEmpty)) {
      return
    }

    const displayNames = TITLES.map(title => getDisplayNames(title, moment(startTime), p50CutoffDate))
    const dataList = [
      transformPriceData(priceData, moment(startTime), p50CutoffDate),
      combineEnablementAvailabilityData(enablementData, availabilityData),
      settlementData,
    ]

    const graphs = dataList.map((line, index) => {
      const title = TITLES[index]
      const graphTemplate = _.cloneDeep(getTemplate(title))
      const extras = { colors: getColors(asset, title) }
      const graph = getTimeSeriesGraph(
        graphTemplate,
        title,
        displayNames[index],
        line.data || {},
        timezone,
        bidInterval,
        extras,
      )
      graph.isLoading = line.isLoading
      return graph
    })

    const [pricesGraph, enablementsGraph, settlementsGraph] = graphs
    const [p10P90, p50, preDispatch, pricesActual] = _.get(pricesGraph, 'series', [])
    setPriceGraphData({
      isLoading: pricesGraph.isLoading,
      pricesActual,
      p10P90,
      p50,
      preDispatch,
    })

    const [enablementsForecast, enablementsActual, enablementsForecastAvailability, enablementsActualAvailability] =
      _.get(enablementsGraph, 'series', [])
    setEnablementsGraphData({
      isLoading: enablementsGraph.isLoading,
      enablementsActual,
      enablementsActualAvailability,
      enablementsForecast,
      enablementsForecastAvailability,
    })

    const [settlementsForecast, settlementsActual] = _.get(settlementsGraph, 'series', [])
    setSettlementsGraphData({
      isLoading: settlementsGraph.isLoading,
      settlementsActual,
      settlementsForecast,
    })
  }, [asset, availabilityData, bidInterval, enablementData, priceData, settlementData, timezone, startTime, p50CutoffDate])

  const getPriceData = useCallback(async () => {
    const { pricesActual, p10P90, p50, preDispatch } = priceGraphData
    const p10P90Options = _.get(p10P90, 'options', {})
    p10P90Options.type = 'rangeArea'
    p10P90Options.fillOpacity = 0.2
    p10P90Options.lineThickness = 1

    return {
      p10P90Options,
      p50Options: _.get(p50, 'options'),
      preDispatchOptions: _.get(preDispatch, 'options'),
      actualOptions: _.get(pricesActual, 'options'),
      stripLineOptions,
    }
  }, [priceGraphData])

  const getEnablementsData = useCallback(async () => {
    const { enablementsActual, enablementsActualAvailability, enablementsForecast, enablementsForecastAvailability } =
      enablementsGraphData
    const enablementsForecastAvailabilityOptions = _.get(enablementsForecastAvailability, 'options', {})
    enablementsForecastAvailabilityOptions.lineDashType = 'dash'
    const enablementsActualAvailabilityOptions = _.get(enablementsActualAvailability, 'options', {})
    enablementsActualAvailabilityOptions.lineDashType = 'dash'

    return {
      enablementsForecastOptions: _.get(enablementsForecast, 'options'),
      enablementsActualOptions: _.get(enablementsActual, 'options'),
      enablementsForecastAvailabilityOptions,
      enablementsActualAvailabilityOptions,
      stripLineOptions,
    }
  }, [enablementsGraphData])

  const getSettlementsData = useCallback(async () => {
    const { settlementsActual, settlementsForecast } = settlementsGraphData
    return {
      settlementsForecastOptions: _.get(settlementsForecast, 'options'),
      settlementsActualOptions: _.get(settlementsActual, 'options'),
      stripLineOptions,
    }
  }, [settlementsGraphData])

  const FIVE_MINUTES = FIVE_MINUTE_DURATION.asMilliseconds()
  const getEndOfInterval = time => {
    if (!_.isNil(time)) {
      const elapsedTimeInMs = time.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(time.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      return newEndOfInterval
    }
  }

  const inProgressOrIsLoading =
    inProgress || priceGraphData.isLoading || enablementsGraphData.isLoading || settlementsGraphData.isLoading
  const nextInterval = getEndOfInterval(moment()).unix()
  const marketPriceMinimum = priceProductId === 1 ? MARKET_PRICE_ENERGY_MINIMUM : MARKET_PRICE_FCAS_MINIMUM

  const MARKET_PRICE_MAXIMUM = getMarketPriceCap(endTime)
  return (
    <GraphGroup
      currentTime={getTimeAtStartOf(moment(), FIVE_MINUTE_DURATION.asMinutes()).unix()}
      setDisplayRange={setDisplayRange}
      displayRange={displayRange}
      range={range}
      context={{ timezone, marketStartHour, nowFloorDuration: ONE_HOUR_DURATION, range, p50CutoffDate }}
      inProgress={inProgressOrIsLoading}
    >
      <Graph
        name={TITLE_PRICES}
        getData={getPriceData}
        height={200}
        formatXAxis={common.formatXAxis}
        formatX2Axis={assetForecastGraphConfigs.formatDemandX2Axis(nextInterval)}
        formatYAxis={assetForecastGraphConfigs.formatPricesYAxis(MARKET_PRICE_MAXIMUM, marketPriceMinimum)}
        formatSeries={assetForecastGraphConfigs.formatPricesSeries}
        formatTooltip={assetForecastGraphConfigs.formatTooltip}
      />
      <Graph
        name={TITLE_ENABLEMENTS}
        getData={getEnablementsData}
        height={200}
        formatXAxis={common.formatXAxis}
        formatX2Axis={assetForecastGraphConfigs.formatDemandX2Axis(nextInterval)}
        formatYAxis={assetForecastGraphConfigs.formatEnablementsYAxis}
        formatSeries={assetForecastGraphConfigs.formatEnablementsSeries}
        formatTooltip={common.formatTooltip}
      />
      <Graph
        name={TITLE_SETTLEMENTS}
        getData={getSettlementsData}
        height={200}
        formatXAxis={common.formatXAxis}
        formatX2Axis={assetForecastGraphConfigs.formatDemandX2Axis(nextInterval)}
        formatYAxis={assetForecastGraphConfigs.formatSettlementsYAxis}
        formatSeries={assetForecastGraphConfigs.formatSettlementsSeries}
        formatTooltip={common.formatTooltip}
      />
    </GraphGroup>
  )
}

ForecastGraphs.displayName = DISPLAY_NAME

ForecastGraphs.propTypes = {
  asset: PropTypes.object,
  displayRange: PropTypes.object.isRequired,
  endTime: PropTypes.object,
  horizon: PropTypes.number.isRequired,
  productName: PropTypes.string,
  productType: PropTypes.string,
  setDisplayRange: PropTypes.func.isRequired,
  startTime: PropTypes.object,
  timezone: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  forecastIntervals: state.forecast,
  getMarketPriceCap: globalAppSelectors.getMarketPriceCapFn(state),
  p50CutoffDate: _.get(state, ['globalApp', 'marketData', 'features', 'v2.25.0'])
})

// will return full day data even if start and end diff is less than a day
// 2018-10-11T03:00:000+10:00 to 2018-10-12T04:00:000+10:00 will return data from 2018-10-10T04:00:000+10:00 to 2018-10-12T04:00:000+10:00
// what is shown on graph is determined by viewPort
const getIntervalDataFromState = (forecastIntervals, asset, startTime, endTime, keyPrefix) => {
  const timezone = _.get(asset, 'market.data.timezone')
  const tradingHourStart = _.get(asset, 'market.data.trading_day_start_hour')
  let actualStartTime = moment(startTime).tz(timezone).startOf('day').add(tradingHourStart, 'hours')

  if (actualStartTime.isAfter(startTime)) {
    actualStartTime = actualStartTime.subtract(1, 'd')
  }
  const initial = _.cloneDeep(getChartData(forecastIntervals, keyPrefix, actualStartTime))
  let isLoading = initial.isLoading
  const data = initial.data
  actualStartTime = moment(actualStartTime).add(1, 'd')

  while (actualStartTime.isBefore(endTime)) {
    const nextData = getChartData(forecastIntervals, keyPrefix, actualStartTime)
    isLoading = isLoading || nextData.isLoading
    if (isLoading) {
      return { isLoading: true }
    }
    if (!_.isEmpty(data)) {
      data.values = data.values.map((values, index) => _.concat(values, nextData?.data?.values[index]))
    }
    actualStartTime = moment(actualStartTime).add(1, 'd')
  }
  return {
    isLoading,
    data,
  }
}

const extractIntervalDataFromState = (
  asset,
  tag,
  productId,
  productName,
  horizon,
  forecastIntervals,
  startTime,
  endTime,
  isSettlement,
) => {
  const assetId = _.get(asset, 'assetId')
  if (isAssetNonScheduledBattery(asset)) {
    const GENProductId = _.get(findProduct(asset, productName, PRODUCT_TYPES.GEN), 'productId')
    const LOADProductId = _.get(findProduct(asset, productName, PRODUCT_TYPES.LOAD), 'productId')
    const genIntervalKey = `${assetId}_${GENProductId}_${tag}_${horizon}`
    const loadIntervalKey = `${assetId}_${LOADProductId}_${tag}_${horizon}`
    if (productName === PRODUCT_NAMES.ENERGY) {
      // merge GEN and LOAD Product Interval data from state through subtraction (GEN - LOAD)
      const genIntervalData = getIntervalDataFromState(forecastIntervals, asset, startTime, endTime, genIntervalKey)
      const loadIntervalData = getIntervalDataFromState(forecastIntervals, asset, startTime, endTime, loadIntervalKey)
      const arithmeticOperationType = isSettlement ? ADDITION_TYPE : SUBTRACTION_TYPE
      return performObjectsWithArraysArithmetic(
        genIntervalData,
        loadIntervalData,
        ['data', 'values'],
        true,
        arithmeticOperationType,
      )
    } else {
      return getIntervalDataFromState(forecastIntervals, asset, startTime, endTime, loadIntervalKey)
    }
  }
  // return everything as is for other assets
  return getIntervalDataFromState(
    forecastIntervals,
    asset,
    startTime,
    endTime,
    `${assetId}_${productId}_${tag}_${horizon}`,
  )
}

const transformPriceData = (priceData, selectedDate, p50CutoffDate) => {
  if (!_.isEmpty(priceData.data)) {
    const priceDataCopy = _.cloneDeep(priceData)
    const p90 = priceDataCopy.data.values[0]
    const p10 = priceDataCopy.data.values[2]

    // change order of line to ['Fluence P10,Fluence P90', 'Fluence P50', 'AEMO Pre-Dispatch', 'Actual']
    priceDataCopy.data.values = [p90.map((value, index) => [p10[index], value])]
    showP50Data(selectedDate, p50CutoffDate) ? priceDataCopy.data.values.push(priceData.data.values[1]) : priceDataCopy.data.values.push(priceData.data.values[5])
    // show either p50 or optimization forecast data
    priceDataCopy.data.values.push(priceData.data.values[3])
    priceDataCopy.data.values.push(priceData.data.values[4])
    return priceDataCopy
  }
  return priceData
}

const combineEnablementAvailabilityData = (enablementData, availabilityData) => {
  if (!_.isEmpty(enablementData.data) && !_.isEmpty(availabilityData.data)) {
    const enablementDataCopy = _.cloneDeep(enablementData)

    // change order of line to ['Forecast Enablement', 'Actual Enablement', 'Forecast Availability', 'Actual Availability']
    enablementDataCopy.data.values.push(availabilityData.data.values[0])
    enablementDataCopy.data.values.push(availabilityData.data.values[1])
    return enablementDataCopy
  }
  return enablementData
}

const getDisplayNames = (title, selectedDate, p50CutoffDate) => {
  switch (title) {
    case TITLE_PRICES:
      return ['Fluence P10,Fluence P90', optForecastOrP50Title(selectedDate, p50CutoffDate), 'AEMO Pre-Dispatch', 'Actual']
    case TITLE_ENABLEMENTS:
      return ['Fluence Forecast', 'Actual', 'Forecast Availability', 'Actual Availability']
    case TITLE_SETTLEMENTS:
      return ['Fluence Forecast', 'Actual']
    default:
      return []
  }
}

const getTemplate = title => {
  let template = {}
  if (title === TITLE_PRICES) {
    template = _.cloneDeep(priceGraphTemplate)
  } else if (title === TITLE_ENABLEMENTS) {
    template = _.cloneDeep(enablementGraphTemplate)
  } else if (title === TITLE_SETTLEMENTS) {
    template = _.cloneDeep(settlementGraphTemplate)
  }
  template.seriesOption.type = 'line'
  template.seriesOption.lineThickness = 1.3
  template.options.axisX.gridThickness = template.options.axisY.gridThickness = 1
  return template
}

const getColors = (asset, title) => {
  switch (title) {
    case TITLE_PRICES:
      return ['#4893c8', '#2482c4', '#e24d42', '#89b368']
    case TITLE_ENABLEMENTS:
      return ['#2482c4', '#89b368', '#135f94', '#607d48']
    case TITLE_SETTLEMENTS:
      return ['#2482c4', '#89b368']
    default:
      return []
  }
}

export default connect(mapStateToProps)(ForecastGraphs)
