import React from 'react'
import _ from 'lodash'
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography } from '@material-ui/core'

interface Props {
  changes?: []
  firstHeader?: string
  footer?: string
  secondHeader?: string
  thirdHeader?: string
  title?: string
}

export const TooltipContent: React.FC<Props> = ({
  changes,
  firstHeader = '',
  footer,
  secondHeader = 'PREVIOUS',
  thirdHeader = 'NEW',
  title,
}) => {
  const showLabelColumn = _.isArray(changes) && changes?.some(({ label }) => label !== undefined)
  const showValueColumn1 = _.isArray(changes) && changes?.some(({ prevValue }) => prevValue !== undefined)
  const showValueColumn2 = _.isArray(changes) && changes?.some(({ newValue }) => newValue !== undefined)
  return (
    <>
      {title && <Typography>{title}</Typography>}
      <TableContainer>
        <Table size="small" padding="none" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {showLabelColumn && (
                <TableCell>
                  <Box pr={2}>{firstHeader}</Box>
                </TableCell>
              )}
              {showValueColumn2 && (
                <TableCell align="right">
                  <Box pr={2}>{secondHeader}</Box>
                </TableCell>
              )}
              {showValueColumn2 && (
                <TableCell align="right">
                  <Box>{thirdHeader}</Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.isArray(changes) &&
              changes?.map(
                ({
                  currency = '',
                  label = '',
                  newValue,
                  newValuePostfix = '',
                  prevValue,
                  prevValuePostfix = '',
                  units = '',
                }) => {
                  const prevValueDisplay =
                    _.isNil(prevValue) || _.isNaN(prevValue) || prevValue === '' ? '-' : `${prevValue}${units}`
                  const newValueDisplay =
                    _.isNil(newValue) || _.isNaN(newValue) || newValue === '' ? '-' : `${newValue}${units}`
                  return (
                    <TableRow key={label}>
                      {(showValueColumn1 || showValueColumn2) && (
                        <TableCell component="th" scope="row">
                          <Box pr={2}>{label}</Box>
                        </TableCell>
                      )}
                      {showValueColumn1 && (
                        <TableCell align="right">
                          <Box pr={2}>
                            {currency}
                            {prevValueDisplay}
                            {prevValuePostfix}
                          </Box>
                        </TableCell>
                      )}
                      {showValueColumn2 && (
                        <TableCell align="right">
                          {currency}
                          {newValueDisplay}
                          {newValuePostfix}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                },
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {footer && <Typography>{footer}</Typography>}
    </>
  )
}
