import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core'
import CircleIcon from '../../assets/icons/CircleIcon'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 0,
    marginLeft: 80,
    marginBottom: theme.spacing(4),
  },
  items: {
    width: 'inherit',
    color: theme.palette.text.secondary,
    userSelect: 'none',
    cursor: 'pointer',
  },
})

const LegendImpl = ({ items, toggleItem, classes, legend }) => (
  <List className={classes.container} dense>
    {items.map((item, index) => (
      <ListItem
        className={classes.items}
        disabled={!legend[item.id]}
        key={index}
        onClick={e => toggleItem(item, e)}
      >
        <CircleIcon htmlColor={item.color} />
        {item.name}
      </ListItem>
    ))}
  </List>
)

export default withStyles(styles)(LegendImpl)
