const roots = [
  'AF_GET_ALL_ASSETS',
  'AF_UPDATE_ASSETDATA',
  'AF_UPDATE_RISK',
  'ASSET_GET_OPT_DISABLED',
  'SELECT_ASSET_ID',
]

// TODO: improve this so a user doesn't need to type TYPE_NAME.root to get the value
const types = roots.reduce((acc, next) => {
  acc[next] = {
    root: next,
    request: `${next}_REQUEST`,
    success: `${next}_SUCCESS`,
    error: `${next}_ERROR`,
  }
  return acc
}, {})

export default types
