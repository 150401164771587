import React, { useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Grid, Box, Modal, IconButton, Paper, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import TocIcon from '@material-ui/icons/Toc'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Button, Download as DownloadIcon, useNotifier } from '@fluence/core'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {
  secondsInMinute,
  enablementTag,
  dateTimeFormat,
  SETTLEMENT_INTERVAL_MINS,
  INTERVAL_SIZE_MINS,
  getIndex,
  getTradingStart,
  findProduct,
  getAllProductIds,
  getTradingActualTime,
  getCardSubtitleByDate,
  getSortedProductNames,
} from '../utility/utility.js'
import { NUM_FIVE_MIN_INTERVALS_PER_DAY, NUM_THIRTY_MIN_INTERVALS_PER_DAY } from '../utility/constants'
import { SyncHandler } from '../utility/syncHandler'
import {
  canAdjustPriceBands,
  canSubmitManualBids,
  canPauseBidDelivery,
  canOnlyPauseBidDelivery,
} from '../utility/user-utils'
import {
  getFirstActiveProductName,
  getDisabledProductTypeToggleNames,
  getFirstActiveProductTypeName,
  shouldShowProductTypeToggle,
} from '../utility/asset-utils'
import { getNextIntervalTime } from '../utility/time-utils'
import {
  getLatestBidFile,
  getPriceBands,
  updateBidDeliveryFlag,
  getBidFileMetadata,
  selectProductName,
  getManualBids,
  selectBidView,
  selectSortedManualBidsData,
  getPriceBandsRange,
  getManualBidsForLog,
  resetZeroMaxBidsMapByStartTime,
  getAssetByAssetId,
} from '../redux/features/bid'
import { globalAppSelectors } from '../redux/features/app'
import { bidFileSettingsResource } from '../redux/features/setting'
import { IntervalConfig, IntervalTimeConfig } from '../redux/features/interval'
import { getCollatedAssetProductIntervals } from '../redux/features/collated-interval'
import { getAssets } from '../redux/features/dashboard'
import StackedBarGraph from '../components/Bid/StackedBarGraph'
import BidTable from '../components/Bid/BidTable'
import DownloadBids from '../components/Bid/DownloadBids'
import EditPriceBands from '../components/Bid/EditPriceBands'
import PauseAlgorithmicBidding from '../components/Bid/PauseAlgorithmicBidding'
import ManualBidLog from '../components/Bid/ManualBidLog'
import ProductToggle from '../components/toggles/ProductToggle'
import ProductTypeToggle from '../components/toggles/ProductTypeToggle.js'
import Card from '../components/Card'
import Toggle from '../components/Toggle'
import KeyboardDatePicker from '../components/KeyboardDatePicker'
import ManualBidDashboard from '../components/Bid/ManualBidDashboard.js'

export const PRODUCT_TYPE_GEN = 'GEN'
export const PRODUCT_TYPE_LOAD = 'LOAD'
export const PRODUCT_NAME_ENERGY = 'ENERGY'
export const PRODUCT_FCAS = 'FCAS'
export const SETTLEMENT_INTERVAL = 'SettlementInterval'
export const BID_INTERVAL = 'BidInterval'
export const BID_VIEW_TABLE = 'Table'
export const BID_VIEW_CHART = 'Chart'
export const DUID_SCHEMA_VIEW = '2 DUID'
export const BDU_SCHEMA_VIEW = 'BDU'
export const REFRESH_INTERVAL = 30000
export const INTERVAL_CUTOFF_MINUTES = 23
export const NUM_BID_INTERVALS_IN_SETTLEMENT_INTERVAL = 6

const DISPLAY_NAME = 'Bids'

const useStyles = makeStyles(
  theme => ({
    datePickerGrid: {
      height: theme.spacing(6),
    },
    tableIcon: {
      marginTop: 5,
      marginLeft: theme.spacing(0.25),
    },
    chartIcon: {
      marginTop: 5,
      marginRight: theme.spacing(0.25),
    },
    iconText: {
      bottom: 3,
      position: 'relative',
    },
    productToggle: {
      marginBottom: theme.spacing(2),
    },
    panelTitle: {
      fontSize: 18,
      margin: 0,
    },
    tableAction: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    pauseButton: {
      color: `${theme.custom.palette.colors.CALIFORNIA}`,
      background: theme.palette.background.paper,
      cursor: 'pointer',
      outline: 'none',
      borderColor: `${theme.custom.palette.colors.CALIFORNIA}`,
      borderWidth: 1,
      borderStyle: 'solid',
      paddingTop: theme.spacing(0.25),
    },
    resumeButton: {
      color: `${theme.custom.palette.colors.CALIFORNIA}`,
      background: theme.palette.background.paper,
      cursor: 'pointer',
      outline: 'none',
      borderColor: `${theme.custom.palette.colors.CALIFORNIA}`,
      borderWidth: 1,
      borderStyle: 'solid',
      paddingTop: theme.spacing(0.25),
    },
    pauseIcon: {
      fontSize: theme.spacing(3),
    },
    downloadRefreshIcon: {
      background: theme.palette.background.paper,
      color: '#FFF',
      border: 'none',
      cursor: 'pointer',
    },
    manualBidsButton: {
      height: theme.spacing(4),
      fontWeight: 600,
      border: 'none',
      verticalAlign: 'top',
    },
    manualBidsItem: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    editPb: {
      height: theme.spacing(5),
      marginRight: theme.spacing(0.5),
      fontWeight: 700,
      border: 'none',
      cursor: 'pointer',
      outline: 'none',
    },
    pauseText: {
      color: theme.palette.error.main,
      fontWeight: 400,
    },
    mobile: {
      width: '100%',
    },
    log: {
      height: 'calc(100% - 300px)',
    },
    tableCardContent: {
      height: 'calc(100vh - 270px)',
      minHeight: 200,
      overflow: 'hidden',
      paddingRight: 0,
      paddingLeft: 0,
    },
    select: {
      '&&': {
        paddingRight: 0,
        backgroundColor: 'transparent',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    formControl: {
      backgroundColor: '#FFFFFF17',
    },
  }),
  { name: DISPLAY_NAME },
)

const refresh = (
  forced = false,
  selectedDate,
  manualBidModalIsOpen,
  isEditPbModalOpen,
  selectedAsset,
  selectedProductName,
  selectedProductType,
  bidCollationInterval,
  setLastUpdated,
  marketTimezone,
  refreshOnly,
  getAllData,
  selectedSchema,
) => {
  if (!moment(selectedDate).isValid()) {
    return Promise.resolve()
  }
  const now = moment()
  if (
    forced ||
    (moment(selectedDate).add(1, 'd').isSameOrAfter(now) &&
      !manualBidModalIsOpen &&
      !isEditPbModalOpen &&
      !_.isNil(selectedAsset))
  ) {
    setLastUpdated(moment().tz(marketTimezone))
    return getAllData(
      selectedAsset,
      selectedProductName,
      selectedProductType,
      selectedDate,
      selectedSchema,
      bidCollationInterval,
      false,
      refreshOnly,
      true,
    )
  }
  return Promise.resolve()
}

const Bids = props => {
  const classes = useStyles()
  const {
    asset,
    dispatchGetAssets,
    onInitialize,
    onDateChange,
    getAllData,
    getProductChangeData,
    onBidIntervalChange,
    onBidViewChange,
  } = props
  const {
    bidFileMetadata,
    bidFileSettingsCreatedOn,
    endOfInterval,
    getSettlementInterval,
    isBidDeliveryPaused,
    latestBidFile,
    manualBidsForLog,
    priceBandsRange,
    reduxPriceBands,
    user,
    dispatchResetZeroMaxBidsMapByStartTime,
  } = props
  const { notifyError } = useNotifier()

  const selectedAsset = asset
  const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})
  const market = _.get(selectedAsset, 'market')
  const pauseTimestamp = isBidDeliveryPaused
    ? moment(bidFileSettingsCreatedOn).tz(_.get(market, 'data.timezone')).format(dateTimeFormat)
    : ''

  const { 'bid_creator_start_cutoff_secs': bidCreatorStartCutoffSecs } = _.get(
    asset,
    'market.data.gate_closure_cutoffs',
    {},
  )

  const today = getTradingStart(_.get(selectedAsset, 'market'))
  const productTypes = _.get(selectedAsset, 'productTypes', [])
  const productNames = _.get(selectedAsset, 'productNames', [])
  const productDisplayNames = _.get(market, 'data.product_display_names')
  const marketTimezone = _.get(market, 'data.timezone')
  const marketStartHour = _.get(market, 'data.trading_day_start_hour')
  moment.tz.setDefault(marketTimezone)

  const [todayInitial, setTodayInitial] = useState(moment().tz('Etc/GMT-10'))
  const [lastUpdated, setLastUpdated] = useState(moment().tz(marketTimezone))
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedProductType, setSelectedProductType] = useState(PRODUCT_TYPE_GEN)
  const [selectedBidView, setSelectedBidView] = useState(BID_VIEW_TABLE)
  const [bidCollationInterval, setBidCollationInterval] = useState(BID_INTERVAL)
  const [selectedProductName, setSelectedProductName] = useState('ENERGY')
  const [refreshable, setRefreshable] = useState(false)
  const [viewPort, setViewPort] = useState({
    xMin: moment(today).unix() + _.get(selectedAsset, 'market.data.settlement_interval') * secondsInMinute,
    xMax: moment(today).add(1, 'd').unix(),
    yMin: null,
    yMax: null,
    wasXYZoom: true,
    wasZoomed: false,
  })
  const [isDownloadBidsModalOpen, setIsDownloadBidsModalOpen] = useState(false)
  const [isBidDeliveryModalOpen, setIsBidDeliveryModalOpen] = useState(false)
  const [isEditPbModalOpen, setIsEditPbModalOpen] = useState(false)
  const [manualBidModalIsOpen, setManualBidModalIsOpen] = useState(false)
  const [finalCombinedBids, setFinalCombinedBids] = useState([])
  const [collapsed, setCollapsed] = useState(true)
  const [bidTransitionExists, setBidTransitionExists] = useState(false)
  const [selectedSchema, setSelectedSchema] = useState(1)
  const [schemaSelection, setSchemaSelection] = useState(false)

  const expandFCAS = (event, productName) => {
    if (productName === PRODUCT_FCAS) {
      setCollapsed(false)
    }
  }
  const [manualBidSelectedDate, setManualBidSelectedDate] = useState(selectedDate)
  const disabledProductNames = _.get(selectedAsset, 'productNames', []).filter(
    productName => _.get(selectedAsset, ['data', 'configuration', `${productName}_${selectedProductType}`]) === 0,
  )

  useEffect(() => {
    dispatchGetAssets()
  }, [dispatchGetAssets, endOfInterval])

  // Sometimes selectedDate is set before today gets correct value
  const todayValue = today ? today.valueOf() : null
  const assetId = _.get(selectedAsset, 'assetId')
  useEffect(() => {
    if (_.isNumber(assetId)) {
      setSelectedDate(moment(todayValue))
      setManualBidSelectedDate(moment(todayValue))
    }
  }, [todayValue, assetId])

  const settlementInterval = getSettlementInterval(selectedDate)
  const productId = _.get(findProduct(asset, selectedProductName, selectedProductType), 'productId')
  const lastSubmittedCombinedBids = _.get(latestBidFile, [
    assetId,
    moment(selectedDate).format('YYYY-MM-DD'),
    productId,
  ])

  useEffect(() => {
    if (bidCollationInterval === SETTLEMENT_INTERVAL && settlementInterval === INTERVAL_SIZE_MINS) {
      setBidCollationInterval(BID_INTERVAL)
    }
  }, [bidCollationInterval, settlementInterval])
  const interval = bidCollationInterval === BID_INTERVAL ? INTERVAL_SIZE_MINS : SETTLEMENT_INTERVAL_MINS
  const currentIndex = getIndex(moment(), selectedDate, moment(selectedDate).add(1, 'd'), interval)
  const productIndex = _.get(asset, 'products', []).findIndex(
    product => product.name === selectedProductName && product.data.type === selectedProductType,
  )
  const priceBands = _.get(reduxPriceBands, ['payload', productIndex, 'data', 'values'], {})
  const priceBandsIsLoading = _.get(reduxPriceBands, 'isLoading', true)
  const submittedCombinedBidsIsLoading = _.get(latestBidFile, 'isLoading', true)
  const reduxSubmittedCombinedBids = JSON.parse(JSON.stringify(_.get(lastSubmittedCombinedBids, 'bids', []))) // call by value
  const size = bidCollationInterval === BID_INTERVAL ? NUM_FIVE_MIN_INTERVALS_PER_DAY : NUM_THIRTY_MIN_INTERVALS_PER_DAY
  const lastUpdatedOn = moment(_.get(lastSubmittedCombinedBids, 'createdOn'))
  const submittedCombinedBids = []
  for (let i = 0; i < size; i++) {
    submittedCombinedBids[i] = {}
  }
  reduxSubmittedCombinedBids.forEach((bid, index) => {
    submittedCombinedBids[index] = { ...bid, createdOn: lastUpdatedOn.toISOString() }
  })

  // construct reason column
  const metadataRebids = _.get(bidFileMetadata, 'payload', [])
  const metadataRebidsIsLoading = _.get(bidFileMetadata, 'isLoading', true)
  const sortedMetadataRebids = _.isArray(metadataRebids)
    ? metadataRebids.sort((x, y) => {
        return moment(x.createdOn) - moment(y.createdOn)
      }) // ascending order so late ones can override early ones
    : []
  if (!submittedCombinedBidsIsLoading) {
    sortedMetadataRebids.forEach(metaDataRebid => {
      const createdOn = moment(_.get(metaDataRebid, 'createdOn'))

      const difference = moment.duration(createdOn.diff(moment(selectedDate))).asMinutes()

      // rebids with created time between minute 25-30 or 55-60 go effective in the next settlement interval
      const atEndOfSettlementInterval =
        Math.ceil(difference / INTERVAL_SIZE_MINS) % (SETTLEMENT_INTERVAL_MINS / INTERVAL_SIZE_MINS) === 0 ? 1 : 0

      // rebids with created time before trading day starts show in first interval
      let bidIndex = 0
      if (!createdOn.isBefore(moment(selectedDate))) {
        bidIndex =
          bidCollationInterval === BID_INTERVAL
            ? Math.ceil(difference / interval)
            : Math.floor(difference / interval) + atEndOfSettlementInterval
      }
      if (bidIndex < size) {
        submittedCombinedBids[bidIndex] = {
          ...submittedCombinedBids[bidIndex],
          reason: _.get(metaDataRebid, 'rebidReason', ''),
          createdOn: _.get(metaDataRebid, 'createdOn'),
        }
      }
    })
  }

  const selectedDateTimestamp = moment(selectedDate).valueOf()
  const { isLoading: manualBidsIsLoading, sortedManualBids } = useSelector(state =>
    selectSortedManualBidsData(state, assetId, selectedDateTimestamp, productId),
  )

  const shouldDisplay = bid => {
    const createdOn = moment(_.get(bid, 'createdOn'))
    const timeAfterCreation = moment.duration(moment().diff(createdOn)).asMinutes()
    if (moment(selectedDate).isBefore(moment())) {
      // for current trading day
      return timeAfterCreation > 0 && timeAfterCreation < 5
    }

    // for future trading day
    if (moment(selectedDate).isAfter(moment())) {
      return true
    }
  }

  const getReasonIndex = bid => {
    let effectiveStartIndex
    const createdOn = moment(_.get(bid, 'createdOn'))

    if (bidCollationInterval === SETTLEMENT_INTERVAL) {
      // LEGACY 30 minute settlement
      const difference = moment.duration(createdOn.diff(moment(selectedDate))).asMinutes()
      const createdIntervalStartIndex = Math.floor(difference / SETTLEMENT_INTERVAL_MINS)
      if (createdOn.minutes() < 23 || (createdOn.minutes() >= 30 && createdOn.minutes() < 53)) {
        effectiveStartIndex = createdIntervalStartIndex
      } else {
        effectiveStartIndex = createdIntervalStartIndex + 1
      }
    } else {
      // 5 minute settlement
      const difference = moment.duration(createdOn.diff(moment(selectedDate))).asMinutes()
      const createdIntervalStartIndex = Math.floor(difference / INTERVAL_SIZE_MINS)
      const minsBeforeNextBidInterval = (createdOn.minutes() + createdOn.seconds() / 60) % INTERVAL_SIZE_MINS
      if (
        moment.duration(minsBeforeNextBidInterval, 'minutes').asSeconds() >= bidCreatorStartCutoffSecs &&
        minsBeforeNextBidInterval < INTERVAL_SIZE_MINS
      ) {
        effectiveStartIndex = createdIntervalStartIndex + 2
      } else {
        effectiveStartIndex = createdIntervalStartIndex + 1
      }
    }

    if (moment(selectedDate).isAfter(moment())) {
      return 0
    }
    return effectiveStartIndex
  }

  if (!_.isNil(sortedManualBids) && !_.isEmpty(sortedManualBids)) {
    // attach the manual bid reason to latest effective interval if manual bid not in submitted bidfile
    const lastManualBid = sortedManualBids[sortedManualBids.length - 1]
    const reasonIndex = getReasonIndex(lastManualBid)
    const lastManualBidEffectiveTime = getNextIntervalTime(lastManualBid.createdOn, 0)
    if (shouldDisplay(lastManualBid)) {
      // NEM-2630-2904: Show manual rebid reason if effective interval is more recent than the existing bidfile
      if (lastManualBidEffectiveTime > moment(submittedCombinedBids[reasonIndex].createdOn)) {
        submittedCombinedBids[reasonIndex].reason = lastManualBid.reason
      }
    }

    sortedManualBids.forEach(manualBid => {
      let bidIndex = moment.duration(moment(manualBid.startTime).diff(moment(selectedDate))).asMinutes() / interval
      const createdOn = moment(_.get(manualBid, 'createdOn'))
      if (moment(manualBid.startTime).isSameOrBefore(createdOn)) {
        // manual bid for current interval (0-23 min)
        if (bidCollationInterval === BID_INTERVAL) {
          const difference = moment.duration(createdOn.diff(moment(manualBid.startTime))).asMinutes()
          bidIndex += Math.ceil(difference / INTERVAL_SIZE_MINS)
          const minsBeforeNextInterval =
            (createdOn.minutes() + createdOn.seconds() / moment.duration(1, 'minutes').asSeconds()) % INTERVAL_SIZE_MINS
          if (
            moment.duration(minsBeforeNextInterval, 'minutes').asSeconds() >= bidCreatorStartCutoffSecs &&
            minsBeforeNextInterval < INTERVAL_SIZE_MINS
          ) {
            bidIndex++
          }
        }
      }
      // Attach manual bid data to collated bids that will be shown on bids table
      if (!_.isNil(submittedCombinedBids[bidIndex])) {
        if (shouldDisplay(manualBid)) {
          // Show manual bid if (1) it's scheduled for current day
          // and it's submitted < 5 minutes ago
          // OR (2) it's scheduled for future trading days
          submittedCombinedBids[bidIndex].values = manualBid.values
          const timeAfterCreation = moment.duration(moment().diff(createdOn)).asMinutes()

          // if the manual bids are for future trading days
          const initialBidLimitTime = moment(selectedDate).startOf('day').subtract(12, 'h') // 12pm
          const nextDayStartTime = moment(selectedDate).startOf('day').add(4, 'h') // 4am of next trading day
          const todayStartTime = moment(createdOn).startOf('day').add(4, 'h') // 4am of current day
          let maxAvailablePower
          if (_.isNil(manualBid.maxAvailablePower) && !_.isNil(lastSubmittedCombinedBids)) {
            if (createdOn.isBetween(initialBidLimitTime, nextDayStartTime) && timeAfterCreation > 5) {
              // between 12pm- 4am of next trading day
              const bids = lastSubmittedCombinedBids['bids']
              maxAvailablePower = bids[bidIndex]?.maxAvailablePower
            } else if (createdOn.isBetween(todayStartTime, initialBidLimitTime)) {
              // between 4am -12pm
              const createdDayNoon = moment(createdOn).startOf('day').add(12, 'h').subtract(5, 'm') // 11:55 AM of Created Day
              if (moment().isAfter(createdDayNoon)) {
                const bids = lastSubmittedCombinedBids['bids']
                maxAvailablePower = bids[bidIndex]?.maxAvailablePower
              }
            }
          } else {
            maxAvailablePower = manualBid.maxAvailablePower
          }
          submittedCombinedBids[bidIndex].maxAvailablePower = maxAvailablePower
          submittedCombinedBids[bidIndex].createdOn = manualBid.createdOn
          submittedCombinedBids[bidIndex].isManualBid = true
          submittedCombinedBids[bidIndex].manualBidReason = manualBid.reason

          const isBidInterval = bidCollationInterval === BID_INTERVAL
          const is30MinSettlement = settlementInterval === SETTLEMENT_INTERVAL_MINS
          if (isBidInterval && is30MinSettlement) {
            while ((bidIndex += 1) % NUM_BID_INTERVALS_IN_SETTLEMENT_INTERVAL !== 0) {
              submittedCombinedBids[bidIndex].values = manualBid.values
              submittedCombinedBids[bidIndex].maxAvailablePower = manualBid.maxAvailablePower
              submittedCombinedBids[bidIndex].createdOn = manualBid.createdOn
            }
          }
        } else if (
          !_.isNil(submittedCombinedBids[bidIndex].values) &&
          !_.isNil(manualBid.values) &&
          submittedCombinedBids[bidIndex].values.every((priceBand, i) => manualBid.values[i] === priceBand) &&
          manualBid.values.every((priceBand, i) => submittedCombinedBids[bidIndex].values[i] === priceBand)
        ) {
          // Show a past manual bid if the manual bid was successfully submitted
          // by verifying if the manual bid's price band array === the actually submitted price band array
          submittedCombinedBids[bidIndex].isManualBid = true
          submittedCombinedBids[bidIndex].manualBidReason = manualBid.reason
        }
      }
    })
  }

  const bidIsLoading =
    submittedCombinedBidsIsLoading || metadataRebidsIsLoading || manualBidsIsLoading || priceBandsIsLoading
  const submittedCombinedBidsStringified = JSON.stringify(submittedCombinedBids)
  useEffect(() => {
    const submittedCombinedBids = JSON.parse(submittedCombinedBidsStringified)
    if (!bidIsLoading && !_.isEqual(submittedCombinedBids, finalCombinedBids)) {
      setFinalCombinedBids(submittedCombinedBids)
    }
  }, [submittedCombinedBidsStringified, bidIsLoading, finalCombinedBids])

  useEffect(() => {
    const productType = getFirstActiveProductTypeName(selectedAsset)
    setSelectedProductType(productType)
    setSelectedProductName(getFirstActiveProductName(selectedAsset, productType, selectedDate))
    setSelectedBidView(BID_VIEW_TABLE)
  }, [selectedAsset, selectedDate])

  // Be aware that interval might never execute if the component re-renders with dependencies more often than the interval time,
  // as it clears interval every time being called
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      refresh(
        false,
        selectedDate,
        manualBidModalIsOpen,
        isEditPbModalOpen,
        selectedAsset,
        selectedProductName,
        selectedProductType,
        bidCollationInterval,
        setLastUpdated,
        marketTimezone,
        true,
        getAllData,
        selectedSchema,
      )
    }, REFRESH_INTERVAL)
    return () => clearInterval(refreshInterval) // prevent memory leak
  }, [
    selectedDate,
    manualBidModalIsOpen,
    isEditPbModalOpen,
    selectedAsset,
    selectedProductName,
    selectedProductType,
    bidCollationInterval,
    setLastUpdated,
    marketTimezone,
    getAllData,
    selectedSchema,
  ])

  useEffect(() => {
    if (!_.isNil(selectedAsset)) {
      onInitialize(selectedAsset)
    }
  }, [selectedAsset, onInitialize, todayValue])

  useEffect(() => {
    if (
      !_.isNil(selectedProductType) &&
      !_.isNil(marketTimezone) &&
      !_.isNil(selectedProductName) &&
      moment(selectedDate).isValid() &&
      _.isFunction(getProductChangeData)
    ) {
      getProductChangeData(selectedAsset, selectedProductName, selectedProductType, selectedDate)
      setLastUpdated(moment().tz(marketTimezone))
    }
  }, [selectedProductType, getProductChangeData, marketTimezone, selectedProductName, selectedAsset, selectedDate])

  useEffect(() => {
    if (selectedDate) {
      const nextMarketDay = getTradingStart(market, todayInitial).add(1, 'd')
      const currentTime = moment().tz(marketTimezone)
      if (currentTime.isAfter(nextMarketDay)) {
        setTodayInitial(nextMarketDay)
      }
    }
  }, [selectedDate, market, todayInitial, marketTimezone])

  useEffect(() => {
    if (selectedDate && !_.isEmpty(selectedAsset) && !manualBidModalIsOpen && !isEditPbModalOpen) {
      refresh(
        true,
        selectedDate,
        manualBidModalIsOpen,
        isEditPbModalOpen,
        selectedAsset,
        selectedProductName,
        selectedProductType,
        bidCollationInterval,
        setLastUpdated,
        marketTimezone,
        false,
        getAllData,
        selectedSchema,
      )
    }
  }, [
    selectedDate,
    manualBidModalIsOpen,
    isEditPbModalOpen,
    selectedAsset,
    selectedProductName,
    selectedProductType,
    bidCollationInterval,
    setLastUpdated,
    marketTimezone,
    getAllData,
    selectedSchema,
  ])

  const assetDetailsByAssetId = useSelector(state => state.bid.assetDetails.payload)
  const bidTranistionDates = assetDetailsByAssetId?.data?.feature_flags

  useEffect(() => {
    if (!_.isNil(selectedDate) && bidTranistionDates) {
      setBidTransitionExists(true)
      const transitionStartTime = moment(bidTranistionDates?.bdu_transition_start_time).startOf('day')
      const transitionTime = moment(bidTranistionDates?.bdu_transition_start_time)
      const preCutOverStartTime = transitionStartTime
        .clone()
        .subtract(bidTranistionDates?.bdu_bid_pre_cut_over_days, 'days')
      const postCutOverEndTime = transitionTime.clone().add(bidTranistionDates?.bdu_bid_post_cut_over_days, 'days')

      if (selectedDate.isBefore(preCutOverStartTime)) {
        setSelectedSchema(1)
        setSchemaSelection(false)
      } else if (selectedDate.isBetween(preCutOverStartTime, transitionStartTime)) {
        setSelectedSchema(1)
        setSchemaSelection(true)
      } else if (selectedDate.isBetween(transitionStartTime, postCutOverEndTime)) {
        setSelectedSchema(2)
        setSchemaSelection(true)
      } else {
        setSelectedSchema(2)
        setSchemaSelection(false)
      }
    }
  }, [bidTranistionDates, selectedDate])

  const zoom = selectedInterval => (xMin, xMax, yMin, yMax, wasXYZoom, wasZoomed) => {
    if (selectedInterval) {
      const xMinPoint = xMin - (xMin % (selectedInterval * secondsInMinute))
      const xMaxPoint = xMax - (xMax % (selectedInterval * secondsInMinute))
      updateViewPort(xMinPoint, xMaxPoint, yMin, yMax, wasXYZoom, wasZoomed)
      setRefreshable(true)
    }
  }

  const refreshGraphViews = selectedDate => {
    zoom(interval)(
      selectedDate.unix() + interval * secondsInMinute,
      moment(selectedDate).add(1, 'd').unix(),
      null,
      null,
      true,
      false,
    )
    setRefreshable(false)
  }

  const [syncHandler] = useState(new SyncHandler())
  const [handlerInterval, setHandlerInterval] = useState(null)

  // We have to wait till interval is populated, but also need to reset the onZoom function if interval changes
  if (interval && handlerInterval !== interval) {
    syncHandler.clearOnZoom()
    syncHandler.addOnZoom(zoom(interval))
    setHandlerInterval(interval)
  }

  const updateViewPort = (xMin, xMax, yMin, yMax, wasXYZoom, wasZoomed) => {
    setViewPort({
      xMin,
      xMax,
      yMin,
      yMax,
      wasXYZoom,
      wasZoomed,
    })
  }

  const handleChangeBidInterval = (event, bidCollationInterval) => {
    if (!_.isNil(bidCollationInterval)) {
      setBidCollationInterval(bidCollationInterval)
    }
    if (
      !_.isNil(bidCollationInterval) &&
      !_.isNil(selectedAsset) &&
      !_.isNil(selectedDate) &&
      !_.isNil(selectedProductType) &&
      !_.isNil(selectedProductName) &&
      _.isFunction(onBidIntervalChange)
    ) {
      onBidIntervalChange(selectedAsset, selectedProductName, selectedProductType, selectedDate, bidCollationInterval)
      const interval = bidCollationInterval === BID_INTERVAL ? INTERVAL_SIZE_MINS : SETTLEMENT_INTERVAL_MINS
      const previousInterval = bidCollationInterval === BID_INTERVAL ? SETTLEMENT_INTERVAL_MINS : INTERVAL_SIZE_MINS
      const newViewPort = _.cloneDeep(viewPort)
      newViewPort.xMin = newViewPort.xMin + (interval - previousInterval) * secondsInMinute
      setViewPort(newViewPort)
    }
  }

  const handleChangeBidView = (event, selectedBidView) => {
    if (!_.isNil(selectedBidView)) {
      setSelectedBidView(selectedBidView)
    }
    if (
      ![selectedBidView, bidCollationInterval].some(_.isNil) &&
      selectedBidView === BID_VIEW_CHART &&
      bidCollationInterval === SETTLEMENT_INTERVAL &&
      _.isFunction(handleChangeBidInterval)
    ) {
      handleChangeBidInterval(event, BID_INTERVAL)
    }
    if (
      ![selectedBidView, bidCollationInterval, selectedAsset, selectedDate].some(_.isNil) &&
      _.isFunction(onBidViewChange)
    ) {
      updateViewPort(
        selectedDate.unix() + interval * secondsInMinute,
        moment(selectedDate).add(1, 'd').unix(),
        null,
        null,
        true,
        false,
      )
    }
  }

  const handleChangeProductType = (event, selectedProductType) => {
    if (!_.isNil(selectedProductType)) {
      // ensure disabled product is not selected
      let nextProductName = selectedProductName
      const disabledProducts = selectedAsset.productNames.filter(
        productName => _.get(selectedAsset, ['data', 'configuration', `${productName}_${selectedProductType}`]) === 0,
      )
      if (disabledProducts.includes(selectedProductName)) {
        nextProductName = getSortedProductNames(productNames).find(product => !disabledProducts.includes(product))
        setSelectedProductType(selectedProductType)
        handleChangeProductName(event, nextProductName)
      } else {
        setSelectedProductType(selectedProductType)
      }
    }
  }

  const handleChangeProductName = (event, selectedProductName) => {
    if (!_.isNil(selectedProductName)) {
      setSelectedProductName(selectedProductName)
    }
  }

  const handleChangeDatePicker = (event, date) => {
    const newDate = moment(date).tz(marketTimezone).startOf('day').add(marketStartHour, 'hours')
    setSelectedDate(newDate)
    setManualBidSelectedDate(newDate)

    if (
      moment(date).isValid() &&
      !_.isNil(selectedDate) &&
      !_.isNil(selectedAsset) &&
      !_.isNil(marketTimezone) &&
      !_.isNil(bidCollationInterval) &&
      _.isFunction(onDateChange) &&
      _.isFunction(getSettlementInterval)
    ) {
      if (moment.isMoment(newDate)) {
        // this helps ensure that we do not reload if a future date greater than 2 weeks out is typed in date input element
        const actualTime = getTradingActualTime(selectedAsset, newDate)
        const actualDate = actualTime.isSameOrBefore(moment(today).add(14, 'days')) ? actualTime : selectedDate
        if (!actualDate.isSame(selectedDate)) {
          onDateChange(selectedAsset, selectedProductName, selectedProductType, actualDate, bidCollationInterval)
          setSelectedDate(actualDate)
          setManualBidSelectedDate(actualDate)
          setLastUpdated(moment().tz(marketTimezone))
          const interval =
            bidCollationInterval === BID_INTERVAL
              ? _.get(selectedAsset, 'market.data.bid_interval')
              : _.get(selectedAsset, 'market.data.settlement_interval')
          updateViewPort(
            actualDate.unix() + interval * secondsInMinute,
            moment(actualDate).add(1, 'd').unix(),
            null,
            null,
            true,
            false,
          )
        }
      }
    }
  }

  const subTitle = getCardSubtitleByDate(
    selectedDate,
    moment(selectedDate).add(1, 'days'),
    lastUpdated,
    marketTimezone,
    marketStartHour,
  )

  const intervalText =
    currentIndex <= -1
      ? ''
      : settlementInterval === INTERVAL_SIZE_MINS
      ? `, Current Interval: ${currentIndex + 1}`
      : `, Current ${bidCollationInterval === BID_INTERVAL ? 'Dispatch' : 'Trading'} Interval: ${currentIndex + 1}`

  const onManualBidModalClick = () => {
    setManualBidModalIsOpen(() => {
      if (selectedDate.isBefore(today)) {
        setSelectedDate(today)
        setManualBidSelectedDate(today)
      }
      return true
    })
  }

  const onManualBidModalClose = useCallback(() => {
    if (![selectedDate, selectedProductName, selectedProductType, marketTimezone].some(_.isNil)) {
      setManualBidModalIsOpen(false)
      dispatchResetZeroMaxBidsMapByStartTime()
    }
  }, [marketTimezone, selectedDate, selectedProductName, selectedProductType, dispatchResetZeroMaxBidsMapByStartTime])

  const prodType = _.get(selectedAsset, ['market', 'data', 'product_type_display_names', selectedProductType])
  const ProdName = _.get(selectedAsset, ['market', 'data', 'product_display_names', selectedProductName])
  const header = prodType ? `Bids: ${prodType}-Side ${ProdName} (MW) \xa0 ` : 'Bids: '
  const manualBidButtonName = 'Manual Bids'

  const handlePauseBidDeliveryUpdate = () => {
    const { isBidDeliveryPaused, updateBidDeliveryFlag } = props

    updateBidDeliveryFlag(selectedAsset.assetId, !isBidDeliveryPaused).then(response => {
      if (response.error) {
        notifyError('Pause bid unsuccessful')
      } else {
        setIsBidDeliveryModalOpen(false)
        props.getBidFileSettings(selectedAsset.assetId)
      }
    })
  }

  const handlePauseBidModalOpen = () => {
    setIsBidDeliveryModalOpen(prev => !prev)
  }

  const handleDownloadBidModalModalOpen = () => {
    setIsDownloadBidsModalOpen(prev => !prev)
  }

  const handleEditModalOpen = () => {
    setIsEditPbModalOpen(prev => !prev)
  }

  const shouldLoadTableChart =
    !_.isNil(selectedProductName) &&
    !_.isNil(selectedProductType) &&
    !_.isNil(selectedDate) &&
    !_.isEmpty(selectedAsset)
  const shouldLoadTable =
    shouldLoadTableChart && selectedBidView === BID_VIEW_TABLE && selectedDate.isValid() && !_.isNil(currentIndex)
  const shouldLoadChart = shouldLoadTableChart && selectedBidView === BID_VIEW_CHART && selectedDate.isValid()
  const disabledProductTypeToggleNames = getDisabledProductTypeToggleNames(selectedAsset)
  const showProductTypeToggle = shouldShowProductTypeToggle(selectedAsset)

  const handleSchemaChange = (event, selectedSchema) => {
    setSelectedSchema(selectedSchema)
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <Grid container spacing={0} wrap="wrap">
          <Grid item className={classes.datePickerGrid}>
            <KeyboardDatePicker
              timezone={marketTimezone}
              marketStartHour={marketStartHour}
              selectedDate={selectedDate}
              onChange={handleChangeDatePicker}
              ariaLabel="change market trading date"
            />
          </Grid>
          <Grid item>
            <Box display="inline-flex" pl={1.5}>
              <Toggle
                values={[
                  {
                    text: <TocIcon className={classes.tableIcon}>Table</TocIcon>,
                    key: BID_VIEW_TABLE,
                    toolTip: BID_VIEW_TABLE,
                  },
                  {
                    text: <ShowChartIcon className={classes.chartIcon}>Chart</ShowChartIcon>,
                    key: BID_VIEW_CHART,
                    toolTip: BID_VIEW_CHART,
                  },
                ]}
                selected={selectedBidView}
                onChange={handleChangeBidView}
              />
            </Box>
          </Grid>
          {showProductTypeToggle && (
            <Grid item>
              <Box display="inline-flex" pl={1.5}>
                <ProductTypeToggle
                  disabledTypes={collapsed ? disabledProductTypeToggleNames : []}
                  selected={selectedProductType}
                  productTypes={productTypes}
                  productTypeDisplayNames={productTypeDisplayNames}
                  onChange={(e, type) => handleChangeProductType(e, type)}
                />
              </Box>
            </Grid>
          )}
          {settlementInterval === SETTLEMENT_INTERVAL_MINS && selectedBidView === BID_VIEW_TABLE && (
            <Grid item>
              <Box display="inline-flex" pl={1.5}>
                <Toggle
                  values={[
                    {
                      text: '5m',
                      key: BID_INTERVAL,
                      toolTip: 'Dispatch Interval',
                    },
                    {
                      text: '30m',
                      key: SETTLEMENT_INTERVAL,
                      toolTip: 'Trading Interval',
                    },
                  ]}
                  selected={bidCollationInterval}
                  onChange={handleChangeBidInterval}
                />
              </Box>
            </Grid>
          )}
          <Grid item className={classes.productToggle}>
            <Box display="inline-flex" pl={1.5}>
              <ProductToggle
                asset={selectedAsset}
                selectedTime={selectedDate}
                selected={selectedProductName}
                productNames={productNames}
                productDisplayNames={productDisplayNames}
                onChange={handleChangeProductName}
                disabledProductNames={disabledProductNames}
                onCollapse={expandFCAS}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box display="inline-flex" pl={1.5}>
              {bidTransitionExists && schemaSelection && (
                <Box display="inline-flex" pl={1.5}>
                  <Toggle
                    values={[
                      {
                        text: DUID_SCHEMA_VIEW,
                        key: 1,
                        toolTip: DUID_SCHEMA_VIEW,
                      },
                      {
                        text: BDU_SCHEMA_VIEW,
                        key: 2,
                        toolTip: BDU_SCHEMA_VIEW,
                      },
                    ]}
                    selected={selectedSchema}
                    onChange={handleSchemaChange}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item className={classes.manualBidsItem}>
            <Box ml={1.5} mb={1.5}>
              {(canPauseBidDelivery(user.permissions, asset) || canOnlyPauseBidDelivery(user, asset)) &&
                typeof isBidDeliveryPaused !== 'undefined' && (
                  <Tooltip
                    title={isBidDeliveryPaused ? 'Resume bid Delivery' : 'Pause bid delivery'}
                    placement="top"
                    enterDelay={1000}
                  >
                    <button
                      className={isBidDeliveryPaused ? classes.resumeButton : classes.pauseButton}
                      onClick={setIsBidDeliveryModalOpen}
                    >
                      {isBidDeliveryPaused ? (
                        <PlayArrowIcon className={classes.pauseIcon} />
                      ) : (
                        <PauseIcon className={classes.pauseIcon} />
                      )}
                    </button>
                  </Tooltip>
                )}
            </Box>
            <Box pl={1.5}>
              {canSubmitManualBids(user.permissions, asset) && (
                <Tooltip title="Manage manual bids" placement="top" enterDelay={1000}>
                  <Button className={classes.manualBidsButton} onClick={onManualBidModalClick}>
                    {manualBidButtonName}
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} className={classes.mobile}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Card
              classes={{
                content: classes.tableCardContent,
              }}
              inProgress={!moment(selectedDate).isValid() || (!_.isNil(bidIsLoading) && bidIsLoading)}
              title={
                <div>
                  <p className={classes.panelTitle}>
                    {header}
                    <span className={classes.pauseText}>
                      {isBidDeliveryPaused ? `Bid delivery paused since ${pauseTimestamp}` : ``}
                    </span>
                  </p>
                </div>
              }
              subheader={subTitle + intervalText}
              action={
                <div>
                  {selectedBidView === 'Table' && (
                    <div className={classes.tableAction}>
                      {canAdjustPriceBands(user.permissions, asset) && (
                        <Button variant="text" className={classes.editPb} onClick={handleEditModalOpen}>
                          EDIT
                        </Button>
                      )}
                      <div>
                        <IconButton
                          title="Download"
                          className={classes.downloadRefreshIcon}
                          onClick={handleDownloadBidModalModalOpen}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                  {selectedBidView === 'Chart' && refreshable && (
                    <IconButton
                      title="Refresh"
                      className={classes.downloadRefreshIcon}
                      onClick={() => refreshGraphViews(selectedDate)}
                    >
                      <RefreshIcon />
                    </IconButton>
                  )}
                </div>
              }
            >
              <>
                {shouldLoadTableChart && (
                  <>
                    {shouldLoadTable && (
                      <BidTable
                        asset={selectedAsset}
                        bidCollationInterval={bidCollationInterval}
                        selectedDate={selectedDate}
                        selectedProductType={selectedProductType}
                        selectedProductName={selectedProductName}
                        currentBidIndex={currentIndex}
                        isLoading={bidIsLoading}
                        bids={finalCombinedBids}
                        priceBandValues={priceBands}
                      />
                    )}
                    {shouldLoadChart && (
                      <StackedBarGraph
                        asset={selectedAsset}
                        interval={interval}
                        syncHandler={syncHandler}
                        viewPort={viewPort}
                        selectedDate={selectedDate}
                        bidCollationInterval={bidCollationInterval}
                        selectedProductType={selectedProductType}
                        selectedProductName={selectedProductName}
                        marketTimezone={marketTimezone}
                        finalCombinedBids={finalCombinedBids}
                        bidIsLoading={bidIsLoading}
                        priceBands={priceBands}
                      />
                    )}
                  </>
                )}
              </>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <div className={classes.log}>
              {!_.isNil(selectedAsset) &&
                !_.isNil(selectedProductName) &&
                !_.isNil(selectedProductType) &&
                !_.isNil(selectedDate) && (
                  <ManualBidLog
                    asset={selectedAsset}
                    selectedProductName={selectedProductName}
                    selectedProductType={selectedProductType}
                    selectedDate={selectedDate}
                    marketTimezone={marketTimezone}
                    marketStartHour={marketStartHour}
                    isBidDeliveryPaused={isBidDeliveryPaused}
                    setIsBidDeliveryModalOpen={handlePauseBidModalOpen}
                    lastUpdated={lastUpdated}
                    manualBidsForLog={manualBidsForLog}
                    priceBandsRange={priceBandsRange}
                    settlementInterval={settlementInterval}
                  />
                )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {!_.isNil(selectedDate) && !_.isNil(finalCombinedBids) && (
        <ManualBidDashboard
          asset={selectedAsset}
          productId={productId}
          productType={selectedProductType}
          startTime={moment(manualBidSelectedDate).valueOf()}
          tradingDay={selectedDate}
          onDelete
          open={manualBidModalIsOpen}
          onClose={onManualBidModalClose}
          bidCollationInterval={bidCollationInterval}
          disabledProductTypeToggleNames={disabledProductTypeToggleNames}
          showProductTypeToggle={showProductTypeToggle}
        />
      )}
      <Modal open={isEditPbModalOpen} BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.18)' } }}>
        <Paper tabIndex={-1}>
          <EditPriceBands
            asset={selectedAsset}
            productTypes={productTypes}
            productDisplayNames={productDisplayNames}
            productNames={productNames}
            marketTimezone={marketTimezone}
            marketStartHour={marketStartHour}
            initialSelectedDate={selectedDate}
            disabledProductTypeToggleNames={disabledProductTypeToggleNames}
            showProductTypeToggle={showProductTypeToggle}
            onClose={handleEditModalOpen}
          />
        </Paper>
      </Modal>
      <Modal open={isDownloadBidsModalOpen} BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.18)' } }}>
        <Paper tabIndex={-1}>
          <DownloadBids
            asset={selectedAsset}
            bids={finalCombinedBids}
            priceBands={priceBands}
            interval={interval}
            selectedProductType={selectedProductType}
            selectedProductName={selectedProductName}
            selectedDate={selectedDate}
            lastUpdated={lastUpdated}
            isDownloadBidsModalOpen={isDownloadBidsModalOpen}
            onClose={handleDownloadBidModalModalOpen}
            disableAck={!_.get(selectedAsset, 'data.is_live')}
          />
        </Paper>
      </Modal>
      <Modal open={isBidDeliveryModalOpen} BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.18)' } }}>
        <Paper tabIndex={-1}>
          <PauseAlgorithmicBidding
            isBidDeliveryPaused={isBidDeliveryPaused}
            asset={selectedAsset}
            pauseBidDeliveryUpdate={handlePauseBidDeliveryUpdate}
            onClose={handlePauseBidModalOpen}
          />
        </Paper>
      </Modal>
    </Grid>
  )
}

const mapStateToProps = state => {
  const user = _.get(state, 'user.current.payload', {})
  const bidFileSettingsCreatedOn = _.get(state, 'setting.bidFileSettings.payload.createdOn')
  const isBidDeliveryPaused = _.get(state, 'setting.bidFileSettings.payload.data.isBidDeliveryPaused')
  const endOfInterval = _.get(state, 'dispatchInterval.status.endOfInterval')
  const getSettlementInterval = globalAppSelectors.getSettlementIntervalFn(state)

  return {
    allManualBids: _.get(state, 'bid.manualBids', []),
    endOfInterval,
    bidFileMetadata: _.get(state, 'bid.bidFileMetadata', []),
    bidFileSettingsCreatedOn,
    getSettlementInterval,
    isBidDeliveryPaused,
    latestBidFile: _.get(state, 'bid.latestBidFile'),
    manualBidsForLog: _.get(state, 'bid.manualBidsForLog'),
    priceBandsRange: _.get(state, 'bid.priceBandsRange'),
    reduxPriceBands: _.get(state, 'bid.priceBands'),
    user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onInitialize: asset => {
      dispatch(bidFileSettingsResource.get(asset.assetId, moment.utc()))
    },
    getAllData: (
      asset,
      productName,
      productType,
      startTime,
      selectedSchema,
      bidCollationInterval,
      collatedBidsOnly,
      refreshOnly,
      clearCache,
    ) => {
      getAllData(
        dispatch,
        asset,
        productName,
        productType,
        startTime,
        selectedSchema,
        bidCollationInterval,
        collatedBidsOnly,
        refreshOnly,
        clearCache,
      )
    },
    onDateChange: (asset, productName, productType, selectedDate, selectedSchema, bidCollationInterval) => {
      getAllData(dispatch, asset, productName, productType, selectedDate, selectedSchema, bidCollationInterval)
    },
    onBidViewChange: selectedBidView => {
      dispatch(selectBidView(selectedBidView))
    },
    dispatchGetAssets: () => {
      dispatch(getAssets())
    },
    getProductChangeData: (asset, productName, productType, startTime) => {
      const productId = _.get(findProduct(asset, productName, productType), 'productId')
      const today = getTradingStart(asset.market)
      const endTime = moment(startTime).add(1, 'd')
      const isBeforeToday = today.isAfter(startTime)
      dispatch(getManualBids(asset.assetId, productId, startTime, endTime, isBeforeToday))
    },
    onBidIntervalChange: (asset, productName, productType, date, selectedSchema, bidCollationInterval) => {
      getAllData(dispatch, asset, productName, productType, date, selectedSchema, bidCollationInterval, true, true)
    },
    onProductNameChange: productName => dispatch(selectProductName(productName)),
    getBidFileSettings: assetId => dispatch(bidFileSettingsResource.get(assetId, moment.utc().add(1, 'minutes'))),
    updateBidDeliveryFlag: (assetId, value) => dispatch(updateBidDeliveryFlag.put(assetId, value)),
    getPriceBandsRange: (asset, productId, startTime, endTime) => {
      dispatch(getPriceBandsRange.get(asset, productId, startTime, endTime, null, false))
    },
    getManualBidsForLog: (asset, productId, startTime, endTime) => {
      dispatch(getManualBidsForLog.get(asset.assetId, productId, startTime, endTime))
    },
    dispatchResetZeroMaxBidsMapByStartTime: () => dispatch(resetZeroMaxBidsMapByStartTime()),
  }
}

Bids.displayName = DISPLAY_NAME

export default connect(mapStateToProps, mapDispatchToProps)(Bids)

const getAllData = (
  dispatch,
  asset,
  productName,
  productType,
  startTime,
  selectedSchema,
  bidCollationInterval,
  collatedBidsOnly,
  refreshOnly,
  clearCache,
) => {
  const endTime = moment(startTime).add(1, 'd')
  const today = getTradingStart(asset.market)
  const isBeforeToday = today.isAfter(startTime)
  const product = findProduct(asset, productName, productType)
  const productId = _.get(product, 'productId')
  const promises = []
  promises.push(dispatch(getAssetByAssetId.get(asset)))
  promises.push(
    dispatch(
      getLatestBidFile(
        asset.assetId,
        productId,
        startTime,
        moment.min([moment.utc().add(1, 'minutes'), endTime]), // pre-transition days show old bid file schema
        selectedSchema,
        bidCollationInterval,
        !clearCache,
      ),
    ),
  )
  promises.push(dispatch(getBidFileMetadata.get(asset.assetId, startTime, selectedSchema)))
  promises.push(dispatch(getManualBidsForLog.get(asset.assetId, productId, startTime, endTime)))
  promises.push(dispatch(getPriceBandsRange.get(asset, productId, startTime, endTime, null, false)))

  if (refreshOnly) {
    promises.push(dispatch(getManualBids(asset.assetId, productId, startTime, endTime, isBeforeToday, clearCache)))
    promises.push(dispatch(bidFileSettingsResource.get(asset.assetId, moment.utc().add(1, 'minutes'))))
  } else if (!collatedBidsOnly && !refreshOnly) {
    promises.push(dispatch(getPriceBands('NA').get(asset, startTime, null, false)))
    promises.push(dispatch(getManualBids(asset.assetId, productId, startTime, endTime, isBeforeToday, clearCache)))
    const intervalConfig = new IntervalConfig(
      `${asset.assetId}_${enablementTag}_allProducts`,
      enablementTag,
      asset.assetId,
      null,
      getAllProductIds(asset),
    )
    const intervalTimeConfig = new IntervalTimeConfig(
      startTime,
      endTime,
      moment().add(_.get(asset, 'market.data.bid_interval'), 'minutes'),
    )
    promises.push(dispatch(getCollatedAssetProductIntervals(intervalConfig, intervalTimeConfig)))
  }
  return Promise.all(promises)
}
