import { combineReducers } from 'redux'
import dashboard from './dashboard'
import dispatchInterval from './dispatch-interval'
import user from './user'
import bid from './bid'
import marketResult from './market-result'
import actual from './actual-interval'
import collated from './collated-interval'
import setting from './setting'
import finance from './finance'
import forecast from './forecast'
import asset from './asset'
import marketScenario from './market-scenario'
import globalApp from './app'
import assetOperation from './asset-operation'
import regulationThroughput from './regulation-throughput'
import shotclock from './shotclock'

export default combineReducers({
  actual,
  asset,
  assetOperation,
  bid,
  collated,
  dashboard,
  dispatchInterval,
  globalApp,
  finance,
  forecast,
  marketResult,
  marketScenario,
  regulationThroughput,
  setting,
  shotclock,
  user,
})
