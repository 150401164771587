import _ from 'lodash'
import { Checkbox, TextField } from '@material-ui/core'
import { CAN_ACCESS_UI, CAN_ADMINISTER_USERS, CAN_DO_ANYTHING_PERMISSION, HAS_MFA } from '../../utility/constants'

export const user = {
  adminUser: 'Admin',
  participantUser: 'Participant',
}

export const getAdminOrParticipantStr = userPermissions => {
  const marketPermissions = _.get(userPermissions, 'marketPermissions', [])
  const hasRootLevelAdminPermissions =
    marketPermissions.includes(CAN_ADMINISTER_USERS) || marketPermissions.includes(CAN_DO_ANYTHING_PERMISSION)
  const customerPermissions = _.get(userPermissions, 'customerPermissions', [])
  const hasAnyCustomerLevelAdminPermissions = _.some(customerPermissions, obj =>
    obj.permissions.includes(CAN_ADMINISTER_USERS),
  )
  const assetPermissions = _.get(userPermissions, 'assetPermissions', [])
  const hasAnyAssetLevelAdminPermissions = _.some(assetPermissions, obj =>
    obj.permissions.includes(CAN_ADMINISTER_USERS),
  )

  return hasRootLevelAdminPermissions || hasAnyCustomerLevelAdminPermissions || hasAnyAssetLevelAdminPermissions
    ? user.adminUser
    : user.participantUser
}

export const userCanAdminister = currentUser => {
  const adminOrParticipant = getAdminOrParticipantStr(_.get(currentUser, 'permissions', []))
  return _.isEqual(adminOrParticipant, user.adminUser)
}
const getEmailValidationMsg = error => {
  return error.type === 'pattern' ? 'Please enter a valid email' : 'Field is required'
}
const getRequiredFieldErrorMsg = () => {
  return 'Field is required'
}
export const isUserInternalAdmin = user => {
  const marketPermissions = _.get(user, 'permissions.marketPermissions', [])
  return marketPermissions.includes(CAN_DO_ANYTHING_PERMISSION)
}
export const getFormFieldDict = (isEditMode, selectedUser, currentUser = {}) => {
  return {
    name: {
      defaultValue: isEditMode ? selectedUser.name : '',
      isRequired: true,
      label: 'Name*',
      getErrorTextFn: getRequiredFieldErrorMsg,
      component: TextField,
    },
    email: {
      defaultValue: isEditMode ? selectedUser.email : '',
      isRequired: true,
      label: 'Email*',
      getErrorTextFn: getEmailValidationMsg,
      pattern: /^\S+@\S+$/i,
      disabled: isEditMode,
      component: TextField,
    },
    title: {
      defaultValue: isEditMode ? selectedUser.title : '',
      isRequired: true,
      label: 'Title*',
      getErrorTextFn: getRequiredFieldErrorMsg,
      component: TextField,
    },
    hasMfa: {
      defaultValue: isEditMode ? selectedUser[HAS_MFA] : true,
      isRequired: false,
      label: 'Enable MFA',
      getErrorTextFn: () => {},
      component: Checkbox,
      hidden: !isUserInternalAdmin(currentUser),
    },
    canAccessUi: {
      defaultValue: isEditMode && !_.isNil(selectedUser[CAN_ACCESS_UI]) ? selectedUser[CAN_ACCESS_UI] : true,
      isRequired: false,
      label: 'Can access UI',
      getErrorTextFn: () => {},
      component: Checkbox,
      // in order to make backwards compatible
      hidden: !isUserInternalAdmin(currentUser),
    },
  }
}

export const defaultAlertPreferenceFields = ['sms', 'email', 'slack', 'assetIds']

export const getNemDefaultPreferences = () => {
  const marketAlertsDefault = {}
  _.forEach(defaultAlertPreferenceFields, fieldName => {
    marketAlertsDefault[fieldName] = []
  })
  return { type: 'MarketUserPreferences', marketAlerts: marketAlertsDefault }
}
