import moment from 'moment-timezone'
import types from './types'

export const addItem = (cacheKey, updatedOn = moment(), payload) => ({
  type: types.HTTP_CACHE_ADD,
  payload: { cacheKey, updatedOn, payload },
})

export const removeItem = key => ({
  type: types.HTTP_CACHE_REMOVE,
  payload: key,
})

export const removeItemIncludes = key => ({
  type: types.HTTP_CACHE_REMOVE_INCLUDES,
  payload: key,
})

export const expireOldEntries = expireTime => ({
  type: types.HTTP_CACHE_EXPIRE_OLD,
  payload: { expireTime },
})

const actions = {
  addItem,
  expireOldEntries,
  removeItem,
  removeItemIncludes,
}

export default actions
