// naming new colors => https://www.color-name.com/
export const colors = {
  WHITE: 'rgba(255, 255, 255, 1)',
  WHITE_LIGHTEN_06: 'rgba(255, 255, 255, 0.06)',

  // Reds
  BITTERSWEET: '#FF6E6E',
  SKY_MAGENTA: '#C67BBA',
  SOFT_RED: '#FF9B9B',
  LAVENDER: '#9580FF',

  // Greens
  PELOROUS: '#44B3B6',
  FERN_GREEN: '#607d48',
  MEDIUM_SEA_GREEN: '#4BAA74',
  MENTHOL: '#C2F39A',

  // Blues
  CORNFLOWER_BLUE: '#75ACED',
  DARK_CERULEAN: '#355881',
  LIGHT_CERULEAN: 'rgba(53, 88, 129, 0.3)',
  SKY_BLUE: '#6ED0E0',
  YALE_BLUE: '#135f94',
  OUTER_SPACE: '#2F353D',

  // Oranges
  CALIFORNIA: '#EA9A3C',
  BRIGHT_ORANGE: '#C37E2D',
  DARK_ORANGE: '#5D3403',

  // Greys
  NERO: '#171717',
  CINDER: '#1D2126',
  TOOLTIP: '#1D252E',
  BLACK_PEARL: '#212934',
  GUNMETAL: '#2F353D',
  RHINO: '#434C57',
}

export default colors
