import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel } from '@material-ui/core'
import {
  getAssetIcon,
  getUnitlessAssetCapacityText,
  formatPercentage,
  KW_TO_MW,
  marketsFix,
  getDailyRevenueDisplayValue,
} from '../../utility/utility'

const useStyles = makeStyles(theme => ({
  table: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& td': {
      borderBottom: '1px solid  #30353c',
      padding: 4,
    },
    '& tr:last-child > td': {
      borderBottom: 0,
    },
    '& th': {
      padding: 4,
      borderBottom: '1px solid  #30353c',
      textTransform: 'uppercase',
    },
  },
}))

const AssetTable = props => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const classes = useStyles(props)
  const { assets: allAssets, isLiveView } = props

  const handleSortClick = property => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const assets = allAssets.filter(asset => !asset.data.is_live === !isLiveView)
  const displayAssets = assets.map(asset => ({
    assetId: asset.assetId,
    name: asset.name,
    regionName: _.get(asset, 'region.data.display_name', ''),
    icon: getAssetIcon(_.get(asset, 'data.tag_prefix'), _.get(asset, 'data.asset_type')),
    type: _.get(asset, 'data.tag_prefix'),
    capacity: parseInt(getUnitlessAssetCapacityText(asset).kw),
    capacityDisplay: getUnitlessAssetCapacityText(asset).kw,
    duration: asset.dualType ? getUnitlessAssetCapacityText(asset).kwh : null,
    durationDisplay: asset.dualType ? getUnitlessAssetCapacityText(asset).kwh : '',
    region: _.get(asset, 'region.data.display_name', ''),
    currentSOE: _.get(asset, 'data.battery_capacity_kwh') * _.get(asset, 'data.number_batteries') * KW_TO_MW,
    currentSOEDisplay: getStateOfEnergyText(asset),
    currentPower: getCurrentPowerText(asset),
    dailyRevenueDisplay: getDailyRevenueDisplayValue(asset),
    dailyRevenue: _.get(asset, 'data.daily_revenue', ''),
  }))
  const sorted = _.orderBy(displayAssets, [orderBy], [order])

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableHeadRow order={order} orderBy={orderBy} handleSortClick={handleSortClick} />
      </TableHead>

      <TableBody>
        {sorted.map(asset => (
          <Row key={asset.assetId} asset={asset} />
        ))}
      </TableBody>
    </Table>
  )
}

const headers = [
  { name: 'Type', key: 'type' },
  { name: 'Asset', key: 'name' },
  { name: 'Size (MW)', key: 'capacity' },
  { name: 'Duration (MWh)', key: 'duration' },
  { name: 'Region', key: 'region' },
  { name: 'Current SOE', key: 'currentSOE' },
  { name: 'Current POWER (MW)', key: 'currentPower' },
  { name: 'Daily Revenue', key: 'dailyRevenue' },
]

const TableHeadRow = ({ order, orderBy, handleSortClick }) => (
  <TableRow>
    {headers.map(header => (
      <TableCell key={header.key} sortDirection={orderBy === header.key ? order : false}>
        <TableSortLabel active={orderBy === header.key} direction={order} onClick={() => handleSortClick(header.key)}>
          {header.name}
        </TableSortLabel>
      </TableCell>
    ))}
  </TableRow>
)

const Row = withRouter(props => {
  const { asset, history } = props

  return (
    <TableRow hover onClick={() => history.push(`/asset/${asset.assetId}/bids`)}>
      <TableCell>{asset.icon}</TableCell>
      <TableCell>{asset.name}</TableCell>
      <TableCell>{asset.capacityDisplay}</TableCell>
      <TableCell>{asset.durationDisplay}</TableCell>
      <TableCell>{asset.regionName}</TableCell>
      <TableCell>{asset.currentSOEDisplay}</TableCell>
      <TableCell>{asset.currentPower}</TableCell>
      <TableCell>{asset.dailyRevenueDisplay}</TableCell>
    </TableRow>
  )
})

const getStateOfEnergyText = asset => {
  if (_.isNil(asset.data.current_soe) || asset.data.current_soe === 'NaN') {
    return ''
  }
  const batteryCapacityMWH = asset.data.battery_capacity_kwh * asset.data.number_batteries * KW_TO_MW
  const batterySOEMWH = asset.data.current_soe * KW_TO_MW

  return `${batterySOEMWH.toFixed(0)} MWh (${formatPercentage(batterySOEMWH / batteryCapacityMWH)})`
}

const getCurrentPowerText = asset => {
  if (!_.isNil(asset.data.current_power) || asset.data.current_power !== 'NaN') {
    const batteryPower = asset.data.current_power * KW_TO_MW

    return batteryPower.toFixed(marketsFix) + ' MW'
  }
}

const mapStateToProps = state => {
  return {
    assets: state.dashboard.assets.payload,
    isLiveView: state.dashboard.view.isLiveView,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetTable)
