import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Graph, GraphGroup } from '../../graph/GraphGroup'
import * as common from '../../../graphs/common'
import * as defaults from '../../../graphs/utility/defaults'
import { getTimeAtStartOf } from '../../../utility/utility'
import {
  AMS_FORECAST_TAG,
  PRE_DISPATCH_DEMAND_TAG,
  PRE_DISPATCH_PRICE_TAG,
  FIVE_MINUTE_DURATION,
  ONE_HOUR_DURATION,
} from '../../../utility/constants'

import { getForecastStripLineData, getTimeSeriesGraph } from '../../../utility/graphUtility'
import {
  enablementGraphTemplate,
  futuresTooltipContentFormatter,
  priceGraphTemplate,
} from '../../../utility/graphTemplate'
import { getChartData } from '../../../redux/features/interval'
import * as dashboard from './dashboard'

const GRAPH_TITLES = ['Market Prices - Energy', 'Market Demand']

const MarketIntelligenceGraphs = props => {
  const { asset, collatedIntervals, inProgress, marketStartHour, region, selectedDate, timezone } = props
  const selectedStart = moment(selectedDate).tz(timezone).startOf('day').add(marketStartHour, 'hours')
  const selectedEnd = moment(selectedStart).add(1, 'days')
  const selectedRange = {
    start: selectedStart.unix(),
    end: selectedEnd.unix(),
  }

  const displayRangeStart = moment(selectedStart).unix()
  const displayRangeEnd = moment(selectedEnd).unix()
  const [displayRange, setDisplayRange] = useState({
    start: displayRangeStart,
    end: displayRangeEnd,
  })

  useEffect(() => {
    setDisplayRange({
      start: displayRangeStart,
      end: displayRangeEnd,
    })
  }, [displayRangeStart, displayRangeEnd])

  const selectedDateTimeStamp = moment(selectedDate).valueOf()
  const allGraphs = useMemo(() => {
    const displayNames = getDisplayNames(asset)
    const colors = _.get(asset, 'market.data.product_colors')

    const data = {}
    data.displayNames = displayNames
    data.timezone = timezone
    data.titles = GRAPH_TITLES
    data.dataList = getPriceAndDemandIntervalData(collatedIntervals, asset, moment(selectedDateTimeStamp), region)
    const graphs = data.dataList.map((line, index) => {
      const title = data.titles[index]
      const graphTemplate = getGraphTemplate(title)
      const extras = {
        colors: displayNames[index].map(key => colors[key.split(' ')[0].toUpperCase()]),
      }
      const timeSeriesGraph = getTimeSeriesGraph(
        graphTemplate,
        title,
        displayNames[index],
        line.data || {},
        timezone,
        _.get(asset, 'market.data.bid_interval') * 60,
        extras,
      )
      timeSeriesGraph.isLoading = line.isLoading
      return timeSeriesGraph
    })

    processGraphs(asset, graphs, data.dataList)

    return graphs
  }, [asset, collatedIntervals, region, selectedDateTimeStamp, timezone])

  const [marketPricesGraph, marketDemandGraph] = allGraphs
  const [pricesPredispatchEnergy, pricesEnergy, pricesStripline] = marketPricesGraph.series
  const [demandPredispatchEnergy, demandEnergy, demandStripline] = marketDemandGraph.series

  const getData = async () => {
    return {
      demand: {
        preMarketEnergyOptions: _.get(demandPredispatchEnergy, 'options'),
        energyOptions: _.get(demandEnergy, 'options'),
        striplineOptions: _.get(demandStripline, 'options'),
      },
      prices: {
        preMarketEnergyOptions: _.get(pricesPredispatchEnergy, 'options'),
        energyOptions: _.get(pricesEnergy, 'options'),
        striplineOptions: _.get(pricesStripline, 'options'),
      },
    }
  }

  const FIVE_MINUTES = FIVE_MINUTE_DURATION.asMilliseconds()
  const getEndOfInterval = time => {
    if (!_.isNil(time)) {
      const elapsedTimeInMs = time.valueOf() % FIVE_MINUTES
      const newEndOfInterval = moment(time.valueOf() - elapsedTimeInMs + FIVE_MINUTES)
        .subtract(1, 'seconds')
        .endOf('minute')
      return newEndOfInterval
    }
  }

  const inProgresOrIsLoading = inProgress || marketPricesGraph.isLoading || marketDemandGraph.isLoading
  const nextInterval = getEndOfInterval(moment()).unix()
  return (
    <GraphGroup
      currentTime={getTimeAtStartOf(moment(), FIVE_MINUTE_DURATION.asMinutes()).unix()}
      setDisplayRange={(start, end) => setDisplayRange({ start, end })}
      displayRange={displayRange}
      inProgress={inProgresOrIsLoading}
      range={selectedRange}
      context={{ timezone, marketStartHour, nowFloorDuration: ONE_HOUR_DURATION }}
    >
      <Graph
        name="Market prices (Energy)"
        getData={getData}
        height={300}
        formatXAxis={common.formatXAxis}
        formatX2Axis={dashboard.formatDemandX2Axis(nextInterval)}
        formatYAxis={dashboard.formatPricesYAxis}
        formatSeries={dashboard.formatPricesSeries}
        formatTooltip={dashboard.formatTooltip(
          _.get(allGraphs, 'marketPricesGraph.forecastStartTime'),
          _.get(asset, 'market.data.product_colors'),
        )}
      />

      <Graph
        name="Market demand"
        getData={getData}
        height={300}
        formatXAxis={common.formatXAxis}
        formatX2Axis={dashboard.formatDemandX2Axis(nextInterval)}
        formatYAxis={common.formatDemandYAxis}
        formatSeries={dashboard.formatDemandSeries}
        formatTooltip={_.get(marketDemandGraph, 'options.toolTip')}
      />
    </GraphGroup>
  )
}

MarketIntelligenceGraphs.propTypes = {
  timezone: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  collatedIntervals: state.collated,
})

export default connect(mapStateToProps)(MarketIntelligenceGraphs)

const getPriceAndDemandIntervalData = (collated, asset, selectedDate, region) => {
  const bidInterval = _.get(asset, 'market.data.bid_interval') * 60
  const startTime = selectedDate
  const selectedRegion = region
  const regionId = _.get(selectedRegion, 'regionId')

  const priceForecastTag = `${regionId}_${AMS_FORECAST_TAG}_allProducts`
  const priceForecastData = getChartData(collated, priceForecastTag, startTime)
  const amsPriceData = _.cloneDeep(priceForecastData)

  const preDispatchTag = `${regionId}_${PRE_DISPATCH_PRICE_TAG}_allProducts`
  const preDispatchPriceDataRaw = getChartData(collated, preDispatchTag, startTime)
  const preDispatchPriceData = _.cloneDeep(preDispatchPriceDataRaw)

  const demandTag = `${regionId}_${PRE_DISPATCH_DEMAND_TAG}_allProducts`
  const demandDataRaw = getChartData(collated, demandTag, startTime)
  const preDispatchDemandData = _.cloneDeep(demandDataRaw)

  const isLoading =
    _.get(amsPriceData, 'isLoading') ||
    _.get(preDispatchPriceData, 'isLoading') ||
    _.get(preDispatchDemandData, 'isLoading')

  let priceData = { isLoading: true }
  let demandData = { isLoading: true }
  if (!_.isNil(isLoading) && !isLoading) {
    const productIndex = asset.productNames.findIndex(productName => productName === asset.market.data.default_product)
    amsPriceData.data.values = [amsPriceData.data.values[productIndex]]
    preDispatchPriceData.data.values = [preDispatchPriceData.data.values[productIndex]]
    preDispatchDemandData.data.values = [preDispatchDemandData.data.values[productIndex]]
    priceData = combineAMSAndPreDispatchPrice(amsPriceData, preDispatchPriceData, bidInterval)
    demandData = processDemandLine(preDispatchDemandData, bidInterval)
  }

  return [priceData, demandData]
}

const combineAMSAndPreDispatchPrice = (amsPriceData, preDispatchPriceData, bidInterval) => {
  const data = {}
  const priceData = { data }

  // fill period where ams and pre-dispatch price actual price overlap with null
  const start = moment(amsPriceData.data.startTime)
  const forecastStart = moment(amsPriceData.data.forecastStartTime)
  _.range(0, (forecastStart.unix() - start.unix()) / bidInterval).forEach(index => {
    preDispatchPriceData.data.values.forEach(data => (data[index] = null))
  })
  data.startTime = start.format()
  data.forecastStartTime = forecastStart.format()
  data.values = _.concat(preDispatchPriceData.data.values, amsPriceData.data.values)
  priceData.isLoading = preDispatchPriceData.isLoading || amsPriceData.isLoading
  return priceData
}

const processDemandLine = (preDispatchDemandData, bidInterval) => {
  const start = moment(preDispatchDemandData.data.startTime)
  const forecastStart = moment(preDispatchDemandData.data.forecastStartTime)

  const actualDemand = _.cloneDeep(preDispatchDemandData)
  const forecastStartIndex = (forecastStart.unix() - start.unix()) / bidInterval
  const endIndex = (start.clone().add(1, 'd').unix() - start.unix()) / bidInterval

  // fill period actual demand in pre-dispatch-demand with null
  preDispatchDemandData.data.values[0] = _.get(preDispatchDemandData, ['data', 'values', 0], []).map((data, index) => {
    if (index < forecastStartIndex) {
      return null
    }
    return data
  })

  // fill period of pre-dispatch demand in actual demand with null
  // leaving an extra dataPoint so plot can be continuous...last interval on this line is a forecasted value
  // modified futuresTooltipContentFormatter function in graphTemplate.js so that this extra point is not shown in the tooltip
  actualDemand.data.values[0] = _.get(actualDemand, ['data', 'values', 0], []).map((data, index) => {
    if (index >= Math.min(forecastStartIndex + 1, endIndex)) {
      return null
    }
    return data
  })

  actualDemand.data.values = _.concat(preDispatchDemandData.data.values, actualDemand.data.values)
  return actualDemand
}

const getDisplayNames = asset => {
  const defaultProduct = _.get(asset, 'market.data.default_product')
  const productDisplayName = _.get(asset, ['market', 'data', 'product_display_names', defaultProduct])
  return [
    [`${productDisplayName} (PRE-DISPATCH)`, `${productDisplayName} (Fluence)`],
    [`${productDisplayName} (PRE-DISPATCH)`, `${productDisplayName}`],
  ]
}

const getGraphTemplate = title => {
  let template = null
  if (title.includes('Market Prices')) {
    template = _.cloneDeep(priceGraphTemplate)
  } else if (title === 'Market Demand') {
    template = _.cloneDeep(enablementGraphTemplate)
    template.name = 'Market Demand'
  } else {
    return {}
  }

  template.height = 270
  template.seriesOption.type = 'line'
  template.options.axisX.interval = 7200
  return template
}

const processGraphs = (asset, graphs, dataList) => {
  const firstData = (dataList[0] || {}).data || {}
  const startTime = moment(firstData.startTime)

  // add dataInterval to make time Interval-ending time AMS-5806
  const forecastStartTime = moment(firstData.forecastStartTime).add(asset.market.data.bid_interval, 'minutes')
  const stripLinesData = getForecastStripLineData(startTime, moment(startTime).add(1, 'd'), forecastStartTime)

  graphs.forEach(graph => {
    graph.options.toolTip = timezone => {
      return {
        ...defaults.tooltip,
        contentFormatter: futuresTooltipContentFormatter(graph.name, timezone.timezone, forecastStartTime),
      }
    }

    // change pre-dispatch lines to dash
    graph.series.forEach(series => {
      if (series.options.name.includes('(PRE-DISPATCH)')) {
        series.options.lineDashType = 'dash'
      }
    })

    // add actual/forecast stripLine
    graph.options.axisX2 = {
      stripLines: stripLinesData.stripLines,
      interval: 7200,
    }
    graph.forecastStartTime = forecastStartTime

    // a series that uses the secondary axis is required for the secondary axis stripLines to show
    graph.series.push(stripLinesData.series)
  })
}
