import React, { useEffect, useRef, useState, useCallback } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { EXTRA_SCROLL_OFFSET } from '../../utility/constants'
import { formatMarketValue, marketsFix, timeFormat, getIndex, perKWToPerMW, KW_TO_MW } from '../../utility/utility'

const DISPLAY_NAME = 'ComparisonTable'
const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    keepBorder: {
      borderCollapse: 'separate' /* Don't collapse */,
      borderSpacing: 0,
    },
    borderNone: {
      border: 'none',
    },
    borderLeft: {
      borderLeft: '1px solid rgba(81, 81, 81, 1)',
    },
    borderRight: {
      borderRight: '1px solid rgba(81, 81, 81, 1)',
    },
    table: {
      borderCollapse: 'separate' /* Don't collapse */,
      borderSpacing: 0,
      textTransform: 'uppercase',
      '& tbody:hover td[rowspan], tr:hover td': {
        backgroundColor: theme.palette.action.hover,
      },
      '& td': {
        padding: theme.spacing(0.75, 1),
      },
      '& th': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
      },
      '& thead > tr:nth-child(1) > th:first-child': {
        paddingLeft: theme.spacing(2),
      },
    },
    tableRowPast: {
      color: theme.palette.text.tertiary,
    },
    tableRowCurrent: {
      color: theme.custom.palette.colors.CORNFLOWER_BLUE,
    },
    noTextSelect: {
      userSelect: 'none',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    dualTypeHeaderTop: {
      borderBottom: 'none',
      paddingTop: 8,
      paddingBottom: 0,
      paddingLeft: 8,
      lineHeight: '1rem',
    },
    singleTypeHeader: {
      padding: theme.spacing(1),
    },
    dualTypeHeaderMiddle: {
      borderTop: 0,
      paddingTop: 0,
    },
    dualTypeHeaderBottom: {
      borderTop: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    lastHeader: {
      borderTop: 0,
      paddingTop: 0,
      borderCollapse: 'separate' /* Don't collapse */,
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
    },
    stickyHeaderSecond: {
      position: 'sticky',
      top: 24,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
    },
    stickyHeaderThird: {
      position: 'sticky',
      top: 40,
      zIndex: 2,
      backgroundColor: theme.palette.background.paper,
    },
    tradingInterval: {
      width: 70,
    },
    tradingWindow: {
      width: 106,
    },
    zeroValue: {
      color: 'rgba(255, 255, 255, 0.5)',
    },
    inheritColor: {
      color: 'inherit',
    },
  }),
  { name: DISPLAY_NAME },
)

const ComparisonTable = props => {
  const { asset, intervals, selectedDate, selectedProducts, settlementIntervalByTime } = props
  const classes = useStyles()

  const [shouldScroll, setShouldScroll] = useState(true)
  const tableContainerRef = useRef()

  const [offsetTop, setOffsetTop] = useState(0)
  const currentRowRef = useCallback(node => {
    if (node !== null) {
      setOffsetTop(node.offsetTop)
    }
  }, [])

  const currentIndex = getIndex(
    moment(),
    selectedDate,
    moment(selectedDate).add(1, 'd'),
    asset.market.data.bid_interval,
  )

  useEffect(() => {
    if (shouldScroll && !_.isNil(tableContainerRef) && offsetTop > 0) {
      setShouldScroll(() => {
        tableContainerRef.current.scrollTo(0, Math.max(offsetTop - EXTRA_SCROLL_OFFSET, 0))
        return false
      })
    }
  }, [shouldScroll, tableContainerRef, currentIndex, offsetTop])

  const ComparisonRows = props => {
    const { asset, currentIndex, intervals, settlementIntervalByTime } = props
    const bidInterval = _.get(asset, 'market.data.bid_interval')
    const WINDOWS_PER_INTERVAL = settlementIntervalByTime === bidInterval ? 1 : 6
    const numIntervals = intervals.length / WINDOWS_PER_INTERVAL

    return _.range(0, numIntervals).map(tradeInterval => (
      <TableBody key={tradeInterval}>
        {_.range(0, WINDOWS_PER_INTERVAL).map(intervalWindow => {
          const intervalIndex = tradeInterval * WINDOWS_PER_INTERVAL + intervalWindow
          return (
            <ComparisonRow
              key={intervalIndex}
              asset={asset}
              currentIndex={currentIndex}
              interval={intervals[intervalIndex]}
              intervalIndex={intervalIndex}
              settlementIntervalByTime={settlementIntervalByTime}
            />
          )
        })}
      </TableBody>
    ))
  }

  const ComparisonRow = rowProps => {
    const { asset, currentIndex, interval, intervalIndex, settlementIntervalByTime } = rowProps
    const timezone = asset.market.data.timezone
    const timeField = `${moment(interval.startTime).tz(timezone).format(timeFormat)} - ${moment(interval.endTime)
      .tz(timezone)
      .format(timeFormat)}`
    const factors = getFactors(asset.dualType)
    const isDollars = getColumnIsDollarsList(asset.dualType)
    const numberOfRevenueColumns = asset.dualType ? 3 : 1
    const bidInterval = _.get(asset, 'market.data.bid_interval')
    const WINDOWS_PER_INTERVAL = settlementIntervalByTime === bidInterval ? 1 : 6
    const rowClass = clsx({
      [classes.tableRowPast]: intervalIndex < currentIndex,
      [classes.tableRowCurrent]: intervalIndex === currentIndex,
      [classes.tableRowFuture]: intervalIndex > currentIndex,
    })

    return (
      <TableRow key={intervalIndex} ref={intervalIndex === currentIndex ? currentRowRef : null} className={rowClass}>
        {intervalIndex % WINDOWS_PER_INTERVAL === 0 && (
          <TableCell
            className={clsx(classes.tradingInterval, classes.inheritColor)}
            align="center"
            rowSpan={WINDOWS_PER_INTERVAL}
          >
            {interval.tradingInterval}
          </TableCell>
        )}
        <TableCell className={clsx(classes.noWrap, classes.inheritColor)}>{timeField}</TableCell>
        {interval.values.map((value, index) => {
          const valueIndex =
            index >= interval.values.length - numberOfRevenueColumns ? factors.length - 1 : index % factors.length
          const convertedValue = _.isNil(value) ? null : value * factors[valueIndex]
          const displayValue = formatMarketValue(convertedValue, 1, marketsFix, false, isDollars[valueIndex])
          const isZero = displayValue === '0' || displayValue === '$0'

          return (
            <TableCell
              key={index}
              className={clsx({
                [classes.inheritColor]: intervalIndex === currentIndex,
                [classes.zeroValue]: isZero && intervalIndex !== currentIndex,
              })}
            >
              {displayValue}
            </TableCell>
          )
        })}
      </TableRow>
    )
  }

  const FirstHeader = myProps => {
    const { asset, selectedProducts } = myProps
    const {
      noTextSelect,
      noWrap,
      borderLeft,
      tradingInterval,
      tradingWindow,
      dualTypeHeaderTop,
      stickyHeader,
      keepBorder,
    } = classes
    const rowSpan = asset.dualType ? 3 : 2

    const productClassName = clsx(noTextSelect, noWrap, borderLeft, dualTypeHeaderTop, stickyHeader)
    const revenueClassName = clsx(
      noTextSelect,
      noWrap,
      { [borderLeft]: selectedProducts.length > 0 },
      dualTypeHeaderTop,
      stickyHeader,
    )

    return (
      <TableRow>
        <TableCell
          className={clsx(tradingInterval, noTextSelect, noWrap, stickyHeader, keepBorder)}
          rowSpan={rowSpan}
        />
        <TableCell className={clsx(tradingWindow, noTextSelect, noWrap, stickyHeader, keepBorder)} rowSpan={rowSpan}>
          interval
        </TableCell>
        {selectedProducts.map((product, index) => (
          <ProductColumn key={index} className={productClassName} asset={asset} product={product} index={index} />
        ))}
        <TableCell
          key="revenue"
          style={{ color: 'white' }}
          className={revenueClassName}
          colSpan={asset.dualType ? 3 : 1}
          rowSpan={2}
        >
          Revenue
        </TableCell>
      </TableRow>
    )
  }

  const ProductColumn = ({ className, asset, product, index }) => {
    const productDisplayNames = _.get(asset, 'market.data.product_display_names', {})

    return (
      <TableCell className={className} colSpan={asset.dualType ? 5 : 3} style={{ color: 'white' }}>
        {productDisplayNames[product]}
      </TableCell>
    )
  }

  const MiddleHeader = ({ asset, selectedProducts }) => {
    const {
      noTextSelect,
      noWrap,
      borderLeft,
      singleTypeHeader,
      dualTypeHeaderTop,
      dualTypeHeaderMiddle,
      stickyHeaderSecond,
    } = classes
    const className = clsx(stickyHeaderSecond, noTextSelect, noWrap, dualTypeHeaderMiddle, {
      [dualTypeHeaderTop]: asset.dualType,
      [singleTypeHeader]: !asset.dualType,
    })

    const colSpan = asset.dualType ? 2 : 1

    return (
      <TableRow>
        {selectedProducts.map(product => (
          <React.Fragment key={product}>
            <TableCell className={clsx(className, borderLeft)}>Price ($/MWh)</TableCell>
            <TableCell colSpan={colSpan} className={className}>
              Enablements (MW)
            </TableCell>
            <TableCell colSpan={colSpan} className={className}>
              Settlements ($)
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    )
  }

  const LastHeader = ({ asset, selectedProducts }) => {
    const { noTextSelect, noWrap, borderLeft, lastHeader, stickyHeaderThird } = classes
    const className = clsx(stickyHeaderThird, noTextSelect, noWrap, lastHeader)
    const productTypeDisplayNames = _.get(asset, 'market.data.product_type_display_names', {})

    return (
      <TableRow>
        {/* add gen, load for MW and gen, load for Settlements */}
        {selectedProducts.map(product => (
          <React.Fragment key={product}>
            <TableCell className={clsx(className, borderLeft)} />
            {_.range(0, 2).map(i =>
              asset.productTypes.map(type => (
                <TableCell key={`${type}-${i}`} className={className}>
                  {productTypeDisplayNames[type]}
                </TableCell>
              )),
            )}
          </React.Fragment>
        ))}
        {/* add gen, load, total for revenue */}
        {asset.productTypes.map((type, index) => (
          <TableCell key={type} className={clsx(className, { [borderLeft]: index === 0 })}>
            {productTypeDisplayNames[type]}
          </TableCell>
        ))}
        <TableCell className={className}>Total</TableCell>
      </TableRow>
    )
  }

  return (
    <Box className={classes.root} style={{ overflowX: 'auto' }} ref={tableContainerRef}>
      <Table className={classes.table} size="small" aria-label="market results table">
        <TableHead>
          <FirstHeader asset={asset} selectedProducts={selectedProducts} />
          <MiddleHeader asset={asset} selectedProducts={selectedProducts} />
          {!!asset.dualType && <LastHeader asset={asset} selectedProducts={selectedProducts} />}
        </TableHead>
        <ComparisonRows
          asset={asset}
          intervals={intervals}
          currentIndex={currentIndex}
          settlementIntervalByTime={settlementIntervalByTime}
        />
      </Table>
    </Box>
  )
}

export default ComparisonTable

const getFactors = dualAssetClass =>
  dualAssetClass ? [perKWToPerMW, KW_TO_MW, KW_TO_MW, 1, 1] : [perKWToPerMW, KW_TO_MW, 1]
const getColumnIsDollarsList = dualAssetClass =>
  dualAssetClass ? [true, false, false, true, true] : [true, false, true]
